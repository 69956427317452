import React from 'react';
import { Typography, Grid, FormControl, FormGroup, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

function Gender(props) {
  const { classes, values, setValues } = props;
  const [showTextField, setShowTextField] = React.useState(false);

  const handleChange = (e) => {
    if (e.target.value !== 'Any' ) {
      setShowTextField(true);
      setValues({ ...values, gender_pref: [e.target.value] })
    } else {
      setShowTextField(false);
      setValues({ ...values, gender_pref: [e.target.value], gender_pref_reason: "" })
    }
  }

  React.useEffect( () => {
    if(values.gender_pref && values.gender_pref.length === 0){
      setValues({ ...values, gender_pref: ["Any"] })
    }
    if (values.gender_pref_reason !== "") {
      setShowTextField(true);
    }
  }, [])

  return <Grid item xs={12} className={classes.my4} >
    <Typography gutterBottom variant='h5' className={`${classes.inputHeading} ${classes.my2}`}>Preferred Gender</Typography>
    <FormControl component="fieldset" className={`${classes.flexbox} ${classes.my4} ${classes.workTypeFormControl}`} >
      <RadioGroup aria-label="worktype" name="opportunity_type"
        value={values.gender_pref && values.gender_pref.toString()}
        onChange={e => handleChange(e)}
        className={classes.workTypeOptions}>
        <FormControlLabel className={classes.mr3} value="Any" control={<Radio classes={{ root: classes.radioColor }} />} label="Any" />
        <FormControlLabel className={classes.mr3} value="Male" control={<Radio classes={{ root: classes.radioColor }} />} label="Male" />
        <FormControlLabel className={classes.mr3} value="Female" control={<Radio classes={{ root: classes.radioColor }} />} label="Female" />
      </RadioGroup>
    </FormControl>
    {showTextField && <div>
      <TextField
        fullWidth
        placeholder='Reason for gender preference'
        classes={{ root: classes.TfRoot }}
        value={values.gender_pref_reason}
        onChange={e => setValues({ ...values, gender_pref_reason: e.target.value })}
      />
    </div>}
  </Grid>;
}

export default Gender;
