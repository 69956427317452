import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import No_Results from './NoResults'
import { connectPagination } from 'react-instantsearch-dom';
import { connectStateResults } from 'react-instantsearch-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from '@material-ui/icons/Search';
import PrimaryAppBar from '../../components/JobAppBar';
import SearchBar from './SearchBar';
import DiscoverCards_2 from './ProfileCards_2';
import DiscoveryList_2 from './DiscoveryList_2';
import CustomPagination from './ProfileCardNavbar';
import CustomPagination_2 from './CustomPagination_2'
import CustomPagination_3 from './CustomPagination_3'
import { ThemeProvider } from '@material-ui/styles';
import { StylesProvider } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Image from '../../assets/Iconwallpaper2.png';
import './App.css';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia.css';
import { RefinementList } from 'react-instantsearch-dom';
import { connectRange } from 'react-instantsearch-dom';
import { connectHits } from 'react-instantsearch-dom';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'rheostat/lib/themes/DefaultTheme';
import MyTheme from '../../style/JobAppTheme';
import '../../style/App.css';
import CustomList from './CustomRefinementList';
import CustomList_qualifications from './CustomRefinementListQualifications'
import CustomListLocation from './CustomRefinementListLocation'
import InfiniteHits from './InfiniteHits'
import InfiniteHits_Card from './InfiniteHits_Card'
import { Stats } from 'react-instantsearch-dom';
import { SortBy, connectSortBy } from 'react-instantsearch-dom';
import myInitObject from './location'
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Loader_Shuffle from './Loader_Shuffle';
import CardsStack from './Cards_Stack';
import ListLoader from './ListLoader';
import PageLoader from './PageLoader';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Configure,
} from 'react-instantsearch-dom';
import { Component } from 'react';
import { connectScrollTo } from 'react-instantsearch-dom';
import selected_qualifications from './quals'
//import { SearchBox } from 'react-instantsearch-dom';
import dividersCount from './dividersCount';
import loaderCountOnPg2Search from './loaderCountOnPg2Search';
import global_isSignedIn from './global_isSignedIn';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import FeedbackAlert from '../../components/FeedbackAlert';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
import { RolesNotToBePassedToAlgolia } from '../../Globals';
import { Helmet } from 'react-helmet-async';
import ogImg from '../../assets/og-img.jpg';
import { Dialog, DialogContent, Drawer, FormControl, InputAdornment, Menu, MenuItem, OutlinedInput } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import ImportExportIcon from '@material-ui/icons/ImportExport';

var axios = require('axios');

class DefaultScrollTo extends Component {
  componentDidUpdate(prevProps) {
    const { value, hasNotChanged } = this.props;

    if (value !== prevProps.value && hasNotChanged) {
      this.el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
  }

  render() {
    return (
      <div ref={ref => (this.el = ref)} style={{ paddingTop: 120, marginTop: '-120px' }}>
        {this.props.children}
      </div>
    );
  }
}

const CustomScrollTo = connectScrollTo(DefaultScrollTo);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingTop: 84,
    paddingTop: 74,
    backgroundAttachment: 'fixed',
  },
  pageTitle: {
    color: '#5BC2A8',
    textAlign: 'center',
    marginBottom: '0.2em',
    fontWeight: 500,
  },
  pageBar: {
    width: 960,
    //width: 860,
    padding: '5px 43px 0',
    flexBasis: 65,
    //marginTop: 35,
    backgroundColor: "transparent",
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    '@media (max-width: 600px)': {
      width: '100%',
      marginBottom: '2em',
      padding: '0 28px 0',
      maxHeight: '50px'
    }
  },
  relevanceMsgBar: {
    width: 860,
    //flexBasis: 40,
    //marginTop: 25,
    zIndex: 8,
    padding: '0',
    marginLeft: 7,
    marginTop: 10,
    background: 'linear-gradient(89deg, rgb(255 255 255 / 0%) 0%, rgb(230 230 230 / 80%) 50%, rgba(255,255,255,0.1) 100%)',
  },
  itemCount: {
    color: '#555',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 500,
    marginRight: '2em',
    minWidth: 100,
    display: 'flex',
    // width: 110,
    width: 135,
    '& .ais-Stats *': {
      // color: '#232323!important',
      color: '#14967b!important',
      textAlign: 'center',
      fontSize: '15px!important',
      fontWeight: 500,
    }
  },
  sortBar: {
    marginLeft: theme.spacing(1),
    '& > *': {
      marginLeft: 2,
      marginRight: 2,
      marginBottom: theme.spacing(4),
      textTransform: 'capitalize',
      fontWeight: 400,
      color: '#616161',
    },
  },
  subtitle: {
    lineHeight: '2em',
    color: '#797979',
    marginRight: 10,
    fontSize: 14,
    fontWeight: 500,
    '@media (max-width: 600px)': {
      textTransform: 'capitalize',
      padding: 0,
      color: '#333',
      backgroundColor: '#fff',
      fontWeight: 400,
      lineHeight: '1.8em',
    }
  },
  mobileMenuPaper: {
    '@media (max-width: 600px)': {
      top: '150px !important',
      left: '42% !important',
      width: '48%',
      '& > ul': {
        padding: 0,
        '& li': {
          padding: 0,
          fontSize: '0.9rem'
        }
      }
    }
  },
  togBtnSizeSmall: {
    padding: '1px 12px',
    backgroundColor: '#FFFFFF',
    marginLeft: '0!important',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    }
  },
  togBtnLabel: {
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 400,
    color: '#555',
  },
  togBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
    '& > span': {
      color: '#ffffff!important',
    }
  },
  togBtnLabel_Sort: {
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  togBtnSelected_Sort: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  togbtnDisabled: {
    color: '#ccc!important',
    cursor: 'not-allowed!important',
    pointerEvents: 'unset!important'
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    width: 900,
    height: 51,
    paddingLeft: 34,
    borderRadius: 35,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 52,
    }
  },
  searchBarIconButton: {
    marginTop: 0,
    height: 51,
    width: 51,
    textTransform: "none",
    backgroundColor: '#14967B',
    borderRadius: '0 35px 35px 0',
    color: 'white',
    "&:hover": {
      background: "#5BC2A8",
    }
  },
  listViewWrapper: {
    opacity: 1,
    '&.animate': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
    }
  },
  checkboxControlRoot: {
    padding: '1px 12px',
    marginRight: 40,
    boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px',
    background: '#ffffff',
    borderRadius: 4,
    '@media (max-width: 600px)': {
      padding: '1px 8px',
    }
  },
  checkboxElement: {
    padding: '0 5px 0 0'
  },
  checkboxLabel: {
    fontSize: 14,
    marginRight: 0,
    color: '#555',
  },
  desktopSearch: {
    zIndex: 99,
    '@media (max-width: 600px)': {
      display: 'none',
    }
  },
  mobileSearchBarDrawer: {
    height: '60vh',
    display: 'flex',
    alignItems: 'flex-end',
    // justifyContent: 'center'
  },
  mobileSearch: {
    width: '100%'
  },
  mobileSortSelected: {
    backgroundColor: '#55c6a9',
    color: '#fff'
  },
  sortByContainer: {
    position: 'relative'
  }

}));

//algolia search only api key
const searchClient = algoliasearch('L42QPPK6EJ', '9f31e7c74c2420ac1e2fd4863a69463a');

const CustomHits = connectHits(DiscoverCards_2);
const DiscoverHits = connectHits(DiscoveryList_2);
const No_Results_Hits = connectHits(No_Results);

const CustomPagination_wrapper = connectPagination(CustomPagination);
const CustomPagination_2_wrapper = connectPagination(CustomPagination_2);
const CustomPagination_3_wrapper = connectPagination(CustomPagination_3);

export const DrawerContext = React.createContext({});

export const globalRoleLocationInvite = {
  search_role: "",
  search_location: "",
}

export const globalInviteList = {
  invite_list: [],
}

const isMobile = window.innerWidth <= 600 ? true : false;

//added match here because we now have parameters (role, location) for this route 
export default function Discovery({ props, match }) {
  //   <div>     
  //     <div>{`The URL is "${match.url}"`}</div>
  //     <div>{`It matched the path "${match.path}"`}</div>
  //     <div>{`The parameter role is "${match.params._role}"`}</div>
  //     <div>{`The parameter location is "${match.params._location}"`}</div>  
  //  </div>

  var dividerCountInline = 0;
  const location = useLocation();
  const classes = useStyles();
  const [profileView, setProfileView] = React.useState(isMobile ? 'list' : 'cards');
  const [role, setRole] = React.useState(match.params._role.split("-").join(" ")); //used role parameter in URl instead of role from location.state
  const [secondaryRoles, setSecondaryRoles] = React.useState(location && location.state && location.state.secondaryRoles ? location.state.secondaryRoles : []);
  // const [jobseekerEnteredRoles, setJobseekerEnteredRoles] = React.useState([match.params._role.split("-").join(" ")]);
  const [jobseekerEnteredRoles, setJobseekerEnteredRoles] = React.useState(null);
  const [qualifications, setQualifications] = React.useState(location && location.state && location.state.qualifications ? location.state.qualifications : []);
  const [autoQualsFlag, setAutoQualsFlag] = React.useState(location && location.state && location.state.flag_auto_qualifications ? location.state.flag_auto_qualifications : false);
  const [isAnyQualCatChecked, setIsAnyQualCatChecked] = React.useState(location && location.state && location.state.isAnyQualCatChecked ? location.state.isAnyQualCatChecked : {});

  const [flag_your_location, setFlagYourLocation] = React.useState(location && location.state && location.state.flag_your_location ? location.state.flag_your_location : false);

  const [job_role_id, setJobRoleId] = React.useState(location && location.state && location.state.job_role_id ? location.state.job_role_id : "")
  const [preferredQualsArray, setPreferredQualsArray] = React.useState(location && location.state && location.state.preferredQualsArray ? location.state.preferredQualsArray : "");

  const [autoTriggerSearch, setAutoTriggerSearch] = React.useState(false);

  const [location_value, setLocationValue] = React.useState("");
  const [sortSelected, setSortSelected] = React.useState(location_value === 'Anywhere/ Work-from-Home' ? 'user_experience_and_qualification' : 'last_active_standard');
  const [loaderRef, setLoaderRef] = React.useState(null);
  const [cardStackRef, setCardStackRef] = React.useState(null);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  const [searchRef, setSearchRef] = React.useState(null);
  const [locationRef, setLocationRef] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  //const [searching, setSearching] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [key_autocomplete, setKeyAutocomplete] = React.useState(false);
  const [geographical_type, setGeographicalType] = React.useState(location && location.state && location.state.geographical_type ? location.state.geographical_type : null);
  const [nb_hits, setNbHits] = React.useState(null);
  const [flag_location_text, setFlagLocationText] = React.useState(false);
  const [flag_no_results, setFlagNoResults] = React.useState(false);
  const [state_dividers_count, setStateDividersCount] = React.useState(0);
  const [navValue, setNavValue] = React.useState(1);
  const [location_invite, setLocationInvite] = React.useState(match.params._location ? match.params._location.split("-").join(" ").replaceAll("_", "/") : "");

  const [filters, setFilters] = React.useState({
    freshers: false,
    female: false
  })

  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.checked });
    setAutoTriggerSearch(prev => !prev);
    if (e.target.name === "freshers" && e.target.checked) { // if fresher filter is applied & most experienced is already selected then switch to recently active
      switchtoRecentFromMostExp();
    }
  }

  //flag for checking if sorting button is clicked manually or through code by refs
  const [flagSortRecentlyActive, setFlagSortRecentlyActive] = React.useState(true);
  const [flagSortNearest, setFlagSortNearest] = React.useState(true);
  const [flagSortMostExperienced, setFlagSortMostExperienced] = React.useState(true);

  const initialRenderNearest = useRef(true);
  const initialRenderRecentlyActive = useRef(true);
  const initialRenderMostExperienced = useRef(true);
  //To ensure that the useEffects for clicking sorting buttons are NOt run on initial render

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;
  const [mobileSortingAnchorEl, setMobileSortingAnchorEl] = React.useState(null);

  const handleMobileSortingClose = () => {
    setMobileSortingAnchorEl(null);
  };

  const isSortMenuOpen = Boolean(mobileSortingAnchorEl);


  // const [isSignedIn, setIsSignedIn] = React.useState(true);
  // global_isSignedIn.isSignedIn = true;

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  //common func to remove localstorage data on session expiry
  const removeLocalStorageOnSessionExpiry = () => {
    localStorage.setItem('company_id', "");
    localStorage.setItem('company_email_id', "");
    localStorage.setItem('company_name', "");
    localStorage.setItem('employer_name', "");
    localStorage.removeItem('is_logged_in');
    setIsSignedIn(false);
    global_isSignedIn.isSignedIn = false;
    handleDrawer();
    //handleAlert("Oops! You've been logged out. Please login again.", 'error');
  }

  const ref_last_active_sort = useRef(null);
  const ref_nearest_sort = useRef(null);
  const ref_most_exp_sort = useRef(null);

  selected_qualifications.quals = qualifications;
  selected_qualifications.role = role;
  selected_qualifications.job_role_id = job_role_id;
  selected_qualifications.preferredQualsArray = preferredQualsArray;

  document.body.classList.remove("login");
  document.body.classList.add('wallpaper--icon');

  const scrollRefPagination = useRef(null);
  const listViewWrapperRef = useRef(null);

  var lat_lng = {
    lat: location && location.state && location.state.lat ? location.state.lat : null,
    lng: location && location.state && location.state.long ? location.state.long : null,
  }
  const [lat, setLatitude] = React.useState(lat_lng.lat);
  const [long, setLongitude] = React.useState(lat_lng.lng);

  myInitObject.lat = lat;
  myInitObject.long = long;
  myInitObject.geoType = geographical_type;

  const nextPath = (path) => {
    props.history.push(path);
  }

  if (role == null) {
    nextPath('/login_2')
  }

  // const shuffleElsRef = React.useRef([]);
  // shuffleElsRef.current = [];

  // const addToRefs = (el) => {
  //   if (el && !shuffleElsRef.current.includes(el)) {
  //     shuffleElsRef.current.push(el);
  //   }
  // }

  // React.useEffect(() => {
  //   //shuffleElsRef.current[0].click();
  // }, []);

  React.useEffect(() => {
    //changed this function to replace location from location.state to the location obtained from URl parameters
    if (lat == null && long == null && match.params._location !== "anywhere") {

      axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + match.params._location.split("-").join(" ").replaceAll("_", "/") + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
        if (result) {
          let result_actual = result.data.results[0]
          setLatitude(result_actual.geometry.location.lat);
          setLongitude(result_actual.geometry.location.lng);

          // if geotype is present
          if (result_actual.types[0]) {
            console.log(result_actual.types[0])
            setGeographicalType(result_actual.types[0]);
          }
        }
      })
      // .then(() => {
      //   //setValue(newValue);
      // })
    }
  }, [])

  // Remove body scroll during the Page loader of 3s. To fix the shifting loader during p1 to p2 transition 
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      document.body.style.overflow = "auto";
    }, 3000)
  }, [])

  let timeout = () => {
    setTimeout(() => {
      setIsCardLoaderOn(false);
      if (loaderRef && loaderRef.current && cardStackRef && cardStackRef.current) {
        loaderRef.current.classList.remove('animate');
        cardStackRef.current.classList.remove('animate');
      };
    }, 1900)
  };

  let listLoaderTimeout = () => {
    setTimeout(() => {
      if (listViewWrapperRef.current) {
        // listLoaderRef.current.classList.remove('animate');
        listViewWrapperRef.current.classList.remove('animate');
      };
    }, 2000)
  };

  const SortBy_new = ({ items, refine }) => (
    <ToggleButtonGroup size="small"
      exclusive
      style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px' }}
    >
      {items.map((item, i) => (
        <ToggleButton
          id={"sort_btn_id" + i}
          key={i}
          value=""
          style={{ fontWeight: item.isRefined ? 'bold' : '', backgroundColor: item.isRefined ? '#14967B' : '#FFFFFF', color: item.isRefined ? '#ffffff' : '#555', }}
          ref={item.label == 'Recently Active' && item.isRefined ? ref_last_active_sort : (item.label == 'Nearest' && item.isRefined ? ref_nearest_sort : ref_most_exp_sort)}
          onClick={() => {
            //do not send data to segment on initial render. Only send when flagSortNearest is set etc. When the sort button is clicked from code, flagSortNearest etc are set to false
            if ((initialRenderNearest.current == false && initialRenderMostExperienced.current == false && initialRenderRecentlyActive.current == false) && (flagSortRecentlyActive && item.label == 'Recently Active' || flagSortNearest && item.label == 'Nearest' || flagSortMostExperienced && item.label == 'Most Experienced')) {
              if (item.label == "Most Experienced")
                AnalyticsHandler.track("discoveryResult_Sorting_experience_btnClick");
              else
                if (item.label == "Nearest")
                  AnalyticsHandler.track("discoveryResults_Sorting_nearest_btnClick");
                else
                  if (item.label == "Recently Active")
                    AnalyticsHandler.track("discoveryResult_Sorting_recentlyActive_btnClick");

              //console.log("sort button clicked");
              if (item.label === "Nearest" && !lat && !long) {
                //setShowNearMeInDropdown(true); //used sessionstorage as the passing prop is causing the card to re-render
                sessionStorage.setItem("showNearMeInDropdown", "true");
              } else {
                //setShowNearMeInDropdown(false);
                sessionStorage.setItem("showNearMeInDropdown", "false");
                setAutoTriggerSearch(prev => !prev);
              }
              //setAutoTriggerSearch(prev => !prev);
            }
            if (item.label === "Nearest" && !lat && !long) {
              locationRef.current && locationRef.current.click();
              locationRef.current && locationRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();//to clear anywhere text from the location box
            } else {
              if (loaderRef && loaderRef.current && cardStackRef && cardStackRef.current) {
                let loaderText = loaderRef.current.querySelector('.loaderText');
                if (item.label === "Nearest") {
                  loaderText.innerHTML = "Showing nearest jobseekers"
                } else if (item.label === "Recently Active") {
                  loaderText.innerHTML = "Showing recently active jobseekers"
                } else {
                  loaderText.innerHTML = "Showing most experienced jobseekers"
                }
                if (loaderRef.current.classList.contains('.animate')) {
                  clearTimeout(timeout())
                  //console.log("cleartimeout")
                }
                loaderRef.current.classList.add('animate');
                cardStackRef.current.classList.add('animate');

                timeout();
              }

              if (profileView !== "cards") {
                if (
                  //listLoaderRef && listLoaderRef.current && 
                  listViewWrapperRef && listViewWrapperRef.current) {
                  let loaderText = listLoaderRef.current.querySelector('.loaderText');
                  if (item.label === "Nearest") {
                    loaderText.innerHTML = "Showing nearest jobseekers"
                  } else if (item.label === "Recently Active") {
                    loaderText.innerHTML = "Showing recently active jobseekers"
                  } else {
                    loaderText.innerHTML = "Showing most experienced jobseekers"
                  }
                  // listLoaderRef.current.classList.add('animate');
                  setIsListLoaderOn(prev => !prev);
                  listViewWrapperRef.current.classList.add('animate');
                  listLoaderTimeout();
                }
              }
              setPage(1);
              // page_global.page = 1;
              refine(item.value);
              setSortSelected(item.value);
              dividersCount.count = (dividersCount.count > 1000) ? 1 : (dividersCount.count + 1);
              //console.log("selected...." + item.value);
            }
            // setTimeout(() => {
            //   showRelevanceChangeDivider();
            // }, 1000);

          }}
          disabled={(filters.freshers || role.toLowerCase().trim() === "fresher") && item.label == "Most Experienced" ? true : false}
          classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel_Sort, selected: classes.togBtnSelected_Sort, disabled: classes.togbtnDisabled }}>
          {item.label == "Nearest" ? "Nearest" : item.label == "Recently Active" ? "Recently Active" : "Most Experienced"}
        </ToggleButton>
      )
      )
      }
    </ToggleButtonGroup>
  );
  const SortBy_new_mobile = ({ items, refine }) => (
    <Menu
      anchorEl={mobileSortingAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      // id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isSortMenuOpen}
      onClose={handleMobileSortingClose}
      classes={{ paper: classes.mobileMenuPaper }}
    >
      {/* <Typography><ImportExportIcon /> <span>Sort by</span></Typography> */}
      {items.map((item, i) => (
        <MenuItem
          classes={{ root: classes.menuListItem, selected: classes.mobileSortSelected }}
          ref={item.label == 'Recently Active' && item.isRefined ? ref_last_active_sort : (item.label == 'Nearest' && item.isRefined ? ref_nearest_sort : ref_most_exp_sort)}
          onClick={() => {
            handleMobileSortingClose();
            //do not send data to segment on initial render. Only send when flagSortNearest is set etc. When the sort button is clicked from code, flagSortNearest etc are set to false
            if ((initialRenderNearest.current == false && initialRenderMostExperienced.current == false && initialRenderRecentlyActive.current == false) && (flagSortRecentlyActive && item.label == 'Recently Active' || flagSortNearest && item.label == 'Nearest' || flagSortMostExperienced && item.label == 'Most Experienced')) {
              if (item.label == "Most Experienced")
                AnalyticsHandler.track("discoveryResult_Sorting_experience_btnClick");
              else
                if (item.label == "Nearest")
                  AnalyticsHandler.track("discoveryResults_Sorting_nearest_btnClick");
                else
                  if (item.label == "Recently Active")
                    AnalyticsHandler.track("discoveryResult_Sorting_recentlyActive_btnClick");

              //console.log("sort button clicked");
              if (item.label === "Nearest" && !lat && !long) {
                //setShowNearMeInDropdown(true); //used sessionstorage as the passing prop is causing the card to re-render
                sessionStorage.setItem("showNearMeInDropdown", "true");
              } else {
                //setShowNearMeInDropdown(false);
                sessionStorage.setItem("showNearMeInDropdown", "false");
                setAutoTriggerSearch(prev => !prev);
              }
              //setAutoTriggerSearch(prev => !prev);
            }
            if (item.label === "Nearest" && !lat && !long) {
              locationRef.current && locationRef.current.click();
              locationRef.current && locationRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0].click();//to clear anywhere text from the location box
            } else {
              if (loaderRef && loaderRef.current && cardStackRef && cardStackRef.current) {
                let loaderText = loaderRef.current.querySelector('.loaderText');
                if (item.label === "Nearest") {
                  loaderText.innerHTML = "Showing nearest jobseekers"
                } else if (item.label === "Recently Active") {
                  loaderText.innerHTML = "Showing recently active jobseekers"
                } else {
                  loaderText.innerHTML = "Showing most experienced jobseekers"
                }
                if (loaderRef.current.classList.contains('.animate')) {
                  clearTimeout(timeout())
                  //console.log("cleartimeout")
                }
                loaderRef.current.classList.add('animate');
                cardStackRef.current.classList.add('animate');

                timeout();
              }

              if (profileView !== "cards") {
                if (
                  //listLoaderRef && listLoaderRef.current && 
                  listViewWrapperRef && listViewWrapperRef.current) {
                  let loaderText = listLoaderRef.current.querySelector('.loaderText');
                  if (item.label === "Nearest") {
                    loaderText.innerHTML = "Showing nearest jobseekers"
                  } else if (item.label === "Recently Active") {
                    loaderText.innerHTML = "Showing recently active jobseekers"
                  } else {
                    loaderText.innerHTML = "Showing most experienced jobseekers"
                  }
                  // listLoaderRef.current.classList.add('animate');
                  setIsListLoaderOn(prev => !prev);
                  listViewWrapperRef.current.classList.add('animate');
                  listLoaderTimeout();
                }
              }
              setPage(1);
              // page_global.page = 1;
              refine(item.value);
              setSortSelected(item.value);
              dividersCount.count = (dividersCount.count > 1000) ? 1 : (dividersCount.count + 1);
              //console.log("selected...." + item.value);
            }
            // setTimeout(() => {
            //   showRelevanceChangeDivider();
            // }, 1000);

          }}
          disabled={(filters.freshers || role.toLowerCase().trim() === "fresher") && item.label == "Most Experienced" ? true : false}
        // classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel_Sort, selected: classes.togBtnSelected_Sort, disabled: classes.togbtnDisabled }}
        >
          <Checkbox
            checked={(sortSelected === 'user_experience_and_qualification' && item.label === 'Nearest') ? true : (sortSelected === 'last_active_standard' && item.label === 'Recently Active') ? true : (sortSelected === 'months_of_exp_standard' && item.label === 'Most Experienced') ? true : false}
            // onChange={handleChange}
            size='small'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {item.label == "Nearest" ? "Nearest" : item.label == "Recently Active" ? "Recently Active" : "Most Experienced"}
        </MenuItem>
      ))}

    </Menu>
  );
  const CustomSortBy = connectSortBy(isMobile ? SortBy_new_mobile : SortBy_new);

  const [hitsPerPage, setHitsPerPage] = React.useState(isMobile ? 12 : 1);
  let pageNumber = 1

  const Results = connectStateResults(
    ({ searchState, searching, searchResults, children }) => {

      if (!searchResults) {
        setProfileView('no_results');
        setLocationValue("Anywhere/ Work-from-Home");
        //setFlagLocationText(true);
      }

      // no results found for the search parameters entered
      if (searchResults && searchResults.nbHits == 0) {

        setProfileView('no_results');
        setLocationValue("Anywhere/ Work-from-Home");
        switchtoRecentFromNearest(); // after location is changed to Anywhere, change sort order to recent from nearest
        setFlagLocationText(true);
        setFlagNoResults(true);
      }

      if (searchResults && (searchResults.nbHits > 0)) {
        if (location_value == "Anywhere/ Work-from-Home") {
          isMobile ? setProfileView('list') : setProfileView('cards');
          setLocationValue("");
          setFlagNoResults(false);
        }
      }
      if (searchResults && searchResults.nbHits) {
        setNbHits(searchResults.nbHits);
        // console.log(searchResults);
        // console.log(searchResults.facets[0] && searchResults.facets[0].data && searchResults.facets[0].data["Guest Service Associate Intern"])

        //console.log(searchResults.page);

        // if (searchResults.page > 0) {
        //   if(profileView === "list"){
        //     pageNumber = searchResults.page * 3;
        //     console.log("pageNumber in listview= " + pageNumber);
        //     console.log("searchResults.page in listview= " + searchResults.page);
        //   } 
        //   // else {
        //   //   pageNumber = searchResults.page * 3;
        //   //   console.log("pageNumber in card view= " + pageNumber);
        //   // }
        // } else if (searchResults.page === 0) { console.log(searchResults.page)
        //   pageNumber = 0
        // }

        // if (searchResults.page) {
        //   pageNumber = 1
        // }

      }

      // if(searchResults && searchResults.nbHits !== 0) {
      //   console.log("not searching"); setSearching(false);
      // } else if(searching){
      //   console.log("searching"); setSearching(true);
      // }

      // return searchResults && searchResults.nbHits !== 0 ? (
      //   children
      // ) : (
      //   <div>
      //     <No_Results
      //       searchRef={searchRef}
      //     />
      //   </div>
      // )

      return searchResults && searchResults.nbHits !== 0
        ? (children) // if results are not 0 then show the results
        : (searching ?
          // <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> 
          <div></div> // else if searching for results then show empty div
          //else show no results page
          : (<div>
            <No_Results
              searchRef={searchRef}
            />
          </div>)
        )
    }
  );

  const handleViewChange = (event, newProfileView) => {
    if (newProfileView == 'list') {
      setHitsPerPage(12);
      //setPage(1);
      handleSearchClickLoader();
      AnalyticsHandler.track("discoveryResults_View_list_btnClick");
    } else if (newProfileView == 'cards') {
      //console.log("clicked on card" + pageNumber)
      setHitsPerPage(1);
      //setPage(pageNumber);
      handleSearchClickLoader();
      //setAutoTriggerSearch(prev => !prev) // workaround added to run profile loader on click of card view
      AnalyticsHandler.track("discoveryResults_View_card_btnClick'");
    }

    if (newProfileView !== null) {
      setPage(1);
      setProfileView(newProfileView);
      dividersCount.count = (dividersCount.count > 1000) ? 1 : (dividersCount.count + 1);
    }
    if (newProfileView !== 'cards') {
      //setAutoTriggerSearch(prev => !prev) // dblcall workaround to activate loader instantly gives issue of skipping of first page results on first click of flist view
      setTimeout(() => {
        setAutoTriggerSearch(prev => !prev)
      }, 500)
    } // workaround that autotriggers the search btn click when switching to list view
  };

  var i, qualifications_id = [];

  if (qualifications)
    var first_qualification = qualifications[0];
  else
    var first_qualification = "";

  if (qualifications) {
    for (i = 0; i < qualifications.length; i++) {
      // qualifications_id[i] = `user_qualifications.qualification_id: ${qualifications[i].tag_id}<score=${qualifications.length - i}>`
      qualifications_id[i] = `user_qualifications.qualification_id: ${qualifications[i].tag_id}<score=${1}>`
    }

  }

  var j, secondary_roles = [];
  if (secondaryRoles) {
    for (j = 0; j < secondaryRoles.length; j++) {
      // secondary_roles[j] = `user_experiences.user_post: ${secondaryRoles[j].title} <score=${qualifications.length + 1}>`
      secondary_roles[j] = `user_experiences.user_post: ${secondaryRoles[j].title} <score=${2}>`
    }
  }
  selected_qualifications.secondaryRoles = secondaryRoles;
  //console.log(selected_qualifications.secondaryRoles);

  var k, jobseeker_entered_roles = [];

  if (jobseekerEnteredRoles) {
    for (k = 0; k < jobseekerEnteredRoles.length; k++) {
      jobseeker_entered_roles[k] = `user_experiences.job_role_display: ${jobseekerEnteredRoles[k]} <score=${2}>`
    }
  }

  selected_qualifications.jobseekerEnteredRoles = jobseekerEnteredRoles;
  //var preferred_qualifications_id = []

  // Loader messages on Search click starts
  const loaderMessageP1ToP2 = () => {
    //console.log(autoQualsFlag)
    if (role) {
      //changed this function to obtain location from URl parameters instead of from location.state
      let loc = match.params._location.split("-").join(" ").replaceAll("_", "/");
      let anywhere = "anywhere";
      if ((loc !== "" && loc !== anywhere) && qualifications.length === 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((loc !== "" && loc !== anywhere) && qualifications.length === 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and " + (qualifications[0].tag_abb ? qualifications[0].tag_abb : qualifications[0].tag_name) + " qualification around your location"
      } else if ((loc !== "" && loc !== anywhere) && qualifications.length > 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((loc !== "" && loc !== anywhere) && qualifications.length > 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and your chosen qualifications around your location"
      } else if ((loc === "" || loc === anywhere) && qualifications.length === 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience"
      } else if ((loc === "" || loc === anywhere) && qualifications.length === 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and " + (qualifications[0].tag_abb ? qualifications[0].tag_abb : qualifications[0].tag_name) + " qualification"
      } else if ((loc === "" || loc === anywhere) && qualifications.length > 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience"
      } else if ((loc === "" || loc === anywhere) && qualifications.length > 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and your chosen qualifications"
      } else if ((loc !== "" && loc !== anywhere) && qualifications.length === 0) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((loc === "" || loc === anywhere) && qualifications.length === 0) {
        return "Searching for Meeत users with " + role + " experience"
      }
    }
    else {
      return "Loading..."
    }
  }

  let loaderMessageOnPage2 = () => {
    // alert("role = " + role)
    if (role) {
      if ((lat !== null && long !== null) && qualifications.length === 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((lat !== null && long !== null) && qualifications.length === 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and " + (qualifications[0].tag_abb ? qualifications[0].tag_abb : qualifications[0].tag_name) + " qualification around your location"
      } else if ((lat !== null && long !== null) && qualifications.length > 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((lat !== null && long !== null) && qualifications.length > 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and your chosen qualifications around your location"
      } else if ((!lat && !long) && qualifications.length === 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience"
      } else if ((!lat && !long) && qualifications.length === 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and " + (qualifications[0].tag_abb ? qualifications[0].tag_abb : qualifications[0].tag_name) + " qualification"
      } else if ((!lat && !long) && qualifications.length > 1 && autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience"
      } else if ((!lat && !long) && qualifications.length > 1 && !autoQualsFlag) {
        return "Searching for Meeत users with " + role + " experience and your chosen qualifications"
      } else if ((lat && long) && qualifications.length === 0) {
        return "Searching for Meeत users with " + role + " experience around your location"
      } else if ((!lat && !long) && qualifications.length === 0) {
        return "Searching for Meeत users with " + role + " experience"
      }
    }
    else {
      return "Loading..."
    }
  }

  const [isCardLoaderOn, setIsCardLoaderOn] = React.useState(false);
  const [isListLoaderOn, setIsListLoaderOn] = React.useState(false);

  let handleSearchClickLoader = () => {
    if (profileView === "cards") {
      setIsListLoaderOn(prev => !prev);
      // let loaderText = listLoaderRef.current.querySelector('.loaderText');
      // loaderText.innerHTML = loaderMessageOnPage2();

      // listLoaderRef.current.classList.add('animate');
      listViewWrapperRef && listViewWrapperRef.current && listViewWrapperRef.current.classList.add('animate');
      listLoaderTimeout();
      //alert(1)
    }
    if (profileView === "list") { // when clicked on card view
      setIsCardLoaderOn(true);
      timeout();
    }
  }

  React.useEffect(() => {
    if (jobseekerEnteredRoles) setIsCardLoaderOn(false);
    else if (!jobseekerEnteredRoles) setIsCardLoaderOn(true);
  }, [jobseekerEnteredRoles]) // onload and whenever the jobseekerEnteredRoles are changed, run the loader to mask the flicker effect of card rerender due to async update

  React.useEffect(() => {
    handleSearchClickLoader();
  }, [loaderCountOnPg2Search.count])

  //This useEffect is run when flagSortNearest is changed from true to false using props from SearchBar. 
  //initialRenderNearest is used to make sure that it runs ONLY when dependency changes and NOT on first render

  React.useEffect(() => {
    if (initialRenderNearest.current) {
      initialRenderNearest.current = false;
    } else {
      ref_nearest_sort.current && ref_nearest_sort.current.click();
      initialRenderNearest.current = true;
      //this is done so that when flagSortNearest is reset to true in the next step and this useEffect is called again, the code in this scope doesnt run again
      setFlagSortNearest(true);
      //flagSortNearest is reset to true 
    }
  }, [flagSortNearest]);

  React.useEffect(() => {
    if (initialRenderRecentlyActive.current) {
      initialRenderRecentlyActive.current = false;
    } else {
      ref_last_active_sort.current && ref_last_active_sort.current.click();
      initialRenderRecentlyActive.current = true;
      setFlagSortRecentlyActive(true);
    }
  }, [flagSortRecentlyActive]);

  React.useEffect(() => {
    if (initialRenderMostExperienced.current) {
      initialRenderMostExperienced.current = false;
    } else {
      ref_most_exp_sort.current && ref_most_exp_sort.current.click();
      initialRenderMostExperienced.current = true;
      setFlagSortMostExperienced(true);
    }
  }, [flagSortMostExperienced]);

  // Loader messages on Search click ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  const [scrolling, setScrolling] = React.useState(false);
  const [scrollY, setScrollY] = React.useState(0);
  let lastScrollY = 0;
  let ticking = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      ticking = true
    } else {
      ticking = false
    }
    if (!ticking) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = 'transparent';
        pageBarRef.current.style.boxShadow = "none";
        pageBarRef.current.style.flexBasis = "65px";
      });
    } else if (ticking) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f9f9f9';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        pageBarRef.current.style.flexBasis = "65px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends

  let relevantToShowQualsMsg = (lat && long) ?
    (qualifications && (qualifications.length == 1) ?

      (autoQualsFlag ? `<p>Meeत users with relevant qualification around your location</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification around your location</p>`)
      :
      (autoQualsFlag ? "<p>Meeत users with relevant qualifications around your location</p>" : "<p>Meeत users with your chosen qualifications around your location</p>")

    )
    : (qualifications && (qualifications.length == 1) ?

      (autoQualsFlag ? `<p>Meeत users with relevant qualification</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification</p>`)
      :
      (autoQualsFlag ? "<p>Meeत users with relevant qualifications</p>" : "<p>Meeत users with your chosen qualifications</p>")
    )

  let relevantToShowRoleMsg = (lat && long) ? (`<p>Meeत users with <span>${role}</span> experience around your location</p>`) : (`<p>Meeत users with <span>${role}</span> experience</p>`)

  let relevantAllMsg = (lat && long) ? "<p>All other Meeत users around your location</p>" : "<p>All other Meeत users</p>"

  let relevantAllMsgForAnyRole = (lat && long) ? `<p>All Meeत users around your location</p>` : `<p>All Meeत users</p>`

  let relevantAllMsgForFresher = (lat && long) ? `<p>All Freshers around your location</p>` : `<p>All Freshers</p>`

  selected_qualifications.dividerMsgRoles = relevantToShowRoleMsg;
  selected_qualifications.dividerMsgQuals = relevantToShowQualsMsg;
  // if (role.toLowerCase().trim() !== "all" ) {
  //   selected_qualifications.dividerMsgAll = relevantAllMsg;
  // } else {
  //   selected_qualifications.dividerMsgAll = relevantAllMsgForAnyRole;
  // }
  if (!RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim())) {
    selected_qualifications.dividerMsgAll = relevantAllMsg;
  } else if (RolesNotToBePassedToAlgolia[0] === role.toLowerCase().trim()) {
    selected_qualifications.dividerMsgAll = relevantAllMsgForAnyRole;
  } else if (RolesNotToBePassedToAlgolia[1] === role.toLowerCase().trim()) {
    selected_qualifications.dividerMsgAll = relevantAllMsgForFresher;
  }

  const showRelevanceChangeDivider = () => {
    let el = document.querySelector('div[data-is-relevant-all=true');
    let elRel = document.querySelector('div[data-is-relevant=true]');
    let elRelQuals = document.querySelector('div[data-is-relevant-to-show-quals=true]');
    let elRelRole = document.querySelector('div[data-is-relevant-to-show-role=true]');

    if (elRel && elRel.querySelectorAll('.relevantTag').length === 0) {
      var node2 = document.createElement("DIV");
      node2.classList.add('relevantTag');
      var textnode2 =
        (lat && long) ?
          (qualifications && (qualifications.length == 1) ?
            (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualification around your location</p>` : `<p>Meeत users with <span>${role}</span> background and <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification around your location</p>`)
            :
            (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualifications around your location</p>` : `<p>Meeत users with <span>${role}</span> experience and <span>your chosen qualifications</span> around your location</p>`)
          )
          : (qualifications && (qualifications.length == 1) ?
            (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualification</p>`
              : `<p>Meeत users with <span>${role}</span> experience and <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification</p>`)
            :
            (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualifications</p>` : `<p>Meeत users with <span>${role}</span> background and your chosen qualifications</p>`)
          )
      elRel.prepend(node2);
      node2.innerHTML = textnode2;
    }

    if (elRelQuals && elRelQuals.querySelectorAll('.relevantTag').length === 0) {
      var node3 = document.createElement("DIV");
      node3.classList.add('relevantTag');
      var textnode3 = (lat && long) ?
        (qualifications && (qualifications.length == 1) ?

          (autoQualsFlag ? `<p>Meeत users with relevant qualification around your location</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification around your location</p>`)
          :
          (autoQualsFlag ? "<p>Meeत users with relevant qualifications around your location</p>" : "<p>Meeत users with your chosen qualifications around your location</p>")

        )
        : (qualifications && (qualifications.length == 1) ?

          (autoQualsFlag ? `<p>Meeत users with relevant qualification</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification</p>`)
          :
          (autoQualsFlag ? "<p>Meeत users with relevant qualifications</p>" : "<p>Meeत users with your chosen qualifications</p>")

        )
      elRelQuals.prepend(node3);
      node3.innerHTML = textnode3;
    }

    if (elRelRole && elRelRole.querySelectorAll('.relevantTag').length === 0) {
      var node4 = document.createElement("DIV");
      node4.classList.add('relevantTag');
      var textnode4 = (lat && long) ? (`<p>Meeत users with <span>${role}</span> experience around your location</p>`) : (`<p>Meeत users with <span>${role}</span> experience</p>`)
      elRelRole.prepend(node4);
      node4.innerHTML = textnode4;
    }

    if (el && el.querySelectorAll('.relevantTag').length === 0) {
      var node1 = document.createElement("DIV");
      node1.classList.add('relevantTag');
      var textnode1 = (lat && long) ? "<p>All other Meeत users around your location</p>" : "<p>All other Meeत users</p>"
      el.prepend(node1);
      node1.innerHTML = textnode1;
    }

    //pageNumber = document.querySelectorAll('.ais-InfiniteHits-item').length

    // if(qualifications && qualifications.length == 1){
    //   alert("state of qualification =" + first_qualification.tag_name)
    // }
    // if(role){
    //   alert("state of role = " + role)
    // }
    // if(lat && long){
    //   alert("state of location = " + lat + " " + long)
    // }
  }

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = (state) => {

    if (!state) {
      AnalyticsHandler.track('signIn_View_btnClick');
    }
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  React.useEffect(() => {
    let addRelevantTrueDivider = setTimeout(() => {
      let el = document.querySelector('div[data-is-relevant=true]');
      if (profileView !== 'cards' && el) {
        var node = document.createElement("DIV");
        node.classList.add('relevantTag');
        var textnode =
          (lat && long) ?
            (qualifications && (qualifications.length == 1) ?
              (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualification around your location</p>` : `<p>Meeत users with <span>${role}</span> background and <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification around your location</p>`)
              :
              (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualifications around your location</p>` : `<p>Meeत users with <span>${role}</span> experience and <span>your chosen qualifications</span> around your location</p>`)
            )
            : (qualifications && (qualifications.length == 1) ?
              (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualification</p>`
                : `<p>Meeत users with <span>${role}</span> experience and <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification</p>`)
              :
              (autoQualsFlag ? `<p>Meeत users with <span>${role}</span> experience and relevant qualifications</p>` : `<p>Meeत users with <span>${role}</span> background and your chosen qualifications</p>`)
            )
        el.prepend(node);
        node.innerHTML = textnode;
      }
    }, 2000);
    return () => clearTimeout(addRelevantTrueDivider);
  }, [profileView, dividersCount.count, refresh, isSignedIn, sortSelected, autoQualsFlag]);

  React.useEffect(() => {
    let addRelevantToShowQualsDivider = setTimeout(() => {
      let el = document.querySelector('div[data-is-relevant-to-show-quals=true]');
      if (profileView !== 'cards' && el) {
        var node = document.createElement("DIV");
        node.classList.add('relevantTag');
        var textnode = (lat && long) ?
          (qualifications && (qualifications.length == 1) ?

            (autoQualsFlag ? `<p>Meeत users with relevant qualification around your location</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification around your location</p>`)
            :
            (autoQualsFlag ? "<p>Meeत users with relevant qualifications around your location</p>" : "<p>Meeत users with your chosen qualifications around your location</p>")

          )
          : (qualifications && (qualifications.length == 1) ?

            (autoQualsFlag ? `<p>Meeत users with relevant qualification</p>` : `<p>Meeत users with <span>${first_qualification.tag_abb ? first_qualification.tag_abb : first_qualification.tag_name}</span> qualification</p>`)
            :
            (autoQualsFlag ? "<p>Meeत users with relevant qualifications</p>" : "<p>Meeत users with your chosen qualifications</p>")

          )
        el.prepend(node);
        node.innerHTML = textnode;
      }
    }, 2000);
    return () => clearTimeout(addRelevantToShowQualsDivider);
  }, [profileView, dividersCount.count, refresh, isSignedIn, sortSelected, autoQualsFlag, filters]);

  React.useEffect(() => {
    let addRelevantToShowRoleDivider = setTimeout(() => {
      let el = document.querySelector('div[data-is-relevant-to-show-role=true]');
      if (profileView !== 'cards' && el) {
        var node = document.createElement("DIV");
        node.classList.add('relevantTag');
        var textnode = (lat && long) ? (`<p>Meeत users with <span>${role}</span> experience around your location</p>`) : (`<p>Meeत users with <span>${role}</span> experience</p>`)
        el.prepend(node);
        node.innerHTML = textnode;
      }
    }, 2000);
    return () => clearTimeout(addRelevantToShowRoleDivider);
  }, [profileView, dividersCount.count, refresh, isSignedIn, sortSelected, filters]);

  React.useEffect(() => {
    let addRelevantAllDivider = setTimeout(() => {
      let el1 = document.querySelector('div[data-is-relevant=true]');
      let el2 = document.querySelector('div[data-is-relevant-to-show-quals=true]');
      let el3 = document.querySelector('div[data-is-relevant-to-show-role=true]');

      let el = document.querySelector('div[data-is-relevant-all=true]');

      let roleCaseFree = role.toLowerCase().trim();

      if (profileView !== 'cards' && el && !el1 && !el2 && !el3) {
        var node = document.createElement("DIV");
        node.classList.add('relevantTag');
        var textnode = (lat && long) ? "<p>We couldn't find what you were looking but get a sense of other job-seekers 👇</p>" : "<p>We couldn't find what you were looking but get a sense of other job-seekers 👇</p>"
        var textnodeForAny = (lat && long) ? `<p>All Meeत users around your location</p>` : "<p>All Meeत users</p>"
        var textnodeForFresher = (lat && long) ? `<p>All Freshers around your location</p>` : "<p>All Freshers</p>"
        // var textnodeForFresher = (lat && long) ? `<p>All Freshers around ${match.params._location}</p>` : "<p>All Freshers</p>"
        el.prepend(node);
        if (!RolesNotToBePassedToAlgolia.includes(roleCaseFree)) {
          node.innerHTML = textnode;
        } else if (RolesNotToBePassedToAlgolia[0] === roleCaseFree) {
          node.innerHTML = textnodeForAny;
        } else if (RolesNotToBePassedToAlgolia[1] === roleCaseFree) {
          node.innerHTML = textnodeForFresher;
        }

      } else if (profileView !== 'cards' && el) {
        var node = document.createElement("DIV");
        node.classList.add('relevantTag');
        var textnode = (lat && long) ? "<p>All other Meeत users around your location</p>" : "<p>All other Meeत users</p>"
        var textnodeForAny = (lat && long) ? `<p>All Meeत users around your location</p>` : "<p>All Meeत users</p>"
        var textnodeForFresher = (lat && long) ? `<p>All Freshers around your location</p>` : "<p>All Freshers</p>"
        el.prepend(node);
        if (!RolesNotToBePassedToAlgolia.includes(roleCaseFree)) {
          node.innerHTML = textnode;
        } else if (RolesNotToBePassedToAlgolia[0] === roleCaseFree) {
          node.innerHTML = textnodeForAny;
        } else if (RolesNotToBePassedToAlgolia[1] === roleCaseFree) {
          node.innerHTML = textnodeForFresher;
        }
      }
    }, 2000);
    return () => clearTimeout(addRelevantAllDivider);
  }, [profileView, dividersCount.count, refresh, isSignedIn, sortSelected, filters]);

  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false) {
      setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  const switchtoRecentFromNearest = () => {
    if (sortSelected === "user_experience_and_qualification") {
      setSortSelected("last_active_standard")
    }
  }

  const switchtoRecentFromMostExp = () => {
    if (sortSelected === "months_of_exp_standard") {
      setSortSelected("last_active_standard")
    }
  }

  const handleLocationForInvite = (newLocation) => {
    setLocationInvite(newLocation);
  }

  const sendLocationForInvite = () => location_invite !== "" && location_invite !== "anywhere" ? location_invite : "Anywhere/ Work-from-Home";

  React.useEffect(() => {
    globalRoleLocationInvite.search_role = role;
    globalRoleLocationInvite.search_location = sendLocationForInvite();
    localStorage.setItem('searched_role', role);
    localStorage.setItem('searched_location', sendLocationForInvite());
  }, [role, location_invite])

  // invite list api functionality starts
  const [inviteList, setInviteList] = React.useState([]);
  const [refreshInviteList, setRefreshInviteList] = React.useState(false);

  React.useEffect(() => {
    const handleInviteList = () => {
      let payload = {
        company_id: localStorage.getItem('company_id'),
        job_role: role,
      }
      //alert(payload.job_role)
      ApiHandler.getInviteList(payload, response => {
        if (response.status == 200) {
          //console.log(response.data);
          setInviteList(response.data);
        } else if (response.response.data.message === 'Session Expire') {
          console.log("session expired while getting invite list");
          removeLocalStorageOnSessionExpiry();
        } else {
          //console.log("error in getting a successful response for invited jobseeker profiles")
        }
      })
    }

    if (isSignedIn) {
      //alert(isSignedIn)
      handleInviteList();
      //console.log('invite list useeffect called')
    }
  }, [role, isSignedIn, profileView, sortSelected])

  //for card view
  React.useEffect(() => {
    //console.log('global invite list useeffect called')
    globalInviteList.invite_list = inviteList;
  }, [inviteList, role, isSignedIn, profileView, sortSelected])
  // invite list api functionality ends

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  // drawer context for card view starts
  const setCommand = (command) => {
    setState({ ...state, command: command })
  }

  const initCardDrawerState = {
    command: "",
    setCommand: setCommand
  }

  const [state, setState] = React.useState(initCardDrawerState)

  React.useLayoutEffect(() => {
    // if (state.command !== "") {
    //   handleDrawer();
    // }
    if (state.command === "openDrawer") {
      handleDrawer(state);
    }
    if (state.command === "removeLocalStorage") {
      removeLocalStorageOnSessionExpiry();
    }
    if (state.command === "refreshInviteList") {
      // setRefreshInviteList(prev => !prev);

      const handleInviteList1 = () => {
        let payload = {
          company_id: localStorage.getItem('company_id'),
          job_role: role,
        }
        //alert(payload.job_role)
        ApiHandler.getInviteList(payload, response => {
          if (response.status == 200) {
            //console.log(response.data);
            setInviteList(response.data);

          } else if (response.response.data.message === 'Session Expire') {
            console.log("session expired while getting invite list");
            removeLocalStorageOnSessionExpiry();
          } else {
            console.log("error in getting a successful response for invited jobseeker profiles")
          }
        })
      }

      handleInviteList1();

      console.log('invite list is refreshed')
    }
  }, [state])
  // drawer context for card view ends

  const [searchBarOpen, setSearchBarOpen] = React.useState(false)

  return (
    <StylesProvider injectFirst>
      <Helmet>
        <title>Hire candidates looking for various job roles with specific qualifications | Meetworks</title>
        <meta name='description' content={`Meet job portal has thousands of jobseekers from various regions, roles and sectors looking for verfied employers`} />
        <link rel='canonical' href={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content={`Jobseekers with ${role} experience from ${match.params._location}`} />
        <meta itemprop="description" content={`Meet job portal has thousands of jobseekers from various regions, roles and sectors looking for verfied employers`} />
        <meta itemprop="image" content={ogImg} />

        {/* Open graph / facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} />
        <meta property="og:title" content={`Hire candidates looking for various job roles with specific qualifications | Meetworks`} />
        <meta property="og:description" content={`Meet job portal has thousands of jobseekers from various regions, roles and sectors looking for verfied employers.`} />
        {/* <meta property="og:image" content='../../assets/Homepage.png' /> */}
        <meta property="og:image" content={ogImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} />
        <meta property="twitter:title" content={`Hire candidates looking for various job roles with specific qualifications | Meetworks`} />
        <meta property="twitter:description" content={`Meet job portal has thousands of jobseekers from various regions, roles and sectors looking for verfied employers.`} />
        <meta property="twitter:image" content={ogImg} />
      </Helmet>
      <div className={classes.root}>
        {/* {isLoading === true ?  */}
        <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader>
        {/* <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> */}
        {/* : */}
        <InstantSearch indexName="user_experience_and_qualification" searchClient={searchClient}>
          {/* <div style={{ position: 'absolute', top: 84, right: 10 }}><SearchBox /></div> */}

          <PrimaryAppBar
            isSignedIn={isSignedIn}
            setIsSignedIn={handleSignedInStatus}
            handleDrawer={handleDrawer}
            navValue={navValue}
            setNavValue={setNavValue}
            ref_last_active_sort={ref_last_active_sort}
            ref_nearest_sort={ref_nearest_sort}
            ref_most_exp_sort={ref_most_exp_sort}
            setFlagSortNearest={setFlagSortNearest}
            setFlagSortRecentlyActive={setFlagSortRecentlyActive}
            setFlagSortMostExperienced={setFlagSortMostExperienced}
          >

          </PrimaryAppBar>
          {isMobile && 
          <>
          <FormControl className='searchinput' style={{ width: '95%' }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              // value={searchQuery}
              onClick={e => {
                // setmobileSearchOpen(true)
                // setSearchBarOpen(true)
                document.querySelector('.mobileSearchBarSecondary').style.height = '280px';
                document.querySelector('.mobileSearchBarSecondary').style.transition = 'height 0.8s';
                document.querySelector('.searchinput').style.display = 'none';
              }}
              style={{ backgroundColor: '#fff', fontSize: '14px' }}
              placeholder='Search by job role, location or qualification'
              className={classes.searchinput}
              endAdornment={true ? <InputAdornment position="end">
                <ClearIcon style={{ cursor: 'pointer', color: '#797979' }}
                // onClick={() => {
                //   setSearchQuery('');
                //   setOpenJobRoles(postedJobs.filter(item => item.enable_status === true))
                //   setClosedJobRoles(postedJobs.filter(item => item.enable_status === false))
                // }}
                />
              </InputAdornment> : null}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
          </FormControl>
          <div style={{ height: '0px', overflow: 'auto', width: '100%', backgroundColor: '#fff', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}
            className='mobileSearchBarSecondary'
          >
            <ClearIcon style={{ marginRight: '10px' }} onClick={() => { document.querySelector('.mobileSearchBarSecondary').style.height = '0px'; document.querySelector('.mobileSearchBarSecondary').style.transition = 'height 0.2s'; document.querySelector('.searchinput').style.display = 'inherit'; }} />
            <div className={classes.mobileSearch}>
              {match.params._role ?  //changed location.state.role to role obtained from match parameters
                <SearchBar
                  role={role}       //changed location to location obtained from URl parameters instead of from location.search
                  loc={match.params._location !== "anywhere" ? match.params._location.split("-").join(" ").replaceAll("_", "/") : ""}
                  //quals={qualifications}
                  quals={location && location.state && location.state.flag_auto_qualifications ? [] : qualifications}
                  setRole={setRole}
                  setSecondaryRoles={setSecondaryRoles}
                  setJobseekerEnteredRoles={setJobseekerEnteredRoles}
                  setLocationRef={setLocationRef}
                  setSearchRef={setSearchRef}
                  setLatitude={setLatitude}
                  setLongitude={setLongitude}
                  setQualifications={setQualifications}
                  autoQualsFlag={autoQualsFlag}
                  setAutoQualsFlag={setAutoQualsFlag}
                  isAnyQualCatChecked={isAnyQualCatChecked}
                  flag_your_location_prev={flag_your_location}
                  location_value={location_value}
                  searchRef={searchRef}
                  key_autocomplete={key_autocomplete}
                  geographicalType={geographical_type}
                  setGeographicalType={setGeographicalType}
                  flag_location_text={flag_location_text}
                  setFlagLocationText={setFlagLocationText}
                  setPage={setPage}
                  //onClickForLoader={handleSearchClickCounter}
                  flag_no_results={flag_no_results}
                  setFlagNoResults={setFlagNoResults}
                  ref_last_active_sort={ref_last_active_sort}
                  ref_nearest_sort={ref_nearest_sort}
                  ref_most_exp_sort={ref_most_exp_sort}
                  setFlagSortNearest={setFlagSortNearest}
                  setFlagSortRecentlyActive={setFlagSortRecentlyActive}
                  setFlagSortMostExperienced={setFlagSortMostExperienced}
                  onWfh={switchtoRecentFromNearest}
                  switchtoRecentFromMostExp={switchtoRecentFromMostExp}
                  handleLocationForInvite={handleLocationForInvite}
                  autoTriggerSearch={autoTriggerSearch}
                  onLocationChange={newLocation => {
                    localStorage.setItem('searched_location', newLocation);
                  }}
                  setSearchBarOpen={setSearchBarOpen}
                  isSecondarySearchBar={true}
                >
                </SearchBar> : <SearchBar isSecondarySearchBar={true} />}
            </div>
          </div>
          </>
        }
          <div className={classes.desktopSearch}>
            {match.params._role ?  //changed location.state.role to role obtained from match parameters
              <SearchBar
                role={role}       //changed location to location obtained from URl parameters instead of from location.search
                loc={match.params._location !== "anywhere" ? match.params._location.split("-").join(" ").replaceAll("_", "/") : ""}
                //quals={qualifications}
                quals={location && location.state && location.state.flag_auto_qualifications ? [] : qualifications}
                setRole={setRole}
                setSecondaryRoles={setSecondaryRoles}
                setJobseekerEnteredRoles={setJobseekerEnteredRoles}
                setLocationRef={setLocationRef}
                setSearchRef={setSearchRef}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                setQualifications={setQualifications}
                autoQualsFlag={autoQualsFlag}
                setAutoQualsFlag={setAutoQualsFlag}
                isAnyQualCatChecked={isAnyQualCatChecked}
                flag_your_location_prev={flag_your_location}
                location_value={location_value}
                searchRef={searchRef}
                key_autocomplete={key_autocomplete}
                geographicalType={geographical_type}
                setGeographicalType={setGeographicalType}
                flag_location_text={flag_location_text}
                setFlagLocationText={setFlagLocationText}
                setPage={setPage}
                //onClickForLoader={handleSearchClickCounter}
                flag_no_results={flag_no_results}
                setFlagNoResults={setFlagNoResults}
                ref_last_active_sort={ref_last_active_sort}
                ref_nearest_sort={ref_nearest_sort}
                ref_most_exp_sort={ref_most_exp_sort}
                setFlagSortNearest={setFlagSortNearest}
                setFlagSortRecentlyActive={setFlagSortRecentlyActive}
                setFlagSortMostExperienced={setFlagSortMostExperienced}
                onWfh={switchtoRecentFromNearest}
                switchtoRecentFromMostExp={switchtoRecentFromMostExp}
                handleLocationForInvite={handleLocationForInvite}
                autoTriggerSearch={autoTriggerSearch}
                onLocationChange={newLocation => {
                  localStorage.setItem('searched_location', newLocation);
                }}
              >
              </SearchBar> : <SearchBar />}
          </div>
          <Grid container
            alignItems="center" wrap='nowrap' className={classes.pageBar} id="pageBar" ref={pageBarRef}>
            <Grid item container style={{ width: !isMobile ? '65%' : 'inherit' }}
              justifyContent={isMobile ? 'space-between' : 'flex-start'}
              alignItems="center" wrap='nowrap'>

              {/* <Typography variant="body2" className={classes.subtitle}>
                Filter by:
              </Typography> */}

              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                    checkedIcon={<CheckBoxIcon fontSize="medium" />}
                    checked={filters.freshers}
                    onChange={handleFilters}
                    name="freshers"
                    color="primary"
                    classes={{ root: classes.checkboxElement }}
                  />
                }
                style={{ boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' }}
                label="Freshers Only"
                classes={{ root: classes.checkboxControlRoot, label: classes.checkboxLabel }}
              />

              {/* <div className={classes.itemCount}>
                <Stats
                  translations={{
                    stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
                      return `${nbHits.toLocaleString()} `
                    },
                  }}
                /><span style={{paddingLeft: 5}}> Meeत Users</span>
              </div> */}
              <div className={classes.sortByContainer}>
                {isMobile && <Button variant="contained"
                  onClick={(event) => {
                    setMobileSortingAnchorEl(event.currentTarget);
                  }}
                  className={classes.subtitle}>
                  Sort by
                </Button>}
                <CustomSortBy
                  defaultRefinement={sortSelected ? sortSelected : ((lat && long) ? "user_experience_and_qualification" : "last_active_standard")}
                  items={
                    [
                      { value: 'user_experience_and_qualification', label: 'Nearest' },
                      { value: 'last_active_standard', label: 'Recently Active' },
                      { value: 'months_of_exp_standard', label: 'Most Experienced' }
                    ]
                  }
                />
              </div>
            </Grid>
            {!isMobile && <Grid item xs={4} container
              justify="flex-end"
              alignItems="center"
              ref={scrollRefPagination}
              wrap='nowrap'>
              <Typography variant="body2" className={classes.subtitle}>
                View:
              </Typography>
              <ToggleButtonGroup size="small" value={profileView} exclusive onChange={handleViewChange} style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px' }}>
                <ToggleButton id="view_card_btn_id" value="cards" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                  <RecentActorsIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> 1 by 1
                </ToggleButton>
                <ToggleButton id="view_list_btn_id" value="list" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                  <ListIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> All Results
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>}
          </Grid>

          {profileView === 'cards' ?
            <Configure
              //page={1}
              hitsPerPage={hitsPerPage}
              //hitsPerPage={1}
              //page={page}
              //facets={[`user_experiences.user_post`]}
              // facetFilters={[`total_months_exp:0`]}
              // facetFilters={[role.toLowerCase() === "fresher" ? `total_months_exp:0` : `''`]}
              // facetFilters={[role.toLowerCase().trim() === "fresher" || filters.freshers ? `total_months_exp:0` : `''`]} //if role is fresher or fresher filter is selected
              filters={(role.toLowerCase().trim() === "fresher" || filters.freshers ? `total_months_exp<6` : `total_months_exp=0 OR total_months_exp>0`)}
              aroundLatLng={(lat && long) ? `${lat}, ${long}` : `0,0`}
              aroundRadius={(lat && long) ? (geographical_type ? (geographical_type == 'country' ? 'all' : (geographical_type == 'administrative_area_level_1' ? '500000' : '200000')) : '200000') : 'all'}
              optionalFilters={[[...qualifications_id, `user_experiences.user_post: ${RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim()) ? '' : role} <score=${2}>`, ...secondary_roles, ...jobseeker_entered_roles]]}
            />
            :
            <Configure
              page={0}
              //offset={0}
              //hitsPerPage={12}
              hitsPerPage={hitsPerPage}
              // facetFilters={[role.toLowerCase().trim() === "fresher" || filters.freshers ? `total_months_exp:0` : `''`]} //if role is fresher or fresher filter is selected
              filters={(role.toLowerCase().trim() === "fresher" || filters.freshers ? `total_months_exp<6` : `total_months_exp=0 OR total_months_exp>0`)}
              aroundLatLng={(lat && long) ? `${lat}, ${long}` : `0,0`}
              aroundRadius={(lat && long) ? (geographical_type ? (geographical_type == 'country' ? 'all' : (geographical_type == 'administrative_area_level_1' ? '500000' : '200000')) : '200000') : 'all'}
              // optionalFilters={[...qualifications_id, `user_experiences.user_post: ${role} <score=${qualifications.length + 1}>`]}
              // optionalFilters={[[...qualifications_id], [`user_experiences.user_post: ${role} <score=${qualifications.length + 1}>`]]}
              // optionalFilters={[[...qualifications_id], [`user_experiences.user_post: ${role} <score=${qualifications.length + 2}>`, `user_experiences.user_post: welder <score=${qualifications.length + 1}>`]]}
              // optionalFilters={[[...qualifications_id], [`user_experiences.user_post: ${role} <score=${2}>`], [...secondary_roles]]} //with 4 categories wherein first is the role+ quals
              // optionalFilters={[[...qualifications_id, `user_experiences.user_post: ${role} <score=${2}>`, ...secondary_roles]]}
              // optionalFilters={[[...qualifications_id, `user_experiences.user_post: ${role} <score=${2}>`, ...secondary_roles, ...jobseeker_entered_roles]]}
              optionalFilters={[[...qualifications_id, `user_experiences.user_post: ${RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim()) ? '' : role} <score=${2}>`, ...secondary_roles, ...jobseeker_entered_roles]]}
            />
          }

          {/* {profileView === 'cards' && (
            <Grid container
              alignItems="center" className={classes.relevanceMsgBar}>
              <Grid item xs={12} container
                justify="center"
                alignItems="center">
                <Typography variant="body2" className={classes.subtitle} style={{ color: '#7c7c7c', fontSize: 15, fontWeight: 400 }}>
                  Meeत users with <span style={{ color: '#14967b', fontWeight: 500 }}>{role}</span>{ qualifications.length > 0 ? " and" : ""} {qualifications.length == 1 ?  qualifications[0].tag_name+ " qualification":( qualifications.length == 0? "": "chosen qualifications")}
                </Typography>
              </Grid>             
            </Grid>
          )} */}

          {/* <RefinementList attribute="user_experiences.user_post" /> */}
          {/* <RefinementList attribute="total_months_exp" /> */}

          <div className={profileView === 'cards' ? "flex-container-pagination" : "flex-container-pagination flex-container-pagination-list"}>
            <div data-classname="pagination"
              style={{ paddingBottom: profileView === 'cards' ? 0 : 30 }} //this is imp to init the infinite scroll
            >
              {profileView === 'cards' ?
                <div>
                  <Loader_Shuffle
                    setLoaderRef={setLoaderRef}
                    isCardLoaderOn={isCardLoaderOn}
                    loaderMessageOnPage2={loaderMessageOnPage2} />
                  <Results>
                    <DrawerContext.Provider value={state}>
                      <Hits hitComponent={CustomHits} />
                    </DrawerContext.Provider>
                    {/* <InfiniteHits_Card minHitsPerPage={1}
                      onFirstRelevance={showRelevanceChangeDivider}
                      role={role}
                      qualifications={qualifications}
                      job_role_id={job_role_id}
                    //onFirstRelevance={handleScrollCounter}
                    //  setStateDividersCount = {setStateDividersCount} 
                    /> */}
                  </Results>
                  {page <= nb_hits ?
                    <CardsStack
                      setCardStackRef={setCardStackRef}
                      isCardLoaderOn={isCardLoaderOn} />
                    : null}
                  <CustomPagination_wrapper
                    page={page}
                    setPage={setPage}
                    nb_hits={nb_hits}
                    searchRef={searchRef}
                    flag_location_text={flag_location_text}
                    setFlagLocationText={setFlagLocationText}
                    signInStatus={checkSignedInStatus}
                  />
                  {/* <Pagination
                    //page={10}
                    showFirst={false}
                    showLast={false}
                    padding={0}
                  /> */}
                </div> :
                (profileView === 'list' ?
                  //list view
                  <div>
                    <ListLoader
                      setListLoaderRef={setListLoaderRef}
                      isListLoaderOn={isListLoaderOn}
                      loaderMessageOnPage2={loaderMessageOnPage2} />
                    <div ref={listViewWrapperRef} className={classes.listViewWrapper}>
                      {/* <CustomScrollTo> */}
                      <Results>
                        <InfiniteHits minHitsPerPage={12}
                          onFirstRelevance={showRelevanceChangeDivider}
                          role={role}
                          secondaryRoles={secondaryRoles}
                          jobseekerEnteredRoles={jobseekerEnteredRoles}
                          qualifications={qualifications}
                          job_role_id={job_role_id}
                          searchRef={searchRef}
                          locationRef={locationRef}
                          signInStatus={checkSignedInStatus}
                          handleDrawer={handleDrawer}
                          removeLocalStorageOnSessionExpiry={removeLocalStorageOnSessionExpiry}
                          sendLocationForInvite={sendLocationForInvite}
                          inviteList={inviteList} //array
                          geographical_type={geographical_type}
                        //onFirstRelevance={handleScrollCounter}
                        //setStateDividersCount = {setStateDividersCount} 
                        />
                      </Results>
                      {/* </CustomScrollTo>
                    <Pagination /> */}
                    </div>
                  </div>
                  :
                  //no results page
                  <div>
                    <ListLoader setListLoaderRef={setListLoaderRef} />
                    <div ref={listViewWrapperRef} className={classes.listViewWrapper}>
                      <CustomScrollTo>
                        <Results>
                          <Hits hitComponent={No_Results_Hits} />
                        </Results>
                      </CustomScrollTo>
                      <Pagination />
                    </div>
                    {/* <CustomPagination_3_wrapper custom_ref={scrollRefPagination} /> */}
                  </div>

                )}
            </div>
          </div>
        </InstantSearch>
        {/* } */}

        <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>

      </div>
      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        //setIsSignedIn = {props.setIsSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />
    </StylesProvider>
  )
}