import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PrimaryAppBar from '../../components/JobAppBar';
import { StylesProvider } from "@material-ui/core/styles";
import '../../style/App.css';
import { useHistory } from "react-router-dom";
import global_isSignedIn from '../discovery/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import ApiHandler from '../../ApiHandler';
import JobOpenings from '../../components/JobOpenings';
import AnalyticsHandler from '../../AnalyticsHandler';
import { Helmet } from 'react-helmet-async';
import ogImg from '../../assets/og-img.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingTop: 84,
    paddingTop: 74,
    backgroundAttachment: 'fixed',
  },
  pageTitle: {
    color: '#5BC2A8',
    textAlign: 'center',
    marginBottom: '0.2em',
    fontWeight: 500,
  },
  pageBar: {
    //width: 860,
    width: 772,
    flexBasis: 65,
    //marginTop: 35,
    marginTop: 0,
    zIndex: 8,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px)',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80vh',
  },
  pageInfoText: {
    marginBottom: 20,
    color: "#555",
    lineHeight: '2em'
  },
  relevanceMsgBar: {
    width: 860,
    //flexBasis: 65,
    //marginTop: 25,
    zIndex: 8,
    padding: '0',
    marginLeft: 7,
    marginTop: 10,
    background: 'linear-gradient(89deg, rgb(255 255 255 / 0%) 0%, rgb(230 230 230 / 80%) 50%, rgba(255,255,255,0.1) 100%)',
  },
  subtitle: {
    lineHeight: '2em',
    color: '#797979',
    marginRight: 10,
    fontSize: 14,
    fontWeight: 500,
  },
  listViewWrapper: {
    opacity: 1,
    '&.animate': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
    }
  },
  headerButton: {
    textTransform: "none",
    width: 100,
    height: 35,
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  headerButton_sec: {
    backgroundColor: "transparent",
    color: "#14967B",
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
}));

export const DrawerContext = React.createContext({});

export default function Jobs(props) {
  const classes = useStyles();
  const [navValue, setNavValue] = React.useState(3);
  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  document.body.classList.remove("login");
  document.body.classList.add('wallpaper--icon');

  const openingsSection = (
    <div>
      {/* <Typography className={classes.pageInfoText} variant="h6">Jobs Page Coming Soon...</Typography> */}
      <JobOpenings />
    </div>

  )

  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false) {
      setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    AnalyticsHandler.track("SignIn_ViewBtn_OpeningPage")
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // drawer context for card view starts
  const setCommand = (command) => {
    setState({ ...state, command: command })
  }

  const initCardDrawerState = {
    command: "",
    setCommand: setCommand
  }

  const [state, setState] = React.useState(initCardDrawerState)

  React.useEffect(() => {
    if (state.command !== "") {
      handleDrawer();
    }
  }, [state])
  // drawer context for card view ends

  return (
    <>
      <Helmet>
        <title>Job opportunities posted by employers | Meetworks</title>
        <meta name='description' content='Find here job opportunities posted by verified employers against their open roles in various sectors, department and locations' />
        <meta name='keywords' content='verified job opportunities, jobs near me, work from home jobs, part time jobs' />
        <meta name="robots" content="noindex, nofollow" />
        <link rel='canonical' href='https://employer.meetworks.in/openings' />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content="Meet" />
        <meta itemprop="description" content="Find here job opportunities posted by verified employers against their open roles in various sectors, department and locations" />
        <meta itemprop="image" content={ogImg} />

        {/* Open graph / facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://employer.meetworks.in/" />
        <meta property="og:title" content="Job opportunities posted by employers | Meetworks" />
        <meta property="og:description" content="Find here job opportunities posted by verified employers against their open roles in various sectors, department and locations" />
        <meta property="og:image" content={ogImg}/>

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://employer.meetworks.in/" />
        <meta property="twitter:title" content="Job opportunities posted by employers | Meetworks" />
        <meta property="twitter:description" content="Find here job opportunities posted by verified employers against their open roles in various sectors, department and locations" />
        <meta property="twitter:image" content={ogImg} />
      </Helmet>
      <StylesProvider injectFirst>
        <div className={classes.root}>
          {/* <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> */}
          <PrimaryAppBar
            isSignedIn={isSignedIn}
            setIsSignedIn={handleSignedInStatus}
            handleDrawer={handleDrawer}
            navValue={navValue}
            setNavValue={setNavValue}
          >
          </PrimaryAppBar>
          {isSignedIn
            ? openingsSection
            : <div className={classes.pageInfo}>
              <Typography className={classes.pageInfoText} component='h1' variant="h6">Sign in to see all the latest openings.</Typography>
              <Button variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`} onClick={handleDrawer}>
                Sign In
              </Button>
            </div>
          }
          {/* {openingsSection} */}
        </div>
        <SignInUpDrawer
          canDrawerOpen={canDrawerOpen}
          refresh={refresh}
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          setForRefreshOnly={setForRefreshOnly}
          setCanDrawerOpen={setCanDrawerOpen}
          doRefresh={doRefresh}
        />
      </StylesProvider>
    </>
  )
}