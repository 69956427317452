var request = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

//var BASE_URL = "";

var Sign_in_URL = "/employer/login";
var Add_job_post_URL = "/jobposting/createjob";
var Get_job_post_URL = "/jobposting/getjobpost";
var Update_job_post_URL = "/jobposting/jobupdate";
var Get_company_info_URL = "/employer_profile/getcompanyinfo";
var Update_company_info_URL = "/employer_profile/companyupdate";
var Update_account_URL = "/employer_manageaccount/update_employer";
var Get_account_URL = "/employer_manageaccount/get_employer_account";
var Get_review_URL = "/company_rating_review/getreviews";
var Get_rating_URL = "/company_rating_review/getratings";
var Reset_password_URL = "/employer/resetpassword";
var Forgrt_password_URL = "/employer/forgetpassword";
var Logout_URL = "/employer/logout";
var Image_upload_URL = "/employer/image_upload";
var Get_locations_URL = "/select/get_locations";
var Get_job_roles_URL = "/select/get_job_roles";
var Get_employee_benefits_URL = "/select/get_employee_benefits";
var Signup_employee_URL = "/intercom/create_employer";
var GetJobCandidatesURL = "/jobposting/getjobcandidates/";
var UpdateCandidateJobStatusURL = "/jobposting/update_status_candidate";
var employerRegisterURL = "/employer/employer_signup";
var employerRegisterURL_new = "/employer/employer_signup_meetv2"
var SendOTPURL = "/users/send_otp";
var verifyEmployerOtpURL = "/employer/verify_employer_mobile";
var Get_company_names_URL = "/employer/companies_name_meetV2/";
var sendInviteURL = "/jobposting/employer_invite_jobseeker";
var Get_invite_status_URL = "/jobposting/verify_invite_jobseeker";
var getInviteListURL = "/jobposting/invite_jobseeker_list";
var getInvitedPageListURL = "/jobposting/invited_names_invite_page";
var getInvitedPageProfileListURL = "/jobposting/invited_jobseeker_profile";
var sendShortlistURL = "/jobposting/shortlist_jobseeker_toggle";
var sendHiredURL = "/jobposting/hire_jobseeker";
var getNotificationsURL = "/notifications/get_employer_notification";

var ApiHandler = {

  signIn: function (email, password, cb) {
    request.post(BASE_URL + Sign_in_URL, { email: email, password: password }, { withCredentials: true })
      .then(res => {
        return res
      })
      .catch(function (response) {
        return response
      })
      .then(cb)
  },
  createJobPost(data, cb) {
    request.post(BASE_URL + Add_job_post_URL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getAllNotifications(data, cb) {
    request.post(BASE_URL + getNotificationsURL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getJobPost(cb) {
    request.get(BASE_URL + Get_job_post_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  updateJobPost(data, cb) {
    request.put(BASE_URL + Update_job_post_URL, data)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getCompanyInfo(cb) {
    request.get(BASE_URL + Get_company_info_URL)
      .then(response => {
        return response
      })
      .catch(error => {
        return error

      })
      .then(cb)
  },
  updateCompanyInfo(data, cb) {
    request.put(BASE_URL + Update_company_info_URL, data)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getReview(cb) {
    request.get(BASE_URL + Get_review_URL)
      .then(response => {

        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getRating(cb) {
    request.get(BASE_URL + Get_rating_URL)
      .then(response => {

        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  updateAccountInfo(data, cb) {
    request.post(BASE_URL + Update_account_URL, data)
      .then(response => {

        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getAccount(cb) {
    request.get(BASE_URL + Get_account_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  resetPassword(data) {
    return request.post(BASE_URL + Reset_password_URL, data)
  },
  forgetPassword(data, cb) {
    request.post(BASE_URL + Forgrt_password_URL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  logout(cb) {
    request.post(BASE_URL + Logout_URL, {}, { withCredentials: true })
      .then(response => {
        return response

      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  imageUpload(data, cb) {
    const config = {
      headers: {

        'content-type': 'multipart/form-data'
      }
    }
    request.post(BASE_URL + Image_upload_URL, data, config)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response

      })
      .then(cb)
  },
  getLocation(cb) {
    request.get(BASE_URL + Get_locations_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getEmployeeBenefits(cb) {
    request.get(BASE_URL + Get_employee_benefits_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getJobRoles(cb) {
    request.get(BASE_URL + Get_job_roles_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  signupEmployer(data, cb) {
    request.post(BASE_URL + Signup_employee_URL, data)
      .then(res1 => {
        return res1
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getjobCandidates(jobid, offset, cb) {
    request.get(BASE_URL + GetJobCandidatesURL + jobid + '/' + offset)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  updatejobStatusCandidate(data, cb) {
    request.post(BASE_URL + UpdateCandidateJobStatusURL, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  employerRegister(data, cb) {
    console.log('URL', BASE_URL + employerRegisterURL_new);
    request.post(BASE_URL + employerRegisterURL_new, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  employerSendOTP(data, cb) {
    request.post(BASE_URL + SendOTPURL, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  verifyEmployerOTP(data, cb) {
    request.post(BASE_URL + verifyEmployerOtpURL, data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getCompanyNames(cb) {
    request.get(BASE_URL + Get_company_names_URL)
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  sendInvite(data, cb) {
    request.post(BASE_URL + sendInviteURL, data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getInviteList(data, cb) {
    request.post(BASE_URL + getInviteListURL, data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getInvitedJobRoles(companyId, jobseekerId, cb) {
    request.get(BASE_URL + '/jobposting/invite_jobroles/' + companyId + "/" + jobseekerId )
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getInvitedPageList(data, cb) {
    request.post(BASE_URL + getInvitedPageListURL, data, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getInvitedPageProfileList(data, cb) {
    request.post(BASE_URL + getInvitedPageProfileListURL, data, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(response => {
        return response
      })
      .then(cb)
  },
  getInvitedProfilesList(data, cb) {
    request.post(BASE_URL + "/jobposting/v2/invited_jobseeker_profile/", data, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getInvitedProfileExp(data, cb) {
    request.post(BASE_URL + "/users/experience/", data, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  getInvitedProfileQuals(data, cb) {
    request.post(BASE_URL + "/users/qualification/", data, { withCredentials: true })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
      .then(cb)
  },
  shortlistToggle(data, cb) {
    request.put(BASE_URL + sendShortlistURL, data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  sendHired(data, cb) {
    request.put(BASE_URL + sendHiredURL, data, { withCredentials: true })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getUniqueJobseeker(data, cb) {
    request.post(BASE_URL + '/users/get_unique_jobseeker_profile', data)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getTopLocationsAnalytics(cb) {
    request.get(BASE_URL + '/select/analytics_top_locations')
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getTopRolesAnalytics(cb) {
    request.get(BASE_URL + '/select/analytics_top_roles')
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  },
  getTopQualsAnalytics(cb) {
    request.get(BASE_URL + '/select/analytics_top_qual')
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      })
      .then(cb)
  }

  // getInviteStatus(params, cb) {
  //   request.get(BASE_URL + Get_invite_status_URL + ', ' + params)
  //   .then(response => {
  //       return response
  //   })
  // .catch(response=> {
  //     return response
  // })
  // .then(cb)
  // }, 
  // getInviteStatus(body_data, cb) {
  //   const options = {
  //     method: 'post',
  //     url : BASE_URL + Get_invite_status_URL,
  //     data : body_data  
  // }            
  // var {data} = await axios(options)
  // },

}
module.exports = ApiHandler;
