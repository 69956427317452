import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import ApiHandler from '../../ApiHandler';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

import PrimaryAppBar from '../../components/JobAppBar';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "auto",
    marginTop: 7,
    padding: '10px',
    borderRadius: 6,
    display: 'flex',
    // flexDirection: 'column',
    background: 'white',
    alignItems: "flex-start",
    width: '95vw',
    boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #55c6a9'
  },
  paper2: {
    margin: "auto",
    marginTop: 7,
    padding: '10px',
    borderRadius: 6,
    // display: 'flex',
    // flexDirection: 'column',
    background: 'white',
    alignItems: "flex-start",
    width: '95vw',
    boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.1)',
    border: '1px solid #55c6a9'
  },
  whiteBackground: {
    backgroundColor: "white",
    width: 160
  },
  // imageWrapper: {
  //   position: "absolute",
  //   margin: "auto",
  //   left: 0,
  //   right: 0,
  //   top: 10
  // },
  imageWrapper: {
    // margin: 'auto',
    width: 100,
    height: 100,
    padding: 0,
    borderRight: '1px solid #AFAFAF',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>img': {
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: 'none',
      display: 'block',
      width: 'auto',
      height: '100%',
      borderRadius: '6px',
      maxWidth: 'unset',
    },
  },
  imgPholder: {
    width: '100%',
    margin: "auto",
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: 100,
      height: 100,
    }
  },
  headerButton: {
    textTransform: "none",
    width: 150,
    height: 42.56,
  },
  headerButton_short: {
    textTransform: "none",
    width: 100,
    height: 42.56,
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    }
  },
  card__LogoWrapper_reg: {
    width: 70,
    height: 70,
    borderRadius: 0,
    overflow: 'hidden',
    marginRight: 0,
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    // background: '#5bc2a873',
    '&>img': {
      //margin: 'auto',
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
    },
  },
  chiproot: {
    marginLeft: 10,
    borderRadius: 4,
    height: 22,
    backgroundColor: "#5BC2A8",
    color: "white",
    textTransform: "none"
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
    textTransform: "none",
    lineHeight: 22,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  listItems: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function Mobile(props) {

  const classes = useStyles();
  const [jobseekerInfo, setJobseekerInfo] = React.useState(null);
  const imgPholderRef = useRef(null);
  const righSectionRef = useRef(null);
  const history = useHistory();

  React.useEffect(() => {
    var arrID = props.match.match.params._id.split('-');
    let job_id = "";
    if (arrID.length - 1 >= 0) {
      job_id = arrID[arrID.length - 1]
    }

    let payload = {
      jobseeker_id: job_id
    }

    ApiHandler.getUniqueJobseeker(payload, response => {
      if (response.status == 200) {
        setJobseekerInfo(response.data[0]);
      } else {
        if (response.response) {
          let statusCode = response.response.status;
          let message = response.response.data.message;

          if (statusCode === 404) {
            if (message === "User not found") {
              history.push('/error'); // 404 error page
            }
          }
        } else {
          history.push('/error'); // 404 error page
        }
      }
    })
  }, []);

  React.useEffect(() => {
    if (imgPholderRef && imgPholderRef.current && righSectionRef && righSectionRef.current) {
      righSectionRef.current.style.opacity = 1
    }
  }, [jobseekerInfo])
  //have changed this useEffect so that it is NOT called only once like in Profile_Cards_2 file.

  const handleImageLoad = (event) => {
    let imageWidth = event.target.clientWidth;
    let imageHeight = event.target.clientHeight;
    // if(imageWidth/imageHeight >=4) {
    //   event.target.style.opacity = 0.2;
    // }
    if (imageWidth / imageHeight >= 1.5) {
      event.target.closest("div").style.padding = '0';
    }
  }

  const handleProfileCardImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 0.75) {
      event.target.style.maxWidth = imageWidth + "px"
    } else if (imageWidth / imageHeight >= 1) {
      event.target.closest("#imageWrapper").style.justifyContent = "center";
    }
    event.target.style.opacity = 1
    // event.target.closest("#leftSectionCardId").style.backgroundColor = "rgba(0,0,0,0)";
    // event.target.closest("#leftSectionCardId").nextSibling.style.opacity = 1;
  }

  var quals_raw_data = []

  if (jobseekerInfo) {
    var qualifications_sorted = jobseekerInfo.user_qualifications ? jobseekerInfo.user_qualifications.sort((a, b) => new Date(b.user_passing_year) - new Date(a.user_passing_year)) : null;
    if (qualifications_sorted)
      //keeps all records that have course_name and user_passing_year
      quals_raw_data = qualifications_sorted.filter((item, i, array) =>
        array.findIndex(t => t.course_name && t.user_passing_year && item.course_name && item.user_passing_year && t.course_name == item.course_name && t.user_passing_year == item.user_passing_year) == i
      );

    //var quals_raw_data = qualifications_sorted;

    if (qualifications_sorted)
      //keeps the records that have id
      qualifications_sorted = qualifications_sorted.filter((item, i, array) =>
        array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
      );

    var years_to_display = (jobseekerInfo.total_months_exp / 12).toFixed(0) == 1 ? " yr " : " yrs ";
    var months_to_display = jobseekerInfo.total_months_exp % 12 == 1 ? " mon " : " mons  ";

    var last_active = moment(new Date()).diff(moment(new Date(jobseekerInfo.last_seen_at * 1000)), 'days');

    var total_years = Math.trunc(jobseekerInfo.total_months_exp / 12)
    var total_months = jobseekerInfo.total_months_exp % 12;
    var fresher = false;
    var less_than_month = jobseekerInfo.total_months_exp > 0 && jobseekerInfo.total_months_exp < 1 ? true : false;

    if ((total_years == 0 && total_months == 0) || jobseekerInfo.user_experiences == [] || jobseekerInfo.user_experiences == [{}] || jobseekerInfo.total_months_exp == null || jobseekerInfo.total_months_exp == 0)
      fresher = true;

    var experiences_sorted = jobseekerInfo.user_experiences && jobseekerInfo.user_experiences.length != 0 ? jobseekerInfo.user_experiences.sort((a, b) => {

      if (a == {} || b == {})
        return 0;
      if (a.company_ending_date === b.company_ending_date)
        return 0;
      else
        if (a.company_ending_date === 'Present') {
          return (new Date(b.company_ending_date) - new Date())
        }
        else
          if (b.company_ending_date === 'Present') {
            return (new Date() - new Date(a.company_ending_date))
          }
          else {
            return (new Date(b.company_ending_date) - new Date(a.company_ending_date))
          }
    }) : null;

    experiences_sorted = experiences_sorted
      && experiences_sorted.filter((item, i, array) =>
        array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
      )

    var full_name = jobseekerInfo.jobseeker_name;

    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
      initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var array_name = jobseekerInfo.jobseeker_name.split(" ");
    var k = 0, name_sentence_case = "";
    for (; k < array_name.length; k++) {
      if (array_name[k])
        name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
    }
  }

  var bg_colors = ["#B9E4D9", "#FDEBB4", "#FFCDD3", "#E4BDDB"]
  var text_colors = ["#5BC2A8", "#FCD450", "#EF9A9B", "#C361B1"]

  return (
    <StylesProvider injectFirst>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>

        <PrimaryAppBar isFullProfilePage={true}> </PrimaryAppBar>

        <Paper className={classes.paper2} style={{ marginTop: 55 }}>
          {/* jobseeker Image */}
          <div style={{ display: "flex", width: '100%', justifyContent: "flex-start", alignItems: "center", marginBottom: '1em' }}>
            <Typography variant="subtitle1" style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 16, color: '#737373', textTransform: 'capitalize', letterSpacing: '0.1em' }}>Personal Information</Typography>
          </div>
          <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <div className={classes.imageWrapper} id="imageWrapper">
              {jobseekerInfo && jobseekerInfo.user_image_url ?
                <img alt="candidates profile picture" src={jobseekerInfo.user_image_url} onLoad={handleProfileCardImage} style={{ opacity: 0 }} />
                :
                <div className={classes.imgPholder} style={{ backgroundColor: `${bg_colors[0]}`, borderRadius: "6px" }}>
                  <p style={{ fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 100, height: 100, lineHeight: '100px', textAlign: 'center', borderRadius: '50%', color: `${text_colors[0]}` }}> {initials} </p>
                </div>
              }
            </div>

            {/* Name and location */}
            <div style={{ margin: "0px auto" }}>
              {jobseekerInfo ?
                <Typography variant="h6" align="center" >
                  {name_sentence_case}
                </Typography>
                : null}
              {jobseekerInfo ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                  <span style={{ fontSize: 16, color: '#ccc', height: 20 }}>
                    {(jobseekerInfo.area || jobseekerInfo.city || jobseekerInfo.distric) ?
                      <LocationOnRoundedIcon style={{ color: '#5bc2a8', fontSize: 20 }} /> : null}
                  </span>
                  <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 3 }}>
                    {jobseekerInfo.area && jobseekerInfo.city ? jobseekerInfo.area + ', ' + jobseekerInfo.city : (jobseekerInfo.city && jobseekerInfo.distric ? jobseekerInfo.city + ', ' + jobseekerInfo.distric : (jobseekerInfo.area && jobseekerInfo.distric ? jobseekerInfo.area + ', ' + jobseekerInfo.distric : (jobseekerInfo.city ? jobseekerInfo.city : (jobseekerInfo.distric ? jobseekerInfo.distric : (jobseekerInfo.area ? jobseekerInfo.area : "")))))}
                  </span>
                </div>
                : null}
            </div>
          </Grid>
        </Paper>

        {/* Experience */}
        {jobseekerInfo ?
          <Paper className={classes.paper2} style={{ rowGap: 5 }}>
            <div style={{ display: "flex", width: '100%', justifyContent: "flex-start", alignItems: "center" }}>
              {/* <WorkIcon fontSize="small" className="primaryText" /> */}
              <Typography variant="subtitle1" style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 16, color: '#737373', textTransform: 'capitalize', letterSpacing: '0.1em', marginBottom: '0.5em' }}>Experience</Typography>
              {/* <Chip size="small" label={(fresher ? 'Fresher' : less_than_month ? " < 1 month Exp." : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) + "Exp." : (total_months + months_to_display) + "Exp.") : (total_years + years_to_display + total_months + months_to_display) + "Exp."))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} /> */}
            </div>

            {experiences_sorted && experiences_sorted.map((item, i) => (
              <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" key={i} style={{ margin: '1em 0' }}>
                {/* <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{
                  maxWidth: 20,
                  marginRight: 60,
                  marginTop: 8,
                }}>
                  <div className={classes.card__LogoWrapper_reg}>
                    {item.company_logo ? <img className={classes.img} alt="" src={item.company_logo ? item.company_logo : null}
                      onLoad={handleImageLoad} /> : ""}
                  </div>
                </Grid> */}
                <ul style={{ paddingLeft: '1em', margin: 0, width: 'inherit' }}>
                  <li>
                    {/* <div className={classes.listItems}> */}
                    <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="subtitle1" style={{ marginLeft: 0, fontWeight: 500 }}>
                        {item.user_post ? item.user_post : ""}
                      </Typography>
                      <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 13 }}>
                        {item.company_name ? item.company_name : ""}
                      </Typography>

                      <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 13, color: '#999' }}>
                        {item.company_starting_date && item.company_ending_date && item.company_ending_date == "Present" ? months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + "Present" : ""}

                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " - " + months[new Date(item.company_ending_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}
                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}
                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}
                      </Typography>

                      <Typography variant="subtitle1"
                        style={{
                          marginLeft: 0, marginTop: 5, fontSize: 13,
                          // fontStyle: 'italic' 
                        }}>
                        {item.role_discription ? item.role_discription : ""}
                      </Typography>
                    </Grid>

                    {/* </div> */}
                  </li>
                </ul>
              </Grid>))}
          </Paper>
          : null}

        {/* Education */}
        {jobseekerInfo ?
          <Paper className={classes.paper2}  >
            <div style={{ display: "flex", width: '100%', justifyContent: "flex-start", alignItems: "center", marginBottom: 8 }}>
              {/* <SchoolRoundedIcon fontSize="small" className="primaryText" /> */}
              <Typography variant="subtitle1" style={{ marginLeft: 5, fontWeight: 'bold', fontSize: 16, color: '#737373', textTransform: 'capitalize', letterSpacing: '0.1em' }}>Education</Typography>
            </div>
            <div style={{ display: "flex", rowGap: 10, flexDirection: "column", alignItems: "flex-start" }}>
              {
                quals_raw_data && quals_raw_data.map((item, i) => (
                  <ul style={{ paddingLeft: '1em', margin: 0 }}>
                    <li>
                      <div key={i}>
                        <Typography variant="subtitle1" style={{ fontWeight: 500, lineHeight: 1.5 }}>
                          {(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : (item.course_name ? item.course_name : null))}{(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}
                          {/* {!item.user_college ? " | " + item.user_passing_year : ""} */}
                          {item.user_passing_year ?
                            <span style={{ fontSize: 13 }}> {" | " + item.user_passing_year} </span> : null}
                        </Typography>

                        <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                          {(item.user_college ? item.user_college : null)}
                        </Typography>
                        {/* <Typography variant="subtitle1" style={{ fontSize: 13, color: '#999' }}>
                      {item.user_passing_year ? item.user_passing_year : null}
                    </Typography> */}
                      </div></li>
                  </ul>))}
            </div>
          </Paper>
          : null}

        {/* Footer */}
        <div style={{ marginTop: 7, backgroundColor: "white", width: "100%", height: "330px", borderTop: "1px solid #14967B" }}>

          <h3 style={{ textAlign: "center", marginTop: 20 }}> Explore Meet!</h3>

          <div style={{ display: "flex", flexDirection: "column", rowGap: 5, margin: "auto", left: 0, right: 0, font: "Roboto", fontSize: 15, color: "#797979" }}>

            <span style={{ marginTop: 0, marginBottom: 0, textAlign: "center" }}>
              <a href="https://api.whatsapp.com/send?phone=919580899412&text=My%20Website" style={{ color: "#14967B", fontFamily: '"Roboto", "Mukta", "Arial", "sans-serif"' }}>अपनी personal career website बनाएं for free</a>
            </span>

            <span style={{ marginTop: 15, marginBottom: 0, textAlign: "center" }} >
              <Typography style={{ margin: "auto" }}> Want to start your career? </Typography>
            </span>

            <Button
              variant="contained" className={`${classes.headerButton_short} ${classes.headerButton_pri}`} style={{ margin: "auto", marginBottom: 10 }}
              onClick={() => window.location.href = "https://findwork.meetworks.in"}>
              Find jobs
            </Button>

            <hr style={{ color: "grey", width: "10%" }} />

            <span style={{ marginTop: 0, marginBottom: 0, textAlign: "center" }} >
              <Typography style={{ margin: "auto" }}> Looking to hire? </Typography>
            </span>

            <Button
              variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`} style={{ margin: "auto", marginBottom: 10 }}
              onClick={() => window.location.href = "https://employer.meetworks.in"}>
              Find jobseekers
            </Button>
          </div>

        </div>
      </div>
    </StylesProvider>
  )
}
