var selected_qualifications =
{
    quals : [],
    role : "",
    secondaryRoles: [],
    jobseekerEnteredRoles: [],
    dividerMsgRoles: "",
    dividerMsgQuals: "",
    dividerMsgAll: "",
    job_role_id: null,
    preferredQualsArray: []
}

export default selected_qualifications;