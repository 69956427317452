import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  flexGrow1: {
    flexGrow: 1,
  },
  height100: {
    height: '100%',
  },
  TfRoot: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: 20,
    display: 'flex',
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
    },
    '& fieldset': {
      border: '1px solid #808080',
    },
  },
  primaryBtn: {
    width: '300px',
    height: 36,
    marginBottom: 15,
    backgroundColor: '#14967B',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#14967B',
    },
  },
  logo: {
    width: '100px',
    marginBottom: '38px',
  },
  imgBox: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  resetPwdContainer: {
    '@media (max-width: 600px)': {
      margin: '32px !important'
    }
  }
}));
