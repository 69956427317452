import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Laptop from './laptop.jsx';
import Mobile from './mobile.jsx';

export default function profile(match) {

  document.body.classList.add('wallpaper--icon');

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-width: 1025px)",
  });

  return (
  <div>
  {isMobileDevice && <Mobile match={match}/>}
  {isLaptop && <Laptop match={match}/>}
  </div>
  );
}
