/* eslint-disable no-use-before-define */
import React, { useRef, useEffect, useState } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
//import { SettingsInputAntennaTwoTone, VideoCallOutlined } from '@material-ui/icons';
import {  connectRefinementList } from 'react-instantsearch-dom';

const Input = styled('input')`
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.6);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.6);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0, 0, 0, 0.6);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(0, 0, 0, 0.6);
}
`;

const Label = styled('label')`
  padding: 0 0 4px;
  font-size: 0.75em;
  display: block;
  color: black;
  font-weight: 700;
  &.focused {
    color: #5BC2A8;
    font-weight: 700;
  }
`;

const InputWrapper = styled('div')`
  flex: 1;
  // height: 30px;
  font-size: 14px;
  border-color: transparent!important;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  max-height: 30px;
  overflow: hidden;

  &:hover {
    border-color: transparent!important;
  }

  &.focused {
    border-color: transparent!important;
    box-shadow: none;
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 10px 0;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    &.inputfocused {
      width: 331px;
      height: 30px;
      background: #F5F5F5;
      border-radius: 25px;
      padding: 9px 10px 9px 0;
      left: 25%;
      top: 326px;
      position: absolute;
    }
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props} className="value-tags" style={{
    padding: '2px 4px',
    border: '1px solid #cacaca',
    borderRadius: 2,
    display: 'flex',
    height: 21,
    marginTop: 3
  }}>
    <span>{label}</span>
    {/* <CloseIcon onClick={
      (e) => {
      var dataTag = e.target.closest('div').getAttribute("data-tag-name ");
      document.querySelector('input[name="' + dataTag + '"]').checked = false;
      document.querySelector('input[name="' + dataTag + '"]').click(); 
      e.target.closest('div').remove();

    }

    } /> */}
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('div')`
  width: 900px;
  display: flex;
  flex-direction: column;
  margin: 2px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 16px;
  // transform: translate3d(315px, 298px, 0)!important;
  transform: translate3d(315px, 77%, 0)!important;
  list-style: none;
  background-color: #fff;
  overflow: hidden;
  max-height: 390px;
  height: 390px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #000000 25%;
  z-index: 1;
  & .header {
    background: white; 
    width: 100%; 
    height: 50px; 
    border: 0.5px solid #E0E0E0;
    padding: 10px 30px;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    & .searchbox {
      width: 331px;
      height: 30px;
      background: #F5F5F5;
      border-radius: 25px;
      display: flex;
      justify-content: flex-start;
      align-item: center;
      padding: 2px;
      & .searchIcon {
        color: #6B6B6B;
        font-size: 15px;
        padding: 4px 10px;
      }
      & input {
        background: #F5F5F5; 
        border: 0;
      }
    }
    & .header__items span{
      padding: 0 0 0 30px;
      font-size: 12px;
      color: #6B6B6B;
      cursor: pointer;
      &:hover{
        color: #000000;
      }
    }
  }

  & > div > ul {display: flex; padding-left: 30px}
  & ul {list-style: none}
// column
  & > div > ul > li {
    padding: 0 5px;
    margin: 0; 
    & .columnTitle{
      margin-bottom: 10px;
      font-weight: 700;
      color: #000000;
    }
    & > div > ul {
      padding: 0;
      margin: 0; 
      display: inline-grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(6, auto);
      height: 80px;
      & > li {
        width: 300px;
        display: flex;
        align-items: flex-start;
        padding: 8px 0;
        & > span:first-child{
          padding: 0
        }
        & > span:nth-child(2) {
          padding-top: 0;
          line-height: 1.5em;
        }
      }
    }
  }
  & li{
    & svg {
      color: #E2E2E2;
    }
  }

  & li[custom_tag= 'true'] {
    background-color: #fafafa;
    // font-weight: 600;

    & svg {
      color: #5BC2A8;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    // & svg {
    //   color: #E2E2E2;
    // }
  }
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <StopRoundedIcon style={{ color: "#5BC2A8" }} fontSize="small" />;

 function CustomRefinementListQualifications({ items, refine }) {
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (inputValue.length >= 0) {
      setOpen(true);
      setPholder('Search for qualifications');
      if (document.querySelector('.value-tags')) {
        document.querySelector('.outerPholder').innerHTML = "";
      } else if (!document.querySelector('.value-tags')) {
        document.querySelector('.outerPholder').innerHTML = "Select Educational Backgrounds";
      }
      document.querySelector("input[data-name='course-input']").classList.add("inputfocused");
    }
  };
  const handleClose = () => {
    setOpen(false);
    setPholder('');
    if (document.querySelector('.value-tags')) {
      setPholder('');
    } else if (!document.querySelector('.value-tags')) {
      setPholder('Select Educational Backgrounds');
    }
    document.querySelector(".inputfocused").blur();
    document.querySelector("input[data-name='course-input']").classList.remove("inputfocused");

  };

  // const handleInputChange = (event, newInputValue) => {
  //   setInputValue(newInputValue);
  //   if (newInputValue.length === 0) {
  //     document.querySelector('.outerPholder').innerHTML = "Select Educational Backgrounds"
  //   } 
  //   // else {
  //   //   setOpen(false);
  //   // }
  // };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    indexlike: 'qualifications-multiselect-box',
    multiple: true,
    options: qualifications,
    disableCloseOnSelect: true,
    clearOnEscape: true,
    open: open,
    onOpen: handleOpen,
    onClose: handleClose,

    // inputValue: inputValue,
    // onInputChange: handleInputChange,

    getOptionLabel: (option) => option.course,
    onChange: (event, newValue) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValues(newValue);
      // if (newValue.length > 0) {
      //   document.querySelector('.outerPholder').innerHTML = "";
      // } else if (newValue.length <= 0) {
      //   if (!open) { setPholder('Select Educational Backgrounds'); }
      //   document.querySelector('.outerPholder').innerHTML = "Select Educational Backgrounds"
      // }
    },
  });
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState(null);
  const [tags_values, setTagsValues] = useState([]);
  const [pholder, setPholder] = useState('Select Educational Backgrounds');
  const [tab, setTab] = useState('');
  const [highlight, setHighlight] = useState('black');
  const [scroll, setScroll] = useState('0');
  const scrollRef = useRef(null);
  const scrollRef_certificate = useRef(null);
  const scrollRef_diploma = useRef(null);
  const scrollRef_graduate = useRef(null);
  const scrollRef_iti = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView();
  const executeScroll_certificate = () => scrollRef_certificate.current.scrollIntoView();
  const executeScroll_diploma = () => scrollRef_diploma.current.scrollIntoView();
  const executeScroll_graduate = () => scrollRef_graduate.current.scrollIntoView();
  const executeScroll_iti = () => scrollRef_iti.current.scrollIntoView();

  var checkedObject = {}

  for(var p of qualifications)
    {
        checkedObject [p] = false;
    }

  var hashMap = {}, i = 0;
  for (i = 0; i < groupedOptions.length; i++) {
    var category = groupedOptions[i].category;
    if (category in hashMap) {
      hashMap[category].push(groupedOptions[i].course);
    } else {
      var array_courses = [];
      array_courses.push(groupedOptions[i].course);
      hashMap[category] = array_courses;
    }
  }

  function setUpdate() {
    // setHighlight("black");
  }

  // useEffect(() => {
  //   if (tab === "School") {
  //     setUpdate();
  //   }
  // }, [tab, qualifications])

  return (
    <NoSsr>
      <div style={{ flex: 1, padding: '14px 0 14px 15px' }}>
        <div {...getRootProps()}>
          <Label {...getInputLabelProps()} className={focused ? 'focused' : ''}>Preferred Qualification(s)</Label>
          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            {tags_values.map((option, index) => {
              return index <= 0 ?
                <Tag data-tag-name={option} label={option} {...getTagProps({ index })} />
                // <Tag label={option.course} {...getTagProps({ index })} />
                : null
            }
            )}

            <span className="outerPholder" style={{ position: 'absolute', padding: '6px 0', color: 'rgba(0, 0, 0, 0.6)' }}></span>
            {/* {values && values.length > 1 ? <span style={{ padding: '7px 2px' }}>+{value.length - 1} More</span> : null} */}

            {/* {tags_values.length === 0 ? <span style={{ position: 'absolute', padding:'7px 2px', color: 'rgba(0, 0, 0, 0.6)' }}>Select Educational Backgrounds</span> : null} */}
            {tags_values.length > 1 ? <span style={{ padding: '7px 2px' }}>+{tags_values.length - 1} More</span> : null}
            {tags_values.length > 0 ? <Button style={{minHeight: 'unset', minWidth: 'unset', width: 20, height: 20, margin: '4px 3px 0 3px', padding: 4, borderRadius: '50%'}} size="small" variant="outlined" onClick={() => {
              tags_values.map((option, index) => {
                checkedObject[option] = false;
                console.log("option=" + checkedObject[option] + option)
                if (open) { document.querySelector('input[name="' + option + '"]').click(); }
              })
              setTagsValues(
                []);
              document.querySelector('.outerPholder').innerHTML = "Select Educational Backgrounds"
            }
            }> <CloseIcon style={{fontSize: 14}}></CloseIcon> </Button> : null}
            <Input data-name="course-input" {...getInputProps()} placeholder={pholder} style={{ zIndex: 9999999 }} onMouseDown={handleOpen} />
          </InputWrapper>
        </div>
        {open && groupedOptions.length >= 0 ? (
          <Listbox {...getListboxProps()}>
            <div className="header">
              <div>
                <div className="searchbox">
                  <div className="searchIcon"><SearchIcon fontSize="small" /></div>
                  <Input />
                </div>
              </div>
              <div className="header__items">
                <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll)}>School</span>
                <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }}
                  onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_diploma)}>Diploma</span>
                <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_graduate)}>Graduate</span>
                <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_iti)}>ITI</span>
                <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_certificate)}>Certificate</span>
              </div>
              <div onClick={handleClose}><CloseIcon style={{ color: 'grey', cursor: 'pointer' }}></CloseIcon></div>
            </div>
            <div style={{
              width: 900, display: 'flex', overflowX: 'auto',
              overflowY: 'hidden', flex: 1
            }}>
              <ul>
                {Object.keys(hashMap).map((key_category, index) => (
                  <li  ref = {key_category === "School"? scrollRef : (key_category === 'Certificate'? scrollRef_certificate : (key_category === 'Diploma'? scrollRef_diploma:(key_category==='Graduate'? scrollRef_graduate : scrollRef_iti)))} >{key_category}
                    <div className="columnTitle" />
                    <div><ul>
                      {hashMap[key_category].map((key_course, index) => {

                        return (
                          <li datacat={key_category} {...getOptionProps({ key_course })} style={{ color: key_category === tab ? highlight : 'grey' }}>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              icon={icon}
                              checkedIcon={checkedIcon}
                              name={key_course}
                              checked={checkedObject[key_course]}
                              onChange={(e) => {
                                // debugger;

                                if (e.target.checked == true) {
                                  console.log("add..." + e.target.name)
                                  setTagsValues([key_course, ...tags_values])
                                  refine(key_course);  
                                  checkedObject[key_course] = true;
                                  console.log(checkedObject[key_course])
                                }
                                else {
                                  console.log("remove....")

                                  setTagsValues(tags_values.filter(item => item != key_course))
                                  checkedObject[key_course] = false;
                                }
                                // debugger;
                                if (tags_values.length >= 0) {
                                  document.querySelector('.outerPholder').innerHTML = "";
                                } else if (!tags_values) {
                                  if (!open) { setPholder('Select Educational Backgrounds'); }
                                  document.querySelector('.outerPholder').innerHTML = "Select Educational Backgrounds"
                                }
                              }}
                            /><span>{key_course}</span>
                          </li>)
                      }
                      )}
                    </ul></div>
                  </li>
                ))
                }

                {/* <li><div><ul> {groupedOptions.map((option, index) => {
                      return option.category !== groupedOptions[index - 1 > 0 ? index - 1 : 0].category ?
                        <span className="columnTitleSpan"><li className="columnTitle" ref={scrollRef}>{option.category}</li><li datacat={option.category} {...getOptionProps({ option, index })} style={{ color: option.category === tab ? highlight : 'grey', paddingLeft: 0, marginLeft: -8 }}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 0 }}
                            checked='selected'
                          /><span>{option.course}</span>
                        </li></span>
                        :
                        <li datacat={option.category} {...getOptionProps({ option, index })} style={{ color: option.category === tab ? highlight : 'grey' }}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked='selected'
                          /><span>{option.course}</span>
                        </li>
                    }
                    )}  
                </ul></div> 
                </li>  */}

              </ul>
            </div>
          </Listbox>
        ) : null}
      </div>
    </NoSsr>
  );
}

// data
const qualifications = [
  { course: "SSC", category: "School" },
  { course: "HSC", category: "School" },
  { course: "HSC Science", category: "School" },
  { course: "HSC Maths", category: "School" },
  { course: "Any Diploma", category: "Diploma" },
  { course: "Photography and Videography", category: "Diploma" },
  { course: "Financial Accounts", category: "Diploma" },
  { course: "Hospitality and Tourism", category: "Diploma" },
  { course: "MRI Technician", category: "Diploma" },
  { course: "PG Diploma in Business Analytics", category: "Diploma" },
  { course: "PG Diploma in Web Designing", category: "Diploma" },
  { course: "PG Diploma in Disaster Management", category: "Diploma" },
  { course: "Photography and Videography", category: "Diploma" },
  { course: "Financial Accounts", category: "Diploma" },
  { course: "Hospitality and Tourism", category: "Diploma" },
  { course: "MRI Technician", category: "Diploma" },
  { course: "PG Diploma in Business Analytics", category: "Diploma" },
  { course: "PG Diploma in Web Designing", category: "Diploma" },
  { course: "PG Diploma in Disaster Management", category: "Diploma" },
  { course: "Photography and Videography", category: "Diploma" },
  { course: "Financial Accounts", category: "Diploma" },
  { course: "Hospitality and Tourism", category: "Diploma" },
  { course: "MRI Technician", category: "Diploma" },
  { course: "PG Diploma in Business Analytics", category: "Diploma" },
  { course: "PG Diploma in Web Designing", category: "Diploma" },
  { course: "PG Diploma in Disaster Management", category: "Diploma" },
  { course: "Any Graduate", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Bachelor of Dental Surgery", category: "Graduate" },
  { course: "Bachelor of Engineering", category: "Graduate" },
  { course: "Photographer", category: "ITI" },
  { course: "Litho-Offset Machine Minder", category: "ITI" },
  { course: "Leather Goods Maker", category: "ITI" },
  { course: "House Keeper", category: "ITI" },
  { course: "Dress Making", category: "ITI" },
  { course: "Library and Information Science", category: "ITI" },
  { course: "Tool and Die Maker", category: "ITI" },
  { course: "Machinist", category: "ITI" },
  { course: "Fitter", category: "ITI" },
  { course: "Fabrication (Fitting and Welding)", category: "ITI" },
  { course: "Welder", category: "ITI" },
  { course: "Electrician", category: "ITI" },
  { course: "Photographer", category: "ITI" },
  { course: "Litho-Offset Machine Minder", category: "ITI" },
  { course: "Leather Goods Maker", category: "ITI" },
  { course: "House Keeper", category: "ITI" },
  { course: "Dress Making", category: "ITI" },
  { course: "Library and Information Science", category: "ITI" },
  { course: "Tool and Die Maker", category: "ITI" },
  { course: "Machinist", category: "ITI" },
  { course: "Fitter", category: "ITI" },
  { course: "Fabrication (Fitting and Welding)", category: "ITI" },
  { course: "Welder", category: "ITI" },
  { course: "Electrician", category: "ITI" },
  { course: "Photographer", category: "ITI" },
  { course: "Litho-Offset Machine Minder", category: "ITI" },
  { course: "Leather Goods Maker", category: "ITI" },
  { course: "House Keeper", category: "ITI" },
  { course: "Dress Making", category: "ITI" },
  { course: "Library and Information Science", category: "ITI" },
  { course: "Tool and Die Maker", category: "ITI" },
  { course: "Machinist", category: "ITI" },
  { course: "Fitter", category: "ITI" },
  { course: "Fabrication (Fitting and Welding)", category: "ITI" },
  { course: "Welder", category: "ITI" },
  { course: "Electrician", category: "ITI" },
  { course: "Photographer", category: "ITI" },
  { course: "Litho-Offset Machine Minder", category: "ITI" },
  { course: "Leather Goods Maker", category: "ITI" },
  { course: "House Keeper", category: "ITI" },
  { course: "Dress Making", category: "ITI" },
  { course: "Library and Information Science", category: "ITI" },
  { course: "Tool and Die Maker", category: "ITI" },
  { course: "Machinist", category: "ITI" },
  { course: "Fitter", category: "ITI" },
  { course: "Fabrication (Fitting and Welding)", category: "ITI" },
  { course: "Welder", category: "ITI" },
  { course: "Electrician", category: "ITI" },
  { course: "Welder", category: "Certificate" },
  { course: "Electrician", category: "Certificate" },
]

const groupByMake = (qualifications = []) => {
  let result = [];
  result = qualifications.reduce((r, a) => {
    r[a.category] = r[a.category] || [];
    r[a.category].push(a);
    return r;
  }, Object.create(null));
  return result;
};
//console.log(groupByMake(qualifications));

const CustomList_qualifications= connectRefinementList(CustomRefinementListQualifications);

export default CustomList_qualifications;