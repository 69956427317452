
import React, { useRef, useEffect, useState } from 'react';
// import Plotly from 'plotly.js-dist';
import ApiHandler from '../../ApiHandler.jsx';

export default function Analytics(props){

const [locations_count, setLocationsCount] = React.useState([]);

const [roles_count, setRolesCount] = React.useState([]);

const [quals_count, setQualsCount] = React.useState([]);

const [total_jobseeker_count, setTotalJobseekerCount] = React.useState(0);

React.useEffect(() => {

var loc_temp = locations_count.sort(function compare(a, b) {
  if (a.count < b.count) {
    return -1;
  }
  if (a.count > b.count) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
);

//get first 10 bars in increasing order
loc_temp = loc_temp.slice(1,11);

var locations = loc_temp.map(function(item) {
  return item['location'];});

var counts = loc_temp.map(function(item) {
  return item['count'];
});

 var layout = {
  title: 'Top 10 Locations versus count of jobseekers (active in last 30 days)',
  font:{
    family: 'Raleway, sans-serif'
  },
  showlegend: false,
    xaxis: {
      autotick: false,
      ticks: 'outside',
      tick0: 0,
      tickangle: -45,
      dtick: 0.25,
      ticklen: 8,
      tickwidth: 4,
      tickcolor: '#000',
      automargin: true
    },
    yaxis: {
      zeroline: false,
      gridwidth: 2
    },

  bargap :0.05
};

var TESTER = document.getElementById('tester');

Plotly.newPlot( TESTER, [{
	x: locations,
	y: counts,
  type: 'bar',
 marker: {
    color: 'red',
    opacity: 0.6,
    line: {
      color: 'red',
      width: 1.5
    }
  }
 }],layout, []
);

}, [locations_count]);

React.useEffect(() => {

var roles_temp = roles_count.sort(function compare(a, b) {
  if (a.count < b.count) {
    return -1;
  }
  if (a.count > b.count) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
);

roles_temp = roles_temp.slice(1,11);

var roles = roles_temp.map(function(item) {
  return item['role'];
});

var counts_roles = roles_temp.map(function(item) {
  return item['count'];
});

var layout_roles = {
  title: 'Top 10 Roles versus count of jobseekers (active in last 30 days)',
  font:{
    family: 'Raleway, sans-serif'
  },
  showlegend: false,
    xaxis: {
      autotick: false,
      ticks: 'outside',
      tick0: 0,
      tickangle: -45,
      dtick: 0.25,
      ticklen: 8,
      tickwidth: 4,
      tickcolor: '#000',
      automargin: true
    },
    yaxis: {
      zeroline: false,
      gridwidth: 2
    },
  bargap :0.05
};

var TESTER_ROLES = document.getElementById('tester_roles');

// Plotly.newPlot( TESTER_ROLES, [{
// 	x: roles,
// 	y: counts_roles,
//   type: 'bar',
//  marker: {
//     color: 'blue',
//     opacity: 0.6,
//     line: {
//       color: 'blue',
//       width: 1.5
//     }
//   }
//  }],layout_roles, []
// );

},[roles_count]);

React.useEffect(() => {

var quals_temp = quals_count.sort(function compare(a, b) {
  if (a.count < b.count) {
    return -1;
  }
  if (a.count > b.count) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
);

quals_temp = quals_temp.slice(1,11);

var quals = quals_temp.map(function(item) {
  return item['qual'];
});

var counts_quals = quals_temp.map(function(item) {
  return item['count'];
});

var layout_quals = {
  title: 'Top 10 Qualifications versus count of jobseekers (active in last 30 days)',
  font:{
    family: 'Raleway, sans-serif'
  },
  showlegend: false,
  xaxis: {
      autotick: false,
      ticks: 'outside',
      tick0: 0,
      tickangle: -45,
      dtick: 0.25,
      ticklen: 8,
      tickwidth: 4,
      tickcolor: '#000',
      automargin: true
    },
    yaxis: {
      zeroline: false,
      gridwidth: 2
    },

  bargap :0.05
};

var TESTER_QUALS = document.getElementById('tester_quals');

// Plotly.newPlot( TESTER_QUALS, [{
// 	x: quals,
// 	y: counts_quals,
//   type: 'bar',
//  marker: {
//     color: 'yellow',
//     opacity: 0.6,
//     line: {
//       color: 'yellow',
//       width: 1.5
//     }
//   },
// }
// ],layout_quals, []
// );
},[quals_count]);


React.useEffect(() => {

ApiHandler.getTopLocationsAnalytics(function (res){
    if(res.status===200)
    {
        debugger;
        setLocationsCount(res.data.result);
        setTotalJobseekerCount(res.data.total_jobseeker_count);
    }

    else {
     console.log("Error in getting locations analytics from api !");
    }   
      });

ApiHandler.getTopRolesAnalytics(function (res){
    if(res.status===200)
    {
        debugger;
        setRolesCount(res.data.result);
    }

    else {
     console.log("Error in getting roles analytics from api !");
    }   
      });

ApiHandler.getTopQualsAnalytics(function (res){
    if(res.status===200)
    {
        debugger;
        setQualsCount(res.data.result);
    }

    else {
     console.log("Error in getting quals analytics from api !");
    }   
      });

//graph locations versus count of jobseeker ids
//var locations_count = [{location:'Delhi', count: 23},{location: 'Lucknow', count: 15},{location:'Mumbai', count: 55}, {location:'Hyderabad', count: 6}, {location:'Telengana', count: 12}, {location:'Patna', count: 65}, {location:'Tirupati', count: 5}, {location:'Bhuvaneshwar', count: 35},{location:'Jaipur', count: 22}, {location:'Sonipat', count: 3}, {location:'Srinagar', count: 8}]

//graph of roles versus count of jobseeker ids
//var roles_count = [{role:'Software Engineer', count: 23},{role: 'Tester', count: 15},{role:'HR Manager', count: 55}, {role:'SAP Analyst', count: 6}, {role:'Sales', count: 12}, {role:'Graphic Designer', count: 65}, {role:'Data Analyst', count: 5}, {role:'Welder', count: 35},{role:'Electrician', count: 22}, {role:'Fashion', count: 3}, {role:'Caterer', count: 8}]


//var quals_count = [{qual:'BTech', count: 23},{qual: 'BA', count: 15},{qual:'MBA', count: 55}, {qual:'Diploma', count: 6}, {qual:'MS', count: 12}, {qual:'Masters', count: 65}, {qual:'PhD', count: 5}, {qual:'BCom', count: 35},{qual:'MCom', count: 22}, {qual:'MA', count: 3}, {qual:'MD', count: 8}]

},[]);

 return (
    <div>

    <h1 style = {{marginLeft:"30px", marginTop:"30px"}}> Analytics </h1>

    <h3 style = {{marginLeft:"30px" }}> Total Jobseeker Count - {total_jobseeker_count} </h3>

    <div id="tester" style={{width:"600px", height:"500px"}}>

    </div>

    <div id="tester_roles" style={{width:"600px", height:"500px"}}>

    </div>

    <div id="tester_quals" style={{width:"600px", height:"500px"}}>

    </div>

    </div>
  );

}