import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropLeftIcon from '@material-ui/icons/ArrowLeft';
import { connectPagination } from 'react-instantsearch-dom';
import { AlternateEmail, PinDropSharp } from '@material-ui/icons';
import No_More_Results from './NoMoreResults';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#aaa',
    color: '#fff',
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    //position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '-60px',
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    //width: 850,
    width: 1005,
    height: 0,
    margin: 'auto',
    marginTop: '-1px',
    padding: 0,
    zIndex: '0',
  },

  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    position: 'absolute',
    //top: '-20px',
    //width: 300,
    width: '100%',
  },
  bigIconRect: {
    // position: 'absolute',
    zIndex: 2,
    height: 35,
    width: 110,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 10,
    textTransform: 'capitalize',
  },
  saveIcon: {
    height: 27,
    width: 27,
  },
  bigIcon: {
    height: 54,
    width: 54,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    color: '#fab116',
    border: '2px solid #fab116',
    marginLeft: 30,
    marginRight: 30,
    '&:hover': {
      backgroundColor: '#fab116',
      color: '#ffffff',
      boxShadow: '0px 0px 7px rgba(221, 150, 0, 0.66)',

    }
  },
  smallIcon: {
    height: 40,
    width: 40,
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    border: '2px solid #14967B',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    '&:hover': {
      backgroundColor: '#b9f0e1',
    },
  },
  smallIcon_first: {
    height: 40,
    width: 40,
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
    borderRadius: '50%',
    backgroundColor: '#D0D0D0',
    border: '2px solid grey',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: 'grey',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#D0D0D0',
    },
  },

  kbdIcon: {
    height: 20,
    width: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: '#dddddd',
    border: '1px solid #ccc',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    color: 'rgba(0, 0, 0, 0.3);',
  },
  secondaryControls: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',

  },
  snackBar: {
    '& > div': {
      backgroundColor: "#14967B",
      borderRadius: 5
    }
  }
}));

let pg = 1;

function ProfileCardNavbar({ currentRefinement, nbPages, refine, createURL, page, setPage, nb_hits, searchRef, flag_location_text, setFlagLocationText, signInStatus }) {
  const classes = useStyles();
  const theme = useTheme();
  //console.log("Page is ...." + page);

  // if (page == 1)
  //   refine(1);

  React.useEffect(() => { 
    if (page > nb_hits)
      setFlagLocationText(true);
    if (page == 1) {
      pg = 1
      refine(1);
    }
  }, [page])

  // React.useEffect(() => { 
  //   if (pg > nb_hits)
  //     setFlagLocationText(true);
  //   if (pg == 1) {
  //     //pg = 1
  //     refine(1);
  //   }
  // }, [pg])

  // React.useEffect(() => { 
  //   //console.log("page= " + page)
  //   refine(page)
  //   pg = page
  // }, [])

  return (
    <div>
      {/* {pg <= nb_hits ? */}
        <Card id="profileNavArrowWrapper" className={classes.root} elevation={4}>
          <div className={classes.controls}>
            <LightTooltip title="Previous Profile" placement="top-start">
              <IconButton aria-label="previous" disabled={pg == nb_hits} className={pg == 1 ? classes.smallIcon_first : classes.smallIcon} onClick={event => {
                event.preventDefault();
                //console.log("prev....." + page)
                // if (page > 1) {
                //   //setPage((prev) => (prev - 1));
                //   // page_global.page = page;
                //   refine(page - 1);
                // }
                //alert(pg)
                if (pg > 1) { 
                  let pgNew = pg--
                  //alert(pg)
                  refine(pgNew - 1);
                } else if (pg === 1) {
                  refine(pg)
                }

              }}>
                {theme.direction === 'rtl' ? <ArrowForwardIosRoundedIcon /> : <ArrowBackIosRoundedIcon />}
              </IconButton>
            </LightTooltip>
            <LightTooltip title="Next Profile" placement="top-start">
              <IconButton aria-label="next" disabled={pg == nb_hits} className={pg == nb_hits ? classes.smallIcon_first : classes.smallIcon} onClick={event => {
                event.preventDefault();
                //console.log("page......" + page);

                // setPage((prev) => {
                //   var x = ++prev;
                //   return x;
                // }
                // );

                //  page_global.page = page;
                //console.log("refining....." + (page + 1))

               // refine(page + 1);
                 let pgNew = pg++
                 refine(pgNew+1)
              }}>
                {theme.direction === 'rtl' ? <ArrowBackIosRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
              </IconButton>
            </LightTooltip>
          </div>

          {/* <div className={classes.secondaryControls}>
        <div style={{flex: 1}}>
          <ArrowDropUpIcon className={classes.kbdIcon} />
          <ArrowDropDownIcon className={classes.kbdIcon} />
          <ArrowDropLeftIcon className={classes.kbdIcon}/>
          <ArrowDropRightIcon className={classes.kbdIcon}/>
        </div>
        <Button variant="contained" color="primary" size="small" style={{flex: 0.5, boxShadow: 'none', textTransform: 'capitalize', backgroundColor: '#2b9f81'}}>
          View Saved Profiles (10)
        </Button>
        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
        <IconButton aria-label="send whatsapp" disabled className={classes.smallIcon}>
          <WhatsAppIcon />
        </IconButton>
        <IconButton aria-label="send sms" disabled className={classes.smallIcon}>
          <SmsIcon />
        </IconButton>
        </div>
      </div> */}
        </Card> 
        {/* :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <br /> <br />
          <Button variant="outlined" onClick={() => { setPage((prev) => (prev - 1)); refine(pg - 1); }}> Go Back </Button>
        </div>

      } */}

      {/* {pg > nb_hits ?
        <div>
          <No_More_Results
            searchRef={searchRef}
          />
        </div>
        :
        null
      } */}
    </div>
  );
}

export default ProfileCardNavbar;