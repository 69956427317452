import React from 'react'
import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import DomainIcon from '@material-ui/icons/Domain';
import feIcon from '../../assets/female.svg';
import organization from '../../assets/organization.svg';
import lgbtq from '../../assets/lgbtq.svg';
import specialNeed from '../../assets/special_need.svg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { Facebook, Instagram, LinkedIn } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';
import Location from './Location';
import GetAppIcon from '@material-ui/icons/GetApp';
import AnalyticsHandler from '../../AnalyticsHandler';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  font-size: 0.75rem;
  color: #797979;
`;

const Input = styled('input')({
    display: 'none',
});

const Overview_2 = (props) => {
    const { classes, data, updateData, updateCompanyInfo, setCompanyIdentificationNum, companyDetails, doRefresh } = props;

    const [editAboutDetailsModal, setEditAboutDetailsModal] = React.useState(false);
    const [editDiversityDetailsModal, setEditDiversityDetailsModal] = React.useState(false);
    const [editAddressDetailsModal, setEditAddressDetailsModal] = React.useState(false);
    const [editSocialDetailsModal, setEditSocialDetailsModal] = React.useState(false);
    const [location, setLocation] = React.useState([]);
    const [sector, setSector] = React.useState([]);
    const [branchLocationValue, setBranchLocationValue] = React.useState([]);
    const [selectedDocument, setSelectedDocument] = React.useState();
    const [selectedDocumentName, setSelectedDocumentName] = React.useState(null);
    const [locationValue, setLocationValue] = React.useState();
    const [latLong, setLatLong] = React.useState();
    const [showEmailAlert, setShowEmailAlert] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(null);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowEmailAlert(false);
    };

    const changeCompanyDocument = (e) => {
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedDocument(e.target.files[0]);
                setSelectedDocumentName(e.target.files[0].name);
            }
        }
    }

    const changeCompanyLogo = (e) => {
        AnalyticsHandler.track("CompanyPage_overview_changeLogoBtn")
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedImage(e.target.files[0]);
            }
        }
    }

    const saveLogoImg = () => {
        var formData = new FormData();
        formData.append('employer_image', selectedImage);
        formData.append('company_id', localStorage.getItem('company_id'));
        axios.post(BASE_URL + '/employer/admin_image_upload', formData)
            .then(response => {
                updateData({ ...data, company_image_url: response.data.image_url });
                doRefresh(p => !p);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const saveCompanyDoc = () => {
        var formData = new FormData();
        formData.append('employer_document', selectedDocument);
        formData.append('company_id', localStorage.getItem('company_id'));
        axios.post(BASE_URL + '/employer/upload_document_retool', formData)
            .then(response => {
                updateData({ ...data, employer_document_url: response.data.image_url, employer_document_name: selectedDocumentName });
            })
            .catch(error => {
                console.log(error);
            });
    }

    React.useEffect(() => {
        if (latLong !== undefined && locationValue !== undefined) {
            updateData({ ...data, latitude: latLong.lat, longitude: latLong.long, company_address: locationValue });
        }
    }, [latLong, locationValue]);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_locations').then((Result) => {
            let locArr = Result.data;
            setLocation(locArr);
        })
    }, []);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_sectors').then((Result) => {
            let secArr = Result.data;
            setSector(secArr);
        })
    }, []);

    React.useEffect(() => {
        if (companyDetails.company_branch_location !== undefined && companyDetails.company_branch_location.length > 0) {
            let locVal = [];
            for (let i = 0; i < companyDetails.company_branch_location.length; i++) {
                const element = companyDetails.company_branch_location[i];
                locVal.push({ city: element });
            }
            setBranchLocationValue(locVal);
        }
    }, [companyDetails.company_branch_location])

    const saveFiles = (setModal) => {
        AnalyticsHandler.track("CompanyPage_overview_saveDetailsBtn");
        updateCompanyInfo(setModal);
        if (selectedImage) {
            saveLogoImg();
        }
        if (selectedDocument) {
            saveCompanyDoc();
        }
    }

    return (
        <>
            <Grid container wrap='nowrap' direction='column' style={{ padding: '1em' }} className="overviewContainer">
                <Grid item container className={classes.overviewFlexContainer} wrap='nowrap' >
                    <Grid xs={12} sm={3} item container className={classes.overview_heading_section} >
                        <Typography>About</Typography>
                        <EditIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditAboutDetailsModal(true)} />
                    </Grid>
                    <Grid xs={12} sm={9} item container direction='column' className={classes.overview_section_details} >
                        <Grid container item>
                            <Grid xs={8} item container direction='column' >
                                <Typography variant='h5' component='h1'>{companyDetails.company_name || ""}</Typography>
                                <Typography className={`${classes.color_light}`} variant='body1' component='h2'>{companyDetails.company_type || ""}</Typography>
                                <Typography className={`${classes.myMin} ${classes.flexbox}`} variant='body2'><PermIdentityOutlinedIcon color='primary' fontSize='small' className={classes.mr2} /><span>{companyDetails.employer_name || ""}</span></Typography>
                                <Typography className={`${classes.myMin} ${classes.flexbox}`} variant='body2'><LanguageOutlinedIcon color='primary' fontSize='small' className={classes.mr2} /><span>{companyDetails.company_website || ""}</span></Typography>
                                <Typography className={`${classes.myMin} ${classes.flexbox}`} variant='body2'><CallIcon color='primary' fontSize='small' className={classes.mr2} /><span>{companyDetails.company_mobile || ""}</span></Typography>
                                <Typography className={`${classes.myMin} ${classes.flexbox}`} variant='body2'><EmailIcon color='primary' fontSize='small' className={classes.mr2} /><span>{companyDetails.company_email || ""}</span></Typography>
                                <Typography className={`${classes.myMin} ${classes.flexbox}`} variant='body2'><DomainIcon color='primary' fontSize='small' className={classes.mr2} /><span>{companyDetails.sector_name || ""}</span></Typography>

                            </Grid>
                            <Grid xs={4} item container>
                                <img src={companyDetails.company_image_url} width={120} height={120} />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <div style={{ display: 'flex', flexDirection: 'column' }} variant='body2'>
                                <Typography variant='body1' className={`${classes.my1} ${classes.semi_bold}`}>About company</Typography>
                                <Typography variant='body2' component='p'>{companyDetails.company_description}</Typography>
                            </div>
                        </Grid>
                        <Grid className={classes.my1} container item>
                            <a href={companyDetails.employer_document_url} download className={classes.documentDownload} ><span>Company Document</span> <span><GetAppIcon fontSize='small' /></span></a>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={classes.overviewFlexContainer} >
                    <Grid xs={12} sm={3} item container className={classes.overview_heading_section} >
                        <Typography>Diversity</Typography>
                        <EditIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditDiversityDetailsModal(true)} />
                    </Grid>
                    <Grid xs={12} sm={9} item container className={classes.overview_section_details} >
                        <Grid direction='column' item wrap='nowrap' container xs={5} alignItems='center' justifyContent='center' style={{ border: '1px solid #D0D1D3', margin: 5, maxHeight: 150, padding: '1em 0', borderRadius: 3 }} >
                            <img src={organization} width={30} height={30} />
                            <Typography variant='body2' className={classes.textAlignCenter}>Organization Size</Typography>
                            <Typography variant='h6'>{companyDetails.number_of_employees || "0"}</Typography>
                        </Grid>
                        <Grid direction='column' item wrap='nowrap' container xs={5} alignItems='center' justifyContent='center' style={{ border: '1px solid #D0D1D3', margin: 5, maxHeight: 150, padding: '1em 0', borderRadius: 3 }} >
                            <img src={feIcon} width={30} height={30} />
                            <Typography variant='body2' className={classes.textAlignCenter}>Female Employees</Typography>
                            <Typography variant='h6'>{companyDetails.number_of_female_employees || "0"}</Typography>
                        </Grid>
                        <Grid direction='column' item wrap='nowrap' container xs={5} alignItems='center' justifyContent='center' style={{ border: '1px solid #D0D1D3', margin: 5, maxHeight: 150, padding: '1em 0', borderRadius: 3 }} >
                            <img src={lgbtq} width={30} height={30} />
                            <Typography variant='body2' className={classes.textAlignCenter}>LGBTQ Employees</Typography>
                            <Typography variant='h6'>{companyDetails.current_lgbtq_count || "0"}</Typography>
                        </Grid>
                        <Grid direction='column' item wrap='nowrap' container xs={5} alignItems='center' justifyContent='center' style={{ border: '1px solid #D0D1D3', margin: 5, maxHeight: 150, padding: '1em 0', borderRadius: 3 }} >
                            <img src={specialNeed} width={30} height={30} />
                            <Typography variant='body2' className={classes.textAlignCenter}>Special Need Employees</Typography>
                            <Typography variant='h6'>{companyDetails.current_disabled_count || "0"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container className={classes.overviewFlexContainer} >
                    <Grid xs={12} sm={3} item container className={classes.overview_heading_section} >
                        <Typography>Location</Typography>
                        <EditIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditAddressDetailsModal(true)} />
                    </Grid>
                    <Grid xs={12} sm={9} item container direction='column' className={classes.overview_section_details} >
                        <Grid wrap='nowrap' item container className={classes.myMin}>
                            <LocationOnIcon fontSize='small' color='primary' className={classes.mr2} /><span><span className={classes.semi_bold}>Headquarter Address:</span> <span className={classes.color_light}>{companyDetails.company_address}</span></span>
                        </Grid>
                        {companyDetails.company_branch_location && <Grid wrap='nowrap' item container className={classes.myMin}>
                            <HomeWorkIcon fontSize='small' color='primary' className={classes.mr2} /><span><span className={classes.semi_bold}>Branches:</span> {companyDetails.company_branch_location.map((item, index, arr) => arr.length === 1 ? <span className={classes.color_light} key={index}>{item}</span> : <span className={classes.color_light} key={index}>{arr.indexOf(item) === arr.length - 1 ? item : item + ', '}</span>)}</span>
                        </Grid>}
                    </Grid>
                </Grid>
                <Grid item container className={classes.overviewFlexContainer} >
                    <Grid xs={12} sm={3} item container className={classes.overview_heading_section} >
                        <Typography>Socials</Typography>
                        <EditIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditSocialDetailsModal(true)} />
                    </Grid>
                    <Grid xs={12} sm={9} item container direction='column' className={classes.overview_section_details} >
                        <Grid item container className={classes.myMin} >
                            <a className={`${classes.flexbox} ${classes.tdn}`} href={companyDetails.facebook_link} target='_blank'>
                                <Facebook fontSize='small' color='action' className={classes.mr2} /><span style={{ color: '#333' }}>Facebook</span>
                            </a>
                        </Grid>
                        <Grid item container className={classes.myMin} >
                            <a className={`${classes.flexbox} ${classes.tdn}`} href={companyDetails.instagram_link} target='_blank'>
                                <Instagram fontSize='small' color='action' className={classes.mr2} /><span style={{ color: '#333' }}>Instagram</span>
                            </a>
                        </Grid>
                        <Grid item container className={classes.myMin} >
                            <a className={`${classes.flexbox} ${classes.tdn}`} href={companyDetails.linkedin_link} target='_blank'>
                                <LinkedIn fontSize='small' color='action' className={classes.mr2} /><span style={{ color: '#333' }}>LinkedIn</span>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Update about section data */}
            <Dialog
                open={editAboutDetailsModal}
                classes={{ paper: classes.editDialogPaper }}
            >
                <DialogContent>
                    <Grid container alignItems='center' justifyContent='flex-end'>
                        <CloseIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditAboutDetailsModal(false)} />
                    </Grid>
                    <div className={`${classes.profileSubCards} ${classes.my1}`}>
                        <Typography component='h2' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0', textAlign: 'center' }}>Edit About Details</Typography>
                        <div className={`${classes.flexboxCol}`}>
                            <div className={`${classes.mr1} ${classes.width_100} `}>
                                <Label>Employer Name*</Label>
                                <TextField fullWidth id="employer-name" value={data.employer_name || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, employer_name: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${classes.my1}`}>
                                <Label>Contact Number*</Label>
                                <TextField fullWidth id="company-contact" type='number' value={data.company_mobile || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, company_mobile: e.target.value, mobile: e.target.value })
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className={`${classes.flexboxCol}`}>
                            <div className={`${classes.mr1} ${classes.width_100} `}>
                                <Label>Company Name*</Label>
                                <TextField fullWidth id="company-name" value={data.company_name || ""} variant='outlined' aria-describedby="my-helper-text"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${classes.my1} ${classes.position_relative}`}>
                                <Label>Company Email*</Label>
                                <TextField fullWidth id="company-email" value={data.company_email || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onClick={e => {
                                        setShowEmailAlert(prev => !prev);
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                {showEmailAlert && <Alert icon={false} onClose={handleAlertClose} className={classes.emailAlert} severity="success">
                                    Please contact our customer support to change your email.
                                </Alert>}
                            </div>
                        </div>
                        <div>
                            <Label>Company Website*</Label>
                            <TextField fullWidth id="company-website" type='url' variant='outlined' value={data.company_website || ""}
                                onChange={(e) => {
                                    updateData({ ...data, company_website: e.target.value, website: e.target.value })
                                }}
                            />
                        </div>
                        <div className={`${classes.company_type} ${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                            <div className={`${classes.company_type_labels} ${classes.mr1} ${classes.width_100}`}>
                                <Label>Company Type*</Label>
                                <Autocomplete
                                    options={company}
                                    name="company_type"
                                    value={data.company_type || ""}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        return option.title
                                    }
                                    }
                                    style={{ flex: 0.75, display: 'inline' }}

                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            updateData({ ...data, company_type: newValue.title });
                                        }
                                    }}

                                    classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                                    renderInput={(params) => <TextField {...params} variant='outlined' classes={{ root: classes.skillsInput }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    }

                                    getOptionSelected={(option, value) => option.title === value}

                                    renderOption={(option) => {
                                        if (option) {
                                            return (
                                                <Grid container alignItems="flex-start" direction="column">
                                                    <Typography variant="body2" style={{ opacity: 1, color: 'black' }}>{option.title} </Typography>
                                                </Grid>
                                            )
                                        }
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                                <Label>Sector*</Label>
                                <Autocomplete
                                    options={sector}
                                    name="skill"
                                    value={data.sector_name || ""}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        return option.sector_name
                                    }
                                    }
                                    style={{ flex: 0.75, display: 'inline' }}

                                    onChange={(e, newValue) => {
                                        if (newValue) {
                                            updateData({ ...data, sector_name: newValue.sector_name });
                                        }
                                    }}

                                    classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                                    renderInput={(params) => <TextField {...params} variant='outlined' classes={{ root: classes.skillsInput }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    }
                                    getOptionSelected={(option, value) => option.sector_name === value}

                                    renderOption={(option) => {
                                        if (option) {
                                            return (
                                                <Grid container alignItems="flex-start" direction="column">
                                                    <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.sector_name} </Typography>
                                                </Grid>
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.my1}>
                            <Label>Description</Label>
                            <TextField id="outlined-basic" value={data.company_description} multiline maxRows={10} minRows={3} fullWidth variant="outlined"
                                onChange={e => {
                                    updateData({ ...data, description: e.target.value, company_description: e.target.value });
                                }}
                            />
                        </div>
                        <div className={`${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                            <div className={`${classes.mr1} ${classes.width_100}`}>
                                <Label>Document Identification Number*</Label>
                                <TextField id="outlined-basic" value={data.company_identification_number || ""} className={` ${classes.input_width}`} fullWidth variant="outlined"
                                    onChange={e => {
                                        setCompanyIdentificationNum(e.target.value);
                                        updateData({ ...data, company_identification_number: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                                <Label>Upload Document*</Label>
                                <div className={`${classes.file_upload}`}>
                                    <Input accept="image/*" className={classes.upload_input} id="icon-button-file" type="file"
                                        onChange={e => changeCompanyDocument(e)}
                                    />
                                    <label style={{ padding: 8, width: '90%' }} htmlFor="icon-button-file">
                                        <span style={{ fontSize: '14px', color: '#797979' }}>{selectedDocument ? selectedDocumentName : 'Upload Document'}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                            <Label>Upload Company logo*</Label>
                            <div className={`${classes.file_upload}`}>
                                <Input accept="image/*" className={classes.upload_input} id="contained-button-file" type="file"
                                    onChange={e => changeCompanyLogo(e)}
                                />
                                <label style={{ padding: 8, width: '90%' }} htmlFor="contained-button-file">
                                    <span style={{ fontSize: '14px', color: '#797979' }}>{selectedImage ? selectedImage.name : 'Upload Logo'}</span>
                                </label>
                            </div>
                        </div>
                        <Grid container alignItems='center' justifyContent='flex-end' className={`${classes.width_100} ${classes.mt1}`}>
                            <Button variant='contained' color='primary' onClick={() => saveFiles(setEditAboutDetailsModal)}>Save</Button>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>

            {/* Update diversity data */}
            <Dialog
                open={editDiversityDetailsModal}
                classes={{ paper: classes.editDialogPaper }}
            >
                <DialogContent>
                    <Grid container alignItems='center' justifyContent='flex-end'>
                        <CloseIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditDiversityDetailsModal(false)} />
                    </Grid>
                    <div className={`${classes.profileSubCards} ${classes.my1}`}>
                        <Typography component='h3' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0', textAlign: 'center' }}>Edit Diversity Details</Typography>
                        <div className={`${classes.flexboxCol}`}>
                            <div className={`${classes.mr1} ${classes.width_100} `}>
                                <Label>Organization size*</Label>
                                <TextField fullWidth id="employees-count" value={data.number_of_employees || ""} type='number' variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, number_of_employees: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${classes.my1}`}>
                                <Label>Female Employees</Label>
                                <TextField fullWidth id="female-employees" type='number' value={data.number_of_female_employees || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, number_of_female_employees: e.target.value })
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className={`${classes.flexboxCol}`}>
                            <div className={`${classes.mr1} ${classes.width_100}`}>
                                <Label>LGBTQ Employees</Label>
                                <TextField fullWidth id="lgbtq" type='number' value={data.current_lgbtq_count || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, current_lgbtq_count: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={`${classes.width_100} ${classes.my1}`}>
                                <Label>Special Needs Employees</Label>
                                <TextField fullWidth id="special-need" type='number' value={data.current_disabled_count || ""} variant='outlined' aria-describedby="my-helper-text"
                                    onChange={e => {
                                        updateData({ ...data, current_disabled_count: e.target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <Grid container alignItems='center' justifyContent='flex-end' className={`${classes.width_100} ${classes.mt1}`}>
                            <Button variant='contained' color='primary' onClick={() => saveFiles(setEditDiversityDetailsModal)}>Save</Button>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>

            {/* update location details */}
            <Dialog
                open={editAddressDetailsModal}
                classes={{ paper: classes.editDialogPaper }}
            >
                <DialogContent>
                    <Grid container alignItems='center' justifyContent='flex-end'>
                        <CloseIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditAddressDetailsModal(false)} />
                    </Grid>
                    <div className={`${classes.profileSubCards} ${classes.my1}`}>
                        <Typography component='h3' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0', textAlign: 'center' }}>Edit Office Location Details</Typography>
                        <div className={classes.my1}>
                            <Label>Headquarter Address*</Label>
                            <Location
                                //profileObj={profileObj}
                                locationValue={locationValue}
                                setLocationValue={setLocationValue}
                                latLong={latLong}
                                setLatLong={setLatLong}
                                data={data}
                            //   isErrorAboutMe={isErrorAboutMe}
                            //   setIsLocationAutocompletePageOpen={setIsLocationAutocompletePageOpen}
                            />
                        </div>
                        <div className={classes.my1}>
                            <Label>Branches</Label>
                            <Autocomplete
                                classes={{ root: classes.autoComplete }}
                                multiple
                                freeSolo
                                name="job_location"
                                // ref={locRef}
                                value={branchLocationValue}
                                fullWidth
                                options={location}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    return option.city
                                }
                                }
                                onChange={(event, newValue) => {
                                    event.preventDefault();
                                    if (newValue) {
                                        let locVal = newValue.map((item, index) => item.city);
                                        updateData({ ...data, company_branch_location: locVal, branch_location: locVal });
                                        setBranchLocationValue(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div className={classes.my1}>
                            <Label>Google Map Link*</Label>
                            <TextField fullWidth id="google-map-url" type='url' variant='outlined' value={data.google_map_url || ""}
                                onChange={(e) => {
                                    updateData({ ...data, google_map_url: e.target.value });
                                }}
                            />
                        </div>
                        <Grid container alignItems='center' justifyContent='flex-end' className={`${classes.width_100} ${classes.mt1}`}>
                            <Button variant='contained' color='primary' onClick={() => saveFiles(setEditAddressDetailsModal)}>Save</Button>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>

            {/* update social details */}
            <Dialog
                open={editSocialDetailsModal}
                classes={{ paper: classes.editDialogPaper }}
            >
                <DialogContent>
                    <Grid container alignItems='center' justifyContent='flex-end'>
                        <CloseIcon fontSize='small' className={classes.cursor_pointer} onClick={() => setEditSocialDetailsModal(false)} />
                    </Grid>
                    <div className={`${classes.profileSubCards} ${classes.my1}`}>
                        <Typography component='h3' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0', textAlign: 'center' }}>Edit Social Media Details</Typography>
                        <div className={classes.my1}>
                            <Label>Linkedin</Label>
                            <TextField fullWidth id="my-input" type='url' value={data.linkedin_link || ''} variant='outlined' aria-describedby="my-helper-text"
                                onChange={e => {
                                    updateData({ ...data, linkedin_link: e.target.value })
                                }}
                            />
                        </div>
                        <div className={classes.my1}>
                            <Label>Instagram</Label>
                            <TextField fullWidth id="my-input" type='url' value={data.instagram_link || ""} variant='outlined' aria-describedby="my-helper-text"
                                onChange={e => {
                                    updateData({ ...data, instagram_link: e.target.value })
                                }}
                            />
                        </div>
                        <div className={classes.my1}>
                            <Label>Facebook</Label>
                            <TextField fullWidth id="my-input" type='url' value={data.facebook_link || ""} variant='outlined' aria-describedby="my-helper-text"
                                onChange={e => {
                                    updateData({ ...data, facebook_link: e.target.value })
                                }}
                            />
                        </div>
                        <Grid container alignItems='center' justifyContent='flex-end' className={`${classes.width_100} ${classes.mt1}`}>
                            <Button variant='contained' color='primary' onClick={() => saveFiles(setEditAddressDetailsModal)}>Save</Button>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Overview_2

const company = [
    { title: 'Private Limited' },
    { title: 'LLP' },
    { title: 'Trust' },
    { title: 'Society' },
    { title: "Section 8 company" },
    { title: 'Public Company' },
    { title: 'Other' },
]