import React from 'react';
import { Typography, Grid, TextField, FormHelperText } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;


const filter = createFilterOptions();

function WorkTitle(props) {
  const { classes, values, setValues, setShowRoles, errors, setErrors } = props;
  const [Roles, setRoles] = React.useState([]);
  const [inputRoleValue, setInputRoleValue] = React.useState(props.role ? props.role : "");
  const [inputBlur, setInputBlur] = React.useState(true);

  const onRoleInputChangeHandler = (event, newInputValue, reason) => {
    setInputRoleValue(newInputValue);
  }

  React.useEffect(() => {
    axios.get(BASE_URL + '/select/get_jobroles').then((Result) => {
      let rolesArr = Result.data;
      setRoles(rolesArr);
    })
  }, []);


  return <Grid item xs={12} className={classes.my4} >
    <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Job Title<span style={{color: '#f44336'}}>*</span></Typography>
    <Autocomplete
      name="title"
      freeSolo
      options={Roles}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.job_role_name
      }
      }
      style={{ flex: 0.75 }}

      value={values.job_title ? values.job_title : values.title}
      // selectOnFocus
      // clearOnBlur

      onChange={(event, newValue) => {
        event.preventDefault();
        setErrors({});
        if (typeof newValue === 'string') {
          setValues({ ...values, title: newValue, mapped_role: newValue });
          setShowRoles(false);
          setInputBlur(false);
        } else if (newValue && newValue.inputValue) {
          setValues({ ...values, title: newValue.inputValue, mapped_role: "" });
          setShowRoles(true);
          setInputBlur(false);
        } else if (newValue) {
          setValues({ ...values, title: newValue.job_role_name, mapped_role: newValue.job_role_name });
          setShowRoles(false);
          setInputBlur(false);
        } else {
          setValues({ ...values, title: "", mapped_role: "" });
          setShowRoles(false);
          setInputBlur(false);
        }
      }}
      inputValue={inputRoleValue}

      onInputChange={onRoleInputChangeHandler}

      onBlur={(event) => {
        if (inputBlur && inputRoleValue !== "") {
          setValues({ ...values, title: inputRoleValue, mapped_role: "" });
          setShowRoles(true);
        }
      }}

      classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

      renderInput={(params) => <TextField {...params} value="" variant={window.innerWidth <= 600 ? 'outlined' : 'standard'} error={errors.title ? true : false} helperText={errors.title ? errors.title : ''} placeholder="Search or add role e.g Sales Associate" classes={{ root: classes.TfRoot }}
        InputLabelProps={{
          shrink: true,
        }} />
      }

      renderOption={(option) => {
        if (option) {
          return (
            <Grid container alignItems="flex-start" direction="column">
              <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.job_role_name} </Typography>
            </Grid>
          )
        }
      }}

      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // add new input value at the top of the dropdown list if value is not empty or already exists in list
        if (params.inputValue !== '') {
          filtered.unshift({
            inputValue: params.inputValue,
            job_role_name: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
    />
    {/* <span className={classes.helper_text}>To select press Enter ↲ | To navigate results use ↑ ↓ </span> */}
  </Grid>;
}

export default WorkTitle;