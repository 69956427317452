import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Grid, TextField, IconButton, Button } from '@material-ui/core';
import styled from 'styled-components';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';

import Location from './Location';
import AnalyticsHandler from '../../AnalyticsHandler';
import { Alert } from '@material-ui/lab';
const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  font-size: 0.75rem;
  color: #797979;
`;

const Input = styled('input')({
    display: 'none',
});

export default function Overview(props) {
    const { classes, data, updateData, updateCompanyInfo, setAlertMessage, setOpenSuccessAlert, setCompanyIdentificationNum } = props;
    const [location, setLocation] = React.useState([]);
    const [sector, setSector] = React.useState([]);
    const [branchLocationValue, setBranchLocationValue] = React.useState([]);
    const [selectedDocument, setSelectedDocument] = React.useState();
    const [selectedDocumentName, setSelectedDocumentName] = React.useState('Choose a file');
    const [locationValue, setLocationValue] = React.useState();
    const [latLong, setLatLong] = React.useState();
    const [selectedImage, setSelectedImage] = React.useState(null);
    // const [value, setValue] = React.useState(null);
    // const [inputValue, setInputValue] = React.useState('');
    // const [options, setOptions] = React.useState([]);

    let initialRender = React.useRef(true);

    const changeCompanyDocument = (e) => {
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "application/pdf" || e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedDocument(e.target.files[0]);
                setSelectedDocumentName(e.target.files[0].name);
            }
        }
    }

    const saveFiles = (e) => {
        AnalyticsHandler.track("CompanyPage_overview_saveDetailsBtn");
        updateCompanyInfo();
    }

    React.useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            var formData = new FormData();
            formData.append('employer_document', selectedDocument);
            formData.append('company_id', localStorage.getItem('company_id'));
            axios.post(BASE_URL + '/employer/upload_document_retool', formData)
                .then(response => {
                    setAlertMessage('Uploaded successfully');
                    setOpenSuccessAlert(true);
                    updateData({ ...data, employer_document_url: response.data.image_url, employer_document_name: selectedDocumentName });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [selectedDocument]);

    React.useEffect(() => {
        if (latLong !== undefined && locationValue !== undefined) {
            updateData({ ...data, latitude: latLong.lat, longitude: latLong.long, company_address: locationValue });
        }
    }, [latLong, locationValue]);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_locations').then((Result) => {
            let locArr = Result.data;
            setLocation(locArr);
        })
    }, []);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_sectors').then((Result) => {
            let secArr = Result.data;
            setSector(secArr);
        })
    }, []);

    React.useEffect(() => {
        if (data.company_branch_location !== undefined && data.company_branch_location.length > 0) {
            let locVal = [];
            for (let i = 0; i < data.company_branch_location.length; i++) {
                const element = data.company_branch_location[i];
                locVal.push({ city: element });
            }
            setBranchLocationValue(locVal);
        }
    }, [data.company_branch_location])

    const [showEmailAlert, setShowEmailAlert] = React.useState(false)

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowEmailAlert(false);
    };

    const changeCompanyLogo = (e) => {
        AnalyticsHandler.track("CompanyPage_overview_changeLogoBtn")
        if (e.target.files[0]) {
            if (!(e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png")) {
                alert("Please upload a valid file");
            } else {
                setSelectedImage(e.target.files[0]);
            }
        }
    }

    React.useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            var formData = new FormData();
            formData.append('employer_image', selectedImage);
            formData.append('company_id', localStorage.getItem('company_id'));
            axios.post(BASE_URL + '/employer/admin_image_upload', formData)
                .then(response => {
                    setAlertMessage('Uploaded successfully');
                    setOpenSuccessAlert(true);
                    updateData({ ...data, company_image_url: response.data.image_url });
                })
                .catch(error => {
                    console.log(error);
                });
            console.log("runn")
        }
    }, [selectedImage])

    return (
        <div className={classes.overview}>
            <div className={classes.profileSubCards}>
                <Typography component='h2' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0' }}>About</Typography>
                <div className={`${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.mr1} ${classes.width_100} `}>
                        <Label>Employer Name*</Label>
                        <TextField fullWidth id="employer-name" value={data.employer_name || ""} variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, employer_name: e.target.value })
                            }}
                        />
                    </div>
                    <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                        <Label>Contact Number*</Label>
                        <TextField fullWidth id="company-contact" type='number' value={data.company_mobile || ""} variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, company_mobile: e.target.value, mobile: e.target.value })
                            }
                            }
                        />
                    </div>
                </div>
                <div className={`${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.mr1} ${classes.width_100} `}>
                        <Label>Company Name*</Label>
                        <TextField fullWidth id="company-name" value={data.company_name || ""} variant='outlined' aria-describedby="my-helper-text"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''} ${classes.position_relative}`}>
                        <Label>Company Email*</Label>
                        <TextField fullWidth id="company-email" value={data.company_email || ""} variant='outlined' aria-describedby="my-helper-text"
                            onClick={e => {
                                setShowEmailAlert(prev => !prev);
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        {showEmailAlert && <Alert icon={false} onClose={handleAlertClose} className={classes.emailAlert} severity="success">
                            Please contact our customer support to change your email.
                        </Alert>}
                    </div>
                </div>
                <div className={classes.my1}>
                    <Label>Company Website*</Label>
                    <TextField fullWidth id="company-website" type='url' variant='outlined' value={data.company_website || ""}
                        onChange={(e) => {
                            updateData({ ...data, company_website: e.target.value, website: e.target.value })
                        }}
                    />
                </div>
                <div className={`${classes.company_type} ${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.company_type_labels} ${classes.mr1} ${classes.width_100}`}>
                        <Label>Company Type*</Label>
                        <Autocomplete
                            options={company}
                            name="company_type"
                            value={data.company_type || ""}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.title
                            }
                            }
                            style={{ flex: 0.75, display: 'inline' }}

                            onChange={(e, newValue) => {
                                if (newValue) {
                                    updateData({ ...data, company_type: newValue.title });
                                }
                            }}

                            classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                            renderInput={(params) => <TextField {...params} variant='outlined' classes={{ root: classes.skillsInput }}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                            }

                            getOptionSelected={(option, value) => option.title === value}

                            renderOption={(option) => {
                                if (option) {
                                    return (
                                        <Grid container alignItems="flex-start" direction="column">
                                            <Typography variant="body2" style={{ opacity: 1, color: 'black' }}>{option.title} </Typography>
                                        </Grid>
                                    )
                                }
                            }}
                        />
                    </div>
                    <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                        <Label>Sector*</Label>
                        <Autocomplete
                            options={sector}
                            name="skill"
                            value={data.sector_name || ""}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.sector_name
                            }
                            }
                            style={{ flex: 0.75, display: 'inline' }}

                            onChange={(e, newValue) => {
                                if (newValue) {
                                    updateData({ ...data, sector_name: newValue.sector_name });
                                }
                            }}

                            classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                            renderInput={(params) => <TextField {...params} variant='outlined' classes={{ root: classes.skillsInput }}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                            }
                            getOptionSelected={(option, value) => option.sector_name === value}

                            renderOption={(option) => {
                                if (option) {
                                    return (
                                        <Grid container alignItems="flex-start" direction="column">
                                            <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.sector_name} </Typography>
                                        </Grid>
                                    )
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={classes.my1}>
                    <Label>Description</Label>
                    <TextField id="outlined-basic" value={data.company_description} multiline maxRows={10} minRows={3} fullWidth variant="outlined"
                        onChange={e => {
                            updateData({ ...data, description: e.target.value, company_description: e.target.value });
                        }}
                    />
                </div>
                <div className={`${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.mr1} ${classes.width_100}`}>
                        <Label>Document Identification Number*</Label>
                        <TextField id="outlined-basic" value={data.company_identification_number || ""} className={` ${classes.input_width}`} fullWidth variant="outlined"
                            onChange={e => {
                                setCompanyIdentificationNum(e.target.value);
                                updateData({ ...data, company_identification_number: e.target.value })
                            }}
                        />
                    </div>
                    <div className={`${classes.width_100} ${window.innerWidth <= 600 ? classes.mt1 : ''}`}>
                        <Label>Company Document*</Label>
                        <div className={`${classes.file_upload}`}>
                            <span className={`${classes.doc_upload} ${classes.fontSize_small}`}>{data.employer_document_url === '' ? selectedDocumentName : data.employer_document_name || ''}</span>
                            {/* <img className={classes.doc_upload_icon} src={data.employer_document_url} style={{ maxWidth: '50px', marginLeft: '1em' }} /> */}
                            <Input accept="image/*" className={classes.upload_input} id="icon-button-file" type="file" onChange={e => changeCompanyDocument(e)} />
                            <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span" style={{ padding: '6px' }}>
                                    <PublishOutlinedIcon fontSize='small' /> <span style={{fontSize: '14px'}}>{data.employer_document_url === '' ? 'Upload Document' : 'Update Document'}</span>
                                </IconButton>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={classes.imgBox}>
                    <img src={data.company_image_url} alt="company_logo" />
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={e => changeCompanyLogo(e)}
                    />
                    <label htmlFor="contained-button-file" className={classes.textAlignCenter} >
                        <Button variant="outlined" color="primary" component="span" size='small'>
                            {data.company_image_url === '' || data.company_image_url === undefined ? 'Add logo' : 'Change logo'}
                        </Button>
                    </label>
                </div>
            </div>
            <div className={`${classes.profileSubCards} ${classes.my1}`}>
                <Typography component='h3' variant='h5' className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Diversity</Typography>
                <div className={`${classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.mr1} ${classes.width_100} `}>
                        <Label>Organization size*</Label>
                        <TextField fullWidth id="employees-count" value={data.number_of_employees || ""} type='number' variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, number_of_employees: e.target.value })
                            }}
                        />
                    </div>
                    <div className={classes.width_100}>
                        <Label>Female Employees</Label>
                        <TextField fullWidth id="female-employees" type='number' value={data.number_of_female_employees || ""} variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, number_of_female_employees: e.target.value })
                            }
                            }
                        />
                    </div>
                </div>
                <div className={`${classes.flexbox} ${classes.my1}`}>
                    <div className={`${classes.mr1} ${classes.width_100}`}>
                        <Label>LGBTQ Employees</Label>
                        <TextField fullWidth id="lgbtq" type='number' value={data.current_lgbtq_count || ""} variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, current_lgbtq_count: e.target.value })
                            }}
                        />
                    </div>
                    <div className={classes.width_100}>
                        <Label>Special Needs Employees</Label>
                        <TextField fullWidth id="special-need" type='number' value={data.current_disabled_count || ""} variant='outlined' aria-describedby="my-helper-text"
                            onChange={e => {
                                updateData({ ...data, current_disabled_count: e.target.value })
                            }}
                        />
                    </div>
                </div>

            </div>
            <div className={classes.profileSubCards}>
                <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Office location(s)</h5>
                <div className={classes.my1}>
                    <Label>Headquarter Address*</Label>
                    <Location
                        //profileObj={profileObj}
                        locationValue={locationValue}
                        setLocationValue={setLocationValue}
                        latLong={latLong}
                        setLatLong={setLatLong}
                        data={data}
                    //   isErrorAboutMe={isErrorAboutMe}
                    //   setIsLocationAutocompletePageOpen={setIsLocationAutocompletePageOpen}
                    />
                </div>
                <div className={classes.my1}>
                    <Label>Branches</Label>
                    <Autocomplete
                        classes={{ root: classes.autoComplete }}
                        multiple
                        freeSolo
                        name="job_location"
                        // ref={locRef}
                        value={branchLocationValue}
                        fullWidth
                        options={location}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option.city
                        }
                        }
                        onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue) { // when value is selected from dropdown
                                let locVal = newValue.map((item, index) => item.city);
                                // setBranchLocations(locVal);
                                updateData({ ...data, company_branch_location: locVal, branch_location: locVal });
                                setBranchLocationValue(newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
                <div className={classes.my1}>
                    <Label>Google Map Link*</Label>
                    <TextField fullWidth id="google-map-url" type='url' variant='outlined' value={data.google_map_url || ""}
                        onChange={(e) => {
                            updateData({ ...data, google_map_url: e.target.value });
                        }
                        }
                    />
                </div>
            </div>
            <div className={`${classes.profileSubCards} ${classes.my1}`}>
                <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Socials</h5>
                <div className={classes.my1}>
                    <Label>Linkedin</Label>
                    <TextField fullWidth id="my-input" type='url' value={data.linkedin_link || ''} variant='outlined' aria-describedby="my-helper-text"
                        onChange={e => {
                            updateData({ ...data, linkedin_link: e.target.value })
                        }}
                    />
                </div>
                <div className={classes.my1}>
                    <Label>Instagram</Label>
                    <TextField fullWidth id="my-input" type='url' value={data.instagram_link || ""} variant='outlined' aria-describedby="my-helper-text"
                        onChange={e => {
                            updateData({ ...data, instagram_link: e.target.value })
                        }}
                    />
                </div>
                <div className={classes.my1}>
                    <Label>Facebook</Label>
                    <TextField fullWidth id="my-input" type='url' value={data.facebook_link || ""} variant='outlined' aria-describedby="my-helper-text"
                        onChange={e => {
                            updateData({ ...data, facebook_link: e.target.value })
                        }}
                    />
                </div>
            </div>
            <Button variant="contained" color="primary" component="span" onClick={saveFiles} className={`${classes.ttc} ${classes.my1} ${classes.saveProfileDetailsBtn}`}>
                Save Details
            </Button>

        </div>
    )
}

const company = [
    { title: 'Private Limited' },
    { title: 'LLP' },
    { title: 'Trust' },
    { title: 'Society' },
    { title: "Section 8 company" },
    { title: 'Public Company' },
    { title: 'Other' },
]
