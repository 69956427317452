import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { ThemeProvider } from '@material-ui/styles';
import MyTheme from '../style/JobAppTheme';
import logo from '../assets/logo_header.png';
import { withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import ApiHandler from '../ApiHandler';
import FeedbackAlert from './FeedbackAlert';
import global_isSignedIn from '../views/discovery/global_isSignedIn';
import AnalyticsHandler from '../AnalyticsHandler';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Jobs from '../views/jobs/Jobs';
import CloseIcon from '@material-ui/icons/Close';
import { AccountCircleOutlined, CloseOutlined, InfoOutlined } from '@material-ui/icons';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined';
import Popover from '@material-ui/core/Popover';
import Notifications from './Notifications';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.45) !important',
  },
  paperScrollPaper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    maxHeight: 'none !important',
  },
  grow: {
    flexGrow: 1,
    '@media (max-width: 600px)': {
      position: 'fixed',
      bottom: 0,
      backgroundColor: '#fff',
      left: 0,
      right: 0,
      boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.17)'
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerButton: {
    textTransform: "none",
    width: 100,
    height: 35,
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  headerButton_sec: {
    backgroundColor: "transparent",
    color: "#14967B",
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    // display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  mobileMenuPaper: {
    top: '60px !important',
    right: '0 !important',
    position: 'absolute',
    width: '50vw',
    left: 'initial !important'
  },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  menuListItem: {
    fontSize: '0.9em',
    height: '40px',
    padding: '10px',
    '& button': {
      padding: '0 8px 0 0',
      '& span': {
        '& span': {
          '& svg': {
            width: '0.8em',
            height: '0.8em',
          }
        }
      }
    }
  },
  mobileMenu: {
    '@media (min-width: 600px)': {
      display: 'none'
    },
  },
  flexContainer: {
    height: 60,
    flexGrow: 1,
  },
  indicator: {
    height: 3,
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
  },
  selected: {
    fontWeight: 700,

  },
  navBtns: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  notificationDialogRoot: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  mobileNotificationIcon: {
    display: 'none',
    '@media (max-width: 600px)': {
      display: 'inline-flex'
    },
  },
  desktopNotificationIcon: {
    display: 'inline-flex',
    marginRight: 6,
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  desktopNotificationContainer: {
    width: '35vw',
    position: 'fixed',
    top: 60,
    backgroundColor: '#fff',
    zIndex: 999,
    right: 25,
    boxShadow: '0px 0px 4px 1px rgb(0 0 0 / 25%)',
    height: '67vh',
    zIndex: 1100,
    overflow: 'auto',
    borderRadius: 5
  },
  bold: {
    fontWeight: 700
  },
  fontSmall: {
    fontSize: 12
  },
  fontMd: {
    fontSize: 14
  },
  fontLarge: {
    fontSize: 16
  },
  supportTab: {
    '@media (max-width: 600px)': {
      display: 'none'
    },
  }
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function MainAppBar(props) {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  // const [navValue, setNavValue] = React.useState(0);
  const [navValue, setNavValue] = React.useState(props.navValue);
  const history = useHistory();
  const [localIsLoggedIn, setLocalIsLoggedIn] = React.useState(props.isSignedIn);
  const [openJobPostingForm, setOpenJobPostingForm] = React.useState(false);


  React.useEffect(() => {
    setLocalIsLoggedIn(props.isSignedIn)
  }, [props.isSignedIn])

  // let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;

  const toggleLogin = () => {
    setIsLoggedIn((prev) => !prev);
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    props.setNavValue(newValue);
    setTimeout(() => {
      if (newValue === 0) {
        AnalyticsHandler.track('discoveryPage_tab_btnClick');
        history.push({
          pathname: '/'
        });
      } else if (newValue === 1) {
        AnalyticsHandler.track('jobseekersPage_Tab_btnClick');
        history.push({
          pathname: '/discover/any/anywhere'
        });
      }
      else if (newValue === 2) {
        AnalyticsHandler.track('invitedPage_Tab_btnClick');
        history.push({
          pathname: '/invited'
        });
      }
      else if (newValue === 3) {
        AnalyticsHandler.track('jobsPage_tab_btnClick');
        history.push({
          pathname: '/jobs'
        });
      }
      else if (newValue === 4) {
        AnalyticsHandler.track('company_tab_btnClick');
        history.push({
          pathname: '/profile'
        });
      }
      else if (newValue === 5) {
        AnalyticsHandler.track('supportPage_tab_btnClick');
        history.push({
          pathname: '/support'
        });
      }
      // else if (newValue === 4) {
      //   AnalyticsHandler.track('companyPage_tab_btnClick');
      //   history.push({
      //     pathname: '/company'
      //   });
      // }
    }, 200)
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const menuId = 'primary-search-account-menu';
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem>Account Settings</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
  //   </Menu>
  // );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const signOut = () => {
    // test code to manage the session cookie issues

    AnalyticsHandler.track('signOut_btnClick');
    AnalyticsHandler.reset();

    let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
    if (!sessionCookie) {
      localStorage.setItem('company_id', "");
      localStorage.setItem('company_email_id', "");
      localStorage.setItem('company_name', "");
      localStorage.setItem('employer_name', "");
      localStorage.removeItem('is_logged_in');
      props.setIsSignedIn(false);
      global_isSignedIn.isSignedIn = false;
      handleAlert('You have successfully logged out! Please log in to access all your account information.', 'success');
      return;
    }

    ApiHandler.logout(response => {
      if (response.status == 200 || (response.response && response.response.data.message === "Session Expire")) {
        handleAlert('You have successfully logged out! Please log in to access all your account information.', 'success');
        localStorage.setItem('company_id', "");
        localStorage.setItem('company_email_id', "");
        localStorage.setItem('company_name', "");
        localStorage.setItem('employer_name', "");
        localStorage.removeItem('is_logged_in');
        props.setIsSignedIn(false);
        global_isSignedIn.isSignedIn = false;
      }
      else {
        handleAlert('Oops! Something went wrong. Please try again.', 'error');
      }
    });
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      classes={{ paper: classes.mobileMenuPaper }}
    >
      {/* <MenuItem
        classes={{ root: classes.menuListItem }}
      >
        <IconButton aria-label="about" color="inherit">
          <InfoOutlined />
        </IconButton>
        <p>About Us</p>
      </MenuItem> */}
      <MenuItem
        classes={{ root: classes.menuListItem }}
        onClick={() => {
          history.push({
            pathname: '/terms-and-conditions'
          });
        }}
      >
        <IconButton aria-label="t&c" color="inherit">
          <DescriptionOutlinedIcon />
        </IconButton>
        <p>Terms & Conditions</p>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuListItem }}
        onClick={() => {
          history.push({
            pathname: '/privacy-policy'
          });
        }}
      >
        <IconButton aria-label="privacy" color="inherit">
          <PolicyOutlinedIcon />
        </IconButton>
        <p>Privacy Policy</p>
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuListItem }}
        onClick={() => {
          history.push({
            pathname: '/support'
          });
        }}
      >
        <IconButton aria-label="privacy" color="inherit">
          <HeadsetMicOutlinedIcon />
        </IconButton>
        <p>Support</p>
      </MenuItem>
      {!localIsLoggedIn ? <MenuItem
        classes={{ root: classes.menuListItem }}
        onClick={() => props.handleDrawer()}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlined />
        </IconButton>
        <p>Sign In</p>
      </MenuItem> : <MenuItem
        classes={{ root: classes.menuListItem }}
        onClick={signOut}
      >
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircleOutlined />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>}
    </Menu>
  );


  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false && props.setIsSignedIn) {
      props.setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      props.setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  //notification start
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false);

  const closeNotificationDialog = () => {
    setOpenNotificationDialog(false);
  }

  const [deskNotifyBarDisplay, setDeskNotifyBarDisplay] = React.useState(false);
  const [notificationBadgeCount, setNotificationBadgeCount] = React.useState(0);

  return (
    <ThemeProvider theme={MyTheme}>
      {/* props.handleDrawer has been used to differentiate between mobile and desktop app bars */}
      <AppBar position="fixed" className="app__header" style={{ minHeight: props.handleDrawer ? 60 : 50 }}>
        <Toolbar style={{ minHeight: props.handleDrawer ? 60 : 50, margin: props.handleDrawer ? 'unset' : 'auto', justifyContent: 'space-between' }}>
          <Button
            id="meetLogo_id"
            onClick={() => {
              AnalyticsHandler.track('homeLogo_btnClick');
              history.push({
                pathname: '/'
              });
            }}>
            <img alt="meet logo" width={80} height={33} style={{ marginRight: '11%' }} src={logo} />
          </Button>
          {!props.isFullProfilePage && <div style={{ display: 'flex', alignItems: 'center' }} >
            {localIsLoggedIn && <IconButton className={classes.mobileNotificationIcon} aria-label="show 10 new notifications" color="inherit">
              <Badge badgeContent={notificationBadgeCount} color="error">
                <NotificationsIcon onClick={() => setOpenNotificationDialog(true)} />
              </Badge>
            </IconButton>}
            {!isMobileMenuOpen ?
              <MenuIcon fontSize='medium' onClick={(event) => setMobileMoreAnchorEl(event.currentTarget)} className={classes.mobileMenu} /> :
              <CloseOutlined fontSize='medium' onClick={(event) => setMobileMoreAnchorEl(event.currentTarget)} className={classes.mobileMenu} />}
          </div>}
          {renderMobileMenu}
          {props.handleDrawer ?  //put this so that it can work without buttons in profile laptop page
            <div style={{ display: (window.location.pathname === '/privacy-policy' || window.location.pathname === '/terms-and-conditions') ? 'none' : 'initial' }} className={classes.grow}>
              <div className={classes.sectionDesktop}>
                <Tabs
                  value={navValue}
                  onChange={handleNavChange}
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  aria-label="navigation header bar"
                  className="navTabBar"
                  classes={{ flexContainer: classes.flexContainer, indicator: classes.indicator }}
                >
                  <Tab id="home_tab_id" label="Home" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 0 ? <HomeIcon /> : <HomeOutlinedIcon />} {...a11yProps(0)} />
                  <Tab id="discovery_tab_id" label="Discovery" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 1 ? <ExploreRoundedIcon /> : <ExploreOutlinedIcon />} {...a11yProps(1)} />
                  <Tab id="invited_tab_id" label="Invited" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 2 ? <PeopleAltRoundedIcon /> : <PeopleAltOutlinedIcon />} {...a11yProps(2)} />
                  <Tab id="openings_tab_id" label="Jobs" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 3 ? <BusinessCenterRoundedIcon /> : <BusinessCenterOutlinedIcon />} {...a11yProps(3)} />
                  <Tab id="company_tab_id" label="Profile" className="navTab" classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 4 ? <BusinessRoundedIcon /> : <BusinessOutlinedIcon />} {...a11yProps(4)} />
                  <Tab id="support_tab_id" label="Support" className={`${classes.supportTab} navTab`} classes={{ labelIcon: classes.labelIcon, selected: classes.selected }} icon={navValue === 5 ? <HeadsetMicRoundedIcon /> : <HeadsetMicOutlinedIcon />} {...a11yProps(5)} />
                  {/* <Tab id="analytics_tab_id" label="Analytics" className="navTab" classes={{labelIcon: classes.labelIcon, selected: classes.selected}} icon={navValue === 4 ? <AssessmentRoundedIcon /> : <AssessmentOutlinedIcon />} {...a11yProps(4)} /> */}

                </Tabs>

                {/* {(!sessionCookie && !localStorage.getItem("is_logged_in")) && ( */}
                {(!localIsLoggedIn) && (
                  <div className={classes.navBtns}>
                    <ThemeProvider theme={MyTheme}>
                      <Button id="postjob_btn_id" variant="contained" color="primary" className={`${classes.headerButton} ${classes.headerButton_sec}`} style={{ marginRight: 15 }} onClick={props.handleDrawer}>
                        Post Job
                      </Button>
                      <Button
                        id="signin_btn_id"
                        variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`}
                        onClick={() => {
                          props.handleDrawer();

                          if (props.ref_last_active_sort && props.ref_last_active_sort.current)
                            // props.ref_last_active_sort.current.click();
                            props.setFlagSortRecentlyActive(false);
                          //made this change so that the analytics.track for sorting button is not called 
                          else

                            if (props.ref_nearest_sort && props.ref_nearest_sort.current)
                              // props.ref_nearest_sort.current.click();
                              props.setFlagSortNearest(false);

                            else

                              if (props.ref_most_exp_sort && props.ref_most_exp_sort.current)
                                // props.ref_most_exp_sort.current.click();
                                props.setFlagSortMostExperienced(false);

                        }}>
                        Sign In
                      </Button>
                    </ThemeProvider>
                  </div>
                )}
                {/* {(sessionCookie && localStorage.getItem("is_logged_in")) ? */}
                {(localIsLoggedIn) ?
                  <div className={classes.navBtns}>
                    <ThemeProvider theme={MyTheme}>
                      <IconButton onClick={() => setDeskNotifyBarDisplay(p => !p)} className={classes.desktopNotificationIcon} aria-label="show 10 new notifications" color="inherit">
                        <Badge badgeContent={notificationBadgeCount} color="error">
                          <NotificationsIcon />
                        </Badge>
                      </IconButton>
                      <Button id="postjob_btn_id" variant="contained" color="primary" className={`${classes.headerButton} ${classes.headerButton_pri}`} style={{ marginRight: 15 }}
                        onClick={() => {
                          //window.open('https://forms.gle/ho3g7mYAcmexduhH9','_blank').focus();
                          // history.push({
                          //   pathname: '/jobs'
                          // });
                          AnalyticsHandler.track('Navbar_postJobBtn');
                          setOpenJobPostingForm(true);
                        }}
                      >
                        Post Job
                      </Button>
                      <Button id="signout_btn_id" variant="contained" onClick={signOut} className={`${classes.headerButton} ${classes.headerButton_sec}`}>
                        Sign Out
                      </Button>
                    </ThemeProvider>
                  </div>
                  : null}

                {/* <IconButton aria-label="show 4 new mails" color="inherit">
                <HelpRoundedIcon />
              </IconButton>

              <IconButton aria-label="show 10 new notifications" color="inherit">
                <Badge badgeContent={10} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}

                {/* <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton> */}

              </div>
              {/* <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div> */}
            </div> : null}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
      <Dialog
        open={openNotificationDialog}
        onClose={closeNotificationDialog}
        fullScreen
      >
        <DialogContent classes={{ root: classes.notificationDialogRoot }}>
          <Grid style={{ height: 60, position: 'fixed', top: 0, left: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)' }} alignItems='center' container>
            <ArrowBackIcon style={{ margin: '0 20px 0 10px' }} onClick={() => setOpenNotificationDialog(false)} />
            <Typography variant='body1' >Notifications</Typography>
          </Grid>
          <Notifications classes={classes} localIsLoggedIn={localIsLoggedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setOpenNotificationDialog} variant='mobile' />
        </DialogContent>
      </Dialog>

      {/* desktop notifications */}
      <div style={{ display: deskNotifyBarDisplay ? 'block' : 'none' }} className={classes.desktopNotificationContainer}>
        <Grid style={{ height: 60, position: 'sticky', top: 0, backgroundColor: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.17)', padding: '0 2em', marginBottom: 2 }} alignItems='center' justifyContent='space-between' container>
          <Typography variant='h6' >Your Notifications</Typography>
          <CloseIcon style={{ cursor: 'pointer' }} onClick={() => setDeskNotifyBarDisplay(false)} />
        </Grid>
        <Notifications classes={classes} localIsLoggedIn={localIsLoggedIn} setNotificationBadgeCount={setNotificationBadgeCount} setNotificationBar={setDeskNotifyBarDisplay} variant='desktop' />
      </div>
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
      {openJobPostingForm &&
        <div>
          <Dialog classes={{ paperScrollPaper: classes.paperScrollPaper, container: classes.dialogContainer }} maxWidth='xl' open aria-labelledby="form-dialog-title">
            <DialogContent>
              <Jobs />
            </DialogContent>
          </Dialog>
          <CloseIcon fontSize='large' style={{ position: 'fixed', top: '3%', right: '2%', color: '#fff', cursor: 'pointer', zIndex: '1500' }} onClick={() => setOpenJobPostingForm(false)} />
        </div>
      }
    </ThemeProvider>
  );
}