import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import StarIcon from '@material-ui/icons/Star';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
import { Snackbar } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import Overview from '../views/company/Overview';
import Followers from '../views/company/Followers';
import Review from '../views/company/Review';
// import Settings from '../views/company/Settings';
// import Personnel from '../views/company/Personnel';
// import Account from '../views/company/Account';
import { Alert } from '@material-ui/lab';
import AnalyticsHandler from '../AnalyticsHandler';
import styled from 'styled-components';
import SnackBarGeneric from '../views/talentpool/SnackBarGeneric';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Overview_2 from '../views/company/Overview_2';
const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

// const Label = styled('label')`
//   padding: 0 0 4px;
//   line-height: 1.5;
//   display: block;
//   font-size: 0.75rem;
//   color: #797979;
// `;

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.3em',
            backgroundColor: '#D5F5ED'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "23px",
        },
        '.MuiAutocomplete-tag': {
            backgroundColor: '#fff',
            border: '1px solid #55c6a9'
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.secondary.main
        },
        '.MuiFormControlLabel-labelPlacementStart': {
            marginLeft: '0px'
        },
        '.MuiTabs-flexContainer': {
            justifyContent: 'center'
        },
        '.MuiBox-root': {
            padding: 0
        },
        '.MuiTabs-indicator': {
            display: 'none !important'
        },
        '.MuiTab-textColorInherit.Mui-selected': {
            color: '#5BC2A8'
        },
        '.MuiTab-textColorInherit': {
            color: '#AFAFAF',
            opacity: '1'
        },
        '.MuiTab-root': {
            '@media (min-width: 600px)': {
                minWidth: '10vw'
            }
        },
    },
    heading: {
        margin: '1em 0 0 0',
        textAlign: 'center',
        fontSize: '1.2em'
    },
    company_card: {
        backgroundColor: 'white',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        // minHeight: '85vh',
        // maxHeight: '72vh',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        margin: '3em auto',
        maxWidth: '60vw',
        minWidth: '60vw',
        // minHeight: '80vh',
        borderRadius: '8px',
        [theme.breakpoints.down('md')]: {
            minWidth: '70vw',
        },
        '@media (max-width: 600px)': {
            maxWidth: '100vw',
            minWidth: '100vw',
            boxShadow: 'none',
            backgroundColor: 'transparent'
        }
    },
    reviewInviteCard: {
        minHeight: '30vh',
        backgroundColor: 'white',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        maxWidth: '60vw',
        margin: '0 auto 20px auto',
        borderRadius: '8px',
        padding: '1em 4em',
        [theme.breakpoints.down('md')]: {
            maxWidth: '70vw',
            minWidth: '70vw',
        },
        '@media (max-width: 600px)': {
            maxWidth: '80vw',
            margin: '0 auto 5em auto',
            padding: '1em'
        }
    },
    bgCover: {
        minHeight: '30vh',
        borderBottom: '1px solid #E0E0E0',
        background: '#F5F5F5',
        position: 'relative',
        padding: theme.spacing(4),
        '& p:first-child': {
            fontSize: '1.1em',
            fontWeight: 'bold',
        },
        '& p:last-child': {
            fontSize: '0.9rem',
        },
        '& input': {
            display: 'none'
        },
        '& label': {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            '& span': {
                textTransform: 'none',
            }
        }
    },
    overview: {
        padding: '2em 4em',
        '@media (max-width: 600px)': {
            padding: '1em'
        }
    },
    overview_heading_section: {
        paddingTop: '2em',
        color: '#737373',
        borderRight: '1px solid #afafaf',
        '& > p': {
            marginRight: 8
        },
        '@media (max-width: 600px)': {
            border: 'none',
            justifyContent: 'space-between',
            paddingTop: 0,
            marginBottom: '1em'
        },
    },
    overview_section_details: {
        paddingTop: '2em',
        paddingLeft: '2em',
        '@media (max-width: 600px)': {
            paddingTop: 0,
            paddingLeft: 0
        },
    },
    overviewFlexContainer: {
        flexDirection: 'row',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            padding: '1em',
            boxShadow: '0px 0px 4px 0px rgb(0 0 0 / 25%)',
            backgroundColor: '#fff',
            borderRadius: 3,
            margin: '0.5em 0'
        },
    },
    saveProfileDetailsBtn: {
        '@media (max-width: 600px)': {
            width: '100%'
        }
    },
    profileSubCards: {
        '@media (max-width: 600px)': {
            // backgroundColor: '#fff',
            // boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)'
        }
    },
    review: {
        padding: '2em 4em',
        '@media (max-width: 600px)': {
            padding: 0,
            margin: '1em'
        }
    },
    reviewSections: {
        '@media (max-width: 600px)': {
            backgroundColor: '#fff',
            padding: '1em'
        }
    },
    employeeReviews: {
        margin: '1em 5em 1em 0',
        '@media (max-width: 600px)': {
            margin: '1em 0 1em 0'
        }
    },
    company_info: {
        maxHeight: '21vh'
    },
    documentDownload: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
        padding: '8px 15px',
        borderRadius: 3,
        textDecoration: 'none',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    imgBox: {
        position: 'relative',
        // display: 'inline-block',
        // top: '2rem',
        // left: '2rem',
        margin: '2em 0 1em 0',
        maxWidth: '120px',
        maxHeight: '120px',
        minHeight: '120px',
        minWidth: '120px',
        border: "1px solid #AFAFAF",
        borderRadius: '4px',
        backgroundColor: '#F5F5F5',
        ' & img': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '100%',
            maxHeight: '100%',
            boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'
        },
        ' & input': {
            display: 'none'
        },
        '& label': {
            position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
            bottom: '5%',
            width: '100%',
            visibility: 'hidden',
            // '@media (max-width: 600px)': {
            //     position: 'static',
            //     visibility: 'visible'
            // },
            '& span': {
                textTransform: 'none',
            }
        },
        '&:hover': {
            cursor: 'pointer',
        },
        '&:hover img': {
            opacity: '0.6',
        },
        '&:hover label': {
            visibility: 'visible',
            opacity: '1'
        }
    },
    company_info_text: {
        position: 'relative',
        justifyContent: 'space-evenly',
        minHeight: '16vh',
        top: '-3.5rem',
        width: '70%',
        left: '8%',
        display: 'inline-flex !important',
        '@media (max-width: 1094px)': {
            width: '65%',
        },
    },
    editInput: {
        width: '70%',
        '& input': {
            padding: '12px 6px',
        },
    },
    emailAlert: {
        position: 'absolute',
        padding: '0px',
        backgroundColor: '#55c6a9d4',
        zIndex: 9,
        right: 0,
        '& div:nth-child(1)': {
            padding: theme.spacing(1),
            color: '#fff'
        },
        '& div:nth-child(2)': {
            padding: '0 16px 0 0',
        },
    },
    editDetailsIcon: {
        position: 'absolute',
        bottom: '0.2em',
        right: '-1.2em',
        cursor: 'pointer',
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center'
    },
    flexboxCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    justify_flexEnd: {
        justifyContent: 'flex-end'
    },
    justify_flexStart: {
        justifyContent: 'flex-start'
    },
    justify_space_bw: {
        justifyContent: 'space-between'
    },
    justify_center: {
        justifyContent: 'center'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    mr1: {
        marginRight: '1rem'
    },
    mr2: {
        marginRight: '0.5rem'
    },
    my1: {
        margin: '1rem 0'
    },
    my2: {
        margin: '2rem 0'
    },
    my0: {
        margin: '0.6em 0'
    },
    myMin: {
        margin: '0.3em 0'
    },
    mt1: {
        marginTop: '1em'
    },
    mt2: {
        marginTop: '2em'
    },
    px1: {
        padding: '0.5rem 0'
    },
    py1: {
        padding: '0.5rem 0'
    },
    width_100: {
        width: '100%'
    },
    fontSize_small: {
        fontSize: '0.8rem'
    },
    fontSize_medium: {
        fontSize: '0.9rem'
    },
    fontSize_regular: {
        fontSize: '1rem'
    },
    fontSize_large: {
        fontSize: '1.1rem'
    },
    fontSize_xl: {
        fontSize: '1.2rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    semi_bold: {
        fontWeight: '500'
    },
    color_primary: {
        color: theme.palette.primary.main
    },
    color_secondary: {
        color: theme.palette.secondary.main
    },
    color_light: {
        color: '#737373'
    },
    cursor_pointer: {
        cursor: 'pointer',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    ttc: {
        textTransform: 'capitalize'
    },
    ttn: {
        textTransform: 'none'
    },
    tdn: {
        textDecoration: 'none'
    },
    helperText: {
        color: '#AFAFAF'
    },
    tabs_div: {
        flexGrow: 1,
        // padding: '2em 0',
        // borderTop: `1px solid ${theme.palette.divider}`,
        // marginTop: '2em'
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '100%',
        marginTop: '-0.9em',
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: 99,
        boxShadow: '0px 4px 6px rgb(0 0 0 / 17%)',
        '& div': {
            '& div': {
                '& button': {
                    minHeight: '40px !important',
                    paddingTop: '15px !important',
                    '& span': {
                        textTransform: 'capitalize !important',
                        '& svg': {
                            marginRight: '6px',
                            marginBottom: '0 !important',
                        }
                    }
                }
            }
        }
    },
    tab_root: {
        flexDirection: 'row',
    },
    // overview: {
    //     padding: '0px 25px',
    // },
    company_type: {
        justifyContent: 'space-evenly',
    },
    settings_label: {
        '& span:last-child': {
            fontSize: '0.88rem',
        },
        marginTop: '-5px',
        justifyContent: 'space-between',
    },
    cw_details: {
        position: 'relative',
        border: '1px solid #5BC2A8',
        borderRadius: '4px',
        padding: theme.spacing(2),
    },
    editIcon: {
        position: 'absolute',
        right: '2%',
        top: '5%',
        cursor: 'pointer',
    },
    doc_upload: {
        border: '1px solid #AFAFAF',
        borderRadius: '4px',
        padding: '2px 10px',
        width: '50%',
        overflow: 'hidden',
        height: '1em',
        '@media (max-width: 600px)': {
            width: '92%',
        },
    },
    editDialogPaper: {
        width: '80vw',
        '@media (max-width: 600px)': {
            minWidth: '90vw',
        },
    },
    file_upload: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid #ccc',
        borderRadius: 3,
        '@media (max-width: 600px)': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        '& > label': {
            '& > span': {
                '@media (max-width: 600px)': {
                    width: '80vw',
                    // backgroundColor: '#55c6a966',/
                    borderRadius: 5,
                    marginTop: '0.5em'
                },
            }
        }
    },
    togBtnSizeSmall: {
        padding: '1px 12px',
        backgroundColor: '#FFFFFF',
        marginLeft: '0!important',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        }
    },
    togBtnLabel: {
        fontSize: 14,
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#555',
    },
    togBtnSelected: {
        backgroundColor: '#14967B!important',
        color: '#ffffff!important',
        '& > span': {
            color: '#ffffff!important',
        }
    },
    applicant_profile: {
        '& > div': {
            margin: '15px auto 4em auto !important'
        }
    },
    slideBtn: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: '1px solid #14967B',
        cursor: 'pointer',
        zIndex: 99
    },
    next: {
        right: '5%',
    },
    prev: {
        left: '5%',
    },
    disabledBtn: {
        display: 'none'
    },
    position_relative: {
        position: 'relative'
    },
    viewChanger: {
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    inviteActionBtn: {
        padding: '1em 1.5em',
        justifyContent: 'flex-start'
    },
    followersInput: {
        '& div': {
            '& input': {
                fontSize: '0.9em'
            }
        }
    },
    scrollableDiv: {
        padding: '0 3em',
        '@media (max-width: 600px)': {
            padding: '0 1em',
            marginBottom: '2em'
        }
    },
    tglBtnLabel: {
        textTransform: 'capitalize'
    },
    tglBtnSelected: {
        backgroundColor: '#14967B!important',
        color: '#ffffff!important',
    },
    bigIconRect: {
        position: 'relative',
        margin: '0 20px 0 0',
        width: '8.7em',
        zIndex: 9,
        height: 35,
        padding: 5,
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        color: '#14967B',
        border: '1px solid #14967B',
        fontSize: '0.875rem',
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#b9f0e1',
            //color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    actionBtns: {
        '@media (max-width: 600px)': {
            marginRight: '0.5em',
            width: '90%',
            '& > span': {
                '& > svg': {
                    fontSize: '1rem'
                }
            }
        }
    },
    followersDialogPaper: {
        minHeight: '65vh',
        maxHeight: '68vh',
        minWidth: '55vw',
        maxWidth: '60vw',
        '@media (max-width: 600px)': {
            minHeight: '100vh',
            maxHeight: '100vh',
            minWidth: '100vw',
            maxWidth: '100vw',
        }
    },
    dialogRolesSubHeadingContainer: {
        width: '102%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        marginLeft: '-1%',
        zIndex: 99,
        '@media (max-width: 600px)': {
            top: '-8px'
        }
    },
    dialogRolesSubHeading: {
        padding: '7% 0 5% 1%',
        marginTop: '-4%',
        backgroundColor: '#fff'
    },
    jobseekerReview: {
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        paddingBottom: '1em',
        '&:last-child': {
            border: 'none'
        }
    },
    customRatings: {
        width: 'auto',
        '& span': {
            '& span': {
                '& svg': {
                    fill: 'currentColor',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                    fontSize: '2rem',
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    flexShrink: 0,
                    userSelect: 'none',
                }
            }
        }
    },
    customRatingsIcon: {
        '& svg': {
            fill: 'currentColor',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fontSize: '2rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexShrink: 0,
            userSelect: 'none',
        }
    }
}));



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CompanyProfile(props) {
    const classes = useStyles();
    const location = useLocation();

    const [value, setValue] = React.useState(location && location.state ? location.state.profilePageTabVal : 0);
    const [data, updateData] = React.useState([]);
    const [companyDetails, setCompanyDetails] = React.useState([]);
    const [companyIdentificationNum, setCompanyIdentificationNum] = React.useState(null);
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [followersLoader, setFollowersLoader] = React.useState(false);
    const [reviewLoader, setReviewLoader] = React.useState(false);
    const [getReviewLabel, setGetReviewLabel] = React.useState('');
    const [refresh, doRefresh] = React.useState(false);

    // let initialRender = React.useRef(true);

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            AnalyticsHandler.track("CompanyPage_overviewTab");
        } else if (newValue === 1) {
            AnalyticsHandler.track("CompanyPage_followersTab");
        } else if (newValue === 2) {
            AnalyticsHandler.track("CompanyPage_reviewsTab");
        }
        setValue(newValue);
    };
    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    };


    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    };

    const updateCompanyInfo = (setModal) => {
        axios.put(BASE_URL + '/employer_profile/companyupdate', {
            ...data,
            company_identification_number: companyIdentificationNum,
            company_id: localStorage.getItem('company_id'),
        })
            .then((response) => {
                setAlertMessage('Saved Successfully');
                setOpenSuccessAlert(true);
                setCompanyIdentificationNum(null);
                doRefresh(p => !p);
                setModal(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    React.useEffect(() => {
        let company_id = localStorage.getItem('company_id');
        axios.get(BASE_URL + `/employer_profile/getcompanyinfo/${company_id}`)
            .then((response) => {
                setCompanyDetails(response.data);
                updateData(response.data)
                // setInitialValues(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [value === 0, refresh]);

    return (
        <>
            <Helmet>
                <title>Company Profile - {value === 0 ? 'Overview' : value === 1 ? 'Followers' : value === 2 ? 'Reviews' : ''}</title>
                <meta name='description' content='Check details about the company' />
                {/* <link rel='canonical' href='/openings' /> */}
            </Helmet>
            <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}
                indicatorColor="primary"
                textColor="inherit"
                centered
            >
                <Tab classes={{ wrapper: classes.tab_root }} icon={<PersonAddOutlinedIcon />} label="Overview" {...a11yProps(0)} />
                <Tab classes={{ wrapper: classes.tab_root }} icon={<PeopleOutlinedIcon />} label="Followers" {...a11yProps(2)} />
                <Tab classes={{ wrapper: classes.tab_root }} icon={<StarIcon />} label="Reviews" {...a11yProps(3)} />
                {/* <Tab classes={{ wrapper: classes.tab_root }} icon={<PeopleOutlinedIcon />} label="Personnel" {...a11yProps(1)} />
                    <Tab classes={{ wrapper: classes.tab_root }} icon={<StarOutlineOutlinedIcon />} label="Reviews" {...a11yProps(2)} />
                    <Tab classes={{ wrapper: classes.tab_root }} icon={<AccountCircleOutlinedIcon />} label="My Account" {...a11yProps(3)} />
                    <Tab classes={{ wrapper: classes.tab_root }} icon={<SettingsOutlinedIcon />} label="Settings" {...a11yProps(4)} /> */}
            </Tabs>
            <div className={classes.company_card} style={{ overflow: value === 1 ? 'hidden' : 'auto' }}>
                {/* {value === 0 && <Typography className={classes.heading} component='h1' variant='h5'>My Profile</Typography>} */}
                {/* <div className={`${classes.bgCover}`} >
                <div>
                    <p className={classes.textAlignCenter}>Upload company images and videos</p>
                    <p className={classes.textAlignCenter}>Showoff your office to potential employees.</p>
                    <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        // onChange={event => {
                        //     let selectedFiles = event.target.files[0];
                        //     // let filesArr = Array.from(selectedFiles);
                        //     setBgImage([{ selectedFiles: selectedFiles.name }]);
                        //     console.log(event.target.files[0].name);
                        //     // console.log(filesArr);
                        //     console.log(bgImage)
                        // }
                        // }
                    />
                    <label htmlFor="contained-button-file" className={classes.textAlignCenter} >
                        <Button variant="outlined" color="primary" component="span">
                            Upload photos
                        </Button>
                    </label>
                </div>
            </div> */}
                <div className={`${classes.tabs_div}`}>
                    <TabPanel value={value} index={0}>
                        {/* <Overview classes={classes} data={data} updateData={updateData} updateCompanyInfo={updateCompanyInfo} setCompanyIdentificationNum={setCompanyIdentificationNum} setAlertMessage={setAlertMessage} setOpenSuccessAlert={setOpenSuccessAlert} /> */}
                        <Overview_2 classes={classes} companyDetails={companyDetails} data={data} updateData={updateData} updateCompanyInfo={updateCompanyInfo} setCompanyIdentificationNum={setCompanyIdentificationNum} setAlertMessage={setAlertMessage} setOpenSuccessAlert={setOpenSuccessAlert} doRefresh={doRefresh} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Followers classes={classes} loader={followersLoader} setLoader={setFollowersLoader} signInStatus={props.signInStatus} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Review classes={classes} loader={reviewLoader} setLoader={setReviewLoader} data={data} setGetReviewLabel={setGetReviewLabel} />
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                    <Personnel classes={classes} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Review classes={classes} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <Account classes={classes} />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <Settings classes={classes} />
                </TabPanel> */}
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                        <Alert icon={false} onClose={handleCloseAlert} severity="success" style={{ backgroundColor: '#14967B', color: '#fff' }}>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </div>

            </div>
            {value === 2 && <div className={classes.reviewInviteCard}>
                <div className={`${classes.my2}`}>
                    <Typography component='span' gutterBottom className={`${classes.bold}`}>{getReviewLabel}!</Typography>
                    <Typography gutterBottom component='p' className={`${classes.fontSize_medium} ${classes.my1} ${classes.helperText}`}>Invite your employees and co-workers to review your company on Meet.</Typography>
                </div>
                {/* <div className={classes.my1}>
                    <Label>Mobile Number</Label>
                    <TextField id="outlined-basic" variant="outlined" />
                </div>
                <div className={classes.my1}>
                    <Label>Message*</Label>
                    <TextField id="outlined-basic" variant="outlined" multiline maxRows={10} minRows={3} fullWidth />
                </div> */}
                <div className={`${classes.my1} ${classes.flexbox}`}>
                    {/* <Button variant="contained" color="primary" component="span" className={`${classes.ttc} ${classes.mr1}`}>
                        Send Invite
                    </Button> */}
                    <CopyToClipboard text={'https://findwork.meetworks.in/' + `profile`} >
                        <Button
                            className={`${classes.bigIconRect} ${classes.ttc}`}
                            // style={{ width: 150, left: '-10px', bottom: 10, width: 100, border: 'none' }}
                            variant="outlined" id="shareButton"
                            onClick={() => { AnalyticsHandler.track('CompanyPage_reviews_getLinkBtn'); handleSnackBar(`Link copied. Share Now!`) }}
                        > Get link </Button>
                    </CopyToClipboard>
                </div>
            </div>}
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
        </>
    )
}
