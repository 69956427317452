import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login_2 from "../views/Login/Login_2.jsx";
import Algolia_Search_2 from "../views/discovery/Algolia_Search_2";
import Invited from "../views/talentpool/Invited";
import Support from "../views/support/Support";
import Jobs from "../views/jobs/Jobs";
import Company from "../views/company/Company";
import Error from "../views/Login/Error"
import Analytics from "../views/analytics/Analytics"
import Profile_page from "../views/Profile/profile"
import Openings from "../views/openings/Openings"
import ResetPassword from '../views/resetPassword/Index';
import { PrivacyPolicy } from "../components/PrivacyPolicy.js";
import Terms from "../components/T&c.js";
import WBDashboard from "../views/analytics/WBDashboard.js";

class Routes extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={Login_2} />
                    {/* <Route exact path="/login_2" component={Login_2} /> */}
                    <Route exact path="/error" component={Error} />
                    {/* <Route exact path="/discovery" component={Algolia_Search_2} /> */}
                    <Route exact path="/discover/:_role/:_location" component={Algolia_Search_2} />
                    <Route exact path="/invited" component={Invited} />
                    <Route exact path="/jobs" component={Openings} />
                    <Route exact path="/support" component={Support} />
                    {/* <Route exact path="/jobs" component={Jobs} /> */}
                    <Route exact path="/profile" component={Company} />
                    {/* <Route exact path="/analytics" component={Analytics} /> */}
                    <Route exact path="/profile/:_id" component={Profile_page} />
                    <Route exact path="/reset/:email/:token" component={ResetPassword} />
                    <Route exact path="/terms-and-conditions" component={Terms} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/wbdashboard/strive-study" component={WBDashboard} />
                    <Redirect from="*" to="/error" />
                </Switch>
            </div>
        );
    }
}
export default Routes;
