import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import ListView from './ListView'
// import dividersCount from './dividersCount';
import No_Results from '../discovery/NoResultsList'

//let profileCount = 0;
class InfiniteHits extends Component {
  sentinel = null;
  //profileSentinel = null;

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
    //this.images = document.querySelectorAll('.ais-InfiniteHits-item');

    // this.observerForProfileCount = new IntersectionObserver(this.onProfileIntersection);
    // this.observerForProfileCount.observe(this.profileSentinel);

    //this.images.forEach(img => this.observerForProfileCount.observe(img));
  }

  // componentDidUpdate() {
  //   this.observerForProfileCount.observe(this.profileSentinel);
  //   console.log(this.profileSentinel)
  // }

  // shouldComponentUpdate(nextProps){
  //   let val = 0;
  //   console.log("running")
  //   console.log(JSON.stringify(nextProps.hits) === JSON.stringify(this.props.hits))
  //   if(JSON.stringify(nextProps.hits) !== JSON.stringify(this.props.hits)){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  componentWillUnmount() {
    this.observer.disconnect();
    //this.observerForProfileCount.disconnect();
  }

  onSentinelIntersection = entries => {
    const { hasMore, refineNext } = this.props;
    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
        // dividersCount.count = (dividersCount.count > 1000) ? 0 : (dividersCount.count + 1);  
        // console.log("dividersCount:::"+dividersCount.count);
        // this.props.incrementDividerCountInline();
        // this.props.setStateDividersCount((prev) => {return (prev > 1000) ? 0: (prev + 1)});
        //this.onRelevantAll();

        //alert(window.scrollY + "coming from infinite scroll")
        if (window.scrollY !== 0) {
          setTimeout(() => {
            this.props.onFirstRelevance();
          }, 100)
        }

        //this.props.onFirstRelevance();
      }
    });
  };

  // onProfileIntersection = entries => {
  //   entries.forEach(entry => {
  //     if (entry.isIntersecting) {
  //       profileCount++
  //       console.log("profileCount= " + profileCount)
  //     }
  //   });
  // };

  // onRelevantAll = ()=> {
  //   let role = "Dentist";
  //   let el = document.querySelector('div[data-is-relevant-all=true');
  //   if (el && el.querySelectorAll('.relevantTag').length === 0 ) {
  //     var node = document.createElement("DIV"); 
  //     node.classList.add('relevantTag');
  //     var textnode = (`<p>Showing Meet users with <span>${role}</span> background around your location</p>`)
  //     el.prepend(node);
  //     node.innerHTML = textnode;
  //   }
  // }
  render() {
    const { hits } = this.props;

    //console.log("role......."+this.props.role);
    return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list">
          {hits.map(hit => (
            <li key={hit.objectID}
              // ref={c => (this.profileSentinel = c)}

              className="ais-InfiniteHits-item" style={{ width: 'inherit' }}>
              <ListView
                hit={hit}
                role={this.props.role}
                // refresh={this.props.refresh}
                // initialRender={this.props.initialRender}
                secondaryRoles={this.props.secondaryRoles}
                jobseekerEnteredRoles={this.props.jobseekerEnteredRoles}
                qualifications={this.props.qualifications}
                job_role_id={this.props.job_role_id}
                signInStatus={this.props.signInStatus()}
                handleDrawer={this.props.handleDrawer}
                removeLocalStorageOnSessionExpiry={this.props.removeLocalStorageOnSessionExpiry}
                sendLocationForInvite={this.props.sendLocationForInvite()}
                inviteList={this.props.inviteList}
                geographical_type={this.props.geographical_type}
              />
            </li>
          ))}

          <li
            className="ais-InfiniteHits-sentinel"
            ref={c => (this.sentinel = c)}
          />
        </ul>
        {!this.props.hasMore ?
          <div>
            <No_Results searchRef={this.props.searchRef} locationRef={this.props.locationRef} />
          </div>
          : null
        }
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
