import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Chip from '@material-ui/core/Chip';
import myInitObject from '../discovery/location'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import StarRoundedIcon from '@material-ui/icons/StarRounded';
// import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
// import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
// import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
// import SnackBarGeneric from './SnackBarGeneric';
// import ApiHandler from '../../ApiHandler';
// import FeedbackAlert from '../../components/FeedbackAlert';
import global_isSignedIn from '../discovery/global_isSignedIn';
import AnalyticsHandler from '../../AnalyticsHandler';
// import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Accordion, AccordionDetails, AccordionSummary, LinearProgress, Popover } from '@material-ui/core';
import { checkDateFormat } from '../../Globals';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import StarRateIcon from '@material-ui/icons/StarRate';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#555',
    color: '#fff',
    //boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    minHeight: 332,
    marginBottom: '1em',
  },
  paper: {
    boxSizing: 'border-box',
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 830,
    position: 'relative',
    // maxHeight: 372,
    width: 'inherit',
    // height: 372,
    overflowX: 'hidden',
    overflowY: 'scroll',
    // position: 'absolute',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .imgPholder': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  leftSection: {
    height: '100%',
    padding: 0,
    position: 'relative',
    background: '#D5F5ED',
    overflow: 'hidden',
    maxWidth: '29%',
    flexBasis: '29%',
  },
  namePlate: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    marginRight: 7,
  },
  RightSection: {
    //height: '100%',
    position: 'relative',
    maxHeight: '50vh',
    paddingTop: 20,
    paddingBottom: 0,
    // paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 450,
    overflow: 'hidden'
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: '240',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarWrapper: {
    width: '100%',
    height: 332,
    borderRadius: 0,
    //paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    margin: 0,
    '&>img': {
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: "none",
    },
  },
  verified: {
    color: '#289e80',
    marginTop: '-20px',
  },
  lastActive: {
    color: '#999',
    fontSize: 12,
  },
  inlineSubtext: {
    color: '#232323',
    fontSize: '13px',
    fontWeight: 300,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    fontSize: '12px',
    fontWeight: 'bold',
    // fontWeight: 500,
    // width: '120px',
    padding: '2px 8px',
    margin: '6px 0 2px 0',
    backgroundColor: '#f5f5f5',
    color: "#797979",
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scrQuesAccordianExp: {
    margin: '0 !important'
  },
  skillsPopover: {
    padding: '1em 2em',
    overflowY: 'scroll',
    maxHeight: '28vh',
    top: '555px !important'
  },
  buttonSec: {
    flex: 1,
    marginRight: theme.spacing(1),
    textTransform: 'none',
    '& > *': {
      color: '#999'
    },
  },
  button: {
    flex: 1,
    textTransform: 'none',
  },
  expTimelineWrapper: {
    width: 600,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  eduTimelineWrapper: {
    width: 600,
    //marginLeft: '-26px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  timelineArrow: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#f6fffde3',
    color: '#555',
    position: 'absolute',
    top: 101,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    fontSize: 24,
    '&:hover': {
      background: 'white',
      color: '#5bc2a8',
      boxShadow: '0 0 8px rgba(0,0,0,0.15)',
    },
  },
  timelineArrow_L: {
    left: 10,
  },
  timelineArrow_R: {
    right: 10,
  },
  timelineArrow_Edu_L: {
    left: 10,
    top: 'unset',
    bottom: 55,
  },
  timelineArrow_Edu_R: {
    right: 10,
    top: 'unset',
    bottom: 55,
  },
  timelineList: {
    display: 'flex',
    marginTop: 10,
    marginLeft: '-12px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      fontSize: 12,
      position: 'relative',
      //width: 400,
      width: 300,
      '& .breakChar': {
        display: 'none',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 150,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        //display: 'none',
      },
      '&:last-child > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '72.5px',
          height: '0.5px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        transform: 'translateX(10%)',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 10%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
    '& > div:nth-child(1)': {
      '&.withBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.noBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.withBreakSameMon': {
        '& > div:nth-child(1) > div:nth-child(2) > span::after': {
          display: 'none',
        },
      }
    },
    '& > div.withBreak': {
      width: '300px!important',
      '& > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'block',
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
      '& .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreak + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
    },
    '& > div.withBreakSameMon': {
      width: '150px!important',
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
      '& > div:nth-child(1) > div:nth-child(2) > span::after': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      '&:not(:last-child) .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreakSameMon + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
    },
    '& > div.noBreakSameMon': {
      width: 150,
    },
    '& > div.noBreak': {
      width: 150,
    },
  },

  timelineListEdu: {
    display: 'flex',
    listStyle: 'none',
    marginTop: 10,
    marginLeft: '-37.3px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'flex-start',
      // fontSize: 12,
      width: 200,
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
        textTransform: 'capitalize',
        width: 200,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '98px',
          height: '1px',
          maxWidth: '98px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 19%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
  },

  timelineDuration: {
    fontSize: 10,
    color: '#797979',
    fontSize: 12,
    fontWeight: 300,
  },
  duration: {
    marginTop: 5,
    fontWeight: 400,
  },
  chiproot: {
    borderRadius: 4,
    height: 24,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  bigIconRect: {
    position: 'relative',
    margin: '0 0.5rem 0 0',
    zIndex: 2,
    height: 35,
    width: 110,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  saveIcon: {
    height: 30,
    width: 30,
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  infoIcon: {
    height: 20,
    width: 20,
    color: '#555',
  },
  bigIcon: {
    height: 46,
    width: 46,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#fab116',
    border: '1px solid #fab116',
    '&:hover': {
      backgroundColor: '#fab116',
      color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopover: {
    padding: theme.spacing(1),
    width: 200,
  },
  dialogBackdropRoot: {
    backgroundColor: "transparent"
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center'
  },
  contactDetailsOverlay: {
    textAlign: 'center',
    position: 'absolute',
    left: '2%',
    bottom: '4%',
    zIndex: 9,
    width: '23%',
    opacity: 0,
    transition: 'opacity 0.2s ease-in',
    '&:hover': {
      opacity: 1,
      cursor: 'pointer'
    },
    backgroundColor: '#fff'
  }
}));

var employer_lat_lng = {
  lat: myInitObject.lat,
  lng: myInitObject.long
}

var rad = function (x) {
  return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(employer_lat_lng.lat - p1.lat);
  var dLong = rad(employer_lat_lng.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

export default function ComplexGrid(props) {
  const classes = useStyles();

  var years_to_display = (props.hit.total_months_exp / 12).toFixed(0) == 1 ? " year " : " years ";
  var months_to_display = props.hit.total_months_exp % 12 == 1 ? " month " : " months  ";

  var last_active = moment(new Date()).diff(moment(new Date(props.hit.last_seen_at * 1000)), 'days');

  var qualifications_sorted = props.hit.user_qualifications ? props.hit.user_qualifications.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)) : null;

  var total_years = Math.trunc(props.hit.total_months_exp / 12);
  var total_months = props.hit.total_months_exp % 12;
  var fresher = false;

  if ((total_years == 0 && total_months == 0) || props.hit.user_experiences == [] || props.hit.user_experiences == [{}] || props.hit.total_months_exp == null || props.hit.total_months_exp == 0)
    fresher = true;

  var full_name = props.hit.jobseeker_name ? props.hit.jobseeker_name : "";
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";
  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }

  var array_name = props.hit.jobseeker_name ? props.hit.jobseeker_name.split(" ") : "";
  var k = 0, name_sentence_case = "";
  for (; k < array_name.length; k++) {
    if (array_name[k])
      name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
  }

  const handleProfileImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;
    //console.log(imageWidth + " " + imageHeight)
    if (imageWidth / imageHeight <= 190.9 / 302) {
      //event.target.style.height = (302 * (190.9 / imageWidth)) + "px"
      event.target.style.height = 'auto';
      event.target.style.width = '100%';
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
  }

  let scrollableWidth = 0;
  const scrollTimeline = (e) => {
    let timeline = e.target.nextSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth += 400;
    if (scrollableWidth <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth -= 400;

    if (scrollableWidth >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  let scrollableWidthEdu = 0;
  const scrollEduTimelineL = (e) => {
    let timeline = e.target.nextSibling;
    scrollableWidthEdu += 400;
    if (scrollableWidthEdu <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' });
      eduArrowRRef.current.style.display = "flex";
      if (scrollableWidthEdu + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollEduTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidthEdu -= 400;
    //console.log("TimelineWidth="+TimelineWidth)
    //console.log("scrollableWidthEdu="+scrollableWidthEdu)
    if (scrollableWidthEdu >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' })
      eduArrowLRef.current.style.display = "flex";
      if (scrollableWidthEdu - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  // const userEdu = qualifications_sorted.filter((item, i, array) =>
  //   array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
  // );

  // console.log(qualifications_sorted)
  var userEdu = [];

  userEdu = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.user_passing_year && item.user_passing_year && t.course_name && item.course_name && t.user_passing_year == item.user_passing_year && t.course_name == item.course_name) == i
  );

  // console.log(userEdu)

  const userExpFiltered = props.hit.user_experiences

  const userExpSorted = userExpFiltered && userExpFiltered.length != 0 ? userExpFiltered.sort((a, b) => {
    if (a == {} || b == {})
      return 0;
    if (a.company_starting_date === b.company_starting_date)
      return 0;
    else {
      return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
    }
  }) : null;

  let userExp = userExpSorted
    && userExpSorted.filter((item, i, array) =>
      array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
    )

  //userExp = userExpSorted;

  const expMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const expMonthsAndExp = userExp && userExp.map((item, i) => {
    if (item != {}) {
      if (item.company_ending_date && item.company_starting_date) {
        var startDate = checkDateFormat(item.company_starting_date)
        var endDate = checkDateFormat(item.company_ending_date)
        var startDateNext = userExp[i + 1] ? new Date(userExp[i + 1].company_starting_date) : null;
        var a = moment(endDate);
        var b = typeof (startDate) === 'string' && startDate.includes(' ') ? startDate : moment(startDate);
        var c = moment(startDateNext);
        var duration = a.diff(b, 'months');
        var dd = c.diff(a, 'days');
        var sameDaysDiff = a.diff(b, 'days')
        return {
          months: duration,
          sameDaysDiff: sameDaysDiff,
          daysDiff: dd,
          expStartMon: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(0, 3) : startDate.getMonth(),
          expEndMon: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(0, 3) : endDate.getMonth(),
          expStartYear: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(4, 8) : startDate.getFullYear(),
          expEndYear: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(4, 8) : endDate.getFullYear(),
          user_post: item.user_post,
        }
      }
      else {
        return {
          months: null,
          sameDaysDiff: null,
          daysDiff: null,
          expEndMon: null,
          expStartMon: null,
          expStartYear: null,
          expEndYear: null,
          user_post: item.user_post,
        };
      }
    }
    else {
      return {
        months: null,
        sameDaysDiff: null,
        daysDiff: null,
        expEndMon: null,
        expStartMon: null,
        expStartYear: null,
        expEndYear: null,
        user_post: null
      }
    }
  })

  const hasOverlapExps = userExp && userExp.length > 0 && userExp.map((item, i) => {
    if (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0) {
      return { overlap: 'true' }
    } else {
      return { overlap: 'false' }
    }
  })


  //Shortlist and Hire butoon functionalities start
  const [gotHireResponse, setGotHireResponse] = React.useState(true);

  const [applicantStatus, setApplicantStatus] = React.useState(props.hit.status);

  let cid = localStorage.getItem('company_id');
  // const handleHireClickOpen = () => {
  //   setOpen(true);
  // };

  const timelineRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  const eduTimelineRef = React.useRef(null);
  const eduArrowLRef = React.useRef(null);
  const eduArrowRRef = React.useRef(null);

  React.useEffect(() => {
    if (timelineRef.current && expArrowRRef.current && timelineRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }

    if (eduTimelineRef.current && eduArrowRRef.current && eduTimelineRef.current.scrollWidth > 638) {
      //console.log(eduTimelineRef.current.scrollWidth)
      eduArrowRRef.current.style.display = "flex";
    }
  }, []);

  const changeStatus = (status) => {
    axios.post(`${BASE_URL}/jobposting/updateApplliedJobStatus`, {
      _id: props.hit._id,
      status: status
    })
      .then(response => {
        if (response.status === 200) {
          if (status === "Shortlisted") {
            props.handleSnackBar('Thanks for shortlisting this jobseeker. We will inform them to call you for further process.')
          } else if (status === "Rejected") {
            props.handleSnackBar('You have successfully rejected this candidate for the current role.')
          } else if (status === "Hired") {
            props.handleSnackBar('Thanks for hiring this jobseeker. We will inform them to call you for further process.')
          }
          setApplicantStatus(status);
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleHireClick = () => {
    // AnalyticsHandler.track("CompanyPage_followers_hireBtn");
    // if (!hireStatus[index]) {
    //   handleHireClickOpen(item, index);
    // }
    setOpen(true);
  }

  const [open, setOpen] = React.useState(false);

  const handleHireYesClose = () => {
    changeStatus("Hired");
    setOpen(false);
  }

  const [customQues, setCustomQues] = React.useState([]);

  React.useEffect(() => {
    axios.get(`${BASE_URL}/jobposting/screeningfield/${props.hit.objectID}/${props.jobPostId}`)
      .then((res) => {
        setCustomQues(res.data[0].custom_questions);
      })
  }, [props.hit])

  const [anchorSkillsEl, setAnchorSkillsEl] = React.useState(null);

  const viewMoreSkills = (event) => {
    setAnchorSkillsEl(event.currentTarget);
  };

  const closeSkillsPopover = () => {
    setAnchorSkillsEl(null);
  };

  const openSkillsPopover = Boolean(anchorSkillsEl);
  const skillsPopoverId = openSkillsPopover ? 'skills-popover' : undefined;

  function getSkills(skills) {
    return skills.map((item, index) =>
      <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.2em 0', flexDirection: 'column' }}>
        <Grid container item alignItems='center' justifyContent='center' wrap='nowrap' style={{ marginRight: '10px' }}>
          <Typography style={{
            textTransform: 'capitalize'
            // width: `${item.skill_name.length > 23 ? '140px' : '120px'}`
          }} className={classes.chip}>
            <span>
              {item.skill_name}
            </span>
            {/* <Rating name="read-only" value={item.rating} style={{ fontSize: '0.9rem' }} readOnly /> */}
            <span style={{ fontSize: '0.8rem', display: 'flex', alignItems: 'center', marginLeft: '3px' }}>
              <StarRateIcon style={{ fontSize: '0.8rem' }} />
              {item.rating}
            </span>
          </Typography>
        </Grid>
      </div>
    )
  }

  return (
    <div className="listItemWrapper" style={{ marginBottom: 30 }}>

      <div className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Grid container direction="row"
            justifyContent="flex-start"
            wrap='nowrap'
            alignItems="flex-start" style={{ height: '302', overflow: 'hidden', position: 'relative' }}>
            <Grid item className={classes.leftSection} id="leftSectionId">
              <Grid item xs container direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item xs container justifyContent="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justifyContent="center"
                    alignItems="center">

                    <div className={classes.avatarWrapper}>
                      {props.hit.user_image_url ?
                        <img className={classes.img} alt="candidates avatar image" loading='lazy' src={props.hit.user_image_url} width={245} height={332} onLoad={handleProfileImage} style={{ opacity: 0 }} />
                        :
                        <div className="imgPholder">
                          <p style={{
                            fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%',
                          }}> {initials} </p>
                        </div>}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} sm={8} container direction='column' wrap='nowrap' className={classes.RightSection}>
              <Grid item xs={12} container direction="row" justifyContent="space-between"
                alignItems="flex-start" style={{ marginLeft: 18, width: '70%' }}>
                <Grid item xs container direction="row" justifyContent="flex-start" alignItems="baseline" style={{ marginBottom: 10 }}>
                  <Typography variant="h6" component='h2' className={classes.namePlate} >
                    {name_sentence_case}
                  </Typography>
                  <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                    <span style={{ fontSize: 18, marginRight: 4, color: '#ccc', fontWeight: 100 }}>|</span>
                    <span style={{ position: 'relative', marginRight: 8 }}>
                      {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                    <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                      {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                    </span>

                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                  </span>
                </Grid>
                {/* <Grid item>
                  <Typography variant="body2" className={classes.lastActive}>
                    Last active:  {last_active <= 30 ? (last_active == 0 ? "Today" : (last_active == 1 ? last_active + " day" : last_active + " days")) : " > 1 month "}
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid item xs container direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item xs container direction="column" justifyContent="center"
                  alignItems="flex-start" style={{ marginLeft: 27 }} >
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginTop: 10, marginBottom: 10 }} >
                    <WorkIcon fontSize="small" className="primaryText" />
                    <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Experience</Typography>
                    {fresher ?
                      <Chip size="small" label={fresher ? 'Fresher' : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                  </Grid>

                  <div className={classes.expTimelineWrapper}>
                    <div ref={expArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_L}`} onClick={scrollTimeline} style={{ display: 'none' }}>&#8249;</div>
                    {userExp && userExp.length === 1 && expMonthsAndExp[0].sameDaysDiff <= 31 && expMonthsAndExp[0].expStartMon === expMonthsAndExp[0].expEndMon ?
                      <div style={{ marginLeft: 30, marginBottom: 10 }}>
                        <Typography variant="subtitle1" style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                          {userExp[0].user_post} {userExp[0].user_post ? "|" && expMonths[expMonthsAndExp[0].expStartMon] && expMonths[expMonthsAndExp[0].expStartYear] : ""} <span style={{ fontWeight: 100, fontSize: 12 }}>{expMonths[expMonthsAndExp[0].expStartMon]} {expMonthsAndExp[0].expStartYear}</span>
                        </Typography>
                      </div>
                      :
                      <div style={{ position: 'relative' }}>
                        {userExp && userExp.length > 0 && hasOverlapExps.some(item => item.overlap === 'true') ?
                          <LightTooltip title="This user has some experiences whose start date is before the end date of their previous experience. For such experiences, the timeline indicates only the starting month." placement="top-start" arrow>
                            <IconButton aria-label="info" size="small" style={{ position: "absolute", top: '-4px', left: '-2px', zIndex: '9', cursor: 'default' }}>
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </IconButton>
                          </LightTooltip>
                          : null}
                        <div id="timelineListId" className={classes.timelineList} ref={timelineRef}>
                          {userExp && userExp.length > 0 && userExp.map((item, i) => (
                            <div key={i}
                              // style={{ width: userExp.length === 2 && i === 0 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30 ? 150 : (userExp.length === 1 && 300) }}
                              style={{
                                width:
                                  (userExp.length === 1)
                                    || (userExp.length > 1 && i === userExp.length - 1
                                      // && (expMonthsAndExp[i].sameDaysDiff > 31 || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon)
                                    )
                                    || (userExp.length > 1 && i === 0 && expMonthsAndExp[i].daysDiff >= 30)
                                    ? 300
                                    : 150
                              }}
                              className={expMonthsAndExp[i]
                                &&
                                // (expMonthsAndExp[i].daysDiff >= 30
                                //   && expMonthsAndExp[i].sameDaysDiff <= 31
                                //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                // )
                                // || (
                                //   expMonthsAndExp[i].sameDaysDiff <= 31 &&
                                //    i === userExp.length - 1
                                //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                // ) ? "withBreakSameMon"
                                // : 
                                (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30
                                  ? "withBreak"
                                  : (
                                    // (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff <= 31)
                                    //   ||
                                    (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0
                                    ) ? "noBreakSameMon"
                                      : "noBreak"))}
                            >

                              <div>
                                <div className={classes.timelineDuration}>
                                  {typeof (expMonthsAndExp[i].expStartMon) === 'string' ? expMonthsAndExp[i].expStartMon : expMonths[expMonthsAndExp[i].expStartMon]} {expMonthsAndExp[i].expStartYear}
                                </div>
                                <div>
                                  <span></span>
                                </div>
                                <div
                                  style={{
                                    transform: expMonthsAndExp[i] && (expMonthsAndExp[i].daysDiff < 0
                                      // ||
                                      // (
                                      //   expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon &&
                                      //   expMonthsAndExp[i].sameDaysDiff <= 31)
                                    )
                                      && 'translate(0)'
                                  }}
                                >
                                  <LightTooltip title={item.user_post ? (item.company_name ? item.user_post + " at " + item.company_name : null) : null} placement="bottom">
                                    <div>{item.user_post ? item.user_post : null}</div>
                                  </LightTooltip>
                                  {/* {expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0 && (
                                  <div className={classes.duration}> {expMonthsAndExp[i].months && expMonthsAndExp[i].months > 0 ? expMonthsAndExp[i].months + 'm' : 'less than 1m'}</div>
                                )
                                } */}
                                  {/* {expMonthsAndExp[i] && (
                                  <div className={classes.duration}> {expMonthsAndExp[i].months && expMonthsAndExp[i].months > 0 ? expMonthsAndExp[i].months + 'm' : 'less than 1m'}</div>
                                )
                                } */}
                                </div>
                              </div>

                              {userExp && userExp.length && i !== userExp.length - 1
                                && (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30)
                                // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                // )
                                &&
                                <div>
                                  <div className={classes.timelineDuration}>
                                    {typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear}
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                </div>
                              }
                              {userExp && userExp.length &&
                                (i === userExp.length - 1
                                  // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                  //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                  // )
                                )
                                &&
                                <div>
                                  <div className={classes.timelineDuration}>
                                    {userExp[i].company_ending_date === "Present" ? "Present" : typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear}
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                </div>
                              }

                              {/* {userExp && userExp.length === 2 && i === 0 &&
                              (expMonthsAndExp[0] && expMonthsAndExp[0].sameDaysDiff > 31) && (expMonthsAndExp[0].daysDiff >= 0 && expMonthsAndExp[0].daysDiff < 30)
                              &&
                              <div>
                                <div className={classes.timelineDuration}>
                                  {userExp[i].company_ending_date === "Present" ? "Present" : expMonths[expMonthsAndExp[i].expEndMon] + " " + expMonthsAndExp[i].expEndYear}
                                </div>
                                <div>
                                  <span></span>
                                </div>
                              </div>
                            } */}
                              <span className="breakChar" style={{ position: 'absolute', top: '19%', right: '-22px', background: 'white' }}>b r e a k</span>
                            </div>
                          ))}

                        </div>
                      </div>
                    }
                    <div ref={expArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_R}`} onClick={scrollTimelineR} style={{ display: 'none' }}>&#8250;</div>
                  </div>
                </Grid>

                <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ marginLeft: 27 }}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" style={{ marginBottom: 10 }}>
                    {qualifications_sorted && qualifications_sorted[0] ? <SchoolRoundedIcon fontSize="small" className="primaryText" /> : null}
                    <Typography variant="subtitle1" component='h3' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10, }}>Education</Typography>
                  </Grid>
                  {qualifications_sorted.length === 1 ?
                    <div style={{ marginLeft: 30, marginBottom: 10 }}>
                      <Typography variant="subtitle1" component='h4' style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                        {qualifications_sorted[0].course_type && (qualifications_sorted[0].course_type === "UG Diploma" || qualifications_sorted[0].course_type === "PG Diploma" || qualifications_sorted[0].course_type === "ITI" || qualifications_sorted[0].course_type === "Certification") ?
                          qualifications_sorted[0].course_type :
                          (
                            qualifications_sorted[0].course_abbreviation ?
                              qualifications_sorted[0].course_abbreviation :
                              (qualifications_sorted[0].course_name ?
                                qualifications_sorted[0].course_name :
                                (qualifications_sorted[0].user_degree ?
                                  qualifications_sorted[0].user_degree : null)
                              )
                          )
                        }
                        {qualifications_sorted[0].course_type && (qualifications_sorted[0].course_type === "UG Diploma" || qualifications_sorted[0].course_type === "PG Diploma" || qualifications_sorted[0].course_type === "ITI" || qualifications_sorted[0].course_type === "Certification") ?
                          (qualifications_sorted[0].course_name ? "—" + qualifications_sorted[0].course_name : null) :
                          (qualifications_sorted[0].specialization_name ? "—" + qualifications_sorted[0].specialization_name : null)}
                        {' | '}
                        <span style={{ fontWeight: 100, fontSize: 12 }}>
                          {qualifications_sorted[0].user_passing_year}
                        </span>
                      </Typography>
                    </div>
                    :
                    <div className={classes.eduTimelineWrapper}>
                      <div ref={eduArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_L}`} onClick={scrollEduTimelineL} style={{ display: 'none' }}>&#8249;</div>
                      <div ref={eduTimelineRef} className={classes.timelineListEdu}>
                        {qualifications_sorted.map((item, i) =>
                        (<div key={i}>
                          <div>
                            <div className={classes.timelineDuration}>
                              {item.user_passing_year}
                            </div>
                            <div>
                              <span></span>
                            </div>
                            <div>
                              <LightTooltip title={
                                ((item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")
                                  ? item.course_type + (item.course_name ? "—" + item.course_name : "")
                                  : (item.course_abbreviation ?
                                    (item.course_abbreviation + (item.specialization_name ? "—" + item.specialization_name : ""))
                                    : (item.user_degree ? item.user_degree : ""))) + " from " + (item.user_college)

                              }
                                placement="bottom">
                                <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', height: '100%' }}>
                                  {item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : (item.course_name ? item.course_name : (item.user_degree ? item.user_degree : "")))}

                                  {/* {item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)} */}
                                </div>
                              </LightTooltip>
                            </div>
                          </div>
                        </div>)
                        )}
                      </div>
                      <div ref={eduArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_R}`} onClick={scrollEduTimelineR} style={{ display: 'none' }}>&#8250;</div>
                    </div>
                  }
                </Grid>
              </Grid>
              {props.hit.user_skills.length > 0 && <Grid item xs container direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: '1em' }}
              >
                <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginLeft: 27 }} >
                  <SportsHockeyIcon fontSize="small" className="primaryText" />
                  <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Skills</Typography>
                </Grid>
                <Grid item xs container wrap='nowrap' alignItems="center" className={classes.sectionItem_content} style={{ margin: '5px 10px 0 25px', paddingLeft: '42px' }} >
                  {props.hit.user_skills.length > 2 ? getSkills(props.hit.user_skills.slice(0, 2)) : getSkills(props.hit.user_skills)}
                  {props.hit.user_skills.length > 2 && <Typography onClick={viewMoreSkills} style={{ fontSize: '0.8em', color: '#14967b', textDecoration: 'underline', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>+{props.hit.user_skills.length - 2} more</Typography>}
                  <Popover
                    classes={{ paper: classes.skillsPopover }}
                    id={skillsPopoverId}
                    open={openSkillsPopover}
                    anchorEl={anchorSkillsEl}
                    onClose={closeSkillsPopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {getSkills(props.hit.user_skills.slice(2, props.hit.user_skills.length))}
                  </Popover>
                </Grid>
              </Grid>}
            </Grid>
            <Grid item style={{ position: 'absolute', right: '3%', top: '7%', zIndex: 9, color: 'rgb(121, 121, 121)' }} >
              <Typography style={{ fontSize: '0.9em' }}>Applied on: {props.createDate(props.hit.date)}</Typography>
            </Grid>
          </Grid>
          <Grid style={{ position: 'relative', left: '33%', width: '65%', borderTop: '1px solid #79797921', marginBottom: '1em', marginLeft: '18px', paddingTop: '0.5em' }} item>
            {(props.jobData.salary_expectation || props.jobData.why_good_fit || props.jobData.change_job_reason || (props.jobData.custom_questions && props.jobData.custom_questions.length > 0)) ?
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  classes={{ expanded: classes.scrQuesAccordianExp }}
                >
                  <Grid container>
                    <Grid item>
                      <Typography style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Screening Form Responses</Typography>
                    </Grid>
                    <Grid item container>
                      {props.jobData.own_laptop && <Grid item>
                        <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.own_laptop ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Laptop</span>
                      </Grid>}
                      {props.jobData.own_vehicle && <Grid item>
                        <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.own_vehicle ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Bike/Scooty</span>
                      </Grid>}
                      {props.jobData.location_agree && <Grid item>
                        <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.location_agree ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Location</span>
                      </Grid>}
                      {props.jobData.salary_agree && <Grid item>
                        <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.salary_agree ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Salary</span>
                      </Grid>}
                      {props.jobData.valid_dl_license && <Grid item>
                        <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.valid_dl_license ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Driving License</span>
                      </Grid>}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    {props.jobData.salary_expectation && <Grid direction='column' style={{ marginBottom: '1.5em' }} item container>
                      <Grid item>
                        <Typography gutterBottom style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Salary Expectation</Typography>
                      </Grid>
                      <Grid item >
                        <span style={{ fontSize: '0.9rem' }}>{props.hit.salary_expectation ? `₹${props.hit.salary_expectation}` : 'No response'}</span>
                      </Grid>
                    </Grid>}
                    {props.jobData.why_good_fit && <Grid item direction='column' container style={{ marginBottom: '1em' }}>
                      <Grid item>
                        <Typography gutterBottom style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Why should you hire me?</Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: '0.9rem' }}>{props.hit.why_good_fit ? props.hit.why_good_fit : 'No response'}</Typography>
                      </Grid>
                    </Grid>}
                    {props.jobData.change_job_reason && <Grid item direction='column' container>
                      <Grid item>
                        <Typography gutterBottom style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Why do you want to change your current job?</Typography>
                      </Grid>
                      <Grid item>
                        <Typography style={{ fontSize: '0.9rem' }}>{props.hit.change_job_reason ? props.hit.change_job_reason : 'No response'}</Typography>
                      </Grid>
                    </Grid>}
                    {props.jobData.custom_questions.length > 0 &&
                      customQues.map((item, index) => {
                        return <Grid key={index} style={{ marginTop: '1em' }} item direction='column' container>
                          <Grid item>
                            <Typography gutterBottom style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>{item.question}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontSize: '0.9rem' }}>{item.answer ? item.answer : 'No response'}</Typography>
                          </Grid>
                        </Grid>
                      })
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion> :
              (props.jobData.own_laptop || props.jobData.own_vehicle || props.jobData.location_agree || props.jobData.salary_agree || props.jobData.valid_dl_license) && (!props.jobData.salary_expectation || !props.jobData.why_good_fit || props.jobData.change_job_reason || (!props.jobData.custom_questions && props.jobData.custom_questions.length === 0)) ?
                <Grid container>
                  <Grid item>
                    <Typography style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Screening Form Responses</Typography>
                  </Grid>
                  <Grid item container>
                    {props.jobData.own_laptop && <Grid item>
                      <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.own_laptop ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Laptop</span>
                    </Grid>}
                    {props.jobData.own_vehicle && <Grid item>
                      <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.own_vehicle ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Bike/Scooty</span>
                    </Grid>}
                    {props.jobData.location_agree && <Grid item>
                      <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.location_agree ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Location</span>
                    </Grid>}
                    {props.jobData.salary_agree && <Grid item>
                      <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.salary_agree ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Salary</span>
                    </Grid>}
                    {props.jobData.valid_dl_license && <Grid item>
                      <span className={classes.flexbox} style={{ fontSize: '0.82rem', fontWeight: 500, margin: '0.5em 1rem 0.5rem 0' }}>{props.hit.valid_dl_license ? <CheckIcon color='primary' fontSize='small' /> : <CloseIcon fontSize='small' style={{ color: '#CD212A' }} />} Driving License</span>
                    </Grid>}
                  </Grid>
                </Grid>
                : <Grid style={{ padding: '1em 0' }} container>
                  <Grid item container direction='column'>
                    <Grid item>
                      <Typography gutterBottom style={{ color: '#797979', fontWeight: 500, fontSize: '0.95rem' }}>Screening Form Responses</Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ fontSize: '0.9rem', color: '#AFAFAF' }}>No responses</Typography>
                    </Grid>
                  </Grid>
                </Grid>}
          </Grid>
          <Grid item style={{ position: 'absolute', left: 0, bottom: '3%', zIndex: 9, color: 'rgb(121, 121, 121)', width: '29%', wordBreak: 'break-all', backgroundColor: '#fff', padding: '0.5em 0 0 1em' }} >
            <Typography style={{ fontSize: '0.9em' }}>Mobile: {props.hit.user_mobile ? props.hit.user_mobile : '+91-xxx-xxx-xxxx'}</Typography>
            <Typography style={{ fontSize: '0.9em' }}>Email: {props.hit.user_email ? props.hit.user_email : 'xxxx-xxxx'}</Typography>
          </Grid>
          {!props.hit.user_email && !props.hit.user_mobile && <Grid className={classes.contactDetailsOverlay} item >
            <Typography style={{ fontSize: '0.9em' }}>You need to verify this job to view contact details</Typography>
          </Grid>}
        </Paper>
      </div>
      <Grid item container
        justifyContent="center"
        alignItems="flex-end"
        style={{ position: 'relative', top: '-2em', left: '50%', transform: 'translateX(-37%)', zIndex: 9, width: '60%' }}
      >
        {/* Button added by kartikey */}
        {applicantStatus !== 'Hired' && <ToggleButton
          id="proceed_to_hire_btn_id"
          value="check"
          className={classes.bigIconRect}
          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected }}
          selected={applicantStatus === "Shortlisted" ? true : false}
          onChange={gotHireResponse ? () => {
            AnalyticsHandler.track("JobOpenings_Applicants_shortlistBtn");
            changeStatus('Shortlisted');
          } : null}
          style={{ borderLeft: '1px solid #14967B', width: 130 }}
        >
          {applicantStatus === "Shortlisted" ?
            <CancelIcon className={classes.saveIconRect} />
            : <CancelOutlinedIcon className={classes.saveIconRect} />
          }
          {applicantStatus === "Shortlisted" ? "Shortlisted" : "Shortlist"}
        </ToggleButton>}

        {applicantStatus !== 'Hired' && <ToggleButton
          id="proceed_to_hire_btn_id"
          value="check"
          className={classes.bigIconRect}
          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
          selected={applicantStatus === "Rejected" ? true : false}
          onChange={gotHireResponse ? () => {
            AnalyticsHandler.track("JobOpenings_Applicants_rejectBtn");
            changeStatus('Rejected')
          } : null}
          style={{ borderLeft: '1px solid #14967B' }}
        >
          {applicantStatus === "Rejected" ?
            <CancelIcon className={classes.saveIconRect} />
            : <CancelOutlinedIcon className={classes.saveIconRect} />
          }
          {applicantStatus === "Rejected" ? "Rejected" : "Reject"}
        </ToggleButton>}

        <ToggleButton
          id="proceed_to_hire_btn_id"
          value="check"
          className={classes.bigIconRect}
          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
          selected={applicantStatus === "Hired" ? true : false}
          onChange={gotHireResponse && applicantStatus !== "Hired" ? () => {
            AnalyticsHandler.track("JobOpenings_Applicants_hireBtn");
            handleHireClick();
          } : null}
          style={{ borderLeft: '1px solid #14967B' }}
        >
          {applicantStatus === "Hired" ?
            <CancelIcon className={classes.saveIconRect} />
            : <CancelOutlinedIcon className={classes.saveIconRect} />
          }
          {applicantStatus === "Hired" ? "Hired" : "Hire"}
        </ToggleButton>

        <Button
          className={classes.bigIconRect}
          classes={{ label: classes.tglBtnLabel }}
          style={{ width: 'auto' }}
          onClick={() => {
            AnalyticsHandler.track("JobOpenings_Applicant_fullProfileBtn");
            window.open(`/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit.objectID}`, "_blank");
          }}
        ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> Full Profile</Button>

      </Grid>
      {/* <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} /> */}
      {/* <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert> */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // BackdropProps={{
        //   classes: {
        //     root: classes.dialogBackdropRoot
        //   }
        // }}
        transitionDuration={150}
      >
        <DialogTitle id="alert-dialog-title">{"Hiring Confirmation"}</DialogTitle>
        <DialogContent style={{ height: 100 }}>
          <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
            Are you sure you want to hire <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{name_sentence_case.toLowerCase()}</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="cancel_hire_btn_id" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button id="hire_btn_id" onClick={gotHireResponse ? handleHireYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}