import React from 'react';
import Button from '@material-ui/core/Button';

const NoResults = (props) => {
      return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <div>
                        <br />
                        <br />
                        <br />
                        No Meet users with your chosen role or qualification around <b>your location</b>.
                        <br /><br />
                  </div>
                  <Button
                        variant="outlined"
                        onClick={() => {
                              var x = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
                              x[0].click();
                              // locationRef.current.click();
                              // document.getElementById("google-map").value = "";
                              // setLocationValue("Any Location / WFH");
                              var t = setTimeout(function () { props.searchRef.current.click(); clearTimeout(t); }, 500);
                        }}>Search Anywhere</Button>
                  <br />
                  <br />
                  <br />
            </div>
      )
}

export default NoResults;