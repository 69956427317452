import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import ShareIcon from '@material-ui/icons/Share';
import Chip from '@material-ui/core/Chip';
import FeedbackAlert from '../../components/FeedbackAlert';
import moment from 'moment';
import { StylesProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import PrimaryAppBar from '../../components/JobAppBar';
import Loader_Shuffle from '../discovery/Loader_Shuffle';
import ApiHandler from '../../ApiHandler';
import global_isSignedIn from '../discovery/global_isSignedIn';
import SnackBarCustom from '../discovery/SnackBarCustom';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { globalRoleLocationInvite } from '../discovery/Algolia_Search_2';
import AnalyticsHandler from '../../AnalyticsHandler';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LogoLoader from '../../assets/logo-loader.gif';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import Location from '../company/Location';
import { CancelOutlined } from '@material-ui/icons';
import Loader from '../company/Loader';
import { Helmet } from 'react-helmet-async';
const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;


const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em',
      backgroundColor: '#D5F5ED'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "23px",
    },
  },
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    // height: 'calc(100vh - 320px)',
    height: 'calc(100vh - 240px)',
    minHeight: 300,
    background: 'white',
    //width: 'calc(100vw - 600px)',
  },
  paper: {
    boxSizing: 'border-box',
    borderRadius: 10,
    padding: theme.spacing(0),
    margin: 'auto',
    // maxWidth: 860,
    // maxHeight: 436,
    width: '100%',
    height: '100%',
    //overflowX: 'hidden',
    //overflowY: 'hidden',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 1,
    transform: 'translate3d(0, 0, 0) scale(1)',
    opacity: 1,
    transition: 'all 1s ease-in-out',

    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // }
    '&::-webkit-scrollbar': {
      width: 5,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#55c6a9',
      borderRadius: 4,
      '&:hover': {
        background: '#5BC2A8',
        cursor: 'pointer',
      },
    },
    '&::-webkit-scrollbar-track': {
      background: '#5bc2a873',
      margin: 20,
    },
    '&.animate': {
      //transform: 'translate3d(-20px, 0, 0) scale(1)',
      //opacity: 0,
      //zIndex: 0,
    }
  },
  profileSection: {
    //height: '100%',
    minHeight: '100%',
    transform: 'translate3d(0, 0, 0)',
    animation: 'profileFade 0.75s ease-in-out 1',
    // animation: 'fadeInOut 5s 2, autoSwipeUp 10s steps(2, end) 1 both',
  },
  leftSection: {
    height: '100%',
    //height: 436,
    background: '#5bc2a814',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-start',
    position: 'sticky',
    top: 0,
    //transition: 'all 0.25s ease-in-out'
  },
  imageWrapper: {
    // width: 327,
    // height: 436,
    width: '100%',
    height: 'calc(100vh - 240px)',
    minHeight: 300,
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&>img': {
      //filter: 'contrast(90%) brightness(90%)',
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: 'none',
    },
  },
  img: {
    //margin: 'auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card__LogoWrapper_reg: {
    width: 70,
    height: 70,
    borderRadius: 0,
    overflow: 'hidden',
    marginRight: 0,
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    // background: '#5bc2a873',
    '&>img': {
      //margin: 'auto',
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
    },
  },
  lastActive: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    color: '#ffffff',
    fontSize: 12,
  },
  chiproot: {
    borderRadius: 4,
    height: 22,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  headerButton: {
    textTransform: "none",
    width: 150,
    height: 35,
  },
  headerButton_short: {
    textTransform: "none",
    width: 100,
    height: 35,
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  bigIconRect: {
    zIndex: 9,
    //position: 'absolute',
    //bottom: '-16px',
    height: 35,
    width: '8.7em',
    padding: 5,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
    }
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  inviteDialogPaper: {
    minHeight: '70vh',
    maxHeight: '85vh',
    minWidth: '75vw',
    maxWidth: '75vw'
  },
  popper: {
    position: 'relative',
    zIndex: 9999
  },
  dialogRolesSubHeadingContainer: {
    width: '102%',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    marginLeft: '-1%',
    zIndex: 99
  },
  dialogRolesSubHeading: {
    padding: '7% 0 3% 1%',
    marginTop: '-4%',
    backgroundColor: '#fff'
  },
  mr1: {
    marginRight: '1rem'
  },
  mr2: {
    marginRight: '0.5rem'
  },
  my1: {
    margin: '1rem 0'
  },
  my2: {
    margin: '2rem 0'
  },
  my0: {
    margin: '0.6em 0'
  },
  mt1: {
    marginTop: '1em'
  },
  mt2: {
    marginTop: '2em'
  },
  px1: {
    padding: '0.5rem 0'
  },
  py1: {
    padding: '0.5rem 0'
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
  ttn: {
    textTransform: 'none'
  },
  ttc: {
    textTransform: 'capitalize'
  },
  textAlignCenter: {
    textAlign: 'center'
  }
}));

const getinvitedDate = (date) => {
  let d = date
  d = new Date(d);
  d = moment(d).format("Do MMM YYYY");
  return d
}

export default function Laptop(props) {

  const classes = useStyles();
  const locationRouter = useLocation();


  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  //const signInStatus = global_isSignedIn.isSignedIn; //UNCOMMENT

  //const signInStatus = true; //COMMENT this out before sending for PR

  const [jobseekerInfo, setJobseekerInfo] = React.useState(null);
  const imgPholderRef = useRef(null);
  const righSectionRef = useRef(null);
  const [navValue, setNavValue] = React.useState(0);
  const [loaderRef, setLoaderRef] = React.useState(null);
  const history = useHistory();

  const [selected, setSelected] = React.useState(false);
  const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

  const [invitedCandidates, setInvitedCandidates] = React.useState({});
  let cid = localStorage.getItem('company_id');
  const [company_id, setCompanyId] = React.useState(cid);

  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [chooseRole, setChooseRole] = React.useState(false);
  const [openRolesList, setOpenRolesList] = React.useState(true);
  const [roles, setRoles] = React.useState([]);
  const [shortlistStatus, setShortlistStatus] = React.useState([]);
  const [hireStatus, setHireStatus] = React.useState([]);
  const [selectedJobseekerName, setSelectedJobseekerName] = React.useState('');
  const [selectedJobseekerRole, setSelectedJobseekerRole] = React.useState('');
  const [selectedJobseekerLocation, setSelectedJobseekerLocation] = React.useState('');
  const [disableConfirmInviteBtn, setDisableConfirmInviteBtn] = React.useState(true);
  const [locationValue, setLocationValue] = React.useState();
  const [latLong, setLatLong] = React.useState();
  const [inviteConfirmPage, setInviteConfirmPage] = React.useState(false);
  const [postedJobs, setPostedJobs] = React.useState([]);
  const [sixSecondsTimer, setSixSecondsTimer] = React.useState(6);
  const [startTimer, setStartTimer] = React.useState(false);
  const [values, setValues] = React.useState({
    role: '',
    location: ''
  });
  const [invited, setInvited] = React.useState(false);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
    if (v) {
      setCompanyId(localStorage.getItem('company_id'));
    }
  };

  const closeInviteDialog = (e, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpenInviteModal(false);
    setChooseRole(false);
    setTimeout(() => {
      setOpenRolesList(true);
    }, 100);
  }

  const [objectID, setObjectID] = React.useState('')
  const fetchObjectIDFromUrl = () => {
    let id = window.location.href.split('-').pop();
    setObjectID(id);
  }

  React.useEffect(() => {
    fetchObjectIDFromUrl();
  }, []);

  React.useEffect(() => {
    if (invitedCandidates[objectID]) {
      setSelected(invitedCandidates[objectID].invite_status);
      // setHireSelected(invitedCandidates[objectID].hired_status);
      // setIsShortlisted(invitedCandidates[objectID].shortlist_status);
    }
  }, [invitedCandidates]);

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // snackbar callback starts
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);

  const [snackBarText, setSnackBarText] = React.useState("Your invite has been sent successfully. The jobseeker will call you if they are interested to work with you.");
  const handleSnackBar = (text) => {
    if (text) {
      setSnackBarText(text);
    }
    setCanSnackBarOpen(true)
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  const [jobseeker_id, setJobseekerId] = React.useState("");
  const [invitedRolesList, setInvitedRolesList] = React.useState([]);
  const [refreshProfile, setRefreshProfile] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [roleToUninvite, setRoleToUninvite] = React.useState('');
  const [uninvitedRoleLocation, setUninvitedRoleLocation] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleUninviteClickOpen = (item) => {
    setOpen(true);
    setRoleToUninvite(item.job_role);
    setUninvitedRoleLocation(item.location)
  };

  const handleUninviteCancelClose = () => {
    setOpen(false);
  };

  if (loaderRef && loaderRef.current) {
    loaderRef.current.classList.add('animate');
  }

  var arrID = props.match.match.params._id.split('-');
  let job_id = "";
  if (arrID.length - 1 >= 0) {
    job_id = arrID[arrID.length - 1]
  }

  React.useEffect(() => {
    setLoader(true);
    setJobseekerId(job_id);

    let payload = {
      jobseeker_id: job_id,
      company_id: localStorage.getItem('company_id')
    }

    ApiHandler.getUniqueJobseeker(payload, response => {
      if (response.status == 200) {
        setOpenInviteModal(false)
        setJobseekerInfo(response.data[0]);
        setLoader(false);
        //stop loader after response
        if (loaderRef && loaderRef.current) {
          loaderRef.current.classList.remove('animate');
        }
      } else {
        if (response.response) {
          let statusCode = response.response.status;
          // let message = response.response.data.message;

          if (statusCode >= 400) {
            // if (message === "User not found") {
            history.push('/error'); // 404 error page
            // }
          }
        } else {
          history.push('/error'); // 404 error page
        }
      }
    })
  }, [refreshProfile, isSignedIn]);

  React.useEffect(() => {
    if (imgPholderRef && imgPholderRef.current && righSectionRef && righSectionRef.current) {
      righSectionRef.current.style.opacity = 1
    }
    if (jobseekerInfo) {
      if (jobseekerInfo.invite_jobseekers) {
        setInvitedRolesList(jobseekerInfo.invite_jobseekers.filter(item => item.invite_status));
        jobseekerInfo.invite_jobseekers.length > 0 && setInvited(jobseekerInfo.invite_jobseekers[0].invite_status)
      }
    }
    //console.log({ jobseekerInfo });
  }, [jobseekerInfo])

  React.useEffect(() => {
    let shortlistArr = [];
    let hireArr = [];
    invitedRolesList && invitedRolesList.forEach(element => {
      shortlistArr.push(element.shortlist_status);
      hireArr.push(element.hired_status);
    })
    setShortlistStatus(shortlistArr);
    setHireStatus(hireArr);
  }, [invitedRolesList])

  const handleLogoImageLoad = (event) => {
    let imageWidth = event.target.clientWidth;
    let imageHeight = event.target.clientHeight;
    // if(imageWidth/imageHeight >=4) {
    //   event.target.style.opacity = 0.2;
    // }
    if (imageWidth / imageHeight >= 1.5) {
      event.target.closest("div").style.padding = '0';
    }
  }

  const handleProfileCardImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 0.75) {
      event.target.closest("#leftSectionCardId").style.maxWidth = imageWidth + "px"
    } else if (imageWidth / imageHeight >= 1) {
      event.target.closest("#imageWrapper").style.justifyContent = "center";
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionCardId").style.backgroundColor = "rgba(0,0,0,0)";
    event.target.closest("#leftSectionCardId").nextSibling.style.opacity = 1;
  }

  var quals_raw_data = []

  if (jobseekerInfo) {
    var qualifications_sorted = jobseekerInfo.user_qualifications ? jobseekerInfo.user_qualifications.sort((a, b) => new Date(b.user_passing_year) - new Date(a.user_passing_year)) : null;
    if (qualifications_sorted)
      //keeps all records that have course_name and user_passing_year
      quals_raw_data = qualifications_sorted.filter((item, i, array) =>
        array.findIndex(t => t.course_name && t.user_passing_year && item.course_name && item.user_passing_year && t.course_name == item.course_name && t.user_passing_year == item.user_passing_year) == i
      );

    //var quals_raw_data = qualifications_sorted;

    if (qualifications_sorted)
      //keeps the records that have id
      qualifications_sorted = qualifications_sorted.filter((item, i, array) =>
        array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
      );

    var years_to_display = (jobseekerInfo.total_months_exp / 12).toFixed(0) == 1 ? " year " : " years ";
    var months_to_display = jobseekerInfo.total_months_exp % 12 == 1 ? " month " : " months  ";

    var last_active = moment(new Date()).diff(moment(new Date(jobseekerInfo.last_seen_at * 1000)), 'days');

    var total_years = Math.trunc(jobseekerInfo.total_months_exp / 12)
    var total_months = jobseekerInfo.total_months_exp % 12;
    var fresher = false;
    var less_than_month = jobseekerInfo.total_months_exp > 0 && jobseekerInfo.total_months_exp < 1 ? true : false;

    if ((total_years == 0 && total_months == 0) || jobseekerInfo.user_experiences == [] || jobseekerInfo.user_experiences == [{}] || jobseekerInfo.total_months_exp == null || jobseekerInfo.total_months_exp == 0)
      fresher = true;

    var experiences_sorted = jobseekerInfo.user_experiences && jobseekerInfo.user_experiences.length != 0 ? jobseekerInfo.user_experiences.sort((a, b) => {

      if (a == {} || b == {})
        return 0;
      if (a.company_ending_date === b.company_ending_date)
        return 0;
      else
        if (a.company_ending_date === 'Present') {
          return (new Date(b.company_ending_date) - new Date())
        }
        else
          if (b.company_ending_date === 'Present') {
            return (new Date() - new Date(a.company_ending_date))
          }
          else {
            return (new Date(b.company_ending_date) - new Date(a.company_ending_date))
          }
    }) : null;

    experiences_sorted = experiences_sorted
      && experiences_sorted.filter((item, i, array) =>
        array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
      )

    var full_name = jobseekerInfo.jobseeker_name;

    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
      initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var array_name = jobseekerInfo.jobseeker_name.split(" ");
    var k = 0, name_sentence_case = "";
    for (; k < array_name.length; k++) {
      if (array_name[k])
        name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
    }
  }

  var bg_colors = ["#B9E4D9", "#FDEBB4", "#FFCDD3", "#E4BDDB"]
  var text_colors = ["#5BC2A8", "#FCD450", "#EF9A9B", "#C361B1"]

  //common func to remove localstorage data on session expiry
  const removeLocalStorageOnSessionExpiry = () => {
    localStorage.setItem('company_id', "");
    localStorage.setItem('company_email_id', "");
    localStorage.setItem('company_name', "");
    localStorage.setItem('employer_name', "");
    localStorage.removeItem('is_logged_in');
    setIsSignedIn(false);
    global_isSignedIn.isSignedIn = false;
    handleDrawer();
    //handleAlert("Oops! You've been logged out. Please login again.", 'error');
  }

  const handleUninviteYesClose = () => {
    // AnalyticsHandler.track("FullProfile_Uninvite_btnClick");
    if (isSignedIn) {
      let payload = {
        company_id,
        jobseeker_id,
        job_role: roleToUninvite,
        location: uninvitedRoleLocation,
        invite_status: false,
      }
      ApiHandler.sendInvite(payload, response => {
        if (response.status == 200) {
          setOpen(false);
          handleSnackBar("The jobseeker has been uninvited successfully")
          setRefreshProfile(prev => !prev);
          if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
            setSelected(false);
          } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
            setSelected(false);
          }
          setGotUninviteResponse(true);
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              if (response.response.data.message === "Session Expire") {
                removeLocalStorageOnSessionExpiry();
              }
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
        }
      })
    } else if (!isSignedIn) {
      handleDrawer();
    }
  }
  // Invite button functionality ends

  const handleShortlistClick = (item, index) => {
    let payload = {
      company_id,
      invite_id: item._id,
      shortlist_status: !shortlistStatus[index],
    }
    ApiHandler.shortlistToggle(payload, (response) => {
      if (response.status === 200) {
        shortlistStatus.splice(index, 1, payload.shortlist_status)
        if (response.data.shortlist_status) {
          handleSnackBar(`Thanks for shortlisting this jobseeker. We will inform them to call you for further process.`);
        } else {
          handleSnackBar("Jobseeker has been successfully removed from shortlist bucket.");
        }
      } else {
        var ErrorMessage = 'Oops! Something went wrong. Please try again.';
        if (response.response) {
          let statusCode = response.response.status;
          if (statusCode === 500) {
            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
          } else if (statusCode === 404) {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else if (statusCode === 400) {
            //ErrorMessage = "Oops! You've been logged out. Please login again."; // does not show as the below statement causes re-render
            //removeLocalStorageOnSessionExpiry && removeLocalStorageOnSessionExpiry();
            if (response.response.data.message === "Session Expire") {
              removeLocalStorageOnSessionExpiry();
            }
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
        } else if (response.message === 'Network Error') {
          ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
        } else {
          ErrorMessage = 'Oops! Something went wrong. Please try again.';
        }
        handleAlert(ErrorMessage, 'error');
      }
    });

  };

  const [openHireDrawer, setOpenHireDrawer] = React.useState(false);
  const [hireItem, setHireItem] = React.useState('');
  const [hireIndex, setHireIndex] = React.useState('');
  const handleHireClick = (item, index) => {
    if (!hireStatus[index]) {
      setOpenHireDrawer(true);
      setHireItem(item);
      setHireIndex(index);
    }
  };

  const handleHireYesClose = () => {
    const payload = {
      company_id,
      invite_id: hireItem._id,
      hired_status: !hireStatus[hireIndex]
    };

    ApiHandler.sendHired(payload, (response) => {
      if (response.status == 200) {
        hireStatus.splice(hireIndex, 1, payload.hired_status)
        setOpenHireDrawer(false);
        handleSnackBar('Congratulations for hiring this jobseeker. We will inform them about their selection.');
        // sessionStorage.setItem('isHireToastDisplayed', true);
      }
      else {
        var ErrorMessage = 'Oops! Something went wrong. Please try again.';
        if (response.response) {
          let statusCode = response.response.status;
          if (statusCode === 500) {
            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
          } else if (statusCode === 404) {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          } else if (statusCode === 400) {
            props.removeLocalStorageOnSessionExpiry();
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
        } else if (response.message === 'Network Error') {
          ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
        } else {
          ErrorMessage = 'Oops! Something went wrong. Please try again.';
        }
        handleAlert(ErrorMessage, 'error');
      }
    });

  };

  const inviteForNewRole = () => {
    setSelectedJobseekerLocation(values.location);
    axios.post(`${BASE_URL}/jobposting/employer_invite_jobseeker`, {
      company_id: `${localStorage.getItem('company_id')}`,
      invite_status: true,
      job_role: values.role,
      jobseeker_id: jobseeker_id,
      location: values.location
    })
      .then(res => {
        if (res.status === 200) {
          setChooseRole(false);
          setInviteConfirmPage(true);
          setSixSecondsTimer(6)
          setStartTimer(prev => !prev);
        }
      })
  }

  //timer functionality on invite confirmed page starts
  const [initialRender, setInitialRender] = React.useState(true);
  const timer = React.useRef();
  const interval = React.useRef();

  React.useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      timer.current = setTimeout(() => {
        // localStorage.setItem('searched_role', chooseRole ? values.role : selectedJobseekerRole);
        history.push({
          pathname: chooseRole ? `/discover/${values.role}/${values.location}` : (selectedJobseekerLocation === ("Anywhere/ Work-from-Home" || "Work From Home") ? `/discover/${selectedJobseekerRole}/anywhere` : `/discover/${selectedJobseekerRole}/${selectedJobseekerLocation.split(',')[0]}`),
        });
      }, 6000);
      interval.current = setInterval(() => {
        setSixSecondsTimer(prev => prev - 1);
      }, 1000);
    }
  }, [startTimer]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearInterval(interval.current);
    };
  }, [openInviteModal]);

  //timer functionality ends

  React.useEffect(() => {
    axios.get(`${BASE_URL}/companies/getalljobpostingbycompanyid/${localStorage.getItem('company_id')}/0/100`)
      .then(res => {
        if (res.data[0].results.length > 0) {
          setPostedJobs(res.data[0].results);
          // setTotalPostedJobs(res.data[0].totalCount[0].count);
        } else {
          setPostedJobs([]);
          // setTotalPostedJobs(0);
        }
      })
      .catch(err => {
      })
  }, [openInviteModal]);

  React.useEffect(() => {
    axios.get(BASE_URL + '/users/get_job_role_mapping').then((Result) => {
      let rolesArr = Result.data;
      setRoles(rolesArr);
    })
  }, [chooseRole]);

  React.useEffect(() => {
    if (locationValue === "Anywhere/ Work-from-Home") {
      setValues({ ...values, location: "Work From Home" });
    } else {
      setValues({ ...values, location: locationValue });
    }
  }, [latLong, locationValue]);

  React.useEffect(() => {
    if (values.role !== '' && values.location !== '') {
      setDisableConfirmInviteBtn(false);
    } else {
      setDisableConfirmInviteBtn(true);
    }
  }, [values])

  return (
    <StylesProvider injectFirst>
      <Helmet>
        <title>{name_sentence_case}</title>
        <meta name='description' content={`${experiences_sorted && experiences_sorted.length !== 0 ? experiences_sorted[experiences_sorted.length - 1].user_post : qualifications_sorted && qualifications_sorted.length !== 0 ? (qualifications_sorted[0].user_degree || qualifications_sorted[0].course_name) : 'Fresher'}`} />
        {/* <link rel='canonical' href={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} /> */}

        {/* Google / Search Engine Tags */}
        {/* <meta itemprop="name" content={`Jobseekers with ${role} experience from ${match.params._location}`} />
        <meta itemprop="description" content={`Hire jobseekers with ${role} experience from ${match.params._location}. Find the perfect candidate for your preferred role`} />
        <meta itemprop="image" content="" /> */}

        {/* Open graph / facebook */}
        {/* <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} />
        <meta property="og:title" content={`Jobseekers with ${role} experience from ${match.params._location}`} />
        <meta property="og:description" content={`Hire jobseekers with ${role} experience. Find the perfect candidate for your preferred role.`} />
        <meta property="og:image" content='https://www.shutterstock.com/image-photo/mountains-under-mist-morning-amazing-260nw-1725825019.jpg' /> */}

        {/* Twitter */}
        {/* <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://employer.meetworks.in/discover/${role}/${match.params._location}`} />
        <meta property="twitter:title" content={`Jobseekers with ${role} experience from ${match.params._location}`} />
        <meta property="twitter:description" content={`Hire jobseekers with ${role} experience. Find the perfect candidate for your preferred role.`} />
        <meta property="twitter:image" content="" /> */}
      </Helmet>
      <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', paddingTop: 85, }}>

        {/* Header */}
        <PrimaryAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
        >
        </PrimaryAppBar>

        {/* Profile Card */}
        <div id="cardWrapper_profileLaptop" className={classes.root}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ position: 'absolute', bottom: '-16px' }}>
            {/* invite button */}
            <ToggleButton
              id="invite_uninvite_card_btn_id"
              value="check"
              className={classes.bigIconRect}
              style={{ marginRight: 10, width: '11em' }}
              classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
              selected={isSignedIn && invited}
              // onChange={gotResponse ? handleInviteClick : undefined}
              onChange={() => {
                if (isSignedIn) {
                  setOpenInviteModal(true);
                  setOpenRolesList(true);
                  setChooseRole(false);
                  setInviteConfirmPage(false);
                } else {
                  handleDrawer();
                }

              }}
            >
              {isSignedIn && invited ?
                <CheckIcon className={classes.saveIconRect} />
                : <EmailIcon className={classes.saveIconRect} />
              }
              {isSignedIn && invited ? "Take Next Steps" : "Email Invite"}
            </ToggleButton>
            {/* share button */}
            {/* {!selected ?  */}
            <CopyToClipboard text={window.location} >
              <Button
                className={classes.bigIconRect}
                // style={{ width: 150, left: '-10px', bottom: 10, width: 100, border: 'none' }}
                variant="outlined" id="shareButton"
                onClick={() => {
                  // AnalyticsHandler.track('FullProfile_ShareButton_Click');
                  handleSnackBar(`Profile link is copied`)
                }}
              ><ShareIcon className={classes.saveIconRect} /> Share </Button>
            </CopyToClipboard>
            {/* : <> */}


          </Grid>
          <Paper className={classes.paper} elevation={3} >
            {loader && <Loader minHeight='72vh' maxWidth='40px' />}
            {jobseekerInfo &&
              <Grid container direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.profileSection} >
                {/* Profile Image */}
                <Grid xs={5} item className={classes.leftSection} id="leftSectionCardId">
                  {jobseekerInfo ?
                    <div className={classes.imageWrapper} id="imageWrapper">
                      {jobseekerInfo.user_image_url ?
                        <img className={classes.img} alt="candidates profile picture" src={jobseekerInfo.user_image_url} onLoad={handleProfileCardImage} style={{ opacity: 0 }} />
                        :
                        <div ref={imgPholderRef} className={classes.imgPholder} style={{ backgroundColor: `${bg_colors[0]}` }}>
                          <p style={{ fontSize: 72, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 150, height: 150, lineHeight: '150px', textAlign: 'center', borderRadius: '50%', color: `${text_colors[0]}` }}> {initials} </p>
                        </div>
                      }
                    </div>
                    : null}
                </Grid>

                {/* User Details */}
                <Grid item xs={7} sm container direction="column"
                  justifyContent="center"
                  alignItems="center"
                  ref={righSectionRef}
                  style={{ paddingTop: 50, paddingBottom: 100, opacity: 0 }}>
                  {jobseekerInfo ?
                    <Grid item xs={10} container direction="column" justifyContent="center" alignItems="flex-start">

                      {/* Name and location */}

                      <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ minHeight: 52 }}>
                        <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="baseline" style={{ marginLeft: 38 }}>
                          <Typography variant="h6" style={{ marginRight: 7 }}>
                            {name_sentence_case}
                          </Typography>
                          <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                            <span style={{ fontSize: 16, marginRight: 4, color: '#ccc' }}>|</span>
                            <span style={{ position: 'relative', marginRight: 8 }}>
                              {((jobseekerInfo.area || jobseekerInfo.city || jobseekerInfo.distric)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                            <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                              {jobseekerInfo.area && jobseekerInfo.city ? jobseekerInfo.area + ', ' + jobseekerInfo.city : (jobseekerInfo.city && jobseekerInfo.distric ? jobseekerInfo.city + ', ' + jobseekerInfo.distric : (jobseekerInfo.area && jobseekerInfo.distric ? jobseekerInfo.area + ', ' + jobseekerInfo.distric : (jobseekerInfo.city ? jobseekerInfo.city : (jobseekerInfo.distric ? jobseekerInfo.distric : (jobseekerInfo.area ? jobseekerInfo.area : "")))))}
                            </span></span>
                        </Grid>

                        {isSignedIn && invitedRolesList.length !== 0 ?
                          <Grid item xs container direction="row" wrap='nowrap' justifyContent="flex-start" alignItems="flex-start" style={{ marginLeft: '37px', paddingTop: 2 }}
                          >
                            <CheckIcon className="primaryText" style={{ fontSize: 14, paddingRight: 2, paddingTop: 2 }} />
                            <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                              Invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>
                                {invitedRolesList.sort(function (a, b) {
                                  return new Date(b.invite_time) - new Date(a.invite_time);
                                })
                                  .map((item, i) => {
                                    if (i < invitedRolesList.length - 1) {
                                      return item.job_role + ", "
                                    } else {
                                      return item.job_role
                                    }
                                  })}
                              </span>
                            </Typography></Grid> : null
                        }
                      </Grid>

                      <div style={{ marginTop: 20, marginLeft: 10 }}>
                        {/* Experience */}
                        <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 0 }}>
                          <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{ maxWidth: 20, marginRight: 10, marginLeft: '28px' }}>
                            <WorkIcon fontSize="small" className="primaryText" style={{ marginTop: 1, marginBottom: 12 }} />
                          </Grid>
                          <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 5 }}>
                              <Typography variant="subtitle1" style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Experience</Typography>
                              {fresher ?
                                <Chip size="small" label={(fresher ? 'Fresher' : less_than_month ? " < 1 month " : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display)))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} />
                                : null
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                        {experiences_sorted && experiences_sorted.map((item, i) => (
                          <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12 }} key={i}>
                            <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{
                              maxWidth: 20,
                              marginRight: 58,
                              marginLeft: '-20px',
                              marginTop: 8,
                            }}>
                              <div className={classes.card__LogoWrapper_reg}>
                                {item.company_logo ? <img className={classes.img} alt="" src={item.company_logo ? item.company_logo : null}
                                  onLoad={handleLogoImageLoad} /> : ""}
                              </div>
                            </Grid>
                            <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                              <Typography variant="subtitle1" style={{ marginLeft: 0, fontWeight: 500 }}>
                                {item.user_post ? item.user_post : ""}
                              </Typography>
                              <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 15 }}>
                                {item.company_name ? item.company_name : ""}
                              </Typography>
                              <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 13, color: '#999' }}>
                                {item.company_starting_date && item.company_ending_date && item.company_ending_date == "Present" ? months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + "Present" : ""}
                                {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " - " + months[new Date(item.company_ending_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}
                                {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}
                                {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" && ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) && (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}
                              </Typography>
                              <Typography variant="subtitle1"
                                style={{
                                  marginLeft: 0, marginTop: 5, fontSize: 14,
                                  // fontStyle: 'italic' 
                                }}>
                                {item.role_discription ? item.role_discription : ""}
                              </Typography>
                            </Grid>
                          </Grid>))}
                        {/* Education */}
                        <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12, marginLeft: '27px' }}>
                          <SchoolRoundedIcon fontSize="small" className="primaryText" style={{ marginTop: 1 }} />

                          <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">

                            <Typography variant="subtitle1" style={{ marginBottom: 12, marginLeft: 10, fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Education</Typography>

                            {
                              quals_raw_data && quals_raw_data.map((item, i) => (
                                <div key={i} style={{ marginBottom: 10 }}>
                                  <Typography variant="subtitle1" style={{ marginLeft: 10, fontWeight: 500, lineHeight: 1.5 }}>
                                    {(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : (item.course_name ? item.course_name : null))}{(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}
                                    {!item.user_college ? " | " + item.user_passing_year : ""}
                                  </Typography>
                                  <Typography variant="subtitle1" style={{ marginLeft: 10, fontSize: 15 }}>
                                    {(item.user_college && item.user_passing_year ? item.user_college + " | " + item.user_passing_year : item.user_college)}
                                  </Typography>
                                </div>))}

                          </Grid>
                        </Grid>
                      </div>

                    </Grid>
                    : null}
                </Grid>
              </Grid>
            }
          </Paper>
        </div>

        {/* Footer */}
        {!isSignedIn &&
          <div style={{
            position: 'fixed', bottom: 0, backgroundColor: "white", width: "100%",
            boxShadow: '0px -4px 6px rgb(0 0 0 / 5%)',
            padding: '15px 10px'
          }}>
            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", fontSize: 15, color: "#797979" }}>
              <h3 style={{ position: 'absolute', left: 24, textAlign: "center", bottom: 5 }}> Explore Meet!</h3>

              <span style={{ marginRight: 20, lineHeight: '35px' }} > Looking to hire? </span>
              <Button
                variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`}
                onClick={() => window.location.href = "https://employer.meetworks.in"}>
                Find jobseekers
              </Button>

              <span style={{ marginLeft: 30, marginRight: 30 }}> <div style={{ height: 30, borderLeft: '1px solid #797979', marginTop: 3 }}></div> </span>

              <span style={{ marginRight: 20, lineHeight: '35px' }} > Want to start your career? </span>
              <Button
                variant="contained" className={`${classes.headerButton_short} ${classes.headerButton_pri}`}
                onClick={() => window.location.href = "https://findwork.meetworks.in"}>
                Find jobs
              </Button>

              <span style={{ marginLeft: 30, lineHeight: '35px' }}><a
                // href="https://play.google.com/store/apps/details?id=com.meetjobseekerapp" 
                href="https://api.whatsapp.com/send?phone=919580899412&text=My%20Website"
                style={{ color: "#14967B", fontFamily: '"Roboto", "Mukta", "Arial", "sans-serif"' }}>अपनी personal career website बनाएं for free</a></span>
            </div>
          </div>
        }
      </div>

      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />

      <SnackBarCustom canSnackBarOpen={canSnackBarOpen} text={snackBarText} refresh={refreshSnackBar} />
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
      <div>
        <Dialog
          open={openInviteModal}
          onClose={closeInviteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paperScrollPaper: classes.inviteDialogPaper }}
        >
          <CloseIcon
            onClick={() => {
              setOpenInviteModal(false);
              setChooseRole(false);
            }}
            className={classes.cursor_pointer} style={{ position: 'fixed', top: '2%', right: '2%', border: '1px solid #14967b', borderRadius: '50%', color: '#14967b', backgroundColor: '#fff', padding: '3px' }} />
          {/* only shown when dialog open */}
          {openRolesList && invitedRolesList.length > 0 &&
            <DialogTitle id="alert-dialog-title">
              {/* {"Posted Roles"} */}
              <Grid container direction='column' justifyContent='center' alignItems='flex-start' >
                <Grid item>
                  <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 'bold', color: '#55c6a9' }} >
                    Invited Roles
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='body2' component='p' >
                    Take next steps
                  </Typography>
                </Grid>
              </Grid>
            </DialogTitle>}
          <DialogContent>
            {/* dialog content on first render */}
            {/* {dialogLoader && <Loader minHeight='42.26vh' maxWidth='40px' />} */}
            {openRolesList &&
              <Grid container direction='column' wrap='nowrap' justifyContent='flex-start' alignItems='flex-start' style={{ paddingBottom: '5em' }} >
                {invitedRolesList.map((item, index) => (
                  <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap' key={index}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: '6px', padding: '0.8em', marginBottom: '0.8em' }}
                  >
                    <Grid container alignItems='flex-start' justifyContent='center' direction='column' style={{ width: hireStatus[index] ? 'auto' : '30vw', paddingRight: '1em' }} >
                      <Grid item>
                        <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 500, fontSize: '0.95em' }} >
                          {`${item.job_role} - ${item.location} `}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2' component='p' >
                          {getinvitedDate(item.invite_time)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems='center' wrap='nowrap' justifyContent='flex-end' style={{ width: 'auto' }} >
                      {!hireStatus[index] && <Grid item>
                        <ToggleButton
                          value='check'
                          color='primary'
                          style={{ marginRight: '1em' }}
                          className={`${classes.ttc} ${classes.bigIconRect}`}
                          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                          onChange={() => handleUninviteClickOpen(item)}
                        >
                          {<CancelOutlined className={classes.saveIconRect} />}
                          {"Cancel Invite"}
                        </ToggleButton>
                      </Grid>}
                      {!hireStatus[index] && <Grid item>
                        <ToggleButton
                          value='check'
                          color='primary'
                          style={{ marginRight: '1em', padding: '0.2em 0.8em' }}
                          selected={shortlistStatus[index]}
                          className={`${classes.ttc} ${classes.bigIconRect}`}
                          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                          onChange={() => handleShortlistClick(item, index)}
                        >
                          {shortlistStatus[index] ? <StarRoundedIcon className={classes.saveIconRect} /> : <StarOutlineRoundedIcon className={classes.saveIconRect} />}
                          {shortlistStatus[index] ? "Shortlisted" : "Shortlist"}
                        </ToggleButton>
                      </Grid>}
                      <Grid item>
                        <ToggleButton
                          value='check'
                          color='primary'
                          style={{ padding: '0.2em 0.8em' }}
                          selected={hireStatus[index]}
                          className={`${classes.ttc} ${classes.bigIconRect}`}
                          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                          onChange={() => handleHireClick(item, index)}
                        >
                          {hireStatus[index] ? <CheckCircleRoundedIcon className={classes.saveIconRect} /> : <CheckCircleOutlineRoundedIcon className={classes.saveIconRect} />}

                          {hireStatus[index] ? "Hired" : "Hire"}
                        </ToggleButton>
                      </Grid>

                    </Grid>
                  </Grid>
                ))}

                <Grid item className={invitedRolesList.length === 0 ? classes.dialogRolesSubHeadingContainer : classes.my1} >
                  <Typography className={invitedRolesList.length === 0 ? classes.dialogRolesSubHeading : ''} variant='body2' component='p' >
                    Invite for other posted roles
                  </Typography>
                </Grid>
                {postedJobs.length > 0 && postedJobs.filter(item => item.job_location.length !== 0).map((item, index) => (
                  <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap' key={index}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: '6px', padding: '0.8em', marginBottom: '0.8em' }}
                  >
                    <Grid item>
                      <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 500, fontSize: '0.95em' }} >
                        {`${item.job_title} ${item.job_location.length !== 0 ? '- ' + item.job_location.map(loc => loc.city) : ''}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        // size='small'
                        style={{ marginRight: '1em', padding: '0.2em 0.8em', textTransform: 'capitalize' }}
                        startIcon={<MailOutlineIcon />}
                        onClick={() => {
                          let invitedLocArr = [];
                          item.job_location.forEach((element, index) => {
                            axios.post(`${BASE_URL}/jobposting/employer_invite_jobseeker`, {
                              company_id: `${localStorage.getItem('company_id')}`,
                              invite_status: true,
                              job_role: item.job_title,
                              jobseeker_id: jobseeker_id,
                              location: element.city
                            }).then(res => {
                              setSelectedJobseekerRole(item.job_title);
                              invitedLocArr.push(element.city)
                              if (index === item.job_location.length - 1) {
                                setChooseRole(false);
                                setOpenRolesList(false);
                                setInviteConfirmPage(true);
                                setSelectedJobseekerLocation(invitedLocArr.toString());
                                setStartTimer(prev => !prev);
                                setSixSecondsTimer(6)
                              }
                            }).catch(err => {
                              handleSnackBar("You have already invited this jobseeker for the selected role")
                            })
                          })
                        }}
                      >
                        Invite
                      </Button>
                    </Grid>
                  </Grid>
                ))
                }
                <Grid item style={{ position: 'absolute', bottom: 0, width: '95%', padding: '1em 0', backgroundColor: '#fff', marginLeft: '-3px' }}>
                  <Button variant='contained'
                    onClick={() => {
                      setOpenRolesList(false);
                      setChooseRole(true);
                      setLocationValue('')
                    }}
                    style={{ textTransform: 'none' }}
                    color="primary">
                    Invite for a new role
                  </Button>
                </Grid>
              </Grid>}
            {/* dialog content on first render ends here */}

            {/* dialog content after invite btn click */}
            {chooseRole &&
              <Grid container direction='column' style={{ padding: '1.5em 0.5em' }}>
                <Grid item >
                  <Typography variant='body1' gutterBottom component='h6' style={{ color: '#14967b' }}>
                    Role
                  </Typography>
                </Grid>
                <Grid item style={{ margin: '0.6em 0' }} >
                  <Autocomplete
                    options={roles}
                    // disablePortal
                    name="role"
                    // value={data.sector_name || ""}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.title
                    }
                    }
                    style={{ flex: 0.75, display: 'inline' }}

                    onChange={(event, newValue) => {
                      event.preventDefault();
                      if (typeof newValue === 'string') {
                        setValues({ ...values, role: newValue });
                      } else if (newValue) {
                        setValues({ ...values, role: newValue.title });
                      } else {
                        setValues({ ...values, role: "" });
                      }
                    }}

                    // classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                    renderInput={(params) => <TextField placeholder='What position are you hiring for?' {...params} variant='outlined' />
                    }
                    // getOptionSelected={(option, value) => option.sector_name === value}

                    renderOption={(option) => {
                      if (option) {
                        return (
                          <Grid container alignItems="flex-start" direction="column">
                            <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.title} </Typography>
                          </Grid>
                        )
                      }
                    }}
                  />
                </Grid>
                <Grid item style={{ margin: '1em 0 0.6em 0' }} >
                  <Typography variant='body1' gutterBottom component='h6' style={{ color: '#14967b' }}>
                    Location
                  </Typography>
                </Grid>
                <Grid item >
                  <Location
                    //profileObj={profileObj}
                    locationValue={locationValue}
                    setLocationValue={setLocationValue}
                    latLong={latLong}
                    setLatLong={setLatLong}
                    placeholder='Start typing e.g. Hazratganj, Lucknow'
                    fontSizeClass={true}
                    anywhere={true}
                  //   isErrorAboutMe={isErrorAboutMe}
                  //   setIsLocationAutocompletePageOpen={setIsLocationAutocompletePageOpen}
                  />
                </Grid>
                <Grid item className={classes.mt2} >
                  <Button disabled={disableConfirmInviteBtn} className={classes.ttn} variant='contained' color="primary"
                    style={{ color: '#fff', position: 'absolute', bottom: '30%', textTransform: 'capitalize' }}
                    onClick={() => {
                      setSelectedJobseekerRole(values.role);
                      setSelectedJobseekerLocation(values.location);
                      inviteForNewRole();

                    }}
                  >
                    Confirm Invite
                  </Button>
                </Grid>
              </Grid>
            }
            {/* dialog content after invite btn click ends here */}

            {/* dialog content after confirm invite for custom role & location */}

            {inviteConfirmPage && <Grid container direction='column' justifyContent='center' style={{ padding: '1.5em 0.5em' }}>
              <Grid container style={{ minHeight: '30vh' }} alignItems='center' justifyContent='center' direction='column'>
                <Grid item>
                  <span><CheckCircleOutlineIcon color='secondary' fontSize='large' /></span>
                </Grid>
                <Grid item >
                  <Typography className={`${classes.my1} ${classes.color_secondary}`} variant='h6'> Invite Sent!</Typography>
                </Grid>
                <Grid className={classes.px1} item>
                  <Typography className={classes.textAlignCenter} variant='body1' component='p'>
                    You invite has been successfully to <span className={`${classes.bold} ${classes.ttc}`}>{selectedJobseekerName}</span> for <span className={classes.bold}>{selectedJobseekerRole}</span> role at <span className={classes.bold}>{selectedJobseekerLocation}</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems='center' justifyContent='center' direction='column'>
                <Grid className={classes.my1} item>
                  <Typography className={classes.textAlignCenter} variant='body2' component='p' >
                    Redirecting you to the Discovery page where you can find and Invite jobseekers for {selectedJobseekerRole} role in {selectedJobseekerLocation.split(',')[0]} in <span style={{ color: 'red' }}>0{sixSecondsTimer}</span> seconds.
                  </Typography>
                </Grid>
                <Grid className={`${classes.my0}`} item>
                  <Button
                    variant='outlined'
                    color='primary'
                    style={{ padding: '0.2em 0.8em' }}
                    className={classes.ttn}
                    onClick={() => {
                      // stopRedirectTimeout();
                      setChooseRole(false);
                      setOpenInviteModal(false);
                      setInviteConfirmPage(false);
                      setLoader(true)
                      setRefreshProfile(prev => !prev);
                    }}
                  >
                    Stay on this page
                  </Button>
                </Grid>
              </Grid>
            </Grid>}

          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openHireDrawer}
          onClose={() => setOpenHireDrawer(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="alert-dialog-title">{"Hiring Confirmation"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to hire <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{jobseekerInfo && jobseekerInfo.jobseeker_name ? jobseekerInfo.jobseeker_name.toLowerCase() : ""}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={() => setOpenHireDrawer(false)}>
              Cancel
            </Button>
            <Button id="hire_btn_id" onClick={handleHireYesClose} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleUninviteCancelClose}
          aria-labelledby="uninvite-dialog-title"
          aria-describedby="uninvite-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="uninvite-dialog-title">{"Confirmation to cancel invite"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="uninvite-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to cancel your invite to <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{jobseekerInfo && jobseekerInfo.jobseeker_name ? jobseekerInfo.jobseeker_name.toLowerCase() : ""}</span> for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{roleToUninvite}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
              Cancel
            </Button>
            <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </StylesProvider>
  )
}
