import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Routes from './routes/Routes';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
// import { Provider } from 'react-redux'
// import Store from './store/store'
import 'tachyons';

// require("dotenv").config();

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#14967B',
    },
    secondary: {
      main: '#55c6a9',
    },
  },
});

ReactDOM.render(
  <HelmetProvider>
    <MuiThemeProvider theme={muiTheme}>
      {/* <Provider store={Store}> */}
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      {/* </Provider> */}
    </MuiThemeProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
