import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListIcon from '@material-ui/icons/List';
import InvitedListItem from './FollowersListView';
// import InvitedListItem from '../../views/openings/ListView';
import ApplicantsCard from './FollowersCardView';
import MobileFollowersList from './MobileFollowersList';
import InfiniteScroll from 'react-infinite-scroll-component';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import StarRoundedIcon from '@material-ui/icons/StarBorder';
import StarOutlineRoundedIcon from '@material-ui/icons/StarBorder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Location from './Location';
import moment from 'moment';
import ApiHandler from '../../ApiHandler';
import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import FeedbackAlert from '../../components/FeedbackAlert';
import { useHistory } from 'react-router-dom';
import Loader from './Loader';
import AnalyticsHandler from '../../AnalyticsHandler';
import ArrowBack from '@material-ui/icons/ArrowBack';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const getinvitedDate = (date) => {
  let d = date
  d = new Date(d);
  d = moment(d).format("Do MMM YYYY");
  return d
}

const isMobile = window.innerWidth <= 600 ? true : false;

function Followers(props) {
  const { classes, loader, setLoader } = props;
  const [profileView, setProfileView] = React.useState('list');
  const [listView, setListView] = React.useState(true);
  const [cardView, setCardView] = React.useState(false);
  const [profiles, setProfiles] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [profileIndex, setProfileIndex] = React.useState(0);
  const [profilesOffset, setProfilesOffset] = React.useState(10);
  const [totalProfiles, setTotalProfiles] = React.useState(null);
  const [openInviteModal, setOpenInviteModal] = React.useState(false);
  const [openRolesList, setOpenRolesList] = React.useState(true);
  const [chooseRole, setChooseRole] = React.useState(false);
  const [disableConfirmInviteBtn, setDisableConfirmInviteBtn] = React.useState(true);
  const [locationValue, setLocationValue] = React.useState();
  const [latLong, setLatLong] = React.useState();
  const [inviteConfirmPage, setInviteConfirmPage] = React.useState(false);
  const [postedJobs, setPostedJobs] = React.useState([]);
  const [totalPostedJobs, setTotalPostedJobs] = React.useState(null);
  const [invitedRoles, setInvitedRoles] = React.useState([]);
  const [jobseeker_id, setJobseeker_id] = React.useState('');
  const [selected, setSelected] = React.useState(false);
  let cid = localStorage.getItem('company_id');
  const [company_id, setCompanyId] = React.useState(cid);
  // const [gotResponse, setGotResponse] = React.useState(true);
  const [shortlistStatus, setShortlistStatus] = React.useState([]);
  const [hireStatus, setHireStatus] = React.useState([]);
  const [selectedJobseekerName, setSelectedJobseekerName] = React.useState('');
  const [selectedJobseekerRole, setSelectedJobseekerRole] = React.useState('');
  const [selectedJobseekerLocation, setSelectedJobseekerLocation] = React.useState('');
  const [values, setValues] = React.useState({
    role: '',
    location: ''
  });
  const [sixSecondsTimer, setSixSecondsTimer] = React.useState(6);
  const [gotHireResponse, setGotHireResponse] = React.useState(true);
  const [initialRender, setInitialRender] = React.useState(true);
  const [startTimer, setStartTimer] = React.useState(false);
  const [dialogLoader, setDialogLoader] = React.useState(true);
  const [refresh, dorefresh] = React.useState(false);
  const [cardViewLoader, setCardViewLoader] = React.useState(false);

  const timer = React.useRef();
  const interval = React.useRef();

  const history = useHistory();
  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  };

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }

  React.useEffect(() => {
    let shortlistArr = [];
    let hireArr = [];
    invitedRoles && invitedRoles.forEach(element => {
      shortlistArr.push(element.shortlist_status);
      hireArr.push(element.hired_status);
    })
    setShortlistStatus(shortlistArr);
    setHireStatus(hireArr);
  }, [invitedRoles])



  // const inviteJobseekerForNewRole =(item) => {
  //   console.log(item.currentTarget)
  //   axios.post(`${BASE_URL}/jobposting/employer_invite_jobseeker`, {
  //     company_id: '1185',
  //     invite_status: true,
  //     job_role: item.job_title || item.mapped_role,
  //     jobseeker_id: jobseeker_id,
  //     location: item.job_location
  //   })
  // }

  React.useEffect(() => {
    setLoader(true);
    // setDialogLoader(true);
    axios.get(`${BASE_URL}/users/followers/0/10/${localStorage.getItem('company_id')}`)
      .then(res => {
        if (res.data[0].result.length > 0) {
          setProfiles(res.data[0].result);
          setTotalProfiles(res.data[0].totalCount[0].count)
          setLoader(false);
          setDialogLoader(false);
          setOpenInviteModal(false)
        }
        else {
          setProfiles([]);
          setTotalProfiles(0)
          setLoader(false);
          setDialogLoader(false);
          setOpenInviteModal(false)
        }
      })
  }, [refresh]);

  const fetchMoreProfiles = () => {
    setProfilesOffset(prev => prev + 10)
    axios.get(`${BASE_URL}/users/followers/${profilesOffset}/10/${localStorage.getItem('company_id')}`)
      .then(res => {
        setProfiles([...profiles, ...res.data[0].result]);
        // setTotalProfiles(res.data[0].totalCount[0].count);
      })
  }

  React.useEffect(() => {
    axios.get(`${BASE_URL}/companies/getalljobpostingbycompanyid/${localStorage.getItem('company_id')}/0/150`)
      .then(res => {
        if (res.data[0].results.length > 0) {
          setPostedJobs(res.data[0].results.filter(item => (item.mapped_role !== null) && (item.job_location !== null)));
          setTotalPostedJobs(res.data[0].totalCount[0].count);
        } else {
          setPostedJobs([]);
          setTotalPostedJobs(0);
        }
      })
      .catch(err => {
      })
  }, [openRolesList]);

  React.useEffect(() => {
    axios.get(BASE_URL + '/users/get_job_role_mapping').then((Result) => {
      let rolesArr = Result.data;
      setRoles(rolesArr);
    })
  }, [chooseRole]);

  React.useEffect(() => {
    if (locationValue === "Anywhere/ Work-from-Home") {
      setValues({ ...values, location: "Work From Home" });
    } else {
      setValues({ ...values, location: locationValue });
    }
  }, [latLong, locationValue]);

  React.useEffect(() => {
    if (values.role !== '' && values.location !== '') {
      setDisableConfirmInviteBtn(false);
    } else {
      setDisableConfirmInviteBtn(true);
    }
  }, [values])

  const inviteForNewRole = () => {
    setSelectedJobseekerLocation(values.location);
    axios.post(`${BASE_URL}/jobposting/employer_invite_jobseeker`, {
      company_id: `${localStorage.getItem('company_id')}`,
      invite_status: true,
      job_role: values.role,
      jobseeker_id: jobseeker_id,
      location: values.location
    })
      .then(res => {
        if (res.status === 200) {
          setChooseRole(false);
          setInviteConfirmPage(true);
          setSixSecondsTimer(6)
          setStartTimer(prev => !prev);
        }
      })
  }

  const closeInviteDialog = (e, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpenInviteModal(false);
    setChooseRole(false);
    setTimeout(() => {
      setOpenRolesList(true);
    }, 100);
  }
  //Shortlist button functionality starts
  const handleShortlistClick = (item, index) => {
    let payload = {
      company_id,
      invite_id: item._id,
      shortlist_status: !shortlistStatus[index],
    }
    AnalyticsHandler.track("CompanyPage_followers_shortlistBtn");
    if (props.signInStatus) {
      // setGotResponse(false);
      ApiHandler.shortlistToggle(payload, response => {
        // if (status) {
        //   setStatus(false);
        // } else {
        //   setStatus(true)
        // }
        shortlistStatus.splice(index, 1, payload.shortlist_status)
        // console.log(response)
        if (response.status == 200) {
          // arr.splice(index, 1, !response.data.shortlist_status)
          // console.log(shortlistStatus)
          // console.log(arr)
          // if (!sessionStorage.getItem('isShortlistToastDisplayed') && status === false) {
          //   // let newStatus = [...shortlistStatus];
          //   // newStatus.splice(index, 1, true)
          //   // setShortlistStatus(newStatus)
          //   // setSelected(true);
          //   setStatus(true);
          //   // if(response.data.shortlist_status){
          //     handleSnackBar(`Thanks for shortlisting this jobseeker. We will inform them to call you for further process.`);
          //   // }
          //   sessionStorage.setItem('isShortlistToastDisplayed', true)
          // } else if (!sessionStorage.getItem('isShortlistToastDisplayed') && selected === true) {
          //   setSelected(false);
          // } else if (sessionStorage.getItem('isShortlistToastDisplayed')) {
          //   setSelected(prev => !prev);
          // }
          // setStatus(true);
          if (response.data.shortlist_status) {
            handleSnackBar(`Thanks for shortlisting this jobseeker. We will inform them to call you for further process.`);
          } else {
            handleSnackBar("Jobseeker has been successfully removed from shortlist bucket.");
          }
          // setGotResponse(true);
          // dorefresh(prev => !prev);
        } else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              ErrorMessage = "Oops! You've been logged out. Please login again."; // does not show as the below statement causes re-render
              props.removeLocalStorageOnSessionExpiry();
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
          // setGotResponse(true);
        }
      })
    }
  }

  //Hire button functionality starts
  const [hireItem, setHireItem] = React.useState('');
  const [hireIndex, setHireIndex] = React.useState(0);
  const handleHireClick = (item, index) => {
    AnalyticsHandler.track("CompanyPage_followers_hireBtn");
    if (!hireStatus[index]) {
      handleHireClickOpen(item, index);
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleHireClickOpen = (item, index) => {
    setOpen(true);
    setHireItem(item);
    setHireIndex(index);
  };

  const handleHireYesClose = () => {
    let payload = {
      company_id,
      invite_id: hireItem._id,
      hired_status: !hireStatus[hireIndex]
    }
    AnalyticsHandler.track("CompanyPage_followers_hire_YesBtn");
    if (props.signInStatus) {
      setGotHireResponse(false);

      ApiHandler.sendHired(payload, response => {
        if (response.status == 200) {
          hireStatus.splice(hireIndex, 1, payload.hired_status)
          setOpen(false);
          // if (!sessionStorage.getItem('isHireToastDisplayed')) {
          handleSnackBar(`Congratulations for hiring this jobseeker. We will inform them about their selection.`);
          // sessionStorage.setItem('isHireToastDisplayed', true)
          // }
          //setGotHireResponse(true);
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              props.removeLocalStorageOnSessionExpiry();
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
          setGotHireResponse(true);
        }
      })
    }
  };

  const handleHireCancelClose = () => {
    AnalyticsHandler.track("CompanyPage_followers_hire_CancelBtn");
    setOpen(false);
  };

  React.useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      timer.current = setTimeout(() => {
        // localStorage.setItem('searched_role', chooseRole ? values.role : selectedJobseekerRole);
        history.push({
          pathname: chooseRole ? `/discover/${values.role}/${values.location}` : (selectedJobseekerLocation === "Anywhere/ Work-from-Home" ? `/discover/${selectedJobseekerRole}/anywhere` : `/discover/${selectedJobseekerRole}/${selectedJobseekerLocation.split(',')[0]}`),
        });
      }, 6000);
      interval.current = setInterval(() => {
        setSixSecondsTimer(prev => prev - 1);
      }, 1000);
    }
  }, [startTimer]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
      clearInterval(interval.current);
    };
  }, [openInviteModal]);

  return (
    <>
      <div style={{ padding: profileView === 'list' ? '0' : '2em 0 5em 0' }}>
        {!loader && profiles.length > 0 && <Grid item xs={12} container
          alignItems="center"
          className={classes.viewChanger}
          style={{ padding: '0 0 1em 3.5em' }}
        >
          <ToggleButtonGroup size="small"
            value={profileView}
            exclusive
            onChange={() => {
              AnalyticsHandler.track("CompanyPage_followers_viewChangeBtn");
              if (profileView === 'cards') {
                // setProfileIndex(0);
                setProfileView('list')
                setListView(true)
                setCardView(false)
              } else if (profileView === 'list') {
                setCardViewLoader(true);
                setProfileView('cards')
                setListView(false)
                setCardView(true)
              }
            }}
            style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px' }}>
            <ToggleButton id="view_list_btn_id" value="list" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
              <ListIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> All Results
            </ToggleButton>
            <ToggleButton id="view_card_btn_id" value="cards" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
              <RecentActorsIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> 1 by 1
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>}
        {loader && <Loader minHeight='70vh' maxWidth='40px' />}

        {!loader && listView && <div id='scrollableDiv' className={classes.scrollableDiv} >
          <InfiniteScroll
            dataLength={profiles.length}
            next={fetchMoreProfiles}
            hasMore={profiles.length !== totalProfiles}
            loader={<Loader maxWidth='20px' minHeight='5vh' />}
            scrollThreshold={0.8}
            // scrollableTarget='scrollableDiv'
            initialScrollY={1}
          // endMessage={<h3 className="text_center" style={{ marginBottom: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>Currently no one is following you.</h3>}
          >
            <div style={{ padding: '0 0.5em' }}>
              {profiles.length > 0 ? profiles.filter(item => item.user_first_name).map((hit, index) => (
                <li key={index} className={classes.applicant_profile} style={{ width: '100%', listStyleType: 'none' }}>
                  {window.innerWidth <= 600 ?
                    <MobileFollowersList
                      hit={hit}
                      isFollowers={true}
                      setOpenInviteModal={setOpenInviteModal}
                      setInvitedRoles={setInvitedRoles}
                      openInviteModal={openInviteModal}
                      setJobseeker_id={setJobseeker_id}
                      profiles={profiles}
                      setJobseekerName={setSelectedJobseekerName}
                      setOpenRolesList={setOpenRolesList}
                      setDialogLoader={setDialogLoader}
                      dorefresh={dorefresh}
                    />
                    :
                    <InvitedListItem
                      hit={hit}
                      isFollowers={true}
                      setOpenInviteModal={setOpenInviteModal}
                      setInvitedRoles={setInvitedRoles}
                      openInviteModal={openInviteModal}
                      setJobseeker_id={setJobseeker_id}
                      profiles={profiles}
                      setJobseekerName={setSelectedJobseekerName}
                      setOpenRolesList={setOpenRolesList}
                      setDialogLoader={setDialogLoader}
                      dorefresh={dorefresh}
                    // index={index}
                    // setSelected={setSelected}
                    />}
                </li>
              )) :
                <div style={{ minHeight: '40vh' }} className={`${classes.flexbox} ${classes.justify_center} ${classes.al}`}>
                  <Typography variant='h6'>Currently no jobseeker is following you!</Typography>
                </div>}
            </div>
          </InfiniteScroll>
        </div>
        }
        {profiles && cardView && !loader && <li className={`${classes.applicant_profile} ${classes.position_relative}`} style={{ width: '100%', listStyleType: 'none', padding: '0 3.5em' }}>
          {/* {hits.length > 0 ? */}
          {profiles.length > 0 ? <div className={`${classes.flexbox} ${classes.justify_center}`}>
            <ApplicantsCard
              hit={profiles[profileIndex]}
              isFollowers={true}
              setOpenInviteModal={setOpenInviteModal}
              setInvitedRoles={setInvitedRoles}
              openInviteModal={openInviteModal}
              setJobseeker_id={setJobseeker_id}
              profileIndex={profileIndex}
              setJobseekerName={setSelectedJobseekerName}
              setOpenRolesList={setOpenRolesList}
              setDialogLoader={setDialogLoader}
              cardViewLoader={cardViewLoader}
              setCardViewLoader={setCardViewLoader}
            />
            {!cardViewLoader && <NavigateBeforeIcon fontSize='large'
              // className={`${classes.slideBtn} ${classes.prev}`}
              className={profileIndex === 0 ? `${classes.slideBtn} ${classes.prev} ${classes.disabledBtn}` : `${classes.slideBtn} ${classes.prev}`}
              onClick={() => {
                AnalyticsHandler.track("CompanyPage_followers_cardView_prevBtn");
                setCardViewLoader(true);
                if (profileIndex > 0) {
                  setProfileIndex(prev => prev - 1)
                } else {
                  return
                }
              }}
            />}
            {!cardViewLoader && <NavigateNextIcon fontSize='large'
              // className={`${classes.slideBtn} ${classes.next}`}
              className={profileIndex === totalProfiles - 1 ? `${classes.slideBtn} ${classes.next} ${classes.disabledBtn}` : `${classes.slideBtn} ${classes.next}`}
              onClick={() => {
                AnalyticsHandler.track("CompanyPage_followers_cardView_nextBtn");
                setCardViewLoader(true);
                if (profileIndex < profiles.length - 1) {
                  setTimeout(() => {
                    setProfileIndex(prev => prev + 1)
                  }, 700);
                } else {
                  setProfilesOffset(prev => prev + 10);
                  axios.get(`${BASE_URL}/users/followers/${profilesOffset}/10/${localStorage.getItem('company_id')}`)
                    .then(res => {
                      setProfiles([...profiles, ...res.data[0].result]);
                      setProfileIndex(prev => prev + 1);
                    })
                }
              }}
            />}
          </div> : <div style={{ minHeight: '40vh' }} className={`${classes.flexbox} ${classes.justify_center} ${classes.al}`}>
            <Typography variant='h6'>Currently no jobseeker is following you!</Typography>
          </div>}
          {/* : <div className={`${classes.flexbox} ${classes.justify_center}`} style={{ minHeight: '50vh' }}>No applicant found</div>} */}
        </li>}
        <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
        <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
        <div>
          <Dialog
            open={open}
            onClose={handleHireCancelClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            BackdropProps={{
              classes: {
                root: classes.dialogBackdropRoot
              }
            }}
            transitionDuration={150}
          >
            <DialogTitle id="alert-dialog-title">{"Hiring Confirmation"}</DialogTitle>
            <DialogContent style={{ height: 100 }}>
              <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
                Are you sure you want to hire <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{selectedJobseekerName.toLowerCase()}</span>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button id="cancel_hire_btn_id" onClick={handleHireCancelClose}>
                Cancel
              </Button>
              <Button id="hire_btn_id" onClick={gotHireResponse ? handleHireYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openInviteModal}
            onClose={closeInviteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paperScrollPaper: classes.followersDialogPaper }}
          >
            {!isMobile && <CloseIcon
              onClick={() => {
                setOpenInviteModal(false);
                setChooseRole(false);
              }}
              className={classes.cursor_pointer} style={{ position: 'fixed', top: '8%', right: '10%', border: '1px solid #14967b', borderRadius: '50%', color: '#14967b', backgroundColor: '#fff', padding: '3px' }} />}
            {/* only shown when dialog open */}
            {openRolesList && invitedRoles.length > 0 &&
              <DialogTitle style={{ padding: '0', paddingTop: 30 }} id="alert-dialog-title">

                {isMobile && <div style={{ boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px', display: 'flex', padding: '0.5em' }}>
                  <ArrowBack onClick={() => {
                    setOpenInviteModal(false);
                    setChooseRole(false);
                  }} style={{ padding: '5px' }} />
                </div>}
                {/* {"Posted Roles"} */}
                <Grid container direction='column' style={{ padding: '16px 24px' }} justifyContent='center' alignItems='flex-start' >
                  <Grid item>
                    <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 'bold', color: '#55c6a9' }} >
                      Invited Roles
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' component='p' >
                      Take next steps
                    </Typography>
                  </Grid>
                </Grid>
              </DialogTitle>}
            <DialogContent style={{ padding: '8px 16px' }}>
              {/* dialog content on first render */}
              {dialogLoader && <Loader minHeight='42.26vh' maxWidth='40px' />}
              {openRolesList && !dialogLoader &&
                <Grid container direction='column' wrap='nowrap' justifyContent='flex-start' alignItems='flex-start' style={{ paddingBottom: '5em' }} >

                  {invitedRoles.sort(function (a, b) {
                    return new Date(b.invite_time) - new Date(a.invite_time);
                  })
                    .map((item, index) => (
                      <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap' key={index}
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: '6px', padding: '0.8em', marginBottom: '0.8em' }}
                      >
                        <Grid container alignItems='flex-start' justifyContent='center' direction='column' style={{ width: isMobile ? '100%' : '30vw', paddingRight: '1em' }} >
                          <Grid item>
                            <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 500, fontSize: '0.95em' }} >
                              {/* {`${invitedRoles[0].job_role} - ${invitedRoles[0].location} `} */}
                              {`${item.job_role} - ${item.location} `}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant='body2' component='p' >
                              {getinvitedDate(item.invite_time)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container alignItems='center' wrap='nowrap' justifyContent='flex-end' style={{ width: 'auto' }} >
                          {!hireStatus[index] && <Grid item>
                            <ToggleButton
                              value='check'
                              color='primary'
                              style={{ marginRight: '1em', padding: '0.2em 0.8em' }}
                              selected={shortlistStatus[index]}
                              // selected={shortlistedCurrent}
                              className={`${classes.ttc} ${classes.bigIconRect} ${classes.actionBtns}`}
                              classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                              onChange={() => handleShortlistClick(item, index)}
                            >
                              {/* {shortlistedCurrent ?
                              <StarRoundedIcon className={classes.saveIconRect} />
                              : <StarOutlineRoundedIcon className={classes.saveIconRect} />
                            // } */}
                              {shortlistStatus[index] ? <StarRoundedIcon className={classes.saveIconRect} /> : <StarOutlineRoundedIcon className={classes.saveIconRect} />}
                              {/* {shortlistedCurrent ? "Shortlisted" : "Shortlist"} */}
                              {shortlistStatus[index] ? "Shortlisted" : "Shortlist"}
                            </ToggleButton>
                          </Grid>}
                          <Grid item>
                            <ToggleButton
                              value='check'
                              color='primary'
                              style={{ marginRight: '1em', padding: '0.2em 0.8em' }}
                              selected={hireStatus[index]}
                              className={`${classes.ttc} ${classes.bigIconRect} ${classes.actionBtns}`}
                              classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                              onChange={() => handleHireClick(item, index)}
                            >
                              {hireStatus[index] ? <CheckCircleRoundedIcon className={classes.saveIconRect} /> : <CheckCircleOutlineRoundedIcon className={classes.saveIconRect} />}

                              {hireStatus[index] ? "Hired" : "Hire"}
                            </ToggleButton>
                          </Grid>

                        </Grid>
                      </Grid>
                    ))}

                  <Grid container alignItems='center' item className={invitedRoles.length === 0 ? classes.dialogRolesSubHeadingContainer : classes.my1}>
                    {isMobile && invitedRoles.length === 0 && <div >
                      <ArrowBack onClick={() => {
                        setOpenInviteModal(false);
                        setChooseRole(false);
                      }} style={{ padding: '5px' }} />
                    </div>}
                    <Typography className={invitedRoles.length === 0 ? classes.dialogRolesSubHeading : ''} variant='body2' component='p' >
                      Invite for other posted roles
                    </Typography>
                  </Grid>
                  {postedJobs.length > 0 && postedJobs.filter(job => job.job_location.length !== 0).map((item, index) => (
                    <Grid container alignItems='center' justifyContent='space-between' wrap='nowrap' key={index}
                      style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', borderRadius: '6px', padding: '0.8em', marginBottom: '0.8em' }}
                    >
                      <Grid item>
                        <Typography variant='body1' gutterBottom component='h6' style={{ fontWeight: 500, fontSize: '0.95em' }} >
                          {`${item.job_title} ${'- ' +
                            item.job_location.map((loc, i) => {
                              // loc.city
                              if (i < item.job_location.length - 1) {
                                return " " + loc.city
                              } else {
                                return loc.city
                              }
                            })
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='outlined'
                          color='primary'
                          // size='small'
                          style={{ marginRight: '1em', padding: '0.2em 0.8em' }}
                          className={classes.ttc}
                          startIcon={<MailOutlineIcon />}
                          onClick={() => {
                            let invitedLocArr = [];
                            item.job_location.forEach((element, index) => {
                              axios.post(`${BASE_URL}/jobposting/employer_invite_jobseeker`, {
                                company_id: `${localStorage.getItem('company_id')}`,
                                invite_status: true,
                                job_role: item.job_title,
                                jobseeker_id: jobseeker_id,
                                location: element.city
                              }).then(res => {
                                setSelectedJobseekerRole(item.job_title);
                                invitedLocArr.push(element.city);
                                if (index === item.job_location.length - 1) {
                                  setChooseRole(false);
                                  setOpenRolesList(false);
                                  setInviteConfirmPage(true);
                                  setSelectedJobseekerLocation(invitedLocArr.toString());
                                  setStartTimer(prev => !prev);
                                  setSixSecondsTimer(6)
                                }
                              }).catch(err => {
                                handleSnackBar("You have already invited this jobseeker for the selected role")
                              })
                            })
                          }}
                        >
                          Invite
                        </Button>
                      </Grid>
                    </Grid>
                  ))
                  }
                  <Grid item style={{ position: 'absolute', bottom: 0, width: '95%', padding: '1em 0', backgroundColor: '#fff', marginLeft: '-3px' }}>
                    <Button className={classes.ttn} style={{ marginBottom: 20 }} variant='contained' onClick={() => {
                      AnalyticsHandler.track("CompanyPage_followers_inviteFor_newRoleBtn")
                      setOpenRolesList(false);
                      setChooseRole(true);
                      setLocationValue('')
                    }} color="primary">
                      Invite for a new role
                    </Button>
                  </Grid>
                </Grid>}
              {/* dialog content on first render ends here */}

              {/* dialog content after invite for new role btn click */}
              {chooseRole &&
                <Grid container direction='column' style={{ padding: '1.5em 0.5em' }}>

                  <Grid item container justifyContent='flex-end' style={{ paddingTop: '1em' }} >
                    {isMobile &&
                      <CloseIcon onClick={() => {
                        setOpenInviteModal(false);
                        setChooseRole(false);
                      }} />
                    }
                  </Grid>
                  <Grid item >
                    <Typography variant='body1' gutterBottom component='h6' style={{ color: '#14967b' }}>
                      Role
                    </Typography>
                  </Grid>
                  <Grid item className={classes.my0} >
                    <Autocomplete
                      options={roles}
                      name="role"
                      // value={data.sector_name || ""}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title
                      }
                      }
                      style={{ flex: 0.75, display: 'inline' }}

                      onChange={(event, newValue) => {
                        event.preventDefault();
                        if (typeof newValue === 'string') {
                          setValues({ ...values, role: newValue });
                        } else if (newValue) {
                          setValues({ ...values, role: newValue.title });
                        } else {
                          setValues({ ...values, role: "" });
                        }
                      }}

                      // classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                      renderInput={(params) => <TextField placeholder='What position are you hiring for?' {...params} variant='outlined' className={classes.followersInput} />
                      }
                      // getOptionSelected={(option, value) => option.sector_name === value}

                      renderOption={(option) => {
                        if (option) {
                          return (
                            <Grid container alignItems="flex-start" direction="column">
                              <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.title} </Typography>
                            </Grid>
                          )
                        }
                      }}
                    />
                  </Grid>
                  <Grid item className={classes.my0} >
                    <Typography variant='body1' gutterBottom component='h6' style={{ color: '#14967b' }}>
                      Location
                    </Typography>
                  </Grid>
                  <Grid item >
                    <Location
                      //profileObj={profileObj}
                      locationValue={locationValue}
                      setLocationValue={setLocationValue}
                      latLong={latLong}
                      setLatLong={setLatLong}
                      placeholder='Start typing e.g. Hazratganj, Lucknow'
                      fontSizeClass={true}
                      anywhere={true}
                    //   isErrorAboutMe={isErrorAboutMe}
                    //   setIsLocationAutocompletePageOpen={setIsLocationAutocompletePageOpen}
                    />
                  </Grid>
                  <Grid item className={classes.mt2} >
                    <Button disabled={disableConfirmInviteBtn} className={classes.ttn} variant='contained' color="primary"
                      style={{ color: '#fff', position: 'absolute', bottom: '30%', zIndex: 'inherit' }}
                      onClick={() => {
                        AnalyticsHandler.track("CompanyPage_followers_inviteFor_newRole_confirmBtn")
                        setSelectedJobseekerRole(values.role);
                        setSelectedJobseekerLocation(values.location);
                        inviteForNewRole();

                      }}
                    >
                      Confirm Invite
                    </Button>
                  </Grid>
                </Grid>
              }
              {/* dialog content after invite btn click ends here */}

              {/* dialog content after confirm invite for custom role & location */}

              {inviteConfirmPage && <Grid container direction='column' justifyContent='center' style={{ padding: '1.5em 0.5em' }}>
                <Grid container style={{ minHeight: '30vh' }} alignItems='center' justifyContent='center' direction='column'>
                  <Grid item>
                    <span><CheckCircleOutlineIcon color='secondary' fontSize='large' /></span>
                  </Grid>
                  <Grid item >
                    <Typography className={`${classes.my1} ${classes.color_secondary}`} variant='h6'> Invite Sent!</Typography>
                  </Grid>
                  <Grid className={classes.px1} item>
                    <Typography className={classes.textAlignCenter} variant='body1' component='p'>
                      You invite has been successfully to <span className={`${classes.bold} ${classes.ttc}`}>{selectedJobseekerName}</span> for <span className={classes.bold}>{selectedJobseekerRole}</span> role at <span className={classes.bold}>{selectedJobseekerLocation.split(',')[0]}</span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems='center' justifyContent='center' direction='column'>
                  <Grid className={classes.my1} item>
                    <Typography className={classes.textAlignCenter} variant='body2' component='p' >
                      Redirecting you to the Discovery page where you can find and Invite jobseekers for {selectedJobseekerRole} role in {selectedJobseekerLocation} in <span style={{ color: 'red' }}>0{sixSecondsTimer}</span> seconds.
                    </Typography>
                  </Grid>
                  <Grid className={`${classes.my0}`} item>
                    <Button
                      variant='outlined'
                      color='primary'
                      style={{ padding: '0.2em 0.8em' }}
                      className={classes.ttn}
                      onClick={() => {
                        // stopRedirectTimeout();
                        AnalyticsHandler.track("CompanyPage_followers_stayOnThisPageBtn")
                        setChooseRole(false);
                        setOpenInviteModal(false);
                        setInviteConfirmPage(false);
                        dorefresh(prev => !prev);
                      }}
                    >
                      Stay on this page
                    </Button>
                  </Grid>
                </Grid>
              </Grid>}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  )
}
export default Followers