import React from 'react';
import Button from '@material-ui/core/Button';


const NoResultsList= (props) => {

return(
<div style = {{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <div>
      <br />
      <br />
      <br />      
      No More Search Results have been found around your location. 
      <br/><br/>
      </div>
      <Button variant="outlined" onClick = { () => { 
      //locationRef.current.click();
    //   var x = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
    //   x[0].click();
     // props.locationRef.current.click();

       var x = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
       x[0].click();

       var t = setTimeout(function(){props.searchRef.current.click(); clearTimeout(t); }, 500);

      }}>Search Anywhere</Button>
      <br />
      <br />
      < br/>

      </div>
)
}

export default NoResultsList;