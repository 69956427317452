import React from 'react';
import 'date-fns';
import { Typography, Grid, InputBase, FormControl, FormGroup, FormControlLabel, Checkbox, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';

function Schedule(props) {
    const { classes, values, setValues } = props;
    const [disabledDiv, setDisabledDiv] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [selectedDate1, setSelectedDate1] = React.useState(new Date('2014-08-18T10:00:00'));
    const [selectedDate2, setSelectedDate2] = React.useState(new Date('2014-08-18T18:00:00'));
    const [initialTime, setInitialTime] = React.useState('10:00AM');
    const [endTime, setEndTime] = React.useState('06:00PM');

    const disableFormDiv = (e) => {
        setDisabledDiv(!disabledDiv);
        setIsChecked(!isChecked);
        setValues({ ...values, isFlexibleDays: true, isFlexibleTime: true, working_time: '', working_days: [] });
        if (!e.target.checked) {
            setValues({ ...values, isFlexibleDays: false, isFlexibleTime: false, working_time: initialTime + ' ' + 'to' + ' ' + endTime, working_days: ["MON", "TUE", "WED", "THU", "FRI"] });
        }
    }

    const handleTimings = (date, setDate, setVal) => {
        setDate(date);
        if (date !== null) {
            let hrs = date.getHours().toString();
            let min = date.getMinutes().toString();
            let ampm = hrs >= 12 ? 'PM' : 'AM';

            if (min < 10) {
                min = '0' + min;
            }
            if (hrs < 10) {
                hrs = '0' + hrs;
            }
            if (hrs > 12) {
                hrs = hrs - 12;
                if (hrs < 10) {
                    hrs = '0' + hrs;
                }
            }
            let time = hrs + ':' + min + ampm;
            setVal(time);
        }
    };

    const handleDays = (e) => {
        if (e.target.checked) {
            setValues({ ...values, working_days: values.working_days !== null && [...values.working_days, e.target.id] })
        } else {
            setValues({ ...values, working_days: values.working_days.filter(item => item !== e.target.id) })
        }
    }

    React.useEffect(() => {
        setValues({ ...values, working_time: initialTime + ' ' + 'to' + ' ' + endTime });
    }, [initialTime, endTime]);

    const dateChange = (index, setTime, setDate) => {
        let time = values.working_time.split(' ');
        let d = new Date();
        d.setHours(time[index].slice(0, 2));
        time[index].slice(5, 7) === 'PM' ? d.setHours(d.getHours() + 12) : d.setHours(d.getHours());
        d.setMinutes(time[index].slice(3, 5));
        d.setSeconds('00');
        setTime(time[index]);
        setDate(d)
    }

    React.useEffect(() => {
        if (values.working_days !== null && values.working_days.length === 0 && values.working_time === null) {
            setValues({ ...values, working_time: initialTime + ' ' + 'to' + ' ' + endTime, working_days: ["MON", "TUE", "WED", "THU", "FRI"] });
        }
        if (values.working_time !== null && values.working_time !== '' && values.working_time) {
            dateChange(0, setInitialTime, setSelectedDate1);
            dateChange(2, setEndTime, setSelectedDate2);
        } else if (values.working_time === '') {
            setIsChecked(true);
            setDisabledDiv(true);
            setValues({ ...values, working_time: '', working_days: [], isFlexibleDays: true, isFlexibleTime: true });
        } else {
            setIsChecked(false);
            setDisabledDiv(false);
        }
    }, []);

    return <Grid item xs={12} className={classes.my3} >
        <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Working Hours & Days</Typography>
        <div className={classes.salaryDetails}>
            <FormControlLabel
                control={<Checkbox onChange={e => disableFormDiv(e)} checked={isChecked} />}
                label='Flexible'
            />
            <div className={disabledDiv ? `${classes.disableDiv} ${classes.flexboxCol}` : classes.flexboxCol}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl className={`${classes.formControl} ${classes.my2}`}>
                        <span style={{ marginRight: props.isMobile ? '5px' : '2em', fontWeight: 'bold', fontSize: props.isMobile ? '0.8em' : '' }}>Hours</span>
                        <span style={{ marginRight: props.isMobile ? '5px' : '1em', fontSize: props.isMobile ? '0.8em' : '', fontWeight: 500}}>From</span>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            value={selectedDate1}
                            onChange={(date) => handleTimings(date, setSelectedDate1, setInitialTime)}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            className={classes.timePicker}
                        />
                        <span style={{ margin: '0 1em', fontSize: props.isMobile ? '0.8em' : '', fontWeight: 500 }}>to</span>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            value={selectedDate2}
                            onChange={(date) => handleTimings(date, setSelectedDate2, setEndTime)}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            className={classes.timePicker}
                        />

                    </FormControl>
                </MuiPickersUtilsProvider>
                <div className={`${classes.flexbox} ${classes.my1}`} >

                    <span style={{ marginRight: props.isMobile ? '1em' : '2em', fontWeight: 'bold', fontSize: props.isMobile ? '0.8em' : '' }}>Days</span>

                    <FormControl className={`${classes.flexbox} ${classes.timeFormControl}`} >
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='MON' checked={values.working_days && values.working_days !== null && values.working_days.includes("MON")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='MON' >M</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='TUE' checked={values.working_days && values.working_days !== null && values.working_days.includes("TUE")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='TUE' >T</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='WED' checked={values.working_days && values.working_days !== null && values.working_days.includes("WED")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='WED' >W</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='THU' checked={values.working_days && values.working_days !== null && values.working_days.includes("THU")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='THU' >T</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='FRI' checked={values.working_days && values.working_days !== null && values.working_days.includes("FRI")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='FRI' >F</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='SAT' checked={values.working_days && values.working_days !== null && values.working_days.includes("SAT")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='SAT' >S</label>
                        </FormGroup>
                        <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                            <input type="checkbox" id='SUN' checked={values.working_days && values.working_days !== null && values.working_days.includes("SUN")} onChange={(e, index) => handleDays(e, index)} />
                            <label htmlFor='SUN' >S</label>
                        </FormGroup>
                    </FormControl>
                </div>


            </div>
        </div>
    </Grid>;
}

export default Schedule;
