import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogoLoader from '../../assets/logo-loader.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    // width: '100%',
    height: 'calc(100vh - 240px)',
    //width: 'calc(100vw - 600px)',
  },
  loaderShuffle: {
    //background: '#f0fffb',
    background: '#fff',
    borderRadius: '10px',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    display: 'none',
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
    '&.animate': {
      //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
      display: 'flex',
      opacity: 1,
    }
  },
  loaderShuffleAnimating: {
    //background: '#f0fffb',
    background: '#fff',
    borderRadius: '10px',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    display: 'flex',
    opacity: 1,
    // transition: 'opacity 0.25s ease-in-out',
  }
}));

const Loader_Shuffle = (props) => {
  const classes = useStyles();
  const loaderShuffleRef = React.useRef(null);
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("");
  
  React.useEffect(() => { 
    props.setLoaderRef(loaderShuffleRef);
  }, [loaderShuffleRef])

  React.useEffect(() => { 
    if(props.isCardLoaderOn){
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [props.isCardLoaderOn])

  React.useEffect(() => { 
    setLoaderMessage(props.loaderMessageOnPage2)
  }, [props.loaderMessageOnPage2])

  return (
    <div id="cardWrapper1" className={classes.root}>
      <Grid item xs={12} className={isAnimating ? classes.loaderShuffleAnimating : classes.loaderShuffle } container direction="column" justify="center" alignItems="center" ref={loaderShuffleRef}>
        {/* <CircularProgress size={50} style={{ color: '#5bc2a8' }} /> */}
        <img alt="meet animated logo loader" style={{ width: 50}} src={LogoLoader} />
        <Typography className="loaderText" variant="body2" style={{ marginTop: 10 }}>
          {loaderMessage}
        </Typography>
      </Grid>
    </div>
  )
}

export default Loader_Shuffle;