import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Chip from '@material-ui/core/Chip';
import myInitObject from '../discovery/location'
import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import ApiHandler from '../../ApiHandler';
import FeedbackAlert from '../../components/FeedbackAlert';
import EmailIcon from '@material-ui/icons/Email';
// import global_isSignedIn from '../discovery/global_isSignedIn';
import { useHistory } from 'react-router-dom';
import AnalyticsHandler from '../../AnalyticsHandler';
import { checkDateFormat } from '../../Globals';
import { Popover } from '@material-ui/core';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import StarRateIcon from '@material-ui/icons/StarRate';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#555',
    color: '#fff',
    //boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 375,
    marginBottom: '-18px',
  },
  paper: {
    boxSizing: 'border-box',
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 830,
    maxHeight: 375,
    width: 'inherit',
    height: 375,
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .imgPholder': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  leftSection: {
    height: '100%',
    padding: 0,
    position: 'relative',
    background: '#D5F5ED',
    overflow: 'hidden',
    maxWidth: '40%',
    flexBasis: '40%',
  },
  namePlate: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    marginRight: 7,
  },
  RightSection: {
    //height: '100%',
    position: 'relative',
    maxHeight: '100%',
    paddingBottom: 0,
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    marginTop: '1em'
  },
  image: {
    width: '100%',
    height: 450,
    overflow: 'hidden'
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarWrapper: {
    width: '100%',
    height: 375,
    borderRadius: 0,
    //paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    margin: 0,
    '&>img': {
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: "none",
    },
  },
  verified: {
    color: '#289e80',
    marginTop: '-20px',
  },
  lastActive: {
    color: '#999',
    fontSize: 12,
  },
  inlineSubtext: {
    color: '#232323',
    fontSize: '13px',
    fontWeight: 300,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  buttonSec: {
    flex: 1,
    marginRight: theme.spacing(1),
    textTransform: 'none',
    '& > *': {
      color: '#999'
    },
  },
  button: {
    flex: 1,
    textTransform: 'none',
  },
  expTimelineWrapper: {
    width: 600,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  eduTimelineWrapper: {
    width: 600,
    //marginLeft: '-26px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  timelineArrow: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#f6fffde3',
    color: '#555',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    fontSize: 24,
    '&:hover': {
      background: 'white',
      color: '#5bc2a8',
      boxShadow: '0 0 8px rgba(0,0,0,0.15)',
    },
  },
  timelineArrow_L: {
    left: 10,
  },
  timelineArrow_R: {
    right: 10,
  },
  timelineArrow_Edu_L: {
    left: 10,
    top: 'unset',
    bottom: 40,
  },
  timelineArrow_Edu_R: {
    right: 10,
    top: 'unset',
    bottom: 40,
  },
  timelineList: {
    display: 'flex',
    marginTop: 10,
    marginLeft: '-12px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      fontSize: 12,
      position: 'relative',
      //width: 400,
      width: 300,
      '& .breakChar': {
        display: 'none',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 150,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        //display: 'none',
      },
      '&:last-child > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '72.5px',
          height: '0.5px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        transform: 'translateX(50%)',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 10%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
    '& > div:nth-child(1)': {
      '&.withBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.noBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.withBreakSameMon': {
        '& > div:nth-child(1) > div:nth-child(2) > span::after': {
          display: 'none',
        },
      }
    },
    '& > div.withBreak': {
      width: '14vw!important',
      '& > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'block',
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
      '& .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreak + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
    },
    '& > div.withBreakSameMon': {
      width: '150px!important',
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
      '& > div:nth-child(1) > div:nth-child(2) > span::after': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      '&:not(:last-child) .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreakSameMon + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
    },
    '& > div.noBreakSameMon': {
      width: 150,
    },
    '& > div.noBreak': {
      width: 150,
    },
  },

  timelineListEdu: {
    display: 'flex',
    listStyle: 'none',
    marginTop: 10,
    marginLeft: '-37.3px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'flex-start',
      // fontSize: 12,
      width: '12vw',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
        textTransform: 'capitalize',
        width: 200,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '98px',
          height: '1px',
          maxWidth: '98px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 19%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
  },

  timelineDuration: {
    fontSize: 10,
    color: '#797979',
    fontSize: 12,
    fontWeight: 300,
  },
  duration: {
    marginTop: 5,
    fontWeight: 400,
  },
  chip: {
    fontSize: '12px',
    fontWeight: 'bold',
    // fontWeight: 500,
    // width: '120px',
    padding: '2px 8px',
    margin: '6px 0 2px 0',
    backgroundColor: '#f5f5f5',
    color: "#797979",
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chiproot: {
    borderRadius: 4,
    height: 24,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  bigIconRect: {
    position: 'relative',
    margin: '0 20px 0 0',
    zIndex: 2,
    height: 35,
    width: 100,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  saveIcon: {
    height: 30,
    width: 30,
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  infoIcon: {
    height: 20,
    width: 20,
    color: '#555',
  },
  bigIcon: {
    height: 46,
    width: 46,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#fab116',
    border: '1px solid #fab116',
    '&:hover': {
      backgroundColor: '#fab116',
      color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopover: {
    padding: theme.spacing(1),
    width: 200,
  },
  dialogBackdropRoot: {
    backgroundColor: "transparent"
  }
}));

var employer_lat_lng = {
  lat: myInitObject.lat,
  lng: myInitObject.long
}

var rad = function (x) {
  return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(employer_lat_lng.lat - p1.lat);
  var dLong = rad(employer_lat_lng.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

export default function ComplexGrid(props) {
  const classes = useStyles();
  const history = useHistory();

  var last_active = moment(new Date()).diff(moment(new Date(props.hit.last_seen_at * 1000)), 'days');

  let expUnitYears, expUnitMons, total_years, total_months;
  // fresher = false;

  var full_name = props.hit.user_first_name ? props.hit.user_first_name + " " + props.hit.user_last_name : "";
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";
  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }

  var array_name = props.hit.user_first_name ? (props.hit.user_last_name ? (props.hit.user_first_name + " " + props.hit.user_last_name).split(" ") : (props.hit.user_first_name).split(" ")) : "";
  // var array_name = props.hit.jobseeker_name ? props.hit.jobseeker_name.split(" ") : "";
  var k = 0, name_sentence_case = "";
  for (; k < array_name.length; k++) {
    if (array_name[k])
      name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
  }

  const handleProfileImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;
    //console.log(imageWidth + " " + imageHeight)
    if (imageWidth / imageHeight <= 190.9 / 302) {
      //event.target.style.height = (302 * (190.9 / imageWidth)) + "px"
      event.target.style.height = 'auto';
      event.target.style.width = '100%';
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
  }

  let scrollableWidth = 0;
  const scrollTimeline = (e) => {
    let timeline = e.target.nextSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth += 400;
    if (scrollableWidth <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth -= 400;

    if (scrollableWidth >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  let scrollableWidthEdu = 0;
  const scrollEduTimelineL = (e) => {
    let timeline = e.target.nextSibling;
    scrollableWidthEdu += 400;
    if (scrollableWidthEdu <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' });
      eduArrowRRef.current.style.display = "flex";
      if (scrollableWidthEdu + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollEduTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidthEdu -= 400;
    //console.log("TimelineWidth="+TimelineWidth)
    //console.log("scrollableWidthEdu="+scrollableWidthEdu)
    if (scrollableWidthEdu >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' })
      eduArrowLRef.current.style.display = "flex";
      if (scrollableWidthEdu - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  //Shortlist and Hire button functionalities start
  const [invited, setInvited] = React.useState(props.hit.invite_jobseekers.length > 0 && props.hit.invite_jobseekers[0].invite_status);
  const [hireSelected, setHireSelected] = React.useState(props.hit.hired_status);
  const [invitedData, setInvitedData] = React.useState([]);
  const [isShortlistBtnVisible, setIsShortlistBtnVisible] = React.useState(!props.hit.hired_status);
  const [gotResponse, setGotResponse] = React.useState(true);
  const [gotHireResponse, setGotHireResponse] = React.useState(true);
  const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

  let cid = localStorage.getItem('company_id');
  const [company_id, setCompanyId] = useState(cid);

  //Shortlist button functionality starts

  //Hire button functionality starts
  const handleHireClick = () => {
    if (!hireSelected) {
      handleHireClickOpen();
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleHireClickOpen = () => {
    setOpen(true);
  };

  const handleHireYesClose = () => {
    let payload = {
      company_id,
      invite_id: props.hit.invite_id,
      hired_status: !hireSelected,
    }
    if (props.signInStatus) {
      setGotHireResponse(false);

      ApiHandler.sendHired(payload, response => {
        if (response.status == 200) {
          setOpen(false);
          if (!sessionStorage.getItem('isHireToastDisplayed')) {
            handleSnackBar(`Congratulations for hiring this jobseeker. We will inform them about their selection.`);
            sessionStorage.setItem('isHireToastDisplayed', true)
          }
          setHireSelected(true);
          setIsShortlistBtnVisible(false);
          //setGotHireResponse(true);
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              props.removeLocalStorageOnSessionExpiry();
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
          setGotHireResponse(true);
        }
      })
    }
  };

  const handleHireCancelClose = () => {
    setOpen(false);
  };

  // Hire button functionality ends

  // uninvite button functionality starts

  const [jobseeker_id, setJobseekerId] = useState(props.hit.jobseeker_id);
  const [job_role, setJobRole] = useState(props.hit.job_role);
  const [location, setLocation] = useState(props.hit.job_location);

  const [isRemoved, setIsRemoved] = useState(false);

  const [openUninvite, setOpenUninvite] = React.useState(false);

  const handleUninviteClickOpen = () => {
    setOpenUninvite(true);
  };

  const handleUninviteCancelClose = () => {
    setOpenUninvite(false);
  };

  // const handleUninviteYesClose = () => {
  //   //AnalyticsHandler.track("discoveryResults_list_Uninvite_btnClick");
  //   if (props.signInStatus) {
  //     let payload = {
  //       company_id,
  //       jobseeker_id,
  //       job_role,
  //       location,
  //       invite_status: false,
  //     }
  //     setGotUninviteResponse(false);
  //     //alert(props.signInStatus)
  //     ApiHandler.sendInvite(payload, response => {
  //       if (response.status == 200) {
  //         setOpenUninvite(false);
  //         if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
  //           setSelected(false);
  //         } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
  //           setSelected(false);
  //         }
  //         setGotUninviteResponse(true);
  //         setIsRemoved(true);
  //         props.setRefreshAfterRemoval(prev => !prev);
  //       }
  //       else {
  //         var ErrorMessage = 'Oops! Something went wrong. Please try again.';
  //         if (response.response) {
  //           let statusCode = response.response.status;
  //           if (statusCode === 500) {
  //             ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
  //           } else if (statusCode === 404) {
  //             ErrorMessage = 'Oops! Something went wrong. Please try again.';
  //           } else if (statusCode === 400) {
  //             if (response.response.data.message === "Session Expire") {
  //               props.removeLocalStorageOnSessionExpiry();
  //             }
  //           } else {
  //             ErrorMessage = 'Oops! Something went wrong. Please try again.';
  //           }
  //         } else if (response.message === 'Network Error') {
  //           ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
  //         } else {
  //           ErrorMessage = 'Oops! Something went wrong. Please try again.';
  //         }
  //         handleAlert(ErrorMessage, 'error');
  //       }
  //     })
  //   } else if (props.signInStatus === false) {
  //     props.handleDrawer();
  //   }
  // }
  // Invite button functionality ends

  const timelineRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  const eduTimelineRef = React.useRef(null);
  const eduArrowLRef = React.useRef(null);
  const eduArrowRRef = React.useRef(null);

  let userEduSorted, _userEdu;
  const [userEduResponse, setUserEduResponse] = React.useState(null);
  const [userEdu, setUserEdu] = React.useState(null);

  let userExpSorted, _userExp, _expMonthsAndExp, _hasOverlapExps;
  const expMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const [userExpResponse, setUserExpResponse] = React.useState(null);
  const [userExp, setUserExp] = React.useState(null);
  const [expMonthsAndExp, setExpMonthsAndExp] = React.useState(null);
  const [hasOverlapExps, setHasOverlapExps] = React.useState(false);
  const [fresher, setFresher] = React.useState(null);
  const [expLabel, setExpLabel] = React.useState(null);

  React.useEffect(() => {
    props.hit.invite_jobseekers.length > 0 && setInvitedData(props.hit.invite_jobseekers);
  }, [props.hit]);

  React.useEffect(() => {
    ApiHandler.getInvitedProfileExp({ jobseeker_id }, response => {
      if (response.status === 200) {
        // console.log(response.data.user_experiences);
        let _expData = response.data.user_experiences;
        let _expMonsData = response.data.total_months_exp;
        setUserExpResponse(_expData);

        expUnitYears = (_expMonsData / 12).toFixed(0) == 1 ? " year " : " years ";
        expUnitMons = _expMonsData % 12 == 1 ? " month " : " months  ";

        total_years = Math.trunc(_expMonsData / 12);
        total_months = _expMonsData % 12;
        let _fresher;

        if (
          (total_years == 0 && total_months == 0)
          || _expData && _expData.length == 0
          || _expData && _expData == [{}]
          || !_expMonsData) {
          setFresher(true);
          _fresher = true;
        } else {
          setFresher(false);
          _fresher = false;
        }

        let _expLabel = _fresher ? 'Fresher' : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + expUnitYears) : (total_months + expUnitMons)) : (total_years + expUnitYears + total_months + expUnitMons));
        setExpLabel(_expLabel);
      }
    });

    ApiHandler.getInvitedProfileQuals({ jobseeker_id }, response => {
      if (response.status === 200) {
        //console.log(response.data);
        setUserEduResponse(response.data)
      }
    })
  }, []);

  React.useEffect(() => {
    if (!userExpResponse) return;

    userExpSorted = userExpResponse && userExpResponse.length != 0 ? userExpResponse.sort((a, b) => {
      if (a == {} || b == {})
        return 0;
      if (a.company_starting_date === b.company_starting_date)
        return 0;
      else {
        return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
      }
    }) : null;

    _userExp = userExpSorted
      && userExpSorted.filter((item, i, array) =>
        array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
      )

    _expMonthsAndExp = _userExp && _userExp.map((item, i) => {
      if (item != {}) {
        if (item.company_ending_date && item.company_starting_date) {
          var startDate = checkDateFormat(item.company_starting_date)
          var endDate = checkDateFormat(item.company_ending_date)
          var startDateNext = _userExp[i + 1] ? new Date(_userExp[i + 1].company_starting_date) : null;
          var a = moment(endDate);
          var b = moment(startDate);
          var c = moment(startDateNext);
          var duration = a.diff(b, 'months');
          var dd = c.diff(a, 'days');
          var sameDaysDiff = a.diff(b, 'days')
          return {
            months: duration,
            sameDaysDiff: sameDaysDiff,
            daysDiff: dd,
            expStartMon: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(0, 3) : startDate.getMonth(),
            expEndMon: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(0, 3) : endDate.getMonth(),
            expStartYear: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(4, 8) : startDate.getFullYear(),
            expEndYear: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(4, 8) : endDate.getFullYear(),
            user_post: item.user_post,
          }
        }
        else {
          return {
            months: null,
            sameDaysDiff: null,
            daysDiff: null,
            expEndMon: null,
            expStartMon: null,
            expStartYear: null,
            expEndYear: null,
            user_post: item.user_post,
          };
        }
      }
      else {
        return {
          months: null,
          sameDaysDiff: null,
          daysDiff: null,
          expEndMon: null,
          expStartMon: null,
          expStartYear: null,
          expEndYear: null,
          user_post: null
        }
      }
    })

    _hasOverlapExps = _userExp && _userExp.length > 0 && _userExp.map((item, i) => {
      if (_expMonthsAndExp[i] && _expMonthsAndExp[i].daysDiff < 0) {
        return { overlap: 'true' }
      } else {
        return { overlap: 'false' }
      }
    });

    setUserExp(_userExp);
    setExpMonthsAndExp(_expMonthsAndExp);
    setHasOverlapExps(_hasOverlapExps);
    //console.log(_userExp, _expMonthsAndExp, _hasOverlapExps)

  }, [userExpResponse])

  React.useEffect(() => {
    if (timelineRef.current && expArrowRRef.current && timelineRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }
  }, [userExp]);

  React.useEffect(() => {
    if (!userEduResponse) return;

    userEduSorted = userEduResponse && userEduResponse.length !== 0 ? userEduResponse.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)) : null;

    _userEdu = userEduSorted ? userEduSorted.filter((item, i, array) =>
      array.findIndex(t => t.user_passing_year && item.user_passing_year && t.course_name && item.course_name && t.user_passing_year == item.user_passing_year && t.course_name == item.course_name) == i
    ) : [];

    setUserEdu(_userEdu);
  }, [userEduResponse])

  React.useEffect(() => {
    if (eduTimelineRef.current && eduArrowRRef.current && eduTimelineRef.current.scrollWidth > 638) {
      //console.log(eduTimelineRef.current.scrollWidth)
      eduArrowRRef.current.style.display = "flex";
    }
  }, [userEdu]);

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  const getinvitedDate = () => {
    let d = props.hit.invite_time;
    d = new Date(d);
    d = moment(d).format("Do MMM");
    return d
  }

  const invitedDate = getinvitedDate();

  const length = invitedData.length;
  var invitedRolesList = [];
  if (invitedData.length > 2) {
    invitedRolesList.push(invitedData[0].job_role);
    invitedRolesList.push(invitedData[1].job_role);
  } else {
    invitedData.forEach(item => {
      invitedRolesList.push(item.job_role);
    })
  }

  // React.useEffect(() => {
  //   props.hit && props.hit.invite_jobseekers.length > 0 &&
  //   props.setSelected(props.hit.invite_jobseekers[props.index].shortlist_status)
  // },[props.openInviteModal])

  // skills section
  const [anchorSkillsEl, setAnchorSkillsEl] = React.useState(null);

  const viewMoreSkills = (event) => {
    setAnchorSkillsEl(event.currentTarget);
  };

  const closeSkillsPopover = () => {
    setAnchorSkillsEl(null);
  };

  const openSkillsPopover = Boolean(anchorSkillsEl);
  const skillsPopoverId = openSkillsPopover ? 'skills-popover' : undefined;

  function getSkills(skills) {
    return skills.map((item, index) =>
      <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.2em 0', flexDirection: 'column' }}>
        <Grid container item alignItems='center' justifyContent='center' wrap='nowrap' style={{ marginRight: '10px' }}>
          <Typography style={{
            textTransform: 'capitalize'
            // width: `${item.skill_name.length > 23 ? '140px' : '120px'}`
          }} className={classes.chip}>
            <span>
              {item.skill_name}
            </span>
            <span style={{ fontSize: '0.8rem', display: 'flex', alignItems: 'center', marginLeft: '3px' }}>
              <StarRateIcon style={{ fontSize: '0.8rem' }} />
              {item.rating}
            </span>
          </Typography>
        </Grid>
      </div>
    )
  }

  return (
    <div className="listItemWrapper" style={{ marginBottom: 30 }}>
      {isRemoved ?
        <Paper className={classes.paper} elevation={3} style={{ height: 150, background: 'rgba(255, 255, 255, 0.5)', position: 'relative' }}>
          <Grid item xs={12} container direction="row" justifyContent="center"
            alignItems="center" style={{ height: '100%' }}>
            <Typography variant="h6" className={classes.namePlate} >
              <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{name_sentence_case}</span> has been removed from the list of jobseekers you invited for the <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.job_role ? props.hit.job_role.toLowerCase() : ""}</span> role.
            </Typography>
          </Grid>
        </Paper>
        :
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={3}>
            {/* <Grid item container
              justify="center"
              alignItems="center"
              style={{ height: 30, background: '#ececec' }}
            > <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                Invited for <span style={{ color: '#14967B' }}>{props.hit.job_role}</span> on <span style={{ color: '#14967B' }}>{invitedDate}</span>
              </Typography></Grid> */}
            <Grid container direction="row"
              justifyContent="center"
              alignItems="flex-start" wrap='nowrap' style={{ height: '302', overflow: 'hidden' }}>
              <Grid item className={classes.leftSection} id="leftSectionId">
                <Grid item xs container direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs container justifyContent="center"
                    alignItems="center">
                    <Grid item xs container direction="column" justifyContent="center"
                      alignItems="center">

                      <div className={classes.avatarWrapper}>
                        {props.hit.user_image_url ?
                          <img className={classes.img} alt="candidates avatar image" src={props.hit.user_image_url} onLoad={handleProfileImage} style={{ opacity: 0 }} />
                          :
                          <div className="imgPholder">
                            <p style={{
                              fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%',
                            }}> {initials} </p>
                          </div>}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9} sm={8} container className={classes.RightSection}>
                <Grid item xs={12} container direction="column" justifyContent="space-between"
                  alignItems="flex-start">
                  <Grid item xs container direction="row" justifyContent="flex-start" alignItems="baseline" style={{ marginBottom: 10 }}>
                    <Typography variant="h6" className={classes.namePlate} >
                      {name_sentence_case}
                    </Typography>
                    <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                      <span style={{ fontSize: 18, marginRight: 4, color: '#ccc', fontWeight: 100 }}>|</span>
                      <span style={{ position: 'relative', marginRight: 8 }}>
                        {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                      <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                        {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                      </span>

                      {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                    </span>
                  </Grid>
                  {/* <Grid item>
                  <Typography variant="body2" className={classes.lastActive}>
                    Last active:  {last_active <= 30 ? (last_active == 0 ? "Today" : (last_active == 1 ? last_active + " day" : last_active + " days")) : " > 1 month "}
                  </Typography>
                </Grid> */}
                  {props.hit.invite_jobseekers.length !== 0 ?
                    <Grid item xs wrap='nowrap' container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ paddingTop: 2 }}
                    >
                      <CheckIcon className="primaryText" style={{ fontSize: 14, paddingRight: 2, paddingTop: 2 }} />
                      <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                        Invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>
                          {invitedRolesList.map((item, i) => {
                            if (i < invitedRolesList.length - 1) {
                              return item + ", "
                            } else {
                              return item
                            }
                          })}
                          {length > 2 ? " " + "+" + (length - 2) + " " + (length === 3 ? "other" : "others") : ""}
                        </span>
                      </Typography></Grid> : null
                  }
                </Grid>
                <Grid item xs container direction="column"
                  justifyContent="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justifyContent="center"
                    alignItems="flex-start" >
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginTop: 10, marginBottom: 10 }} >
                      <WorkIcon fontSize="small" className="primaryText" />
                      <Typography variant="subtitle1" style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Experience</Typography>
                      {fresher ?
                        <Chip size="small" label={expLabel} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10, minWidth: 63 }} /> : null}
                    </Grid>

                    <div className={classes.expTimelineWrapper}>
                      <div ref={expArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_L}`} onClick={scrollTimeline} style={{ display: 'none' }}>&#8249;</div>
                      {userExp && userExp.length === 1 && expMonthsAndExp[0].sameDaysDiff <= 31 && expMonthsAndExp[0].expStartMon === expMonthsAndExp[0].expEndMon ?
                        <div style={{ marginLeft: 30, marginBottom: 10 }}>
                          <Typography variant="subtitle1" style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                            {userExp[0].user_post} {userExp[0].user_post ? "|" && expMonths[expMonthsAndExp[0].expStartMon] && expMonths[expMonthsAndExp[0].expStartYear] : ""} <span style={{ fontWeight: 100, fontSize: 12 }}>{expMonths[expMonthsAndExp[0].expStartMon]} {expMonthsAndExp[0].expStartYear}</span>
                          </Typography>
                        </div>
                        :
                        <div style={{ position: 'relative' }}>
                          {userExp && userExp.length > 0 && hasOverlapExps && hasOverlapExps.some(item => item.overlap === 'true') ?
                            <LightTooltip title="This user has some experiences whose start date is before the end date of their previous experience. For such experiences, the timeline indicates only the starting month." placement="top-start" arrow>
                              <IconButton aria-label="info" size="small" style={{ position: "absolute", top: '-4px', left: '-2px', zIndex: '9', cursor: 'default' }}>
                                <InfoOutlinedIcon className={classes.infoIcon} />
                              </IconButton>
                            </LightTooltip>
                            : null}
                          <div id="timelineListId" className={classes.timelineList} ref={timelineRef}>
                            {userExp && userExp.length > 0 && userExp.map((item, i) => (
                              <div key={i}
                                // style={{ width: userExp.length === 2 && i === 0 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30 ? 150 : (userExp.length === 1 && 300) }}
                                style={{
                                  width:
                                    (userExp && userExp.length === 1)
                                      || (userExp.length > 1 && i === userExp.length - 1
                                        // && (expMonthsAndExp[i].sameDaysDiff > 31 || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon)
                                      )
                                      || (userExp.length > 1 && i === 0 && expMonthsAndExp[i].daysDiff >= 30)
                                      ? 300
                                      : 150
                                }}
                                className={expMonthsAndExp[i]
                                  &&
                                  // (expMonthsAndExp[i].daysDiff >= 30
                                  //   && expMonthsAndExp[i].sameDaysDiff <= 31
                                  //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                  // )
                                  // || (
                                  //   expMonthsAndExp[i].sameDaysDiff <= 31 &&
                                  //    i === userExp.length - 1
                                  //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                  // ) ? "withBreakSameMon"
                                  // : 
                                  (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30
                                    ? "withBreak"
                                    : (
                                      // (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff <= 31)
                                      //   ||
                                      (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0
                                      ) ? "noBreakSameMon"
                                        : "noBreak"))}
                              >

                                <div>
                                  <div className={classes.timelineDuration}>
                                    {typeof (expMonthsAndExp[i].expStartMon) === 'string' ? expMonthsAndExp[i].expStartMon : expMonths[expMonthsAndExp[i].expStartMon]} {expMonthsAndExp[i].expStartYear}
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                  <div
                                    style={{
                                      transform: expMonthsAndExp[i] && (expMonthsAndExp[i].daysDiff < 0
                                        // ||
                                        // (
                                        //   expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon &&
                                        //   expMonthsAndExp[i].sameDaysDiff <= 31)
                                      )
                                        && 'translate(0)'
                                    }}
                                  >
                                    <LightTooltip title={item.user_post ? (item.company_name ? item.user_post + " at " + item.company_name : null) : null} placement="bottom">
                                      <div>{item.user_post ? item.user_post : null}</div>
                                    </LightTooltip>
                                    {/* {expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0 && (
                                  <div className={classes.duration}> {expMonthsAndExp[i].months && expMonthsAndExp[i].months > 0 ? expMonthsAndExp[i].months + 'm' : 'less than 1m'}</div>
                                )
                                } */}
                                    {/* {expMonthsAndExp[i] && (
                                  <div className={classes.duration}> {expMonthsAndExp[i].months && expMonthsAndExp[i].months > 0 ? expMonthsAndExp[i].months + 'm' : 'less than 1m'}</div>
                                )
                                } */}
                                  </div>
                                </div>

                                {userExp && userExp.length && i !== userExp.length - 1
                                  && (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30)
                                  // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                  //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                  // )
                                  &&
                                  <div>
                                    <div className={classes.timelineDuration}>
                                      {typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear}
                                    </div>
                                    <div>
                                      <span></span>
                                    </div>
                                  </div>
                                }
                                {userExp && userExp.length &&
                                  (i === userExp.length - 1
                                    // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                    //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                    // )
                                  )
                                  &&
                                  <div>
                                    <div className={classes.timelineDuration}>
                                      {userExp[i].company_ending_date === "Present" ? "Present" : typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear}
                                    </div>
                                    <div>
                                      <span></span>
                                    </div>
                                  </div>
                                }

                                {/* {userExp && userExp.length === 2 && i === 0 &&
                              (expMonthsAndExp[0] && expMonthsAndExp[0].sameDaysDiff > 31) && (expMonthsAndExp[0].daysDiff >= 0 && expMonthsAndExp[0].daysDiff < 30)
                              &&
                              <div>
                                <div className={classes.timelineDuration}>
                                  {userExp[i].company_ending_date === "Present" ? "Present" : expMonths[expMonthsAndExp[i].expEndMon] + " " + expMonthsAndExp[i].expEndYear}
                                </div>
                                <div>
                                  <span></span>
                                </div>
                              </div>
                            } */}
                                <span className="breakChar" style={{ position: 'absolute', top: '19%', right: '-22px', background: 'white' }}>b r e a k</span>
                              </div>
                            ))}

                          </div>
                        </div>
                      }
                      <div ref={expArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_R}`} onClick={scrollTimelineR} style={{ display: 'none' }}>&#8250;</div>
                    </div>
                  </Grid>

                  <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs container justifyContent="flex-start" alignItems="center" style={{ marginBottom: 10 }}>
                      {userEdu && userEdu[0] ? <SchoolRoundedIcon fontSize="small" className="primaryText" /> : null}
                      <Typography variant="subtitle1" style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10, }}>Education</Typography>
                    </Grid>
                    {userEdu && userEdu.length === 1 ?
                      <div style={{ marginLeft: 30, marginBottom: 10 }}>
                        <Typography variant="subtitle1" style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                          {userEdu[0].course_type && (userEdu[0].course_type === "UG Diploma" || userEdu[0].course_type === "PG Diploma" || userEdu[0].course_type === "ITI" || userEdu[0].course_type === "Certification") ? userEdu[0].course_type : (userEdu[0].course_abbreviation ? userEdu[0].course_abbreviation : (userEdu[0].course_name ? userEdu[0].course_name : (userEdu[0].user_degree ? userEdu[0].user_degree : null)))}{userEdu[0].course_type && (userEdu[0].course_type === "UG Diploma" || userEdu[0].course_type === "PG Diploma" || userEdu[0].course_type === "ITI" || userEdu[0].course_type === "Certification") ? (userEdu[0].course_name ? "—" + userEdu[0].course_name : null) : (userEdu[0].specialization_name ? "—" + userEdu[0].specialization_name : null)}{' | '}<span style={{ fontWeight: 100, fontSize: 12 }}>{userEdu[0].user_passing_year}</span>
                        </Typography>
                      </div>
                      :
                      <div className={classes.eduTimelineWrapper}>
                        <div ref={eduArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_L}`} onClick={scrollEduTimelineL} style={{ display: 'none' }}>&#8249;</div>
                        <div ref={eduTimelineRef} className={classes.timelineListEdu}>
                          {userEdu && userEdu.map((item, i) =>
                          (<div key={i}>
                            <div>
                              <div className={classes.timelineDuration}>
                                {item.user_passing_year}
                              </div>
                              <div>
                                <span></span>
                              </div>
                              <div>
                                <LightTooltip title={
                                  ((item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")
                                    ? item.course_type + (item.course_name ? "—" + item.course_name : "")
                                    : (item.course_abbreviation ?
                                      (item.course_abbreviation + (item.specialization_name ? "—" + item.specialization_name : ""))
                                      : (item.user_degree ? item.user_degree : ""))) + " from " + (item.user_college)

                                }
                                  placement="bottom">
                                  <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', height: '100%' }}>{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : (item.course_name ? item.course_name : (item.user_degree ? item.user_degree : "")))}{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}</div>
                                </LightTooltip>
                              </div>
                            </div>
                          </div>)
                          )}
                        </div>

                        <div ref={eduArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_R}`} onClick={scrollEduTimelineR} style={{ display: 'none' }}>&#8250;</div>

                      </div>
                    }
                  </Grid>
                  <Grid item xs container direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginBottom: '1em' }}
                  >
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap' }} >
                      <SportsHockeyIcon fontSize="small" className="primaryText" />
                      <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Skills</Typography>
                    </Grid>
                    <Grid item xs container wrap='nowrap' alignItems="center" className={classes.sectionItem_content} style={{ paddingLeft: '30px' }} >
                      {/* {getSkills(props.hit.user_skills)} */}
                      {props.hit.user_skills.length > 2 ? getSkills(props.hit.user_skills.slice(0, 2)) : getSkills(props.hit.user_skills)}
                      {props.hit.user_skills.length > 2 && <Typography onClick={viewMoreSkills} style={{ fontSize: '0.8em', color: '#14967b', textDecoration: 'underline', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>+{props.hit.user_skills.length - 2} more</Typography>}
                      <Popover
                        classes={{ paper: classes.skillsPopover }}
                        id={skillsPopoverId}
                        open={openSkillsPopover}
                        anchorEl={anchorSkillsEl}
                        onClose={closeSkillsPopover}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {getSkills(props.hit.user_skills.slice(2, props.hit.user_skills.length))}
                      </Popover>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item container justify="flex-end"
                alignItems="flex-end" style={{ position: 'absolute', bottom: 10, right: 10 }}>
                <IconButton aria-label="save" className={classes.bigIcon}>
                  <BookmarkRoundedIcon className={classes.saveIcon} />
                </IconButton>
              </Grid> */}
              </Grid>
            </Grid>
          </Paper>
        </div>}
      {!isRemoved &&
        <Grid item container
          justifyContent="center"
          alignItems="flex-end"
          style={{ paddingLeft: '30%' }}
        >
          <ToggleButton
            // id={props.index}
            value="check"
            className={classes.bigIconRect}
            style={{ marginRight: 10, width: 'auto' }}
            classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
            // selected={invited}
            // onChange={gotResponse ? handleInviteClick : undefined}
            onChange={(e) => {
              AnalyticsHandler.track("CompanyPage_followers_emailInviteBtn")
              if (props.hit.invite_jobseekers.length > 0) {
                props.setInvitedRoles(props.hit.invite_jobseekers);
                // props.setSelected(props.hit.invite_jobseekers[e.target.parentNode.id])
              } else {
                props.setInvitedRoles([])
              }
              props.setOpenInviteModal(true);
              props.setOpenRolesList(true);
              props.setJobseeker_id(props.hit.jobseeker_id);
              props.setJobseekerName(props.hit.user_last_name ? props.hit.user_first_name + ' ' + props.hit.user_last_name : props.hit.user_first_name)

            }}

          >
            {/* <EmailIcon className={classes.saveIconRect} /> */}
            {invited ?
              ''
              : <EmailIcon className={classes.saveIconRect} />
            }
            {invited ? "Take Next Steps" : "Email Invite"}
          </ToggleButton>
          <Button
            className={classes.bigIconRect}
            classes={{ label: classes.tglBtnLabel }}
            style={{ width: 'auto' }}
            onClick={() => {
              AnalyticsHandler.track("CompanyPage_followers_fullProfileBtn")
              // props.hit.invite_jobseekers.length && localStorage.setItem('searched_role', props.hit.invite_jobseekers[0].job_role)
              window.open(`/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit.jobseeker_id}`, "_blank");
            }}
          ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> Full Profile</Button>

        </Grid>}

      <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
      <div>
        <Dialog
          open={open}
          onClose={handleHireCancelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="alert-dialog-title">{"Hiring Confirmation"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to hire <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={handleHireCancelClose}>
              Cancel
            </Button>
            <Button id="hire_btn_id" onClick={gotHireResponse ? handleHireYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openUninvite}
          onClose={handleUninviteCancelClose}
          aria-labelledby="remove-dialog-title"
          aria-describedby="remove-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="remove-dialog-title">{"Confirmation to remove"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="remove-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to remove <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span> from the list of jobseekers you invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.job_role ? props.hit.job_role.toLowerCase() : ""}</span> role?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
              Cancel
            </Button>
            {/* <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}