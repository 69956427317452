import { connectRefinementList } from 'react-instantsearch-dom';
/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
var axios = require('axios');

const useStyles = makeStyles({
  // option: {
  //   fontSize: 15,
  //   '& > span': {
  //     marginRight: 10,
  //     fontSize: 18,
  //   },
  // },
  comboBoxRoot: {
    marginTop: 14,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
    "&:after": {
      position: 'absolute',
      content: '""',
      width: 1,
      height: 24,
      backgroundColor: '#E0E0E0',
    }
  },
  inputRoot: {
    padding: '0 0 0 8px!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    "& .MuiIconButton-label": { display: "none" },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
  },

  input: {
    height: 43,
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },
  },
  popper: {
    marginTop: 16,
  },
  listbox: {
  },
  paper: {
    borderRadius: 10,
  }
});

function CustomRefinementList  ({ items, refine }) {

const classes = useStyles();
const [Roles,setRoles] = React.useState([]);

React.useEffect(() => {
    axios.get('http://dev.meetworks.in:9000/users/get_job_role_mapping/').then(( Result)=>
    {    
      setRoles(Result.data)     
    })
}, [])

  return (
    <Autocomplete
      id="roles filter"
      style={{ flex: 1 }}
      options={Roles}
      classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper }}
      autoHighlight
      onChange={(event, newValue) => {
          event.preventDefault();
            if(newValue)
            refine(newValue.title);
        }}

      getOptionLabel={(option) => option.title}
      renderOption={(option) => (
        <React.Fragment>       
          {option.title} 
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Role"
          variant="outlined"
          placeholder="What position are you hiring for?"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />

//  <Autocomplete
//       id="Qualifications filter"
//       style={{ width: 300 }}
//       options={Qualifications}
//       classes={{
//         option: classes.option,
//       }}
//       autoHighlight
//       onChange={(event, newValue) => {
//          // setValue(newValue);
//          // debugger;

//           event.preventDefault();
//             if(newValue)
//             refine(newValue.title);
//         }}

//       getOptionLabel={(option) => option.title}
//       renderOption={(option) => (
//         <React.Fragment>       
//           {option.title} 
//         </React.Fragment>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label="Choose Qualifications"
//           variant="outlined"
//           inputProps={{
//             ...params.inputProps,
//             autoComplete: 'new-password', // disable autocomplete and autofill
//           }}
//         />
//       )}
//     /> 
  );
}


const CustomList = connectRefinementList(CustomRefinementList);

export default CustomList;