import React from 'react';
import { Typography, TextField, Grid } from '@material-ui/core';
// import TextEditor from '../../components/TextEditor';

function OtherRequirements(props) {
    const { classes, values, setValues } = props;
    // const change = (state) => {
    //     // Get current text
    //     let stringText = state.getCurrentContent().getPlainText();
    //     setValues({ ...values, requirement: stringText });
    // }
    return <Grid item xs={12} className={classes.my4} >
        <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Other Requirements</Typography>
        {/* <TextEditor 
            label="Metion any other requirements..."
            onChange={change}
         /> */}
         <TextField
            multiline
            fullWidth
            variant='outlined'
            className={classes.description_textField}
            minRows={8}
            placeholder="Mention any other requirements..."
            onChange={e => setValues({ ...values, candidate_requirement: e.target.value })}
            value={values.candidate_requirement}
        />
    </Grid>;
}

export default OtherRequirements;
