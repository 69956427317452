import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 'unset',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        // width: '100%',
        height: 'calc(100vh - 240px)',
        minHeight: 300,
        //width: 'calc(100vw - 600px)',
        '&.animate': {
            '& #paper1':{
                animation: 'moveForward1 0.5s ease-in-out 0.15s infinite alternate',
            },
            '& #paper0':{
                animation: 'moveForward0 0.5s ease-in-out infinite alternate',
            }
        }
    },
    rootAnimating: {
        flexGrow: 'unset',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        // width: '100%',
        height: 'calc(100vh - 240px)',
        minHeight: 300,
        //width: 'calc(100vw - 600px)',
        '&': {
            '& #paper1':{
                animation: 'moveForward1 0.5s ease-in-out 0.15s infinite alternate',
            },
            '& #paper0':{
                animation: 'moveForward0 0.5s ease-in-out infinite alternate',
            }
        }
    },
    paper1: {
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: theme.spacing(0),
        margin: 'auto',
        width: '100%',
        height: '100%',
        position: 'absolute',
        transform: 'translate3d(30px, 0, 0) scale(0.96)',
        transition: 'all 0.25s ease-in',
        backgroundColor: '#E8E8E8',
    },
    paper0: {
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: theme.spacing(0),
        margin: 'auto',
        width: '100%',
        height: '100%',
        position: 'absolute',
        transform: 'translate3d(58px, 0, 0) scale(0.92)',
        transition: 'all 0.25s ease-in',
        backgroundColor: '#D6D6D6',
    },
}));

const CardsStack = (props) => {
    const classes = useStyles();
    const cardStackRef = React.useRef(null);
    const [isAnimating, setIsAnimating] = React.useState(false);
    
    React.useEffect(() => {
        props.setCardStackRef(cardStackRef);
     }, [cardStackRef])

     React.useEffect(() => { 
        if(props.isCardLoaderOn){
          setIsAnimating(true);
        } else {
          setIsAnimating(false);
        }
      }, [props.isCardLoaderOn])
   
    return (
        <div id="cardWrapper1" className={isAnimating ? classes.rootAnimating : classes.root} ref={cardStackRef}>
            <Paper id="paper0" className={classes.paper0} elevation={1}></Paper>
            <Paper id="paper1" className={classes.paper1} elevation={2}></Paper>
        </div>
    )
}

export default CardsStack;