import React from 'react'
import { Grid } from '@material-ui/core';
import AngryIcon from '../assets/AngryIcon.svg';
import SadIcon from '../assets/Sad.svg';
import NeutralIcon from '../assets/Neutral.svg';
import HappyIcon from '../assets/Happy.svg';
import VeryHappyIcon from '../assets/VeryHappy.svg';
import AngryUnselected from '../assets/angryUnselected.svg';
import SadUnselected from '../assets/sadUnselected.svg';
import NeutralUnselected from '../assets/neutralUnselected.svg';
import HappyUnselected from '../assets/happyUnselected.svg';
import veryHappyUnselected from '../assets/veryHappyUnselected.svg';

function Ratings(props) {
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        let val = props.selectedValue;
        switch (true) {
            case val >= 0.5 && val < 1.5:
                setValue(1);
                break;
            case val >= 1.5 && val < 2.5:
                setValue(2);
                break;
            case val >= 2.5 && val < 3.5:
                setValue(3);
                break;
            case val >= 3.5 && val <= 4.5:
                setValue(4);
                break;
            case val >= 4.5 && val <= 5:
                setValue(5);
                break;

            default:
                break;
        }
    }, []);

    return (
        <>
            <Grid style={{ width: '50%' }} item container justifyContent={props.justifyContent} alignItems='center' wrap='nowrap' >
                {value === 1 ? <span style={{ width: '2.5em' }}> <img style={{ width: '2.5em', marginRight: '3px', marginLeft: '-5px' }} src={AngryIcon} alt='angry' /></span> : <span style={{ width: '2em' }}><img style={{ width: '1.5em', marginRight: '3px' }} src={AngryUnselected} alt='angry' /></span>}
                {value === 2 ? <span style={{ width: '2.5em' }}> <img style={{ width: '2.5em', marginRight: '3px', marginLeft: '-5px' }} src={SadIcon} alt='sad' /></span> : <span style={{ width: '2em' }}><img style={{ width: '1.5em', marginRight: '3px' }} src={SadUnselected} alt='angry' /></span>}
                {value === 3 ? <span style={{ width: '2.5em' }}> <img style={{ width: '2.5em', marginRight: '3px', marginLeft: '-5px' }} src={NeutralIcon} alt='neutral' /></span> : <span style={{ width: '2em' }}><img style={{ width: '1.5em', marginRight: '3px' }} src={NeutralUnselected} alt='angry' /></span>}
                {value === 4 ? <span style={{ width: '2.5em' }}> <img style={{ width: '2.5em', marginRight: '3px', marginLeft: '-5px' }} src={HappyIcon} alt='happy' /></span> : <span style={{ width: '2em' }}><img style={{ width: '1.5em', marginRight: '3px' }} src={HappyUnselected} alt='angry' /></span>}
                {value === 5 ? <span style={{ width: '2.5em' }}> <img style={{ width: '2.5em', marginRight: '3px', marginLeft: '-5px' }} src={VeryHappyIcon} alt='veryHappy' /></span> : <span style={{ width: '2em' }}><img style={{ width: '1.5em', marginRight: '3px' }} src={veryHappyUnselected} alt='angry' /></span>}
            </Grid>
        </>
    )
}

export default Ratings