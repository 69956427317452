import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PrimaryAppBar from '../../components/JobAppBar';
import { StylesProvider } from "@material-ui/core/styles";
import '../../style/App.css';
import { useHistory } from "react-router-dom";
import global_isSignedIn from '../discovery/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';
//import artworkImage from '../../assets/support_ page_illustration.svg';
import LogoLoader from '../../assets/logo-loader.gif';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer';
import ogImg from '../../assets/og-img.jpg';
import supportIcon from '../../assets/support-icon.svg';
import { Facebook, Instagram, LinkedIn } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: 'calc(70vh - 0px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 74,
        backgroundAttachment: 'fixed',
    },
    pageTitle: {
        color: '#5BC2A8',
        textAlign: 'center',
        marginBottom: '0.2em',
        fontWeight: 500,
    },
    pageBar: {
        width: 772,
        flexBasis: 65,
        marginTop: 0,
        zIndex: 8,
    },
    pageInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 220px)',
        //paddingTop: 50,
    },
    pageInfoText: {
        font: "Mukta",
        fontWeight: "400",
        fontSize: 18,
        padding: '0 10px',
        textAlign: 'center',
        '@media (max-width: 600px)': {
            fontSize: 13,
        }
    },
    subtitle: {
        fontSize: 18,
        marginBottom: 5,
        fontWeight: 500,
        font: "Roboto",
        '@media (max-width: 600px)': {
            fontSize: 14
        }
    },
    addressTitle: {
        fontSize: 16,
        '@media (max-width: 600px)': {
            fontSize: 14
        }
    },
    addressText: {
        fontSize: 16,
        marginLeft: 8,
        color: '#797979',
        '@media (max-width: 600px)': {
            fontSize: 13
        }
    },
    sectionDesktop: {
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        // marginBottom: 40,
        '@media (max-width: 915px)': {
            display: 'none'
        }
    },
    sectionMobile: {
        display: 'none',
        alignItems: 'center', 
        justifyContent: 'center', 
        flexDirection: 'column',
        '@media (max-width: 915px)': {
            display: 'flex'
        }
    },
    sectionContainer: {
        width: "95vw",
        height: 150,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: '#fff',
        padding: '0 2em',
        margin: '0.3em 0',
        '@media (max-width: 600px)': {
            height: 120,
        }
    },
    sectionContainerDesktop: {
        width: "45vw",
        height: 150,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: '#fff',
        padding: '0 2em',
        margin: '0.3em 0',
        '@media (max-width: 600px)': {
            height: 120,
        }
    },
    listViewWrapper: {
        opacity: 1,
        '&.animate': {
            opacity: 0,
            transition: 'opacity 0.25s ease-in-out',
        }
    },
    pageButton: {
        textTransform: "none",
        width: 'auto',
        padding: 25,
        height: 35,
        marginTop: 20,
        fontSize: 18,
    },
    pageButton_pri: {
        backgroundColor: "#14967B",
        color: "white",
        borderRadius: 5,
        '&:hover': {
            backgroundColor: "#14967B",
        },
    },
    artwork: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 250,
        height: 250,
        marginBottom: 0,
        overflow: 'hidden',
        borderRadius: '10%',
        background: 'none',
        '@media (max-width: 600px)': {
            width: 200,
            height: 200,
        }

    }
}));

export const DrawerContext = React.createContext({});

export default function Support(props) {
    const classes = useStyles();
    const [navValue, setNavValue] = React.useState(5);

    let sessionCookie = document.cookie.indexOf('connect.sid') === -1 ? null : true;
    const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
    global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

    const checkSignedInStatus = () => {
        return isSignedIn
    }
    const handleSignedInStatus = (v) => {
        setIsSignedIn(v);
    }

    document.body.classList.remove("login");
    document.body.classList.add('wallpaper--icon');

    React.useEffect(() => {
        if (global_isSignedIn.isSignedIn === false) {
            setIsSignedIn(false);
        } else if (global_isSignedIn.isSignedIn === true) {
            setIsSignedIn(true);
        }
    }, [global_isSignedIn.isSignedIn])

    // drawer callback starts
    const [refresh, doRefresh] = React.useState(false);
    const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
    const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
    const handleDrawer = () => {
        AnalyticsHandler.track("signIn_View_btnClick_SupportPage");
        setCanDrawerOpen(true)
        doRefresh(prev => !prev)
    }
    // drawer callback ends

    // drawer context for card view starts
    const setCommand = (command) => {
        setState({ ...state, command: command })
    }

    const initCardDrawerState = {
        command: "",
        setCommand: setCommand
    }

    const [state, setState] = React.useState(initCardDrawerState)

    React.useEffect(() => {
        if (state.command !== "") {
            handleDrawer();
        }
    }, [state])
    // drawer context for card view ends

    return (
        <StylesProvider injectFirst>
            <div className={classes.root}>
                <Helmet>
                    <title>Contact details of Meetworks | namaste@meetworks.in</title>
                    <meta name='description' content='Get in touch with us for anything related to our company, profile or services. Contact us for any job posting or related queries on our platforms' />
                    <meta name='keywords' content='contact details of Meetworks, Address of Meetworks, Whatsapp Meetworks' />
                    <link rel='canonical' href='https://employer.meetworks.in/support' />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" content="Meet" />
                    <meta itemprop="description" content="Get in touch with us for anything related to our company, profile or services. Contact us for any job posting or related queries on our platforms" />
                    <meta itemprop="image" content={ogImg} />

                    {/* Open graph / facebook */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://employer.meetworks.in/" />
                    <meta property="og:title" content="Contact details of Meetworks | namaste@meetworks.in" />
                    <meta property="og:description" content="Get in touch with us for anything related to our company, profile or services. Contact us for any job posting or related queries on our platforms" />
                    <meta property="og:image" content={ogImg} />

                    {/* Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://employer.meetworks.in/" />
                    <meta property="twitter:title" content="Contact details of Meetworks | namaste@meetworks.in" />
                    <meta property="twitter:description" content="Get in touch with us for anything related to our company, profile or services. Contact us for any job posting or related queries on our platforms" />
                    <meta property="twitter:image" content={ogImg} />
                </Helmet>
                {/* <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> */}
                <PrimaryAppBar
                    isSignedIn={isSignedIn}
                    setIsSignedIn={handleSignedInStatus}
                    handleDrawer={handleDrawer}
                    navValue={navValue}
                    setNavValue={setNavValue}
                >
                </PrimaryAppBar>
                <div className={`${classes.headingSection} ${classes.sectionMobile}`}>
                    <span style={{ alignItems: "center", font: "Roboto", fontWeight: "500", fontSize: 25, marginTop: '1vh', marginBottom: '1em' }}>  Contact Us </span>
                    <div style={{ alignItems: 'center' }} className={classes.sectionContainer}>
                        <span className={classes.pageInfoText}>Get in touch for help to build your amazing team.  </span>
                        <Button
                            id="whatsapp_button"
                            variant="contained"
                            className={`${classes.pageButton} ${classes.pageButton_pri}`}
                            style={{ width: 200, fontSize: '1rem', textTransform: 'capitalize', marginTop: '1em' }}
                            onClick={() => {
                                AnalyticsHandler.track('Support_whatsapp_BtnClick');
                                window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
                            }}
                        >
                            {/* अपने मीत को  */}
                            WhatsApp करें
                        </Button>
                    </div>
                    <div style={{ alignItems: 'flex-start' }} className={classes.sectionContainer}>
                        <div style={{ display: 'flex', marginBottom: '1em' }}>
                            <span className={classes.addressTitle}>Address:</span> <span className={classes.addressText}>Medha Learning Foundation, 407, Dr. Bajinath Road, New Hyderabad, Lucknow - 226007, Uttar Pradesh, India</span>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span className={classes.addressTitle}>Email Id:</span> <a href='mailto:namaste@meetworks.in' className={classes.addressText} >namaste@meetworks.in</a>
                        </div>
                    </div>
                    <div style={{ alignItems: 'flex-start' }} className={classes.sectionContainer}>
                        <span className={classes.subtitle}>Follow us on social media</span>
                        <a href='https://www.instagram.com/meet_app/' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><Instagram fontSize='small' style={{ marginRight: 5 }} />Instagram</a>
                        <a href='https://www.facebook.com/meetworks.in' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><Facebook fontSize='small' style={{ marginRight: 5 }} />Facebook</a>
                        <a href='https://www.linkedin.com/company/meetworks' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', color: '#333', fontSize: 14, margin: '3px 0', textDecoration: 'none' }}><LinkedIn fontSize='small' style={{ marginRight: 5 }} />Linkedin</a>

                    </div>
                </div>
                <div className={`${classes.headingSection} ${classes.sectionDesktop}`}>
                    <span style={{ alignItems: "center", font: "Roboto", fontWeight: "500", fontSize: 25, marginTop: '1vh', marginBottom: '1em' }}>  Contact Us </span>
                    <Grid wrap='nowrap' container style={{ width: '70vw' }} >
                        <Grid container item xs={4} style={{ backgroundColor: '#fff', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px', marginRight: 20, padding: '0 10px' }} direction='column' alignItems='center' justifyContent='center' >
                            <img src={supportIcon} width={150} height={150} />
                            <span className={classes.pageInfoText}>Get in touch for help to build your amazing team.</span>
                            <Button
                                id="whatsapp_button"
                                variant="contained"
                                className={`${classes.pageButton} ${classes.pageButton_pri}`}
                                style={{ width: 200, fontSize: '1rem', textTransform: 'capitalize', marginTop: '1em' }}
                                onClick={() => {
                                    AnalyticsHandler.track('Support_whatsapp_BtnClick');
                                    window.location.href = "https://api.whatsapp.com/send?phone=919580899412&text=Hi Meet team"
                                }}
                            >
                                WhatsApp करें
                            </Button>
                        </Grid>
                        <Grid xs={8} container item direction='column' alignItems='center' justifyContent='center' >
                            <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px' }} className={classes.sectionContainerDesktop}>
                                <div style={{ display: 'flex', marginBottom: '1em' }}>
                                    <span className={classes.addressTitle}>Address:</span> <span className={classes.addressText}>Medha Learning Foundation, 407, Dr. Bajinath Road, New Hyderabad, Lucknow - 226007, Uttar Pradesh, India</span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span className={classes.addressTitle}>Email Id:</span> <a href='mailto:namaste@meetworks.in' className={classes.addressText} style={{color: '#5bc2a8'}} >namaste@meetworks.in</a>
                                </div>
                            </div>
                            <div style={{ alignItems: 'flex-start', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 3px 0px' }} className={classes.sectionContainerDesktop}>
                                <span className={classes.subtitle}>Follow us on social media</span>
                                <a href='https://www.instagram.com/meet_app/' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><Instagram fontSize='small' style={{ marginRight: 5, color: '#333' }} />Instagram</a>
                                <a href='https://www.facebook.com/meetworks.in' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><Facebook fontSize='small' style={{ marginRight: 5, color: '#333' }} />Facebook</a>
                                <a href='https://www.linkedin.com/company/meetworks' target='_blank' style={{ display: 'flex', alignItems: 'center', width: 'max-content', fontSize: 14, margin: '3px 0', color: '#5bc2a8' }}><LinkedIn fontSize='small' style={{ marginRight: 5, color: '#333' }} />Linkedin</a>

                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <SignInUpDrawer
                canDrawerOpen={canDrawerOpen}
                refresh={refresh}
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                setForRefreshOnly={setForRefreshOnly}
                setCanDrawerOpen={setCanDrawerOpen}
                doRefresh={doRefresh}
            />
            <Footer />
        </StylesProvider >
    )
}