import React from 'react';
import { Typography, Grid, InputBase, FormControl, InputAdornment, FormControlLabel, Checkbox, TextField } from '@material-ui/core';


function Salary(props) {
    const { classes, values, setValues, showUnpaidOption, errors, setErrors, setIsUnpaid } = props;
    const [disabledDiv, setDisabledDiv] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(true);
    const [isIncentiveChecked, setIsIncentiveChecked] = React.useState(false);

    const disableFormDiv = () => {
        setIsUnpaid(true);
        setDisabledDiv(!disabledDiv);
        setIsChecked(!isChecked);
        setValues({ ...values, variable_salary_amount: '0', job_salary_amount: '0', min_salary: '', max_salary: '' });
    }

    React.useEffect(() => {
        if (values.job_salary_amount === '0') {
            setIsChecked(true);
            setDisabledDiv(true);
        } else {
            setIsChecked(false);
            setDisabledDiv(false);
        }
    }, []);

    return <Grid item xs={12} className={classes.my3} >
        <Typography variant='h5' className={`${props.isMobile ? classes.inputHeading : ''}`}>Salary Details<span style={{ color: '#f44336' }}>*</span></Typography>
        <div className={classes.salaryDetails}>
            {showUnpaidOption && <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={disableFormDiv} />}
                label='Unpaid Internship'
                classes={{ label: classes.checkboxLabel }}
            />}
            <div className={disabledDiv ? `${classes.disableDiv} ${classes.flexboxCol}` : classes.flexboxCol}>
                <div
                    // style={{ flexDirection: props.isMobile ? 'column' : 'row', alignItems: props.isMobile ? 'center' : 'end', justifyContent: props.isMobile ? 'center' : 'space-between' }} className={props.isMobile ? classes.formControlSalary : `${classes.formControlSalary} ${classes.my2}`}
                    className={classes.formControlSalary}
                >
                    <div className={classes.flexboxCol}>
                        <p className={classes.mr1}>Min<span style={{ color: '#f44336' }}>*</span></p>
                        <div className={classes.flexbox}>
                            <TextField
                                type='number'
                                classes={{ root: classes.TfRoot }}
                                style={{ width: props.isMobile ? '70%' : 'max-content' }}
                                variant={props.isMobile ? 'outlined' : 'standard'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            ₹
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={e => {
                                    setErrors({});
                                    setValues({ ...values, min_salary: e.target.value });
                                }}
                                value={values.min_salary}
                                error={errors.min_salary ? true : false}
                                helperText={errors.min_salary ? errors.min_salary : ''}
                            />
                            <span style={{ color: '#0000008A' }}>/month</span>
                        </div>
                    </div>
                    <FormControl>
                        <p className={`${props.isMobile ? classes.mb1 : null}`}>Max<span style={{ color: '#f44336' }}>*</span></p>
                        <div className={classes.flexbox}>
                            <TextField
                                type='number'
                                classes={{ root: classes.TfRoot }}
                                style={{ width: props.isMobile ? '70%' : 'max-content' }}
                                variant={props.isMobile ? 'outlined' : 'standard'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            ₹
                                        </InputAdornment>
                                    ),
                                }}
                                // placeholder='e.g. article/sale/word'
                                onChange={e => {
                                    setErrors({});
                                    setValues({ ...values, max_salary: e.target.value });
                                }}
                                value={values.max_salary}
                                error={errors.max_salary ? true : false}
                                helperText={errors.max_salary ? errors.max_salary : ''}
                            />
                            <span style={{ color: '#0000008A' }}>/month</span>
                        </div>
                    </FormControl>
                </div>
                <FormControlLabel
                    control={<Checkbox />}
                    label='With incentive'
                    classes={{ label: classes.checkboxLabel }}
                    onChange={() => {
                        setIsIncentiveChecked(p => !p);
                        setValues({ ...values, variable_salary_amount: '', variable_salary_unit: '' })
                    }}
                />
                {isIncentiveChecked && <FormControl style={{ flexDirection: props.isMobile ? 'column' : 'row', justifyContent: props.isMobile ? 'center' : 'space-between' }} className={props.isMobile ? `${classes.formControlSalary}` : `${classes.formControlSalary} ${classes.my2}`}>
                    <p className={classes.mr1}>Incentive</p>
                    <TextField
                        type='number'
                        classes={{ root: classes.TfRoot }}
                        variant={props.isMobile ? 'outlined' : 'standard'}
                        style={{ width: props.isMobile ? '70%' : 'max-content', marginBottom: props.isMobile ? 5 : 0 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    ₹
                                </InputAdornment>
                            ),
                        }}
                        onChange={e => setValues({ ...values, variable_salary_amount: e.target.value })}
                        value={values.variable_salary_amount === '0' || values.variable_salary_amount === null ? '' : values.variable_salary_amount}
                    />
                    <FormControl style={{ flexDirection: props.isMobile ? 'column' : 'row', alignItems: props.isMobile ? 'flex-start' : 'center' }} className={classes.formControlSalary}>
                        <p className={classes.mx2}>per</p>
                        <TextField
                            classes={{ root: classes.TfRoot }}
                            variant={props.isMobile ? 'outlined' : 'standard'}
                            style={{ width: props.isMobile ? '70%' : 'max-content', marginTop: props.isMobile ? 10 : 0 }}
                            placeholder='e.g. article/sale/word'
                            onChange={e => setValues({ ...values, variable_salary_unit: e.target.value })}
                            value={values.variable_salary_unit}
                        />
                    </FormControl>
                </FormControl>}
                {/* <span className={classes.helper_text} style={{ marginLeft: '10rem', alignSelf: 'center' }}>press Enter ↲</span> */}
                {/* <FormControlLabel control={<Checkbox />} label='Training Period' classes={{ label: classes.checkboxLabel }} />
                <FormControl className={classes.formControlSalary}>
                    <span style={{ marginRight: '2.2em' }}>Duration</span>
                    <InputBase
                        classes={{ root: classes.IbRoot }}
                        placeholder='No. of months'
                    />
                </FormControl>
                <FormControl className={classes.formControlSalary}>
                    <span style={{ marginRight: '3.6em' }}>Fixed</span>
                    <InputBase
                        classes={{ root: classes.IbRoot }}
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                    />
                </FormControl>
                <FormControl className={classes.formControlSalary}>
                    <span className={classes.mr1}>Incentive</span>
                    <InputBase
                        classes={{ root: classes.IbRoot }}
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                    />
                    <FormControl className={classes.formControlSalary}>
                        <span style={{ margin: '1em' }}>per</span>
                        <InputBase
                            classes={{ root: classes.IbRoot }}
                            style={{ width: 'max-content' }}
                            placeholder='e.g. article/sale/word'
                        />
                    </FormControl>
                </FormControl>
                <span className={classes.helper_text} style={{ marginLeft: '10rem', alignSelf: 'center' }}>press Enter ↲</span> */}

            </div>
        </div>
    </Grid>;
}

export default Salary;
