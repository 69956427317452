import React from 'react';
import { Typography, Grid, InputBase, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

function Duration(props) {
  const { classes, values, setValues, error } = props;
  // const [inputDurationValue, setInputDurationValue] = React.useState(props.duration ? props.duration : "");
  const [year, setYear] = React.useState('');
  const [month, setMonth] = React.useState('');

  // const onRoleInputChangeHandler = (event, newInputValue, reason) => {
  //   setInputDurationValue(newInputValue);
  // }

  React.useEffect(() => {
    if (year && month) {
      setValues({ ...values, internship_duration: year + ' ' + month });
    } else if (year) {
      setValues({ ...values, internship_duration: year });
    } else if (month) {
      setValues({ ...values, internship_duration: month });
    } else {
      setValues({ ...values, internship_duration: '' });
    }
  }, [year, month]);

  React.useEffect(() => {
    if (values.internship_duration !== '') {
      let duration = values.internship_duration.split(' ');
      if (duration.length === 2 && duration[1] === 'year' || duration[1] === 'years') {
        setYear(duration[0] + ' ' + duration[1]);
      } else if (duration.length === 2 && duration[1] === 'month' || duration[1] === 'months') {
        setMonth(duration[0] + ' ' + duration[1]);
      } else {
        setYear(duration[0] + ' ' + duration[1]);
        setMonth(duration[2] + ' ' + duration[3]);
      }
    }
  }, []);

  return <Grid item xs={12} className={classes.my4} >
    <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Duration</Typography>
    <div className={`${classes.durationDiv} ${classes.flexbox}`}>
      <Autocomplete
        id="combo-box-demo"
        options={durationYears}
        name="internship_duration"
        value={year || null}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        getOptionSelected={(option, value) => option.name === value}
        style={{ width: 280 }}
        renderInput={(params) => <TextField {...params} placeholder='No. of years' variant={window.innerWidth <= 600 ? 'outlined' : 'standard'} classes={{ root: classes.TfRoot }} />}
        renderOption={(option) => option.name}

        onChange={(event, newValue) => {
          event.preventDefault();
          if (newValue) {
            setYear(newValue.name);
          } else {
            setYear('');
          }
        }}
        classes={{input: classes.input}}

      />
      <span style={{ margin: '0 1em' }}>and/or</span>
      <Autocomplete
        id="combo-box"
        options={durationMonths}
        name="internship_duration"
        value={month || null}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          // Regular option
          return option.name;
        }}
        classes={{input: classes.input}}

        getOptionSelected={(option, value) => option.name === value}
        style={{ width: 280 }}
        renderInput={(params) => <TextField {...params} placeholder='No. of months' variant={window.innerWidth <= 600 ? 'outlined' : 'standard'} classes={{ root: classes.TfRoot }} />}
        renderOption={(option) => option.name}

        onChange={(event, newValue) => {
          event.preventDefault();
          if (newValue) {
            setMonth(newValue.name);
          } else {
            setMonth('');
          }
        }}
      />
    </div>
  </Grid>;
}

const durationYears = [
  { id: 1, name: '1 year' },
  { id: 2, name: '2 years' },
  { id: 3, name: '3 years' },
];
const durationMonths = [
  { id: 1, name: '1 month' },
  { id: 2, name: '2 months' },
  { id: 3, name: '3 months' },
  { id: 4, name: '4 months' },
  { id: 5, name: '5 months' },
  { id: 6, name: '6 months' },
  { id: 7, name: '7 months' },
  { id: 8, name: '8 months' },
  { id: 9, name: '9 months' },
  { id: 10, name: '10 months' },
  { id: 11, name: '11 months' },
];

export default Duration;
