import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LogoLoader from '../../assets/logo-loader.gif';
import { PlayCircleFilledWhite } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    //position: 'relative',
    position: 'absolute',
    top: '-16px',
    left: 0,
    width: '100%',
    height: 'calc(100vh - 187px)!important',
    marginBottom: theme.spacing(2),
    '& .paper': {
      boxSizing: 'border-box',
      padding: theme.spacing(0),
      margin: 'auto',
      maxWidth: 830,
      maxHeight: 300,
      width: 830,
      height: 300,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      background: '#ffffff',
      borderRadius: '10px',
      height: '100%',
      position: 'absolute',
      top: 0,
      zIndex: 2,
      display: 'none',
    },
    '&.animate .paper': {
      animation: 'bgColorFade 0.15s ease-in-out infinite alternate',
      display: 'flex'
    },
    '&.animate div': {
      //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
      display: 'flex'
    }
  },

  leftSection: {
    height: '100%',
    padding: 0,
    position: 'relative',
    background: '#D5F5ED',
    overflow: 'hidden',
    maxWidth: '23%',
    flexBasis: '23%',
  },

  RightSection: {
    //height: '100%',
    maxHeight: '100%',
    paddingTop: 10,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 450,
    overflow: 'hidden'
  },
  img: {
    margin: 'auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  avatarWrapper: {
    width: '100%',
    height: 300,
    borderRadius: 0,
    //paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    margin: 0,
  },
  lastActive: {
    background: '#EEE',
    width: '20%',
    height: '10px',
    borderRadius: 5,
  },
  ph_namePlate: {
    background: '#EEE',
    width: '60%',
    height: '20px',
    borderRadius: 5,
    marginBottom: 25,
  },
  ph_text: {
    background: '#EEE',
    width: '40%',
    height: '15px',
    borderRadius: 5,
    marginBottom: 10,
    marginLeft: 10,
  },
  ph_subtext: {
    background: '#EEE',
    width: '60%',
    height: '12px',
    borderRadius: 5,
    marginBottom: 20,
    marginLeft: 10,
  },
  loaderShuffle: {
    // background: ' rgb(252,252,252,0.6)',
    // background: 'radial-gradient(circle, rgba(252,252,252,0) 0%, rgba(252,252,252,1) 67%, rgba(252,252,252,1) 100%)',
    background:'radial-gradient(circle, rgba(252,252,252,0.8) 0%, rgba(252,252,252,0.95) 27%, rgba(252,252,252,1) 100%)',
    borderRadius: '10px',
    border: '1px solid #eee',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 3,
    display: 'none',
    '&.animate': {
      //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
      display: 'flex'
    },
    '& p': {
      padding: '0 1em'
    }
  }

}));

const ListLoader = (props) => {
  const classes = useStyles();
  const listLoaderRef = React.useRef(null);
  const [loaderMessage, setLoaderMessage] = React.useState("");

  let playLoader = () => {
    // let loaderText = listLoaderRef.current.querySelector('.loaderText');
    // loaderText.innerHTML = props.loaderMessageOnPage2();
   
    listLoaderRef.current.classList.add('animate');
  }

  let listLoaderTimeout = () => {
    setTimeout(() => {
      if (listLoaderRef.current) {
        listLoaderRef.current.classList.remove('animate');
      };
    }, 2000)
  };

  React.useEffect(() => {
    props.setListLoaderRef(listLoaderRef);
  }, [])

  React.useEffect(() => { 
    playLoader();
    listLoaderTimeout();
  }, [props.isListLoaderOn])

  React.useEffect(() => { 
    setLoaderMessage(props.loaderMessageOnPage2)
  }, [props.loaderMessageOnPage2])

  return (
    <div id="cardWrapper1" className={classes.root}
      ref={listLoaderRef}
      style={{ flexDirection: 'column' }}>
      <Grid item xs={12} className={classes.loaderShuffle} container direction="column" justify="center" alignItems="center">
        <img alt="meet animated logo loader" style={{ width: 50 }} src={LogoLoader} />
        <Typography className="loaderText" component="p" variant="body2" style={{ marginTop: 10 }}>
        {loaderMessage}
        </Typography>
      </Grid>



      {/* <div className={classes.root} >
        <Paper className="paper" elevation={3}>
          <Grid container direction="row"
            justify="center"
            alignItems="flex-start" style={{ height: '100%', overflow: 'hidden' }} >
            <Grid item className={classes.leftSection}>
              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container justify="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justify="center"
                    alignItems="center">
                    <div className={classes.avatarWrapper}>

                    </div>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={9} sm container className={classes.RightSection}>
              <Grid item xs={12} container direction="row" justify="space-between"
                alignItems="flex-start">
                <div style={{ display: 'flex', width: '60%' }}>
                  <div className={classes.ph_namePlate}></div>
                </div>
                <div className={classes.lastActive}></div>
              </Grid>

              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container direction="column" justify="center"
                  alignItems="flex-start" >
                  <Grid item xs container justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }} >
                    <div className={classes.ph_text} ></div>
                    <div className={classes.ph_subtext} ></div>
                    <div className={classes.ph_text}></div>
                    <div className={classes.ph_subtext}></div>
                    <div className={classes.ph_text}></div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container justify="flex-end"
                alignItems="flex-end" style={{ position: 'absolute', bottom: 10, right: 10 }}>

              </Grid>

            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.root} >
        <Paper className="paper" elevation={3}>
          <Grid container direction="row"
            justify="center"
            alignItems="flex-start" style={{ height: '100%', overflow: 'hidden' }} >
            <Grid item className={classes.leftSection}>
              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container justify="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justify="center"
                    alignItems="center">
                    <div className={classes.avatarWrapper}>

                    </div>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={9} sm container className={classes.RightSection}>
              <Grid item xs={12} container direction="row" justify="space-between"
                alignItems="flex-start">
                <div style={{ display: 'flex', width: '60%' }}>
                  <div className={classes.ph_namePlate}></div>
                </div>
                <div className={classes.lastActive}></div>
              </Grid>

              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container direction="column" justify="center"
                  alignItems="flex-start" >
                  <Grid item xs container justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }} >
                    <div className={classes.ph_text} ></div>
                    <div className={classes.ph_subtext} ></div>
                    <div className={classes.ph_text}></div>
                    <div className={classes.ph_subtext}></div>
                    <div className={classes.ph_text}></div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container justify="flex-end"
                alignItems="flex-end" style={{ position: 'absolute', bottom: 10, right: 10 }}>

              </Grid>

            </Grid>
          </Grid>
        </Paper>
      </div>
      <div id="cardWrapper1" className={classes.root}>
        <Paper className="paper" elevation={3}>
          <Grid container direction="row"
            justify="center"
            alignItems="flex-start" style={{ height: '100%', overflow: 'hidden' }} >
            <Grid item className={classes.leftSection}>
              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container justify="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justify="center"
                    alignItems="center">
                    <div className={classes.avatarWrapper}>

                    </div>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={9} sm container className={classes.RightSection}>
              <Grid item xs={12} container direction="row" justify="space-between"
                alignItems="flex-start">
                <div style={{ display: 'flex', width: '60%' }}>
                  <div className={classes.ph_namePlate}></div>
                </div>
                <div className={classes.lastActive}></div>
              </Grid>

              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container direction="column" justify="center"
                  alignItems="flex-start" >
                  <Grid item xs container justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }} >
                    <div className={classes.ph_text} ></div>
                    <div className={classes.ph_subtext} ></div>
                    <div className={classes.ph_text}></div>
                    <div className={classes.ph_subtext}></div>
                    <div className={classes.ph_text}></div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container justify="flex-end"
                alignItems="flex-end" style={{ position: 'absolute', bottom: 10, right: 10 }}>

              </Grid>

            </Grid>
          </Grid>
        </Paper>
      </div> */}
    </div>
  )
}

export default ListLoader;