import React, { useState, useEffect, useMemo } from "react";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EcoRoundedIcon from '@material-ui/icons/EcoRounded';
import ComputerRoundedIcon from '@material-ui/icons/ComputerRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';

import MapChart from './MapChart';
import MapChartJobs from './MapChartJobs';
import MapChartStudents from './MapChartStudents';
import useStyles from './styles';

const WBDashboard = () => {
  const classes = useStyles();
  const [tradeBtn, setTradeBtn] = useState("");
  const [isNavActive, setIsNavActive] = useState([true, false, false, false, false, false, false])

  const [compOrigMarkers, setCompOrigMarkers] = useState([]);
  const [allCompCount, setAllCompCount] = useState(0); // count of all companies
  const [secCompCount, setSecCompCount] = useState({}); // sectorwise count of companies

  const [jobsOrigMarkers, setJobsOrigMarkers] = useState([]);
  const [allJobsCount, setAllJobsCount] = useState(0); // count of all jobs
  const [secJobsCount, setSecJobsCount] = useState({}); // sectorwise count of jobs

  const [studentsOrigMarkers, setStudentsOrigMarkers] = useState([]);
  const [allStudentsCount, setAllStudentsCount] = useState(0); // count of all jobs
  const [secStudentsCount, setSecStudentsCount] = useState({}); // sectorwise count of jobs

  // the names of districts as the ones in districtMap.json
  const ambala = ["Panchkula", "Kurukshetra", "Yamuna Nagar"];
  const jind = ["Hisar", "Kaithal", "Karnal", "Panipat"];
  const bhiwani = ["Mahendragarh", "Rohtak"];
  const gurugram = ["Faridabad", "Palwal", "Mewat", "Rewari"];
  const delhi = ["Jhajjar", "Sonepat", "Ghaziabad", "Gautam Buddha Nagar"]

  const handleNavClick = (trade, index) => {
    setTradeBtn(trade);
    let arr = isNavActive;
    for (let i = 0; i < arr.length; i++) {
      arr[i] = false;
    }
    arr[index] = true;
    setIsNavActive(arr);
    console.log(arr)
  }

  function exportTableToCSV(html, filename) {
    var csv = [
      ["", "Trade Group", "Total Count", "Tailoring/Textiles", "Beauty", "Mechanical", "Other", "Secretarial", "Computer/IT"],
      ["", "District Group"],
      ["COMPANIES"]];
    // For companies
    var rows = compOrigMarkers;

    for (var i = 0; i < rows.length; i++) {
      var row = "" + "," + rows[i].name + "+" + "," + rows[i].allCount + "," + rows[i].sectors.textile + "," + rows[i].sectors.beauty + "," + rows[i].sectors.mechanical + "," + rows[i].sectors.healthcare + "," + rows[i].allCount + "," + rows[i].allCount
      csv.push(row);
    }
    let lastRow = [["", "Total", allCompCount, secCompCount.textiles, secCompCount.beauty, secCompCount.mechanical, secCompCount.healthcare, allCompCount, allCompCount]];
    csv.push(lastRow);

    // For Openings

    var jobRows = jobsOrigMarkers;

    csv.push(["OPENINGS"])

    for (var i = 0; i < jobRows.length; i++) {
      var row = "" + "," + jobRows[i].name + "+" + "," + jobRows[i].allCount + "," + jobRows[i].sectors.textile + "," + jobRows[i].sectors.beauty + "," + jobRows[i].sectors.mechanical + "," + jobRows[i].sectors.healthcare + "," + jobRows[i].sectors.secretarial + "," + jobRows[i].sectors.it
      csv.push(row);
    }
    let jobLastRow = [["", "Total", allJobsCount, secJobsCount.textiles, secJobsCount.beauty, secJobsCount.mechanical, secJobsCount.healthcare, secJobsCount.secretarial, secJobsCount.it]];
    csv.push(jobLastRow);

    // For Students

    var studentRows = studentsOrigMarkers;

    csv.push(["STUDENTS"])

    for (var i = 0; i < studentRows.length; i++) {
      var row = "" + "," + studentRows[i].name + "+" + "," + studentRows[i].allCount + "," + studentRows[i].sectorsT.textile + "," + studentRows[i].sectorsT.beauty + "," + studentRows[i].sectorsT.mechanical + "," + studentRows[i].sectorsT.healthcare + "," + studentRows[i].sectorsT.secretarial + "," + studentRows[i].sectorsT.it
      csv.push(row);
    }
    let studentLastRow = [["", "Total", allStudentsCount, secStudentsCount.textiles, secStudentsCount.beauty, secStudentsCount.mechanical, secStudentsCount.healthcare, secStudentsCount.secretarial, secStudentsCount.it]];
    csv.push(studentLastRow);

    // download csv file
    downloadCSV(csv.join("\n"), filename);
  }

  function downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;


    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  return (
    <>
      <div className={classes.root}>

        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <h3 style={{ textAlign: 'center', textTransform: 'uppercase' }}>Strive Study</h3>
          <Divider />
          <List>
            {['All Trades', 'Beauty', 'Computer/IT', 'Mechanical', 'Secretarial', 'Textiles', 'Other'].map((text, index) => (
              <ListItem button key={text}
                onClick={() => {
                  index === 0 ? handleNavClick("all", 0)
                    : index === 1 ? handleNavClick("beauty", 1)
                      : index === 2 ? handleNavClick("it", 2)
                          : index === 3 ? handleNavClick("mechanical", 3)
                            : index === 4 ? handleNavClick("secretarial", 4)
                              : index === 5 ? handleNavClick("textile", 5)
                              : index === 6 ? handleNavClick("healthcare", 6)
                                : handleNavClick("all", 0)

                }}
                className={isNavActive[index] ? classes.listItemActive : classes.listItem}
              >
                <ListItemIcon>{
                  index === 0 ? null
                    : index === 1 ? <StarBorderRoundedIcon />
                      : index === 2 ? <ComputerRoundedIcon />
                        
                          : index === 3 ? <SettingsRoundedIcon />
                            : index === 4 ? <AccountBalanceWalletRoundedIcon />
                              : index === 5 ? <EcoRoundedIcon />
                              : index === 6 ? <FavoriteBorderRoundedIcon />
                                : null}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          
          {/* download csv btn */}
          <div style={{ position: 'fixed', bottom: 0, left: 10 }}>
            <Button
              variant="contained"
              style={{ width: 180 }}
              className={`${classes.headerButton} ${classes.headerButton_pri}`}
              onClick={() => {
                var dt = new Date();
                var suffix = (dt.getDate() + "-" + dt.getMonth() + 1 + "-" + dt.getFullYear())
                exportTableToCSV(null, 'strivedata' + suffix + '.csv')
              }}>
              Download CSV
            </Button>
          </div>
        </Drawer>

        <main className={classes.content}>
          <Grid container>
            
            <Grid item md={6}>
              <h4 style={{ textAlign: 'center', color: "teal", textTransform: "uppercase" }}>Companies</h4>
              <MapChart
                setOrigMarkers={setCompOrigMarkers}
                setAllCompCount={setAllCompCount}
                setSecCompCount={setSecCompCount}
                tradeBtn={tradeBtn}
              />
            </Grid>
            <Grid item md={6}>
              <h4 style={{ textAlign: 'center', color: "teal", textTransform: "uppercase" }}>Openings</h4>
              <MapChartJobs
                setOrigMarkers={setJobsOrigMarkers}
                setAllJobsCount={setAllJobsCount}
                setSecJobsCount={setSecJobsCount}
                tradeBtn={tradeBtn} />
            </Grid>
            <Grid item md={6}>
              <h4 style={{ textAlign: 'center', color: "teal", textTransform: "uppercase", marginTop: 40 }}>Students</h4>
              <MapChartStudents
                setOrigMarkers={setStudentsOrigMarkers}
                setAllStudentsCount={setAllStudentsCount}
                setSecStudentsCount={setSecStudentsCount}
                tradeBtn={tradeBtn} />
            </Grid>

            {/* Legend */}
            <Grid item md={12}>
              <div className={classes.legendsContainer}>
                <h6 style={{ margin: "5px 0", color: "teal", textTransform: "uppercase" }}>Legend</h6>
                <Typography variant="body2"><span style={{ fontWeight: 600 }}>Ambala+</span> {ambala.join(" + ")}</Typography>
                <Typography variant="body2"><span style={{ fontWeight: 600 }}>Jind+</span> {jind.join(" + ")}</Typography>
                <Typography variant="body2"><span style={{ fontWeight: 600 }}>Bhiwani+</span> {bhiwani.join(" + ")}</Typography>
                <Typography variant="body2"><span style={{ fontWeight: 600 }}>Gurugram+</span> {gurugram.join(" + ")}</Typography>
                <Typography variant="body2"><span style={{ fontWeight: 600 }}>Delhi+</span> {delhi.join(" + ")}</Typography>
              </div>
            </Grid>
          </Grid>
        </main>
      </div>
    </>
  );
};

export default WBDashboard;
