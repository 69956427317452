import React from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import LanguageIcon from '@material-ui/icons/Language';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import { TextField, Button, Typography } from '@material-ui/core';
import QualificationsList2 from './QualificationsList2';
import MobileQualificationList from './MobileQualificationsList.js';
import SearchIcon from '@material-ui/icons/Search';
import AnalyticsHandler from '../../AnalyticsHandler.jsx';

const autocompleteService = { current: null };

var axios = require('axios');

var option_to_add_single =
{
  description: "Near Me",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Near Me",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Near Me"
        }
      ]
  },
  types:
    [
      "establishment", "political", "geocode"
    ]
}

var option_to_add_anywhere =
{
  description: "Anywhere/ Work-from-Home",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Anywhere/ Work-from-Home",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Anywhere/ Work-from-Home"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

var option_to_add =
{
  description: "Near Me",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Near Me",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Near Me"
        }
      ]
  },
  types:
    [
      "establishment", "political", "geocode"
    ]
}

var optionAssociatedWithWfh =
{
  description: "Anywhere/ Work-from-Home",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Anywhere/ Work-from-Home",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "wfh"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

function change_display(address) {
  var option_to_display =
  {
    description: address,
    matched_substrings: [
      {
        length: 2,
        offset: 0,

      }],
    structured_formatting:
    {
      main_text: address,
      main_text_matched_substrings:
        [{
          length: 2,
          offset: 0
        }
        ],
      terms:
        [
          {
            offset: 0,
            value: address
          }
        ],
      types:
        [
          "locality", "political", "geocode"
        ]
    }
  }

  return option_to_display;

}

const filter = createFilterOptions();

const SearchBarWrapper = (props) => {
  const { classes, setTopCoursesList, Roles } = props;

  const [value, setValue] = React.useState(null);
  // const [Roles, setRoles] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [current_latitude, setLatitude] = React.useState(null);
  const [current_longitude, setLongitude] = React.useState(null);
  const [current_role, setCurrentRole] = React.useState('');
  const [secondaryRoles, setSecondaryRoles] = React.useState([]);
  const [searchedRole, setSearchedRole] = React.useState('');
  const [qualifications, setQualifications] = React.useState([]);
  const [autoQualifications, setAutoQualifications] = React.useState([]);
  const [isAnyQualCatChecked, setIsAnyQualCatChecked] = React.useState({});
  const [job_role_id, setJobRoleId] = React.useState(null);
  const [flag_your_location, setFlagYourLocation] = React.useState(false);
  const [preferredQualsArray, setPreferredQualsArray] = React.useState([]);
  const [geographical_type, setGeographicalType] = React.useState(null);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const history = useHistory();
  const ref_role = React.useRef(null);
  const locRef = React.useRef(null);
  const [isNearMeResultLoading, setIsNearMeResultLoading] = React.useState(false);
  const [initialRender, setInitialRender] = React.useState(true);

  const [triggerClick, setTriggerClick] = React.useState(false);
  const handleSearchClick = () => {

    AnalyticsHandler.track('LandingDiscovery_Search_btnClick');

    let role = document.getElementById("combo-box").value;
    setSearchedRole(role);

    if (role.toLowerCase() !== "any") {
      //do a custom role string search through the list of roles
      const matchedRolesArray = Roles.filter(item =>
        item['title'] && item['title'].toLowerCase().includes(role.toLowerCase())
      );
      setSecondaryRoles(matchedRolesArray);
    } else {//do not do a custom role string search through the list of roles if role is the same as any
      setSecondaryRoles([]);
    }

    if (role === '') {
      ref_role.current.click();
      return;
    }
    setTriggerClick(prev => !prev);
  }
  // console.log(inputValue)

  const onRoleInputChangeHandler = (event, newInputValue, reason) => {
    //setCurrentRole(newInputValue);
    if (!isSearchActive) setIsSearchActive(true);
  }

  const executeOpenLoc = () => {
    if (document.getElementById("google-map").value === "") {
      locRef.current.click();
    }
  }

  React.useEffect(() => {
    const triggeredSearchClick = () => {
      if (searchedRole === '') {
        ref_role.current.click();
      } else {
        //chaned pathname to include role and location as parameters so that URL can be shared

        history.push({
          pathname: (inputValue === "Anywhere/ Work-from-Home" || inputValue === "") ? `/discover/${searchedRole.split(" ").join("-")}/anywhere` : `/discover/${searchedRole.split(" ").join("-")}/${inputValue.split(" ").join("-").replaceAll("/", "_")}`,
          state: {
            // role: current_role,
            role: searchedRole,
            secondaryRoles: secondaryRoles,
            lat: current_latitude,
            long: current_longitude,
            loc: inputValue,
            qualifications: qualifications,
            isAnyQualCatChecked: isAnyQualCatChecked,
            job_role_id: job_role_id,
            preferredQualsArray: preferredQualsArray,
            flag_your_location: flag_your_location,
            geographical_type: geographical_type
          }
        });
      }
    }
    if (qualifications.length !== 0) {
      initialRender ? setInitialRender(false) : triggeredSearchClick()
    }

  }, [searchedRole])
  // console.log(autoQualifications)
  React.useEffect(() => {
    const searchClick = () => {
      if (searchedRole === '') {
        ref_role.current.click();
      } else {
        //console.log("send autoqualifications:" + autoQualifications)
        history.push({
          pathname: (inputValue === "Anywhere/ Work-from-Home" || inputValue === "") ? `/discover/${searchedRole.split(" ").join("-")}/anywhere` : `/discover/${searchedRole.split(" ").join("-")}/${inputValue.split(" ").join("-").replaceAll("/", "_")}`,
          state: {
            // role: current_role,
            // role: searchedRole,
            secondaryRoles: secondaryRoles,
            lat: current_latitude,
            long: current_longitude,
            loc: inputValue,
            qualifications: autoQualifications,
            isAnyQualCatChecked: isAnyQualCatChecked,
            flag_auto_qualifications: true,
            job_role_id: job_role_id,
            preferredQualsArray: preferredQualsArray,
            flag_your_location: flag_your_location,
            geographical_type: geographical_type
          }
        });
      }
    }
    initialRender ? setInitialRender(false) : searchClick();
  }, [autoQualifications])

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([option_to_add_anywhere, option_to_add_single])
      return undefined;
    }

    if (inputValue === "Anywhere/ Work-from-Home") {
      setOptions([option_to_add_single])
      return undefined;
    }

    let wfhTerms = ['wfh', 'work ', 'work from', 'work from home', 'workfromhome', 'anywhere', 'remote'];
    if (wfhTerms.includes(inputValue.toLowerCase())) {
      setOptions([optionAssociatedWithWfh]);
      return undefined;
    }

    //  location: {lat: 27.3868934, lng: 80.1040091},
    //             radius: 400000  
    //  types: ['(cities)'],

    fetch({
      input: inputValue,
      componentRestrictions: { country: "in" }
    }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          // if (inputValue == "") {console.log("3")
          //   results.unshift(option_to_add, option_to_add_anywhere);
          // }
          // if (inputValue == 'Anywhere/ Work-from-Home') {console.log("4")
          //   results = [option_to_add, option_to_add_anywhere];
          // }

          // let wfhTerms = ['wfh', 'workfromhome', 'remote']
          // if (wfhTerms.includes(inputValue)) {
          //   console.log("5")
          //   results = [optionAssociatedWithWfh];
          // }


          if (flag_your_location) {
            results = [option_to_add_anywhere]
          }

          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);



  const SearchButton = () => {
    return <>
      <SearchIcon fontSize="large" className={classes.desktopSearchIcon} />
      <span className={classes.mobileSearchIcon}>Search</span>
    </>
  }
  return (
    <>
      <div className={classes.searchBarWrapper}>
        <Autocomplete
          id="combo-box"
          disableClearable
          openOnFocus
          freeSolo
          ref={ref_role}
          options={Roles}
          onChange={(event, newValue) => {
            event.preventDefault();
            executeOpenLoc();
            if (newValue) {
              setJobRoleId(newValue.job_role_id);
              setPreferredQualsArray(newValue.qualification_id);
              setCurrentRole(newValue.title);
              setIsSearchActive(true);
            }
          }}
          onInputChange={onRoleInputChangeHandler}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            return option.title
          }
          }
          style={{ flex: 1 }}
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}
          renderInput={(params) => <TextField {...params} label="Role*" variant="outlined" placeholder="What position are you hiring for?"
            InputLabelProps={{
              shrink: true,
            }} />}
          getOptionDisabled={(option) => option === Roles[0]} //disabled when first option is the instruction title
          renderOption={(option) => {
            if (option) {
              return (
                <Grid container alignItems="flex-start" direction="column">
                  {option.title === "ب" ?
                    //add instruction title at the top of dropdown
                    (<Grid container alignItems="flex-start" style={{ opacity: 1 }}>
                      <Grid item style={{ padding: '0 0 10px' }}>
                        <Typography variant="body2" color="textSecondary" style={{ opacity: 1 }}>
                          Type job role or select 👇
                        </Typography>
                      </Grid>
                    </Grid>
                    )
                    : (<Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.title} </Typography>) // add options in the list
                  }

                </Grid>
              )
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            function getInputInList(n) {
              if (n.title.toLowerCase() === params.inputValue.toLowerCase()) {
                return 1
              } else {
                return 0
              }
            }
            let inputIndex = Roles.findIndex(getInputInList);
            //console.log("inputIndex = " + inputIndex)

            // add new input value at the top of the dropdown list if value is not empty or already exists in list
            if (params.inputValue !== '' && inputIndex === -1) {
              filtered.unshift({
                inputValue: params.inputValue,
                title: `${params.inputValue}`,
              });
            }
            return filtered;
          }}
        />
        <Autocomplete
          id="google-map"
          ref={locRef}
          openOnFocus
          style={{ flex: 1 }}
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popperLocation, listbox: classes.listbox, paper: classes.paperLocation, popupIndicator: classes.popupIndicator }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {

            setFlagYourLocation(false);
            var display = newValue;
            // if geotype is present
            if (newValue && newValue.types && newValue.types[0]) {
              setGeographicalType(newValue.types[0])
            }
            // if location is typed
            if (newValue && newValue.terms) {

              var location = newValue.terms[0].value;

              //  debugger;

              // if (location == "Jaipur") {
              //   setLatitude(26.8854479);
              //   setLongitude(75.6504695);
              //   setValue(newValue);
              // }

              // else

              //   if (location != "Near Me" && location != "Anywhere/ Work-from-Home") {
              //     axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + location + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
              //       if (result) {
              //         setLatitude(result.data.results[0].geometry.location.lat);
              //         setLongitude(result.data.results[0].geometry.location.lng)
              //       }
              //     }).then(() => {
              //       setValue(newValue);
              //     })
              //   }

              if (newValue.place_id) { //place_id used instead of terms as for places like medha newValue.terms[0] returns no results
                if (location !== "Near Me" && location != "Anywhere/ Work-from-Home") {
                  axios.get('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + newValue.place_id + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
                    if (result) {
                      setLatitude(result.data.results[0].geometry.location.lat)
                      setLongitude(result.data.results[0].geometry.location.lng)
                    }
                  }).then(() => {
                    setValue(newValue);
                  })
                }
              }

            }
            // if near me
            if (newValue == option_to_add || newValue == option_to_add_single) {
              setIsNearMeResultLoading(true);
              var lat, lng;

              setValue("");

              navigator.geolocation.getCurrentPosition(function (position) {
                // console.log("Latitude is :", position.coords.latitude);
                // console.log("Longitude is :", position.coords.longitude);
                lat = position.coords.latitude;
                lng = position.coords.longitude;

                setLatitude(lat);
                setLongitude(lng);

                axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + "," + lng + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0')
                  .then((response) => {
                    var address = response.data.results;
                    var address_1 = address[1].formatted_address;
                    display = change_display(address_1);

                    setIsNearMeResultLoading(false);

                  }).then(() => {
                    setValue(display);
                    setFlagYourLocation(true);
                  })
              }, function () { // onerror
                setInputValue("") // to remove current location text from location box on error in geolocation API
                setIsNearMeResultLoading(false);
                // handleSnackBar("You need to enable Location access services from your Browser settings to use this feature.")
              });
            }
            // if anywhere/ work-from-home
            if (newValue == option_to_add_anywhere) {
              setLatitude(null);
              setLongitude(null);
              display = change_display("Anywhere/ Work-from-Home");
              setValue(display);
            }

            // if optionAssociatedWithWfh
            if (newValue == optionAssociatedWithWfh) {
              setLatitude(null);
              setLongitude(null);
              setValue(optionAssociatedWithWfh);
            }
          }
          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (newInputValue === "") {
              setValue("");
              setLatitude(null);
              setLongitude(null);
              setFlagYourLocation(false);
            }
            if (newInputValue.length === 1) { //so that if after clicking near me the user selects the input and writes a letter, the map location options should show up without pressing the backspace
              setFlagYourLocation(false);
            }
          }}

          renderInput={(params) => (
            <TextField {...params} label="Work Location" variant="outlined" placeholder="Start typing. e.g. Hazratganj, Lucknow" fullWidth InputLabelProps={{
              shrink: true,
            }} />
          )}
          renderOption={(option) => {
            if (option.structured_formatting) {

              const matches = option.structured_formatting.main_text_matched_substrings;

              if (matches) {
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                  <Grid container alignItems="flex-start" direction="column">
                    {option.structured_formatting.terms && option.structured_formatting.terms[0].value === "Anywhere/ Work-from-Home" &&
                      (<Grid container alignItems="flex-start">
                        <Grid item style={{ padding: '0 0 10px' }}>
                          <Typography variant="body2" color="textSecondary">
                            Or Choose
                          </Typography>
                        </Grid>
                      </Grid>
                      )
                    }
                    <Grid container alignItems="flex-start">
                      {/* {option.description === "Current Location" ?
                            <Grid item>
                              <GpsFixedRoundedIcon className={classes.gpsIcon} />
                            </Grid>
                            :
                            <Grid item>
                              <LocationOnIcon className={classes.icon} />
                            </Grid>
                          } */}

                      {option.description === "Anywhere/ Work-from-Home" &&
                        (<Grid item>
                          <LanguageIcon className={classes.gpsIcon} />
                        </Grid>)}
                      {option.description === "Near Me" &&
                        (<Grid item>
                          <GpsFixedRoundedIcon className={classes.gpsIcon} />
                        </Grid>)}
                      {option.description !== "Anywhere/ Work-from-Home" && option.description !== "Near Me" &&
                        (<Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>)}



                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: option.structured_formatting.main_text === "Detect Current Location" ? 'black' : '#333', fontSize: 14 }}>
                            {part.text}
                          </span>
                        ))}
                        {option.description === "Near Me" ? null
                          : <Typography variant="body2" color="textSecondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            }
          }
          }
        />

        {window.innerWidth <= 600 ? <MobileQualificationList
          searchedRole={searchedRole}
          triggerClick={triggerClick}
          qualifications={qualifications}
          setLoginQualifications={setQualifications}
          setAutoQualifications={setAutoQualifications}
          setIsAnyQualCatChecked={setIsAnyQualCatChecked}
          setTopCourses={setTopCoursesList}
          classes={classes}
        >
        </MobileQualificationList> :
          <QualificationsList2
            searchedRole={searchedRole}
            triggerClick={triggerClick}
            qualifications={qualifications}
            setLoginQualifications={setQualifications}
            setAutoQualifications={setAutoQualifications}
            setIsAnyQualCatChecked={setIsAnyQualCatChecked}
            setTopCourses={setTopCoursesList}
            classes={classes}
          >
          </QualificationsList2>
        }
        <Button id="home_search_btn_id" size="small" variant="contained" className={isSearchActive ? classes.searchBarIconButton_Active : classes.searchBarIconButton} onClick={() => { handleSearchClick() }} disabled={isNearMeResultLoading}>
          {isNearMeResultLoading ?
            <div style={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#fff' }} size={30} /></div>
            : <SearchButton />
          }
        </Button>
      </div>
    </>
  )
}

export default SearchBarWrapper