import { createMuiTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';

export default createMuiTheme({
    palette: {
        primary: { // works
          main: '#55c6a9',
          contrastText: '#fff',
        },
        secondary: { // works
          main: '#fab116',
          contrastText: '#fff',
        },

    },
    overrides: {
        // Style sheet name ⚛️
        MuiStepLabel: {
          // Name of the rule
          labelContainer: {
            // Some CSS
            color: 'white',
            backgroundColor: teal['A400'], 
          },
        },
      },
      typography: {
        fontFamily: [
          'Roboto',
          'Arial',
          'sans-serif',
        ].join(','),
      },
});