import React from 'react';
import Button from '@material-ui/core/Button';


const NoMoreResults= (props) => {

return(
<div style = {{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
      <div>
      <br />
      <br />
      <br />      
      No more Meet users with your chosen role around <b>your location</b>. Go back or Search Anywhere. 
      <br/><br/>
      </div>
      <Button variant="outlined" onClick = { () => { 
      //locationRef.current.click();
      var x = document.getElementsByClassName("MuiAutocomplete-clearIndicator");
      x[0].click();
      // locationRef.current.click();
     // document.getElementById("google-map").value = "";
     // setLocationValue("Any Location / WFH");

      var t = setTimeout(function(){props.searchRef.current.click(); clearTimeout(t); }, 500);

      }}>Search Anywhere</Button>
      <br />
      <br />
      < br/>

      </div>
)
}

export default NoMoreResults;