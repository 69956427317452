import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { NavigateBefore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import City_Placeholder from "../assets/City_Placeholder.png"
import Role_placeholder from "../assets/Role_placeholder.png"
import AnalyticsHandler from '../AnalyticsHandler';
import ribbon from '../assets/homepage/bg-ribbon.svg';


const useStyles = makeStyles((theme) => {
    return ({
        sliderContainer: {
            position: 'relative',
            overflow: 'hidden',
            zIndex: -1,
            '@media (max-width: 600px)': {
                height: '170px !important'
            }
        },
        sliderContainerDiv: {
            position: 'absolute',
            transition: '0.5s ease',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        },
        sliderNavBtn: {
            position: ' absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            borderRadius: '50%',
            border: '1px solid #14967B',
            padding: '4px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'whitesmoke',
            zIndex: '99',
            cursor: 'pointer',
            '@media (max-width: 600px)': {
                display: 'none !important'
            },
        },
        next: {
            right: '1%',
        },
        prev: {
            left: '0%'
        },
        sliderItems: {
            borderRadius: '15px',
            flexShrink: 0,
            marginRight: '2em',
            position: 'relative',
            // margin: '0 1em',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            // '&::before': {
            //     content: '" "',
            //     position: 'absolute',
            //     display: 'block',
            //     zIndex: '99',
            //     width: '100%',
            //     height: '100%',
            //     backgroundColor: 'rgba(0, 0, 0, 0.25)',
            //     borderRadius: '15PX'
            // },
            '&:hover': {
                cursor: 'pointer'
            },
            // '&:nth-child(odd)': {
            //     backgroundColor: '#FDEBB4',
            // },
            // '&:nth-child(even)': {
            //     backgroundColor: '#B9E4D9',
            // },
            '@media (max-width: 1398px)': {
                marginRight: '2em',
            },
            '@media (max-width: 1350px)': {
                marginRight: '1.8em',
            },
            '@media (max-width: 1270px)': {
                marginRight: '1.7em',
            },
            '@media (max-width: 1230px)': {
                marginRight: '1.5em',
            },
            '@media (max-width: 600px)': {
                height: '130px !important',
                width: '130px !important',
                marginRight: '1em',
            }
        },
        sliderItemsQual: {
            borderRadius: '15px',
            flexShrink: 0,
            position: 'relative',
            marginRight: '2em',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            textAlign: 'center',
            padding: '1em 1em 1em 2em',
            // backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            // paddingTop: '2em',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            border: '1px solid whitesmoke',
            '&:hover': {
                cursor: 'pointer'
            },
            '@media (max-width: 1398px)': {
                marginRight: '1.8em',
            },
            '@media (max-width: 1230px)': {
                marginRight: '1.5em',
            },
            '@media (max-width: 600px)': {
                padding: 0,
                marginRight: '1em',
                width: '120px !important',
                height: '120px !important',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px'
            },
        },
        qualImg: {
            borderRadius: '50%',
            border: '1px solid #fff',
            '& img': {
                borderRadius: '50%'
            },
            '@media (max-width: 600px)': {
                '& img': {
                    width: '50px !important',
                    height: '50px !important'
                },
            },
        },
        qualSliderContent: {
            color: '#333'
        },
        sliderContent: {
            // margin: '1em 2em !important',
            // position: 'absolute',
            // zIndex: '99',
            fontSize: '1.1em !important',
            '@media (max-width: 1330px)': {
                textShadow: 'none !important',
                fontWeight: '400 !important',
                fontSize: '1em'
            },
        },
        badge: {
            background: `url(${ribbon})`,
            backgroundPosition: 'center !important',
            backgroundRepeat: 'no-repeat !important',
            backgroundSize: 'cover !important',
            width: '60%',
            height: '2.2em',
            position: 'absolute',
            zIndex: 99,
            top: '10%',
            left: '-5.8%',
            paddingLeft: '1em',
            paddingBottom: '3px',
            display: 'flex',
            alignItems: 'center',
            '@media (max-width: 1330px)': {
                height: '2em',
            },
            '@media (max-width: 600px)': {
                position: 'relative',
                zIndex: 99,
                bottom: '-15%',
                position: 'absolute',
                width: '100%',
                backgroundColor: '#fff',
                padding: '9px',
                top: 'initial',
                left: 'initial !important',
                height: '40px',
                background: 'none',
                justifyContent: 'center',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                borderRadius: '0 0 6px 6px',
                '& p': {
                    color: '#333 !important'
                },
                fontSize: '12px'
            },
            '@media (max-width: 1380px)': {
                left: '-5.5%'
            },
            '@media (max-width: 1350px)': {
                left: '-5%'
            },
            '@media (max-width: 1332px)': {
                left: '-5%'
            },
        },
        roles: {
            position: 'relative',
            zIndex: 99,
            bottom: '-15%',
            position: 'absolute',
            width: '100%',
            backgroundColor: '#fff',
            padding: '9px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            borderRadius: '0 0 6px 6px',
            '& p': {
                color: '#333 !important'
            },
            '@media (max-width: 600px)': {
                height: '40px !important'
            },
            fontSize: '12px'
        },
        responsiveSliderImg: {
            '@media (max-width: 600px)': {
                width: '150px !important',
                height: '150px !important',
                borderRadius: '6px !important'
            },
        }
    });
});

function Slider(props) {
    const classes = useStyles();
    const [position, setPosition] = React.useState(1);
    const [counter, setCounter] = React.useState(0);
    const history = useHistory();

    const navigateNextItem = (item) => {
        // if (item === 'location') {
        //     console.log(counter)
        //     setCounter(prev => prev + 1);
        //     counter === 1 ? setPosition(prev => prev - 51) : setPosition(prev => prev - 101)
        // } else if (item === 'role') {
        //     setCounter(prev => prev + 1);
        //     counter === 1 ? setPosition(prev => prev - 51) : setPosition(prev => prev - 101);
        // }
        setCounter(prev => prev + 1);
        counter === 1 ? setPosition(prev => prev - 51) : setPosition(prev => prev - 101);
    }

    const navigatePrevItem = (item) => {
        // if (item === 'location') {
        //     setCounter(prev => prev - 1);
        //     counter === 2 ? setPosition(prev => prev + 51) : setPosition(prev => prev + 101)
        // } else if (item === 'role') {
        //     setCounter(prev => prev - 1);
        //     counter === 2 ? setPosition(prev => prev + 51) : setPosition(prev => prev + 101);
        // }
        setCounter(prev => prev - 1);
        counter === 2 ? setPosition(prev => prev + 51) : setPosition(prev => prev + 101);
    }

    const searchApplicants = (item, query) => {
        //console.log(item, query)
        var newPath;
        if (item === 'location') {
            newPath = `/discover/any/${query}`
        } else if (item === 'role') {
            newPath = `/discover/${query}/anywhere`
        }
        history.push({
            pathname: newPath
        })
    }

    // qual loic
    // remove duplicates
    let newArray = props.courses && props.courses.filter((item, i, array) =>
        array.findIndex(t => t.qualification_id == item.qualification_id) == i
    )

    const groupByCat = (qualifications = []) => {
        let result = [];
        result = qualifications.reduce((r, a) => {
            if (a.course_type !== 'Ph.D' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
                r[a.course_type] = r[a.course_type] || [];
                r[a.course_type].push(a);
            }
            return r;
        }, Object.create(null));
        return result;
    };

    let newHashMap = groupByCat(newArray);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
        return evt.touches
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    };

    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                if (props.name === 'course') {
                    position > -300 ? setPosition(p => p - 200) : null
                } else {
                    position > -1100 ? setPosition(p => p - 120) : null
                }
            } else {
                /* left swipe */
                if (props.name === 'course') {
                    position < 1 ? setPosition(p => p + 200) : null
                } else {
                    position < 1 ? setPosition(p => p + 120) : null
                }
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
                console.log('down swipe')
            } else {
                /* up swipe */
                console.log('up swipe')
            }
        }
        /* reset values */
        xDown = null;
        yDown = null;
    };
    const isMobile = window.innerWidth <= 600 ? true : false


    return (
        <>
            <Grid container justifyContent='space-between' wrap='nowrap' className={classes.sliderContainer} style={{ height: props.containerHeight ? props.containerHeight : props.height }}  >
                <div
                    className={classes.sliderContainerDiv}
                    style={{ left: isMobile ? `${position}px` : `${position}%` }}
                    onTouchStart={(evt) => {
                        handleTouchStart(evt)

                    }}
                    onTouchMove={(evt) => {
                        handleTouchMove(evt)
                    }}
                >
                    {props.data.map((item, index) => {
                        return (
                            <Grid
                                item
                                key={index}
                                className={props.isQual ? classes.sliderItemsQual : classes.sliderItems}
                                style={{
                                    width: props.width,
                                    height: props.height,
                                    flexDirection: 'column'
                                }}
                                name={!props.isQual ? item[props.title] : item.course_name}
                                onClick={() => {
                                    AnalyticsHandler.track(props.segmentEvent)
                                    if (props.isQual) {
                                        let qualArr = [];
                                        newHashMap[item[props.title]].map((key_course) => {
                                            qualArr.push({ tag_name: key_course['course_name'], tag_spl: key_course['specialization_name'], tag_id: key_course['qualification_id'], tag_abb: key_course['course_abbreviation'], tag_courseType: item[props.title] });
                                        })

                                        let newPath = `/discover/any/anywhere`
                                        history.push({
                                            pathname: newPath,
                                            state: {
                                                qualifications: qualArr,
                                            }
                                        })
                                    } else {
                                        searchApplicants(props.name, item[props.title]);
                                    }
                                }
                                }
                            >
                                {/* Added slider image in case of location & roles only */}
                                {!props.isQual && <img
                                    className={classes.responsiveSliderImg}
                                    alt={`${item[props.title]} ${props.name === 'location' ? 'city' : 'role'}`}
                                    width={350}
                                    height={props.height}
                                    style={{ borderRadius: '15px' }}
                                    src={item.illustration_image ? item.illustration_image : (props.name === 'location' ? City_Placeholder : Role_placeholder)}
                                />}

                                {/* Add img for qualification */}
                                {props.isQual && <div className={classes.qualImg}>
                                    <img src={item.illustration_image} width={80} height={80} alt={item.title} />
                                </div>}

                                {/* Add ribbon image as bg for location name in desktop version */}
                                <div className={props.name === 'location' ? classes.badge : props.name === 'role' ? classes.roles : ''}>
                                    <Typography variant="body2"
                                        style={{ color: !props.isQual ? '#fff' : '', fontSize: props.isQual ? '1em' : '', marginTop: '6px', fontWeight: props.isQual ? 600 : 500, marginBottom: props.isQual ? '5px' : '', textShadow: props.name === 'location' ? '2px 2px 3px rgba(0,0,0,0.3)' : '' }}
                                        className={!props.isQual ? classes.sliderContent : classes.qualSliderContent}
                                    >
                                        {item[props.title]}
                                    </Typography>
                                </div>

                                {/* <Typography variant="body2" style={{ color: !props.isQual ? '#fff' : '', fontSize: props.isQual ? '1em' : '' }} className={!props.isQual ? classes.sliderContent : ''} >{item[props.title]}</Typography> */}
                                {props.isQual && <Typography style={{ fontSize: '1.1em' }} variant="body2">{item.course_name}</Typography>}
                            </Grid>
                        )
                    })}
                </div>
                {!props.isQual && <Typography component='span' className={`${classes.sliderNavBtn} ${classes.next}`} style={{ display: counter > props.maxCount ? 'none' : 'flex' }}
                    onClick={() => navigateNextItem(props.name)}
                >
                    <NavigateNextIcon fontSize='large' color="primary" />
                </Typography>}
                <Typography component='span' className={`${classes.sliderNavBtn} ${classes.prev}`} style={{ display: counter < props.minCount ? 'none' : 'flex' }}
                    onClick={() => navigatePrevItem(props.name)}
                >
                    <NavigateBefore color="primary" />
                </Typography>
            </Grid>
        </>
    )
}

export default Slider