/* eslint-disable react/jsx-filename-extension */
/* 
	This component will render on /reset/<email>/<token>
	User will come to this page from the link in email to reset password.
	After coming to this page, user will enter the new password and confirm password.
	When user click on button to chnage the password, we perform validation
	for email and password. After all the validations, we send a request to change
	the password and after successful password change, we show a countdown to redirect user to
	home page and meanwhile sign in the user and show a copy password button.

 */
import React, { useState } from 'react';
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { Alert } from '@material-ui/lab';
import { Redirect, useHistory } from 'react-router-dom';
import {
  CheckCircleOutline,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import customTheme from '../../style/JobAppTheme';
import useStyles from './styles';
import resetImage from '../../assets/images/reset_image.jpg';
import logo from '../../assets/logo_header.png';
import ApiHandler from '../../ApiHandler';
import AnalyticsHandler from '../../AnalyticsHandler';

export default function ResetPassword({ match }) {
  const classes = useStyles();
  const { email, token } = (match && match.params) || {};
  const [password, setPassword] = useState({});
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const INTERVAL_TIMEOUT = 1000;
  const REDIRECT_COUNTDOWN_TIME = 7;
  const history = useHistory();
  const AFTER_LOGIN_REDIRECT_PATH = '/';

  if (!email || !token) {
    return <Redirect to="/error" />;
  }

  document.body.style.overflow = 'hidden';

  function singinUser(userEmail, userPassword) {
    return new Promise((resolve, reject) => {
      ApiHandler.signIn(userEmail, userPassword, (res) => {
        if (res.status === 200) {
          AnalyticsHandler.identify();
          localStorage.setItem('company_id', res.data.company_id);
          localStorage.setItem('company_email_id', res.data.company_email);
          localStorage.setItem('company_name', res.data.company_name);
          localStorage.setItem('employer_name', res.data.employer_name);
          localStorage.setItem('is_logged_in', true);
          resolve(res);
        } else {
          reject(res.data.message);
        }
      });
    });
  }

  function startRedirection() {
    const intervalId = setInterval(() => {
      setRedirectTimer((prev) => {
        if (prev === null) {
          return REDIRECT_COUNTDOWN_TIME;
        }
        const prevDecrement = prev - 1;
        if (prevDecrement < 0) {
          clearInterval(intervalId);
          history.replace(AFTER_LOGIN_REDIRECT_PATH);
        }
        return prevDecrement;
      });
    }, INTERVAL_TIMEOUT);
  }

  async function handleResetPassword() {
    if (password.new_password !== password.confirm_password) {
      setShowError('Your passwords don’t match. Please try again.');
    } else {
      const CHANGE_PASSWORD_DATA = {
        company_email: email,
        new_password: password.new_password,
        forgetpassword_token: token,
      };
      try {
        const { data } = await ApiHandler.resetPassword(CHANGE_PASSWORD_DATA);
        switch (data.statusCode) {
          case 200:
            setShowSuccess(data.message);
            setShowError(false);
            await singinUser(email, password.new_password);
            startRedirection();
            break;
          case 404:
          case 500:
            setShowSuccess(false);
            setShowError(data.message);
            break;
          default:
            setShowSuccess(false);
            setShowError(data.message || 'Something went wrong');
            break;
        }
      } catch (error) {
        console.log({ error });
        setShowSuccess(false);
        if (error && error.response && error.response.data) {
          setShowError(error.response.data.message || 'Something went wrong');
        } else setShowError(error.message || 'Something went wrong');
      }
    }
  }

  function handlePassword(e) {
    const { name, value } = e.target;
    // eslint-disable-next-line arrow-parens
    setPassword((prev) => ({ ...prev, [name]: value }));
    setShowError(false);
    setShowSuccess(false);
  }

  function handleClickShowPassword() {
    setShowPassword((prev) => !prev);
  }

  function handleClickShowConfirmPassword() {
    setShowConfirmPassword((prev) => !prev);
  }

  function handleCopyPassword() {
    navigator.clipboard.writeText(password.new_password);
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container spacing={0}>
        <Grid className={classes.imgBox} item>
          <img src={resetImage} alt="reset" style={{ height: '100vh' }} />
        </Grid>
        <Grid
          item
          className={`${classes.flexGrow11} ${classes.resetPwdContainer}`}
          style={{ marginLeft: '130px' }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            // alignItems="center"
            className={classes.height100}
          >
            <Grid item>
              <img src={logo} className={classes.logo} alt="" />
              <Collapse in={redirectTimer === null}>
                <Typography variant="h5" gutterBottom>
                  Reset Your Password
                </Typography>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  gutterBottom
                  paragraph
                >
                  Type and confirm a new password to secure your account.
                </Typography>
              </Collapse>
              <Collapse in={showSuccess}>
                <Alert
                  color="info"
                  icon={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <CheckCircleOutline
                      style={{ color: '#14967B' }}
                      fontSize="inherit"
                    />
                  }
                  style={{
                    marginBottom: '20px',
                    color: '#232323',
                    fontSize: '14px',
                  }}
                >
                  {showSuccess}
                </Alert>
              </Collapse>
              <Grid container direction="column">
                <TextField
                  value={email}
                  disabled
                  variant="outlined"
                  classes={{ root: classes.TfRoot }}
                />
                <form>
                  <TextField
                    placeholder="New Password"
                    variant="outlined"
                    classes={{ root: classes.TfRoot }}
                    type={showPassword ? 'text' : 'password'}
                    autoFocus
                    autoComplete={false}
                    onChange={handlePassword}
                    name="new_password"
                    disabled={showSuccess}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            name="new_password"
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                    }}
                  />
                  <Collapse in={redirectTimer === null}>
                    <TextField
                      placeholder="Confirm Password"
                      variant="outlined"
                      type={showConfirmPassword ? 'text' : 'password'}
                      classes={{ root: classes.TfRoot }}
                      style={{ marginBottom: '10px' }}
                      onChange={handlePassword}
                      autoComplete={false}
                      name="confirm_password"
                      disabled={showSuccess}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              name="confirm_password"
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                    />
                  </Collapse>
                </form>
                <Collapse in={showError}>
                  <Typography variant="body2" paragraph color="error">
                    {showError}
                  </Typography>
                </Collapse>
                {showSuccess ? (
                  <Button
                    variant="contained"
                    className={classes.primaryBtn}
                    onClick={handleCopyPassword}
                  >
                    Copy New Password
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.primaryBtn}
                    onClick={handleResetPassword}
                  >
                    Change Password
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Collapse in={redirectTimer !== null}>
                <Typography style={{ marginTop: '20px' }}>
                  Redirecting you to the Discovery page where you can find and
                  Invite jobseekers in{' '}
                  <Typography component="span" color="error">
                    {redirectTimer}
                  </Typography>{' '}
                  seconds.
                </Typography>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
