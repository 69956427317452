import React from 'react';
import { Typography, Grid, FormControl, FormGroup, InputBase } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BubbleChartOutlinedIcon from '@material-ui/icons/BubbleChartOutlined';

function Perks(props) {
  const { classes, values, setValues, benefits, setBenefits } = props;

  const newItem ={
    benefit_name: '',
    illustration_img_url: null,
  };

  const addToList = () => {
    setBenefits([...benefits, newItem]);
  }

  const addNewPerk = () => {
    let addItem = {
      benefit_name: <InputBase type='textarea' size='small' classes={{ root: classes.TaRoot }} multiline rows={2} placeholder='Type here' required onChange={e => newItem.benefit_name = e.target.value} onBlur={addToList} />,
      illustration_img_url: null,
    }
    setBenefits([...benefits, addItem]);
  }

  const handleCheckBoxValue = (e, value) => {
    if (e.target.checked) {
      setValues({ ...values, work_benefits: [...values.work_benefits, value] })
    } else {
      setValues({ ...values, work_benefits: values.work_benefits.filter(item => item !== value) })
    }
  }

  return <Grid item xs={12} className={`${classes.ml1} ${classes.position_relative}`} >
    <Typography gutterBottom variant='h5' className={`${classes.sticky_heading} ${classes.inputHeading}`}>Perks and Benefits </Typography>
    <span className={classes.helper_text}> Choose as many as you like or add new </span>
    <FormControl className={`${classes.benefitsContainer}`}>
      {benefits.map((item, index) => {
        return <FormGroup key={index}
          className={`${classes.benefitsItem} ${classes.flexboxCol}`}
        >
          <input type="checkbox" id={`checkbox-${index}`} checked={values.work_benefits.includes(item.benefit_name)} onChange={(e) => { handleCheckBoxValue(e, item.benefit_name) }} />
          <label htmlFor={`checkbox-${index}`}>{item.illustration_img_url !== null ? <img src={item.illustration_img_url} alt="" style={{ width: '20px' }} /> : <BubbleChartOutlinedIcon fontSize='small' />}
            <span className={classes.labelSpan}>{item.benefit_name}</span>
          </label>
        </FormGroup>
      })}
      <div className={`${classes.benefitsItem} ${classes.flexboxCol}`} onClick={addNewPerk}>
        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#14967B', fontSize: '0.8em', fontWeight: '500' }}><AddIcon fontSize='small' /> Add new</span>
        {/* <p style={{ margin: '0', fontSize: '0.85rem', textAlign: 'center' }}>or press /</p> */}
      </div>
    </FormControl>
  </Grid>;
}

export default Perks;
