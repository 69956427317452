import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PrimaryAppBar from '../../components/JobAppBar';
import SearchBar from './SearchBarInvited';
import { StylesProvider } from "@material-ui/core/styles";
import '../../style/App.css';
import { useHistory } from "react-router-dom";
import global_isSignedIn from '../discovery/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import ApiHandler from '../../ApiHandler';
import InvitedListItem from './InvitedListItem';
import ListLoader from '../discovery/ListLoader';
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from '@material-ui/core/CircularProgress';
import FeedbackAlert from '../../components/FeedbackAlert';
import AnalyticsHandler from '../../AnalyticsHandler';
import { Helmet } from 'react-helmet-async';
import ogImg from '../../assets/og-img.jpg';
import MobileInviteList from './MobileInviteList';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 0px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingTop: 84,
    paddingTop: 74,
    backgroundAttachment: 'fixed',
  },
  heading: {
    margin: '1em 0 1em 0', 
    textAlign: 'center',
    fontSize: '1.3em',
    '@media (max-width: 600px)': {
      display: 'none'
    },
},
  pageTitle: {
    color: '#5BC2A8',
    textAlign: 'center',
    marginBottom: '0.2em',
    fontWeight: 500,
  },
  pageBar: {
    width: 860,
    //width: 772,
    padding: '8px 43px',
    flexBasis: 65,
    //marginTop: 35,
    backgroundColor: "transparent",
    marginTop: 0,
    zIndex: 8,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    '@media (max-width: 600px)': {
      width: '90vw !important',
      padding: '8px 43px 25px 43px',
    },
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em',
    '@media (max-width: 600px)': {
      textAlign: 'center',
      padding: '0 1em',
    },
  },
  relevanceMsgBar: {
    width: 860,
    //flexBasis: 65,
    //marginTop: 25,
    zIndex: 8,
    padding: '0',
    marginLeft: 7,
    marginTop: 10,
    background: 'linear-gradient(89deg, rgb(255 255 255 / 0%) 0%, rgb(230 230 230 / 80%) 50%, rgba(255,255,255,0.1) 100%)',
  },
  subtitle: {
    lineHeight: '2em',
    color: '#797979',
    marginRight: 10,
    fontSize: 14,
    fontWeight: 500,
  },
  listViewWrapper: {
    opacity: 1,
    '&.animate': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
    }
  },
  pageButton: {
    textTransform: "none",
    width: 100,
    height: 35,
    marginTop: 20,
  },
  pageButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  pageButton_sec: {
    backgroundColor: "transparent",
    color: "#14967B",
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
  invitedPageContainer: {
    '@media (max-width: 600px)': {
      width: '90vw !important'
    },
  }
}));

export const DrawerContext = React.createContext({});

export default function Invited(props) {
  const classes = useStyles();
  const [navValue, setNavValue] = React.useState(2);
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [listLoaderRef, setListLoaderRef] = React.useState(null);
  // const [isSignedIn, setIsSignedIn] = React.useState(localStorage.getItem("is_logged_in") || false);
  // global_isSignedIn.isSignedIn = localStorage.getItem("is_logged_in") || false;

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  document.body.classList.remove("login");
  document.body.classList.add('wallpaper--icon');

  const [profileView, setProfileView] = React.useState('pending');
  const handleViewChange = (event, newProfileView) => {
    if (newProfileView !== null) {
      setProfileView(newProfileView);
    }
  };

  //common func to remove localstorage data on session expiry
  const removeLocalStorageOnSessionExpiry = () => {
    localStorage.setItem('company_id', "");
    localStorage.setItem('company_email_id', "");
    localStorage.setItem('company_name', "");
    localStorage.setItem('employer_name', "");
    localStorage.removeItem('is_logged_in');
    setIsSignedIn(false);
    global_isSignedIn.isSignedIn = false;
    //handleAlert("Oops! You've been logged out. Please login again.", 'error');
    //commented out the handlealert call as it is being called on load everytime this page is visited
  }

  // get invited profile list---> starts

  const [invitedProfiles, setInvitedProfiles] = React.useState(null);
  const [jobseekerIDSearched, setJobseekerIDSearched] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [invitedCount, setInvitedCount] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(true);

  const [refreshAfterRemoval, setRefreshAfterRemoval] = React.useState(false);

  const [searchedProfiles, setSearchedProfiles] = React.useState(null);

  // get invited profile list with infinitescroll as the offset changes---> starts
  const fetchMoreData = () => {
    //alert(currentPage + "+" + pageOffset)
    let page = 1;
    page = currentPage + 1;
    setCurrentPage(page);
    setPageOffset((page - 1) * 10);
  }

  React.useEffect(() => {
    // alert(currentPage + "+" + pageOffset)
    const handleMoreInvitedProfiles = () => {
      //alert("pageOffset= " + pageOffset)
      let payload = {
        company_id: localStorage.getItem('company_id'),
        limit: 10,
        offset: pageOffset,
      }

      ApiHandler.getInvitedProfilesList(payload, response => {
        if (response.status == 200) {
          //console.log(response.data[0].invite_data);
          //setInvitedProfiles(response.data[0].invite_data);
          if (invitedProfiles === null) {
            setInvitedProfiles(response.data[0].invite_data);
          }
          else {
            setInvitedProfiles(prevState => prevState.concat(response.data[0].invite_data))
            //console.log(invitedProfiles.concat(response.data[0].invite_data));
          }
          setInvitedCount(response.data[0].totalCount[0].count);
        } else if (response.response) {
          if (response.response.data.message === 'Session Expire') {
            console.log("session got expired");
            removeLocalStorageOnSessionExpiry();
          }
        }
        else {
          console.log("error in getting a successful response for invited jobseeker profiles")
        }
      })
    }

    // if (invitedCount !== null && currentPage > invitedCount / 5) { alert("call hasmore false" + currentPage)
    if (invitedProfiles && invitedProfiles.length >= invitedCount) {
      setHasMore(false);
    } else if (currentPage > 1) {
      //alert(currentPage + "+" + invitedCount + "+" + pageOffset)
      handleMoreInvitedProfiles();
    }
  }, [pageOffset])

  // get invited profile list after every search---> starts
  const trackInitialRenderRef = React.useRef(true);

  React.useEffect(() => {
    if (trackInitialRenderRef.current) {
      trackInitialRenderRef.current = false;
      return;
    }

    const handleInvitedProfiles = () => {
      setIsLoading(true);
      setCurrentPage(1);
      setHasMore(true);

      //alert("jobseeker id detected")
      let payload = {
        company_id: localStorage.getItem('company_id'),
        jobseeker_id: jobseekerIDSearched,
      }

      if (!jobseekerIDSearched) {
        payload = {
          company_id: localStorage.getItem('company_id'),
          limit: 10,
          offset: 0,
        }
      }

      ApiHandler.getInvitedProfilesList(payload, response => {
        if (response.status == 200) {
          if (jobseekerIDSearched) {
            console.log(response.data[0].invite_data);
            setInvitedProfiles(response.data[0].invite_data);
            //setSearchedProfiles(response.data)
          } else if (!jobseekerIDSearched) {
            //console.log(response.data[0].invite_data);
            setInvitedProfiles(response.data[0].invite_data);
            if (response.data[0].totalCount[0]) {
              setInvitedCount(response.data[0].totalCount[0].count);
            }
          }
          setIsLoading(false);
        } else if (response.response && response.response.data && response.response.data.message && response.response.data.message === 'Session Expire') {
          console.log("session expired");
          removeLocalStorageOnSessionExpiry();
        } else {
          console.log("error in getting a successful response for invited jobseeker profiles")
        }
      })
    }
    if (isSignedIn) {
      //alert(isSignedIn)
      handleInvitedProfiles();
    }

  }, [jobseekerIDSearched])

  // get invited profile list on page load and then if signed in---> ends
  React.useEffect(() => {
    const handleInvitedProfiles = () => {
      setIsLoading(true);
      setCurrentPage(1);
      // setHasMore(true);

      let payload = {
        company_id: localStorage.getItem('company_id'),
        limit: 10,
        offset: 0,
      }
      // ApiHandler.getInvitedPageProfileList(payload, response => {
      //   if (response.status == 200) {
      //     setInvitedProfiles(response.data[0].invite_data);
      //     if (response.data[0].totalCount[0]) {
      //       setInvitedCount(response.data[0].totalCount[0].count);
      //     }
      //     setIsLoading(false);
      //   } else if (response.response && response.response.data && response.response.data.message && response.response.data.message === 'Session Expire') {
      //     console.log("session expired");
      //     removeLocalStorageOnSessionExpiry();
      //   } else {
      //     console.log("error in getting a successful response for invited jobseeker profiles")
      //   }
      // })

      ApiHandler.getInvitedProfilesList(payload, response => {
        if (response.status == 200) {
          setInvitedProfiles(response.data[0].invite_data);
          if (response.data[0].totalCount[0]) {
            setInvitedCount(response.data[0].totalCount[0].count);
          } else {
            setInvitedCount(0);
          }
          setIsLoading(false);
        } else if (response.response && response.response.data && response.response.data.message && response.response.data.message === 'Session Expire') {
          console.log("session expired");
          removeLocalStorageOnSessionExpiry();
        } else {
          console.log("error in getting a successful response for invited jobseeker profiles")
        }
      })
    }
    if (isSignedIn) {
      //alert(isSignedIn)
      handleInvitedProfiles();
    }
  }, [isSignedIn, refreshAfterRemoval])
  // get invited profile list on page load and then if signed in---> ends

  // Page Loader starts
  React.useEffect(() => {
    if (isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.add('animate');
    } else if (!isLoading && listLoaderRef && listLoaderRef.current) {
      listLoaderRef.current.classList.remove('animate');
    }
  }, [isLoading, listLoaderRef])
  // Page Loader ends

  //sort bar bg effect on scroll starts
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let scrolling = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 60) {
      scrolling = true
    } else {
      scrolling = false
    }
    if (!scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = 'transparent';
        pageBarRef.current.style.boxShadow = "none";
        pageBarRef.current.style.flexBasis = "65px";
      });
    } else if (scrolling && pageBarRef && pageBarRef.current) {
      window.requestAnimationFrame(() => {
        pageBarRef.current.style.background = '#f9f9f9';
        pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
        pageBarRef.current.style.flexBasis = "50px";
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);
  //sort bar bg effect on scroll ends


  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false) {
      setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    AnalyticsHandler.track('SignIn_View_btnClick_InvitedPage');
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  // drawer context for card view starts
  const setCommand = (command) => {
    setState({ ...state, command: command })
  }

  const initCardDrawerState = {
    command: "",
    setCommand: setCommand
  }

  const [state, setState] = React.useState(initCardDrawerState)

  React.useEffect(() => {
    if (state.command !== "") {
      handleDrawer();
    }
  }, [state])
  // drawer context for card view ends

  return (
    <StylesProvider injectFirst>
      <div className={classes.root} ref={trackInitialRenderRef}>
        <Helmet>
        <title>Jobseekers invited for recruitment process</title>
        <meta name='description' content='Meet job portal enables employers to invite jobseekers whose profiles are liked by them against their posted job roles and positions' />
        <meta name='keywords' content='freshers for placement, job opportunities, open job roles, job search portal' />
        <meta name="robots" content="noindex, nofollow" />
        <link rel='canonical' href='https://employer.meetworks.in/invited' />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content="Meet" />
        <meta itemprop="description" content="Meet job portal enables employers to invite jobseekers whose profiles are liked by them against their posted job roles and positions" />
        <meta itemprop="image" content={ogImg} />

        {/* Open graph / facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://employer.meetworks.in/" />
        <meta property="og:title" content="Jobseekers invited for recruitment process" />
        <meta property="og:description" content="Meet job portal enables employers to invite jobseekers whose profiles are liked by them against their posted job roles and positions" />
        <meta property="og:image" content={ogImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://employer.meetworks.in/" />
        <meta property="twitter:title" content="Jobseekers invited for recruitment process" />
        <meta property="twitter:description" content="Meet job portal enables employers to invite jobseekers whose profiles are liked by them against their posted job roles and positions" />
        <meta property="twitter:image" content={ogImg} />
        </Helmet>
        {/* <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> */}
        <PrimaryAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
        >
        </PrimaryAppBar>
        {isSignedIn
          ?
          <div>
            {/* searchbar */}
            {invitedCount > 0 ?
              <Grid container
                alignItems="center" justifyContent='center' className={classes.pageBar} ref={pageBarRef}>
                <Grid item xs={12} container
                  justifyContent="center"
                  alignItems="center">
                  <SearchBar
                    setJobseekerIDSearched={setJobseekerIDSearched}
                  ></SearchBar>
                </Grid>
              </Grid>
              : null
            }


            {/* {invitedSection} */}
            <div className={`${classes.invitedPageContainer} flex-container-pagination flex-container-pagination-list`}
            >

              {isLoading
                ? <div style={{
                  width: 900, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
                }}>
                  <ListLoader setListLoaderRef={setListLoaderRef} /></div>
                :
                // (!jobseekerIDSearched
                //   ? 
                <div data-classname="pagination" style={{ paddingBottom: 30, marginBottom: '2em' }}>
                   <Typography className={classes.heading} component='h1' variant='h5'>Invited Jobseekers</Typography>
                  {invitedProfiles && invitedProfiles.length > 0 &&
                    <InfiniteScroll
                      dataLength={invitedProfiles.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<div style={{
                        width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
                      }}>
                        <CircularProgress style={{ color: '#ccc' }} />
                      </div>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          {invitedCount} {invitedCount === 1 ? "candidate" : "candidates"} viewed. Discover and invite more candidates to see more.
                        </p>
                      }
                      scrollThreshold={0.7}
                    >
                      {invitedProfiles.map((hit, index) =>
                      // (<InvitedListItem hit={invitedProfiles[index]}
                      //   signInStatus={isSignedIn}
                      //   setIsSignedIn={setIsSignedIn}
                      //   removeLocalStorageOnSessionExpiry={removeLocalStorageOnSessionExpiry}
                      //   key={index}
                      //   setRefreshAfterRemoval={setRefreshAfterRemoval}
                      // />)
                      (window.innerWidth <= 600 ? <MobileInviteList hit={invitedProfiles[index]}
                        signInStatus={isSignedIn}
                        setIsSignedIn={setIsSignedIn}
                        removeLocalStorageOnSessionExpiry={removeLocalStorageOnSessionExpiry}
                        key={index}
                        setRefreshAfterRemoval={setRefreshAfterRemoval}
                      /> :
                      <InvitedListItem hit={invitedProfiles[index]}
                        signInStatus={isSignedIn}
                        setIsSignedIn={setIsSignedIn}
                        removeLocalStorageOnSessionExpiry={removeLocalStorageOnSessionExpiry}
                        key={index}
                        setRefreshAfterRemoval={setRefreshAfterRemoval}
                      />))}
                    </InfiniteScroll>
                  }

                  {invitedProfiles && invitedProfiles.length === 0 &&
                    (<div className={classes.pageInfo}>
                      <Typography className={classes.pageInfoText} variant="h6">You haven’t invited any jobseekers yet.</Typography>
                      <Typography variant="body2">Search Meet users that match your needs and invite them to work with you.</Typography>
                      <Button
                        variant="contained"
                        className={`${classes.pageButton} ${classes.pageButton_pri}`}
                        onClick={() => {
                          history.push({
                            pathname: '/'
                          })
                        }}
                        style={{ width: 200 }}>
                        Discover jobseekers
                      </Button>
                    </div>)
                  }
                </div>

                // : <div data-classname="pagination" style={{ paddingBottom: 30 }}>
                //   {searchedProfiles && searchedProfiles.length > 0 &&
                //     <InfiniteScroll
                //       dataLength={searchedProfiles.length}
                //       next={fetchMoreData}
                //       hasMore={hasMore}
                //       loader={<div style={{
                //         width: '100%', height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
                //       }}>
                //         <CircularProgress style={{ color: '#ccc' }} />
                //       </div>}
                //       endMessage={
                //         <p style={{ textAlign: "center" }}>
                //           <b></b>
                //         </p>
                //       }
                //       scrollThreshold="0.7"
                //     >
                //       {searchedProfiles.map((hit, index) =>
                //       (<InvitedListItem hit={searchedProfiles[index]}
                //         signInStatus={isSignedIn} key={index}
                //       />)
                //       )
                //       }
                //     </InfiniteScroll>
                //   }

                // </div>
                // )
              }
            </div>
          </div>
          : <div className={classes.pageInfo}>
            <Typography className={classes.pageInfoText} component='h1' variant="h6">Sign in to see profiles of jobseekers you've invited.</Typography>
            <Button id="sign_in_from_talentpool_id" variant="contained" className={`${classes.pageButton} ${classes.pageButton_pri}`} onClick={handleDrawer}>
              Sign In
            </Button>
          </div>
        }

        <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>

      </div>
      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />
    </StylesProvider>
  )
}