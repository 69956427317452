import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Chip from '@material-ui/core/Chip';
import myInitObject from '../discovery/location'
import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import FeedbackAlert from '../../components/FeedbackAlert';
import AnalyticsHandler from '../../AnalyticsHandler';
import { checkDateFormat } from '../../Globals';
import EmailIcon from '@material-ui/icons/Email';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import ApiHandler from '../../ApiHandler';
import { RolesNotToBePassedToAlgolia } from '../../Globals';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareOutlined from '@material-ui/icons/ShareOutlined';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: '#555',
        color: '#fff',
        //boxShadow: theme.shadows[1],
        fontSize: 14,
        fontWeight: 400,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 'unset',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: 185,
        marginBottom: '-18px',
        zIndex: -1
    },
    paper: {
        boxSizing: 'border-box',
        padding: theme.spacing(0),
        margin: 'auto',
        maxWidth: 830,
        maxHeight: 380,
        width: '90vw',
        height: 210,
        overflowX: 'hidden',
        overflowY: 'scroll',
        position: 'absolute',
        zIndex: 1,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& .imgPholder': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    leftSection: {
        height: '100%',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
    },
    namePlate: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 16,
        marginRight: 7,
        '@media (max-width: 600px)': {
            marginBottom: '0.5em'
        }
    },
    // RightSection: {
    //     //height: '100%',
    //     position: 'relative',
    //     maxHeight: '100%',
    //     paddingTop: 5,
    //     paddingBottom: 0,
    //     paddingRight: theme.spacing(2),
    //     paddingLeft: theme.spacing(2),
    // },
    image: {
        width: '100%',
        height: 450,
        overflow: 'hidden'
    },
    img: {
        margin: '0',
        display: 'block',
        width: 'auto',
        height: '100%',
        maxWidth: 'unset',
        //position: 'absolute',
    },
    imgPholder: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarWrapper: {
        width: 120,
        height: 150,
        borderRadius: 0,
        //paddingTop: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'hidden',
        margin: 0,
        '&>img': {
            transition: 'opacity 0.25s ease-in-out',
            pointerEvents: "none",
        },
    },
    userDetails: {
        zIndex: 9,
        color: 'rgb(121, 121, 121)',
        padding: '0.5em 0.7em',
    },
    verified: {
        color: '#289e80',
        marginTop: '-20px',
    },
    lastActive: {
        color: '#999',
        fontSize: 12,
    },
    inlineSubtext: {
        color: '#232323',
        fontSize: '13px',
        fontWeight: 300,
    },
    chip: {
        fontSize: '12px',
        fontWeight: 'bold',
        // fontWeight: 500,
        // width: '120px',
        padding: '2px 8px',
        margin: '6px 0 2px 0',
        backgroundColor: '#f5f5f5',
        color: "#797979",
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chipsWrapper: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    buttonSec: {
        flex: 1,
        marginRight: theme.spacing(1),
        textTransform: 'none',
        '& > *': {
            color: '#999'
        },
    },
    profilesList: {
        marginBottom: 30,
        '@media (max-width: 600px)': {
            margin: 0,
            position: 'relative'
        }
    },
    actionBtnsContainer: {
        '@media (max-width: 600px)': {
            borderTop: '2px solid #e7e4e4',
            position: 'absolute',
            bottom: -24,
            width: '80%',
            /* margin-top: 0.5em; */
            marginLeft: '2em',
            paddingTop: '0.5em',
        }
    },
    button: {
        flex: 1,
        textTransform: 'none',
    },
    expTimelineWrapper: {
        width: 600,
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    eduTimelineWrapper: {
        width: 600,
        //marginLeft: '-26px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    timelineArrow: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        background: '#f6fffde3',
        color: '#555',
        position: 'absolute',
        top: 96,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        fontSize: 24,
        '&:hover': {
            background: 'white',
            color: '#5bc2a8',
            boxShadow: '0 0 8px rgba(0,0,0,0.15)',
        },
    },
    timelineArrow_L: {
        left: 10,
    },
    timelineArrow_R: {
        right: 10,
    },
    timelineArrow_Edu_L: {
        left: 10,
        top: 'unset',
        bottom: 40,
    },
    timelineArrow_Edu_R: {
        right: 10,
        top: 'unset',
        bottom: 40,
    },
    timelineList: {
        display: 'flex',
        marginTop: 10,
        marginLeft: '-12px',
        marginBottom: 8,
        fontSize: 12,
        fontWeight: 400,
        color: '#797979',
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            fontSize: 12,
            position: 'relative',
            //width: 400,
            width: 300,
            '& .breakChar': {
                display: 'none',
            },
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 150,
            },
            '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
                display: 'none',
            },
            '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
                //display: 'none',
            },
            '&:last-child > div:nth-child(2) > div:nth-child(2) > span::after': {
                display: 'none',
            },
            '& > div > div:nth-child(2) > span': {
                position: 'relative',
                display: 'flex',
                width: 5,
                height: 5,
                marginBottom: 5,
                borderRadius: 5,
                backgroundColor: '#5bc2a8',
                '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '3px',
                    transform: 'translateY(-50%)',
                    MozTransform: 'translateY(0)',
                    backgroundColor: '#999',
                    width: '72.5px',
                    height: '0.5px',
                },
                '&:before': {
                    right: '5px',
                },
                '&:after': {
                    left: '5px',
                },
            },
            '& > div > div:nth-child(3)': {
                width: '100%',
                textAlign: 'center',
                // transform: 'translateX(-50%)',
                position: 'relative',
                left: '50%',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                padding: '0 10%',
                textOverflow: 'ellipsis',
                lineClamp: 2,
                display: '-webkit-box',
                boxOrient: 'vertical',
                height: 28,
                overflow: 'hidden',
            },
        },
        '& > div:nth-child(1)': {
            '&.withBreak': {
                width: 300
            }
        },
        '& > div:last-child': {
            '&.noBreak': {
                width: 300
            }
        },
        '& > div:last-child': {
            '&.withBreakSameMon': {
                '& > div:nth-child(1) > div:nth-child(2) > span::after': {
                    display: 'none',
                },
            }
        },
        '& > div.withBreak': {
            width: '300px!important',
            '& > div:nth-child(2) > div:nth-child(2) > span::after': {
                display: 'block',
                //backgroundColor: 'red',
                backgroundColor: '#e0e0e0',
            },
            '& .breakChar': {
                display: 'block',
                color: '#b0b0b0',
            },
        },
        '& > div.withBreak + div': {
            '& > div:nth-child(1) > div:nth-child(2) > span::before': {
                //backgroundColor: 'red',
                backgroundColor: '#e0e0e0',
            },
        },
        '& > div.withBreakSameMon': {
            width: '150px!important',
            // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
            //   backgroundColor: 'purple',
            // },
            '& > div:nth-child(1) > div:nth-child(2) > span::after': {
                //backgroundColor: 'purple',
                backgroundColor: '#e0e0e0',
            },
            '&:not(:last-child) .breakChar': {
                display: 'block',
                color: '#b0b0b0',
            },
        },
        '& > div.withBreakSameMon + div': {
            '& > div:nth-child(1) > div:nth-child(2) > span::before': {
                //backgroundColor: 'purple',
                backgroundColor: '#e0e0e0',
            },
            // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
            //   backgroundColor: 'purple',
            // },
        },
        '& > div.noBreakSameMon': {
            width: 150,
        },
        '& > div.noBreak': {
            width: 150,
        },
    },

    timelineListEdu: {
        display: 'flex',
        listStyle: 'none',
        marginTop: 10,
        marginLeft: '-37.3px',
        marginBottom: 8,
        fontSize: 12,
        fontWeight: 400,
        color: '#797979',
        '& > div': {
            // display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'flex-start',
            // fontSize: 12,
            width: 200,
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: 12,
                textTransform: 'capitalize',
                width: 200,
            },
            '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
                display: 'none',
            },
            '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
                display: 'none',
            },
            '& > div > div:nth-child(2) > span': {
                position: 'relative',
                display: 'flex',
                width: 5,
                height: 5,
                marginBottom: 5,
                borderRadius: 5,
                backgroundColor: '#5bc2a8',
                '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '3px',
                    transform: 'translateY(-50%)',
                    MozTransform: 'translateY(0)',
                    backgroundColor: '#999',
                    width: '98px',
                    height: '1px',
                    maxWidth: '98px',
                },
                '&:before': {
                    right: '5px',
                },
                '&:after': {
                    left: '5px',
                },
            },
            '& > div > div:nth-child(3)': {
                width: '100%',
                textAlign: 'center',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                padding: '0 19%',
                textOverflow: 'ellipsis',
                lineClamp: 2,
                display: '-webkit-box',
                boxOrient: 'vertical',
                height: 28,
                overflow: 'hidden',
            },
        },
    },

    timelineDuration: {
        fontSize: 10,
        color: '#797979',
        fontSize: 12,
        fontWeight: 300,
    },
    duration: {
        marginTop: 5,
        fontWeight: 400,
    },
    chiproot: {
        borderRadius: 4,
        height: 24,
    },
    chiplabel: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    bigIconRect: {
        position: 'relative',
        margin: '0 5px 0 0',
        zIndex: 2,
        height: 40,
        width: 80,
        padding: 5,
        borderRadius: '5px',
        // backgroundColor: '#ffffff',
        boxShadow: 'none',
        color: '#14967B',
        border: 'none',
        fontSize: 13,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#b9f0e1',
            //color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    tglBtnLabel: {
        textTransform: 'capitalize',
        color: '#333'
    },
    tglBtnSelected: {
        // backgroundColor: '#14967B!important',
        color: '#ffffff!important',
    },
    saveIcon: {
        height: 30,
        width: 30,
    },
    saveIconRect: {
        height: 16,
        width: 16,
        marginRight: 5,
    },
    infoIcon: {
        height: 20,
        width: 20,
        color: '#555',
    },
    bigIcon: {
        height: 46,
        width: 46,
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        color: '#fab116',
        border: '1px solid #fab116',
        '&:hover': {
            backgroundColor: '#fab116',
            color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paperPopover: {
        padding: theme.spacing(1),
        width: 200,
    },
    dialogBackdropRoot: {
        backgroundColor: "transparent"
    }
}));

var employer_lat_lng = {
    lat: myInitObject.lat,
    lng: myInitObject.long
}

var rad = function (x) {
    return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(employer_lat_lng.lat - p1.lat);
    var dLong = rad(employer_lat_lng.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

export default function MobileDiscoveryList(props) {
    const classes = useStyles();
    // fresher = false;

    var qualifications = props.qualifications;
    //var role = selected_qualifications.role;
    var role = props.role;
    var secondaryRoles = props.secondaryRoles;
    var jobseekerEnteredRoles = props.jobseekerEnteredRoles;
    // console.log(jobseekerEnteredRoles)
    // var job_role_id = selected_qualifications.job_role_id;
    //var job_role_id  = props.job_role_id;
    //var preferredQuals = selected_qualifications.preferredQualsArray;
    var qualifications_to_search = props.hit.user_qualifications;
    var experiences_to_search = props.hit.user_experiences;
    var relevant_quals = false, relevant_to_show_role = false, relevant = false, relevant_role = false, relevant_to_show_quals = false, relevant_all = false;

    var full_name = props.hit.jobseeker_name ? props.hit.jobseeker_name : "";
    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
        initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    var array_name = props.hit.jobseeker_name ? props.hit.jobseeker_name.split(" ") : "";
    var k = 0, name_sentence_case = "";
    for (; k < array_name.length; k++) {
        if (array_name[k])
            name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
    }

    for (; k < experiences_to_search.length; k++) {

        if (RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim())) break; // if role is all or fresher then relevant_role will always be false

        if (experiences_to_search[k].user_post && (role.toUpperCase().trim() == experiences_to_search[k].user_post.toUpperCase().trim())) {
            relevant_role = true;
            break;
        }
        //check if any of the secondary roles exists in the user_post
        if (experiences_to_search[k].user_post && secondaryRoles && secondaryRoles.length !== 0 && secondaryRoles.some(item => item.title.toUpperCase().trim() === experiences_to_search[k].user_post.toUpperCase().trim())) {
            relevant_role = true;
            break;
        }

        //check if any of the secondary roles exists in the user_post
        if (experiences_to_search[k].job_role_display && jobseekerEnteredRoles && jobseekerEnteredRoles.some(item => item.toUpperCase().trim() === experiences_to_search[k].job_role_display.toUpperCase().trim())) {
            relevant_role = true;
            break;
        }
    }

    var geoType = props.geographical_type;
    //console.log(geoType)

    var tagLabelDist = employer_lat_lng.lat && employer_lat_lng.lng && geoType ?
        (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) == 0.0 ? "0" :
            (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1)
        : null;

    var tagLabel = tagLabelDist ?
        geoType === "locality" && tagLabelDist < 60 ? tagLabelDist + " km from city center" :
            geoType !== "locality" && tagLabelDist < 60 ? tagLabelDist + " km from area center" :
                "> 60 km"
        : null;

    const [invitedRoles, setInvitedRoles] = React.useState([]);
    const invitedList = props.inviteList;
    const isInvited = invitedList.some(item => item.jobseeker_id === props.hit.objectID);

    useEffect(() => {
        //alert("invitedList= " + invitedList)
        if (props.signInStatus) {
            //alert("isInvited= " + isInvited)
            setSelected(isInvited);
        }
    }, []);

    //   const [open, setOpen] = React.useState(false);

    const handleUninviteClickOpen = () => {
        setOpen(true);
    };

    const handleUninviteCancelClose = () => {
        setOpen(false);
    };

    const updateInvitedJobRoles = () => {
        ApiHandler.getInvitedJobRoles(company_id, jobseeker_id, response => {
            if (response.status === 200) {
                let jobRoles = response.data && response.data[0] ? response.data[0].job_roles : [];
                setInvitedRoles(jobRoles);
            } else {
                console.log("Oops! Something went wrong. Please try again.")
            }
        })
    }
    const handleInviteClick = () => {
        //AnalyticsHandler.track("discoveryResults_list_InviteUninvite_btnClick");
        if (props.signInStatus) {
            if (selected === true) { //open confirmation dialog if uninvite is clicked
                handleUninviteClickOpen();
                return
            }

            let payload = {
                company_id,
                jobseeker_id,
                job_role,
                location,
                invite_status: !selected,
            }
            setGotResponse(false);
            //alert(props.signInStatus)
            ApiHandler.sendInvite(payload, response => {
                if (response.status == 200) {

                    AnalyticsHandler.track("discoveryResults_list_Invite_success");
                    if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
                        setSelected(true);
                        handleSnackBar('Your invite has been sent successfully. The jobseeker will call you if they are interested to work with you');
                        sessionStorage.setItem('isSnackbarDisplayed', true)
                    }
                    // else if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
                    //   setSelected(false);
                    // } 
                    else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
                        setSelected(true);
                    }
                    // else if (sessionStorage.getItem('isSnackbarDisplayed')) {
                    //   setSelected(prev => !prev); alert("selected= " + selected)
                    // }
                    setGotResponse(true);
                    updateInvitedJobRoles();
                }
                else {
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            if (response.response.data.message === "Session Expire") {
                                props.removeLocalStorageOnSessionExpiry();
                            }
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleAlert(ErrorMessage, 'error');
                }
            })
        } else if (props.signInStatus === false) {
            props.handleDrawer();
        }
    }

    const handleUninviteYesClose = () => {
        AnalyticsHandler.track("discoveryResults_list_Uninvite_btnClick");
        if (props.signInStatus) {
            let payload = {
                company_id,
                jobseeker_id,
                job_role,
                location,
                invite_status: !selected,
            }
            setGotUninviteResponse(false);
            //alert(props.signInStatus)
            ApiHandler.sendInvite(payload, response => {
                if (response.status == 200) {
                    setOpen(false);
                    if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
                        setSelected(false);
                    } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
                        setSelected(false);
                    }
                    setGotUninviteResponse(true);
                    updateInvitedJobRoles();
                }
                else {
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            if (response.response.data.message === "Session Expire") {
                                props.removeLocalStorageOnSessionExpiry();
                            }
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleAlert(ErrorMessage, 'error');
                }
            })
        } else if (props.signInStatus === false) {
            props.handleDrawer();
        }
    }

    const handleProfileImage = (event) => {
        let imageWidth = event.target.width;
        let imageHeight = event.target.height;
        //console.log(imageWidth + " " + imageHeight)
        if (imageWidth / imageHeight <= 190.9 / 302) {
            //event.target.style.height = (302 * (190.9 / imageWidth)) + "px"
            event.target.style.height = 'auto';
            event.target.style.width = '100%';
        }
        event.target.style.opacity = 1
        event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
    }

    const changeStatus = (status) => {
        axios.post(`${BASE_URL}/jobposting/updateApplliedJobStatus`, {
            _id: props.hit._id,
            status: status
        })
            .then(response => {
                if (response.status === 200) {
                    if (status === "Shortlisted") {
                        props.handleSnackBar('Thanks for shortlisting this jobseeker. We will inform them to call you for further process.')
                    } else if (status === "Rejected") {
                        props.handleSnackBar('You have successfully rejected this candidate for the current role.')
                    } else if (status === "Hired") {
                        props.handleSnackBar('Thanks for hiring this jobseeker. We will inform them to call you for further process.')
                    }
                    setApplicantStatus(status);
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    //Shortlist and Hire button functionalities start
    const [applicantStatus, setApplicantStatus] = React.useState(props.hit.status);
    const [selected, setSelected] = React.useState(false);
    const [hireSelected, setHireSelected] = React.useState(props.hit.hired_status);
    const [gotResponse, setGotResponse] = React.useState(true);
    const [gotHireResponse, setGotHireResponse] = React.useState(true);
    const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

    let cid = localStorage.getItem('company_id');
    const [company_id, setCompanyId] = useState(cid);


    const [open, setOpen] = React.useState(false);


    // uninvite button functionality starts

    const [jobseeker_id, setJobseekerId] = useState(props.hit.objectID);
    const [job_role, setJobRole] = useState(props.role);
    const [location, setLocation] = useState(props.sendLocationForInvite);

    const [isRemoved, setIsRemoved] = useState(false);

    const [openUninvite, setOpenUninvite] = React.useState(false);


    // Invite button functionality ends

    const timelineRef = React.useRef(null);
    const expArrowRRef = React.useRef(null);

    let userEduSorted, _userEdu;
    const [userEduResponse, setUserEduResponse] = React.useState(props.hit.user_qualifications);
    const [userEdu, setUserEdu] = React.useState(null);

    let userExpSorted, _userExp, _expMonthsAndExp, _hasOverlapExps;
    const expMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [userExpResponse, setUserExpResponse] = React.useState(props.hit.user_experiences);
    const [userExp, setUserExp] = React.useState(null);
    const [expMonthsAndExp, setExpMonthsAndExp] = React.useState(null);
    const [hasOverlapExps, setHasOverlapExps] = React.useState(false);
    const [fresher, setFresher] = React.useState(null);
    const [expLabel, setExpLabel] = React.useState(null);


    React.useEffect(() => {
        userExpSorted = userExpResponse && userExpResponse.length != 0 ? userExpResponse.sort((a, b) => {
            if (a == {} || b == {})
                return 0;
            if (a.company_starting_date === b.company_starting_date)
                return 0;
            else {
                return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
            }
        }) : null;

        _userExp = userExpSorted
            && userExpSorted.filter((item, i, array) =>
                array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
            )

        _expMonthsAndExp = _userExp && _userExp.map((item, i) => {
            if (item != {}) {
                if (item.company_ending_date && item.company_starting_date) {
                    var startDate = checkDateFormat(item.company_starting_date)
                    var endDate = checkDateFormat(item.company_ending_date)
                    var startDateNext = _userExp[i + 1] ? new Date(_userExp[i + 1].company_starting_date) : null;
                    var a = moment(endDate);
                    var b = moment(startDate);
                    var c = moment(startDateNext);
                    var duration = a.diff(b, 'months');
                    var dd = c.diff(a, 'days');
                    var sameDaysDiff = a.diff(b, 'days')
                    return {
                        months: duration,
                        sameDaysDiff: sameDaysDiff,
                        daysDiff: dd,
                        expStartMon: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(0, 3) : startDate.getMonth(),
                        expEndMon: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(0, 3) : endDate.getMonth(),
                        expStartYear: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(4, 8) : startDate.getFullYear(),
                        expEndYear: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(4, 8) : endDate.getFullYear(),
                        user_post: item.user_post,
                    }
                }
                else {
                    return {
                        months: null,
                        sameDaysDiff: null,
                        daysDiff: null,
                        expEndMon: null,
                        expStartMon: null,
                        expStartYear: null,
                        expEndYear: null,
                        user_post: item.user_post,
                    };
                }
            }
            else {
                return {
                    months: null,
                    sameDaysDiff: null,
                    daysDiff: null,
                    expEndMon: null,
                    expStartMon: null,
                    expStartYear: null,
                    expEndYear: null,
                    user_post: null
                }
            }
        })

        _hasOverlapExps = _userExp && _userExp.length > 0 && _userExp.map((item, i) => {
            if (_expMonthsAndExp[i] && _expMonthsAndExp[i].daysDiff < 0) {
                return { overlap: 'true' }
            } else {
                return { overlap: 'false' }
            }
        });

        setUserExp(_userExp);
        setExpMonthsAndExp(_expMonthsAndExp);
        setHasOverlapExps(_hasOverlapExps);
        //console.log(_userExp, _expMonthsAndExp, _hasOverlapExps)

    }, [userExpResponse])

    React.useEffect(() => {
        if (timelineRef.current && expArrowRRef.current && timelineRef.current.scrollWidth > 612) {
            expArrowRRef.current.style.display = "flex";
        }
    }, [userExp]);

    React.useEffect(() => {
        if (!userEduResponse) return;

        userEduSorted = userEduResponse && userEduResponse.length !== 0 ? userEduResponse.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)) : null;

        _userEdu = userEduSorted ? userEduSorted.filter((item, i, array) =>
            array.findIndex(t => t.user_passing_year && item.user_passing_year && t.course_name && item.course_name && t.user_passing_year == item.user_passing_year && t.course_name == item.course_name) == i
        ) : [];

        setUserEdu(_userEdu);
    }, [userEduResponse])


    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    }
    // snackbar callback ends

    // FeedbackAlert callback starts
    const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
    const [refreshAlert, doRefreshAlert] = React.useState(false);
    const [canAlertOpen, setCanAlertOpen] = React.useState(false);
    const handleAlert = (message, type) => {
        setCanAlertOpen(true);
        setAlertMessage({ message: message, type: type });
        doRefreshAlert(prev => !prev);
    }
    // FeedbackAlert callback ends

    const getinvitedDate = () => {
        let d = props.hit.invite_time;
        d = new Date(d);
        d = moment(d).format("Do MMM");
        return d
    }

    const invitedDate = getinvitedDate();

    return (
        <div className={`listItemWrapper ${classes.profilesList}`}>
            {isRemoved ?
                <Paper className={classes.paper} elevation={3} style={{ height: 150, background: 'rgba(255, 255, 255, 0.5)', position: 'relative' }}>

                    <Grid item xs={12} container direction="row" justifyContent="center"
                        alignItems="center" style={{ height: '100%' }}>
                        <Typography variant="h6" className={classes.namePlate} >
                            <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{name_sentence_case}</span> has been removed from the list of jobseekers you invited for the <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.job_role ? props.hit.job_role.toLowerCase() : ""}</span> role.
                        </Typography>
                    </Grid>

                </Paper>
                :
                <div className={classes.root}>
                    <Paper className={classes.paper} elevation={3}>
                        <Grid item container
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: 30, background: '#ececec', display: 'none' }}
                        > <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                                Invited for <span style={{ color: '#14967B' }}>{props.hit.job_role}</span> on <span style={{ color: '#14967B' }}>{invitedDate}</span>
                            </Typography></Grid>
                        <Grid container direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start" style={{ height: '302', overflow: 'hidden' }}>
                            <Grid item className={classes.leftSection} id="leftSectionId">
                                <Grid item xs container direction="column"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid alignItems='flex-start' item xs container wrap='nowrap' justifyContent="center">
                                        <Grid item xs container direction="column" justifyContent="center"
                                            alignItems="center">

                                            <div className={classes.avatarWrapper}>
                                                {props.hit.user_image_url ?
                                                    <img className={classes.img} alt="candidates avatar" src={props.hit.user_image_url} onLoad={handleProfileImage} style={{ opacity: 0 }} />
                                                    :
                                                    <div className="imgPholder">
                                                        <p style={{
                                                            fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%',
                                                        }}> {initials} </p>
                                                    </div>}
                                            </div>
                                        </Grid>
                                        <Grid item className={classes.userDetails} >
                                            <Grid item xs={12} container direction="row" justifyContent="space-between"
                                                alignItems="flex-start">
                                                <Grid item xs container direction="column" justifyContent="flex-start" alignItems="baseline" style={{ marginBottom: 10 }}>
                                                    <Typography variant="h6" className={classes.namePlate} >
                                                        {name_sentence_case}
                                                    </Typography>
                                                    <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                                                        <span style={{ position: 'relative', marginRight: 8 }}>
                                                            {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                                                        <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                                                            {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                                                        </span>

                                                        {/* {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null} */}
                                                        {/* show distance tag if lat long present & geotype is not state or district */}
                                                        {employer_lat_lng.lat != null && employer_lat_lng.lng != null && geoType && geoType !== "administrative_area_level_1" && geoType && geoType !== "administrative_area_level_2" && geoType !== "colloquial_area" ? <Chip size="small" label={tagLabel} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ margin: '5px 0 0 10px' }} /> : null}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            {userExp && userExp.length > 0 && <Grid style={{ fontSize: '14px', marginBottom: 10 }} item container alignItems='center' wrap='nowrap' >
                                                <WorkIcon style={{ fontSize: '14px', marginRight: 8 }} fontSize="small" className="primaryText" />
                                                <span>
                                                    {userExp && userExp[userExp.length - 1].user_post} - {userExp && userExp[userExp.length - 1].company_name}
                                                </span>
                                            </Grid>}
                                            {userEdu && userEdu.length > 0 && <Grid style={{ fontSize: '14px' }} item container alignItems='flex-start' wrap='nowrap' >
                                                <SchoolRoundedIcon style={{ fontSize: '14px', marginRight: 8 }} fontSize="small" className="primaryText" />
                                                <span>
                                                    {userEdu && userEdu[userEdu.length - 1].course_type}-{userEdu && userEdu[userEdu.length - 1].user_degree} | {userEdu && userEdu[userEdu.length - 1].user_passing_year}
                                                </span>
                                            </Grid>}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>

                            </Grid>

                        </Grid>
                    </Paper>
                </div>}
            {!isRemoved &&
                <Grid item container
                    justifyContent="center"
                    alignItems="flex-end"
                    className={classes.actionBtnsContainer}
                >
                    <ToggleButton
                        // id={props.index}
                        value="check"
                        className={classes.bigIconRect}
                        style={{ marginRight: 10, width: 'auto' }}
                        classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                        // selected={invited}
                        onChange={gotResponse ? handleInviteClick : undefined}
                    >
                        {/* <EmailIcon className={classes.saveIconRect} /> */}
                        {selected ?
                            <CheckIcon className={classes.saveIconRect} />
                            : <EmailOutlinedIcon className={classes.saveIconRect} />
                        }
                        {selected ? "Invited" : "Email Invite"}
                    </ToggleButton>



                    <CopyToClipboard text={'https://mywebsite.meetworks.in' + `/profile/${(array_name[0] ? array_name[0] : "") + "-" + (array_name[1] ? array_name[1] : "") + '-' + props.hit.objectID}`} >
                        <Button
                            className={classes.bigIconRect}
                            classes={{ label: classes.tglBtnLabel }}
                            style={{ width: 'auto' }}
                            onClick={() => {
                                AnalyticsHandler.track("ShareButton_DiscoveryPage_Click");
                                handleSnackBar(`Profile link is copied`)
                            }}
                        ><ShareOutlined className={classes.saveIconRect} /> <span>Share</span></Button>
                    </CopyToClipboard>
                    <Button
                        className={classes.bigIconRect}
                        classes={{ label: classes.tglBtnLabel }}
                        style={{ width: 'auto' }}
                        onClick={() => {
                            AnalyticsHandler.track("InvitePage_candidate_fullProfileBtn");
                            localStorage.setItem('searched_role', props.hit.job_role)
                            window.open(`/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit.objectID}`, "_blank");
                        }}
                    ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> <span>Full Profile</span></Button>
                </Grid>}

            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
            <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
            <div>
                <Dialog
                    open={open}
                    onClose={handleUninviteCancelClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{
                        classes: {
                            root: classes.dialogBackdropRoot
                        }
                    }}
                    transitionDuration={150}
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmation to cancel invite"}</DialogTitle>
                    <DialogContent style={{ height: 100 }}>
                        <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
                            Are you sure you want to cancel your invite to <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span> for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{role}</span>?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
                            Cancel
                        </Button>
                        <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}