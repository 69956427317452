import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import Loader from './Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import Ratings from '../../components/Ratings';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;


export default function Review(props) {
  const { classes, loader, setLoader } = props;
  const [reviews, setReviews] = React.useState([]);
  const [reviewsOffset, setReviewsOffset] = React.useState(10);
  const [totalReviews, setTotalReviews] = React.useState(0);

  React.useEffect(() => {
    setLoader(true);
    axios.get(`${BASE_URL}/companies/getReviews/0/10/${localStorage.getItem('company_id')}`)
      .then(res => {
        setReviews(res.data[0].results);
        res.data[0].results.length !== 0 && setTotalReviews(res.data[0].totalCount[0].count);
        setLoader(false)
      })
  }, []);

  React.useEffect(() => {
    if (reviews.length !== 0) {
      props.setGetReviewLabel('Get more reviews')
    } else {
      props.setGetReviewLabel('Get reviews')
    }
  }, [reviews])

  const fetchMoreReviews = () => {
    setReviewsOffset(p => p + 10);
    axios.get(`${BASE_URL}/companies/getReviews/${reviewsOffset}/10/${localStorage.getItem('company_id')}`)
      .then(res => {
        setReviews([...reviews, ...res.data[0].results]);
      })
  }

  // function getReviewDate(date) {
  //   let d = date
  //   d = new Date(date);
  //   d = (d.getDay() < 10 ? '0' + d.getDay() : d.getDay()) + '/' + (d.getMonth() < 10 ? '0' + d.getMonth() : d.getMonth()) + '/' + d.getFullYear();
  //   return d;
  // }

  return (
    <>
      {!loader ?
        (reviews.length > 0 && <div className={classes.review}>
          <div className={`${classes.my1} ${classes.reviewSections}`}>
            <Typography gutterBottom component='p' variant='h6' className={`${classes.textAlignCenter}`}>How employees feel about your company</Typography>
            <Typography gutterBottom component='p' className={`${classes.fontSize_medium} ${classes.textAlignCenter} ${classes.helperText}`}>Rating submitted by verified employees</Typography>
            <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap' className={classes.my2} >
              <Grid item style={{ width: '60%' }}>
                <Grid item container wrap='nowrap' justifyContent='space-between' alignItems='center'>
                  <Typography className={classes.bold} gutterBottom component='p'>Company का माहौल</Typography>
                  <Ratings selectedValue={props.data.avg_env} justifyContent='flex-end' />
                </Grid>
                <Grid className={classes.my1} wrap='nowrap' item container justifyContent='space-between' alignItems='center'>
                  <Typography className={classes.bold} gutterBottom component='p'>Growth के chances</Typography>
                  <Ratings selectedValue={props.data.avg_growth} justifyContent='flex-end' />
                </Grid>
                <Grid item container wrap='nowrap' justifyContent='space-between' alignItems='center'>
                  <Typography className={classes.bold} gutterBottom component='p'>Salary</Typography>
                  <Ratings selectedValue={props.data.salary_rating} justifyContent='flex-end' />
                </Grid>
              </Grid>
              <Grid item className={`${classes.flexboxCol} ${classes.alignItemsCenter}`} style={{ width: '40%' }}  >
                <Typography component='span' variant='h3'
                  style={{ color: props.data.avg_rating < 1 ? '#CD212A' : props.data.avg_rating >= 1 && props.data.avg_rating < 2 ? '#FF4F58' : props.data.avg_rating >= 2 && props.data.avg_rating < 3 ? '#FAB116' : props.data.avg_rating >= 3 && props.data.avg_rating < 4 ? '#8AA158' : props.data.avg_rating >= 4 && props.data.avg_rating <= 5 ? '#586E25' : '' }}>
                  {props.data.avg_rating}
                </Typography>
                <Typography component='span' variant='body2'>({props.data.number_of_rating} ratings)</Typography>
              </Grid>
            </Grid>
          </div>
          <div className={`${classes.my1} ${classes.reviewSections}`}>
            <Typography gutterBottom component='p' variant='h6' style={{ marginTop: '2em' }} className={`${classes.textAlignCenter}`}>What are they saying?</Typography>
            <Typography gutterBottom component='p' className={`${classes.fontSize_medium} ${classes.textAlignCenter} ${classes.helperText}`}>Reviews submitted by verified employees </Typography>
            <div>
              <InfiniteScroll
                dataLength={reviews.length}
                next={fetchMoreReviews}
                hasMore={reviews.length !== totalReviews}
                loader={<Loader maxWidth='20px' minHeight='5vh' />}
                scrollThreshold={0.8}
              >
                {reviews.filter(item => item.review_data.goodThing).map((item, index) => (
                  <Grid key={index} container justifyContent='space-between' wrap='nowrap' className={`${classes.my1} ${classes.jobseekerReview}`} >
                    <Grid item >
                      <Grid item >
                        <Ratings selectedValue={item.overall_rating} justifyContent='flex-start' />
                      </Grid>
                      {/* <Grid item style={{ margin: '1em 5em 1em 0' }}>
                        <Typography className={`${classes.bold} ${classes.mr2} ${classes.fontSize_medium}`} gutterBottom component='span'>💼 मेरा Role (काम) :</Typography>
                        <Typography gutterBottom component='span'>{item.review_data.jobRole}</Typography>
                      </Grid> */}
                      <Grid item className={classes.employeeReviews}>
                        <Typography className={`${classes.bold} ${classes.mr2} ${classes.fontSize_medium}`} gutterBottom component='span'>🙂 3 बातें जो मुझे अच्छी लगी :</Typography>
                        <Typography gutterBottom component='span'>{item.review_data.goodThing}</Typography>
                      </Grid>
                      <Grid item className={classes.employeeReviews}>
                        <Typography className={`${classes.bold} ${classes.mr2} ${classes.fontSize_medium}`} gutterBottom component='span'>🙁 3 बातें जो मुझे अच्छी नहीं लगी :</Typography>
                        <Typography gutterBottom component='span'>{item.review_data.badThing}</Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid item>
                      <Typography gutterBottom component='p' className={classes.helperText}>{getReviewDate(item.review_date)}</Typography>
                    </Grid> */}
                  </Grid>
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>) : <Loader minHeight='70vh' maxWidth='40px' />}
    </>
  )
}
