import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import Chip from '@material-ui/core/Chip';
import myInitObject from '../discovery/location'
import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import ApiHandler from '../../ApiHandler';
import FeedbackAlert from '../../components/FeedbackAlert';
import global_isSignedIn from '../discovery/global_isSignedIn';
import { useHistory } from 'react-router-dom';
import AnalyticsHandler from '../../AnalyticsHandler';
import { checkDateFormat } from '../../Globals';
import EmailIcon from '@material-ui/icons/Email';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: '#555',
        color: '#fff',
        //boxShadow: theme.shadows[1],
        fontSize: 14,
        fontWeight: 400,
    },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 'unset',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: 216,
        marginBottom: '-18px',
    },
    paper: {
        boxSizing: 'border-box',
        padding: theme.spacing(0),
        margin: 'auto',
        maxWidth: 830,
        maxHeight: 380,
        width: '90vw',
        height: 240,
        overflowX: 'hidden',
        overflowY: 'scroll',
        position: 'absolute',
        zIndex: 1,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '& .imgPholder': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    leftSection: {
        height: '100%',
        padding: 0,
        position: 'relative',
        overflow: 'hidden',
    },
    namePlate: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 16,
        marginRight: 7,
        '@media (max-width: 600px)': {
            marginBottom: '1em'
        }
    },
    // RightSection: {
    //     //height: '100%',
    //     position: 'relative',
    //     maxHeight: '100%',
    //     paddingTop: 5,
    //     paddingBottom: 0,
    //     paddingRight: theme.spacing(2),
    //     paddingLeft: theme.spacing(2),
    // },
    image: {
        width: '100%',
        height: 450,
        overflow: 'hidden'
    },
    img: {
        margin: '0',
        display: 'block',
        width: 'auto',
        height: '100%',
        maxWidth: 'unset',
        //position: 'absolute',
    },
    imgPholder: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarWrapper: {
        width: 120,
        height: 150,
        borderRadius: 0,
        //paddingTop: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'hidden',
        margin: 0,
        '&>img': {
            transition: 'opacity 0.25s ease-in-out',
            pointerEvents: "none",
        },
    },
    userDetails: {
        zIndex: 9,
        color: 'rgb(121, 121, 121)',
        padding: '0.5em 0.7em',
    },
    verified: {
        color: '#289e80',
        marginTop: '-20px',
    },
    lastActive: {
        color: '#999',
        fontSize: 12,
    },
    inlineSubtext: {
        color: '#232323',
        fontSize: '13px',
        fontWeight: 300,
    },
    chip: {
        fontSize: '12px',
        fontWeight: 'bold',
        // fontWeight: 500,
        // width: '120px',
        padding: '2px 8px',
        margin: '6px 0 2px 0',
        backgroundColor: '#f5f5f5',
        color: "#797979",
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    chipsWrapper: {
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    buttonSec: {
        flex: 1,
        marginRight: theme.spacing(1),
        textTransform: 'none',
        '& > *': {
            color: '#999'
        },
    },
    actionBtnContainer: {
        borderTop: '2px solid #e7e4e4',
        position: 'absolute',
        zIndex: 9,
        paddingTop: '0.5em',
        bottom: '-22px'
    },
    button: {
        flex: 1,
        textTransform: 'none',
    },
    expTimelineWrapper: {
        width: 600,
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    eduTimelineWrapper: {
        width: 600,
        //marginLeft: '-26px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    timelineArrow: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        background: '#f6fffde3',
        color: '#555',
        position: 'absolute',
        top: 96,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        fontSize: 24,
        '&:hover': {
            background: 'white',
            color: '#5bc2a8',
            boxShadow: '0 0 8px rgba(0,0,0,0.15)',
        },
    },
    timelineArrow_L: {
        left: 10,
    },
    timelineArrow_R: {
        right: 10,
    },
    timelineArrow_Edu_L: {
        left: 10,
        top: 'unset',
        bottom: 40,
    },
    timelineArrow_Edu_R: {
        right: 10,
        top: 'unset',
        bottom: 40,
    },
    timelineList: {
        display: 'flex',
        marginTop: 10,
        marginLeft: '-12px',
        marginBottom: 8,
        fontSize: 12,
        fontWeight: 400,
        color: '#797979',
        '& > div': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            fontSize: 12,
            position: 'relative',
            //width: 400,
            width: 300,
            '& .breakChar': {
                display: 'none',
            },
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 150,
            },
            '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
                display: 'none',
            },
            '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
                //display: 'none',
            },
            '&:last-child > div:nth-child(2) > div:nth-child(2) > span::after': {
                display: 'none',
            },
            '& > div > div:nth-child(2) > span': {
                position: 'relative',
                display: 'flex',
                width: 5,
                height: 5,
                marginBottom: 5,
                borderRadius: 5,
                backgroundColor: '#5bc2a8',
                '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '3px',
                    transform: 'translateY(-50%)',
                    MozTransform: 'translateY(0)',
                    backgroundColor: '#999',
                    width: '72.5px',
                    height: '0.5px',
                },
                '&:before': {
                    right: '5px',
                },
                '&:after': {
                    left: '5px',
                },
            },
            '& > div > div:nth-child(3)': {
                width: '100%',
                textAlign: 'center',
                // transform: 'translateX(-50%)',
                position: 'relative',
                left: '50%',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                padding: '0 10%',
                textOverflow: 'ellipsis',
                lineClamp: 2,
                display: '-webkit-box',
                boxOrient: 'vertical',
                height: 28,
                overflow: 'hidden',
            },
        },
        '& > div:nth-child(1)': {
            '&.withBreak': {
                width: 300
            }
        },
        '& > div:last-child': {
            '&.noBreak': {
                width: 300
            }
        },
        '& > div:last-child': {
            '&.withBreakSameMon': {
                '& > div:nth-child(1) > div:nth-child(2) > span::after': {
                    display: 'none',
                },
            }
        },
        '& > div.withBreak': {
            width: '300px!important',
            '& > div:nth-child(2) > div:nth-child(2) > span::after': {
                display: 'block',
                //backgroundColor: 'red',
                backgroundColor: '#e0e0e0',
            },
            '& .breakChar': {
                display: 'block',
                color: '#b0b0b0',
            },
        },
        '& > div.withBreak + div': {
            '& > div:nth-child(1) > div:nth-child(2) > span::before': {
                //backgroundColor: 'red',
                backgroundColor: '#e0e0e0',
            },
        },
        '& > div.withBreakSameMon': {
            width: '150px!important',
            // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
            //   backgroundColor: 'purple',
            // },
            '& > div:nth-child(1) > div:nth-child(2) > span::after': {
                //backgroundColor: 'purple',
                backgroundColor: '#e0e0e0',
            },
            '&:not(:last-child) .breakChar': {
                display: 'block',
                color: '#b0b0b0',
            },
        },
        '& > div.withBreakSameMon + div': {
            '& > div:nth-child(1) > div:nth-child(2) > span::before': {
                //backgroundColor: 'purple',
                backgroundColor: '#e0e0e0',
            },
            // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
            //   backgroundColor: 'purple',
            // },
        },
        '& > div.noBreakSameMon': {
            width: 150,
        },
        '& > div.noBreak': {
            width: 150,
        },
    },

    timelineListEdu: {
        display: 'flex',
        listStyle: 'none',
        marginTop: 10,
        marginLeft: '-37.3px',
        marginBottom: 8,
        fontSize: 12,
        fontWeight: 400,
        color: '#797979',
        '& > div': {
            // display: 'flex',
            // flexDirection: 'row',
            // alignItems: 'flex-start',
            // fontSize: 12,
            width: 200,
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: 12,
                textTransform: 'capitalize',
                width: 200,
            },
            '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
                display: 'none',
            },
            '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
                display: 'none',
            },
            '& > div > div:nth-child(2) > span': {
                position: 'relative',
                display: 'flex',
                width: 5,
                height: 5,
                marginBottom: 5,
                borderRadius: 5,
                backgroundColor: '#5bc2a8',
                '&:before, &:after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    zIndex: '-1',
                    top: '3px',
                    transform: 'translateY(-50%)',
                    MozTransform: 'translateY(0)',
                    backgroundColor: '#999',
                    width: '98px',
                    height: '1px',
                    maxWidth: '98px',
                },
                '&:before': {
                    right: '5px',
                },
                '&:after': {
                    left: '5px',
                },
            },
            '& > div > div:nth-child(3)': {
                width: '100%',
                textAlign: 'center',
                textTransform: 'capitalize',
                fontWeight: 'bold',
                padding: '0 19%',
                textOverflow: 'ellipsis',
                lineClamp: 2,
                display: '-webkit-box',
                boxOrient: 'vertical',
                height: 28,
                overflow: 'hidden',
            },
        },
    },

    timelineDuration: {
        fontSize: 10,
        color: '#797979',
        fontSize: 12,
        fontWeight: 300,
    },
    duration: {
        marginTop: 5,
        fontWeight: 400,
    },
    chiproot: {
        borderRadius: 4,
        height: 24,
    },
    chiplabel: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    bigIconRect: {
        position: 'relative',
        margin: '0 5px 0 0',
        zIndex: 2,
        height: 40,
        width: 80,
        padding: 5,
        borderRadius: '5px',
        // backgroundColor: '#ffffff',
        boxShadow: 'none',
        color: '#14967B',
        border: 'none',
        fontSize: 13,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#b9f0e1',
            //color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        },
        '& span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '@media (max-width: 600px)': {
            color: '#333'
        }
    },
    tglBtnLabel: {
        textTransform: 'capitalize',
    },
    tglBtnSelected: {
        backgroundColor: '#14967B!important',
        color: '#ffffff!important',
    },
    saveIcon: {
        height: 30,
        width: 30,
    },
    saveIconRect: {
        height: 16,
        width: 16,
        marginRight: 5,
    },
    infoIcon: {
        height: 20,
        width: 20,
        color: '#555',
    },
    bigIcon: {
        height: 46,
        width: 46,
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        color: '#fab116',
        border: '1px solid #fab116',
        '&:hover': {
            backgroundColor: '#fab116',
            color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    popover: {
        pointerEvents: 'none',
    },
    paperPopover: {
        padding: theme.spacing(1),
        width: 200,
    },
    dialogBackdropRoot: {
        backgroundColor: "transparent"
    }
}));

var employer_lat_lng = {
    lat: myInitObject.lat,
    lng: myInitObject.long
}

var rad = function (x) {
    return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(employer_lat_lng.lat - p1.lat);
    var dLong = rad(employer_lat_lng.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
};

export default function MobileFollowersList(props) {
    const classes = useStyles();
    const history = useHistory();

    var last_active = moment(new Date()).diff(moment(new Date(props.hit.last_seen_at * 1000)), 'days');

    let expUnitYears, expUnitMons, total_years, total_months;
    // fresher = false;

    var full_name = props.hit.user_first_name ? props.hit.user_first_name + " " + props.hit.user_last_name : "";
    var full_name_array = full_name.split(" ");
    var k = 0;
    var initials = "";
    for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
        initials = initials + full_name_array[k].charAt(0).toUpperCase();
    }

    var array_name = props.hit.user_first_name ? (props.hit.user_last_name ? (props.hit.user_first_name + " " + props.hit.user_last_name).split(" ") : (props.hit.user_first_name).split(" ")) : "";
    var k = 0, name_sentence_case = "";
    for (; k < array_name.length; k++) {
        if (array_name[k])
            name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
    }

    const handleProfileImage = (event) => {
        let imageWidth = event.target.width;
        let imageHeight = event.target.height;
        //console.log(imageWidth + " " + imageHeight)
        if (imageWidth / imageHeight <= 190.9 / 302) {
            //event.target.style.height = (302 * (190.9 / imageWidth)) + "px"
            event.target.style.height = 'auto';
            event.target.style.width = '100%';
        }
        event.target.style.opacity = 1
        event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
    }

    let scrollableWidth = 0;
    const scrollTimeline = (e) => {
        let timeline = e.target.nextSibling;
        let TimelineWidth = timeline.scrollWidth;
        scrollableWidth += 400;
        if (scrollableWidth <= 0) {
            timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' });
            expArrowRRef.current.style.display = "flex";
            if (scrollableWidth + 400 > 0) {
                e.target.style.display = "none"
            }
        }
    }
    const scrollTimelineR = (e) => {
        let timeline = e.target.previousSibling;
        let TimelineWidth = timeline.scrollWidth;
        scrollableWidth -= 400;

        if (scrollableWidth >= -TimelineWidth) {
            timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' })
            expArrowLRef.current.style.display = "flex";
            if (scrollableWidth - 405 <= -TimelineWidth) {
                e.target.style.display = "none"
            }
        }
    }

    let scrollableWidthEdu = 0;
    const scrollEduTimelineL = (e) => {
        let timeline = e.target.nextSibling;
        scrollableWidthEdu += 400;
        if (scrollableWidthEdu <= 0) {
            timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' });
            eduArrowRRef.current.style.display = "flex";
            if (scrollableWidthEdu + 400 > 0) {
                e.target.style.display = "none"
            }
        }
    }
    const scrollEduTimelineR = (e) => {
        let timeline = e.target.previousSibling;
        let TimelineWidth = timeline.scrollWidth;
        scrollableWidthEdu -= 400;
        if (scrollableWidthEdu >= -TimelineWidth) {
            timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' })
            eduArrowLRef.current.style.display = "flex";
            if (scrollableWidthEdu - 405 <= -TimelineWidth) {
                e.target.style.display = "none"
            }
        }
    }

    //Shortlist and Hire button functionalities start
    const [selected, setSelected] = React.useState(props.hit.shortlist_status);
    const [hireSelected, setHireSelected] = React.useState(props.hit.hired_status);
    const [isShortlistBtnVisible, setIsShortlistBtnVisible] = React.useState(!props.hit.hired_status);
    const [gotResponse, setGotResponse] = React.useState(true);
    const [gotHireResponse, setGotHireResponse] = React.useState(true);
    const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

    let cid = localStorage.getItem('company_id');
    const [company_id, setCompanyId] = useState(cid);

    //Shortlist button functionality starts
    const handleShortlistClick = () => {
        AnalyticsHandler.track("InvitePage_candidate_shortlistBtn")
        let payload = {
            company_id,
            invite_id: props.hit.invite_id,
            shortlist_status: !selected,
        }
        if (props.signInStatus) {
            setGotResponse(false);
            ApiHandler.shortlistToggle(payload, response => {
                if (response.status == 200) {
                    if (!sessionStorage.getItem('isShortlistToastDisplayed') && selected === false) {
                        setSelected(true);
                        handleSnackBar(`Thanks for shortlisting this jobseeker. We will inform them to call you for further process.`);
                        sessionStorage.setItem('isShortlistToastDisplayed', true)
                    } else if (!sessionStorage.getItem('isShortlistToastDisplayed') && selected === true) {
                        setSelected(false);
                    } else if (sessionStorage.getItem('isShortlistToastDisplayed')) {
                        setSelected(prev => !prev);
                    }
                    setGotResponse(true);
                } else {
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            ErrorMessage = "Oops! You've been logged out. Please login again."; // does not show as the below statement causes re-render
                            props.removeLocalStorageOnSessionExpiry();
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleAlert(ErrorMessage, 'error');
                    setGotResponse(true);
                }
            })
        }
    }

    //Hire button functionality starts
    const handleHireClick = () => {
        AnalyticsHandler.track("InvitePage_candidate_hireBtn")
        if (!hireSelected) {
            handleHireClickOpen();
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleHireClickOpen = () => {
        setOpen(true);
    };

    const handleHireYesClose = () => {
        AnalyticsHandler.track("InvitePage_candidate_hire_yesBtn")
        let payload = {
            company_id,
            invite_id: props.hit.invite_id,
            hired_status: !hireSelected,
        }
        if (props.signInStatus) {
            setGotHireResponse(false);

            ApiHandler.sendHired(payload, response => {
                if (response.status == 200) {
                    setOpen(false);
                    if (!sessionStorage.getItem('isHireToastDisplayed')) {
                        handleSnackBar(`Congratulations for hiring this jobseeker. We will inform them about their selection.`);
                        sessionStorage.setItem('isHireToastDisplayed', true)
                    }
                    setHireSelected(true);
                    setIsShortlistBtnVisible(false);
                    //setGotHireResponse(true);
                }
                else {
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            props.removeLocalStorageOnSessionExpiry();
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleAlert(ErrorMessage, 'error');
                    setGotHireResponse(true);
                }
            })
        }
    };

    const handleHireCancelClose = () => {
        AnalyticsHandler.track("InvitePage_candidate_hire_cancelBtn")
        setOpen(false);
    };

    // Hire button functionality ends

    // uninvite button functionality starts

    const [jobseeker_id, setJobseekerId] = useState(props.hit.jobseeker_id);
    const [job_role, setJobRole] = useState(props.hit.job_role);
    const [location, setLocation] = useState(props.hit.job_location);

    const [isRemoved, setIsRemoved] = useState(false);

    const [openUninvite, setOpenUninvite] = React.useState(false);

    const handleUninviteClickOpen = () => {
        AnalyticsHandler.track("InvitePage_candidate_uninviteBtn")
        setOpenUninvite(true);
    };

    const handleUninviteCancelClose = () => {
        AnalyticsHandler.track("InvitePage_candidate_uninvite_cancelBtn")
        setOpenUninvite(false);
    };

    const handleUninviteYesClose = () => {
        AnalyticsHandler.track("InvitePage_candidate_uninvite_yesBtn");
        if (props.signInStatus) {
            let payload = {
                company_id,
                jobseeker_id,
                job_role,
                location,
                invite_status: false,
            }
            setGotUninviteResponse(false);
            //alert(props.signInStatus)
            ApiHandler.sendInvite(payload, response => {
                if (response.status == 200) {
                    setOpenUninvite(false);
                    if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
                        setSelected(false);
                    } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
                        setSelected(false);
                    }
                    setGotUninviteResponse(true);
                    setIsRemoved(true);
                    props.setRefreshAfterRemoval(prev => !prev);
                }
                else {
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            if (response.response.data.message === "Session Expire") {
                                props.removeLocalStorageOnSessionExpiry();
                            }
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleAlert(ErrorMessage, 'error');
                }
            })
        } else if (props.signInStatus === false) {
            props.handleDrawer();
        }
    }
    // Invite button functionality ends

    const [invited, setInvited] = React.useState(props.hit.invite_jobseekers.length > 0 && props.hit.invite_jobseekers[0].invite_status);
    const timelineRef = React.useRef(null);
    const expArrowLRef = React.useRef(null);
    const expArrowRRef = React.useRef(null);

    const eduTimelineRef = React.useRef(null);
    const eduArrowLRef = React.useRef(null);
    const eduArrowRRef = React.useRef(null);

    let userEduSorted, _userEdu;
    const [userEduResponse, setUserEduResponse] = React.useState(null);
    const [userEdu, setUserEdu] = React.useState(null);

    let userExpSorted, _userExp, _expMonthsAndExp, _hasOverlapExps;
    const expMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const [userExpResponse, setUserExpResponse] = React.useState(null);
    const [userExp, setUserExp] = React.useState(null);
    const [expMonthsAndExp, setExpMonthsAndExp] = React.useState(null);
    const [hasOverlapExps, setHasOverlapExps] = React.useState(false);
    const [fresher, setFresher] = React.useState(null);
    const [expLabel, setExpLabel] = React.useState(null);

    React.useEffect(() => {
        ApiHandler.getInvitedProfileExp({ jobseeker_id }, response => {
            if (response.status === 200) {
                // console.log(response.data.user_experiences);
                let _expData = response.data.user_experiences;
                let _expMonsData = response.data.total_months_exp;
                setUserExpResponse(_expData);

                expUnitYears = (_expMonsData / 12).toFixed(0) == 1 ? " year " : " years ";
                expUnitMons = _expMonsData % 12 == 1 ? " month " : " months  ";

                total_years = Math.trunc(_expMonsData / 12);
                total_months = _expMonsData % 12;
                let _fresher;

                if (
                    (total_years == 0 && total_months == 0)
                    || _expData && _expData.length == 0
                    || _expData && _expData == [{}]
                    || !_expMonsData) {
                    setFresher(true);
                    _fresher = true;
                } else {
                    setFresher(false);
                    _fresher = false;
                }

                let _expLabel = _fresher ? 'Fresher' : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + expUnitYears) : (total_months + expUnitMons)) : (total_years + expUnitYears + total_months + expUnitMons));
                setExpLabel(_expLabel);
            }
        });

        ApiHandler.getInvitedProfileQuals({ jobseeker_id }, response => {
            if (response.status === 200) {
                //console.log(response.data);
                setUserEduResponse(response.data)
            }
        })
    }, []);

    React.useEffect(() => {
        if (!userExpResponse) return;

        userExpSorted = userExpResponse && userExpResponse.length != 0 ? userExpResponse.sort((a, b) => {
            if (a == {} || b == {})
                return 0;
            if (a.company_starting_date === b.company_starting_date)
                return 0;
            else {
                return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
            }
        }) : null;

        _userExp = userExpSorted
            && userExpSorted.filter((item, i, array) =>
                array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
            )

        _expMonthsAndExp = _userExp && _userExp.map((item, i) => {
            if (item != {}) {
                if (item.company_ending_date && item.company_starting_date) {
                    var startDate = checkDateFormat(item.company_starting_date)
                    var endDate = checkDateFormat(item.company_ending_date)
                    var startDateNext = _userExp[i + 1] ? new Date(_userExp[i + 1].company_starting_date) : null;
                    var a = moment(endDate);
                    var b = moment(startDate);
                    var c = moment(startDateNext);
                    var duration = a.diff(b, 'months');
                    var dd = c.diff(a, 'days');
                    var sameDaysDiff = a.diff(b, 'days')
                    return {
                        months: duration,
                        sameDaysDiff: sameDaysDiff,
                        daysDiff: dd,
                        expStartMon: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(0, 3) : startDate.getMonth(),
                        expEndMon: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(0, 3) : endDate.getMonth(),
                        expStartYear: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(4, 8) : startDate.getFullYear(),
                        expEndYear: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(4, 8) : endDate.getFullYear(),
                        user_post: item.user_post,
                    }
                }
                else {
                    return {
                        months: null,
                        sameDaysDiff: null,
                        daysDiff: null,
                        expEndMon: null,
                        expStartMon: null,
                        expStartYear: null,
                        expEndYear: null,
                        user_post: item.user_post,
                    };
                }
            }
            else {
                return {
                    months: null,
                    sameDaysDiff: null,
                    daysDiff: null,
                    expEndMon: null,
                    expStartMon: null,
                    expStartYear: null,
                    expEndYear: null,
                    user_post: null
                }
            }
        })

        _hasOverlapExps = _userExp && _userExp.length > 0 && _userExp.map((item, i) => {
            if (_expMonthsAndExp[i] && _expMonthsAndExp[i].daysDiff < 0) {
                return { overlap: 'true' }
            } else {
                return { overlap: 'false' }
            }
        });

        setUserExp(_userExp);
        setExpMonthsAndExp(_expMonthsAndExp);
        setHasOverlapExps(_hasOverlapExps);
        //console.log(_userExp, _expMonthsAndExp, _hasOverlapExps)

    }, [userExpResponse])

    React.useEffect(() => {
        if (timelineRef.current && expArrowRRef.current && timelineRef.current.scrollWidth > 612) {
            expArrowRRef.current.style.display = "flex";
        }
    }, [userExp]);

    React.useEffect(() => {
        if (!userEduResponse) return;

        userEduSorted = userEduResponse && userEduResponse.length !== 0 ? userEduResponse.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)) : null;

        _userEdu = userEduSorted ? userEduSorted.filter((item, i, array) =>
            array.findIndex(t => t.user_passing_year && item.user_passing_year && t.course_name && item.course_name && t.user_passing_year == item.user_passing_year && t.course_name == item.course_name) == i
        ) : [];

        setUserEdu(_userEdu);
    }, [userEduResponse])


    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    }
    // snackbar callback ends

    // FeedbackAlert callback starts
    const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
    const [refreshAlert, doRefreshAlert] = React.useState(false);
    const [canAlertOpen, setCanAlertOpen] = React.useState(false);
    const handleAlert = (message, type) => {
        setCanAlertOpen(true);
        setAlertMessage({ message: message, type: type });
        doRefreshAlert(prev => !prev);
    }
    // FeedbackAlert callback ends

    const getinvitedDate = () => {
        let d = props.hit.invite_time;
        d = new Date(d);
        d = moment(d).format("Do MMM");
        return d
    }

    const invitedDate = getinvitedDate();

    return (
        <div className="listItemWrapper" style={{ marginBottom: 30, position: 'relative' }}>
            {isRemoved ?
                <Paper className={classes.paper} elevation={3} style={{ height: 150, background: 'rgba(255, 255, 255, 0.5)', position: 'relative' }}>

                    <Grid item xs={12} container direction="row" justifyContent="center"
                        alignItems="center" style={{ height: '100%' }}>
                        <Typography variant="h6" className={classes.namePlate} >
                            <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{name_sentence_case}</span> has been removed from the list of jobseekers you invited for the <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.job_role ? props.hit.job_role.toLowerCase() : ""}</span> role.
                        </Typography>
                    </Grid>

                </Paper>
                :
                <div className={classes.root}>
                    <Paper className={classes.paper} elevation={3}>
                        <Grid item container
                            justifyContent="center"
                            alignItems="center"
                            style={{ height: 30, background: '#ececec', display: 'none' }}
                        > <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                                Invited for <span style={{ color: '#14967B' }}>{props.hit.job_role}</span> on <span style={{ color: '#14967B' }}>{invitedDate}</span>
                            </Typography></Grid>
                        <Grid container direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start" style={{ height: '302', overflow: 'hidden' }}>
                            <Grid item className={classes.leftSection} id="leftSectionId">
                                <Grid item xs container direction="column"
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid alignItems='flex-start' item xs container wrap='nowrap' justifyContent="center">
                                        <Grid item xs container direction="column" justifyContent="center"
                                            alignItems="center">

                                            <div className={classes.avatarWrapper}>
                                                {props.hit.user_image_url ?
                                                    <img className={classes.img} alt="candidates avatar" src={props.hit.user_image_url} onLoad={handleProfileImage} style={{ opacity: 0 }} />
                                                    :
                                                    <div className="imgPholder">
                                                        <p style={{
                                                            fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%',
                                                        }}> {initials} </p>
                                                    </div>}
                                            </div>
                                        </Grid>
                                        <Grid item className={classes.userDetails} >
                                            <Grid item xs={12} container direction="row" justifyContent="space-between"
                                                alignItems="flex-start">
                                                <Grid item xs container direction="column" justifyContent="flex-start" alignItems="baseline" style={{ marginBottom: 10 }}>
                                                    <Typography variant="h6" className={classes.namePlate} >
                                                        {name_sentence_case}
                                                    </Typography>
                                                    <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                                                        <span style={{ position: 'relative', marginRight: 8 }}>
                                                            {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                                                        <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                                                            {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                                                        </span>

                                                        {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            {userExp && userExp.length > 0 && <Grid style={{ fontSize: '14px', marginBottom: 10 }} item container alignItems='center' wrap='nowrap' >
                                                <WorkIcon style={{ fontSize: '14px', marginRight: 8 }} fontSize="small" className="primaryText" />
                                                <span>
                                                    {userExp && userExp[userExp.length - 1].user_post} - {userExp && userExp[userExp.length - 1].company_name}
                                                </span>
                                            </Grid>}
                                            {userEdu && userEdu.length > 0 && <Grid style={{ fontSize: '14px' }} item container alignItems='flex-start' wrap='nowrap' >
                                                <SchoolRoundedIcon style={{ fontSize: '14px', marginRight: 8 }} fontSize="small" className="primaryText" />
                                                <span>
                                                    {userEdu && userEdu[userEdu.length - 1].course_type}-{userEdu && userEdu[userEdu.length - 1].user_degree} | {userEdu && userEdu[userEdu.length - 1].user_passing_year}
                                                </span>
                                            </Grid>}

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>

                            </Grid>

                        </Grid>
                    </Paper>
                </div>}
            {!isRemoved &&
                <Grid item container
                    justifyContent="center"
                    alignItems="flex-end"
                    className={classes.actionBtnContainer}
                >
                    <ToggleButton
                        // id={props.index}
                        value="check"
                        className={classes.bigIconRect}
                        style={{ marginRight: 10, width: 'auto', border: invited ? '1px solid #33333385' : 'none', height: invited ? '30px' : '40px', marginBottom: invited ? '5px' : 0 }}
                        classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
                        // selected={invited}
                        // onChange={gotResponse ? handleInviteClick : undefined}
                        onChange={(e) => {
                            AnalyticsHandler.track("CompanyPage_followers_emailInviteBtn")
                            if (props.hit.invite_jobseekers.length > 0) {
                                props.setInvitedRoles(props.hit.invite_jobseekers);
                                // props.setSelected(props.hit.invite_jobseekers[e.target.parentNode.id])
                            } else {
                                props.setInvitedRoles([])
                            }
                            props.setOpenInviteModal(true);
                            props.setOpenRolesList(true);
                            props.setJobseeker_id(props.hit.jobseeker_id);
                            props.setJobseekerName(props.hit.user_last_name ? props.hit.user_first_name + ' ' + props.hit.user_last_name : props.hit.user_first_name)

                        }}

                    >
                        {/* <EmailIcon className={classes.saveIconRect} /> */}
                        {invited ?
                            ''
                            : <EmailIcon className={classes.saveIconRect} />
                        }
                        {invited ? "Take Next Steps" : "Email Invite"}
                    </ToggleButton>



                    <Button
                        className={classes.bigIconRect}
                        classes={{ label: classes.tglBtnLabel }}
                        style={{ width: 'auto' }}
                        onClick={() => {
                            AnalyticsHandler.track("InvitePage_candidate_fullProfileBtn");
                            localStorage.setItem('searched_role', props.hit.job_role)
                            window.open(`/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit._id}`, "_blank");
                        }}
                    ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> <span>Full Profile</span></Button>

                </Grid>}

            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
            <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
            <div>
                <Dialog
                    open={open}
                    onClose={handleHireCancelClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    BackdropProps={{
                        classes: {
                            root: classes.dialogBackdropRoot
                        }
                    }}
                    transitionDuration={150}
                >
                    <DialogTitle id="alert-dialog-title">{"Hiring Confirmation"}</DialogTitle>
                    <DialogContent style={{ height: 100 }}>
                        <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
                            Are you sure you want to hire <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span>?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="cancel_hire_btn_id" onClick={handleHireCancelClose}>
                            Cancel
                        </Button>
                        <Button id="hire_btn_id" onClick={gotHireResponse ? handleHireYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openUninvite}
                    onClose={handleUninviteCancelClose}
                    aria-labelledby="remove-dialog-title"
                    aria-describedby="remove-dialog-description"
                    BackdropProps={{
                        classes: {
                            root: classes.dialogBackdropRoot
                        }
                    }}
                    transitionDuration={150}
                >
                    <DialogTitle id="remove-dialog-title">{"Confirmation to remove"}</DialogTitle>
                    <DialogContent style={{ height: 100 }}>
                        <DialogContentText id="remove-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
                            Are you sure you want to remove <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span> from the list of jobseekers you invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.job_role ? props.hit.job_role.toLowerCase() : ""}</span> role?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
                            Cancel
                        </Button>
                        <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}