import React from 'react';
import { Typography, Grid, FormControl, FormGroup, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@material-ui/core';
var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

function WorkType(props) {
  const { classes, values, setValues, setShowDuration, setShowUnpaidOption, errors, setErrors, jobType } = props;
  const [workTypeValue, setWorkTypeValue] = React.useState(null);
  let initialRender = React.useRef(true);

  const handleChange = (e) => {
    setErrors({});
    setWorkTypeValue(e.target.value);
    if (e.target.value === 'Internship') {
      setShowUnpaidOption(true);
    } else {
      setShowUnpaidOption(false);
    }
    if (e.target.value === 'Full-time') {
      setShowDuration(false);
      setValues({ ...values, opportunity_type: e.target.value, internship_duration: "" });
    } else {
      setShowDuration(true);
      setValues({ ...values, opportunity_type: e.target.value });
    }

  };

  React.useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (values.opportunity_type === 'Full-time') {
        setShowDuration(false);
      } else {
        setShowDuration(true);
      }
      if (values.opportunity_type === 'Internship') {
        setShowUnpaidOption(true);
      } else {
        setShowUnpaidOption(false);
      }
    }
  }, []);

  return <Grid item xs={12} className={classes.my4} >
    <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Job Type<span style={{color: '#f44336'}}>*</span></Typography>
    <FormControl component="fieldset" className={`${classes.flexbox} ${classes.my4} ${classes.workTypeFormControl}`} error={errors.opportunity_type ? true : false} >
      <RadioGroup aria-label="worktype" name="opportunity_type" value={values.opportunity_type}
        onChange={e => handleChange(e)}
        className={classes.workTypeOptions}>
          {jobType.filter(el=> el !== 'Self-Employed').map((type, index) => {
            return <FormControlLabel key={index} className={classes.mr2} value={type} control={<Radio classes={{ root: classes.radioColor }} />} label={type} />
          })}
      </RadioGroup>
      <FormHelperText>{errors.opportunity_type ? errors.opportunity_type : ''}</FormHelperText>
    </FormControl>
  </Grid>;
}

export default WorkType;
