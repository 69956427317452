import React from 'react';
import { Typography, Grid, InputBase, TextField, Chip, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import LanguageIcon from '@material-ui/icons/Language';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBarGeneric from '../discovery/SnackBarCustom';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { array } from 'prop-types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

var axios = require('axios');
const autocompleteService = { current: null };
var BASE_URL = process.env.REACT_APP_API_ROOT;

function Location(props) {
  const { classes, values, setValues, errors, setErrors } = props; //values is the final object to be submitted in the jobForm

  const [value, setValue] = useState(props.multipleLocationVal && props.multipleLocationVal.length > 0 ? props.multipleLocationVal : []); //locations (address)
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  let addressArr = []; // to save the array of location addresses

  const [flag_your_location, setFlagYourLocation] = useState(false);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  var option_to_add_single =
  {
    description: "Current Location",
    matched_substrings: [{ length: 0, offset: 0, }],
    structured_formatting:
    {
      main_text: "Current Location",
      main_text_matched_substrings: [{ length: 0, offset: 0 }],
      terms: [{ offset: 0, value: "Current Location" }],
      types: ["locality", "political", "geocode"]
    },
  }

  var option_to_add_anywhere =
  {
    description: "Anywhere/ Work-from-Home",
    matched_substrings: [{ length: 0, offset: 0, }],
    structured_formatting:
    {
      main_text: "Anywhere/ Work-from-Home",
      main_text_matched_substrings: [{ length: 0, offset: 0 }],
      terms: [{ offset: 0, value: "Anywhere/ Work-from-Home" }],
      types: ["locality", "political", "geocode"]
    },
  }

  var option_to_add =
  {
    description: "Current Location",
    matched_substrings: [{ length: 0, offset: 0, }],
    structured_formatting:
    {
      main_text: "Current Location",
      main_text_matched_substrings: [{ length: 0, offset: 0 }],
      terms: [{ offset: 0, value: "Current Location" }],
      types: ["locality", "political", "geocode"]
    },
  }

  function change_display(address) {
    var option_to_display =
    {
      description: address,
      matched_substrings: [{ length: 2, offset: 0, }],
      structured_formatting:
      {
        main_text: address,
        main_text_matched_substrings: [{ length: 2, offset: 0 }],
        terms: [{ offset: 0, value: address }],
        types: ["locality", "political", "geocode"]
      }
    }
    return option_to_display;
  }

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([option_to_add_anywhere])
      return undefined;
    }

    // if (inputValue === "Anywhere/ Work-from-Home") {
    //   setOptions([option_to_add_single])
    //   return undefined;
    // }

    fetch({
      input: inputValue,
      componentRestrictions: { country: "in" }
    }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          if (flag_your_location) {
            results = [option_to_add_anywhere]
          }

          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const locationRef = useRef(true);

  var locationPromiseArr = []; //to set coordinates after google place api call

  React.useEffect(() => {
    if (props.multipleLocationVal && props.multipleLocationVal.length > 0) {
      setValue(props.multipleLocationVal);
    }
  }, [props.multipleLocationVal]); //to update the locations value when the value is updated from the parent onload

  return <Grid item xs={12} className={classes.my4} >
    {(!props.purpose || !props.purpose === "edit") && <Typography gutterBottom variant='h5' className={classes.my2}>Location(s)*</Typography>}
    <Autocomplete
      id="location_id"
      ref={locationRef}
      classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popperLocation, listbox: classes.listbox, paper: classes.paperLocation, popupIndicator: classes.popupIndicator }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      multiple
      includeInputInList
      filterSelectedOptions
      value={value || []}
      onChange={(event, newValue) => {
        let listArr = []; // to save location coordinates
        let isWorkFromHome = false; // to check if WFM is selected
        setValue(newValue);
        // props.setMultipleLocationVal(newValue)
        if (!props.purpose || !props.purpose === "edit") props.setMultipleLocationVal(newValue) //not to set for jobUpdate

        if (newValue.length > 0) {
          if (setErrors) setErrors({});

          newValue.map((element, index) => {
            if (element.description === "Anywhere/ Work-from-Home" || element === "Anywhere/ Work-from-Home") { //newly selected one has Anywhere/ Work-from-Home in description while the older wfh location itself is equal to Anywhere/ Work-from-Home
              isWorkFromHome = true;
            } else {
              if (element.place_id) { //place_id used instead of terms as for places like medha vals.terms[0] returns no results. if the location value is a newly selected one then get the co-ordinates from Google API
                var location = element.terms[0].value;
                if (location !== "Current Location" && location != "Anywhere/ Work-from-Home") {
                  locationPromiseArr.push(axios.get('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + element.place_id + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0'))
                }
              }

              if (typeof element === 'string' && values.geo_json.coordinates && values.geo_json.coordinates[index]) { //if the location value is a previously populated one then add the co-ordinates from geo_json recieved from API
                if (typeof values.geo_json.coordinates[index] === "number") { //case: older co-ordinate was a number. to support older jobs this is kept.
                  listArr.push(values.geo_json.coordinates)
                } else { //case: new co-ordinate is an array
                  listArr.push(values.geo_json.coordinates[index])
                }
              }

              addressArr.push({ "address": element.description ? element.description : element }); //if description field is not present it means the address is a previously populated string
            }

          })
        }
        if (locationPromiseArr) {
          Promise.all(locationPromiseArr).then((result) => { // get location selected by user and then save co-ordinates by iterating through them
            result.forEach(element => {
              var latLngData = [element.data.results[0].geometry.location.lng, element.data.results[0].geometry.location.lat]
              listArr.push(latLngData)
            })
          }).then(() => {
            setValues({ ...values, geo_json: listArr.length > 0 ? { type: 'MultiPoint', coordinates: listArr } : null, isWorkFromHome, job_location: addressArr });
          })
        } else {
          setValues({ ...values, isWorkFromHome })
        }
      }}

      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue === "") {
          setLatitude(null);
          setLongitude(null);
        }
      }}
      getOptionSelected={(option, value) => option.description === value.description}
      renderOption={(option) => {
        if (option.structured_formatting) {
          const matches = option.structured_formatting.main_text_matched_substrings;
          if (matches) {
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );
            return (
              <Grid container alignItems="flex-start" direction="column">
                {option.description === "Anywhere/ Work-from-Home" &&
                  (<Grid container alignItems="flex-start">
                    <Grid item style={{ padding: '0 0 10px' }}>
                      <Typography variant="body2" color="textSecondary">
                        Or Choose
                      </Typography>
                    </Grid>
                  </Grid>
                  )
                }
                <Grid container alignItems="flex-start">
                  {option.description === "Anywhere/ Work-from-Home" &&
                    (<Grid item>
                      <LanguageIcon className={classes.gpsIcon} />
                    </Grid>)}
                  {option.description !== "Anywhere/ Work-from-Home" && option.description !== "Current Location" &&
                    (<Grid item>
                      <LocationOnIcon className={classes.locationIcon} />
                    </Grid>)}
                  <Grid item xs>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: option.structured_formatting.main_text === "Detect Current Location" ? 'black' : '#333', fontSize: 14 }}>
                        {part.text}
                      </span>
                    ))}
                    {option.description === "Current Location" ? null
                      : <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        }
      }}

      renderInput={(params) => (
        <TextField {...params}
          id="locationInput_id"
          variant="outlined"
          placeholder={value.length > 0 ? "" : "Start typing. e.g. Lucknow, Kanpur"}
          error={errors && errors.job_location ? true : false}
          helperText={errors && errors.job_location ? errors.job_location : ''}
        />
      )}
    />
    {/* <span className={classes.helper_text}> To select press Enter ↲ | To navigate results use ↑ ↓ </span>
        <Typography gutterBottom variant='body1' component='p' classes={{ root: classes.flexbox }} color='primary' >
            <AddIcon color='primary' classes={{ root: classes.icon }} /> <span>Add a location/WFM</span>
        </Typography> */}
  </Grid>;
}

export default Location;