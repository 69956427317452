import React from 'react';
import '../../assets/css/meetApp.css';
import { makeStyles } from '@material-ui/core/styles';
import JobAppBar from '../../components/JobAppBar';
import { ThemeProvider } from '@material-ui/styles';
import MyTheme from '../../style/JobAppTheme';
import { StylesProvider } from "@material-ui/core/styles";

import './Login_2.css';
import logo from '../../assets/logo_header.png';
import poweredByGoogle from '../../assets/powered_by_google_on_white.png';
import SignInUpDrawer from '../../components/SignInUpDrawer'
import global_isSignedIn from '../discovery/global_isSignedIn';
import AnalyticsHandler from '../../AnalyticsHandler.jsx';
import Slider from '../../components/Slider';
import SearchBarWrapper from './SearchBarWrapper';
import './ParticleBackgroundd.css'
import { Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import Footer from '../../components/Footer';
import banner from '../../assets/homepage/banner.png';
import mobileBanner from '../../assets/homepage/mobileBanner.png';
import aboutImg from '../../assets/homepage/about.jpg';
import aboutImgMobile from '../../assets/homepage/aboutImgMob.jpg';
import bigBasketLogo from '../../assets/homepage/Bigbasket.png';
import bluestarLogo from '../../assets/homepage/Bluestar.png';
import forceLogo from '../../assets/homepage/Force.png';
import geminiLogo from '../../assets/homepage/Gemini.png';
import indiamartLogo from '../../assets/homepage/Indiamart.png';
import janaLogo from '../../assets/homepage/Jana.png';
import jdLogo from '../../assets/homepage/JD.png';
import landmarkLogo from '../../assets/homepage/Landmarkgroup.png';
import relianceLogo from '../../assets/homepage/Reliance.png';
import subrosLogo from '../../assets/homepage/Subros.png';
import tradesmartLogo from '../../assets/homepage/Tradesmart.png';
import tvsLogo from '../../assets/homepage/Tvs.png';
import meetImg1 from '../../assets/homepage/meetworks1.png';
import meetImg2 from '../../assets/homepage/meetworks2.png';
import meetImg3 from '../../assets/homepage/meetworks3.png';
import meetImg4 from '../../assets/homepage/meetworks4.png';
import IT from '../../assets/homepage/IT.svg';
import UGDip from '../../assets/homepage/ugdiploma.svg';
import UGDeg from '../../assets/homepage/ugdegree.svg';
import PGDip from '../../assets/homepage/pgdiploma.svg';
import PGDeg from '../../assets/homepage/pgdegree.svg';
import ogImg from '../../assets/og-img.jpg';
import jobPost from '../../assets/homepage/job-post.jpg';
import InstaLogo from '../../assets/homepage/insta.jpg';
import FbLogo from '../../assets/homepage/fb.png';
import LinkedinLogo from '../../assets/homepage/linkedin.png';
import MobileJobForm from '../../components/MobileJobForm.js';
import { ArrowBack } from '@material-ui/icons';
import bgIcon from '../../assets/Iconwallpaper2.png'

var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

const useStyles = makeStyles((theme) => ({
  bgOverlay: {
    background: `url(${banner}) no-repeat center center/cover`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: '100vw 50vh',
    marginTop: '3em',
    width: '100%',
    height: '55vh',
    paddingTop: '3em',
    position: 'relative',
    marginBottom: '2.5em',
    zIndex: 99,
    '@media (max-width: 620px)': {
      height: '40vh',
      // backgroundSize: '100vw 36vh',
      background: `url(${mobileBanner}) no-repeat center center/cover`
    },
  },
  centerAbsoluteDiv: {
    position: 'absolute',
    left: '50%',
    bottom: '-1%',
    height: '20vh',
    transform: 'translate(-50%)',
    zIndex: 999,
    backgroundColor: '#fff',
    padding: '0 1em 0 1em',
    zIndex: 999,
    width: '82%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '15px 15px 0 0',
    '@media (max-height: 620px)': {
      height: '25vh',
    },
    '@media (max-width: 600px)': {
      height: '35vh',
      boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
      width: '90%',
      position: 'relative',
      bottom: '-45%',
      borderRadius: '8px',
    }
  },
  // imageBg: {
  //   background: 'url("/bg-image.jpg") no-repeat center center fixed',
  //   backgroundSize: 'cover',
  // },
  pageTitle: {
    color: "white",
    // paddingTop: "135px",
    // paddingBottom: "50px",
    fontSize: "35px",
    fontWeight: 'bold',
    textShadow: "0px 4px 10px rgba(0, 0, 0, 0.58)",
    textAlign: 'left',
    position: 'absolute',
    left: '10%',
    top: '25%',
    width: '40%',
    '@media (max-width: 600px)': {
      width: '55%',
      fontSize: '23px',
      left: '6%',
      top: '13%',
    }
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    width: 970,
    height: 72,
    paddingLeft: 34,
    borderRadius: 50,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    transform: 'scale(1)',
    border: '1px solid #55c6a9',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      border: 'none',
      background: 'transparent',
      boxShadow: 'none',
      height: 'initial',
      paddingLeft: 0,
      marginTop: -10
    }
  },
  jobFormRoot: {
    background: `url(${bgIcon})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  qualLabel: {
    '@media (max-width: 600px)': {
      position: 'absolute',
      top: '-18%',
      zIndex: 9999,
      backgroundColor: 'white',
      height: '5px',
      marginLeft: '8px'
    }
  },
  mobileQualboxContainer: {
    '@media (max-width: 600px)': {
      position: 'relative',
      border: '1px solid #55c6a9',
      height: '43px',
      borderRadius: '5px'
    }
  },
  qualInputWrapper: {
    '@media (max-width: 600px)': {
      margin: '10px'
    }
  },
  searchBarIconButton: {
    marginTop: 0,
    height: 72,
    width: 72,
    textTransform: "none !important",
    backgroundColor: '#14967B !important',
    borderRadius: '0 50% 50% 0 !important',
    color: 'white !important',
    "&:hover": {
      background: "#5BC2A8 !important",
    },
    "&.Mui-disabled": {
      backgroundColor: '#14967B !important',
      color: '#fff !important',
    },
    '@media (max-width: 600px)': {
      alignSelf: 'flex-end',
      height: 'initial',
      borderRadius: '6px !important',
      // margin: '10px',
      backgroundColor: '#5bc2a8 !important',
      width: 95
    }
  },
  desktopSearchIcon: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  mobileSearchIcon: {
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
  searchBarIconButton_Active: {
    marginTop: 0,
    height: 72,
    width: 72,
    textTransform: "none",
    background: "#5BC2A8",
    borderRadius: '0 50% 50% 0',
    color: 'white',
    "&:hover": {
      background: "#5BC2A8",
    },
    "&.Mui-disabled": {
      backgroundColor: '#14967B',
      color: '#fff',
    },
    '@media (max-width: 600px)': {
      alignSelf: 'flex-end',
      height: 'initial',
      borderRadius: '6px !important',
      margin: '20px 0'
    }
  },
  icon: {
    //color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    color: '#999',
    fontSize: 16,
    marginTop: 5,
  },
  gpsIcon: {
    paddingRight: 16,
    //color: '#5BC2A8',
    color: '#999',
    fontSize: 16,
    marginTop: 4,
  },
  gpsText1: {
    fontSize: 20,
    color: '#5BC2A8',
    marginBottom: 5,
  },
  gpsText2: {
    fontSize: 14,
    color: '#6b6b6b',
  },
  comboBoxRoot: {
    marginTop: 22,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
    // "&:after": {
    //   position: 'absolute',
    //   content: '""',
    //   width: 1,
    //   height: 34,
    //   backgroundColor: '#E0E0E0',
    // },
    '@media (max-width: 600px)': {
      border: 'initial !important'
    }

  },
  inputRoot: {
    padding: '0 0 0 8px!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    //   "& .MuiIconButton-label": { 
    //     display: "flex",  
    // },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
    '@media (max-width: 600px)': {
      borderColor: '#55c6a9 !important',
      "& *, &:hover > *": {
        borderColor: '#55c6a9 !important',
      },
    }
  },
  whyHireTiles: {
    width: '24%',
    '@media (max-width: 600px)': {
      width: '48% !important',
      height: 'fit-content',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      padding: '6px',
      marginBottom: '1em',
      borderRadius: '6px'
    },
  },
  whyHireImg: {
    height: '200px',
    borderRadius: '6px',
    '@media (max-width: 600px)': {
      height: '120px',
      borderRadius: '6px 6px 0 0'
    },
  },
  whyHireText: {
    marginTop: '6px',
    '@media (max-width: 600px)': {
      marginTop: 0
    },
  },
  companyLogoContainer: {
    '@media (max-width: 600px)': {
      width: 'fit-content',
      height: 'fit-content',
      '& img': {
        width: '100px',
        height: '80px'
      }
    },
  },
  aboutContainer: {
    '@media (max-width: 600px)': {
      // flexWrap: 'nowrap',
      justifyContent: 'center',
      flexFlow: 'column-reverse'
      // paddingBottom: '82px'
    },
  },
  aboutContent: {
    '@media (max-width: 600px)': {
      padding: '1em 0',
    },
  },
  aboutImg: {
    background: `url(${aboutImg})`,
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
    width: '500px',
    height: '320px',
    '@media (max-width: 600px)': {
      background: `url(${aboutImg})`,
      width: '275px',
      height: '175px'
      // width: '200px',
      // height: '370px'
    },
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
    textDecoration: 'none',
  },
  popupIndicator: {
    display: 'none'
  },

  input: {
    height: 43,
    maxWidth: '91%',
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },

  },

  inputFocusedDark: {
    "& ::-webkit-input-placeholder": {
      color: 'red',
    },
  },
  popper: {
    marginTop: 16,
    zIndex: 999,
    '@media (max-width: 600px)': {
      marginTop: 0
    },
  },
  popperLocation: {
    marginTop: 16,
    '@media (max-width: 600px)': {
      marginTop: 0
    },
    "& .MuiAutocomplete-listbox": {
      paddingBottom: 0,
    },
    "& .MuiAutocomplete-listbox::after": {
      content: '""',
      position: 'absolute',
      right: 8,
      bottom: 8,
      width: 144,
      height: 18,
      backgroundImage: `url(${poweredByGoogle})`,
      backgroundRepeat: 'no-repeat',
    }
  },
  listbox: {
  },
  paper: {
    borderRadius: 10,
    "& .MuiAutocomplete-option[aria-disabled='true']": { // add this to make the disabled instruction text of role box clear
      opacity: 1
    }
  },
  paperLocation: {
    borderRadius: 10,
    paddingBottom: 30
  },
  // sectionDesktop: {
  //   display: 'none',
  //   overflow: 'hidden',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'flex',
  //     flexDirection: 'column',
  //   },
  // },
  // sectionMobile: {
  //   display: 'flex',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'none',
  //   },
  // },
  landingPageSubsection: {
    background: 'white',
  },
  lowerSectionDiv: {
    position: 'relative',
    padding: '2em 0',
    width: '84vw',
    margin: 'auto',
    zIndex: '9',
    '@media (max-width: 600px)': {
      padding: '0.5em 0',
      width: '90vw',
    },
  },
  sliderHeadings: {
    margin: '0 1em 1em 0',
    fontSize: '1.5em',
    '@media (max-width: 600px)': {
      fontSize: '1.1em',
      margin: '0 0 1em 0',
    },
  },
  imageList: {
    width: 500,
    height: 450,
  },
  desktopNav: {
    '@media (max-width: 600px)': {
      // position: 'fixed',
      // bottom: 0,
      // backgroundColor: '#fff',
      display: 'none'
    },
  },
  mobileNav: {
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
  jobPostBannerMobile: {
    // border: '1px solid red',
    // height: '10vh',
    width: '94%',
    margin: '9em auto 0 auto',
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
  paperScrollPaper: {
    margin: '0 !important',
    height: '100vh !important',
    width: '100vw !important',
    maxHeight: '100vh !important',
    maxWidth: '100vw !important',
    '& > div': {
      // padding: 0
    }
  },
}));

const isMobile = window.innerWidth <= 600 ? true : false

export default function Login_2() {

  // console.log("Code Release Date: 29 Nov ");

  const classes = useStyles();
  const [Roles, setRoles] = React.useState([]);
  const ref_role = React.useRef(null);
  const [navValue, setNavValue] = React.useState(0);

  const [topCoursesList, setTopCoursesList] = React.useState([]);
  const [topCourses, setTopCourses] = React.useState([]);
  const [topLocations, setTopLocations] = React.useState([]);
  const whyHire = [
    {
      illustration_image: `${meetImg1}`,
      title: 'Freshers looking for jobs - Diploma / Graduates / Post Graduates / ITI'
    },
    {
      illustration_image: `${meetImg4}`,
      title: 'Invite co-workers to share reviews and ratings of their companies'
    },
    {
      illustration_image: `${meetImg3}`,
      title: 'Male and Female jobseekers in T2 & T3 cities'
    },
    {
      illustration_image: `${meetImg2}`,
      title: 'Invite candidates for interview and hiring process'
    },
  ]

  const qualData = [
    {
      title: 'ITI',
      illustration_image: `${IT}`
    },
    {
      title: 'UG Diploma',
      illustration_image: `${UGDip}`
    },
    {
      title: 'UG Degree',
      illustration_image: `${UGDeg}`
    },
    {
      title: 'PG Diploma',
      illustration_image: `${PGDip}`
    },
    {
      title: 'PG Degree',
      illustration_image: `${PGDeg}`
    },
  ]
  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  document.body.classList.remove("wallpaper--icon");

  const loaded = React.useRef(false);

  let newArray = topCoursesList.filter((item, i, array) =>
    array.findIndex(t => t.qualification_id == item.qualification_id) == i
  )

  const groupByCat = (qualifications = []) => {
    let result = [];
    result = qualifications.reduce((r, a) => {
      if (a.course_type !== 'Ph.D' && a.course_type !== 'Certification' && a.course_type !== 'School' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
        r[a.course_type] = r[a.course_type] || [];
        r[a.course_type].push(a);
      }
      return r;
    }, Object.create(null));
    return result;
  };

  let newHashMap = groupByCat(newArray);

  const sortQuals = (arr, cat) => {
    if (arr[cat]) {
      let _a = arr[cat].sort((a, b) => {
        if (a.QID_count && b.QID_count) {
          return a.QID_count < b.QID_count ? 1 : -1
        }
      })
      return _a.slice(0, 2)
    }
  }

  React.useEffect(() => {
    if (Object.keys(newHashMap).length > 0) {
      let courses = [];
      let ITI = sortQuals(newHashMap, 'ITI');
      let UgDiploma = sortQuals(newHashMap, 'UG Diploma');
      let UgDegree = sortQuals(newHashMap, 'UG Degree');
      let PgDiploma = sortQuals(newHashMap, 'PG Diploma');
      let PgDegree = sortQuals(newHashMap, 'PG Degree');

      courses.push(...ITI, ...UgDiploma, ...UgDegree, ...PgDiploma, ...PgDegree);
      //console.log(courses);
      setTopCourses(courses);
    }
  }, [topCoursesList])

  React.useEffect(() => {
    axios.get(BASE_URL + '/users/get_job_role_mapping/').then((Result) => {
      let rolesArr = Result.data;
      let allRole = { title: 'any' }
      let fresherRole = { title: 'Fresher' }
      rolesArr.unshift(allRole) // add all at the first place
      rolesArr.splice(1, 0, fresherRole); // add fresher at 2nd place

      let roleBoxTitle = {
        title: 'ب',
      }

      rolesArr.unshift(roleBoxTitle) // add title text at the first
      setRoles(rolesArr);
    })

  }, []);

  React.useEffect(() => {
    axios.get(BASE_URL + '/select/v2/analytics_top_locations/0/10')
      .then(res => {
        setTopLocations(res.data[0].results);
      })
  }, []);

  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false) {
      setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const [openJobPostingForm, setOpenJobPostingForm] = React.useState(false);
  const handleDrawer = () => {
    AnalyticsHandler.track('signIn_View_btnClick');

    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  const jobFormDialogRef = React.useRef(null);

  return (
    <React.Fragment>
      <Helmet>
        <title>Search jobseekers eager to start their careers | Meetworks </title>
        <meta name='description' content='Search candidates looking for full time, part time, work from home and freelance opportunities for your required roles in various departments' />
        <meta name="keywords" content="post job listings for free, job portal for freshers, app for employers, hire candidate"></meta>
        <link rel='canonical' href="https://employer.meetworks.in" />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content="Meet" />
        <meta itemprop="description" content="Search candidates looking for full time, part time, work from home and freelance opportunities for your required roles in various departments" />
        <meta itemprop="image" content={ogImg} />

        {/* Open graph / facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://employer.meetworks.in/" />
        <meta property="og:title" content="Search jobseekers eager to start their careers | Meetworks" />
        <meta property="og:description" content="Search candidates looking for full time, part time, work from home and freelance opportunities for your required roles in various departments" />
        <meta property="og:image" content={ogImg} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://employer.meetworks.in/" />
        <meta property="twitter:title" content="Search jobseekers eager to start their careers | Meetworks" />
        <meta property="twitter:description" content="Search candidates looking for full time, part time, work from home and freelance opportunities for your required roles in various departments" />
        <meta property="twitter:image" content={ogImg} />
      </Helmet>
      {/* <div className={classes.sectionMobile}>
        <div style={{ backgroundColor: '#fafafa', width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: 70, position: 'absolute', top: 30 }}><img alt="meet logo" style={{ width: '100%' }} src={logo} /></div>
          <Typography style={{ padding: '30px 10px', background: '#5bc2a8', color: 'white', fontWeight: 500, textAlign: 'center' }}> Hello there! We are currently available only on desktop. You can use Meeत in the web browser of your laptop.</Typography>

        </div>
      </div> */}
      <div className={classes.sectionDesktop}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={MyTheme}>
            {/* <div className={classes.desktopNav}> */}
            <JobAppBar
              isSignedIn={isSignedIn}
              setIsSignedIn={handleSignedInStatus}
              handleDrawer={handleDrawer}
              navValue={navValue}
              setNavValue={setNavValue}
            >
            </JobAppBar>
            {/* </div> */}
            {/* <div className={classes.mobileNav}>
              <MobileAppBar
                isSignedIn={isSignedIn}
                setIsSignedIn={handleSignedInStatus}
                handleDrawer={handleDrawer}
                navValue={navValue}
                setNavValue={setNavValue}
              >
              </MobileAppBar>
            </div> */}
          </ThemeProvider>
          <div className={`${classes.bgOverlay}`}>
            {/* <ParticleBackground /> */}
            {/* <div className="bgOverlayAnim">
              <div className="animation-wrapper">
                <div className="particle particle-1"></div>
                <div className="particle particle-2"></div>
                <div className="particle particle-3"></div>
                <div className="particle particle-4"></div>
              </div>
            </div> */}
            <Typography variant='h5' component='h1' className={classes.pageTitle}>
              Find young people eager to start their careers
            </Typography>
            <div className={classes.centerAbsoluteDiv}>
              <SearchBarWrapper classes={classes} setTopCoursesList={setTopCoursesList} Roles={Roles} />
            </div>
          </div>
          <div onClick={() => setOpenJobPostingForm(true)} className={classes.jobPostBannerMobile}>
            {/* <Grid alignItems='center' wrap='nowrap' container>
              <Grid item container direction='column' >
                <Typography variant='h6' component='h2'>
                  Streamline Your Hiring Process
                </Typography>
                <Typography variant='body2' component='p'>
                  Streamline Your Hiring Process
                </Typography>
                <Button>
                  Post Job
                </Button>
              </Grid>
              <Grid item>
                <img src={postJob}/>
              </Grid>
            </Grid> */}
            <img src={jobPost} />
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h2' className={classes.sliderHeadings} >
              Find location wise jobseekers
            </Typography>
            <Slider
              data={topLocations}
              height='330px'
              width='19.1vw'
              segmentEvent='DiscoveryPage_slider_locationClick'
              classes={classes}
              maxCount={1}
              minCount={1}
              title='location'
              name='location'
            />
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h3' className={classes.sliderHeadings} >
              Search candidates as per job roles
            </Typography>
            <Slider
              data={Roles.slice(3, 13)}
              height='260px'
              width='19.1vw'
              containerHeight='310px'
              segmentEvent='DiscoveryPage_slider_roleClick'
              classes={classes}
              maxCount={1}
              minCount={1}
              title='title'
              name='role'
            />
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h4' className={classes.sliderHeadings} >
              Explore jobseekers as per qualifications
            </Typography>
            <Slider
              isQual={true}
              data={qualData}
              height='150px'
              containerHeight='155px'
              width='14.1vw'
              segmentEvent='DiscoveryPage_slider_qualificationClick'
              classes={classes}
              maxCount={1}
              minCount={1}
              title='title'
              name='course'
              courses={topCoursesList}
            />
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h4' className={classes.sliderHeadings} >
              Why should companies hire from Meeत?
            </Typography>
            <Grid container justifyContent='space-between' >
              {whyHire.map(item => {
                return <Grid key={item.title} className={classes.whyHireTiles} item container direction='column' >
                  <img src={item.illustration_image} width={350} height={200} alt={item.title} className={classes.whyHireImg} />
                  <Typography variant="body1"
                    className={classes.whyHireText}
                  // className={!props.isQual ? classes.sliderContent : classes.qualSliderContent} 
                  >
                    {item.title}
                  </Typography>
                </Grid>
              })}

            </Grid>
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h4' className={classes.sliderHeadings} >
              Companies that hired our jobseekers
            </Typography>
            <Grid justifyContent='center' container>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={jdLogo} width={200} height={150} alt='justdial' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={indiamartLogo} width={200} height={150} alt='indiamart' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={bigBasketLogo} width={200} height={150} alt='bigbasket' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={relianceLogo} width={200} height={150} alt='reliance' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={bluestarLogo} width={200} height={150} alt='bluestar' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={tvsLogo} width={200} height={150} alt='tvs' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={landmarkLogo} width={200} height={150} alt='landmark' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={forceLogo} width={200} height={150} alt='force' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={tradesmartLogo} width={200} height={150} alt='trademart' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={janaLogo} width={200} height={150} alt='jana' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={subrosLogo} width={200} height={150} alt='subros' />
              </Grid>
              <Grid className={classes.companyLogoContainer} item sm={2}>
                <img src={geminiLogo} width={200} height={150} alt='gemini' />
              </Grid>
            </Grid>
          </div>
          <div className={classes.lowerSectionDiv}>
            <Typography variant="h6" component='h4' style={{ fontSize: '1.3em',  margin: '0 0 1em 0', }} >
              About Us
            </Typography>
            <Grid className={classes.aboutContainer} container justifyContent='space-between' alignItems='center' >
              <Grid className={classes.aboutContent} item sm={6}>
                <Typography variant='body1'>
                  Meet is a job search portal incubated at Medha that enables Diploma and Graduate freshers passed out from government, aided and private colleges from Tier II and III cities to connect with verified job opportunities in their area, in pursuit of their dream career.
                  <br />
                  <br />
                  The platform caters to Hinglish (Hindi and English) speaking jobseekers of North India looking for full time, part time, work from home and freelance opportunities. Employers can post job listings for free on Meet job portal that ensures quality of employers who are listed on the platform through their ratings and reviews.
                </Typography>
              </Grid>
              <Grid item>
                <div className={classes.aboutImg} div />
              </Grid>
            </Grid>

          </div>
          {isMobile && <div className={classes.lowerSectionDiv}>
            <Typography gutterBottom variant="h6" component='h4' style={{ fontSize: '1.5em' }} >
              Follow us on social media
            </Typography>
            <Grid style={{ paddingBottom: '82px', width: '80%' }} container justifyContent='space-between' alignItems='center' >
              <a className={`${classes.socialLinks} ${classes.my1}`} target="_blank" href="https://www.instagram.com/meet_app/" rel="noopener noreferrer">
                <img style={{ marginBottom: '10px' }} src={InstaLogo} width={30} height={30} alt='meet-instagram' />
                <span>Instagram</span>
              </a>
              <a className={classes.socialLinks} target="_blank" href="https://www.facebook.com/meetworks.in" rel="noopener noreferrer">
                <img style={{ marginBottom: '10px' }} src={FbLogo} width={30} height={30} alt='meet-facebook' />
                <span>Facebook</span>
              </a>
              <a className={`${classes.socialLinks} ${classes.my1}`} target="_blank" href="https://www.linkedin.com/company/meetworks" rel="noopener noreferrer">
                <img style={{ marginBottom: '10px' }} src={LinkedinLogo} width={30} height={30} alt='meet-linkedIn' />
                <span>LinkedIn</span>
              </a>

            </Grid>

          </div>}
          <SignInUpDrawer
            canDrawerOpen={canDrawerOpen}
            refresh={refresh}
            isSignedIn={isSignedIn}
            //setIsSignedIn = {props.setIsSignedIn}
            setIsSignedIn={handleSignedInStatus}
            setForRefreshOnly={setForRefreshOnly}
            setCanDrawerOpen={setCanDrawerOpen}
            doRefresh={doRefresh}
          />
        </StylesProvider>
        <Footer />
        {openJobPostingForm &&
          <div>
            <Dialog classes={{ paperScrollPaper: classes.paperScrollPaper, container: classes.dialogContainer }} maxWidth='xl' open aria-labelledby="form-dialog-title">
              <DialogContent ref={jobFormDialogRef} classes={{root: classes.jobFormRoot}} style={{ padding: 0, margin: 0 }}>
                <MobileJobForm jobFormDialogRef={jobFormDialogRef} setOpenJobPostingForm={setOpenJobPostingForm} />
              </DialogContent>
            </Dialog>
          </div>
        }
      </div>
    </React.Fragment>
  );
}

