import React from 'react';
import Button from '@material-ui/core/Button';

const CustomPagination_3 = ({ currentRefinement, nbPages, refine, custom_ref }) => 
{

 const executeScroll = () => custom_ref.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" });

 // var count = 0; 
const [count, setCount] = React.useState(0); 
const style_1 = {
        display:  "inline" ,fontWeight:'bold', color: "grey", borderStyle:"solid", borderRadius: 3, borderColor: "grey", borderWidth: "thin", padding: 0
      };

return (
   <div style = {{display :"flex", flexDirection:"row"}}> 
  <Button style={style_1} onClick = {()=> {
         if (count >=1) 
            {
            setCount((prev)=> --prev)
            //console.log("count:::"+count);
            }
            }
            }> {"<"} </Button>  
  <ul style = {{ listStyleType: "none"}}>
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1;
      const style = {
        display: page > count && page <= count +10 ? "inline" : "none" ,fontWeight: currentRefinement === page ? 'bold' : '', color: currentRefinement === page? "grey" :"teal", marginRight: 40, marginBottom: "50px", marginTop: 50, borderStyle:"solid", borderRadius: 3, borderColor: "grey", borderWidth: "thin", padding: 5
      };

      return (
        <li key={index} style = {{float : "left"}}>
          <a
            href="#"
            style={style}
            onClick={event => {
              event.preventDefault();
              refine(page);
              executeScroll();  
            }}
          >
            {page} 
          </a>
        </li>
      );
    })}
  </ul>
  <Button style = {style_1} onClick = {() => {
             if (count <100)
            {
             setCount((prev)=> (++prev))
            }
            console.log("count:::"+ count);
            }
            }> {">"} </Button>  
</div>
);

}

export default CustomPagination_3;