import React, { useState, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WcIcon from '@material-ui/icons/Wc';
import SportsHockeyOutlinedIcon from '@material-ui/icons/SportsHockeyOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


import Popover from '@material-ui/core/Popover';

// import { CopyToClipboard } from 'react-copy-to-clipboard';
import SnackBarGeneric from '../views/talentpool/SnackBarGeneric';
import { NavigateBeforeSharp, ScatterPlotOutlined } from '@material-ui/icons';
import { FormControlLabel, Switch } from '@material-ui/core';
import { Spinner } from './JobOpenings';
import AnalyticsHandler from '../AnalyticsHandler';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet-async';

var BASE_URL = process.env.REACT_APP_API_ROOT;

const useStyles = makeStyles((theme) => ({
  tabBar: {
    alignItems: 'left',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -4px 12px rgba(0, 0, 0, 0.14)',
  },
  navTabBar: {
    flexGrow: 1,
    minHeight: 40,
  },
  navTab: {
    textTransform: 'capitalize',
    padding: 0,
    margin: ' 0 10px',
    minHeight: 40,
    minWidth: 100,
    [theme.breakpoints.down('md')]: {
      minHeight: 40,
      minWidth: 90,
    },
  },
  indicator: {
    height: 2,
  },
  labelIcon: {
    fontWeight: 400,
    color: "#AFAFAF",
    [theme.breakpoints.down('md')]: {
      color: theme.palette.grey[600],
      marginBottom: '0!important',
      fontSize: 12,
      '& .MuiTab-wrapper > *:first-child': {
        marginBottom: 0
      }
    },
  },
  selected: {
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },

  appBar: {
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.16)',
    top: 'auto',
    bottom: 0,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 'calc(80vh - 500px);',
    alignItems: 'center',
    flexDirection: 'column',
    height: '75vh',
  },
  pageInfoText: {
    color: "#555",
    lineHeight: '2em'
  },
  button: {
    textTransform: "none",
    width: 140,
  },
  button_pri: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button_pri_inactive: {
    backgroundColor: '#fff',
    color: theme.palette.primary.dark,
    borderRadius: 5,
    border: '1px solid',
    borderColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  section: {
    backgroundColor: theme.palette.background.paper,
    width: '70%',
    borderRadius: '6px',
    maxHeight: '75vh',
    minHeight: '75vh',
    overflow: 'auto',
    margin: '4.5em auto auto auto',
    '@media (max-width: 600px)': {
      width: '100%',
      margin: 0,
      maxHeight: '100vh',
      paddingBottom: '4em',
      height: '100vh',
      borderRadius: 0,
    }
  },
  verif_status: {
    borderRadius: '6px 6px 0px 0px',
    padding: '0.5rem 1rem',
  },
  sectionItem: {
    // padding: '8px 0px',
    padding: '0.5rem 1.5rem',
    // borderBottom: '1px solid',
    // borderBottomColor: theme.palette.grey[100],
    '&:last-child': {
      border: 'none',
    }
  },
  sectionItem_heading: {
    flexWrap: 'nowrap',
    marginBottom: '5px'
  },
  sectionItem_heading_icon: {
    marginRight: 5,
  },
  sectionItem_heading_icon2: {
    marginRight: 10,
    color: theme.palette.common.grey,
  },
  sectionItem_content: {
    flexWrap: 'wrap',
  },
  sectionItem_rating: {
    flexWrap: 'wrap',
    margin: '10px 0',
    '&:last-child': {
      marginBottom: 0,
    }
  },
  sectionItem_text_button: {
    fontWeight: 500,
    color: theme.palette.primary.dark,
  },
  cardFrame: {
    borderBottom: '1px solid #C4C4C4',
    // padding: '15px 0 8px',
    padding: '0.5rem 1rem 0 0.5rem',
    marginBottom: 10,
  },
  cardText: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  cardTag: {
    ...theme.typography.cardTag,
    width: 'auto',
    padding: '2px 8px',
    marginRight: 15,
    backgroundColor: '#5BC2A8',
    color: theme.palette.primary.contrastText,
    borderRadius: '4px 0 0 4px',
  },
  infoIcon: {
    '&, &:hover': {
      borderRadius: '0 4px 4px 0',
      background: theme.palette.primary.dark,
      padding: '6px 8px',
    }

  },
  cardinfoPopoverText: {
    background: theme.palette.primary.light,
    padding: theme.spacing(2),
  },
  chip: {
    fontSize: '0.815rem',
    fontWeight: 500,
    width: 'auto',
    padding: '2px 8px',
    margin: '6px 10px 0 0',
    backgroundColor: '#f5f5f5',
    color: theme.palette.primary.main,
    borderRadius: 4,
  },
  icon: {
    marginRight: 8,
    color: theme.palette.common.blackDark,
  },
  cardIcon: {
    marginRight: 8,
  },
  dayChip: {
    color: '#5BC2A8',
    backgroundColor: '#D5F5ED',
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
    textAlign: 'center'
  },
  dayChip_inactive: {
    color: theme.palette.common.grey,
    backgroundColor: theme.palette.grey[100],
    width: 22,
    height: 22,
    lineHeight: '22px',
    padding: '0 5px',
    borderRadius: 2,
    marginRight: 10,
  },
  highlight: {
    color: theme.palette.common.blackLight,
    fontWeight: 500,
  },
  tileHeading: {
    ...theme.typography.tileHeading,
    fontSize: '0.9375rem',
    color: theme.palette.common.blackDark,
  },
  tileRatingText: {
    ...theme.typography.tileRatingText,
  },
  tileRatingStar: {
    fontSize: '1.2rem',
    color: theme.palette.common.star,
    marginRight: 4,
  },
  imageTag: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxWidth: '100%',
    transition: 'opacity 0.2s ease-in-out',
    pointerEvents: "none",
  },
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    overflow: 'hidden',
    margin: 0,
    borderRadius: 6,
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.25)',
  },
  flexContainer: {
    justifyContent: 'center'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  ellipsisPost2Lines: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 4,
    color: theme.palette.common.greyMedium,
  },
  invite_button: {
    borderColor: theme.palette.primary.main,
    padding: '4px 15px',
    marginRight: '0.5rem !important'
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row !important',
  },
  flexboxCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  mr1: {
    marginRight: '0.5rem !important'
  },
  fontSize_medium: {
    fontSize: '0.9rem'
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
  ttc: {
    textTransform: 'capitalize',
  },
  job_status: {
    borderTop: '1px solid #AFAFAF',
    // padding: '8px 0',
    padding: '0.5rem 2rem',
    width: '103%',
    margin: '0 -1em'
  },
  d_inline_block: {
    display: 'inline-block'
  },
  slideBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #14967B',
    cursor: 'pointer'
  },
  next: {
    right: '5%',
  },
  prev: {
    left: '5%',
  },
  backBtn: {
    position: 'absolute',
    top: '7%',
    left: '2%',
    color: '#fff',
    zIndex: 1500
  },
  disabledBtn: {
    opacity: '0.5',
    pointerEvents: 'none',
  },

}));

const axios = require('axios');
export default function JobDetail(props) {
  const { openRoles, closedRoles } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [response, setResponse] = React.useState([]);
  const [jobsCountForARole, setJobsCountForARole] = useState(0);
  const companyRef = useRef(null);
  const jobDetailRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // let jobAudio = new Audio(jobAudioFile);

  document.body.style.overflow = "scroll";

  const handleSalaryInfoClick = (event) => {
    AnalyticsHandler.track('JobDetail_infoSalary_BtnClick');
    setAnchorEl(event.currentTarget);
  };

  const handleSalaryInfoClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);

  // get details of a particular job
  const getJobDetailsById = async () => {
    await axios.post(`${BASE_URL}/companies/getJobDetails`, { _id: props.currentJobId })
      .then(res => {
        setResponse(res.data);
        props.setJobPreviewLoader(false);
      })
  }

  React.useEffect(() => {
    getJobDetailsById()
  }, [])
  let working_days_temp = response.working_days, i = 0,
    working_days = [{
      day: "M",
      present: false
    },
    {
      day: "T",
      present: false
    },
    {
      day: "W",
      present: false
    },
    {
      day: "T",
      present: false
    },
    {
      day: "F",
      present: false
    },
    {
      day: "S",
      present: false
    },
    {
      day: "S",
      present: false
    }
    ]
  if (response.working_days) {
    for (; i < working_days_temp.length; i++) {
      if (working_days_temp[i] == "MON")
        working_days[0].present = true;
      if (working_days_temp[i] == "TUE")
        working_days[1].present = true;
      if (working_days_temp[i] == "WED")
        working_days[2].present = true;
      if (working_days_temp[i] == "THU")
        working_days[3].present = true;
      if (working_days_temp[i] == "FRI")
        working_days[4].present = true;
      if (working_days_temp[i] == "SAT")
        working_days[5].present = true;
      if (working_days_temp[i] == "SUN")
        working_days[6].present = true;
    }
    // debugger;
  }

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  return (
    <>
      <Helmet>
        <title>{response.job_title}</title>
        <meta name='description' content='Latest jobs posted by employer.' />
      </Helmet>
      {/* 
      <div>{`The URL is "${match.url}"`}</div>
      <div>{`It matched the path "${match.path}"`}</div>
      <div>{`The parameter is "${match.params._id}"`}</div>
    </div> */}

      {isLoading
        ? <div style={{
          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1
        }}>
          {/* <ListLoader setListLoaderRef={setListLoaderRef} isText={"none"} /> */}
        </div>
        :
        <>

          {/* About Job */}
          <div id="jobDetailRef_id" ref={jobDetailRef} className={classes.section} style={{ position: 'relative', paddingBottom: 20 }}>
            {props.jobPreviewLoader &&
              <Grid
                container
                justifyContent="center"
                alignItems='center'
                style={{ minHeight: '70vh', position: 'fixed', left: '0' }}
              >
                <Spinner />
              </Grid>
            }
            {!props.jobPreviewLoader &&
              <div>
                {response.job_created_at &&
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className={`${classes.verif_status} ${classes.flexbox} ${classes.justify_space_bw}`}
                    style={{ backgroundColor: response.isVerified ? '#D5F5ED' : '#E0E0E0' }}
                  >

                    <Typography variant='body1' component='span' className={`${classes.flexbox} ${classes.fontSize_medium}`} style={{ color: response.isVerified ? '#14967B' : '#797979' }} >
                      <VerifiedUserIcon fontSize='small' className={classes.mr1} />
                      {/* Verified */}
                      {response.isVerified ? 'Verified' : 'Pending Verification'}
                    </Typography>
                    <Typography variant='body1' color='primary' component='span' className={`${classes.flexbox} ${classes.fontSize_medium}`} >
                      Updated: {props.createDate(response.job_created_at)}
                    </Typography>
                  </Grid>
                }

                {/* intro tile */}
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  className={classes.cardFrame}>
                  {response.job_title ?
                    <Grid
                      container
                      wrap='nowrap'
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ marginBottom: 12, padding: '0 10px' }}>
                      <Grid item>
                        <Typography component='h3' variant='h6'>{response.job_title} </Typography>
                      </Grid>
                      <Grid item container alignItems='center' justifyContent='flex-end' style={{ width: '30%' }}>
                        <Grid item>
                          <EditOutlinedIcon color='primary' className={`${classes.mr1} ${classes.cursor_pointer}`}
                            onClick={() => {
                              // props.setDisplay('none');
                              AnalyticsHandler.track('JobPostingForm_preview_modalBox_editJobBtn')
                              props.setJobPreviewModal(false)
                              props.setJobUpdateLoader(true);
                              props.setShowJobUpdatePage(true);
                              if (props.jobFormDialogRef) props.jobFormDialogRef.current.style.overflow = 'hidden';
                              // props.setJobFormModalLoader(true);
                              props.getJobDataToUpdateJob(response);
                            }}
                          />
                        </Grid>
                        <CopyToClipboard text={`${process.env.REACT_APP_NODE_ENV === 'development' ? 'https://jsdev.meetworks.in' : 'https://findwork.meetworks.in'}/jobDetail/${response.job_title}-${localStorage.getItem('company_name')}-${response._id}`}>
                          <ShareOutlinedIcon color='primary' className={`${classes.cursor_pointer}`}
                            onClick={() => {
                              // AnalyticsHandler.track('JobOpenings_share_btn'); 
                              handleSnackBar(`Link copied. Share now!`)
                            }}
                          />
                        </CopyToClipboard>
                      </Grid>

                    </Grid> : null}
                  {response.job_salary_amount ?
                    <Grid
                      item xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ marginBottom: 12, padding: '0 10px' }}>
                      <Grid
                        item xs={12}
                        container
                        justifyContent="flex-start"
                        alignItems="center">
                        <Typography
                          className={`${classes.cardTag} ${classes.ellipsis}`}
                          style={{ maxWidth: '90%', width: 'unset', marginRight: 0 }}>
                          {/* {response.job_salary_amount.includes('₹') ? response.job_salary_amount : `₹ ${response.job_salary_amount}`} */}
                          {response.job_salary_amount && (response.job_salary_amount !== '' || response.job_salary_amount > 0) ? (response.job_salary_amount.includes('₹') ? response.job_salary_amount : `₹ ${response.job_salary_amount}`) : 'No fixed salary'}
                          {response.job_salary_amount > 0 && <span style={{ fontWeight: 400, fontSize: 12 }}> /month</span>}
                        </Typography>
                        <IconButton className={classes.infoIcon} id="infoSalaryButton" aria-describedby="infoSalary_id" variant="contained" color="primary" onClick={handleSalaryInfoClick}>
                          <InfoOutlinedIcon style={{ fontSize: '1rem', color: 'white' }}></InfoOutlinedIcon>
                        </IconButton>

                        <Popover
                          id="infoSalary_id"
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleSalaryInfoClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Typography className={classes.cardinfoPopoverText}>
                            <span style={{ fontWeight: 400, fontSize: 12, }} >
                              {/* {response.job_salary_amount.includes('₹') ? response.job_salary_amount : `₹ ${response.job_salary_amount}`} */}
                              {response.job_salary_amount && (response.job_salary_amount !== '' || response.job_salary_amount > 0) ? (response.job_salary_amount.includes('₹') ? response.job_salary_amount : `₹ ${response.job_salary_amount}`) : 'No fixed salary'}
                            </span>
                            {(response.job_salary_amount !== '' || response.job_salary_amount > 0) && <span style={{ fontWeight: 400, fontSize: 12, }}> /month</span>}
                            {response.variable_salary_amount && <span style={{ fontWeight: 400, fontSize: 12, }}> + {response.variable_salary_amount.includes('₹') ? response.variable_salary_amount : `₹ ${response.variable_salary_amount}`} /</span>}
                            {response.variable_salary_amount && <span style={{ fontWeight: 400, fontSize: 12, }}>{response.variable_salary_unit ? response.variable_salary_unit : ""}</span>}
                          </Typography>
                        </Popover>
                        {/* <WorkOutlineRoundedIcon fontSize="small" className={classes.icon} /> */}
                      </Grid>
                    </Grid> : null}
                  <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: 10, padding: '0 10px' }}>
                    {response.opportunity_type ?
                      <Typography className={`${classes.cardText} ${classes.ttc}`}
                        style={{ color: response.opportunity_type.includes('ob') ? 'rgb(249, 150, 45)' : response.opportunity_type.includes('nternship') ? 'rgb(20, 150, 123)' : response.opportunity_type.includes('renticeship') ? 'rgb(114, 86, 179)' : response.opportunity_type.includes('reelanc') ? 'rgb(94, 150, 221)' : '#333', display: 'flex', alignItems: 'center' }}
                      >
                        <WorkOutlineRoundedIcon fontSize="small" className={classes.icon} />
                        {response.opportunity_type}</Typography>
                      : null}
                    {/* <HourglassEmptyRoundedIcon fontSize="small" className={classes.icon} style={{ marginLeft: 15 }} /> */}
                    {(response.opportunity_type && response.internship_duration) || (response.opportunity_type && response.no_of_vacancies) ? <span style={{ margin: '0 10px' }}>·</span> : null}
                    {/* {response.internship_duration ? <Typography className={`${classes.cardText}`}>{response.internship_duration}</Typography> : null}
                    {response.internship_duration && response.no_of_vacancies ? <span style={{ margin: '0 10px' }}>·</span> : null} */}
                    {response.no_of_vacancies ? <Typography className={classes.cardText}>{response.no_of_vacancies} {response.no_of_vacancies == 1 ? "Opening" : "Openings"} </Typography> : null}
                  </Grid>

                  {response.job_location ? <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: 5, padding: '0 10px' }}>
                    <LocationOnOutlinedIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.cardText}>
                      {typeof response.job_location !== 'string' ? response.job_location.map((item, i) => {
                        if (i < response.job_location.length - 1) {
                          return ' ' + item.city
                        } else {
                          if (response.isWorkFromHome) {
                            return ' ' + item.city + ', '
                          } else {
                            return ' ' + item.city
                          }
                        }
                      }) + (response.isWorkFromHome ? 'Work From Home' : '') : response.job_location}
                    </Typography>
                    {/* {response.job_created_at ?
                  <Typography variant="caption" style={{ marginLeft: 'auto' }}>Updated: <span>{jobUpdatedDate}</span></Typography>
                  : null} */}

                  </Grid> : null}
                  {/* {props.job[props.jobIndex].enable_status &&  */}
                  {/* {response.isWorkFromHome ? <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: 5, padding: '0 10px' }}>
                    <LocationOnOutlinedIcon fontSize="small" className={classes.icon} />
                    <Typography className={classes.cardText}>Work from home</Typography>
                  </Grid> : null} */}
                  <Grid
                    className={`${classes.job_status}`}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div className={classes.d_inline_block}>
                      <Typography component='span' className={`${classes.color_light_grey} ${classes.mr3}`}>Status: </Typography>
                      <FormControlLabel
                        control={<Switch color='primary' name="reviews" />}
                        label={response.enable_status ? 'Open' : 'Closed'}
                        labelPlacement='end'
                        checked={response.enable_status ? true : false}
                        onChange={() => {
                          let company_id = localStorage.getItem('company_id').toString()
                          let jobpost_id = response._id;
                          let data;
                          data = { ...response, jobpost_id, company_id }
                          delete data._id
                          delete data.companies
                          AnalyticsHandler.track("JobOpenings_jobStatusBtn");
                          props.setOpenConfirmDialog(true);
                          props.setCurrentJob(response);
                          props.setJobData(data);
                        }}
                        className={classes.settings_label}
                      />
                    </div>
                    <div className={classes.d_inline_block}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={`${classes.mr1} ${classes.ttc} ${classes.invite_button}`}
                        startIcon={<GroupAddIcon />}
                        onClick={() => {
                          AnalyticsHandler.track("JobPostingForm_preview_modalBox_invite");
                          let mapped_role = response.job_title;
                          mapped_role = mapped_role.includes('/') ? mapped_role.replaceAll('/', '-') : mapped_role;
                          let location = response.location;
                          var newPath;
                          if (location && location.length > 0) {
                            newPath = `/discover/${mapped_role}/${location[0].city}`;
                          } else if (response.isWorkFromHome) {
                            newPath = `/discover/${mapped_role}/anywhere`;
                          } else {
                            newPath = `/discover/${mapped_role}/anywhere`;
                          }
                          history.push({
                            pathname: newPath,
                          })
                        }}
                      >
                        Invite jobseekers
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        className={`${classes.ttc} ${classes.invite_button}`}
                        startIcon={<GroupAddIcon />}
                        onClick={() => {
                          AnalyticsHandler.track("JobOpenings_viewApplicantsBtn");
                          if (response.enable_status === true) {
                            props.getJobApplicants(props.jobIndex, openRoles)
                          } else {
                            props.getJobApplicants(props.jobIndex, closedRoles)
                          }
                          // to invite candidates if no applicants found
                          props.setCurrentJobLocation(response.job_location);
                          props.setCurrentJobStatus(response.enable_status.toString());
                        }}
                      >
                        Applicants
                      </Button> */}
                    </div>
                  </Grid>


                  {/* <Grid
                item xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginBottom: 10 }}>
                <VacancyIcon fontSize="small" className={classes.icon} />
                <Typography className={classes.cardText}>20 Vacancies</Typography>
              </Grid> */}

                  {response.job_created_at ? <Grid
                    item xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                      //padding: '8px 10px 0',
                      padding: '0 10px 0',
                      // borderTop: '1px solid',
                      // borderTopColor: theme.palette.primary.main
                    }}>

                    {/* 
                  <Typography variant="caption">Updated: <span>{new Date(response.job_created_at).getDate() + " " + months[new Date(response.job_created_at).getMonth() - 1] + ", " + new Date(response.job_created_at).getFullYear()}</span></Typography> */}

                  </Grid> : null}
                </Grid>

                {/* Benefits */}
                {response.work_benefits && response.work_benefits[0] && response.work_benefits[0] != "" ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      {/* <BenefitsIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} /> */}
                      {/* import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'; */}
                      <ScatterPlotOutlined fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">
                        Perks and Benefits
                      </Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      {response.work_benefits.map((item, index) =>
                        <Typography key={index} className={classes.chip}>{item}</Typography>
                      )}
                    </Grid>
                  </Grid> : null}

                {/* Working Days */}
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <DateRangeOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="body2" component='h4' color="primary">Working Days</Typography>
                  </Grid>
                  {response.working_days && response.working_days[0] && response.working_days[0] !== "" ?
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      <Typography className={(working_days && working_days[0].present) == true ? classes.dayChip : classes.dayChip_inactive}>M</Typography>
                      <Typography className={(working_days && working_days[1].present) == true ? classes.dayChip : classes.dayChip_inactive}>T</Typography>
                      <Typography className={(working_days && working_days[2].present) ? classes.dayChip : classes.dayChip_inactive}>W</Typography>
                      <Typography className={(working_days && working_days[3].present) ? classes.dayChip : classes.dayChip_inactive}>T</Typography>
                      <Typography className={(working_days && working_days[4].present) ? classes.dayChip : classes.dayChip_inactive}>F</Typography>
                      <Typography className={(working_days && working_days[5].present) ? classes.dayChip : classes.dayChip_inactive}>S</Typography>
                      <Typography className={(working_days && working_days[6].present) == true ? classes.dayChip : classes.dayChip_inactive}>S</Typography>
                    </Grid> : <Typography variant="body1">Flexible</Typography>
                    // response.working_days && response.working_days.length === 0 ?
                    //   <Typography variant="body1">Flexible</Typography> : null
                  }
                </Grid>

                {/* Timings */}
                <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                  <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                    <ScheduleOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                    <Typography variant="body2" component='h4' color="primary">Timings</Typography>
                  </Grid>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                    {/* <Typography variant="body1"><span className={classes.highlight}>Shift A</span>: 7:30 am - 4:00 pm</Typography>
                 */}
                    <Typography variant="body1">{response.working_time ? response.working_time : "Flexible"}</Typography>
                  </Grid>
                </Grid>

                {/* Description */}
                {response.job_description ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <FormatListBulletedRoundedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">Job Details</Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      <Typography variant="body1" style={{ listStyle: "circle", whiteSpace: "pre-line" }}>{response.job_description}
                      </Typography>
                    </Grid>
                  </Grid>
                  : null}

                {/* Skills */}
                {response.skills_required && response.skills_required[0] && response.skills_required[0] !== "" ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <SportsHockeyOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">Skills Required </Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" direction='column' alignItems="flex-start" className={classes.sectionItem_content} >
                      {response.skills_required && response.skills_required.map((item, index) =>
                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                          {item.skill && <Typography className={classes.chip}>{item.skill}</Typography>}
                          {item.no_of_experience && <Typography style={{ marginRight: '10px' }}>-</Typography>}
                          {item.no_of_experience && <Typography className={classes.chip}>{item.no_of_experience}</Typography>}
                          {item.sector && <Typography style={{ marginRight: '10px' }}>-</Typography>}
                          {item.sector && <Typography className={classes.chip}>{item.sector}</Typography>}
                        </div>
                      )}
                    </Grid>
                  </Grid> : null}

                {/* Qualification */}
                {response.qualification_req && response.qualification_req[0] && response.qualification_req[0] !== "" && Object.keys(response.qualification_req[0]).length !== 0 ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <SchoolOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">Preferred Qualification(s)</Typography>
                    </Grid>

                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      {response.qualification_req && response.qualification_req.map((item, index) =>
                        <Typography key={index} variant="body1">{index === 0 ? (item.includes("any - any") ? 'Any' : item) : ", " + item}
                          {/* {index != response.qualification_req.length - 1 ? ", " : ""}  */}
                        </Typography>
                      )}
                    </Grid>
                  </Grid> : null}

                {/* Experience */}
                {/* {response.experience_required ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <WorkOutlineRoundedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="h4" color="primary">Experience Range</Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      <Typography variant="body1">{response.experience_required}</Typography>
                    </Grid>
                  </Grid> : null} */}

                {/* Other Requirements */}
                {response.candidate_requirement ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      <MoreHorizIcon color="primary" style={{ border: '1px solid #14967b', borderRadius: '50%', fontSize: '1rem' }} className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">Other Requirements </Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} >
                      {/* <ul style={{listStyle: 'circle', margin: 0, paddingLeft: 20}}> */}
                      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>{response.candidate_requirement}.</Typography>
                      {/* <Typography variant="body1" component="li">Character Certificate.</Typography> */}
                      {/* </ul> */}
                    </Grid>
                  </Grid> : null}

                {/* Gender */}
                {(response.gender_pref && response.gender_pref[0] != "") ?
                  <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.sectionItem}>
                    <Grid item xs container justifyContent="flex-start" alignItems="center" className={classes.sectionItem_heading} >
                      {/* <GenderOutlinedIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} /> */}
                      <WcIcon fontSize="small" color="primary" className={classes.sectionItem_heading_icon} />
                      <Typography variant="body2" component='h4' color="primary">Gender Preference</Typography>
                    </Grid>
                    <Grid item xs container justifyContent="flex-start" alignItems="flex-start" className={classes.sectionItem_content} ><Typography variant="body1">
                      {response.gender_pref.length > 0 && response.gender_pref.map((item, index) => (
                        <span key={index}>
                          {item.includes('ale') ? item + ' ' + 'only' : item}
                          {/* {index != (response.gender_pref.length - 1) ? "," : ""} {index == (response.gender_pref.length - 1) && index == 0 ? "only." : ""} */}
                        </span>))}
                      <span style={{ opacity: '0.75' }}>{response.gender_pref_reason ? " (" + response.gender_pref_reason + ")" : null}</span></Typography>
                    </Grid>
                  </Grid> : null}

                {/* to set scroll ref for company section tab */}
                <div style={{ position: 'absolute', bottom: 80 }} ref={companyRef}></div>


                {/* About Company */}



                {/* Button for Similar jobs in this role */}
                <div style={{ marginTop: 0, paddingTop: 0, paddingBottom: 70 }}>
                  {response && response.mapped_role && jobsCountForARole && jobsCountForARole > 1 ?
                    <Grid item xs={12} container justifyContent="center">
                      <Button
                        id="MoreButton"
                        size="medium"
                        style={{
                          backgroundColor: '#fff', fontSize: '0.9375rem',
                          color: theme.palette.common.blackDark, textTransform: 'unset', width: '100%'
                        }}
                        onClick={() => {//send role and back button flag via location object to role specific jobs list page
                          history.push({
                            //pathname: `/findwork`,
                            pathname: `/${response.mapped_role}`,
                            state: {
                              role: response.mapped_role,
                              goBackToPrev: true,
                            }
                          });
                        }
                        }
                      >
                        और {response.mapped_role} openings देखें
                        <ChevronRightRoundedIcon fontSize="small" color="primary" style={{ marginLeft: 5 }} />
                      </Button>
                    </Grid>
                    : null}
                </div>
              </div>}
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
          </div>

          {/* Bottom App Bar */}

        </>
      }

      {props.isMobile ? <div style={{ display: 'flex', position: 'fixed', top: 0, left: 0, color: '#333', cursor: 'pointer', zIndex: '1500', backgroundColor: '#fff', width: '100%', height: 65, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.17)', alignItems: 'center', justifyContent: 'flex-start' }}>
        <ArrowBackIcon fontSize='small' style={{ color: '#333', cursor: 'pointer', zIndex: '1500', padding: 5 }}
          onClick={() => {
            props.handleBack();
            if (props.jobFormDialogRef) props.jobFormDialogRef.current.style.overflow = 'auto';
          }} /> Back to posted job
      </div> :
        <ArrowBackIcon fontSize='large' className={`${classes.cursor_pointer} ${classes.mr1}`} style={{ color: '#fff', zIndex: '1500', padding: 5, position: 'fixed', top: '9%', left: 0, }} onClick={props.handleBack} />}
    </>
  )
}