import React from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
// import TextEditor from '../../components/TextEditor';

function Description(props) {
    const { classes, values, setValues, errors, setErrors } = props;
    // const change = (state) => {
    //     // More info about EditorState object at
    //     // https://draftjs.org/docs/api-reference-editor-state
    //     //
    //     // Get current selection
    //     // console.log(state.getSelection())

    //     // Get current content
    //     // console.log(JSON.stringify(state.getCurrentContent()))

    //     // Get current text
    //     // console.log(state.getCurrentContent().getPlainText());
    //     let stringText = state.getCurrentContent().getPlainText();
    //     setValues({ ...values, description: stringText });

    //     // Check if editor is empty
    //     // if (!state.getCurrentContent().hasText()) {
    //     //     console.log("empty")
    //     // }
    // }
    return <Grid item xs={12} className={classes.my4} >
        <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Description<span style={{ color: '#f44336' }}>*</span></Typography>
        {/* <TextEditor
            label="Tell us more about the role..."
            onChange={change}
        /> */}
        <TextField
            multiline
            fullWidth
            variant='outlined'
            minRows={props.minRows}
            className={classes.description_textField}
            error={errors.job_description ? true : false}
            helperText={errors.job_description ? errors.job_description : ''}
            value={values.job_description || values.job_description}
            placeholder="Tell us more about the role..."
            onChange={e => {
                setErrors({});
                setValues({ ...values, job_description: e.target.value })
            }}
        />
    </Grid>;
}

export default Description;