import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Radio, RadioGroup, Tooltip, TextField, InputBase, Snackbar, FormHelperText } from '@material-ui/core';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import AnalyticsHandler from '../AnalyticsHandler';
import styled from 'styled-components';
import InfoIcon from '@material-ui/icons/Info';
import SnackBarGeneric from '../views/talentpool/SnackBarGeneric';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import RemoveIcon from '@material-ui/icons/Remove';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import Location from '../views/jobs/Location';
import { Helmet } from 'react-helmet-async';
import { ArrowBack } from '@material-ui/icons';

const filter = createFilterOptions();
let newHashMap = []
const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  font-size: 0.75rem;
  color: #797979;
`;

const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.3em',
            backgroundColor: '#D5F5ED'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "23px",
        },
        '.MuiAutocomplete-tag': {
            backgroundColor: '#fff',
            border: '1px solid #55c6a9'
        },
        '.MuiChip-deleteIcon': {
            color: theme.palette.secondary.main
        },
        '.MuiFormControlLabel-labelPlacementStart': {
            marginLeft: '0px'
        },
        '.MuiTabs-flexContainer': {
            justifyContent: 'center'
        },
        '.MuiBox-root': {
            padding: 0
        },
        '.MuiTabs-indicator': {
            display: 'none !important'
        },
        '.MuiTab-textColorInherit.Mui-selected': {
            color: '#5BC2A8'
        },
        '.MuiTab-textColorInherit': {
            color: '#AFAFAF',
            opacity: '1'
        },
        '.MuiTab-root': {
            '@media (min-width: 600px)': {
                minWidth: '10vw'
            }
        },
    },
    company_card: {
        backgroundColor: 'white',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        // minHeight: '85vh',
        // maxHeight: '72vh',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        margin: '2em auto 0 auto',
        maxWidth: '69vw',
        minWidth: '60vw',
        // minHeight: '80vh',
        borderRadius: '8px',
        [theme.breakpoints.down('md')]: {
            maxWidth: '70vw',
            minWidth: '70vw',
        },
        '@media (max-width: 600px)': {
            minWidth: '93vw',
            boxShadow: 'none',
            border: '1px solid #55c6a9',
            // marginBottom: '4em',
            margin: '5em auto 4em auto',
            overflowX: 'hidden'
        }
    },
    overview: {
        padding: '2em 4em',
        '@media (max-width: 600px)': {
            padding: '0 1em'
        }
    },
    review: {
        padding: '2em 4em',
    },
    company_info: {
        maxHeight: '21vh'
    },
    skillsExpInput: {
        '& div': {
            '& input': {
                padding: 9
            }
        }
    },
    gender_pref_field: {
        '& div': {
            '& input': {
                padding: '1em 0'
            }
        }
    },
    show: {
        display: 'flex',
        backgroundColor: '#55c6a93d',
        color: '#14967B',
        padding: '0 8px',
        position: 'absolute',
        borderRadius: '3px',
        top: '1.5%',
        right: '3%',
        width: '30%',

        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '2px',
            left: '-7px',
            borderRight: '7px solid #55c6a93d',
            borderBottom: '6px solid transparent',
            borderTop: '7px solid transparent'
        }
    },
    hidden: {
        display: 'none'
    },
    workTypeOptions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '0.35em',
        flexFlow: 'wrap',
        width: '100%'
    },
    mb1: {
        marginBottom: '1em !important'
    },
    vidJdLinks: {
        fontWeight: 400,
        textDecoration: 'none',
        color: '#55c6a9',
    },
    helper_text: {
        color: "#AFAFAF",
        fontSize: '0.9rem',
        fontWeight: 300,
        display: 'block',
    },
    radioColor: {
        color: '#55c6a9'
    },
    disableDiv: {
        pointerEvents: 'none',
        opacity: '0.45'
    },
    formControl: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    timePicker: {
        // width: '21%',
        width: '30%',
        marginTop: 6,
        '& > div': {
            fontSize: '14px',
            '& > div': {
                marginLeft: -10,
                '& button': {
                    padding: 0,
                    '& > span': {
                        '& > svg': {
                            fontSize: '0.7em'
                        }
                    }
                }
            }
        }
    },
    timeFormControl: {
        flexDirection: 'row !important',
    },
    days: {
        width: '25px',
        height: '25px',
        border: '1px solid #5BC2A8',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#797979',
        borderRadius: '2px',
        marginRight: '1em',
        boxShadow: '0px 2px 4px rgba(20, 150, 123, 0.1)',
        '& label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.72em',
            wordBreak: 'break-word',
            width: 'inherit',
            height: 'inherit',
            borderRadius: '3px',
            margin: '0 0 -1px -1px',
            '&:hover': {
                cursor: 'pointer'
            },
        },
        '& input[type="checkbox"]': {
            display: 'none',
            '&:checked + label': {
                color: '#fff',
                backgroundColor: '#5BC2A8',
            }
        },
        '@media (max-width: 600px)': {
            marginRight: 5
        }
    },
    TaPerks: {
        '& textarea': {
            textAlign: 'center',
            backgroundColor: '#F5F5F5',
            paddingTop: '1em',
            height: '4vh',
            borderRadius: 1
        }
    },
    TaRootSrcForm: {
        margin: '0 3px',
        padding: 0,
        '& textarea': {
            backgroundColor: '#F5F5F5',
            borderRadius: '1px',
            color: '#797979',
            fontSize: '1em',
            textAlign: 'center',
            paddingTop: '1em',
            width: '50vw',
            '&:focus': {
                border: '1px solid #5BC2A8'
            }

        }
    },
    editInput: {
        width: '70%',
        '& input': {
            padding: '12px 6px',
        },
    },
    emailAlert: {
        position: 'absolute',
        padding: '0px',
        backgroundColor: '#55c6a9d4',
        zIndex: 9,
        right: 0,
        '& div:nth-child(1)': {
            padding: theme.spacing(1),
            color: '#fff'
        },
        '& div:nth-child(2)': {
            padding: '0 16px 0 0',
        },
    },
    editDetailsIcon: {
        position: 'absolute',
        bottom: '0.2em',
        right: '-1.2em',
        cursor: 'pointer',
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center'
    },
    flexboxCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    justify_flexEnd: {
        justifyContent: 'flex-end'
    },
    justify_flexStart: {
        justifyContent: 'flex-start'
    },
    justify_space_bw: {
        justifyContent: 'space-between'
    },
    justify_center: {
        justifyContent: 'center'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    mr1: {
        marginRight: '1rem'
    },
    mr2: {
        marginRight: '0.5rem'
    },
    my1: {
        margin: '1rem 0'
    },
    my2: {
        margin: '2rem 0'
    },
    my0: {
        margin: '0.6em 0'
    },
    mt1: {
        marginTop: '1em'
    },
    mt2: {
        marginTop: '2em'
    },
    px1: {
        padding: '0.5rem 0'
    },
    py1: {
        padding: '0.5rem 0'
    },
    width_100: {
        width: '100%'
    },
    fontSize_small: {
        fontSize: '0.8rem'
    },
    fontSize_medium: {
        fontSize: '0.9rem'
    },
    fontSize_regular: {
        fontSize: '1rem'
    },
    fontSize_large: {
        fontSize: '1.1rem'
    },
    fontSize_xl: {
        fontSize: '1.2rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    semi_bold: {
        fontWeight: '500'
    },
    color_primary: {
        color: theme.palette.primary.main
    },
    color_secondary: {
        color: theme.palette.secondary.main
    },
    cursor_pointer: {
        cursor: 'pointer'
    },
    ttc: {
        textTransform: 'capitalize'
    },
    ttn: {
        textTransform: 'none'
    },
    helperText: {
        color: '#AFAFAF'
    },
    tabs_div: {
        flexGrow: 1,
        // padding: '2em 0',
        // borderTop: `1px solid ${theme.palette.divider}`,
        // marginTop: '2em'
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        width: '100%',
        marginTop: '-0.9em',
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: 99,
        boxShadow: '0px 4px 6px rgb(0 0 0 / 17%)',
        '& div': {
            '& div': {
                '& button': {
                    minHeight: '40px !important',
                    paddingTop: '15px !important',
                    '& span': {
                        textTransform: 'capitalize !important',
                        '& svg': {
                            marginRight: '6px',
                            marginBottom: '0 !important',
                        }
                    }
                }
            }
        }
    },
    tab_root: {
        flexDirection: 'row',
    },
    // overview: {
    //     padding: '0px 25px',
    // },
    company_type: {
        justifyContent: 'space-evenly',
    },
    settings_label: {
        '& span:last-child': {
            fontSize: '0.88rem',
        },
        marginTop: '-5px',
        justifyContent: 'space-between',
    },
    cw_details: {
        position: 'relative',
        border: '1px solid #5BC2A8',
        borderRadius: '4px',
        padding: theme.spacing(2),
    },
    editIcon: {
        position: 'absolute',
        right: '2%',
        top: '5%',
        cursor: 'pointer',
    },
    doc_upload: {
        border: '1px solid #AFAFAF',
        borderRadius: '4px',
        padding: '2px 10px',
        width: '50%',
        overflow: 'hidden',
        height: '1em'
    },
    file_upload: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '70%',
    },
    togBtnSizeSmall: {
        padding: '1px 12px',
        backgroundColor: '#FFFFFF',
        marginLeft: '0!important',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        }
    },
    togBtnLabel: {
        fontSize: 14,
        textTransform: 'capitalize',
        fontWeight: 400,
        color: '#555',
    },
    togBtnSelected: {
        backgroundColor: '#14967B!important',
        color: '#ffffff!important',
        '& > span': {
            color: '#ffffff!important',
        }
    },
    applicant_profile: {
        '& > div': {
            margin: '15px auto 15px auto !important'
        }
    },
    slideBtn: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        borderRadius: '50%',
        border: '1px solid #14967B',
        cursor: 'pointer',
        zIndex: 99
    },
    next: {
        right: '5%',
    },
    prev: {
        left: '5%',
    },
    disabledBtn: {
        display: 'none'
    },
    position_relative: {
        position: 'relative'
    },
    // viewChanger: {
    //     position: 'sticky',
    //     top: '26vh',
    //     backgroundColor: '#fff',
    //     padding: '1em 0.1em',
    //     zIndex: 99
    // },
    qualIcons: {
        display: 'inline-block',
        '@media (max-width: 600px)': {
            display: 'none'
        }
    },
    scrFormLabel: {
        '@media (max-width: 600px)': {
            fontSize: '15px'
        }
    },
    inviteActionBtn: {
        padding: '1em 1.5em',
        justifyContent: 'flex-start'
    },
    followersInput: {
        '& div': {
            '& input': {
                fontSize: '0.9em'
            }
        }
    },
    scrollableDiv: {
        padding: '0 3em',
    },
    tglBtnLabel: {
        textTransform: 'capitalize'
    },
    tglBtnSelected: {
        backgroundColor: '#14967B!important',
        color: '#ffffff!important',
    },
    bigIconRect: {
        position: 'relative',
        margin: '0 20px 0 0',
        width: '8.7em',
        zIndex: 9,
        height: 35,
        padding: 5,
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        color: '#14967B',
        border: '1px solid #14967B',
        fontSize: '0.875rem',
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#b9f0e1',
            //color: '#ffffff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        }
    },
    followersDialogPaper: {
        minHeight: '65vh',
        maxHeight: '68vh',
        minWidth: '55vw',
        maxWidth: '60vw'
    },
    dialogRolesSubHeadingContainer: {
        width: '102%',
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        marginLeft: '-1%',
        zIndex: 99
    },
    dialogRolesSubHeading: {
        padding: '7% 0 5% 1%',
        marginTop: '-4%',
        backgroundColor: '#fff'
    },
    jobseekerReview: {
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        paddingBottom: '1em',
        '&:last-child': {
            border: 'none'
        }
    },
    customRatings: {
        width: 'auto',
        '& span': {
            '& span': {
                '& svg': {
                    fill: 'currentColor',
                    width: '1em',
                    height: '1em',
                    display: 'inline-block',
                    fontSize: '2rem',
                    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    flexShrink: 0,
                    userSelect: 'none',
                }
            }
        }
    },
    customRatingsIcon: {
        '& svg': {
            fill: 'currentColor',
            width: '1em',
            height: '1em',
            display: 'inline-block',
            fontSize: '2rem',
            transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            flexShrink: 0,
            userSelect: 'none',
        }
    },
    perksCheckboxRoot: {
        '& label': {
            width: '49%',
            '@media (max-width: 600px)': {
                width: '100%'
            }
        },
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const isMobile = window.innerWidth <= 600 ? true : false

export default function JobUpdate(props) {
    const classes = useStyles();

    const [updatedValues, setUpdatedValues] = React.useState({ ...props.values, isVerified: false, min_salary: props.values.job_salary_amount.includes('-') ? props.values.job_salary_amount.split('-')[0] : props.values.job_salary_amount, max_salary: props.values.job_salary_amount.includes('-') ? props.values.job_salary_amount.split('-')[1] : props.values.job_salary_amount })
    let initialRender = React.useRef(true);
    //NEWLY ADDED DATA FOR JOB UPDATE
    const [sector, setSector] = React.useState([]);
    const [locationValue, setLocationValue] = React.useState();
    const [Roles, setRoles] = React.useState([]);
    const [inputRoleValue, setInputRoleValue] = React.useState(props.role ? props.role : "");
    const [jobUpdateErrors, setJobUpdateErrors] = React.useState({});
    const [isIncentiveChecked, setIsIncentiveChecked] = React.useState(props.values.variable_salary_amount ? true : false);

    const onRoleInputChangeHandler = (event, newInputValue, reason) => {
        setInputRoleValue(newInputValue);
    }

    // dropdown data: get job roles onload
    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_jobroles').then((Result) => {
            let rolesArr = Result.data;
            setRoles(rolesArr);
        })
    }, []);

    //Job type
    const [jobType, setJobType] = React.useState([]);
    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_experience_type').then((res) => {
            setJobType(res.data);
        })
    }, []);

    // Mapped Role
    const [active, setActive] = React.useState(false);
    const changeAlertVisibility = () => {
        setActive(prev => !prev);
    }

    const [isFormSubmit, setIsFormSubmit] = React.useState(false);
    const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const saveFiles = (e) => {
        AnalyticsHandler.track("JobUpdatePage_submitBtn");
        setIsSubmitting(true);
        setJobUpdateErrors(validateFormDetails(updatedValues))
        setIsFormSubmit(true)
    }


    React.useEffect(() => {
        if (Object.keys(jobUpdateErrors).length === 0 && isFormSubmit) {
            let min_salary = updatedValues.min_salary;
            let max_salary = updatedValues.max_salary;
            delete updatedValues.min_salary;
            delete updatedValues.max_salary;
            axios.post(`${BASE_URL}/jobposting/v2/jobpost/`, {
                ...updatedValues,
                job_salary_amount: min_salary === max_salary ? updatedValues.job_salary_amount : min_salary.concat('-' + max_salary)
            })
                .then(res => {
                    setIsSubmitting(false);
                    handleSnackBar(`Job updated successfully`)
                    setTimeout(() => {
                        props.doRefresh(p => !p)
                        props.setJobUpdateLoader(false);
                        props.setShowJobUpdatePage(false);
                        props.setJobFormVisibility('visible');
                    }, 1500);
                }).catch(err => {
                    console.log(err)
                })
        }
    }, [jobUpdateErrors])

    function ErrorAlert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const validateFormDetails = (updatedValues) => {
        setIsSubmitting(false)
        const errors = {}
        if (!updatedValues.job_title) {
            errors.job_title = 'Job title is required*'
        }
        if (!updatedValues.mapped_role) {
            errors.mapped_role = 'Job role is required*'
        }
        if (!updatedValues.opportunity_type) {
            errors.opportunity_type = 'Job type is required*'
        }
        if (!updatedValues.job_description) {
            errors.job_description = 'Job description is required*'
        }
        if (updatedValues.job_location.length === 0) {
            if (updatedValues.isWorkFromHome) {
            } else {
                errors.job_location = 'Job location is required*'
            }
        }
        if (!updatedValues.no_of_vacancies) {
            errors.no_of_vacancies = 'Please enter a valid number*'
        }
        if (!updatedValues.min_salary) {
            errors.min_salary = 'Minimum salary amount is required*'
        }
        if (!updatedValues.max_salary) {
            errors.max_salary = 'Maximum salary amount is required*'
        }
        if (updatedValues.qualification_req.length < 1 && (inputFields.some(qual => qual.course_type === null) || inputFields[0].course_type !== 'any')) {
            errors.qualification_req = 'Course type is required*'
        }
        if (Object.keys(errors).length > 0) {
            setIsErrorAlertOpen(true);
        }
        return errors
    }

    // React.useEffect(() => {
    //     setUpdatedValues(values);
    // }, [values]); // update the form values to be sent to the BE API whenever the values object changes from the child

    // TEMPorarily commented out
    // to render the previously selected locations onload
    // React.useEffect(() => {
    //     if (Array.isArray(updatedValues.job_location) && updatedValues.job_location.length > 0) {
    //         let locVal = [];
    //         for (let i = 0; i < updatedValues.job_location.length; i++) {
    //             if (updatedValues.job_location[i].address) {
    //                 const element = updatedValues.job_location[i].address;
    //                 locVal.push(element);
    //             } else if (updatedValues.job_location[i].city) { //for older jobs where address field is missing, city can be used
    //                 locVal.push(updatedValues.job_location[i].city);
    //             }
    //         }
    //         if (updatedValues.isWorkFromHome) {
    //             locVal.push("Anywhere/ Work-from-Home");
    //         }
    //         setLocationValue(locVal)
    //     }
    //     else if (Array.isArray(updatedValues.job_location) && updatedValues.job_location.length === 0 && updatedValues.isWorkFromHome) { // case of only wfh
    //         setLocationValue(["Anywhere/ Work-from-Home"])
    //     }
    //     else if (typeof updatedValues.job_location === 'string') {
    //         setLocationValue([updatedValues.job_location]);
    //     }

    // }, []);


    //Temporarily added to implement old location box
    const [location, setLocation] = React.useState([]);
    const [isWfhChecked, setIsWfhChecked] = React.useState(false);
    // React.useEffect(() => {
    //     if (latLong !== undefined && locationValue !== undefined) {
    //         updateData({ ...data, latitude: latLong.lat, longitude: latLong.long, company_address: locationValue });
    //     }
    // }, [latLong, locationValue]);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_locations').then((Result) => {
            let locArr = Result.data;
            let filtered = locArr.filter(item => item.city !== 'Work From Home')
            setLocation(filtered);
        })
    }, []);

    React.useEffect(() => {
        let locVal = [];
        if (Array.isArray(updatedValues.job_location) && updatedValues.job_location.length > 0) {
            for (let i = 0; i < updatedValues.job_location.length; i++) {
                const element = updatedValues.job_location[i];
                if (updatedValues.geo_json) { }
                locVal.push({ city: element.city, address: element.address, area: element.area, longitude: updatedValues.geo_json ? (updatedValues.geo_json.coordinates[i][0] || updatedValues.geo_json.coordinates[0]) : null, latitude: updatedValues.geo_json ? (updatedValues.geo_json.coordinates[i][1] || updatedValues.geo_json.coordinates[1]) : null });
            }
        } if (updatedValues.isWorkFromHome) {
            // locVal.push({ city: 'Work From Home' });
            setIsWfhChecked(true);
            // setLocationValue([{ city: updatedValues.job_location }]);
        }
        setLocationValue(locVal);
    }, []);

    //Temp data ends here

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_sectors').then((Result) => {
            let secArr = Result.data;
            setSector(secArr);
        })
    }, []);

    const [showEmailAlert, setShowEmailAlert] = React.useState(false)

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowEmailAlert(false);
    };

    //DURATION START
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');

    React.useEffect(() => {
        if (year && month) {
            setUpdatedValues({ ...updatedValues, internship_duration: year + ' ' + month });
        } else if (year) {
            setUpdatedValues({ ...updatedValues, internship_duration: year });
        } else if (month) {
            setUpdatedValues({ ...updatedValues, internship_duration: month });
        } else {
            setUpdatedValues({ ...updatedValues, internship_duration: '' });
        }
    }, [year, month]);

    React.useEffect(() => {
        if (updatedValues.internship_duration !== '') {
            let duration = updatedValues.internship_duration.split(' ');
            if (duration.length === 2 && duration[1] === 'year' || duration[1] === 'years') {
                setYear(duration[0] + ' ' + duration[1]);
            } else if (duration.length === 2 && duration[1] === 'month' || duration[1] === 'months') {
                setMonth(duration[0] + ' ' + duration[1]);
            } else {
                setYear(duration[0] + ' ' + duration[1]);
                setMonth(duration[2] + ' ' + duration[3]);
            }
        }
    }, []);
    //DURATION ENDS


    // SCHEDULE START
    const [disabledDiv, setDisabledDiv] = React.useState(updatedValues.isFlexibleTime && updatedValues.isFlexibleDays ? true : false);
    const [isChecked, setIsChecked] = React.useState(updatedValues.isFlexibleTime && updatedValues.isFlexibleDays ? true : false);
    const [selectedDate1, setSelectedDate1] = React.useState(new Date('2014-08-18T10:00:00'));
    const [selectedDate2, setSelectedDate2] = React.useState(new Date('2014-08-18T18:00:00'));
    const [initialTime, setInitialTime] = React.useState('10:00AM');
    const [endTime, setEndTime] = React.useState('06:00PM');

    const disableFormDiv = (e) => {
        setDisabledDiv(!disabledDiv);
        setIsChecked(!isChecked);
        setUpdatedValues({ ...updatedValues, isFlexibleDays: true, isFlexibleTime: true, working_time: '', working_days: [] });
        if (!e.target.checked) {
            setUpdatedValues({ ...updatedValues, isFlexibleDays: false, isFlexibleTime: false, working_time: initialTime + ' ' + 'to' + ' ' + endTime, working_days: ["MON", "TUE", "WED", "THU", "FRI"] });
        }
    }

    const handleTimings = (date, setDate, setVal) => {
        setDate(date);
        if (date !== null) {
            let hrs = date.getHours().toString();
            let min = date.getMinutes().toString();
            let ampm = hrs >= 12 ? 'PM' : 'AM';

            if (min < 10) {
                min = '0' + min;
            }
            if (hrs < 10) {
                hrs = '0' + hrs;
            }
            if (hrs > 12) {
                hrs = hrs - 12;
                if (hrs < 10) {
                    hrs = '0' + hrs;
                }
            }
            let time = hrs + ':' + min + ampm;
            setVal(time);
        }
    };

    const handleDays = (e) => {
        if (e.target.checked) {
            setUpdatedValues({ ...updatedValues, working_days: updatedValues.working_days !== null && [...updatedValues.working_days, e.target.id] })
        } else {
            setUpdatedValues({ ...updatedValues, working_days: updatedValues.working_days.filter(item => item !== e.target.id) })
        }
    }

    React.useEffect(() => {
        setUpdatedValues({ ...updatedValues, working_time: initialTime + ' ' + 'to' + ' ' + endTime });
    }, [initialTime, endTime]);

    //SCHEDULE ENDS HERE

    //SALARY SECTION

    const [isCheckedUnpaid, setIsCheckedUnpaid] = React.useState(false)
    const [disabledSalaryDiv, setDisabledSalaryDiv] = React.useState(false)
    const [showUnpaidOption, setShowUnpaidOption] = React.useState(false);
    const disableSalaryFormDiv = () => {
        setDisabledSalaryDiv(!disabledSalaryDiv);
        setIsCheckedUnpaid(!isCheckedUnpaid);
        setUpdatedValues({ ...updatedValues, variable_salary_amount: '0', job_salary_amount: '0' });
    }

    React.useEffect(() => {
        if (updatedValues.opportunity_type === 'Internship') {
            setShowUnpaidOption(true);
            if (updatedValues.job_salary_amount === '' || updatedValues.job_salary_amount == 0) {
                setIsCheckedUnpaid(true);
                setDisabledSalaryDiv(true);
            }
        };
    }, []);

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);

    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    };

    //PERKS AND BENEFITS
    const [benefits, setBenefits] = React.useState([]);
    const newPerkItem = {
        benefit_name: '',
        illustration_img_url: null,
    };

    const addToPerkList = () => {
        setBenefits([...benefits, newPerkItem]);
    }

    const addNewPerk = () => {
        let addItem = {
            benefit_name: <InputBase type='textarea' size='small' classes={{ root: classes.TaPerks }} multiline rows={2} placeholder='Type here' required onChange={e => newPerkItem.benefit_name = e.target.value} onBlur={addToPerkList} />,
            illustration_img_url: null,
        }
        setBenefits([...benefits, addItem]);
    }
    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_benefits').then((Result) => {
            let perksArr = Result.data;
            setBenefits(perksArr);
        })
    }, []);

    //Perks END HERE

    //QUALIFICATION SECTION STARTED

    const [disableForm, setDisableForm] = React.useState(false);
    const [inputFields, setInputFields] = React.useState([
        {
            course_type: null,
            course: null,
            specialization: null
        }
    ])

    const [isLoading, setIsLoading] = React.useState(false);
    //dropdown options for every qualification
    const [course, setCourse] = React.useState([]);
    const [courseType, setCourseType] = React.useState([]);
    const [specialization, setSpecialization] = React.useState([]);
    const [isQualChecked, setIsQualChecked] = React.useState(false);
    const [disableQualForm, setDisableQualForm] = React.useState(false);

    const addQualInput = () => {
        setInputFields([...inputFields, { course_type: null, course: null, specialization: null }]);
    }

    const removeQualInput = (index) => {
        const list = [...inputFields];
        list.splice(index, 1);
        setInputFields(list);
        setUpdatedValues({ ...updatedValues, qualification_req: [...list] });

        //remove course dropdown vals on delete
        let courseDropdown = [...course];
        courseDropdown.splice(index, 1);
        setCourse(courseDropdown);

        //remove specialization dropdown vals on delete
        let specializationDropdown = [...specialization];
        specializationDropdown.splice(index, 1);
        setSpecialization(specializationDropdown);
    }

    const disableQualFormDiv = () => {
        setDisableQualForm(!disableQualForm)
        setInputFields([{ course_type: null, course: null, specialization: null }])
        setIsQualChecked(!isQualChecked);
        setUpdatedValues({ ...updatedValues, qualification_req: [{ course_type: "any", course: "any", specialization: "any" }] });
    }

    const handleQualChange = (key, index, val) => {
        //const name = ref.current.getAttribute("name");
        const list = [...inputFields];
        list[index][key] = val;
        setInputFields(list);
        let filteredInputs = inputFields.filter(input => input.course_type !== null || input.course !== null || input.specialization !== null);
        setUpdatedValues({ ...updatedValues, qualification_req: filteredInputs });
        if (key === "course_type") { //to create dropdown options for course name
            let newCourses = newHashMap[val] ? newHashMap[val].filter((item, i, array) =>
                array.findIndex(t => t.course_name == item.course_name) == i
            ) : null

            let temp_array = []
            temp_array = [...course];
            temp_array[index] = newCourses;
            setCourse(temp_array)
            // setCourse(...course, course[index] = newHashMap[val])
        } else if (key === "course") { //to create dropdown options for specialization name
            let courseType = inputFields[index].course_type;
            let newCourses = newHashMap[courseType] ? newHashMap[courseType].filter((courseItem) =>
                courseItem.course_name === val && courseItem.specialization_name
            ) : null;
            let temp_array = []
            temp_array = [...specialization];
            temp_array[index] = newCourses;
            setSpecialization(temp_array)
        }
    }

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_course_types').then((Result) => {
            let courseTypeArr = Result.data.filter(item => item.course_type !== "NA" && item.course_type !== "Ph.D");

            setCourseType(courseTypeArr);
        })
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await axios(BASE_URL + '/users/get_aggregated_qualification_mapping/');

            // remove duplicates
            let newArray = result.data.filter((item, i, array) =>
                array.findIndex(t => t.qualification_id == item.qualification_id) == i
            )

            const groupByCat = (qualifications = []) => {
                let result = [];
                result = qualifications.reduce((r, a) => {
                    if (a.course_type !== 'Ph.D' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
                        r[a.course_type] = r[a.course_type] || [];
                        r[a.course_type].push(a);
                    }
                    return r;
                }, Object.create(null));
                return result;
            };

            newHashMap = groupByCat(newArray);

            //create dropdowns onload for the quals added earlier
            if (updatedValues.qualification_req && updatedValues.qualification_req.length > 0) {
                let qualInputFields = [];
                updatedValues.qualification_req.forEach((input, index) => {
                    qualInputFields.push({ course_type: null, course: null, specialization: null })
                })
                setInputFields(qualInputFields)
                let list = [...qualInputFields]
                let temp_Courses = [...course];
                let temp_specs = [...specialization];

                updatedValues.qualification_req.forEach((input, index) => {
                    let element = input.split('-');
                    //if course type was selected earlier, create dropdown for course name 
                    if (element[0].trim() === 'any') {
                        list[0] = { course_type: null, course: null, specialization: null };
                        setIsQualChecked(true);
                        setDisableQualForm(true);
                    } else {
                        if (element[0]) {
                            let val = element[0].trim();
                            list[index]['course_type'] = val;
                            let newCourses = newHashMap[val] ? newHashMap[val].filter((item, i, array) =>
                                array.findIndex(t => t.course_name == item.course_name) == i
                            ) : null

                            temp_Courses[index] = newCourses;
                        }
                        //if course name was selected earlier, create dropdown for specialization 
                        if (element[1]) {
                            let val = element[1].trim();
                            list[index]['course'] = val;
                            // let courseType = input.course_type;
                            let newCourses = newHashMap[val] ? newHashMap[val].filter((courseItem) =>
                                courseItem.course_name === input.course && courseItem.specialization_name
                            ) : null;

                            temp_specs[index] = newCourses;
                        }
                        if (element[2]) {
                            let val = element[2].trim();
                            list[index]['specialization'] = val;
                        }
                    }

                })
                setInputFields(list);
                // let filteredInputs = inputFields.filter(input => input.course_type !== null || input.course !== null || input.specialization !== null);
                // console.log(filteredInputs)
                setUpdatedValues({ ...updatedValues, qualification_req: list });
                setCourse(temp_Courses);
                setSpecialization(temp_specs);
            }

            setIsLoading(false);
        };
        fetchData();
    }, []);


    //QUALIFICTION SECTION ENDS

    //SKILL START HERE
    const [skillInputFields, setSkillInputFields] = React.useState([
        {
            skill: null,
            no_of_experience: null,
            sector: null
        }
    ])

    const [skill, setSkill] = React.useState([]);
    const addSkillInput = () => {
        setSkillInputFields([...skillInputFields, { skill: null, no_of_experience: null, sector: null }])
    }

    const removeSkillInput = (index) => {
        const list = [...skillInputFields];
        list.splice(index, 1);
        setSkillInputFields(list);
        setUpdatedValues({ ...updatedValues, skills_required: [...list] });
    }
    const handleSkillChange = (key, index, val) => {
        // const name = ref.current.getAttribute("name");
        const list = [...skillInputFields];
        list[index][key] = val;
        setSkillInputFields(list);
        let filteredInputs = skillInputFields.filter(input => input.skill !== null || input.sector !== null || input.no_of_experience !== null);
        setUpdatedValues({ ...updatedValues, skills_required: filteredInputs });
    }

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_skills').then((Result) => {
            let skillArr = Result.data;
            setSkill(skillArr);
        })
    }, []);

    React.useEffect(() => {
        if (updatedValues.skills_required && updatedValues.skills_required.length > 0) {
            let newVal = [...skillInputFields]
            updatedValues.skills_required.forEach((input, index) => {
                newVal[index] = { skill: input.skill, no_of_experience: input.no_of_experience, sector: input.sector }
            })
            setSkillInputFields(newVal)
        }
    }, []);

    //SKILL ENDS

    //GENDER PREF

    const [showTextField, setShowTextField] = React.useState(false)

    //GENDER PREF ENDS

    //SCREENING QUESTIONS START
    const [count, setCount] = React.useState(0);
    const [customQuestions, setCustomQuestions] = React.useState([]);

    React.useEffect(() => {
        if (updatedValues.custom_questions.length > 0) {
            setCustomQuestions(updatedValues.custom_questions);
        }
    }, [])

    const [questions, setQuestions] = React.useState([
        {
            name: 'location_agree',
            ques: "Are you ready to work at the mentioned location?"
        },
        {
            name: 'salary_agree',
            ques: "Are you ready to work for the mentioned salary?",
        },
        {
            name: 'why_good_fit',
            ques: "Why should we select you for this role? Tell us in detail how your education and/or experience will help you perform the mentioned work.",
        },
        {
            name: 'change_job_reason',
            ques: "Why do you want to change your current job?",
        },
        {
            name: 'salary_expectation',
            ques: "What are your salary expectations for this role?",
        },
        {
            name: 'own_laptop',
            ques: "Do you have a personal laptop/computer?",
        },
        {
            name: 'valid_dl_license',
            ques: "Do you have a valid driving license?",
        },
        {
            name: 'own_vehicle',
            ques: "Do you have a personal vehicle?",
        },
    ]);

    const newItem = {
        name: '',
        question: "",
    };

    let addQues;

    function handleScrQuesChange(e, val) {
        if (e.target.checked) {
            setUpdatedValues({ ...updatedValues, [val]: true })
        }
        else {
            setUpdatedValues({ ...updatedValues, [val]: false })
        }
    }

    function addCustomQues() {
        setCount(prev => prev + 1)
        addQues = {
            name: count,
            question: <InputBase type='textarea' size='small' fullWidth classes={{ root: classes.TaRootSrcForm }} multiline rows={2} placeholder='Type here' required
                onChange={e => {
                    let ques = e.target.value;
                    let list = [...updatedValues.custom_questions];
                    newItem.question = ques;
                    list.push({ question: ques, isChecked: true })
                    // list[count]['question'] = ques;
                    setUpdatedValues({ ...updatedValues, custom_questions: list })
                }}
                onBlur={addToList} />
        }
        setCustomQuestions([...customQuestions, addQues]);
    }

    function addToList() {
        newItem.name = addQues.name;
        setCustomQuestions([...customQuestions, newItem]);
    }

    function handleCustomQuesChange(e, val, key) {
        let list = [...updatedValues.custom_questions]
        if (e.target.checked) {
            list[val][key] = true
            setUpdatedValues({ ...updatedValues, custom_questions: list })
        }
        else {
            list[val][key] = false
            setUpdatedValues({ ...updatedValues, custom_questions: list })
        }
    }
    function editDone(index) {
        let customQuestionsArray = [...customQuestions];
        newItem.name = addQues.name;
        customQuestionsArray.splice(index, 1, newItem);
        setCustomQuestions(customQuestionsArray);
    }

    function editCustomQuestion(index) {
        let customQuestionsArray = [...updatedValues.custom_questions]
        addQues = {
            name: count,
            question: <InputBase type='textarea' size='small' defaultValue={customQuestions[index].question} fullWidth classes={{ root: classes.TaRootSrcForm }} multiline rows={2} placeholder='Type here' required
                onChange={e => {
                    let ques = e.target.value;
                    let list = [...updatedValues.custom_questions];
                    list.splice(index, 1, { question: ques, isChecked: true })
                    newItem.question = ques;
                    // list.push({ question: ques, isChecked: true })
                    // list[count]['question'] = ques;
                    setUpdatedValues({ ...updatedValues, custom_questions: list })
                }}
                onBlur={() => editDone(index)} />
        }
        customQuestionsArray.splice(index, 1, addQues)
        setCustomQuestions(customQuestionsArray);
    }
    //SCREENING QUESTION ENDS HERE

    return (
        <>
            <Helmet>
                <title>Edit Job</title>
                <meta name='description' content='Edit job after posting it on meet portal.' />
                {/* <link rel='canonical' href='/openings' /> */}
            </Helmet>
            <ArrowBack fontSize='large' onClick={() => {
                props.setShowJobUpdatePage(false);
            }} style={{ position: 'absolute', top: 70, left: 20, zIndex: 9, marginBottom: '1em', minHeight: '60px', cursor: 'pointer', color: '#fff', display: window.innerWidth <= 600 ? 'none' : 'block' }} />
            <div className={classes.company_card} style={{ overflow: 'auto', height: props.height ? props.height : '87vh' }}>
                {isMobile && <div style={{ boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px', display: 'flex', position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 9, backgroundColor: '#fff', alignItems: 'center', boxShadow: 'rgba(0, 0, 0, 0.17) 0px 4px 6px', marginBottom: '1em', minHeight: '60px' }}>
                    <ArrowBack onClick={() => {
                        props.setShowJobUpdatePage(false);
                    }} style={{ padding: '5px' }} />
                    Edit Job Details
                </div>}
                <div className={classes.overview}>
                    <div className={classes.about_company}>
                        {/* <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`} style={{ margin: '0 0 1.5em 0' }}>About</h5> */}
                        <div className={`${classes.flexbox} ${classes.my1}`} style={{ alignItems: 'unset', flexDirection: window.innerWidth <= 600 ? 'column' : 'row' }}>
                            <div className={`${classes.mr1} ${classes.width_100} `}>
                                <Typography component='h1' variant='h5' style={{ margin: window.innerWidth <= 600 ? '0.8em 0' : '1.67em 1.67em 1.67em 0' }} className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Job Title<span style={{ color: '#f44336' }}>*</span></Typography>
                                <Autocomplete
                                    name="title"
                                    freeSolo
                                    options={Roles}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.job_role_name
                                    }
                                    }
                                    style={{ flex: 0.75 }}

                                    value={updatedValues.job_title}
                                    // selectOnFocus
                                    // clearOnBlur

                                    onChange={(event, newValue) => {
                                        event.preventDefault();
                                        setJobUpdateErrors({ ...jobUpdateErrors, job_title: '' });
                                        if (typeof newValue === 'string') {
                                            setJobUpdateErrors({ ...jobUpdateErrors, job_title: '', mapped_role: '' });
                                            setUpdatedValues({ ...updatedValues, job_title: newValue, mapped_role: newValue });
                                        } else if (newValue && newValue.inputValue) {
                                            setUpdatedValues({ ...updatedValues, job_title: newValue.inputValue, mapped_role: "" });
                                        } else if (newValue) {
                                            setJobUpdateErrors({ ...jobUpdateErrors, job_title: '', mapped_role: '' });
                                            setUpdatedValues({ ...updatedValues, job_title: newValue.job_role_name, mapped_role: newValue.job_role_name });
                                        } else {
                                            setUpdatedValues({ ...updatedValues, job_title: "", mapped_role: "" });
                                        }
                                    }}
                                    inputValue={inputRoleValue}

                                    onInputChange={onRoleInputChangeHandler}

                                    onBlur={(event) => {
                                        if (inputRoleValue !== "") {
                                            setUpdatedValues({ ...updatedValues, title: inputRoleValue });
                                        }
                                    }}

                                    // classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                                    renderInput={(params) => <TextField {...params} value=""
                                        error={jobUpdateErrors.job_title ? true : false}
                                        helperText={jobUpdateErrors.job_title ? jobUpdateErrors.job_title : ''}
                                        variant='outlined'
                                        placeholder="Search or add role e.g Sales Associate" classes={{ root: classes.gender_pref_field }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    }

                                    renderOption={(option) => {
                                        if (option) {
                                            return (
                                                <Grid container alignItems="flex-start" direction="column">
                                                    <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.job_role_name} </Typography>
                                                </Grid>
                                            )
                                        }
                                    }}

                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        // add new input value at the top of the dropdown list if value is not empty or already exists in list
                                        if (params.inputValue !== '') {
                                            filtered.unshift({
                                                inputValue: params.inputValue,
                                                job_role_name: `Add "${params.inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                />
                            </div>
                            <div className={classes.width_100}>
                                <div className={classes.flexbox}>
                                    {/* <Typography gutterBottom variant='h5' classes={{ root: classes.flexbox }} className={classes.my2} ><span></span> */}
                                    <Typography component='h2' variant='h5' style={{ margin: window.innerWidth <= 600 ? '0.8em 0' : '1.65rem 0' }} className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Choose Role<span style={{ color: '#f44336' }}>*</span></Typography>

                                    <InfoIcon className={classes.cursor_pointer} classes={{ root: classes.icon }} onClick={changeAlertVisibility} />
                                    <Alert icon={false} severity="success" className={active ? `${classes.show}` : `${classes.hidden}`}>
                                        Select the closest role from the list to your work title.
                                    </Alert>
                                    {/* </Typography> */}
                                </div>
                                <Autocomplete
                                    name="title"
                                    freeSolo
                                    options={Roles}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.job_role_name
                                    }
                                    }
                                    style={{ flex: 0.75 }}

                                    value={updatedValues.mapped_role}
                                    // selectOnFocus
                                    // clearOnBlur

                                    onChange={(event, newValue) => {
                                        event.preventDefault();
                                        setJobUpdateErrors({ ...jobUpdateErrors, mapped_role: '' });
                                        if (typeof newValue === 'string') {
                                            setUpdatedValues({ ...updatedValues, mapped_role: newValue });
                                            //   setInputBlur(false);
                                        } else if (newValue && newValue.inputValue) {
                                            setUpdatedValues({ ...updatedValues, mapped_role: newValue.inputValue });
                                            //   setInputBlur(false);
                                        } else if (newValue) {
                                            setUpdatedValues({ ...updatedValues, mapped_role: newValue.job_role_name });
                                            //   setInputBlur(false);
                                        } else {
                                            setUpdatedValues({ ...updatedValues, mapped_role: "" });
                                            //   setInputBlur(false);
                                        }
                                    }}
                                    // inputValue={inputRoleValue}

                                    // onInputChange={onRoleInputChangeHandler}

                                    // onBlur={(event) => {
                                    //     if (inputBlur && inputRoleValue !== "") {
                                    //         setValues({ ...values, title: inputRoleValue, mapped_role: "" });
                                    //         setShowRoles(true);
                                    //     }
                                    // }}

                                    // classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

                                    renderInput={(params) => <TextField {...params} value=""
                                        error={jobUpdateErrors.mapped_role ? true : false}
                                        helperText={jobUpdateErrors.mapped_role ? jobUpdateErrors.mapped_role : ''}
                                        variant='outlined'
                                        placeholder="Search or add role e.g Sales Associate" classes={{ root: classes.TfRoot }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    }

                                    renderOption={(option) => {
                                        if (option) {
                                            return (
                                                <Grid container alignItems="flex-start" direction="column">
                                                    <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.job_role_name} </Typography>
                                                </Grid>
                                            )
                                        }
                                    }}

                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        // add new input value at the top of the dropdown list if value is not empty or already exists in list
                                        if (params.inputValue !== '') {
                                            filtered.unshift({
                                                inputValue: params.inputValue,
                                                job_role_name: `Add "${params.inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.my1}>
                            <Typography component='h3' variant='h5' style={{ margin: window.innerWidth <= 600 ? '0.8em 0' : '1.67em 1.67em 1em 0' }} className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Job Type<span style={{ color: '#f44336' }}>*</span></Typography>
                            <FormControl component="fieldset" className={`${classes.flexbox} ${classes.my4} ${classes.workTypeFormControl}`} error={jobUpdateErrors.opportunity_type ? true : false} >
                                <RadioGroup aria-label="worktype" name="opportunity_type"
                                    value={updatedValues.opportunity_type}
                                    onChange={e => {
                                        setJobUpdateErrors({ ...jobUpdateErrors, opportunity_type: '' });
                                        if (e.target.value === 'Internship') {
                                            setShowUnpaidOption(true);
                                        } else {
                                            setShowUnpaidOption(false);
                                        }
                                        if (e.target.value === 'Full-time') {
                                            // setShowDuration(false);
                                            setUpdatedValues({ ...updatedValues, opportunity_type: e.target.value, internship_duration: "" });
                                        } else {
                                            // setShowDuration(true);
                                            setUpdatedValues({ ...updatedValues, opportunity_type: e.target.value });
                                        }
                                    }}
                                    className={classes.workTypeOptions}
                                >
                                    {jobType.filter(el => el !== 'Self-Employed').map((type, index) => {
                                        return <FormControlLabel key={index} className={classes.mr2} value={type} control={<Radio classes={{ root: classes.radioColor }} />} label={type} />
                                    })}
                                </RadioGroup>
                                <FormHelperText style={{ position: 'absolute', left: 0, bottom: '-35%' }}>{jobUpdateErrors.opportunity_type ? jobUpdateErrors.opportunity_type : ''}</FormHelperText>
                            </FormControl>
                        </div>
                        {updatedValues.opportunity_type !== 'Full-time' && <Grid item xs={12} className={classes.my4} >
                            <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Duration</h5>
                            <div className={`${classes.durationDiv} ${classes.flexbox}`}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={durationYears}
                                    name="internship_duration"
                                    value={year || null}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        return option.name;
                                    }}
                                    getOptionSelected={(option, value) => option.name === value}
                                    style={{ width: 280 }}
                                    renderInput={(params) => <TextField {...params} placeholder='No. of years' classes={{ root: classes.TfRoot }} />}
                                    renderOption={(option) => option.name}

                                    onChange={(event, newValue) => {
                                        event.preventDefault();
                                        if (newValue) {
                                            setYear(newValue.name);
                                        } else {
                                            setYear('');
                                        }
                                    }}

                                />
                                <span style={{ margin: '0 1em' }}>and/or</span>
                                <Autocomplete
                                    id="combo-box"
                                    options={durationMonths}
                                    name="internship_duration"
                                    value={month || null}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Regular option
                                        return option.name;
                                    }}

                                    getOptionSelected={(option, value) => option.name === value}
                                    style={{ width: 280 }}
                                    renderInput={(params) => <TextField {...params} placeholder='No. of months' classes={{ root: classes.TfRoot }} />}
                                    renderOption={(option) => option.name}

                                    onChange={(event, newValue) => {
                                        event.preventDefault();
                                        if (newValue) {
                                            setMonth(newValue.name);
                                        } else {
                                            setMonth('');
                                        }
                                    }}
                                />
                            </div>
                        </Grid>}
                        <div className={classes.my1}>
                            <Typography component='h4' variant='h5' style={{ margin: '1.67em 1.67em 1em 0' }} className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Working Hours & Days</Typography>
                            <div className={classes.salaryDetails}>
                                <FormControlLabel
                                    control={<Checkbox onChange={e => disableFormDiv(e)} checked={isChecked} />}
                                    label='Flexible'
                                />
                                <div className={disabledDiv ? `${classes.disableDiv} ${classes.flexboxCol}` : classes.flexboxCol}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormControl className={`${classes.formControl}`}>
                                            <span style={{ marginRight: '1.5em', fontWeight: 'bold' }}>Hours</span>
                                            <span style={{ marginRight: '1em' }}>From</span>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                value={selectedDate1}
                                                onChange={(date) => handleTimings(date, setSelectedDate1, setInitialTime)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                className={classes.timePicker}
                                            />
                                            <span style={{ margin: '0 1.5em' }}>to</span>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                id="time-picker"
                                                value={selectedDate2}
                                                onChange={(date) => handleTimings(date, setSelectedDate2, setEndTime)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                                className={classes.timePicker}
                                            />

                                        </FormControl>
                                    </MuiPickersUtilsProvider>
                                    <div className={`${classes.flexbox} ${classes.my1}`} >
                                        <span style={{ marginRight: '2em', fontWeight: 'bold' }}>Days</span>
                                        <FormControl className={`${classes.flexbox} ${classes.timeFormControl}`} >
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='MON'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("MON")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='MON' >M</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='TUE'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("TUE")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='TUE' >T</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='WED'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("WED")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='WED' >W</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='THU'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("THU")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='THU' >T</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='FRI'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("FRI")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='FRI' >F</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='SAT'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("SAT")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='SAT' >S</label>
                                            </FormGroup>
                                            <FormGroup className={`${classes.days} ${classes.flexboxCol}`} >
                                                <input type="checkbox" id='SUN'
                                                    checked={updatedValues.working_days !== null && updatedValues.working_days.includes("SUN")}
                                                    onChange={(e, index) => handleDays(e, index)} />
                                                <label htmlFor='SUN' >S</label>
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.my1}>
                            <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Description<span style={{ color: '#f44336' }}>*</span></h5>
                            <TextField error={jobUpdateErrors.job_description ? true : false} helperText={jobUpdateErrors.job_description ? jobUpdateErrors.job_description : ''} id="outlined-basic" value={updatedValues.job_description} multiline maxRows={10} minRows={3} fullWidth variant="outlined"
                                onChange={e => {
                                    setJobUpdateErrors({ ...jobUpdateErrors, job_description: '' });
                                    setUpdatedValues({ ...updatedValues, job_description: e.target.value });
                                }}
                            />
                        </div>
                        <div className={classes.my1}>
                            <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Location<span style={{ color: '#f44336' }}>*</span></h5>

                            {/* <Location classes={classes}
                                // errors={errors} 
                                // setErrors={setErrors} 
                                purpose={"update"}
                                values={updatedValues}
                                setValues={setUpdatedValues}
                                setMultipleLocationVal={setLocationValue}
                                multipleLocationVal={locationValue} /> */}

                            <FormControlLabel
                                control={<Checkbox checked={isWfhChecked}
                                    onChange={(e) => {
                                        setJobUpdateErrors({ ...jobUpdateErrors, job_location: '' });
                                        setIsWfhChecked(prev => !prev);
                                        if (e.target.checked) {
                                            setUpdatedValues({ ...updatedValues, isWorkFromHome: true });
                                        } else {
                                            setUpdatedValues({ ...updatedValues, isWorkFromHome: false });
                                        }
                                    }}
                                />}
                                label='Work From Home'
                                className={classes.allQualCheckbox}
                            />
                            <Autocomplete
                                //   classes={{ root: classes.locationAutoComplete }}
                                multiple
                                name="job_location"
                                // ref={locRef}
                                options={location}
                                getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    return option.city
                                }
                                }

                                renderOption={(option) => {
                                    if (option.city !== 'Work From Home') {
                                        return option.city
                                    }
                                }}

                                filterSelectedOptions
                                getOptionSelected={(option, value) => option.city === value.city}
                                value={locationValue || []}
                                // defaultValue={values.job_location}
                                onChange={(event, newValue) => {
                                    event.preventDefault();
                                    setJobUpdateErrors({ ...jobUpdateErrors, job_location: '' });
                                    if (newValue) { // when value is selected from dropdown
                                        let locVal = [];
                                        // let isWorkFromHome = false;
                                        let latLng = [];
                                        // let locVal = [];
                                        newValue.map((item, index) => {
                                            // if (item.city === 'Work From Home') {
                                            //     isWorkFromHome = true;
                                            // } else {
                                            locVal.push({ city: item.city, address: item.address || '', area: item.area || '' })
                                            latLng.push([item.longitude, item.latitude])
                                            // }
                                        });
                                        // setUpdatedValues({ ...updatedValues, job_location: locVal, isWorkFromHome, geo_json: latLng.length > 0 ? { type: 'MultiPoint', coordinates: latLng } : null });
                                        setUpdatedValues({ ...updatedValues, job_location: locVal, geo_json: latLng.length > 0 ? { type: 'MultiPoint', coordinates: latLng } : null });
                                        setLocationValue(newValue);
                                        // locVal.length === 1 ? setValues({ ...values, job_location: locVal.toString() }) : setValues({ ...values, job_location: locVal });
                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        error={jobUpdateErrors.job_location ? true : false}
                                        helperText={jobUpdateErrors.job_location ? jobUpdateErrors.job_location : ''}
                                        placeholder={updatedValues.job_location && updatedValues.job_location.length > 0 ? '' : 'Select work from home or search location'}
                                        classes={{ root: classes.TfRoot }}
                                    />
                                )}
                            />

                        </div>
                        <div className={`${classes.flexbox} ${classes.my1}`}>
                            <div className={`${classes.mr1} ${classes.width_100}`}>
                                <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Vacancies<span style={{ color: '#f44336' }}>*</span></h5>
                                <TextField error={jobUpdateErrors.no_of_vacancies ? true : false} helperText={jobUpdateErrors.no_of_vacancies ? jobUpdateErrors.no_of_vacancies : ''} id="outlined-basic" className={` ${classes.input_width}`} fullWidth variant="outlined"
                                    onChange={e => {
                                        setJobUpdateErrors({ ...jobUpdateErrors, no_of_vacancies: '' })
                                        setUpdatedValues({ ...updatedValues, no_of_vacancies: e.target.value });
                                    }}
                                    value={updatedValues.no_of_vacancies}
                                    placeholder='Add number of openings e.g. 2'
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.salaryDetails}>
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Salary Details<span style={{ color: '#f44336' }}>*</span></h5>
                        {showUnpaidOption && <FormControlLabel
                            control={<Checkbox checked={isCheckedUnpaid} onChange={disableSalaryFormDiv} />}
                            label='Unpaid Internship'
                            classes={{ label: classes.checkboxLabel }}
                        />}
                        <div className={disabledSalaryDiv ? `${classes.disableDiv} ${classes.flexboxCol}` : classes.flexboxCol}>
                            <FormControl className={isMobile ? `${classes.formControlSalary}` : `${classes.formControlSalary} ${classes.my1}`}>
                                <span style={{ marginRight: '2em' }}>Min<span style={{ color: '#f44336' }}>*</span></span>
                                <div className={classes.flexbox} style={{margin: !isMobile ? '1em 0' : 0}}>
                                    <TextField
                                        type='number'
                                        style={{ width: isMobile ? '70%' : 'max-content' }}
                                        variant={isMobile ? 'outlined' : 'standard'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={e => {
                                            setJobUpdateErrors({});
                                            setJobUpdateErrors({ ...jobUpdateErrors, min_salary: '' });
                                            setUpdatedValues({ ...updatedValues, min_salary: e.target.value });
                                        }}
                                        value={updatedValues.min_salary}
                                        error={jobUpdateErrors.min_salary ? true : false}
                                        helperText={jobUpdateErrors.min_salary ? jobUpdateErrors.min_salary : ''}
                                    />
                                    <span style={{ color: '#0000008A' }}>/month</span>
                                </div>
                                {/* <FormControl className={classes.formControl}> */}
                                <span className={`${isMobile ? classes.my0 : classes.mx2}`}>Max<span style={{ color: '#f44336' }}>*</span></span>
                                <div className={classes.flexbox} style={{margin: !isMobile ? '1em 0' : 0}}>
                                    <TextField
                                        type='number'
                                        classes={{ root: classes.TfRoot }}
                                        style={{ width: isMobile ? '70%' : 'max-content' }}
                                        variant={isMobile ? 'outlined' : 'standard'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                        // placeholder='e.g. article/sale/word'
                                        onChange={e => {
                                            setJobUpdateErrors({ ...jobUpdateErrors, max_salary: '' });
                                            setUpdatedValues({ ...updatedValues, max_salary: e.target.value });
                                        }}
                                        value={updatedValues.max_salary}
                                        error={jobUpdateErrors.max_salary ? true : false}
                                        helperText={jobUpdateErrors.max_salary ? jobUpdateErrors.max_salary : ''}
                                    />
                                    <span style={{ color: '#0000008A' }}>/month</span>
                                </div>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox />}
                                label='With incentive'
                                classes={{ label: classes.checkboxLabel }}
                                checked={isIncentiveChecked}
                                onChange={() => {
                                    setIsIncentiveChecked(p => !p);
                                    setUpdatedValues({ ...updatedValues, variable_salary_amount: '', variable_salary_unit: '' })
                                }}
                            />
                            {isIncentiveChecked && <div className={isMobile ? `${classes.formControlSalary} ${classes.flexbox}` : `${classes.formControlSalary} ${classes.my1}`} style={{alignItems: isMobile ? 'flex-start' : 'center'}}>
                                <span style={{ marginRight: isMobile ? '1em' : '2em' }}>Incentive</span>
                                <div className={`${isMobile ? classes.flexboxCol : classes.flexbox}`} style={{margin: !isMobile ? '1em 0' : 0}}>
                                    <TextField
                                        type='number'
                                        classes={{ root: classes.TfRoot }}
                                        variant={isMobile ? 'outlined' : 'standard'}
                                        style={{ width: 'max-content' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ₹
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={e => setUpdatedValues({ ...updatedValues, variable_salary_amount: e.target.value })}
                                        value={updatedValues.variable_salary_amount === '0' || updatedValues.variable_salary_amount === null ? '' : updatedValues.variable_salary_amount}
                                    />
                                    <FormControl className={ `${isMobile ? '' : classes.formControl}`}>
                                        <span style={{ margin: isMobile ? '0.5em 1em 0.5em 0' : '0 2em' }}>per</span>
                                        <TextField
                                            classes={{ root: classes.TfRoot }}
                                            variant={isMobile ? 'outlined' : 'standard'}
                                            style={{ width: 'max-content' }}
                                            placeholder='e.g. article/sale/word'
                                            onChange={e => setUpdatedValues({ ...updatedValues, variable_salary_unit: e.target.value })}
                                            value={updatedValues.variable_salary_unit}
                                        />
                                    </FormControl>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <Grid item xs={12} className={`${classes.ml1} ${classes.position_relative}`} >
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Perks and Benefits</h5>
                        <span style={{ marginBottom: '1em' }} className={classes.helper_text}> Choose as many as you like or add new </span>
                        <FormGroup style={{ marginTop: '1em' }} classes={{ root: classes.perksCheckboxRoot }} row className={`${classes.benefitsContainer}`}>
                            {benefits.map((item, index) => {
                                return <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={updatedValues.work_benefits ? updatedValues.work_benefits.includes(item.benefit_name) : false}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setUpdatedValues({ ...updatedValues, work_benefits: [...updatedValues.work_benefits, item.benefit_name] })
                                                } else {
                                                    setUpdatedValues({ ...updatedValues, work_benefits: updatedValues.work_benefits.filter(perk => perk !== item.benefit_name) })
                                                }
                                            }}
                                            name="checkedB"
                                            color="primary"
                                        // style={{width: '50%'}}
                                        />
                                    }
                                    label={item.benefit_name}
                                />
                            })}
                            {/* <div className={`${classes.benefitsItem} ${classes.flexboxCol}`} onClick={addNewPerk}>
                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#14967B', fontSize: '0.8em', fontWeight: '500' }}><AddIcon fontSize='small' /> Add new</span>
                            </div> */}
                            <div className={`${classes.benefitsItem} ${classes.flexboxCol}`} onClick={addNewPerk}>
                                <span style={{ display: 'flex', alignItems: 'center', color: '#14967B', fontWeight: '500', cursor: 'pointer', margin: '1em 0' }}><AddIcon fontSize='small' /> Add new</span>
                                {/* <p style={{ margin: '0', fontSize: '0.85rem', textAlign: 'center' }}>or press /</p> */}
                            </div>
                        </FormGroup>

                    </Grid>
                    <Grid item xs={12} className={`${classes.my3} ${classes.position_relative}`} >
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Preferred Qualification</h5>
                        <FormControlLabel
                            control={<Checkbox checked={isQualChecked} onChange={disableQualFormDiv} />}
                            label='All qualifications are welcome'
                            // classes={{ label: classes.checkboxLabel }}
                            className={classes.allQualCheckbox}
                        />
                        <div className={disableQualForm ? `${classes.flexboxCol} ${classes.disableDiv}` : classes.flexboxCol}>
                            {inputFields.map((input, index) => (
                                <div key={index} className={`${classes.my3} ${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox} ${classes.skillsDiv}`} >
                                    <Autocomplete
                                        // id="combo-box2"
                                        freeSolo
                                        clearOnBlur
                                        options={courseType}
                                        // ref={courseTypeRef}
                                        name="course_type"
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            return option.course_type
                                        }
                                        }
                                        style={{ flex: 0.75, display: 'inline', width: window.innerWidth <= 600 ? '100%' : 'initial' }}

                                        value={input.course_type || ""}

                                        onChange={(event, newValue) => {
                                            event.preventDefault();
                                            setJobUpdateErrors({ ...jobUpdateErrors, qualification_req: '' });
                                            if (newValue) { // when value is selected from dropdown
                                                handleQualChange("course_type", index, newValue.course_type);
                                            } else {
                                                handleQualChange("course_type", index, null);
                                            }
                                        }}

                                        // inputValue={inputCourseValue}

                                        // onInputChange={onCourseInputChangeHandler}

                                        renderInput={(params) => <TextField {...params} error={inputFields[index].course_type === null && jobUpdateErrors.qualification_req ? true : false} helperText={inputFields[index].course_type === null && jobUpdateErrors.qualification_req ? jobUpdateErrors.qualification_req : ''} value="" label="Course Type" variant='outlined' placeholder="e.g ITI" classes={{ root: classes.skillsInput }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        }

                                        renderOption={(option) => {
                                            if (option) {
                                                return (
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.course_type} </Typography>
                                                    </Grid>
                                                )
                                            }
                                        }}
                                    />
                                    <RemoveIcon className={`${classes.mx1} ${classes.qualIcons}`} />
                                    <Autocomplete
                                        // id="combo-box"
                                        name="course"
                                        freeSolo
                                        clearOnBlur
                                        selectOnFocus
                                        options={course[index] ? course[index] : []}
                                        // ref={courseRef}

                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.course_name
                                        }
                                        }

                                        style={{ flex: 0.75, display: 'inline', margin: '1em 0', width: window.innerWidth <= 600 ? '100%' : 'initial' }}

                                        value={input.course || ""}

                                        onChange={(event, newValue) => {
                                            event.preventDefault();
                                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                                handleQualChange("course", index, newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                handleQualChange("course", index, newValue.inputValue);
                                            } else if (newValue) { // when value is selected from dropdown
                                                handleQualChange("course", index, newValue.course_name);
                                            }
                                            // else {
                                            //     handleQualChange("course", index, null);
                                            // }
                                        }}

                                        onInputChange={(event, newValue) => {
                                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                                handleQualChange("course", index, null);
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} value="" variant='outlined' label="Course" placeholder="e.g Mechanic" classes={{ root: classes.skillsInput }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        }

                                        renderOption={(option) => {
                                            if (option) {
                                                return (
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.course_name} </Typography>
                                                    </Grid>
                                                )
                                            }
                                        }}

                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            // Suggest the creation of a new value
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    course_name: `Add "${params.inputValue}"`,
                                                });
                                            }
                                            return filtered;
                                        }}
                                    />
                                    <RemoveIcon className={`${classes.mx1} ${classes.qualIcons}`} />
                                    <Autocomplete
                                        // id="combo-box3"
                                        name="specialization"
                                        freeSolo
                                        clearOnBlur
                                        selectOnFocus
                                        options={specialization[index] ? specialization[index] : []}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.specialization_name;
                                        }
                                        }
                                        style={{ flex: 0.75, display: 'inline', width: window.innerWidth <= 600 ? '100%' : 'initial' }}

                                        value={input.specialization || ""}
                                        // ref={specializationRef}
                                        onChange={(event, newValue) => {
                                            event.preventDefault();
                                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                                handleQualChange("specialization", index, newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                handleQualChange("specialization", index, newValue.inputValue);
                                            } else if (newValue) { // when value is selected from dropdown
                                                handleQualChange("specialization", index, newValue.specialization_name);
                                            }
                                            // else {
                                            //     handleQualChange("specialization", index, null);
                                            // }
                                        }}

                                        onInputChange={(event, newValue) => {
                                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                                handleQualChange("specialization", index, null);
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} value="" variant='outlined' label="Specialization" placeholder="e.g Mechanical Engineering" classes={{ root: classes.skillsInput }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        }

                                        renderOption={(option) => {
                                            if (option) {
                                                return (
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.specialization_name} </Typography>
                                                    </Grid>
                                                )
                                            }
                                        }}

                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            // Suggest the creation of a new value
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    specialization_name: `Add "${params.inputValue}"`,
                                                });
                                            }
                                            return filtered;
                                        }}
                                    />
                                    {inputFields.length > 1 &&
                                        <CancelIcon fontSize='small' className={`${classes.mx1} ${classes.icon}`}
                                            onClick={() => removeQualInput(index)}
                                        />
                                    }
                                </div>
                            ))}
                            <div>
                                <Typography gutterBottom variant='body1' component='p' style={{ display: 'inline-flex' }} classes={{ body1: classes.my1 }} color='primary' onClick={addQualInput}>
                                    <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add another qualification</span>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={`${classes.my3} ${classes.position_relative}`} >
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Skills Required</h5>
                        <div className={disableForm ? `${classes.flexboxCol} ${classes.disableDiv}` : classes.flexboxCol}>
                            {skillInputFields.map((input, index) => (
                                <div key={index} className={`${classes.my3} ${`${window.innerWidth <= 600 ? classes.flexboxCol : classes.flexbox}`} ${classes.skillsDiv}`} >
                                    <Autocomplete
                                        // id="combo-box2"
                                        freeSolo
                                        clearOnBlur
                                        options={skill}
                                        // ref={courseTypeRef}
                                        name="course_type"
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            return option.skill_name
                                        }
                                        }
                                        style={{ flex: 0.75, display: 'inline' }}

                                        value={input.skill || ""}

                                        onChange={(event, newValue) => {
                                            event.preventDefault();
                                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                                handleSkillChange('skill', index, newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                handleSkillChange('skill', index, newValue.inputValue);
                                            } else if (newValue) { // when value is selected from dropdown
                                                handleSkillChange('skill', index, newValue.skill_name);
                                            }
                                        }}

                                        onInputChange={(event, newValue) => {
                                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                                handleSkillChange("skill", index, null);
                                            }
                                        }}

                                        // inputValue={inputCourseValue}

                                        // onInputChange={onCourseInputChangeHandler}

                                        renderInput={(params) => <TextField {...params} value="" label="Skill" variant='outlined' placeholder="e.g SEO" classes={{ root: classes.skillsInput }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        }

                                        renderOption={(option) => {
                                            if (option) {
                                                return (
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.skill_name} </Typography>
                                                    </Grid>
                                                )
                                            }
                                        }}
                                    />
                                    <RemoveIcon className={`${classes.mx1} ${classes.qualIcons}`} />
                                    <TextField
                                        label="Experience required"
                                        placeholder="e.g 1 year"
                                        variant='outlined'
                                        type='string'
                                        style={{ margin: '1em 0', padding: isMobile ? 0 : 9 }}
                                        value={input.no_of_experience || ""}
                                        name="no_of_experience"
                                        onChange={e => {
                                            // setExpValue(e.target.value);
                                            let name = e.target.getAttribute('name');
                                            let list = [...skillInputFields];
                                            list[index][name] = e.target.value;
                                            setSkillInputFields(list);
                                            let filteredInputs = skillInputFields.filter(input => input.skill !== null || input.sector !== null || input.no_of_experience !== null && input.no_of_experience !== "");
                                            setUpdatedValues({ ...updatedValues, skills_required: filteredInputs });
                                            // handleSkillChange(expRef, index, expValue);
                                        }}
                                        classes={{ root: classes.skillsExpInput }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <RemoveIcon className={`${classes.mx1} ${classes.qualIcons}`} />
                                    <Autocomplete
                                        // id="combo-box3"
                                        name="specialization"
                                        freeSolo
                                        clearOnBlur
                                        selectOnFocus
                                        options={sector}
                                        getOptionLabel={(option) => {
                                            // Value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.sector_name;
                                            }
                                            return option.sector_name;
                                        }
                                        }
                                        style={{ flex: 0.75, display: 'inline' }}

                                        value={input.sector || ""}
                                        // ref={specializationRef}
                                        onChange={(event, newValue) => {
                                            event.preventDefault();
                                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                                handleSkillChange('sector', index, newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                handleSkillChange('sector', index, newValue.inputValue);
                                            } else if (newValue) { // when value is selected from dropdown
                                                handleSkillChange('sector', index, newValue.sector_name);
                                            }
                                        }}

                                        onInputChange={(event, newValue) => {
                                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                                handleSkillChange("sector", index, null);
                                            }
                                        }}

                                        renderInput={(params) => <TextField {...params} value="" variant='outlined' label="Domain" placeholder="e.g Logistics" classes={{ root: classes.skillsInput }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} />
                                        }

                                        renderOption={(option) => {
                                            if (option) {
                                                return (
                                                    <Grid container alignItems="flex-start" direction="column">
                                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.sector_name} </Typography>
                                                    </Grid>
                                                )
                                            }
                                        }}

                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            // Suggest the creation of a new value
                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    sector_name: `Add "${params.inputValue}"`,
                                                });
                                            }
                                            return filtered;
                                        }}
                                    />
                                    {skillInputFields.length > 1 &&
                                        <CancelIcon fontSize='small' className={`${classes.mx1} ${classes.icon}`}
                                            onClick={() => removeSkillInput(index)}
                                        />
                                    }
                                </div>
                            ))}
                            <div>
                                <Typography gutterBottom variant='body1' component='p' style={{ display: 'inline-flex' }} classes={{ body1: classes.my1 }} color='primary' onClick={addSkillInput}>
                                    <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add another skill</span>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} className={classes.my4} >
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Preferred Gender</h5>
                        <FormControl component="fieldset" className={`${classes.flexbox} ${classes.my4} ${classes.workTypeFormControl}`} >
                            <RadioGroup aria-label="worktype" name="opportunity_type"
                                value={updatedValues.gender_pref && updatedValues.gender_pref.toString()}
                                onChange={e => {
                                    if (e.target.value !== 'Any') {
                                        setShowTextField(true);
                                        setUpdatedValues({ ...updatedValues, gender_pref: [e.target.value] })
                                    } else {
                                        setShowTextField(false);
                                        setUpdatedValues({ ...updatedValues, gender_pref: [e.target.value], gender_pref_reason: "" })
                                    }
                                }}
                                className={classes.workTypeOptions}>
                                <FormControlLabel className={classes.mr3} value="Any" control={<Radio classes={{ root: classes.radioColor }} />} label="Any" />
                                <FormControlLabel className={classes.mr3} value="Male" control={<Radio classes={{ root: classes.radioColor }} />} label="Male" />
                                <FormControlLabel className={classes.mr3} value="Female" control={<Radio classes={{ root: classes.radioColor }} />} label="Female" />
                            </RadioGroup>
                        </FormControl>
                        {showTextField && <div>
                            <TextField
                                fullWidth
                                placeholder='Reason for gender preference'
                                classes={{ root: classes.gender_pref_field }}
                                value={updatedValues.gender_pref_reason}
                                onChange={e => setUpdatedValues({ ...updatedValues, gender_pref_reason: e.target.value })}
                            />
                        </div>}
                    </Grid>
                    <div className={classes.my1}>
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Other Requirements</h5>
                        <TextField id="outlined-basic" value={updatedValues.candidate_requirement} multiline maxRows={10} minRows={3} fullWidth variant="outlined"
                            onChange={e => {
                                let val = e.target.value
                                setUpdatedValues({ ...updatedValues, candidate_requirement: val });
                            }}
                        />
                    </div>
                    <Grid direction='column' style={{ maxWidth: 'fit-content' }} container>
                        <Grid className={`${classes.my1} ${classes.sticky_heading}`} container item direction='column'>
                            <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Screening Questions</h5>
                            <span className={classes.helper_text}>Select the questions that apply for this role</span>
                        </Grid>
                        <Grid className={classes.my2} container item direction='column'>
                            {questions.map((item, index) => {
                                return <Grid key={index} style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: window.innerWidth <= 600 ? '0.5em' : '1em 1em 1em 2em', margin: 'auto', width: '99%' }} className={classes.mb1} wrap='nowrap' justifyContent='space-between' item container>
                                    <Grid style={{ width: '100%' }} item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={updatedValues[item.name]}
                                                    onChange={(e) => handleScrQuesChange(e, item.name)}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                            label={item.ques}
                                        />
                                    </Grid>
                                </Grid>
                            })}
                            {customQuestions.length > 0 && customQuestions.map((item, index) => {
                                return <Grid alignItems='center' key={index} style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: window.innerWidth <= 600 ? '0.5em' : '1em 1em 1em 2em', margin: 'auto', width: '99%' }} className={classes.mb1} wrap='nowrap' justifyContent='space-between' item container>
                                    <Grid style={{ width: '100%' }} item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={updatedValues.custom_questions.length > 0 && updatedValues.custom_questions[index] ? updatedValues.custom_questions[index].isChecked : false}
                                                    onChange={(e) => handleCustomQuesChange(e, index, 'isChecked')}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                            label={item.question}
                                        />
                                    </Grid>
                                    {typeof (item.question) === 'string' && <Grid style={{ cursor: 'pointer' }} item onClick={() => editCustomQuestion(index)}>
                                        <EditOutlinedIcon color='primary' fontSize='small' />
                                    </Grid>}
                                </Grid>
                            })}
                            {customQuestions.length < 3 && <Grid style={{ width: '100%' }} item>
                                <Tooltip title="You can add upto 3 more questions">
                                    <Typography gutterBottom variant='body1' component='p' style={{ display: 'inline-flex' }} classes={{ body1: classes.my1 }} color='primary' onClick={addCustomQues} >
                                        <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add more questions</span>
                                    </Typography>
                                </Tooltip>
                                {/* <span className={classes.helper_text}>You can add upto 3 questions</span> */}
                            </Grid>}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} className={classes.my4} >
                        <h5 className={`${classes.fontSize_regular} ${classes.semi_bold}`}>Video Job Description</h5>
                        <InputBase
                            classes={{ root: classes.TfRoot }}
                            className={classes.my3}
                            multiline
                            style={{ borderBottom: '1px solid black' }}
                            fullWidth
                            size='medium'
                            required
                            placeholder='Paste URL here...'
                            onChange={e => setUpdatedValues({ ...updatedValues, video_jd: e.target.value })}
                            value={updatedValues.video_jd}
                        />
                        <span style={{ margin: '1em 0' }} className={`${classes.helper_text}`}> Please upload a video as job description on <a href='https://drive.google.com/' rel='noreferrer' className={classes.vidJdLinks} target='_blank' >Google Drive</a> or <a href='https://youtube.com/' rel='noreferrer' className={classes.vidJdLinks} target='_blank' >Youtube</a> and paste the link here. </span>
                    </Grid> */}


                    <Button variant="contained" disabled={isSubmitting} color="primary" component="span" onClick={saveFiles} className={`${classes.ttc}`} style={{ margin: window.innerWidth <= 600 ? '0 0 7em 0' : '1rem 0', width: window.innerWidth <= 600 ? '100%' : 'initial' }}>
                        Save Changes
                    </Button>

                </div>
            </div>
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
            <Snackbar open={isErrorAlertOpen} autoHideDuration={6000} onClose={() => setIsErrorAlertOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <ErrorAlert onClose={() => setIsErrorAlertOpen(false)} severity="error">
                    Please fill all the required details!
                </ErrorAlert>
            </Snackbar>
        </>
    )
};

const durationYears = [
    { id: 1, name: '1 year' },
    { id: 2, name: '2 years' },
    { id: 3, name: '3 years' },
];
const durationMonths = [
    { id: 1, name: '1 month' },
    { id: 2, name: '2 months' },
    { id: 3, name: '3 months' },
    { id: 4, name: '4 months' },
    { id: 5, name: '5 months' },
    { id: 6, name: '6 months' },
    { id: 7, name: '7 months' },
    { id: 8, name: '8 months' },
    { id: 9, name: '9 months' },
    { id: 10, name: '10 months' },
    { id: 11, name: '11 months' },
];
