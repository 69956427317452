import React, { useRef } from 'react';
import { Icon, Paper, TextField, Button, Typography, Snackbar, MenuItem, MenuList } from '@material-ui/core';
import '../../assets/css/meetApp.css';
import { makeStyles } from '@material-ui/core/styles';
//import AppBarLanding from '../../components/AppBarLanding';
import QualificationsList2 from '../Login/QualificationsList2';
import MobileQualificationList from '../Login/MobileQualificationsList';
import SearchIcon from '@material-ui/icons/Search';
import { ThemeProvider } from '@material-ui/styles';
import MyTheme from '../../style/JobAppTheme';
import { StylesProvider } from "@material-ui/core/styles";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import LanguageIcon from '@material-ui/icons/Language';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import '../Login/Login_2.css'
import myInitObject from './location';
import dividersCount from './dividersCount';
import loaderCountOnPg2Search from './loaderCountOnPg2Search';
import selected_qualifications from './quals';
import { Sort } from '@material-ui/icons';
import poweredByGoogle from '../../assets/powered_by_google_on_white.png';
import AnalyticsHandler from '../../AnalyticsHandler';
import { RolesNotToBePassedToAlgolia } from '../../Globals';

var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  bgOverlay: {
    background: 'rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  pageTitle: {
    color: "white",
    paddingTop: "135px",
    paddingBottom: "50px",
    fontSize: "36px",
    fontWeight: 'bolder',
    textShadow: "2px 2px 5px black"
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    // width: 900,
    width: 820,
    height: 51,
    // paddingLeft: 15,
    paddingLeft: 10,
    borderRadius: 35,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 52,
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      border: 'none',
      background: 'transparent',
      boxShadow: 'none',
      height: 'initial',
      paddingLeft: 0,
      width: '90%'
    },
  },
  searchBarIconButton: {
    marginTop: 0,
    height: 51,
    width: 51,
    minWidth: 60,
    textTransform: "none !important",
    backgroundColor: '#14967B !important',
    borderRadius: '0 35px 35px 0 !important',
    color: 'white !important',
    "&:hover": {
      background: "#5BC2A8 !important",
    },
    "&.Mui-disabled": {
      backgroundColor: '#14967B !important',
      color: '#fff !important',
    },
    '@media (max-width: 600px)': {
      alignSelf: 'flex-end',
      height: 'initial',
      borderRadius: '6px !important',
      margin: '20px 0',
      backgroundColor: '#55c6a9 !important',
    }
  },
  // icon: {
  //   color: theme.palette.text.secondary,
  //   marginRight: theme.spacing(2),
  // },
  // gpsIcon: {
  //   paddingRight: 16,
  //   color: '#5BC2A8',
  // },
  // gpsText1: {
  //   fontSize: 20,
  //   color: '#5BC2A8',
  //   marginBottom: 5,
  // },
  // gpsText2: {
  //   fontSize: 14,
  //   color: '#6b6b6b',
  // },
  icon: {
    //color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    color: '#999',
    fontSize: 16,
    marginTop: 5,
  },
  gpsIcon: {
    paddingRight: 16,
    //color: '#5BC2A8',
    color: '#999',
    fontSize: 16,
    marginTop: 4,
  },
  gpsText1: {
    fontSize: 20,
    color: '#5BC2A8',
    marginBottom: 5,
  },
  gpsText2: {
    fontSize: 14,
    color: '#6b6b6b',
  },
  comboBoxRoot: {
    marginTop: 14,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
      '@media (max-width: 600px)': {
        backgroundColor: '#fff'
      }
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
    // "&:after": {
    //   position: 'absolute',
    //   content: '""',
    //   width: 1,
    //   height: 24,
    //   backgroundColor: '#E0E0E0',
    // },
    '@media (max-width: 600px)': {
      border: '1px solid #55c6a9 !important',
      borderRadius: 5,
    }
  },
  mobileQualboxContainer: {
    '@media (max-width: 600px)': {
      position: 'relative',
      border: '1px solid #55c6a9',
      // height: '43px',
      borderRadius: '5px'
    }
  },
  qualLabel: {
    '@media (max-width: 600px)': {
      position: 'absolute',
      top: '-18%',
      zIndex: 9999,
      backgroundColor: 'white',
      height: '5px',
      marginLeft: '8px'
    }
  },
  qualInputWrapper: {
    '@media (max-width: 600px)': {
      margin: '10px'
    }
  },
  inputRoot: {
    padding: '0 0 0 8px!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    //   "& .MuiIconButton-label": { 
    //     display: "flex",  
    // },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
  },

  popupIndicator: {
    display: 'none'
  },

  input: {
    height: 43,
    maxWidth: '98%',
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },
  },
  popper: {
    marginTop: 16,
  },
  popperLocation: {
    marginTop: 16,
    "& .MuiAutocomplete-listbox": {
      paddingBottom: 0,
    },
    "& .MuiAutocomplete-listbox::after": {
      content: '""',
      position: 'absolute',
      right: 8,
      bottom: 8,
      width: 144,
      height: 18,
      backgroundImage: `url(${poweredByGoogle})`,
      backgroundRepeat: 'no-repeat',
    }
  },

  listbox: {
  },
  paper: {
    borderRadius: 10,
    "& .MuiAutocomplete-option[aria-disabled='true']": { // add this to make the disabled instruction text of role box clear
      opacity: 1
    }
  },
  paperLocation: {
    borderRadius: 10,
    paddingBottom: 30
  },
  checkboxLabel: {
    fontSize: 13
  },
  checkboxRoot: {
    padding: 5
  },
  desktopSearchIcon: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  mobileSearchIcon: {
    '@media (min-width: 600px)': {
      display: 'none'
    }
  },
}));

var option_to_add_anywhere =
{
  description: "Anywhere/ Work-from-Home",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Anywhere/ Work-from-Home",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Anywhere/ Work-from-Home"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

var option_to_add_single =
{
  description: "Near Me",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Near Me",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Near Me"
        }
      ]
  },
  types:
    [
      "establishment", "political", "geocode"
    ]
}

var option_to_add =
{
  description: "Near Me",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Near Me",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Near Me"
        }
      ]
  },
  types:
    [
      "establishment", "political", "geocode"
    ]
}

var optionAssociatedWithWfh =
{
  description: "Anywhere/ Work-from-Home",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Anywhere/ Work-from-Home",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "wfh"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

function change_display(address) {
  var option_to_display =
  {
    description: address,
    matched_substrings: [
      {
        length: 2,
        offset: 0,

      }],
    structured_formatting:
    {
      main_text: address,
      main_text_matched_substrings:
        [{
          length: 2,
          offset: 0
        }
        ],
      terms:
        [
          {
            offset: 0,
            value: address
          }
        ],
      types:
        [
          "locality", "political", "geocode"
        ]
    }
  }

  return option_to_display;

}

const filter = createFilterOptions();

export default function SearchBar(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [roleValue, setRoleValue] = React.useState(null);
  //const [roleValue, setRoleValue] = React.useState(props.role ? props.role : null);
  // const [inputRoleValue, setInputRoleValue] = React.useState(null);
  const [inputRoleValue, setInputRoleValue] = React.useState(props.role ? props.role : "");
  const [searchedRole, setSearchedRole] = React.useState(props.role ? props.role : "");
  const [inputBlur, setInputBlur] = React.useState(true);
  const [inputBlurRefresh, setInputBlurRefresh] = React.useState(false);
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const [isFirstLocRender, setIsFirstLocRender] = React.useState(true);
  const [location, setLocation] = React.useState("");
  const [current_latitude, setCurrentLatitude] = React.useState(null);
  const [current_longitude, setCurrentLongitude] = React.useState(null);
  const [current_role, setCurrentRole] = React.useState(null);
  //const [current_role, setCurrentRole] = React.useState(props.role ? props.role : null);
  const [current_qualifications, setCurrentQualifications] = React.useState(props.quals ? props.quals : null)
  const [autoQualsFlag2, setAutoQualsFlag2] = React.useState(props.autoQualsFlag ? props.autoQualsFlag : false)
  const [Roles, setRoles] = React.useState([]);
  const [jobseekersRoles, setJobseekersRoles] = React.useState(null);
  const [geo_type, setGeoType] = React.useState(props.geographicalType ? props.geographicalType : null);
  // console.log(props.geographicalType)
  const autoClickRef = useRef(null);
  const loaded = React.useRef(false);
  const [flag_your_location, setFlagYourLocation] = React.useState(false);
  const [location_invite, setLocationInvite] = React.useState("");
  const [isNearMeResultLoading, setIsNearMeResultLoading] = React.useState(false);

  const [filters, setFilters] = React.useState({
    freshers: false,
    female: false
  })

  const SearchButton = () => {
    return <>
      <SearchIcon fontSize="large" className={classes.desktopSearchIcon} />
      <span className={classes.mobileSearchIcon}>Search</span>
    </>
  }

  const handleFilters = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.checked })
  }

  const OpenLocationBoxRef = useRef(null);
  const clickSearchRef = useRef(null);

  //console.log(props.quals)
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  // const onRoleInputChangeHandler = (event, newInputValue, reason) => {
  //   if (isFirstRender === true) { 
  //     //setInputRoleValue(props.role);
  //     if (newInputValue !== "") {alert(1)
  //       setIsFirstRender(false)
  //       setInputRoleValue(newInputValue);
  //     }
  //   } else {
  //     setInputRoleValue(newInputValue);
  //     //props.setRole(newInputValue);
  //   }
  //   console.log("Role = " + inputRoleValue)
  // }

  const onRoleInputChangeHandler = (event, newInputValue, reason) => {
    if (isFirstRender === true && newInputValue === "") { // onload it will enter this
      setIsFirstRender(false);
      //setRoleValue(inputRoleValue);
    } else {
      setInputRoleValue(newInputValue);
      setCurrentRole(newInputValue);
      //console.log("Role = " + newInputValue)
    }
  }

  React.useEffect(() => {
    if (props.setLocationRef)
      props.setLocationRef(OpenLocationBoxRef);
  }, [OpenLocationBoxRef])

  React.useEffect(() => {
    if (props.setSearchRef)
      props.setSearchRef(clickSearchRef);
  }, [])

  React.useEffect(() => {
    if (props.location_value == "Anywhere/ Work-from-Home") {
      var display = change_display("Anywhere/ Work-from-Home")
      setValue(display);
      props.searchRef.current.click();
    }
  }, [])

  React.useEffect(() => {
    axios.get(BASE_URL + '/users/get_job_role_mapping/').then((Result) => {
      let rolesArr = Result.data;
      let allRole = { title: 'any' }
      let fresherRole = { title: 'Fresher' }
      rolesArr.unshift(allRole) // add all at the first place
      rolesArr.splice(1, 0, fresherRole); // add fresher at 2nd place

      let roleBoxTitle = {
        title: 'ب',
      }
      //let fresherIndex = rolesArr.findIndex(getFresher);
      // if (fresherIndex !== -1) { alert(1)
      //   rolesArr.splice(fresherIndex, 1);  // Remove Fresher from the list of roles
      // }
      // function getFresher(n) {
      //   if (n.title === 'Fresher') {
      //     return 1
      //   } else {
      //     return 0
      //   }
      // }

      rolesArr.unshift(roleBoxTitle) // add title text at the first
      setRoles(rolesArr);

    })

    axios.get(BASE_URL + '/users/get_user_post_list/').then((Result) => {
      let rolesArr = Result.data;
      setJobseekersRoles(rolesArr);
      //console.log(rolesArr)
    })
  }, []);

  // to handle the case of setting secondary roles when the url(.../discovery/role/location) is hit directly --starts
  const isRolesFirstRender = useRef(true)
  React.useEffect(() => {
    if (isRolesFirstRender.current) {
      isRolesFirstRender.current = false // toggle flag after first render/mounting
      return;
    }

    // if (jobseekersRoles === null) return

    if (RolesNotToBePassedToAlgolia.includes(searchedRole.toLowerCase().trim())) return // if role is equal to all then do not set secondaryroles

    //do a custom role string search through the list of roles and their synonyms
    const matchedRolesArray = Roles.filter(item =>
      item['title'] && item['title'].toLowerCase().includes(searchedRole.toLowerCase()) ||
      item['synonyms'] && item['synonyms'].some(item => item.toLowerCase().includes(searchedRole.toLowerCase()))
    );
    props.setSecondaryRoles(matchedRolesArray);
    //console.log(matchedRolesArray);

    // if (props.setJobseekerEnteredRoles && jobseekersRoles) {
    //   //do a custom role string search through the list of roles
    //   const matchedRolesArray1 = jobseekersRoles.filter(item =>
    //     item['title'] && item['title'].toLowerCase().includes(searchedRole.toLowerCase())
    //   );
    //   const matchedRolesArrayFinal = matchedRolesArray1.map(item => item.title);
    //   console.log(matchedRolesArrayFinal);
    //   props.setJobseekerEnteredRoles(matchedRolesArrayFinal);
    // }
  }, [Roles]) // this will run only once when the above setRoles will set the roles onload

  // to handle the case of setting secondary roles when the url(.../discovery/role/location) is hit directly --ends

  // to handle the case of setting jobseeker entered roles when the url(.../discovery/role/location) is hit directly --starts
  const isJobseekersRolesFirstRender = useRef(true)
  React.useEffect(() => {
    if (isJobseekersRolesFirstRender.current) {
      isJobseekersRolesFirstRender.current = false // toggle flag after first render/mounting
      return;
    }

    if (RolesNotToBePassedToAlgolia.includes(searchedRole.toLowerCase().trim())
      // searchedRole.toLowerCase() === "all"
    ) {
      props.setJobseekerEnteredRoles([])
      return // if role is equal to any then set jobseekerenteredroles to empty array
    }

    if (props.setJobseekerEnteredRoles) {
      //do a custom role string search through the list of roles
      const matchedRolesArray = jobseekersRoles.filter(item =>
        item['title'] && item['title'].toLowerCase().includes(searchedRole.toLowerCase())
      );
      const matchedRolesArrayFinal = matchedRolesArray.map(item => item.title);
      //console.log(matchedRolesArrayFinal);
      props.setJobseekerEnteredRoles(matchedRolesArrayFinal);
    }
  }, [jobseekersRoles]) // this will run only once when the above setJobseekersRoles will set the roles onload

  // to handle the case of setting jobseeker entered roles when the url(.../discovery/role/location) is hit directly --ends

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    // if (inputValue === '') {
    //   //console.log("input value...............")
    //   setOptions([option_to_add_anywhere, option_to_add_single])
    //   return undefined;
    // }

    if (inputValue === '') {
      //console.log("input value...............")
      let showNearMeInDropdown = sessionStorage.getItem("showNearMeInDropdown");
      if (showNearMeInDropdown) { //when nearest is clicked and input value is empty show near me
        setOptions([option_to_add_single])
      } else {
        setOptions([option_to_add_anywhere, option_to_add_single])
      }

      return undefined;
    }

    if (inputValue === "Anywhere/ Work-from-Home") {
      setOptions([option_to_add_single])
      return undefined;
    }

    let wfhTerms = ['wfh', 'work ', 'work from', 'work from home', 'workfromhome', 'anywhere', 'remote'];
    if (wfhTerms.includes(inputValue.toLowerCase())) {
      setOptions([optionAssociatedWithWfh]);
      return undefined;
    }

    // location: {lat: 27.3868934, lng: 80.1040091},
    // radius: 400000  

    fetch({
      input: inputValue,
      componentRestrictions: { country: "in" }
    }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {

          // if (inputValue == "") {
          //   results.unshift(option_to_add);
          //   results.unshift(option_to_add_anywhere);
          // }

          // if (inputValue === 'Anywhere/ Work-from-Home') {
          //   results = [option_to_add, option_to_add_anywhere];
          // }

          if (flag_your_location) {
            results = [option_to_add_anywhere]
          }

          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  // clear value as well on click of clear location button--starts
  let locText = ""
  const handleLocClear = () => {
    setTimeout(() => {
      setValue(locText);
    }, 1)
  }

  React.useEffect(() => {
    const locClearBtn = OpenLocationBoxRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (locClearBtn) {
      locClearBtn.addEventListener('click', handleLocClear)
    }
    return () => {
      locClearBtn.removeEventListener('click', handleLocClear)
    };
  }, [])
  // clear value as well on click of clear location button--ends

  const [triggerClick, setTriggerClick] = React.useState(false);
  const trackInitialRenderRef = React.useRef(true);
  const trackInitialRenderRef_2 = React.useRef(true);
  const [refreshAutoQualifications, setRefreshAutoQualifications] = React.useState(false);

  React.useEffect(() => {
    setGeoType(props.geographicalType)
  }, [props.geographicalType])
  //workaround: this is to wait for the value of geo_type to get updated by props.geographicalType
  //console.log(geo_type)

  const searchClickAuto = () => {
    //props.setPage(1);
    props.setGeographicalType(geo_type);
    dividersCount.count = (dividersCount.count > 1000) ? 1 : (dividersCount.count + 1);
    loaderCountOnPg2Search.count = (loaderCountOnPg2Search.count > 1000) ? 1 : (loaderCountOnPg2Search.count + 1);
    //console.log("dividersCount:::" + dividersCount.count);
    props.setFlagLocationText(false);

    if (current_role) {
      props.setRole(current_role);
      //setSearchedRole(current_role);

      if (props.setJobseekerEnteredRoles &&
        !RolesNotToBePassedToAlgolia.includes(searchedRole.toLowerCase().trim())
        // searchedRole.toLowerCase() !== "all"
      ) {
        const matchedRolesArray = jobseekersRoles.filter(item =>
          item['title'] && item['title'].toLowerCase().includes(searchedRole.toLowerCase())
        );
        const matchedRolesArrayFinal = matchedRolesArray.map(item => item.title);
        //console.log(matchedRolesArrayFinal);
        props.setJobseekerEnteredRoles(matchedRolesArrayFinal);
      } else { //do not do a custom role string search through the list of jobseekersRoles if role is the same as all or fresher
        props.setJobseekerEnteredRoles([]);
      }
    }

    if (props.setSecondaryRoles &&
      !RolesNotToBePassedToAlgolia.includes(searchedRole.toLowerCase().trim())
      //searchedRole.toLowerCase() !== "all"
    ) {
      // do a custom role string search through the list of roles and their synonyms
      const matchedRolesArray = Roles.filter(item =>
        item['title'] && item['title'].toLowerCase().includes(searchedRole.toLowerCase()) ||
        item['synonyms'] && item['synonyms'].some(item => item.toLowerCase().includes(searchedRole.toLowerCase()))
      );
      props.setSecondaryRoles(matchedRolesArray);
      //console.log(matchedRolesArray);
    } else { //do not do a custom role string search through the list of roles if role is the same as all or fresher
      props.setSecondaryRoles([]);
    }

    if (location == "Anywhere/ Work-from-Home" || value === "") {
      props.setLatitude(null);
      props.setLongitude(null);
      props.onWfh();
      if (value === "") {
        setValue("Anywhere/ Work-from-Home");
        // props.onWfh();
      }
    } else if (current_latitude && current_longitude) {
      props.setLatitude(current_latitude);
      props.setLongitude(current_longitude)
    } else if (!current_latitude && !current_longitude && props.flag_no_results) {
      props.setLatitude(current_latitude);
      props.setLongitude(current_longitude);
      props.setFlagNoResults(false);
    }

    myInitObject.lat = current_latitude;
    myInitObject.long = current_longitude;

    if (current_qualifications) {
      props.setQualifications((prev) => ([...current_qualifications]));
    }
    // state update for divider and loader messages
    if (props.setAutoQualsFlag) props.setAutoQualsFlag(autoQualsFlag2);


    // making sure the sorting criteria is applied
    if (props.ref_last_active_sort.current) {
      props.setFlagSortRecentlyActive(false);
      // props.ref_last_active_sort.current.click();  // moved to algolia_search file
      //removed from here so that analytics data is not sent to algolia for sort button click
    }
    else if (props.ref_nearest_sort.current) {
      props.setFlagSortNearest(false);
      //  props.ref_nearest_sort.current.click();
    }
    else if (props.ref_most_exp_sort.current) {
      if (current_role && current_role.toLowerCase().trim() === 'fresher') {
        props.switchtoRecentFromMostExp();
        props.setFlagSortRecentlyActive(false); // check later if this is needed
      } else {
        props.setFlagSortMostExperienced(false);
      }
    }

    //in case of only location being changed
    var newRole = current_role ? current_role : props.role;

    var newPath = (location === "Anywhere/ Work-from-Home" || value === "") ? `/discover/${newRole.split(" ").join("-")}/anywhere` : `/discover/${newRole.split(" ").join("-")}/${location.split(" ").join("-").replaceAll("/", "_")}`

    //to change the URL according to new search parameters so that it can be shared
    window.history.replaceState(null, "", newPath);

  }

  React.useEffect(() => {
    //console.log(searchedRole)
    // so that this function does not run first time on main page load
    if (trackInitialRenderRef.current) {
      trackInitialRenderRef.current = false;
      return;
    }
    searchClickAuto();
    AnalyticsHandler.track('discoveryResults_Search_btnClick');
    props.handleLocationForInvite(location_invite);
  }, [triggerClick]) // on click of search button, triggerClick will be set and will trigger this effect

  React.useEffect(() => {
    //so that this useEffect doesnt run the first time on page load
    if (trackInitialRenderRef_2.current) {
      trackInitialRenderRef_2.current = false;
      return;
    }
    searchClickAuto();
  }, [props.autoTriggerSearch])
  // autotriggerSearch is used as a workaround which autotriggers the search btn click when switching to list view

  React.useEffect(() => {
    if (current_qualifications) {
      props.setQualifications([...current_qualifications]);
    }
    if (props.setAutoQualsFlag) props.setAutoQualsFlag(autoQualsFlag2);

  }, [refreshAutoQualifications])

  return (
    <div className="searchBarOuterWrapper" style={{ display: window.innerWidth <= 600 ? 'flex' : 'block', alignItems: 'center', justifyContent: 'center' }} ref={trackInitialRenderRef}>
      {/* <StylesProvider injectFirst> */}
      <div className={`${classes.searchBarWrapper} ${classes.shrink}`} ref={trackInitialRenderRef_2} >
        <Autocomplete
          id="combo-box"
          clearOnBlur={false}
          disableClearable={true}
          freeSolo
          options={Roles}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            return option.title
          }
          }
          style={{ flex: 0.75 }}
          value={roleValue}
          onChange={(event, newValue) => {
            event.preventDefault();
            // if (newValue) { console.log("onchange = " + newValue)
            //   setCurrentRole(newValue.title)
            //   setRoleValue(newValue)
            //   selected_qualifications.role = newValue.title;
            //   setInputBlur(false);
            // }
            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
              //console.log("onchange 1 = " + newValue)
              setCurrentRole(newValue);
              setRoleValue(newValue);
              selected_qualifications.role = newValue;
              setInputBlur(false);
            } else if (newValue) { // when value is selected from dropdown
              //console.log("onchange 2 = " + newValue.title)
              setCurrentRole(newValue.title)
              setRoleValue(newValue)
              selected_qualifications.role = newValue.title;
              setInputBlur(false);
            }
          }}
          inputValue={inputRoleValue}
          onInputChange={onRoleInputChangeHandler}
          onBlur={(event) => {
            //alert(event.target.value)
            if (inputBlur && inputRoleValue === "") {
              //setInputBlurRefresh(prev => !prev);
              setInputRoleValue(props.role);
              //alert("blur")
            } else if (!inputBlur && inputRoleValue === "") {
              setInputRoleValue(current_role);
            }
          }}
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}
          renderInput={(params) => <TextField {...params} label="Role*" value="" variant="outlined" placeholder="What position are you hiring for?"
            InputLabelProps={{
              shrink: true,
            }} />
          }
          getOptionDisabled={(option) => option === Roles[0]} //disabled when first option is the instruction title
          renderOption={(option) => {
            if (option) {
              return (
                <Grid container alignItems="flex-start" direction="column">
                  {option.title === "ب" ?
                    //add instruction title at the top of dropdown
                    (<Grid container alignItems="flex-start" style={{ opacity: 1 }}>
                      <Grid item style={{ padding: '0 0 10px' }}>
                        <Typography variant="body2" color="textSecondary" style={{ opacity: 1 }}>
                          Type job role or select 👇
                        </Typography>
                      </Grid>
                    </Grid>
                    )
                    : (<Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.title} </Typography>) // add options in the list
                  }
                </Grid>
              )
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            function getInputInList(n) {
              if (n.title.toLowerCase() === params.inputValue.toLowerCase()) {
                return 1
              } else {
                return 0
              }
            }
            let inputIndex = Roles.findIndex(getInputInList);
            //console.log("inputIndex = " + inputIndex)

            // add new input value at the top of the dropdown list if value is not empty or already exists in list
            if (params.inputValue !== '' && inputIndex === -1) {
              filtered.unshift({
                inputValue: params.inputValue,
                title: `${params.inputValue}`,
              });
            }
            return filtered;
          }}
        />
        <Autocomplete
          id="google-map"
          ref={OpenLocationBoxRef}
          key={props.key_autocomplete}
          style={{ flex: 0.95 }}
          openOnFocus
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popperLocation, listbox: classes.listbox, paper: classes.paperLocation, popupIndicator: classes.popupIndicator }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={isFirstLocRender ? (props.loc !== "" ? props.loc : "Anywhere/ Work-from-Home") : (props.flag_location_text ? "Anywhere/ Work-from-Home" : value)}
          onChange={(event, newValue) => {
            console.log(newValue)
            setFlagYourLocation(false);
            var display = newValue;

            if (newValue) {
              setIsFirstLocRender(false);
            }
            // if geotype is present
            if (newValue && newValue.types && newValue.types[0]) {
              // props.setGeographicalType(newValue.types[0])
              setGeoType(newValue.types[0]);
            }
            // if location is typed
            if (newValue && newValue.terms) {

              var location = newValue.terms[0].value;
              //debugger;
              var i = 0, temp_location_invite = "";

              for (; i < newValue.terms.length; i++) {
                temp_location_invite = temp_location_invite + newValue.terms[i].value + ","
              }

              var n = temp_location_invite.lastIndexOf(",");
              temp_location_invite = temp_location_invite.substring(0, n);

              //console.log(temp_location_invite);

              setLocationInvite(temp_location_invite);

              // if (location == "Jaipur") {
              //   setCurrentLatitude(26.8854479);
              //   setCurrentLongitude(75.6504695);
              //   setValue(newValue);

              // }

              // else

              //   if (location !== "Near Me" && location != "Anywhere/ Work-from-Home") {

              //     axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + location + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
              //       // debugger;
              //       if (result) {
              //         //  debugger;
              //         setCurrentLatitude(result.data.results[0].geometry.location.lat)
              //         setCurrentLongitude(result.data.results[0].geometry.location.lng)
              //       }
              //     }).then(() => {
              //       setValue(newValue);
              //     })
              //   }

              if (newValue.place_id) { //place_id used instead of terms as for places like medha newValue.terms[0] returns no results
                if (location !== "Near Me" && location != "Anywhere/ Work-from-Home") {
                  axios.get('https://maps.googleapis.com/maps/api/geocode/json?place_id=' + newValue.place_id + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) => {
                    if (result) {
                      console.log(result)
                      setCurrentLatitude(result.data.results[0].geometry.location.lat)
                      setCurrentLongitude(result.data.results[0].geometry.location.lng)
                    }
                  }).then(() => {
                    setValue(newValue);
                  })
                }
              }

            }
            // if near me
            if (newValue && newValue == option_to_add || newValue == option_to_add_single) {
              setIsNearMeResultLoading(true);
              var lat, lng;

              setValue("");

              navigator.geolocation.getCurrentPosition(function (position) {

                lat = position.coords.latitude;
                lng = position.coords.longitude;

                // debugger;
                setCurrentLatitude(lat);
                setCurrentLongitude(lng);

                axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + "," + lng + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0')
                  .then((response) => {
                    var address = response.data.results;
                    var address_1 = address[1].formatted_address;

                    setLocation(address_1);

                    setLocationInvite(address_1);

                    display = change_display(address_1);

                    setIsNearMeResultLoading(false);

                  }).then(() => {
                    setValue(display);
                    setFlagYourLocation(true);
                  })
              }, function () { // onerror
                setInputValue("") // to remove current location text from location box on error in geolocation API
                setIsNearMeResultLoading(false);
                // handleSnackBar("You need to enable Location access services from your Browser settings to use this feature.")
              });
            }
            // if anywhere/ work-from-home
            if (newValue && newValue == option_to_add_anywhere) {

              setCurrentLatitude(null);
              setCurrentLongitude(null);
              display = change_display("Anywhere/ Work-from-Home");
              setValue(display);
            }

            // if optionAssociatedWithWfh
            if (newValue == optionAssociatedWithWfh) {
              setCurrentLatitude(null);
              setCurrentLongitude(null);
              setValue(optionAssociatedWithWfh);
            }
          }

          }
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {

            setLocation(newInputValue);
            setInputValue(newInputValue);
            if (newInputValue === "") {
              setValue("");
              setCurrentLatitude(null);
              setCurrentLongitude(null);
              setIsFirstLocRender(false);

            }
            if (newInputValue.length === 1) { //so that if after clicking near me the user selects the input and writes a letter, the map location options should show up without pressing the backspace
              setFlagYourLocation(false);
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Work Location" variant="outlined" placeholder="Start typing. e.g. Hazratganj, Lucknow" fullWidth InputLabelProps={{
              shrink: true,
            }} />
          )}
          renderOption={(option) => {
            if (option.structured_formatting) {

              const matches = option.structured_formatting.main_text_matched_substrings;

              if (matches) {
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                  <Grid container alignItems="flex-start" direction="column">
                    {option.structured_formatting.terms && option.structured_formatting.terms[0].value === "Anywhere/ Work-from-Home" &&
                      (<Grid container alignItems="flex-start">
                        <Grid item style={{ padding: '0 0 10px' }}>
                          <Typography variant="body2" color="textSecondary">
                            Or Choose
                          </Typography>
                        </Grid>
                      </Grid>
                      )
                    }
                    <Grid container alignItems="flex-start">
                      {/* {option.description === "Current Location" ?
                          <Grid item>
                            <GpsFixedRoundedIcon className={classes.gpsIcon} />
                          </Grid>
                          :
                          <Grid item>
                            <LocationOnIcon className={classes.icon} />
                          </Grid>
                        } */}

                      {option.description === "Anywhere/ Work-from-Home" &&
                        (<Grid item>
                          <LanguageIcon className={classes.gpsIcon} />
                        </Grid>)}
                      {option.description === "Near Me" &&
                        (<Grid item>
                          <GpsFixedRoundedIcon className={classes.gpsIcon} />
                        </Grid>)}
                      {option.description !== "Anywhere/ Work-from-Home" && option.description !== "Near Me" &&
                        (<Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>)}

                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: option.structured_formatting.main_text === "Detect Current Location" ? 'black' : '#333', fontSize: 14 }}>
                            {part.text}
                          </span>
                        ))}
                        {option.description === "Near Me" ? null
                          : <Typography variant="body2" color="textSecondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }
            }
          }
          }
        />
        <div style={{marginTop: window.innerWidth <= 600 ? '10px' : 0}} className="qualificationsWrapper--Mini">
          {window.innerWidth <= 600 ?
            <MobileQualificationList
              searchedRole={searchedRole}
              quals={props.quals}
              triggerClick={triggerClick}
              qualifications={current_qualifications}
              setCurrentQualifications={setCurrentQualifications}
              setAutoQualifications={setCurrentQualifications}
              setRefreshAutoQualifications={setRefreshAutoQualifications}
              // setAutoQualsFlag={props.setAutoQualsFlag}
              setAutoQualsFlag2={setAutoQualsFlag2}
              isAnyQualCatChecked={props.isAnyQualCatChecked}
              classes={classes}
              isSecondarySearchBar={props.isSecondarySearchBar}
            />
            : <QualificationsList2
              searchedRole={searchedRole}
              quals={props.quals}
              triggerClick={triggerClick}
              qualifications={current_qualifications}
              setCurrentQualifications={setCurrentQualifications}
              setAutoQualifications={setCurrentQualifications}
              setRefreshAutoQualifications={setRefreshAutoQualifications}
              // setAutoQualsFlag={props.setAutoQualsFlag}
              setAutoQualsFlag2={setAutoQualsFlag2}
              isAnyQualCatChecked={props.isAnyQualCatChecked}
            />
          } </div>
        <Button id="discovery_search_btn_id" size="small" ref={clickSearchRef} variant="contained" className={classes.searchBarIconButton}
          onClick={() => {
            //console.log("current role when search is clicked = " + current_role )
            // props.setSearchBarOpen ? props.setSearchBarOpen(false) : null
            let mobSearchBar = document.querySelector('.mobileSearchBarSecondary')
            let mobSearchInput = document.querySelector('.searchinput')

            if (mobSearchBar) mobSearchBar.style.height = '0px';
            if (mobSearchInput) mobSearchInput.style.display = 'inherit';
            if (props.onLocationChange && typeof props.onLocationChange === 'function') props.onLocationChange(location);
            if (current_role) {
              setSearchedRole(current_role);
            }
            setTriggerClick(prev => !prev);
          }} disabled={isNearMeResultLoading}>
          {isNearMeResultLoading ?
            <div style={{ display: 'flex' }}>
              <CircularProgress style={{ color: '#fff' }} size={20} /></div>
            : <SearchButton />
          }
        </Button>
      </div>

      {/* <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={filters.freshers}
            onChange={handleFilters}
            name="freshers"
            color="primary"
            classes={{root: classes.checkboxRoot}}
          />
        }
        label="Freshers Only"
        classes={{label: classes.checkboxLabel}}
      />

      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={filters.female}
            onChange={handleFilters}
            name="female"
            color="primary"
            classes={{root: classes.checkboxRoot}}
          />
        }
        label="Female Only"
        classes={{label: classes.checkboxLabel}}
      /> */}
      {/* </StylesProvider> */}
    </div>
  );
}

