import React from 'react';
import { Typography, Grid, InputBase, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '@material-ui/icons/Remove';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
var axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;
const filter = createFilterOptions();

let newHashMap = []

function Qualifications(props) {
    const { classes, values, setValues, errors, setErrors } = props;

    const [disableForm, setDisableForm] = React.useState(false);
    const [inputFields, setInputFields] = React.useState([
        {
            course_type: null,
            course: null,
            specialization: null
        },
        // {
        //     course_type: null,
        //     course: null,
        //     specialization: null
        // },
        // {
        //     course_type: null,
        //     course: null,
        //     specialization: null
        // }
    ])

    const [isLoading, setIsLoading] = React.useState(false);
    //dropdown options for every qualification
    const [course, setCourse] = React.useState([]);
    const [courseType, setCourseType] = React.useState([]);
    const [specialization, setSpecialization] = React.useState([]);
    const [isChecked, setIsChecked] = React.useState(false);
    // const courseTypeRef = React.useRef();
    // const courseRef = React.useRef();
    // const specializationRef = React.useRef();

    const addQualInput = () => {
        setInputFields([...inputFields, { course_type: null, course: null, specialization: null }]);
        setValues({...values, qualification_req: [...values.qualification_req, { course_type: null, course: null, specialization: null }]})
    }

    const removeQualInput = (index) => {
        const list = [...inputFields];
        list.splice(index, 1);
        setInputFields(list);
        setValues({ ...values, qualification_req: [...list] });

        //remove course dropdown vals on delete
        let courseDropdown = [...course];
        courseDropdown.splice(index, 1);
        setCourse(courseDropdown);

        //remove specialization dropdown vals on delete
        let specializationDropdown = [...specialization];
        specializationDropdown.splice(index, 1);
        setSpecialization(specializationDropdown);
    }

    const disableFormDiv = () => {
        setDisableForm(!disableForm);
        setIsChecked(!isChecked);
        setValues({ ...values, qualification_req: [{ course_type: "any", course: "any", specialization: "any" }] });
    }

    const handleQualChange = (key, index, val) => {
        //const name = ref.current.getAttribute("name");
        const list = [...inputFields];
        list[index][key] = val;
        setInputFields(list);
        let filteredInputs = inputFields.filter(input => input.course_type !== null || input.course !== null || input.specialization !== null);
        setValues({ ...values, qualification_req: filteredInputs });
        if (key === "course_type") { //to create dropdown options for course name
            let newCourses = newHashMap[val] ? newHashMap[val].filter((item, i, array) =>
                array.findIndex(t => t.course_name == item.course_name) == i
            ) : null

            let temp_array = []
            temp_array = [...course];
            temp_array[index] = newCourses;
            setCourse(temp_array)
            // setCourse(...course, course[index] = newHashMap[val])
        } else if (key === "course") { //to create dropdown options for specialization name
            let courseType = inputFields[index].course_type;
            let newCourses = newHashMap[courseType] ? newHashMap[courseType].filter((courseItem) =>
                courseItem.course_name === val && courseItem.specialization_name
            ) : null;
            let temp_array = []
            temp_array = [...specialization];
            temp_array[index] = newCourses;
            setSpecialization(temp_array)
        }
    }

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_course_types').then((Result) => {
            let courseTypeArr = Result.data.filter(item => item.course_type !== "NA" && item.course_type !== "Ph.D");

            setCourseType(courseTypeArr);
        })
    }, []);

    React.useEffect(() => {
        if (values.qualification_req && values.qualification_req.length > 0) {
            let newVal = [...inputFields];
            values.qualification_req.forEach((input, index) => {
                if (input.course_type !== 'any') {
                    newVal[index] = { course_type: input.course_type, course: input.course, specialization: input.specialization }
                } else {
                    setIsChecked(true);
                    setDisableForm(true);
                }
            })
            setInputFields(newVal)
        }
    }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await axios(BASE_URL + '/users/get_aggregated_qualification_mapping/');

            // remove duplicates
            let newArray = result.data.filter((item, i, array) =>
                array.findIndex(t => t.qualification_id == item.qualification_id) == i
            )

            const groupByCat = (qualifications = []) => {
                let result = [];
                result = qualifications.reduce((r, a) => {
                    if (a.course_type !== 'Ph.D' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
                        r[a.course_type] = r[a.course_type] || [];
                        r[a.course_type].push(a);
                    }
                    return r;
                }, Object.create(null));
                return result;
            };

            newHashMap = groupByCat(newArray);

            //create dropdowns onload for the quals added earlier
            if (values.qualification_req && values.qualification_req.length > 0) {
                let temp_Courses = [...course];
                let temp_specs = [...specialization];

                values.qualification_req.forEach((input, index) => {
                    //if course type was selected earlier, create dropdown for course name 
                    if (input.course_type) {
                        let newCourses = newHashMap[input.course_type] ? newHashMap[input.course_type].filter((item, i, array) =>
                            array.findIndex(t => t.course_name == item.course_name) == i
                        ) : null

                        temp_Courses[index] = newCourses;
                    }
                    //if course name was selected earlier, create dropdown for specialization 
                    if (input.course_type) {
                        let courseType = input.course_type;
                        let newCourses = newHashMap[courseType] ? newHashMap[courseType].filter((courseItem) =>
                            courseItem.course_name === input.course && courseItem.specialization_name
                        ) : null;

                        temp_specs[index] = newCourses;
                    }
                })
                setCourse(temp_Courses);
                setSpecialization(temp_specs);
            }

            setIsLoading(false);
        };
        fetchData();
    }, []);

    return <Grid item xs={12} className={`${classes.my3} ${classes.position_relative}`} >
        <Typography gutterBottom variant='h5' className={`${classes.sticky_heading} ${classes.inputHeading}`}>Preferred Qualification<span style={{ color: '#f44336' }}>*</span></Typography>
        <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={disableFormDiv} />}
            label='All qualifications are welcome'
            // classes={{ label: classes.checkboxLabel }}
            className={classes.allQualCheckbox}
        />
        <div className={disableForm ? `${classes.flexboxCol} ${classes.disableDiv}` : classes.flexboxCol}>
            {inputFields.map((input, index) => (
                <div key={index} className={`${classes.my3} ${classes.flexbox} ${classes.skillsDiv}`} >
                    <Autocomplete
                        // id="combo-box2"
                        freeSolo
                        clearOnBlur
                        options={courseType}
                        // ref={courseTypeRef}
                        name="course_type"
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            return option.course_type
                        }
                        }
                        style={{ flex: 0.75, display: 'inline' }}

                        value={input.course_type || ""}

                        onChange={(event, newValue) => {
                            event.preventDefault();
                            setErrors({});
                            // if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                            //     //console.log("onchange 1 = " + newValue)
                            //     setCurrentCourseType(newValue);
                            //     setCourseTypeValue(newValue);
                            //     // selected_qualifications.role = newValue;
                            //     setInputBlur(false);
                            // } else 
                            if (newValue) { // when value is selected from dropdown
                                // setCurrentCourseType(newValue.course_name)
                                // setCourseTypeValue(newValue)
                                // selected_qualifications.role = newValue.course_name;
                                handleQualChange("course_type", index, newValue.course_type);
                            } else {
                                handleQualChange("course_type", index, null);
                            }
                        }}

                        // inputValue={inputCourseValue}

                        // onInputChange={onCourseInputChangeHandler}

                        renderInput={(params) => <TextField {...params} error={inputFields[index].course_type === null && errors.qualification_req ? true : false} helperText={inputFields[index].course_type === null && errors.qualification_req ? errors.qualification_req : ''} value="" label="Course Type*" placeholder="e.g ITI" classes={{ root: classes.skillsInput }} variant={props.isMobile ? 'outlined' : 'standard'}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        }

                        renderOption={(option) => {
                            if (option) {
                                return (
                                    <Grid container alignItems="flex-start" direction="column">
                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.course_type} </Typography>
                                    </Grid>
                                )
                            }
                        }}
                    />
                    <RemoveIcon className={`${!props.isMobile? classes.mx1 : classes.hideOnMobile}`} />
                    <Autocomplete
                        // id="combo-box"
                        name="course"
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        options={course[index] ? course[index] : []}
                        // ref={courseRef}

                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.course_name
                        }
                        }

                        style={{ flex: 0.75, display: 'inline', margin: props.isMobile ? '1em 0' : 0 }}

                        value={input.course || ""}

                        onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                handleQualChange("course", index, newValue);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleQualChange("course", index, newValue.inputValue);
                            } else if (newValue) { // when value is selected from dropdown
                                handleQualChange("course", index, newValue.course_name);
                            }
                            // else {
                            //     handleQualChange("course", index, null);
                            // }
                        }}

                        onInputChange={(event, newValue) => {
                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                handleQualChange("course", index, null);
                            }
                        }}

                        renderInput={(params) => <TextField {...params} value="" label="Course" placeholder="e.g Mechanic" classes={{ root: classes.skillsInput }} variant={props.isMobile ? 'outlined' : 'standard'}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        }

                        renderOption={(option) => {
                            if (option) {
                                return (
                                    <Grid container alignItems="flex-start" direction="column">
                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.course_name} </Typography>
                                    </Grid>
                                )
                            }
                        }}

                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    course_name: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                    />
                    <RemoveIcon className={`${!props.isMobile? classes.mx1 : classes.hideOnMobile}`} />
                    <Autocomplete
                        // id="combo-box3"
                        name="specialization"
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        options={specialization[index] ? specialization[index] : []}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.specialization_name;
                        }
                        }
                        style={{ flex: 0.75, display: 'inline' }}

                        value={input.specialization || ""}
                        // ref={specializationRef}
                        onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                handleQualChange("specialization", index, newValue);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleQualChange("specialization", index, newValue.inputValue);
                            } else if (newValue) { // when value is selected from dropdown
                                handleQualChange("specialization", index, newValue.specialization_name);
                            }
                            // else {
                            //     handleQualChange("specialization", index, null);
                            // }
                        }}

                        onInputChange={(event, newValue) => {
                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                handleQualChange("specialization", index, null);
                            }
                        }}

                        renderInput={(params) => <TextField {...params} value="" label="Specialization" placeholder="e.g Mechanical Engineering" classes={{ root: classes.skillsInput }} variant={props.isMobile ? 'outlined' : 'standard'}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        }

                        renderOption={(option) => {
                            if (option) {
                                return (
                                    <Grid container alignItems="flex-start" direction="column">
                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.specialization_name} </Typography>
                                    </Grid>
                                )
                            }
                        }}

                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    specialization_name: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                    />
                    {inputFields.length > 1 &&
                        <CancelIcon fontSize='small' className={`${classes.mx1} ${classes.icon}`} onClick={() => removeQualInput(index)} />
                    }
                </div>
            ))}
            {/* <span className={classes.helper_text}>To select press Enter ↲ | To navigate results use ↑ ↓ </span> */}
            <div>
                <Typography gutterBottom variant='body1' component='p' style={{display: 'inline-flex'}} classes={{ body1: classes.my2 }} color='primary' onClick={addQualInput}>
                    <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add another qualification</span>
                </Typography>
            </div>
        </div>
    </Grid>;
}

export default Qualifications;
