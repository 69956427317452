import { makeStyles } from '@material-ui/core';

const drawerWidth = 200;
export default makeStyles((theme) => ({
  headerButton: {
    textTransform: "none",
    width: 140,
    height: 25,
    marginBottom: 10,
    fontSize: '0.75rem'
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  headerButton_sec: {
    backgroundColor: "transparent",
    color: "#14967B",
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
  mapContainer: {
    // width: 350,
    width: 450,
    height: 520,
    display: 'flex',
    border: '1px solid #cdfffa',
    margin: '20px auto 0',
    borderRadius: 10,
    overflow: 'hidden',
    boxShadow: '1px 1px 5px rgb(0 0 0 / 10%)',
    cursor: 'grab',
    position: "relative",
    [theme.breakpoints.down('md')]: {
      width: 340,
      height: 490,
    }
  },
  legend: {
    textTransform: "none",
    width: 140,
    height: 10,
    marginBottom: 10,
    fontSize: '0.75rem',
  },
  legend_pri: {
    //backgroundColor: "#14967B",
    color: "#14967B",
    borderRadius: 5,
    fontSize: '0.975rem',
    marginBottom: 13,
    "& span": {
      fontWeight: 600
    }
  },
  legend_sec: {
    backgroundColor: "transparent",
    color: "grey",
  },
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0),
  },
  listItemActive: {
    color: 'teal',
    backgroundColor: '#e0f2f1'
  },
  legendsContainer: {
    height: 'auto',
    border: '1px solid #cdfffa',
    margin: 20,
    padding: "10px 20px",
    borderRadius: 10,
    boxShadow: '1px 1px 5px rgb(0 0 0 / 10%)',
    position: "relative",
  },
}));
