import { Highlight, connectRefinementList } from 'react-instantsearch-dom';
/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import throttle from 'lodash/throttle';
import Grid from '@material-ui/core/Grid';
import parse from 'autosuggest-highlight/parse';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GpsFixedRoundedIcon from '@material-ui/icons/GpsFixedRounded';
import {Typography } from '@material-ui/core';

var axios = require('axios');

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  gpsIcon: {
    paddingRight: 16,
    color: '#5BC2A8',
  },
  gpsText1: {
    fontSize: 20,
    color: '#5BC2A8',
    marginBottom: 5,
  },
  gpsText2: {
    fontSize: 14,
    color: '#6b6b6b',
  },
  comboBoxRoot: {
    marginTop: 14,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
    "&:after": {
      position: 'absolute',
      content: '""',
      width: 1,
      height: 24,
      backgroundColor: '#E0E0E0',
    }
  },
  inputRoot: {
    padding: '0 0 0 8px!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    "& .MuiIconButton-label": { display: "none" },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
  },

  input: {
    height: 43,
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },
  },
  popper: {
    marginTop: 16,
  },
  listbox: {
  },
  paper: {
    borderRadius: 10,
  }
}));

const autocompleteService = { current: null };

var option_to_add_single =
{
  description: "Current Location",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Detect Current Location",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Current Location"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

var option_to_add =
{
  description: "Current Location",
  matched_substrings: [
    {
      length: 0,
      offset: 0,

    }],
  structured_formatting:
  {
    main_text: "Current Location",
    main_text_matched_substrings:
      [{
        length: 0,
        offset: 0
      }
      ],
    terms:
      [
        {
          offset: 0,
          value: "Current Location"
        }
      ],
    types:
      [
        "locality", "political", "geocode"
      ]
  }
  ,
}

function change_display(address) {
  var option_to_display =
  {
    description: address,
    matched_substrings: [
      {
        length: 2,
        offset: 0,

      }],
    structured_formatting:
    {
      main_text: address,
      main_text_matched_substrings:
        [{
          length: 2,
          offset: 0
        }
        ],
      terms:
        [
          {
            offset: 0,
            value: address
          }
        ],
      types:
        [
          "locality", "political", "geocode"
        ]
    }
  }

  return option_to_display;

}

function CustomRefinementListLocation  ({ items, refine }) {

const classes = useStyles();
  const [Roles,setRoles] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

React.useEffect(() => {
    let active = true;

    axios.get('http://dev.meetworks.in:9000/users/get_job_role_mapping/').then(( Result)=>
    {
       
      setRoles(Result.data)
      
    })

if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([option_to_add_single])
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {

          results.unshift(option_to_add);
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
}, [value, inputValue, fetch])

  return (
     <Autocomplete
              id="google-map"
              style={{ flex: 1 }}
              classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper }}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                var display = newValue;
                console.log("On change......")

            if(newValue && newValue.terms)
                 {

                 var location = newValue.terms[0].value;



                if(location != "Current Location")  
                  {
                      refine(location)  ;
                      //setLocation(location);
                      axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='+location+'&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0').then((result) =>
                            { 
                              if(result && result[0]) 
                               {
                              // setLatitude(result.data.results[0].geometry.location.lat) ;
                              // setLongitude(result.data.results[0].geometry.location.lng) 
                                }
                            })
                  }

                }

                if (newValue == option_to_add || newValue == option_to_add_single) {
                  var lat, lng;

                   setValue("") ;

                  navigator.geolocation.getCurrentPosition(function (position) {
                    console.log("Latitude is :", position.coords.latitude);
                    console.log("Longitude is :", position.coords.longitude);
                    lat = position.coords.latitude;
                    lng = position.coords.longitude;

                   // setLatitude(lat);
                   // setLongitude(lng);

                    axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + "," + lng + '&key=AIzaSyCjskHW4g3dSHkiL-mdD3bf8iPhkJP3KX0')
                      .then((response) => {
                        var address = response.data.results;
                        var address_1 = address[1].formatted_address;
                        //setLocation(address_1);
                        display = change_display(address_1);

                        refine(address_1)

                      }).then(() => {
                        setValue(display);
                      })
                  });
                }
              }
              }

              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                
              }}

              renderInput={(params) => (
                <TextField {...params} label="Location" variant="outlined" placeholder="Search your office location" fullWidth InputLabelProps={{
                  shrink: true,
                }} />
              )}
              renderOption={(option) => {
                if (option.structured_formatting) {

                  const matches = option.structured_formatting.main_text_matched_substrings;

                  if(matches)
                {
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                  );

                  return (
                    <Grid container alignItems="center">
                      {option.description === "Current Location" ? <Grid item>
                        <GpsFixedRoundedIcon className={classes.gpsIcon} />
                      </Grid>
                        : <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                      }
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: option.structured_formatting.main_text === "Detect Current Location" ? '#5BC2A8' : 'black' }}>
                            {part.text}
                          </span>
                        ))}
                        {option.description === "Current Location" ? <Typography variant="body2" className={classes.gpsText2}>
                        Using GPS
                        </Typography>
                          : <Typography variant="body2" color="textSecondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        }
                      </Grid>
                    </Grid>
                  );
                }
                }
              }
              }
            />
  );
}


const CustomList = connectRefinementList(CustomRefinementListLocation);

export default CustomList;