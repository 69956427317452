import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import LogoLoader from '../../assets/logo-loader.gif';
import LogoBg from '../../assets/Iconwallpaper2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    background: ' rgb(252,252,252,1)',
    //background: 'radial-gradient(circle, rgba(252,252,252,0) 0%, rgba(252,252,252,1) 67%, rgba(252,252,252,1) 100%)',
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 9999,
    top: 0,
    width: '100%',
    height: '100vh',
    overflowY: 'hidden',
    opacity: 1,
    animation: 'pageLoader 2s ease-in-out 1 forwards',
    //transition: 'opacity 0.25s ease-in-out',
  },
  loaderBox: {
    background: `url(${LogoBg})`,
    width: '100%',
    height: '100%',
    '& h1': {
      maxWidth: '80% !important'
    }
    // position: 'absolute',
    // top: 0,
    // zIndex: 2,
    // display: 'flex',
    // opacity: 1,
    // '&.animate': {
    //   //animation: 'bgColorFade 0.5s ease-in-out infinite alternate',
    //   display: 'flex',
    //   opacity: 1,
    // }
  }
}));

const Loader_Shuffle = (props) => {
  const classes = useStyles();
  const loaderShuffleRef = React.useRef(null);
  
  // React.useEffect(() => {
  //   props.setLoaderRef(loaderShuffleRef);
  // }, [loaderShuffleRef])
  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.loaderBox} container direction="column" justify="center" alignItems="center" 
      //ref={loaderShuffleRef}
      >
        {/* <CircularProgress size={50} style={{ color: '#5bc2a8' }} /> */}
        <img alt="meet animated logo loader" style={{ width: 60, height: 68}} src={LogoLoader} />
        <Typography className="loaderText" variant="h6" component='h1' style={{ marginTop: 15, color: "#333", maxWidth: '50%', textAlign: 'center', lineHeight: '2em' }}>{props.loaderMessage}</Typography>
      </Grid>
    </div>
  )
}

export default Loader_Shuffle;