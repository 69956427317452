// var AnalyticsHandler= {

// //This function takes a message as a a parameter and uses it to send tracking info to segment debugger;
// track : function(message) {

// window.analytics.track(message);

// }
// ,

// // this checks to see if the user is logged in and if so, send identity info back to segment as well.

// identify: function() {

//   var logged_in = localStorage.getItem('is_logged_in');

//     if(logged_in)
//     {
//         window.analytics.identify( {
//         name: localStorage.getItem('employer_name'),
//         email: localStorage.getItem('company_email_id'),
//         });
//     }
// }
// }

var AnalyticsHandler = {

  //This function takes a message as a a parameter and uses it to send tracking info to segment debugger;
  track: function (message) {
    if (window.location.hostname.trim() === "employer.meetworks.in" || window.location.hostname.trim() === "mywebsite.meetworks.in") {
      window.mixpanel.track(message);
    }

  }
  ,

  // this checks to see if the user is logged in and if so, send identity info back to segment as well.

  identify: function () {
    if (window.location.hostname.trim() === "employer.meetworks.in" || window.location.hostname.trim() === "mywebsite.meetworks.in") {
      var logged_in = localStorage.getItem('is_logged_in');

      if (logged_in) {
        window.mixpanel.identify(localStorage.getItem('company_email_id'));
      }
    }
  },

  reset: function () {
    if (window.location.hostname.trim() === "employer.meetworks.in" || window.location.hostname.trim() === "mywebsite.meetworks.in") {
      window.mixpanel.reset();
    }
  },

  setOnce: function (name, email) {
    if (window.location.hostname.trim() === "employer.meetworks.in" || window.location.hostname.trim() === "mywebsite.meetworks.in") {
      window.mixpanel.people.set_once({
        $name: name,
        $email: email
      })
    }

  }
}

export default AnalyticsHandler;