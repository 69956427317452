import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ApiHandler from '../ApiHandler';
import { ThemeProvider } from '@material-ui/styles';
import MyTheme from '../style/JobAppTheme';
import FormControl from '@material-ui/core/FormControl';
import global_isSignedIn from '../views/discovery/global_isSignedIn';
import { CompassCalibrationOutlined } from '@material-ui/icons';
import AnalyticsHandler from '../AnalyticsHandler';
var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

const useStyles = makeStyles(theme => ({
  flexContainer: {
    height: 60,
    flexGrow: 1,
  },
  drawerPaper: {
    borderRadius: '20px 20px 0px 0px',
  },
  drawer: {
    width: 'auto',
    height: 'auto',
    minHeight: 460,
    background: '#FFFFFF',
    boxShadow: ' 0px -4px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: '20px 20px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerCloseBtn: {
    alignSelf: 'flex-end',
    margin: 8,
  },
  drawerBackBtn: {
    position: 'absolute',
    alignSelf: 'flex-start',
    margin: 8,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 300,
    marginTop: 20,
  },
  formSignUp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 700,
    marginTop: 20,
  },
  TfRoot: {
    width: '100%',
    marginBottom: 20,
    '& input': {
      height: 48,
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      }
    },
    '& fieldset': {
      border: '1px solid #808080',
    },
    '@media (max-width: 600px)': {
      height: 30,
      '& input': {
        height: 30,
        fontSize: 14
      }
    }
  },
  drawerActionButton: {
    width: '100%',
    height: 36,
    marginBottom: 15,
    backgroundColor: '#14967B',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#14967B',
    },
  },
  forgotBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 10,
  },
  linkButton: {
    textTransform: 'unset',
    color: '#14967B',
  },
  inputRootAc: {
    padding: '0 9px!important',
  },
  checkboxLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  fcRoot: {
    marginRight: 3,
  },
  mobileInputs: {
    '@media (max-width: 600px)': {
      width: '100% !important'
    }
  }
}));

const isMobile = window.innerWidth <= 600 ? true : false;

const filter = createFilterOptions();

export default function SignInUpDrawer(props) {
  const classes = useStyles();
  const [toSignIn, setToSignIn] = React.useState(true);
  const [onlySignIn, setOnlySignIn] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [emailInput, setEmailInputChange] = useState('');
  const [emptyError, setEmptyError] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const [errorMessageSignUp, setErrorMessageSignUp] = useState("");

  const [employer_name, setEmployerName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [company_name, setCompanyName] = useState(null);
  const [company_email, setCompanyEmail] = useState("");
  const [company_mobile, setCompanyMobile] = useState("");
  const [company_password, setCompanyPassword] = useState("");
  const [employer_document_url, setEmployerDocumentUrl] = useState("");
  const [employer_document_name, setEmployerDocumentName] = useState("");
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [isAlertOpenForSignUp, setIsAlertOpenForSignUp] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState("");
  const [checked_tc, setCheckedTc] = React.useState(true);
  const [checked_other, setCheckedOther] = React.useState(true);
  const [clicked_forgot_password, setClickedForgotPassword] = React.useState(false);
  const [forget_password_email, setForgetPasswordEmail] = React.useState(null);
  const [errorMessageForgetPassword, setErrorMessageForgetpassword] = useState("");
  const [isAlertOpenForgetPasswordError, setIsAlertOpenForgetPasswordError] = React.useState(false);
  const [isAlertOpenForgetPasswordSuccess, setIsAlertOpenForgetPasswordSuccess] = React.useState(false);
  const [emptyErrorForgetPassword, setEmptyErrorForgetPassword] = React.useState(true);
  const [isAlertOpenForSignUpSuccess, setIsAlertOpenForSignUpSuccess] = React.useState(false);
  const [validEmailSignIn, setValidEmailSignIn] = React.useState(true);
  const [company_website, setCompanyWebsite] = React.useState("");
  const [company_name_key, setCompanyNameKey] = React.useState(false);

  var formData = new FormData();

  const handleCheckedTc = (event) => {
    setCheckedTc(event.target.checked);
  }

  const handleCheckedOther = (event) => {
    setCheckedOther(event.target.checked);
  }

  const forgot_password = (event) => {
    AnalyticsHandler.track('forgotPassword_btnClick');
    setOnlySignIn(false);
    setClickedForgotPassword(true);
    setIsAlertOpenForgetPasswordSuccess(false);
    setIsAlertOpenForgetPasswordError(false);
    setIsAlertOpen(false);
  }


  const handleSubmit = () => {

    AnalyticsHandler.track('Complete_Registration_btnClick');

    setIsAlertOpenForSignUp(false);
    setIsAlertOpenForSignUpSuccess(false);

    let payload = {
      employer_name: employer_name,
      company_name: company_name,
      company_email: company_email,
      company_mobile: company_mobile,
      company_website: company_website,
      company_password: company_password,
      employer_document_url: employer_document_url,
      employer_document_name: employer_document_name
    }

    if (company_website == "" || employer_name == "" || company_name == null || company_name == "" || company_email == "" || company_mobile == "" || company_password == "") {
      setErrorMessageSignUp("Please enter the necessary information in all the fields of the registration form...");
      setIsAlertOpenForSignUp(true);
    }

    // if (employer_name == "" || company_name == null || company_email == "" || company_mobile == "" || company_password == "") {
    //   setErrorMessageSignUp("Please enter the necessary information in all the fields of the registration form...");
    //   setIsAlertOpenForSignUp(true);
    // }

    else if (!validateEmail(company_email)) {
      setErrorMessageSignUp("Please provide a valid email address");
      setIsAlertOpenForSignUp(true);

    }
    else if (company_mobile.length != 10) {
      setErrorMessageSignUp("Please enter a ten digit mobile number (without 0 or the country code +91)");
      setIsAlertOpenForSignUp(true);
    }

    else if (company_password.length < 6 || company_password.length > 15) {
      setErrorMessageSignUp("Please enter a password between 6 and 15 characters");
      setIsAlertOpenForSignUp(true);
    }

    else if (checked_tc == false) {
      setErrorMessageSignUp("Please read and agree to the Terms and Conditions to complete registration form.");
      setIsAlertOpenForSignUp(true);
    }

    else {
      ApiHandler.employerRegister(payload, response => {
        //console.log('response', response);
        if (response.status == 200) {
          setIsAlertOpenForSignUpSuccess(true);
          handleSignInClick_autoSignIn();
        }
        else {

          if (response.response) {
            let statusCode = response.response.status;
            let message = response.response.data.message;
            setIsAlertOpenForSignUp(true);
            if (statusCode === 500) {
              setErrorMessageSignUp('Oops! Something went wrong at our end. Please refresh the page and try again. ');
            } else if (statusCode === 404) {
              setErrorMessageSignUp('Oops! Something went wrong. Please try again.');
            } else if (statusCode === 400) {
              //setErrorMessageSignUp(response.response.data.message);
              if (message === "email_duplicate") {
                setErrorMessageSignUp(
                  'Seems you are already registered on Meet with this email ID. Please login or email us at employer@meetworks.in for support.');
              } else if (message === "mobile_duplicate") {
                setErrorMessageSignUp(
                  'Seems you are already registered on Meet with this mobile number. Please login or email us at employer@meetworks.in for support.');
              }
            } else {
              setErrorMessageSignUp('Oops! Something went wrong. Please try again.');
            }
          } else if (response.message === 'Network Error') {
            setErrorMessageSignUp('Looks like your internet is down. Try a different network or wait till your connection is restored.');
          } else {
            setErrorMessageSignUp('Oops! Something went wrong. Please try again.');
          }
        }
      })
    }
  }

  const onFileChange = (event) => {

    if (!(event.target.files[0].type === "application/pdf" || event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png")) {
      alert("Please upload correct filetype - pdf or image");
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    setEmployerDocumentName(event.target.files[0].name)

    setSelectedFile(event.target.files[0]);

  }

  const handleEmailInputChange = (event) => {
    setIsAlertOpen(false);
    setEmailInputChange(event.target.value)
  }

  const handleForgetpasswordEmailChange = (event) => {

    setIsAlertOpenForgetPasswordError(false);
    setIsAlertOpenForgetPasswordSuccess(false);

    setForgetPasswordEmail(event.target.value);

    if (event.target.value == "")
      setEmptyErrorForgetPassword(true);
    else
      setEmptyErrorForgetPassword(false);
  }


  const handleForgetPassword = async (event) => {

    AnalyticsHandler.track('resetPassword_btnClick');

    if (validateEmail(forget_password_email || emailInput)) {
      // var formData = {
      //   company_email: forget_password_email
      // }

      // axios.post(BASE_URL + "/employer/forgetpassword_ops_verify", formData)
      //   .then(res => {
      //     setErrorMessageForgetpassword(res.data);
      //     setIsAlertOpenForgetPasswordSuccess(true);
      //   })
      //   .catch(err => {
      //     setErrorMessageForgetpassword(err.message);
      //     setIsAlertOpenForgetPasswordError(true);
      //   }
      //   )
      try {
        setEmptyErrorForgetPassword(false);
        const { data: responseData } = await axios.get(`${BASE_URL}/employer/resetpassword_sendemail/${forget_password_email || emailInput}`);
        setErrorMessageForgetpassword(`A link to reset the password for your account has been sent to ${forget_password_email || emailInput}`);
        setIsAlertOpenForgetPasswordSuccess(true);
      } catch (error) {
        setErrorMessageForgetpassword(error && error.message && error.message);
        setIsAlertOpenForgetPasswordError(true);
      }
    }

    else {
      setIsAlertOpenForgetPasswordError(true);
    }
  }

  useEffect(() => {

    if (employer_document_name != "") {
      formData.append('employer_document', selectedFile)

      axios.post(BASE_URL + "/employer/upload_document_signUp", formData)

        .then(res => {
          setEmployerDocumentUrl(res.data.document_url);
        });

    }
    formData = new FormData();
    setEmployerDocumentName("");

  }, [selectedFile]);

  // useEffect(() => {
  //   console.log("clicked forgot password::", clicked_forgot_password);
  // }, [clicked_forgot_password]);

  //Drawer starts
  const [drawerState, setDrawerState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (open == false) {
      setClickedForgotPassword(false);
      setForgetPasswordEmail("");
      setEmailInputChange("");
      setEmployerName("");
      setCompanyName(null);
      setCompanyEmail("");
      setCompanyMobile("");
      setCompanyPassword("");
      setCompanyWebsite("")
      setEmployerDocumentUrl("");
      setEmployerDocumentName("");
      setSelectedFile("");
      setCheckedTc(true);
      setCheckedOther(true);
      setToSignIn(true);
      setIsAlertOpenForSignUp(false);
      setIsAlertOpen(false);
      setIsAlertOpenForgetPasswordError(false);
      setIsAlertOpenForgetPasswordSuccess(false);
      setIsAlertOpenForSignUpSuccess(false);

      values.password = "";
    }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };

  //Drawer ends

  // password starts
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handlePasswordChange = (prop) => (event) => {
    setIsAlertOpen(false);
    setValues({ ...values, [prop]: event.target.value });

  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // password ends

  const validateEmail = (email) => {
    if (email == "")
      return true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSignInClick_autoSignIn = () => {
    var email1 = company_email;
    var password1 = company_password;

    ApiHandler.signIn(email1, password1, function (res) {
      if (res.status === 200) {
        AnalyticsHandler.track("signIn_Success");
        localStorage.setItem('company_id', res.data.company_id);
        localStorage.setItem('company_email_id', res.data.company_email);
        localStorage.setItem('company_name', res.data.company_name);
        localStorage.setItem('employer_name', res.data.employer_name);

        localStorage.setItem('is_logged_in', true);
        props.setIsSignedIn(true);
        global_isSignedIn.isSignedIn = true;
        AnalyticsHandler.identify();
        AnalyticsHandler.setOnce(res.data.employer_name, res.data.company_email);

        if (props.setForRefresh) {
          props.setForRefresh(prev => !prev);
        }
        if (props.setForRefreshOnly) {
          props.setForRefreshOnly(prev => !prev);
        }
        props.doRefresh(prev => !prev);
      }
      else {
        setErrorMessage(true);
        setIsAlertOpen(true);
      }
    })
  }

  const handleSignInClick = () => {
    AnalyticsHandler.track("signIn_Submit_btnClick");

    var email1 = emailInput;
    var password1 = values.password
    if (email1 === '') {
      setEmptyError(true);
      setIsAlertOpen(true);
    }

    else if (password1 === '') {
      setEmptyError(true);
      setIsAlertOpen(true);
    }

    else if (!validateEmail(email1)) {
      setValidEmailSignIn(false);
      setIsAlertOpen(true);
    }

    else {
      ApiHandler.signIn(email1, password1, function (res) {
        if (res.status === 200) {
          AnalyticsHandler.track("signIn_Success");
          localStorage.setItem('company_id', res.data.company_id);
          localStorage.setItem('company_email_id', res.data.company_email);
          localStorage.setItem('company_name', res.data.company_name);
          localStorage.setItem('employer_name', res.data.employer_name);

          localStorage.setItem('is_logged_in', true);
          props.setIsSignedIn(true);
          global_isSignedIn.isSignedIn = true;
          AnalyticsHandler.identify();
          AnalyticsHandler.setOnce(res.data.employer_name, res.data.company_email);
          //auto logout
          // let timer = 17280000; // 48 hours in milliseconds
          // let expiryDate = new Date(); ///////////////////////////// to be done-- date from the cookie
          // localStorage.setItem('sessionExpiry', expiryDate);
          // alert(expiryDate)
          // setTimeout(() => {
          //   localStorage.removeItem('is_logged_in');
          //   localStorage.removeItem('sessionExpiry');
          //   props.setIsSignedIn(false);
          //   global_isSignedIn.isSignedIn = false;
          // }, timer);

          if (props.setForRefresh) {
            props.setForRefresh(prev => !prev);
          }
          if (props.setForRefreshOnly) {
            props.setForRefreshOnly(prev => !prev);
          }
          props.setCanDrawerOpen(false);
          props.doRefresh(prev => !prev);
        }
        else {
          setErrorMessage(true);
          setIsAlertOpen(true);
        }
      })
    }
  }

  const signIn = (anchor) => {
    return (clicked_forgot_password == false ?
      <div className={classes.drawer} id="sign_in_section" role="presentation">
        <IconButton
          id="drawerclose_btn_id"
          className={classes.drawerCloseBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}> <CloseIcon style={{ color: '#999', cursor: 'pointer' }}></CloseIcon></IconButton>
        <Typography variant="h5" color="primary" style={{ marginBottom: 20, fontWeight: 500, fontSize: window.innerWidth <= 600 ? '1em' : '1.5em' }}>
          Recruit young talent for your amazing company.
        </Typography>

        <Collapse in={isAlertOpen}>
          <Alert
            severity="error"
            action={
              <IconButton
                id="alertclose_btn_id"
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsAlertOpen(false);
                  setTimeout(() => {
                    console.log("timeout");
                    setErrorMessage(false);
                    setEmptyError(false);
                    setValidEmailSignIn(true);
                  }, 200);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {emptyError ?
              <div>
                <Typography variant="body1">
                  Please enter the necessary information in all the fields..
                </Typography>
              </div> :
              (errorMessage ?
                <div>
                  <Typography variant="body1">
                    Oops! We couldn't sign you in.
                  </Typography>
                  <Typography variant="body2">
                    If you are an existing user, check your email ID or click Forgot Password.
                  </Typography>
                  <Typography variant="body2">
                    If you are a new user, click Register.
                  </Typography>
                </div> :
                !validEmailSignIn ?
                  <div>
                    Please enter a valid email address.
                  </div> : <div></div>)}
          </Alert>
        </Collapse>

        <form noValidate autoComplete="off" className={classes.form}>
          <TextField
            required
            id="emailInput"
            type="email"
            value={emailInput}
            onChange={handleEmailInputChange}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleSignInClick();
              }
            }
            }
            variant="outlined"
            placeholder="Your Email ID"
            classes={{ root: classes.TfRoot }}
            autoFocus
          />

          <TextField
            required
            id="passwordInput"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handlePasswordChange('password')}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleSignInClick();
              }
            }

            }
            variant="outlined"
            placeholder="Password"
            classes={{ root: classes.TfRoot }}
            style={{ marginBottom: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.forgotBtnContainer}>
            <Button
              id="forgot_password_btn_id"
              className={classes.linkButton} size="small" onClick={() => { forgot_password() }} style={{ paddingRight: 0 }}>
              Forgot Password
            </Button>
          </div>
          <Button
            id="sign_in_btn_id"
            variant="contained"
            onClick={handleSignInClick}
            className={classes.drawerActionButton}>
            Sign In
          </Button>
        </form>
        <Button id="register_btn_id" className={classes.linkButton} style={{ marginBottom: 20 }} onClick={() => { AnalyticsHandler.track('signUp_View_btnClick'); setToSignIn(false); setIsAlertOpen(false); values.password = "" }}>
          <span style={{ color: '#797979', marginRight: 5 }}>New to Meet? </span> <span style={{ fontWeight: 500 }}>Register</span>
        </Button>
      </div> :

      <div className={classes.drawer} role="presentation">
        <IconButton id="backtosignin_btn_id" className={classes.drawerBackBtn}
          onClick={() => { setClickedForgotPassword(false); setForgetPasswordEmail(""); setOnlySignIn(true); }}
        > <ArrowBackIcon style={{ color: '#55c6a9', cursor: 'pointer' }} /></IconButton>
        <IconButton
          id="fp_drawerclose_btn_id"
          className={classes.drawerCloseBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}> <CloseIcon style={{ color: '#999', cursor: 'pointer' }}></CloseIcon></IconButton>

        <Typography variant="h5" color="primary" style={{ marginBottom: 20, fontWeight: 500 }}>
          Forgot your password?
        </Typography>
        <span style={{ padding: isMobile ? '0 1em' : 'initial', textAlign: 'center' }}>
          No worries, it happens. Lets get you a new password.
        </span>
        <br /><br />

        <Collapse in={isAlertOpenForgetPasswordError}>
          <Alert
            severity="error"
            action={
              <IconButton
                id="fp_alertclose_btn_id"
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setIsAlertOpenForgetPasswordError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {emptyErrorForgetPassword ?
              <div>
                <Typography variant="body1">
                  Please enter your Email ID so that we can help you reset your password.
                </Typography>
              </div> : (
                !validateEmail(forget_password_email || emailInput) ?
                  <div>
                    Please enter a valid email address.
                  </div> :
                  (errorMessageForgetPassword != "" ?
                    <div>
                      <Typography variant="body1">
                        Sorry! It seems your email id {forget_password_email || emailInput} is not registered with Meet. <br />
                        Please check your email ID and go back and Register. <br /> <br />
                        For any support please email us on <b><u>employer@meetworks.in</u></b>
                      </Typography>
                    </div> :
                    <div>
                    </div>))}
          </Alert>
        </Collapse>

        <Collapse in={isAlertOpenForgetPasswordSuccess}>
          <Alert
            severity="success"
          >
            {emptyErrorForgetPassword ?
              <div>
                <Typography variant="body1">
                  Please fill in all fields
                </Typography>
              </div> :
              (errorMessageForgetPassword != "" ?
                <div>
                  <Typography variant="body1">
                    {errorMessageForgetPassword || <><b> Your request for resetting the password has been sent to our team.</b><br /> We will contact you within 24 hours :)</>}
                  </Typography>
                </div> :
                <div>
                </div>)}
          </Alert>
        </Collapse>

        <form noValidate onSubmit={event => event.preventDefault()} autoComplete="off" className={classes.form}>
          {!isAlertOpenForgetPasswordSuccess ?
            <div>
              <TextField
                required
                id="email_Input_forget_password"
                type="email"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleForgetPassword();
                  }
                }
                }
                value={forget_password_email || emailInput}
                onChange={handleForgetpasswordEmailChange}
                variant="outlined"
                placeholder="Your Email ID"
                classes={{ root: classes.TfRoot }}
                autoFocus
              />
              <Button
                id="reset_btn_id"
                variant="contained"
                onClick={handleForgetPassword}
                className={classes.drawerActionButton}>
                Reset Password
              </Button>
            </div> :
            null}
        </form>
      </div>
    )
  }

  const signUp = (anchor) => (
    <div className={classes.drawer} role="presentation">
      <IconButton
        className={classes.drawerBackBtn}
        onClick={() => {
          setToSignIn(true)
          setClickedForgotPassword(false);
          setForgetPasswordEmail("");
          setEmailInputChange("");
          setEmployerName("");
          setCompanyName(null);
          setCompanyEmail("");
          setCompanyMobile("");
          setCompanyPassword("");
          setEmployerDocumentUrl("");
          setEmployerDocumentName("");
          setSelectedFile("");
          setCheckedTc(true);
          setCheckedOther(true);
          setIsAlertOpenForSignUp(false);
          setIsAlertOpenForSignUpSuccess(false);
        }}
      > <ArrowBackIcon style={{ color: '#55c6a9', cursor: 'pointer' }} /></IconButton>
      <IconButton
        className={classes.drawerCloseBtn}
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}> <CloseIcon style={{ color: '#999', cursor: 'pointer' }}></CloseIcon></IconButton>

      <Typography variant="h5" color="primary" style={{ marginBottom: 20, fontWeight: 500, fontSize: isMobile ? '1em' : '1.5em', padding: isMobile ? '0 1em' : 0 }}>
        We would love to know more about you and your company :)
      </Typography>

      <Collapse in={isAlertOpenForSignUp} style={{ maxWidth: '700px', margin: '0 auto', minHeight: isAlertOpenForSignUp ? '85px' : 0 }}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsAlertOpenForSignUp(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography variant="body1">
            {errorMessageSignUp}
          </Typography>
        </Alert>
      </Collapse>

      <Collapse in={isAlertOpenForSignUpSuccess}>
        <Alert
          severity="success"
        >
          <Typography variant="body1">
            <b> You have registered successfully! </b> <br />
            Please give us 24-48 hours to verify your company <br /> <br />
            Meanwhile, you can invite jobseekers, create your company <br />
            profile, and post jobs. Your invites and jobs will go live after <br />
            your company is verified.
          </Typography>
        </Alert>
      </Collapse>

      {!isAlertOpenForSignUpSuccess ?
        <form noValidate autoComplete="off" className={classes.formSignUp}>
          <Grid item xs container direction={isMobile ? 'column' : 'row'}
            justifyCenter="center"
            alignItems="center">
            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }}>
              <TextField
                required
                variant="outlined"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }
                placeholder="Your Name*"
                value={employer_name}
                onChange={e => { setEmployerName(e.target.value) }
                }
                classes={{ root: classes.TfRoot }}
                autoFocus
              />
            </Grid>
            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }}>
              <TextField
                required
                variant="outlined"
                type="number"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }
                onChange={e => { setCompanyMobile(e.target.value) }
                }
                value={company_mobile}
                placeholder="Mobile Number*"
                classes={{ root: classes.TfRoot }}
              />
            </Grid>
          </Grid>
          <Grid item xs container direction={isMobile ? 'column' : 'row'}
            justifyCenter="center"
            alignItems="center">
            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }}>
              <Autocomplete
                freeSolo
                autoSelect
                value={company_name}
                key={company_name_key}
                defaultValue={""}
                options={companies}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }

                onInputChange={(event, value) => {
                  if (value === "") {
                    setCompanyName("");
                    setCompanyNameKey(prev => !prev);
                  }
                }}

                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    console.log(option.inputValue);
                    return option.inputValue;
                  }
                  // Regular option
                  return option.company_name;
                }}
                renderOption={(option) => option.company_name}
                // onInputChange = {(event,newValue) => {
                //   setCompanyName(newValue)}
                // }
                onChange={(event, newValue) => {
                  if (newValue) {
                    if (typeof newValue === 'string') {
                      setCompanyName(newValue);
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setCompanyName(newValue.inputValue);
                      console.log("setting company name");
                    } else {
                      setCompanyName(newValue.company_name);
                    }
                  }
                }
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      company_name: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                classes={{ inputRoot: classes.inputRootAc, }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" placeholder="Your Company Name*" classes={{ root: classes.TfRoot }} />
                )}
              />
            </Grid>


            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }}>
              <TextField
                required
                variant="outlined"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }
                placeholder="Your Company Website*"
                value={company_website}
                onChange={e => { setCompanyWebsite(e.target.value) }
                }
                classes={{ root: classes.TfRoot }}
              />
            </Grid>
          </Grid>

          <Grid item xs container direction={isMobile ? 'column' : 'row'}
            justifyCenter="center"
            alignItems="center">
            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }} >
              <TextField
                required
                id="emailInput"
                type="email"
                variant="outlined"
                value={company_email}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }
                onChange={e => { setCompanyEmail(e.target.value) }
                }
                placeholder="Your Email ID*"
                classes={{ root: classes.TfRoot }}
              />
            </Grid>
            <Grid item xs={6} className={classes.mobileInputs} style={{ padding: '0 10px' }} >
              <TextField
                required
                id="passwordInput"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password || company_password}
                onChange={
                  //handlePasswordChange(company_password)
                  e => { setCompanyPassword(e.target.value) }}
                variant="outlined"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }
                }
                placeholder="Set Password*"
                classes={{ root: classes.TfRoot }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item container direction="row"
            justifyCenter="center"
            style={{ width: isMobile ? '50%' : '100%' }}
            alignItems="center">
            <Grid item xs={12} style={{ padding: '0 10px' }}>
              {/* <TextField
              required
              variant="outlined"
              placeholder="Upload a supporting document to verify your company.*"
              helperText="E.g. company incorporation certificate, trust deed, society registration, PAN, etc."
              classes={{ root: classes.TfRoot }}
            /> */}
              <FormControl fullWidth={true} margin="normal">
                <div style={{ color: "grey" }} >
                  Upload a supporting document to verify your company of maximum size 5 MB.
                  <label>  </label>
                  <input type="file" id="myfile" name="myfile" accept=".pdf,image/*" onChange={onFileChange} />
                </div>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs container direction="row"
            justifyCenter="center"
            style={{ width: isMobile ? '50%' : '100%' }}
            alignItems="center">
            <Grid item xs={12} className={classes.mobileInputs} style={{ padding: '0 10px', marginBottom: '-10px' }}>
              <FormControlLabel
                classes={{ label: classes.checkboxLabel, root: classes.fcRoot }}
                control={<Checkbox
                  name="tnc"
                  defaultChecked
                  size="small"
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      handleSubmit();
                    }
                  }
                  }
                  icon={icon}
                  onChange={handleCheckedTc}
                  checkedIcon={checkedIcon} />}
                label="I agree to the" /><a href="https://docs.google.com/document/d/17SwT2MLiYZ3ti58VlY6L0IZSJGPw9Sdy/edit "><span style={{ fontSize: 12 }}>Terms and Conditions</span></a>
            </Grid>
            <Grid item xs={12} className={classes.mobileInputs} style={{ padding: '0 10px', marginBottom: 20 }}>
              <FormControlLabel
                classes={{ label: classes.checkboxLabel, root: classes.fcRoot }}
                control={<Checkbox
                  name="notifs"
                  defaultChecked
                  onChange={handleCheckedOther}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      handleSubmit();
                    }
                  }
                  }
                  size="small"
                  icon={icon}
                  checkedIcon={checkedIcon} />}
                label="I want to receive WhatsApp notifications about updates related to my jobs and account." />
            </Grid>
          </Grid>

          <Button
            id="complete_registration_btn_id"
            variant="contained"
            onClick={
              //toggleLogin
              handleSubmit
            }
            className={classes.drawerActionButton}
            style={{ width: 300, marginBottom: 30 }}>
            Complete Registration
          </Button>
        </form> :
        null}
    </div>
  );

  const icon = <CheckBoxOutlineBlankIcon style={{ color: "#E2E2E2" }} fontSize="small" />;
  const checkedIcon = <CheckBoxIcon style={{ color: "#5BC2A8" }} fontSize="small" />;
  // open drawer on trigger from parent component
  useEffect(() => {
    if (props.canDrawerOpen === true) {
      setDrawerState({ ...drawerState, ['bottom']: true });
    }
    else {
      setDrawerState({ ...drawerState, ['bottom']: false });
    }
  }, [props.refresh]);

  //  // company data starts
  useEffect(() => {
    ApiHandler.getCompanyNames((Result) => {
      setCompanies(Result.data)
    })
  }, [])
  //  // company data ends

  return (
    <div>
      <ThemeProvider theme={MyTheme}>
        <Drawer anchor='bottom'
          open={drawerState['bottom']}
          onClose={toggleDrawer('bottom', false)}
          style={{ background: 'none' }} classes={{ paper: classes.drawerPaper }}>
          {toSignIn ? signIn('bottom') : signUp('bottom')}
        </Drawer>
      </ThemeProvider>
    </div>
  );
}