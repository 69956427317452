import React from 'react'
import { connectHits } from 'react-instantsearch-dom';
import ListView from './ListView'
import MobileListView from './MobileListView'
import ApiHandler from '../../ApiHandler';
// import No_Results from '../discovery/NoResultsList'
import SnackBarGeneric from '../talentpool/SnackBarGeneric';
import { Button } from '@material-ui/core';
import AnalyticsHandler from '../../AnalyticsHandler';

const isMobile = window.innerWidth <= 600 ? true : false
function Hits(props) {
    const { hits } = props;

    // snackbar callback starts
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
    const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
    const [disableInviteBtn, setDisableInviteBtn] = React.useState(false);

    const handleSnackBar = (message) => {
        setCanSnackBarOpen(true);
        setSnackBarMessage(message);
        doRefreshSnackBar(prev => !prev)
    };


    //invite all users on the current page
    function inviteAllCandidates() {
        let element;
        if (props.paginationRef.current.childNodes[3].className === "ais-Pagination") {
            element = props.paginationRef.current.childNodes[3].childNodes[0].childNodes[9].childNodes[0];
        } else {
            element = props.paginationRef.current.childNodes[4].childNodes[0].childNodes[9].childNodes[0];
        }
        hits.forEach((hit, index) => {
            let payload = {
                company_id: localStorage.getItem('company_id').toString(),
                jobseeker_id: hit.objectID,
                job_role: props.role,
                location: props.location.length > 0 ? props.location[0].city : 'Delhi',
                invite_status: true,
            }
            ApiHandler.sendInvite(payload, response => {
                if (response.status == 200) {
                    AnalyticsHandler.track("InviteAll_btn_success")
                    if (index === hits.length - 1) {
                        setTimeout(() => {
                            setDisableInviteBtn(false);
                            element.click();
                        }, 1500);
                        handleSnackBar('You have successfully invited all the candidates on the previous page. Please go ahead to invite more jobseekers.')
                    }
                }
                else {
                    if (index === hits.length - 1) {
                        setTimeout(() => {
                            setDisableInviteBtn(false);
                            element.click();
                        }, 1500);
                    }
                    var ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    if (response.response) {
                        let statusCode = response.response.status;
                        if (statusCode === 500) {
                            ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
                        } else if (statusCode === 404) {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        } else if (statusCode === 400) {
                            if (response.response.data.message === "Session Expire") {
                            }
                            ErrorMessage = 'Looks like you have already invited some of these jobseekers for the current role. Please go ahead and try inviting jobseekers on the next page.'
                        } else {
                            ErrorMessage = 'Oops! Something went wrong. Please try again.';
                        }
                    } else if (response.message === 'Network Error') {
                        ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
                    } else {
                        ErrorMessage = 'Oops! Something went wrong. Please try again.';
                    }
                    handleSnackBar(ErrorMessage)
                }
            })
        });
    }

    return (
        <>
            <div className='ais-Hits'>
                <ul className='ais-Hits-list'>
                    {hits.map(hit => (
                        <li key={hit.objectID} className='ais-Hits-item'>
                            {isMobile ?
                                <MobileListView
                                    hit={hit}
                                    role={props.role}
                                    secondaryRoles={props.secondaryRoles}
                                    jobseekerEnteredRoles={props.jobseekerEnteredRoles}
                                    qualifications={props.qualifications}
                                    job_role_id={props.job_role_id}
                                    signInStatus={props.signInStatus()}
                                    handleDrawer={props.handleDrawer}
                                    removeLocalStorageOnSessionExpiry={props.removeLocalStorageOnSessionExpiry}
                                    sendLocationForInvite={props.sendLocationForInvite()}
                                    inviteList={props.inviteList}
                                    geographical_type={props.geographical_type}
                                />
                                :
                                <ListView
                                    hit={hit}
                                    role={props.role}
                                    secondaryRoles={props.secondaryRoles}
                                    jobseekerEnteredRoles={props.jobseekerEnteredRoles}
                                    qualifications={props.qualifications}
                                    job_role_id={props.job_role_id}
                                    signInStatus={props.signInStatus()}
                                    handleDrawer={props.handleDrawer}
                                    removeLocalStorageOnSessionExpiry={props.removeLocalStorageOnSessionExpiry}
                                    sendLocationForInvite={props.sendLocationForInvite()}
                                    inviteList={props.inviteList}
                                    geographical_type={props.geographical_type}
                                />}
                        </li>
                    ))}
                </ul>
            </div>
            <div style={{ width: '100%', marginBottom: '2em', position: 'sticky', bottom: '11%', zIndex: 999, backgroundColor: 'white', padding: isMobile ? '0.8em 1em' : '0.5em 3em' }}>
                <Button
                    variant="contained"
                    disabled={disableInviteBtn}
                    onClick={() => {
                        AnalyticsHandler.track("InviteAll_btn")
                        setDisableInviteBtn(true);
                        inviteAllCandidates();
                    }}
                    style={{ marginTop: '1em', width: isMobile ? '100%' : '27%' }}
                    className={props.classes.nextButton}
                >
                    Invite All
                </Button>
            </div>
            <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
        </>
    )
}

export default connectHits(Hits);