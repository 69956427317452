import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import MouseIcon from '@material-ui/icons/Mouse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircularProgress from '@material-ui/core/CircularProgress';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';
import MotorcycleRoundedIcon from '@material-ui/icons/MotorcycleRounded';
import LaptopChromebookRoundedIcon from '@material-ui/icons/LaptopChromebookRounded';
import SignalWifi4BarRoundedIcon from '@material-ui/icons/SignalWifi4BarRounded';
import ShareIcon from '@material-ui/icons/Share';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import profile_image from './profile_image.png';
import college_placeholder from './college_placeholder.png';
import company_placeholder from './company_placeholder.png';
import myInitObject from './location';
import count from './count';
import No_Results from './NoResults';
import page_global from './page';
import dividersCount from './dividersCount';
import { useSessionStorage } from '../../components/SessionStorageForInvite';
import ApiHandler from '../../ApiHandler';
import global_isSignedIn from './global_isSignedIn';
import SnackBarCustom from './SnackBarCustom';
import { DrawerContext } from './Algolia_Search_2';
import { globalRoleLocationInvite } from './Algolia_Search_2';
import { globalInviteList } from './Algolia_Search_2';
import FeedbackAlert from '../../components/FeedbackAlert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import AnalyticsHandler from '../../AnalyticsHandler';
import selected_qualifications from './quals';
import { RolesNotToBePassedToAlgolia } from '../../Globals';

// import {
//  // EmailShareButton,
//   FacebookShareButton,
//  // HatenaShareButton,
//  // InstapaperShareButton,
//  // LineShareButton,
//   LinkedinShareButton,
//  // LivejournalShareButton,
//  // MailruShareButton,
// //  OKShareButton,
//   PinterestShareButton,
//  // PocketShareButton,
//   RedditShareButton,
//  // TelegramShareButton,
// //  TumblrShareButton,
//   TwitterShareButton,
//  // ViberShareButton,
//  // VKShareButton,
//   WhatsappShareButton,
//  // WorkplaceShareButton
// } from "react-share";

// import {
//   //EmailIcon,
//   FacebookIcon,
//   FacebookMessengerIcon,
//   //HatenaIcon,
//   //InstapaperIcon,
//   //LineIcon,
//   LinkedinIcon,
//   //LivejournalIcon,
//   //MailruIcon,
//   //OKIcon,
//   PinterestIcon,
//   //PocketIcon,
//   RedditIcon,
//   //TelegramIcon,
//   //TumblrIcon,
//   TwitterIcon,
//   //ViberIcon,
//   //VKIcon,
//   //WeiboIcon,
//   WhatsappIcon,
//   //WorkplaceIcon
// } from "react-share";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    // height: 'calc(100vh - 320px)',
    height: 'calc(100vh - 240px)',
    minHeight: 300,
    background: 'white',
    //width: 'calc(100vw - 600px)',
  },
  paper: {
    boxSizing: 'border-box',
    borderRadius: 10,
    padding: theme.spacing(0),
    margin: 'auto',
    // maxWidth: 860,
    // maxHeight: 436,
    width: '100%',
    height: '100%',
    //overflowX: 'hidden',
    //overflowY: 'hidden',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 1,
    transform: 'translate3d(0, 0, 0) scale(1)',
    opacity: 1,
    transition: 'all 1s ease-in-out',

    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // }
    '&::-webkit-scrollbar': {
      width: 5,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#55c6a9',
      borderRadius: 4,
      '&:hover': {
        background: '#5BC2A8',
        cursor: 'pointer',
      },
    },
    '&::-webkit-scrollbar-track': {
      background: '#5bc2a873',
      margin: 20,
    },
    '&.animate': {
      //transform: 'translate3d(-20px, 0, 0) scale(1)',
      //opacity: 0,
      //zIndex: 0,
    }
  },
  dividerMsgDiv: {
    position: "absolute",
    top: "-15px",
    zIndex: 9,
    '& p': {
      margin: 0,
      color: "#5BC2A8",
      background: "white",
      padding: "5px 10px",
      border: "1px solid #C4C4C4",
      borderRadius: 15,
      '& span': {
        color: "#14967B",
        fontWeight: 500,
        textTransform: "capitalize",
      }
    }
  },
  profileSection: {
    //height: '100%',
    minHeight: '100%',
    transform: 'translate3d(0, 0, 0)',
    animation: 'profileFade 0.75s ease-in-out 1',
    // animation: 'fadeInOut 5s 2, autoSwipeUp 10s steps(2, end) 1 both',
  },
  leftSection: {
    height: '100%',
    //height: 436,
    background: '#5bc2a814',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-start',
    position: 'sticky',
    top: 0,
    //transition: 'all 0.25s ease-in-out'
  },
  rightSectionScrollable: {
    height: 'calc(100vh - 400px)',
    overflowY: 'auto',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // },
  },
  imageWrapper: {
    // width: 327,
    // height: 436,
    position: "relative",
    width: '100%',
    height: 'calc(100vh - 240px)',
    minHeight: 300,
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&>img': {
      //filter: 'contrast(90%) brightness(90%)',
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: 'none',
      zIndex: 1
    },
  },
  img: {
    //margin: 'auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    position: "relative",
    width: "50%",
    img: {
      // width: "100%",
      // height: "auto"
    },
    btn: {
      position: "absolute",
      top: "80%",
      left: "10%",
      // transform: translate(-50%, -50%);
      // -ms-transform: translate(-50%, -50%);
      backgroundColor: "white",
      // color: white;
      fontSize: "16px",
      // padding: 12px 24px;
      border: "none",
      // cursor: pointer;
      borderRadius: "50%"
    }
  },
  avatarWrapper: {
    width: 72,
    height: 72,
    borderRadius: 10,
    overflow: 'hidden',
    margin: theme.spacing(2),
    '&>img': {
      borderRadius: 10,
      margin: 'auto',
    },
  },
  card__LogoWrapper: {
    width: 40,
    height: 40,
    //borderRadius: 4,
    borderRadius: '50%',
    //overflow: 'hidden',
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // background: '#5bc2a873',
    '&>img': {
      margin: 'auto',
      display: 'block',
      width: 'auto',
      height: '100%',
      maxWidth: 150,
    },
  },
  card__LogoWrapper_reg: {
    width: 70,
    height: 70,
    borderRadius: 0,
    overflow: 'hidden',
    marginRight: 0,
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    // background: '#5bc2a873',
    '&>img': {
      //margin: 'auto',
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
    },
  },
  verified: {
    color: '#289e80',
    marginBottom: 5,
  },
  lastActive: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    color: '#ffffff',
    fontSize: 12,
  },
  chiproot: {
    borderRadius: 4,
    height: 22,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  cardIndicatorBar: {
    width: 4,
    height: 60,
    marginBottom: theme.spacing(1),
    backgroundColor: '#dcece8',
    // border: '1px solid #dcece8',
    borderRadius: 10,
  },
  cardIndicatorBar__progress: {
    width: '100%',
    height: 0,
    backgroundColor: '#55c6a9',
    borderRadius: 10,
    animation: 'indicatorProgress 5s linear 1 forwards',
  },
  progressBar2: {
    animationDelay: '5s',
  },
  progressBar3: {
    animationDelay: '10s',
  },
  tabsRoot: {
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      background: '#5bc2a873',
      width: '5px',
      right: 0,
      borderRadius: '2.5px',
    },
  },
  tabRoot: {
    height: 80,
    minHeight: 80,
    //margin: '5px 0',
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   height: '100%',
    //   background: '#5bc2a873',
    //   width: '5px',
    //   left: '34%',
    //   //borderRadius: '2.5px',
    // },
  },
  tabs: {
    borderRight: 'none',
    position: 'absolute',
    right: '1.5%',
    zIndex: 2,
    bottom: '20%',
    width: 60,
  },
  tabIndicator: {
    width: 5,
    height: 100,
    borderRadius: 10,
    backgroundColor: '#55c6a9',
  },
  cardHeader: {
    maxHeight: 61,
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#9ba2a0',
    position: 'sticky',
    top: 0,
    zIndex: 4,
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
    '&.animate': {
      opacity: 1,
      boxShadow: "0px 2px 10px rgba(0,0,0,0.1)"
    }
  },
  textOverlay: {
    width: '57%',
    height: 58,
    background: 'linear-gradient(179deg, rgba(255,255,255,0.0035364487591911242) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)',
    position: 'absolute',
    bottom: 0,
    right: 10,
    zIndex: 2,
  },
  // button: {
  //   zIndex: 12,
  //   position: 'absolute',
  //   bottom: '-23',
  // },
  bigIconRect: {
    zIndex: 9,
    //position: 'absolute',
    //bottom: '-16px',
    height: 35,
    width: 140,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
    }
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
}));

var rad = function (x) {
  return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(employer_lat_lng.lat - p1.lat);
  var dLong = rad(employer_lat_lng.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

const ComplexGrid = (props) => {
  const classes = useStyles();
  //const [scrollPlay, setScrollPlay] = React.useState(true);
  //const scrollDownElemRef = React.useRef(null);

  var qualifications_sorted = props.hit.user_qualifications ? props.hit.user_qualifications.sort((a, b) => new Date(b.user_passing_year) - new Date(a.user_passing_year)) : null // removed the reverse method as it causes an order switching bug on click of buttons on profile

  // var qualifications_sorted = props.hit.user_qualifications ? props.hit.user_qualifications.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)).reverse() : null;

  var quals_raw_data = []

  //keeps all records that have course_name and user_passing_year
  quals_raw_data = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.course_name && t.user_passing_year && item.course_name && item.user_passing_year && t.course_name == item.course_name && t.user_passing_year == item.user_passing_year) == i
  );

  //var quals_raw_data = qualifications_sorted;

  //keeps the records that have id
  qualifications_sorted = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
  );

  // qualifications_sorted = qualifications_sorted && qualifications_sorted.filter(item => Object.keys(item).length > 0)

  // const scrollDownPlay = () => {
  //   if (scrollDownElemRef.current) {
  //     scrollDownElemRef.current.querySelector('.scrollDown__Icons--arow').animate([
  //       { top: '20px' },
  //       { top: '50px' },
  //       { top: '20px' }
  //     ], {
  //       duration: 2000,
  //       iterations: Infinity,
  //       easing: 'ease-out',
  //     });
  //   }
  // }
  const imgPholderRef = useRef(null);
  const righSectionRef = useRef(null);
  React.useEffect(() => {
    if (imgPholderRef && imgPholderRef.current && righSectionRef && righSectionRef.current) {
      righSectionRef.current.style.opacity = 1
    }
  }, [])

  React.useEffect(() => {
    if (!props.hit.user_image_url) {
      count.counter = (count.counter + 1) % 4;
      //console.log("COUNT:::" + count.counter);
    }
  }, []);

  var employer_lat_lng =
  {
    lat: myInitObject.lat,
    lng: myInitObject.long
  }

  var geoType = myInitObject.geoType;
  //console.log(geoType)

  var tagLabelDist = employer_lat_lng.lat && employer_lat_lng.lng && geoType ?
    (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) == 0.0 ? "0" :
      (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1)
    : null;

  var tagLabel = tagLabelDist ?
    geoType === "locality" && tagLabelDist < 60 ? tagLabelDist + " km from city center" :
      geoType !== "locality" && tagLabelDist < 60 ? tagLabelDist + " km from area center" :
        "> 60 km"
    : null;

  var years_to_display = (props.hit.total_months_exp / 12).toFixed(0) == 1 ? " year " : " years ";
  var months_to_display = props.hit.total_months_exp % 12 == 1 ? " month " : " months  ";

  var last_active = moment(new Date()).diff(moment(new Date(props.hit.last_seen_at * 1000)), 'days');

  var total_years = Math.trunc(props.hit.total_months_exp / 12)
  var total_months = props.hit.total_months_exp % 12;
  var fresher = false;
  var less_than_month = props.hit.total_months_exp > 0 && props.hit.total_months_exp < 1 ? true : false;

  if ((total_years == 0 && total_months == 0) || props.hit.user_experiences == [] || props.hit.user_experiences == [{}] || props.hit.total_months_exp == null || props.hit.total_months_exp == 0)
    fresher = true;

  var experiences_sorted = props.hit.user_experiences && props.hit.user_experiences.length != 0 ? props.hit.user_experiences.sort((a, b) => {

    if (a == {} || b == {})
      return 0;
    if (a.company_ending_date === b.company_ending_date)
      return 0;
    else
      if (a.company_ending_date === 'Present') {
        return (new Date(b.company_ending_date) - new Date())
      }
      else
        if (b.company_ending_date === 'Present') {
          return (new Date() - new Date(a.company_ending_date))
        }
        else {
          return (new Date(b.company_ending_date) - new Date(a.company_ending_date))
        }
  }) : null;

  experiences_sorted = experiences_sorted
    && experiences_sorted.filter((item, i, array) =>
      array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
    )


  var qualifications = selected_qualifications.quals;
  var role = globalRoleLocationInvite.search_role;
  var secondaryRoles = selected_qualifications.secondaryRoles;
  var jobseekerEnteredRoles = selected_qualifications.jobseekerEnteredRoles;
  //console.log(secondaryRoles)

  var qualifications_to_search = props.hit.user_qualifications;
  var experiences_to_search = props.hit.user_experiences;
  var relevant_quals = false, relevant_to_show_role = false, relevant = false, relevant_role = false, relevant_to_show_quals = false, relevant_all = false;
  var k = 0;

  for (; k < qualifications.length; k++) {
    var item = qualifications[k].tag_id;
    var t = 0;
    for (; t < qualifications_to_search.length; t++) {
      if (item == qualifications_to_search[t].qualification_id) {
        relevant_quals = true;
        break;
      }
    }
  }
  var k = 0;

  for (; k < experiences_to_search.length; k++) {

    if (RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim())) break; // if role is all or fresher then relevant_role will always be false

    if (experiences_to_search[k].user_post && (role.toUpperCase().trim() == experiences_to_search[k].user_post.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }
    //check if any of the secondary roles exist in the user_post
    if (experiences_to_search[k].user_post && secondaryRoles && secondaryRoles.length !== 0 && secondaryRoles.some(item => item.title.toUpperCase().trim() === experiences_to_search[k].user_post.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }

    //check if the any of the jobseekerEnteredRoles exists in the user_post
    if (experiences_to_search[k].job_role_display && jobseekerEnteredRoles && jobseekerEnteredRoles.some(item => item.toUpperCase().trim() === experiences_to_search[k].job_role_display.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }
  }
  // }


  relevant = relevant_quals && relevant_role;

  relevant_to_show_quals = (!relevant_role) && (relevant_quals);

  //relevant_to_show_role = (relevant_role) && (!relevant_quals)

  relevant_to_show_role = (relevant_role); // so that roles irrespective of quals set the flag to true

  relevant_all = (!relevant_role) && (!relevant_quals)


  let dividerMsg = relevant_to_show_role ? selected_qualifications.dividerMsgRoles :
    relevant_to_show_quals ? selected_qualifications.dividerMsgQuals :
      relevant_all ? selected_qualifications.dividerMsgAll : "";



  React.useEffect(() => {
    let dividerMsgDiv = document.querySelector("#dividerMsgDiv");
    dividerMsgDiv.innerHTML = dividerMsg;
  }, [])



  var full_name = props.hit.jobseeker_name ? props.hit.jobseeker_name : "";
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";
  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }

  var bg_colors = ["#B9E4D9", "#FDEBB4", "#FFCDD3", "#E4BDDB"]
  var text_colors = ["#5BC2A8", "#FCD450", "#EF9A9B", "#C361B1"]

  const handleImageLoad = (event) => {
    let imageWidth = event.target.clientWidth;
    let imageHeight = event.target.clientHeight;
    // if(imageWidth/imageHeight >=4) {
    //   event.target.style.opacity = 0.2;
    // }
    if (imageWidth / imageHeight >= 1.5) {
      event.target.closest("div").style.padding = '0';
    }
  }

  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const handleProfileCardImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 0.75) {
      event.target.closest("#leftSectionCardId").style.maxWidth = imageWidth + "px"
    } else if (imageWidth / imageHeight >= 1) {
      event.target.closest("#imageWrapper").style.justifyContent = "center";
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionCardId").style.backgroundColor = "rgba(0,0,0,0)";
    event.target.closest("#leftSectionCardId").nextSibling.style.opacity = 1;
  }

  var array_name = props.hit.jobseeker_name ? props.hit.jobseeker_name.split(" ") : "";
  var k = 0, name_sentence_case = "";
  for (; k < array_name.length; k++) {
    if (array_name[k])
      name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
  }

  // snackbar callback starts
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);

  const [snackBarText, setSnackBarText] = React.useState("Your invite has been sent successfully. The jobseeker will call you if they are interested to work with you.");
  const handleSnackBar = (text) => {
    if (text) {
      setSnackBarText(text);
    }
    setCanSnackBarOpen(true)
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  // opendrawer context starts
  const openDrawer = React.useContext(DrawerContext);
  // opendrawer context ends

  //check the invitedList for the presence of this jobseeker's id
  const invitedList = globalInviteList.invite_list;
  const isInvited = invitedList.some(item => item.jobseeker_id === props.hit.objectID);
  const [selected, setSelected] = React.useState(false);

  const [gotResponse, setGotResponse] = React.useState(true);
  const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

  React.useLayoutEffect(() => {
    //alert("invitedList= " + invitedList)
    if (signInStatus) {
      //console.log("isInvited status= " + isInvited)
      setSelected(isInvited);
    }
  }, [invitedList]);

  // Invite button functionality starts
  let cid = localStorage.getItem('company_id');
  const [company_id, setCompanyId] = useState(cid);
  const [jobseeker_id, setJobseekerId] = useState(props.hit.objectID);
  const [job_role, setJobRole] = useState(globalRoleLocationInvite.search_role);
  const [location, setLocation] = useState(globalRoleLocationInvite.search_location);
  const [invitedRoles, setInvitedRoles] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const handleUninviteClickOpen = () => {
    setOpen(true);
  };

  const handleUninviteCancelClose = () => {
    setOpen(false);
  };

  const handleInviteClick = () => {
    //AnalyticsHandler.track('discoveryResults_Card_InviteUninvite_btnClick');
    if (signInStatus) {
      if (selected === true) { //open confirmation dialog if uninvite is clicked
        handleUninviteClickOpen();
        return
      }

      let payload = {
        company_id,
        jobseeker_id,
        job_role,
        location,
        invite_status: !selected,
      }
      setGotResponse(false);
      ApiHandler.sendInvite(payload, response => {
        if (response.status == 200) {
          AnalyticsHandler.track('discoveryResults_Card_Invite_success');
          if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
            setSelected(true);
            handleSnackBar('Your invite has been sent successfully. The jobseeker will call you if they are interested to work with you.');
            sessionStorage.setItem('isSnackbarDisplayed', true)
          } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
            setSelected(true);
          }

          //openDrawer.setCommand('refreshInviteList'); //commented out as it unnecessarily triggers the parent to rerender this component

          setGotResponse(true);
          updateInvitedJobRoles();
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              if (response.response.data.message === "Session Expire") {
                openDrawer.setCommand('removeLocalStorage');
              }
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
        }
      })
    } else if (!signInStatus) {
      openDrawer.setCommand('openDrawer')
    }
  }

  const handleUninviteYesClose = () => {
    AnalyticsHandler.track("discoveryResults_Card_Uninvite_btnClick");
    if (signInStatus) {
      let payload = {
        company_id,
        jobseeker_id,
        job_role,
        location,
        invite_status: !selected,
      }
      setGotUninviteResponse(false);
      //alert(props.signInStatus)
      ApiHandler.sendInvite(payload, response => {
        if (response.status == 200) {
          setOpen(false);
          if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
            setSelected(false);
          } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
            setSelected(false);
          }
          setGotUninviteResponse(true);
          updateInvitedJobRoles();
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              if (response.response.data.message === "Session Expire") {
                openDrawer.setCommand('removeLocalStorage');
              }
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
        }
      })
    } else if (!signInStatus) {
      openDrawer.setCommand('openDrawer')
    }
  }
  // Invite button functionality ends

  const updateInvitedJobRoles = () => {
    ApiHandler.getInvitedJobRoles(company_id, jobseeker_id, response => {
      if (response.status === 200) {
        let jobRoles = response.data && response.data[0] ? response.data[0].job_roles : [];
        setInvitedRoles(jobRoles);
      } else {
        console.log("Oops! Something went wrong. Please try again.")
      }
    })
  }

  React.useLayoutEffect(() => {
    updateInvitedJobRoles();
  }, [])

  const history = useHistory();

  //const isSignedIn =  signInStatus();
  const signInStatus = global_isSignedIn.isSignedIn;

  const length = invitedRoles.length;
  var invitedRolesList = [];
  if (invitedRoles.length > 2) {
    invitedRolesList.push(invitedRoles[0]);
    invitedRolesList.push(invitedRoles[1]);
  } else {
    invitedRoles.forEach(item => {
      invitedRolesList.push(item);
    })
  }

  // return (page_global.page <= page_global.nb_hits ) ? (
  return (
    <div id="cardWrapper" className={classes.root}>
      <SnackBarCustom canSnackBarOpen={canSnackBarOpen} text={snackBarText} refresh={refreshSnackBar} />
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ position: 'absolute', bottom: '-16px' }}>
        {/* invite button */}
        <DrawerContext.Provider>
          <ToggleButton
            id="invite_uninvite_card_btn_id"
            value="check"
            className={classes.bigIconRect}
            style={{ marginRight: 10 }}
            classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
            selected={selected}
            onChange={gotResponse ? handleInviteClick : null}>
            {selected ?
              <CheckIcon className={classes.saveIconRect} />
              : <EmailIcon className={classes.saveIconRect} />
            }
            {selected ? "Invited" : "Email Invite"}
          </ToggleButton>
        </DrawerContext.Provider>
        {/* share button */}
        <CopyToClipboard text={'https://mywebsite.meetworks.in' + `/profile/${(array_name[0] ? array_name[0] : "") + "-" + (array_name[1] ? array_name[1] : "") + '-' + props.hit.objectID}`} >
          <Button
            className={classes.bigIconRect}
            // style={{ width: 150, left: '-10px', bottom: 10, width: 100, border: 'none' }}
            variant="outlined" id="shareButton"
            onClick={() => { AnalyticsHandler.track('ShareButton_DiscoveryPage_Click'); handleSnackBar(`Profile link is copied`) }}
          ><ShareIcon className={classes.saveIconRect} /> Share </Button>
        </CopyToClipboard>
      </Grid>

      <div className={classes.textOverlay}></div>

      <div className={classes.dividerMsgDiv} id="dividerMsgDiv"></div>

      <Paper id="paper" className={classes.paper} elevation={3}>
        <Grid container direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.profileSection} >
          <Grid xs={5} item className={classes.leftSection} id="leftSectionCardId">
            <div className={classes.imageWrapper} id="imageWrapper">
              {props.hit.user_image_url ?
                <img className={classes.img} alt="candidates profile picture" src={props.hit.user_image_url} width='400px' height='550px' onLoad={handleProfileCardImage} style={{ opacity: 0 }} />
                :
                <div ref={imgPholderRef} className={classes.imgPholder} style={{ backgroundColor: `${bg_colors[count.counter]}` }}>
                  <p style={{ fontSize: 72, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 150, height: 150, lineHeight: '150px', textAlign: 'center', borderRadius: '50%', color: `${text_colors[count.counter]}` }}> {initials} </p>
                </div>
              }
            </div>
          </Grid>
          <Grid item xs={7} sm container direction="column"
            justifyContent="center"
            alignItems="center"
            ref={righSectionRef}
            style={{ paddingTop: 50, paddingBottom: 100, opacity: 0 }}>

            <Grid item xs={10} container direction="column" justifyContent="center" alignItems="flex-start">

              <Grid item xs={10} container direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ minHeight: 52 }}>
                <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="baseline" style={{ marginLeft: 38 }}>
                  <Typography variant="h6" component='h2' style={{ marginRight: 7 }}>
                    {name_sentence_case}
                  </Typography>

                  {/* <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginTop: 5, marginBottom: 30, marginLeft: '10px' }}> */}

                  <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>
                    <span style={{ fontSize: 16, marginRight: 4, color: '#ccc' }}>|</span>
                    <span style={{ position: 'relative', marginRight: 8 }}>
                      {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                    <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                      {/* {(props.hit.area && props.hit.city) ? (props.hit.area + " , " + props.hit.city) : (props.hit.city ? props.hit.city: "") +((props.hit.city && props.hit.distric) ? " , ": "")+ (props.hit.distric? props.hit.distric : "")} */}
                      {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                    </span></span>
                  {/* show distance tag if lat long present & geotype is not state or district */}
                  {/* {employer_lat_lng.lat != null && employer_lat_lng.lng != null && geoType && geoType!=="administrative_area_level_1" && geoType!=="administrative_area_level_2" && geoType!=="colloquial_area"? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km"} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} /> : null} */}

                  {employer_lat_lng.lat != null && employer_lat_lng.lng != null && geoType && geoType !== "administrative_area_level_1" && geoType !== "administrative_area_level_2" && geoType !== "colloquial_area" ? <Chip size="small" label={tagLabel} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} /> : null}

                </Grid>

                {invitedRoles.length !== 0 ?
                  <Grid item xs container direction="row" wrap='nowrap' justifyContent="flex-start" alignItems="flex-start" style={{ marginLeft: '37px', paddingTop: 2 }}
                  >
                    <CheckIcon className="primaryText" style={{ fontSize: 14, paddingRight: 2, paddingTop: 2 }} />
                    <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                      Invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>
                        {invitedRolesList.map((item, i) => {
                          if (i < invitedRolesList.length - 1) {
                            return item + ", "
                          } else {
                            return item
                          }
                        })}
                        {length > 2 ? " " + "+" + (length - 2) + " " + (length === 3 ? "other" : "others") : ""}
                      </span>
                    </Typography></Grid> : null
                }

              </Grid>

              {/* {fresher?
              <Chip size="small" label={"Total Exp: " + (fresher ? 'Fresher' : less_than_month ? " < 1 month " : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display)))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginBottom: 28 }} />
              : null } */}

              {/* {((props.hit.user_experiences.length == 1 && props.hit.user_qualifications.length == 1) || (props.hit.user_experiences.length == 0 && props.hit.user_qualifications.length == 1) || (props.hit.user_experiences.length == 1 && props.hit.user_qualifications.length == 0)) ? */}
              <div style={{ marginTop: 20, marginLeft: 10 }}>

                {/* <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginBottom: 30, marginLeft: '27px' }}>
                    {((props.hit.area || props.hit.city) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 3, color: '#555', fontSize: 16 }} /> : null}
                   
                 <Typography variant="subtitle1" style={{ marginLeft: 5, fontWeight: 400, fontSize: 14 }}>{props.hit.area ? props.hit.area : null} {props.hit.area && props.hit.city ? ", " : null} {props.hit.city ? props.hit.city : null}</Typography>
                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                  </Grid> */}

                <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 0 }}>
                  <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{ maxWidth: 20, marginRight: 10, marginLeft: '28px' }}>
                    <WorkIcon fontSize="small" className="primaryText" style={{ marginTop: 1, marginBottom: 12 }} />
                  </Grid>
                  <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 5 }}>
                      <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Experience</Typography>
                      {fresher ?
                        <Chip size="small" label={(fresher ? 'Fresher' : less_than_month ? " < 1 month " : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display)))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} />
                        : null
                      }

                    </Grid>
                  </Grid>
                </Grid>

                {experiences_sorted && experiences_sorted.map((item, i) => (
                  <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12 }} key={i}>
                    <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{
                      maxWidth: 20,
                      // marginRight: 79, 
                      // marginLeft: '-40px',
                      marginRight: 58,
                      marginLeft: '-20px',
                      marginTop: 8,
                    }}>
                      <div className={classes.card__LogoWrapper_reg}>
                        {item.company_logo ? <img className={classes.img} width='60px' height='60px' alt="company logo" src={item.company_logo ? item.company_logo : null}
                          // onError="this.style.display='none'" 
                          onLoad={handleImageLoad} /> : ""}
                      </div>
                    </Grid>
                    <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                      <Typography variant="subtitle1" component='h3' style={{ marginLeft: 0, fontWeight: 500 }}>
                        {item.job_role_display ? item.job_role_display : ""}
                      </Typography>
                      <Typography variant="subtitle1" component='h4' style={{ marginLeft: 0, fontSize: 15 }}>
                        {item.company_name ? item.company_name : ""}
                      </Typography>
                      <Typography variant="subtitle1" component='h5' style={{ marginLeft: 0, fontSize: 13, color: '#999' }}>
                        {item.company_starting_date && item.company_ending_date && item.company_ending_date == "Present" ? (item.company_starting_date.includes(" ") ? item.company_starting_date + " - " + "Present" : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + "Present") : ""}

                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                          ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) &&
                            (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                              item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " - " + months[new Date(item.company_ending_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}

                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                          ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) &&
                            (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                              item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}

                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                          ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) &&
                            (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                              item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}
                              
                        {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                          ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) &&
                            (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                              item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}
                      </Typography>
                      <Typography variant="subtitle1" component='h6'
                        style={{
                          marginLeft: 0, marginTop: 5, fontSize: 14,
                          // fontStyle: 'italic' 
                        }}>
                        {item.role_discription ? item.role_discription : ""}
                      </Typography>
                    </Grid>
                  </Grid>))}

                <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12, marginLeft: '27px' }}>
                  <SchoolRoundedIcon fontSize="small" className="primaryText" style={{ marginTop: 1 }} />

                  <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">

                    <Typography variant="subtitle1" component='h3' style={{ marginBottom: 12, marginLeft: 10, fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Education</Typography>

                    {
                      quals_raw_data && quals_raw_data.map((item, i) => (
                        <div key={i} style={{ marginBottom: 10 }}>
                          <Typography variant="subtitle1" component='h4' style={{ marginLeft: 10, fontWeight: 500, lineHeight: 1.5 }}>
                            {/* {item.course_type && (item.course_type == "UG Degree" || item.course_type == "PG Degree") ? (item.course_name || item.specialization_name ? (item.course_name ? item.course_name : "") + (item.specialization_name ? " - " + item.specialization_name : "") : "") : (item.course_name || item.specialization_name || item.course_type ? (item.course_type ? item.course_type : "") + (item.course_name ? " - " + item.course_name : "") + (item.specialization_name ? " - " + item.specialization_name : "") : "")} */}
                            {(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : (item.course_name ? item.course_name : null))}{(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}
                            {!item.user_college ? " | " + item.user_passing_year : ""}
                          </Typography>
                          <Typography variant="subtitle1" component='h5' style={{ marginLeft: 10, fontSize: 15 }}>
                            {(item.user_college && item.user_passing_year ? item.user_college + " | " + item.user_passing_year : item.user_college)}
                          </Typography>
                        </div>))}

                    {/* { quals_raw_data ?

                      quals_raw_data.map((item)=> (
                        <div>
                         <Typography variant="subtitle1" style={{ marginLeft: 10, fontWeight: 500, lineHeight: 1.5 }}>
                        {item.course_name} <br/>
                        </Typography>
                        <Typography variant="subtitle1" style={{ marginLeft: 10, fontSize: 15 }}>
                        {item.user_college} | {item.user_passing_year} <br/>
                        </Typography>
                        </div>
                          ))

                    : null

                    } */}

                  </Grid>
                </Grid>
              </div>

              {/*  :
                <div>
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginBottom: 10 }}>
                    {((props.hit.area || props.hit.city) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon fontSize="small" className="primaryText" /> : null}
                    <Typography variant="body2" style={{ marginLeft: 10 }}>{props.hit.area ? props.hit.area : null} {props.hit.area && props.hit.city ? ", " : null} {props.hit.city ? props.hit.city : null}</Typography>
                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                  </Grid>

                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginBottom: 10 }} >
                    {experiences_sorted && experiences_sorted[0].user_post ? <WorkIcon fontSize="small" className="primaryText" /> : null}
                    <Typography variant="body2" style={{ marginLeft: 10, fontWeight: 400 }}>
                      {!props.hit.user_experiences || props.hit.user_experiences == [] ? null :

                        <span>
                          {experiences_sorted && experiences_sorted[0].user_post ? experiences_sorted[0].user_post : null} <span className={classes.inlineSubtext}>
                            {experiences_sorted && experiences_sorted[0].company_name ? ", " + experiences_sorted[0].company_name + " " : null}
                            {experiences_sorted && experiences_sorted[0].company_starting_date && experiences_sorted[0].company_ending_date ? "(" + experiences_sorted[0].company_starting_date + " - " + experiences_sorted[0].company_ending_date + ")" : null}
                          </span> </span>
                      }
                    </Typography>
                  </Grid>

                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginBottom: 10 }} >
                    {qualifications_sorted && qualifications_sorted[0] ? <SchoolRoundedIcon fontSize="small" className="primaryText" /> : null}
                    <Typography variant="body2" style={{ marginLeft: 10 }}>
                      {qualifications_sorted && qualifications_sorted[0] ? qualifications_sorted[0].user_degree : null}
                      {qualifications_sorted && qualifications_sorted.length == 1 ? " , " + qualifications_sorted[0].user_college + " | " + qualifications_sorted[0].user_passing_year : null}
                    </Typography>
                  </Grid>

                </div>
              } */}
            </Grid>

            {/* {(props.hit.user_experiences.length > 1 || props.hit.user_qualifications.length > 1) && (
              <div className="scrollDownElem" ref={scrollDownElemRef} title="scroll down" style={{ display: scrollPlay === true ? 'block' : 'none' }}>
                <div className="scrollDown__Icons">
                  <MouseIcon fontSize="small" style={{ color: "#ccc" }} />
                  <KeyboardArrowDownIcon fontSize="small" className="scrollDown__Icons--arow" />
                </div>
              </div>)} */}
          </Grid>

          {/* <Typography variant="body2" className={classes.lastActive} style={{ color: '#797979', position: 'absolute', top: 0, right: 0 }}>
            Last active: {last_active <= 30 ? last_active + " days" : " > 1 month "}
          </Typography> */}
        </Grid>





      </Paper>

      <div>
        <Dialog
          open={open}
          onClose={handleUninviteCancelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation to cancel invite"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to cancel your invite to <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span> for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{job_role}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
              Cancel
            </Button>
            <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )

}

export default ComplexGrid;