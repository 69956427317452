import React from 'react';
import { Typography, Grid, InputBase, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import RemoveIcon from '@material-ui/icons/Remove';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();
var axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;


function Skills(props) {
    const { classes, values, setValues } = props;
    const [inputFields, setInputFields] = React.useState([
        {
            skill: null,
            no_of_experience: null,
            sector: null
        },
        // {
        //     skill: null,
        //     no_of_experience: null,
        //     sector: null
        // },
        // {
        //     skill: null,
        //     no_of_experience: null,
        //     sector: null
        // },
    ])

    const [skill, setSkill] = React.useState([]);
    const [sector, setSector] = React.useState([]);
    // const skillRef = React.useRef();
    // const sectorRef = React.useRef();

    const addSkillInput = () => {
        setInputFields([...inputFields, { skill: null, no_of_experience: null, sector: null }])
    }

    const removeSkillInput = (index) => {
        const list = [...inputFields];
        list.splice(index, 1);
        setInputFields(list);
        setValues({ ...values, skills_required: [...list] });
    }
    const handleSkillChange = (key, index, val) => {
        // const name = ref.current.getAttribute("name");
        const list = [...inputFields];
        list[index][key] = val;
        setInputFields(list);
        let filteredInputs = inputFields.filter(input => input.skill !== null || input.sector !== null || input.no_of_experience !== null);
        setValues({ ...values, skills_required: filteredInputs });
    }

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_skills').then((Result) => {
            let skillArr = Result.data;
            setSkill(skillArr);
        })
    }, []);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_sectors').then((Result) => {
            let sectorArr = Result.data;
            setSector(sectorArr);
        })
    }, []);

    React.useEffect(() => {
        if (values.skills_required.length > 0 && values.skills_required !== null) {
            let newVal = [...inputFields]
            values.skills_required.forEach((input, index) => {
                newVal[index] = { skill: input.skill, no_of_experience: input.no_of_experience, sector: input.sector }
            })
            setInputFields(newVal)
        }
    }, []);



    return <Grid item xs={12} className={`${classes.my3} ${classes.position_relative}`} >
        <Typography variant='h5' className={`${classes.sticky_heading} ${classes.inputHeading}`}>Skills Required</Typography>
        <div className={classes.flexboxCol}>
            {inputFields.map((input, index) => (
                <div key={index} className={`${classes.my3} ${classes.flexbox} ${classes.skillsDiv}`} >
                    <Autocomplete
                        // id={input.skill}
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        options={skill}
                        // ref={skillRef}
                        name="skill"
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            return option.skill_name
                        }
                        }
                        style={{ flex: 0.75, display: 'inline' }}

                        // value={skillValue}
                        value={input.skill || ""}

                        onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                handleSkillChange('skill', index, newValue);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleSkillChange('skill', index, newValue.inputValue);
                            } else if (newValue) { // when value is selected from dropdown
                                handleSkillChange('skill', index, newValue.skill_name);
                            }
                            //     else {
                            //         handleSkillChange('skill', index, null);
                            //     }
                        }}

                        onInputChange={(event, newValue) => {
                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                handleSkillChange("skill", index, null);
                            }
                        }}
                        // inputValue={inputCourseValue}

                        renderInput={(params) => <TextField {...params} label="Skill" placeholder="e.g SEO" classes={{ root: classes.skillsInput }} variant={props.isMobile ? 'outlined' : 'standard'}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        }

                        renderOption={(option) => {
                            if (option) {
                                return (
                                    <Grid container alignItems="flex-start" direction="column">
                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.skill_name} </Typography>
                                    </Grid>
                                )
                            }
                        }}

                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    skill_name: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                    />
                    <RemoveIcon className={`${!props.isMobile? classes.mx1 : classes.hideOnMobile}`} />
                    <TextField
                        label="Experience required"
                        placeholder="e.g 1 year"
                        type='string'
                        value={input.no_of_experience || ""}
                        name="no_of_experience"
                        variant={props.isMobile ? 'outlined' : 'standard'}
                        onChange={e => {
                            // setExpValue(e.target.value);
                            let name = e.target.getAttribute('name');
                            let list = [...inputFields];
                            list[index][name] = e.target.value;
                            setInputFields(list);
                            let filteredInputs = inputFields.filter(input => input.skill !== null || input.sector !== null || input.no_of_experience !== null && input.no_of_experience !== "");
                            setValues({ ...values, skills_required: filteredInputs });
                            // handleSkillChange(expRef, index, expValue);
                        }}
                        style={{margin: props.isMobile ? '1em 0' : 0}}
                        classes={{ root: classes.skillsInputExp }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <RemoveIcon className={`${!props.isMobile? classes.mx1 : classes.hideOnMobile}`} />
                    <Autocomplete
                        // id="combo-box2"
                        name='sector'
                        freeSolo
                        clearOnBlur
                        selectOnFocus
                        options={sector}
                        // ref={sectorRef}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            if (option.inputValue) {
                                return option.sector_name;
                            }
                            return option.sector_name
                        }
                        }
                        style={{ flex: 0.75, display: 'inline' }}

                        value={input.sector || ""}

                        onChange={(event, newValue) => {
                            event.preventDefault();
                            if (newValue && typeof newValue === 'string') { // when a free input is entered and enter is pressed
                                handleSkillChange('sector', index, newValue);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                handleSkillChange('sector', index, newValue.inputValue);
                            } else if (newValue) { // when value is selected from dropdown
                                handleSkillChange('sector', index, newValue.sector_name);
                            }
                        }}

                        onInputChange={(event, newValue) => {
                            if (newValue === "") {  //to clear the value on backspace and clear btn press
                                handleSkillChange("sector", index, null);
                            }
                        }}

                        // inputValue={inputCourseValue}

                        renderInput={(params) => <TextField {...params} value="" label="Domain" placeholder="e.g Logistics" classes={{ root: classes.skillsInput }} variant={props.isMobile ? 'outlined' : 'standard'}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                        }

                        renderOption={(option) => {
                            if (option) {
                                return (
                                    <Grid container alignItems="flex-start" direction="column">
                                        <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.sector_name} </Typography>
                                    </Grid>
                                )
                            }
                        }}

                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            // Suggest the creation of a new value
                            if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    sector_name: `Add "${params.inputValue}"`,
                                });
                            }
                            return filtered;
                        }}
                    />
                    {inputFields.length > 1 &&
                        <CancelIcon fontSize='small' className={`${classes.mx1} ${classes.icon}`} onClick={() => removeSkillInput(index)} />
                    }
                </div>
            ))}
            {/* <span className={classes.helper_text}>To select press Enter ↲ | To navigate results use ↑ ↓ </span> */}
            <div>
                <Typography gutterBottom variant='body1' component='p' style={{ display: 'inline-flex' }} classes={{ body1: classes.my2 }} color='primary' onClick={addSkillInput}>
                    <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add another skill</span>
                </Typography>
            </div>
        </div>
    </Grid>;
}

export default Skills;
