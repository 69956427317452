import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import myInitObject from '../discovery/location';
import count from '../discovery/count';
import global_isSignedIn from '../discovery/global_isSignedIn';
// import SnackBarCustom from './SnackBarCustom';
import FeedbackAlert from '../../components/FeedbackAlert';
// import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
// import AnalyticsHandler from '../../AnalyticsHandler';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import ApiHandler from '../../ApiHandler';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import EmailIcon from '@material-ui/icons/Email';
import AnalyticsHandler from '../../AnalyticsHandler';
import Loader from './Loader';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import StarRateIcon from '@material-ui/icons/StarRate';


const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 'unset',
    // display: 'flex',
    // justifyContent: 'center',
    position: 'relative',
    // height: 'calc(100vh - 320px)',
    // height: 'calc(100vh - 240px)',
    height: '60vh',
    minHeight: 300,
    background: 'white',
    //width: 'calc(100vw - 600px)',
  },
  paper: {
    boxSizing: 'border-box',
    borderRadius: 10,
    padding: theme.spacing(0),
    margin: 'auto',
    // maxWidth: 860,
    // maxHeight: 436,
    width: '100%',
    height: '100%',
    //overflowX: 'hidden',
    //overflowY: 'hidden',
    // overflowY: 'auto',
    position: 'absolute',
    zIndex: 1,
    transform: 'translate3d(0, 0, 0) scale(1)',
    opacity: 1,
    transition: 'all 1s ease-in-out',
    overflowX: 'hidden',
    overflowY: 'auto',

    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // }
    '&::-webkit-scrollbar': {
      width: 5,
      height: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#55c6a9',
      borderRadius: 4,
      '&:hover': {
        background: '#5BC2A8',
        cursor: 'pointer',
      },
    },
    '&::-webkit-scrollbar-track': {
      background: '#5bc2a873',
      margin: 20,
    },
    '&.animate': {
      //transform: 'translate3d(-20px, 0, 0) scale(1)',
      //opacity: 0,
      //zIndex: 0,
    }
  },
  profileSection: {
    //height: '100%',
    minHeight: '100%',
    transform: 'translate3d(0, 0, 0)',
    animation: 'profileFade 0.75s ease-in-out 1',
    // animation: 'fadeInOut 5s 2, autoSwipeUp 10s steps(2, end) 1 both',
  },
  leftSection: {
    height: '100%',
    //height: 436,
    background: '#5bc2a814',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignSelf: 'flex-start',
    position: 'sticky',
    top: 0,
    //transition: 'all 0.25s ease-in-out'
  },
  rightSectionScrollable: {
    height: 'calc(100vh - 400px)',
    overflowY: 'auto',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // },
  },
  imageWrapper: {
    // width: 327,
    // height: 436,
    position: "relative",
    width: '100%',
    // height: 'calc(100vh - 240px)',
    height: '60vh',
    minHeight: 300,
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>img': {
      //filter: 'contrast(90%) brightness(90%)',
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: 'none',
      zIndex: 1
    },
  },
  img: {
    //margin: 'auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    position: "relative",
    width: "50%",
    img: {
      // width: "100%",
      // height: "auto"
    },
    btn: {
      position: "absolute",
      top: "80%",
      left: "10%",
      // transform: translate(-50%, -50%);
      // -ms-transform: translate(-50%, -50%);
      backgroundColor: "white",
      // color: white;
      fontSize: "16px",
      // padding: 12px 24px;
      border: "none",
      // cursor: pointer;
      borderRadius: "50%"
    }
  },
  avatarWrapper: {
    width: 72,
    height: 72,
    borderRadius: 10,
    overflow: 'hidden',
    margin: theme.spacing(2),
    '&>img': {
      borderRadius: 10,
      margin: 'auto',
    },
  },
  card__LogoWrapper: {
    width: 40,
    height: 40,
    //borderRadius: 4,
    borderRadius: '50%',
    //overflow: 'hidden',
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // background: '#5bc2a873',
    '&>img': {
      margin: 'auto',
      display: 'block',
      width: 'auto',
      height: '100%',
      maxWidth: 150,
    },
  },
  card__LogoWrapper_reg: {
    width: 70,
    height: 70,
    borderRadius: 0,
    overflow: 'hidden',
    marginRight: 0,
    padding: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    // background: '#5bc2a873',
    '&>img': {
      //margin: 'auto',
      display: 'block',
      height: 'auto',
      maxWidth: '100%',
    },
  },
  verified: {
    color: '#289e80',
    marginBottom: 5,
  },
  lastActive: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    color: '#ffffff',
    fontSize: 12,
  },
  chip: {
    fontSize: '13px',
    fontWeight: 'bold',
    // fontWeight: 500,
    // width: '120px',
    padding: '2px 8px',
    margin: '6px 0 2px 0',
    backgroundColor: '#f5f5f5',
    color: "#797979",
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chiproot: {
    borderRadius: 4,
    height: 22,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  cardIndicatorBar: {
    width: 4,
    height: 60,
    marginBottom: theme.spacing(1),
    backgroundColor: '#dcece8',
    // border: '1px solid #dcece8',
    borderRadius: 10,
  },
  cardIndicatorBar__progress: {
    width: '100%',
    height: 0,
    backgroundColor: '#55c6a9',
    borderRadius: 10,
    animation: 'indicatorProgress 5s linear 1 forwards',
  },
  progressBar2: {
    animationDelay: '5s',
  },
  progressBar3: {
    animationDelay: '10s',
  },
  tabsRoot: {
    '&:before': {
      content: '""',
      position: 'absolute',
      height: '100%',
      background: '#5bc2a873',
      width: '5px',
      right: 0,
      borderRadius: '2.5px',
    },
  },
  tabRoot: {
    height: 80,
    minHeight: 80,
    //margin: '5px 0',
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   height: '100%',
    //   background: '#5bc2a873',
    //   width: '5px',
    //   left: '34%',
    //   //borderRadius: '2.5px',
    // },
  },
  tabs: {
    borderRight: 'none',
    position: 'absolute',
    right: '1.5%',
    zIndex: 2,
    bottom: '20%',
    width: 60,
  },
  tabIndicator: {
    width: 5,
    height: 100,
    borderRadius: 10,
    backgroundColor: '#55c6a9',
  },
  cardHeader: {
    maxHeight: 61,
    borderRadius: '10px 10px 0 0',
    backgroundColor: '#9ba2a0',
    position: 'sticky',
    top: 0,
    zIndex: 4,
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
    '&.animate': {
      opacity: 1,
      boxShadow: "0px 2px 10px rgba(0,0,0,0.1)"
    }
  },
  textOverlay: {
    width: '57%',
    height: 58,
    background: 'linear-gradient(179deg, rgba(255,255,255,0.0035364487591911242) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)',
    position: 'absolute',
    bottom: 0,
    right: 10,
    zIndex: 2,
  },
  // button: {
  //   zIndex: 12,
  //   position: 'absolute',
  //   bottom: '-23',
  // },
  bigIconRect: {
    zIndex: 9,
    //position: 'absolute',
    //bottom: '-16px',
    height: 35,
    width: 140,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
    }
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
}));

var rad = function (x) {
  return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(employer_lat_lng.lat - p1.lat);
  var dLong = rad(employer_lat_lng.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

const ComplexGrid = (props) => {
  const classes = useStyles();
  //const [scrollPlay, setScrollPlay] = React.useState(true);
  //const scrollDownElemRef = React.useRef(null);
  const [userExpResponse, setUserExpResponse] = React.useState([])
  const [userEduResponse, setUserEduResponse] = React.useState([])
  // const [jobseeker_id, setJobseekerId] = useState(props.hit.jobseeker_id);
  const [selected, setSelected] = React.useState(props.hit.invite_status);
  const [invited, setInvited] = React.useState(props.hit.invite_jobseekers.length > 0 ? props.hit.invite_jobseekers[0].invite_status : false);
  const [invitedData, setInvitedData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  var qualifications_sorted = userEduResponse ? userEduResponse.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)).reverse() : null;

  var quals_raw_data = []

  React.useEffect(() => {
    if (props.hit.invite_jobseekers.length > 0) {
      setInvited(props.hit.invite_jobseekers[0].invite_status)
    }
    else {
      setInvited(false)
    }
  }, [props.hit])

  //keeps all records that have course_name and user_passing_year
  quals_raw_data = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.course_name && t.user_passing_year && item.course_name && item.user_passing_year && t.course_name == item.course_name && t.user_passing_year == item.user_passing_year) == i
  );

  //var quals_raw_data = qualifications_sorted;

  //keeps the records that have id
  // qualifications_sorted = qualifications_sorted.filter((item, i, array) =>
  //   array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
  // );

  // qualifications_sorted = qualifications_sorted && qualifications_sorted.filter(item => Object.keys(item).length > 0)

  const imgPholderRef = useRef(null);
  const righSectionRef = useRef(null);

  React.useEffect(() => {
    if (imgPholderRef && imgPholderRef.current && righSectionRef && righSectionRef.current) {
      righSectionRef.current.style.opacity = 1
    }
  }, []);

  React.useEffect(() => {
    props.hit.invite_jobseekers.length > 0 && setInvitedData(props.hit.invite_jobseekers);
  }, [props.hit]);

  React.useEffect(() => {
    if (!props.hit.user_image_url) {
      count.counter = (count.counter + 1) % 4;
      //console.log("COUNT:::" + count.counter);
    }
  }, []);

  React.useEffect(() => {
    ApiHandler.getInvitedProfileExp({ jobseeker_id: props.hit.jobseeker_id }, response => {
      if (response.status === 200) {
        // console.log(response.data.user_experiences);
        let _expData = response.data.user_experiences;
        let _expMonsData = response.data.total_months_exp;
        // console.log(_expData)
        setUserExpResponse(response.data);
        props.setCardViewLoader(false);
      }
    });

    ApiHandler.getInvitedProfileQuals({ jobseeker_id: props.hit.jobseeker_id }, response => {
      setLoader(true);
      if (response.status === 200) {
        //console.log(response.data);
        setUserEduResponse(response.data)
        setLoader(false);
      }
    })
  }, [props.hit]);

  var employer_lat_lng =
  {
    lat: myInitObject.lat,
    lng: myInitObject.long
  }

  var years_to_display = userExpResponse && (userExpResponse.total_months_exp / 12).toFixed(0) == 1 ? " year " : " years ";
  var months_to_display = userExpResponse && userExpResponse.total_months_exp % 12 == 1 ? " month " : " months  ";

  var total_years = Math.trunc(userExpResponse.total_months_exp / 12)
  var total_months = userExpResponse && userExpResponse.total_months_exp % 12;
  var fresher = false;
  var less_than_month = userExpResponse && userExpResponse.total_months_exp > 0 && props.hit.total_months_exp < 1 ? true : false;

  if ((total_years == 0 && total_months == 0) || userExpResponse.user_experiences == [] || userExpResponse.user_experiences == [{}] || userExpResponse.total_months_exp == null || userExpResponse.total_months_exp == 0)
    fresher = true;

  var experiences_sorted = userExpResponse && userExpResponse.user_experiences && userExpResponse.user_experiences.length != 0 ? userExpResponse.user_experiences.sort((a, b) => {

    if (a == {} || b == {})
      return 0;
    if (a.company_ending_date === b.company_ending_date)
      return 0;
    else
      if (a.company_ending_date === 'Present') {
        return (new Date() - new Date(b.company_ending_date))
      }
      else
        if (b.company_ending_date === 'Present') {
          return (new Date(a.company_ending_date) - new Date())
        }
        else {
          return (new Date(a.company_ending_date) - new Date(b.company_ending_date))
        }
  }).reverse() : null;

  experiences_sorted = experiences_sorted
    && experiences_sorted.filter((item, i, array) =>
      array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
    )

  var full_name = props.hit.user_first_name ? props.hit.user_first_name + " " + props.hit.user_last_name : "";
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";
  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }

  var bg_colors = ["#B9E4D9", "#FDEBB4", "#FFCDD3", "#E4BDDB"]
  var text_colors = ["#5BC2A8", "#FCD450", "#EF9A9B", "#C361B1"]

  const handleImageLoad = (event) => {
    let imageWidth = event.target.clientWidth;
    let imageHeight = event.target.clientHeight;
    // if(imageWidth/imageHeight >=4) {
    //   event.target.style.opacity = 0.2;
    // }
    if (imageWidth / imageHeight >= 1.5) {
      event.target.closest("div").style.padding = '0';
    }
  }

  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const handleProfileCardImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 0.75) {
      event.target.closest("#leftSectionCardId").style.maxWidth = `${imageWidth}px`
    } else if (imageWidth / imageHeight >= 1) {
      event.target.closest("#imageWrapper").style.justifyContent = "center";
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionCardId").style.backgroundColor = "rgba(0,0,0,0)";
    event.target.closest("#leftSectionCardId").nextSibling.style.opacity = 1;
  }

  var array_name = props.hit.user_first_name ? (props.hit.user_first_name + " " + props.hit.user_last_name).split(" ") : "";
  var k = 0, name_sentence_case = "";
  for (; k < array_name.length; k++) {
    if (array_name[k])
      name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
  }

  // Invite button functionality starts
  let cid = localStorage.getItem('company_id');
  const [applicantStatus, setApplicantStatus] = React.useState(props.hit.status);
  const [gotHireResponse, setGotHireResponse] = React.useState(true);


  React.useEffect(() => {
    setApplicantStatus(props.hit.status);
  }, [props.hit])

  // capitalize first letter of each word
  const capitalizeString = (str) => {
    if (str.includes(" ")) {
      let str_array = str.split(" ");
      let k = 0;
      for (; k < str_array.length; k++) {
        str_array[k] = str_array[k].charAt(0).toUpperCase() + str_array[k].substr(1).toLowerCase();
      }
      return str_array.join(" ");
    } else {
      return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    }
  }

  // Invite button functionality ends

  const history = useHistory();

  //const isSignedIn =  signInStatus();
  const signInStatus = global_isSignedIn.isSignedIn;

  // return (page_global.page <= page_global.nb_hits ) ? (

  const changeStatus = (status) => {
    axios.post(`${BASE_URL}/jobposting/updateApplliedJobStatus`, {
      _id: props.hit._id,
      status: status
    })
      .then(response => {
        // console.log(response)
        if (response.status === 200) {
          setApplicantStatus(status);
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const length = invitedData.length;
  var invitedRolesList = [];
  if (invitedData.length > 2) {
    invitedRolesList.push(invitedData[0].job_role);
    invitedRolesList.push(invitedData[1].job_role);
  } else {
    invitedData.forEach(item => {
      invitedRolesList.push(item.job_role);
    })
  }

  function getSkills(skills) {
    return skills.map((item, index) =>
      <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.2em 0', flexDirection: 'column' }}>
        <Grid container item alignItems='center' justifyContent='center' wrap='nowrap' style={{ marginRight: '10px' }}>
          <Typography style={{
            textTransform: 'capitalize'
            // width: `${item.skill_name.length > 23 ? '140px' : '120px'}`
          }} className={classes.chip}>
            <span>
              {item.skill_name}
            </span>
            {/* <Rating name="read-only" value={item.rating} style={{ fontSize: '0.9rem' }} readOnly /> */}
            <span style={{ fontSize: '0.8rem', display: 'flex', alignItems: 'center', marginLeft: '3px' }}>
              <StarRateIcon style={{ fontSize: '0.8rem' }} />
              {item.rating}
            </span>
          </Typography>
        </Grid>
      </div>
    )
  }

  return (
    <>
      <div id="cardWrapper" className={classes.root}>
        {props.cardViewLoader && <Loader minHeight='70vh' maxWidth='42px' />}
        {/* <SnackBarCustom canSnackBarOpen={canSnackBarOpen} text={snackBarText} refresh={refreshSnackBar} /> */}
        {/* <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert> */}
        {<div>
          {!props.cardViewLoader && <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ position: 'absolute', bottom: '-16px', paddingLeft: '40%' }}>

            <ToggleButton
              id="invite_uninvite_card_btn_id"
              value="check"
              className={classes.bigIconRect}
              style={{ marginRight: 10, width: 'auto' }}
              classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
              // selected={invited}
              onChange={(e) => {
                AnalyticsHandler.track("CompanyPage_followers_emailInviteBtn")
                if (props.hit.invite_jobseekers.length > 0) {
                  props.setInvitedRoles(props.hit.invite_jobseekers)
                } else {
                  props.setInvitedRoles([])
                }
                props.setOpenRolesList(true);
                props.setOpenInviteModal(true);
                props.setJobseeker_id(props.hit.jobseeker_id);
                props.setJobseekerName(props.hit.user_last_name ? props.hit.user_first_name + ' ' + props.hit.user_last_name : props.hit.user_first_name)
              }}
            >
              {invited ?
                ''
                : <EmailIcon className={classes.saveIconRect} />
              }
              {invited ? "Take Next Steps" : "Email Invite"}
            </ToggleButton>
            <Button
              className={classes.bigIconRect}
              classes={{ label: classes.tglBtnLabel }}
              style={{ width: 'auto' }}
              onClick={() => {
                AnalyticsHandler.track("CompanyPage_followers_fullProfileBtn");
                // props.hit.invite_jobseekers.length && localStorage.setItem('searched_role', props.hit.invite_jobseekers[0].job_role)
                window.open(`/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit.jobseeker_id}`, "_blank");
              }}
            ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> Full Profile</Button>


          </Grid>}

          <div className={classes.textOverlay}></div>

          <Paper id="paper" className={classes.paper} elevation={3}>
            <Grid container direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.profileSection}
              wrap='nowrap' >
              <Grid xs={5} item className={classes.leftSection} id="leftSectionCardId">
                <div className={classes.imageWrapper} id="imageWrapper">
                  {props.hit.user_image_url ?
                    <img className={classes.img} alt="candidates profile picture" src={props.hit.user_image_url} onLoad={handleProfileCardImage} style={{ opacity: 0 }} />
                    :
                    <div ref={imgPholderRef} className={classes.imgPholder} style={{ backgroundColor: `${bg_colors[count.counter]}` }}>
                      <p style={{ fontSize: 72, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 150, height: 150, lineHeight: '150px', textAlign: 'center', borderRadius: '50%', color: `${text_colors[count.counter]}` }}> {initials} </p>
                    </div>
                  }
                </div>
              </Grid>
              <Grid item xs={7} sm container direction="column"
                justifyContent="center"
                alignItems="center"
                ref={righSectionRef}
                style={{ paddingTop: 50, paddingBottom: 50, opacity: 0 }}>

                <Grid item xs={10} container direction="column" justifyContent="center" alignItems="flex-start">

                  {/* <Grid item xs={10} container direction="column" justifyContent="center" alignItems="flex-start"> */}
                  <Grid item xs={12} wrap='nowrap' container direction="row" justifyContent="flex-start" alignItems="baseline" style={{ marginBottom: 30, marginLeft: 38 }}>
                    <Typography variant="h6" style={{ marginRight: 7, width: 'auto' }}>
                      {name_sentence_case}
                    </Typography>

                    <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10, display: 'flex', width: 'auto' }}>
                      {props.hit.city && <span style={{ fontSize: 16, marginRight: 4, color: '#ccc' }}>|</span>}
                      <span style={{ position: 'relative', marginRight: 8 }}>
                        {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} /> : null}</span>
                      <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                        {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                      </span></span>
                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} /> : null}
                  </Grid>
                  {props.hit.invite_jobseekers.length !== 0 ?
                    <Grid item xs wrap='nowrap' container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ padding: '0 0 1rem 0', marginLeft: 28, width: '90%' }}
                    >
                      <CheckIcon className="primaryText" style={{ fontSize: 14, paddingRight: 2, paddingTop: 2 }} />
                      <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                        Invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>
                          {invitedRolesList.map((item, i) => {
                            if (i < invitedRolesList.length - 1) {
                              return item + ", "
                            } else {
                              return item
                            }
                          })}
                          {length > 2 ? " " + "+" + (length - 2) + " " + (length === 3 ? "other" : "others") : ""}
                        </span>
                      </Typography></Grid> : null
                  }

                  {/* {fresher?
              <Chip size="small" label={"Total Exp: " + (fresher ? 'Fresher' : less_than_month ? " < 1 month " : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display)))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginBottom: 28 }} />
              : null } */}

                  {/* {((props.hit.user_experiences.length == 1 && props.hit.user_qualifications.length == 1) || (props.hit.user_experiences.length == 0 && props.hit.user_qualifications.length == 1) || (props.hit.user_experiences.length == 1 && props.hit.user_qualifications.length == 0)) ? */}
                  <div>


                    {/* <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginBottom: 30, marginLeft: '27px' }}>
                    {((props.hit.area || props.hit.city) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ? <LocationOnRoundedIcon style={{ marginTop: 3, color: '#555', fontSize: 16 }} /> : null}
                   
                 <Typography variant="subtitle1" style={{ marginLeft: 5, fontWeight: 400, fontSize: 14 }}>{props.hit.area ? props.hit.area : null} {props.hit.area && props.hit.city ? ", " : null} {props.hit.city ? props.hit.city : null}</Typography>
                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null ? <Chip size="small" label={(getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) < 60 ? (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) + " km" : "> 60 " + " km "} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                  </Grid> */}


                    <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 0 }}>
                      <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{ maxWidth: 20, marginRight: 10, marginLeft: 28 }}>
                        <WorkIcon fontSize="small" className="primaryText" style={{ marginTop: 1, marginBottom: 12 }} />
                      </Grid>
                      <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 5 }}>
                          <Typography variant="subtitle1" style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Experience</Typography>
                          {fresher ?
                            <Chip size="small" label={(fresher ? 'Fresher' : less_than_month ? " < 1 month " : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display)))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} />
                            : null
                          }

                        </Grid>
                      </Grid>
                    </Grid>

                    {experiences_sorted && experiences_sorted.map((item, i) => (
                      <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12 }} key={i}>
                        <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-end" style={{
                          maxWidth: 20,
                          // marginRight: 79, 
                          // marginLeft: '-40px',
                          marginRight: 58,
                          marginLeft: '-20px',
                          marginTop: 8,
                        }}>
                          <div className={classes.card__LogoWrapper_reg}>
                            {item.company_logo ? <img className={classes.img} alt="" src={item.company_logo ? item.company_logo : null}
                              // onError="this.style.display='none'" 
                              onLoad={handleImageLoad} /> : ""}
                          </div>
                        </Grid>
                        <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">
                          <Typography variant="subtitle1" style={{ marginLeft: 0, fontWeight: 500 }}>

                            {/* changes made by kartikey */}
                            {item.job_role_display ? item.job_role_display : item.user_post ? item.user_post : ""}
                            {/* changes end here */}
                          </Typography>
                          <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 15 }}>

                            {/* changes made by kartikey */}
                            {item.company_name ? (item.company_name.includes(':') ? item.company_name : (item.company_name && item.company_location) ? item.company_name + ' : ' + capitalizeString(item.company_location) : item.company_name) : ""}
                            {/* changes end here */}

                          </Typography>
                          <Typography variant="subtitle1" style={{ marginLeft: 0, fontSize: 13, color: '#999' }}>
                            {item.company_starting_date && item.company_ending_date && item.company_ending_date == "Present" ? (item.company_starting_date.includes(" ") ? item.company_starting_date + " - " + "Present" : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + "Present") : ""}

                            {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                              ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) &&
                                (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                                  item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " - " + months[new Date(item.company_ending_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}

                            {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                              ((new Date(item.company_starting_date).getFullYear() == new Date(item.company_ending_date).getFullYear()) &&
                                (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                                  item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " , " + new Date(item.company_starting_date).getFullYear() : ""}

                            {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                              ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) &&
                                (new Date(item.company_starting_date).getMonth() != new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                                  item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}

                            {item.company_starting_date && item.company_ending_date && item.company_ending_date != "Present" &&
                              ((new Date(item.company_starting_date).getFullYear() != new Date(item.company_ending_date).getFullYear()) &&
                                (new Date(item.company_starting_date).getMonth() == new Date(item.company_ending_date).getMonth())) ? (item.company_starting_date.includes(' ') &&
                                  item.company_ending_date.includes(' ')) ? item.company_starting_date + ' - ' + item.company_ending_date : months[new Date(item.company_starting_date).getMonth()] + " " + new Date(item.company_starting_date).getFullYear() + " - " + months[new Date(item.company_ending_date).getMonth()] + "  " + new Date(item.company_ending_date).getFullYear() : ""}
                          </Typography>
                          <Typography variant="subtitle1"
                            style={{
                              marginLeft: 0, marginTop: 5, fontSize: 14,
                              // fontStyle: 'italic' 
                            }}>
                            {item.role_discription ? item.role_discription : ""}
                          </Typography>
                        </Grid>
                      </Grid>))}

                    <Grid item xs container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginBottom: 12, marginLeft: 27 }}>
                      <SchoolRoundedIcon fontSize="small" className="primaryText" style={{ marginTop: 1 }} />

                      <Grid item xs container direction="column" justifyContent="flex-start" alignItems="flex-start">

                        <Typography variant="subtitle1" style={{ marginBottom: 12, marginLeft: 10, fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em' }}>Education</Typography>

                        {
                          qualifications_sorted.map((item, i) => (
                            <div key={i} style={{ marginBottom: 10 }}>
                              <Typography variant="subtitle1" style={{ marginLeft: 10, fontWeight: 500, lineHeight: 1.5 }}>
                                {/* {item.course_type && (item.course_type == "UG Degree" || item.course_type == "PG Degree") ? (item.course_name || item.specialization_name ? (item.course_name ? item.course_name : "") + (item.specialization_name ? " - " + item.specialization_name : "") : "") : (item.course_name || item.specialization_name || item.course_type ? (item.course_type ? item.course_type : "") + (item.course_name ? " - " + item.course_name : "") + (item.specialization_name ? " - " + item.specialization_name : "") : "")} */}
                                {(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ? item.course_type :
                                  (item.course_abbreviation ? item.course_abbreviation :
                                    (item.course_name ? item.course_name : null))}
                                {(item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")) ?
                                  (item.course_name ? "—" + item.course_name : null) :
                                  (item.specialization_name ? "—" + item.specialization_name : null)}
                                {!item.user_college ? " | " + item.user_passing_year : ""}
                              </Typography>
                              <Typography variant="subtitle1" style={{ marginLeft: 10, fontSize: 15 }}>
                                {(item.user_college && item.user_passing_year ? item.user_college + " | " + item.user_passing_year : item.user_college)}
                              </Typography>
                            </div>))}

                      </Grid>
                    </Grid>
                    <Grid item xs container direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginBottom: '1em' }}
                >
                  <Grid item xs container justifyContent="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginLeft: 27 }} >
                    <SportsHockeyIcon fontSize="small" className="primaryText" />
                    <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Skills</Typography>
                  </Grid>
                  <Grid item xs container wrap='nowrap' alignItems="flex-start" direction='column' className={classes.sectionItem_content} style={{ margin: '5px 10px 0 25px', paddingLeft: '35px' }} >
                    {getSkills(props.hit.user_skills)}
                  </Grid>
                </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>}
      </div>
    </>
  )
}

export default ComplexGrid;