import React, { useRef, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styled from 'styled-components';
import {RolesNotToBePassedToAlgolia} from '../../Globals';

var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

const Input = styled('input')`
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.6);
}
::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.6);
}
:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0, 0, 0, 0.6);
}
:-moz-placeholder { /* Firefox 18- */
  color: rgba(0, 0, 0, 0.6);
}
&.qualsInput{
  z-index: 999; padding: 8px 0 20px;
}
`;

const Label = styled('label')`
  padding: 0 0 4px 4px;
  font-size: 0.75em;
  display: block;
  color: black;
  font-weight: 700;
  &.focused {
    color: #5BC2A8;
    font-weight: 700;
  }
`;

const InputWrapper = styled('div')`
  flex: 1;
  // height: 30px;
  font-size: 14px;
  border-color: transparent!important;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  max-height: 30px;
  overflow: hidden;

  &:hover {
    border-color: transparent!important;
  }

  &.focused {
    border-color: transparent!important;
    box-shadow: none;
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 10px 0;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    &.inputfocused {
      width: 200px;
      height: 30px;
      background: #F5F5F5;
      border-radius: 25px;
      padding: 9px 10px 9px 0;
      left: 25%;
      top: 326px;
      position: absolute;
    }
  }
  & .clearButton {
    min-height: unset;
    min-width: unset; 
    width: 20px;
    height: 20px; 
    margin: 4px 3px 0 3px; 
    padding: 4px;
    border-radius: 50%;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props} className="value-tags" style={{
    padding: '2px 4px',
    border: '1px solid #cacaca',
    borderRadius: 2,
    display: 'flex',
    height: 21,
    marginTop: 3,
  }}>
    <span style={{
      // maxWidth: 180,
      maxWidth: 165,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'noWrap'
    }}>{label}</span>
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('div')`
  //width: 900px;
  width: 971px; //for bigger box
  display: flex;
  flex-direction: column;
  margin: 2px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  //margin-top: 16px;
  margin-top: 0; //for bigger box
   //transform: translate3d(35px,75px,0);
  transform: translate3d(0,0,0); //for bigger box
  transition: max-height 0.1s ease-in;
  list-style: none;
  background-color: #fff;
  overflow: hidden;
  max-height: 325px;
  // max-height: calc(100vh - 120px); //for bigger box
  min-height: 325px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #000000 25%;
  z-index: 9999;
  &.expanded {
    //max-height: 390px;
    max-height: calc(100vh - 120px); //for bigger box
    width: 971px; //for bigger box
    margin-top: 0; //for bigger box
    transform: translate3d(0,-140px,0); //for bigger box
  } 
  &.expanded > .scroll > ul > li > div > ul{
    // display: inline-grid; //for bigger box
  }
  &.expanded > div > ul > li { //for bigger box having cols with vertical scroll
    & > div:nth-child(2) > ul {
      padding: 0 0 30px 5px;
      overflow-y: auto;
      height: calc(100vh - 263px);
    }
  }
  & .header {
    background: white; 
    width: 100%; 
    height: 50px; 
    border: 0.5px solid #E0E0E0;
    //padding: 10px 30px;
    padding: 10px 30px 10px 10px;//changed or search on right
    display: flex; 
    justify-content: space-between;
    align-items: center;
    & .searchbox {
      width: 240px;
      height: 30px;
      background: #F5F5F5;
      border-radius: 25px;
      display: flex;
      justify-content: flex-start;
      align-item: center;
      padding: 2px;
      & .searchIcon {
        color: #6B6B6B;
        font-size: 15px;
        padding: 4px 10px;
      }
      & input {
        background: #F5F5F5; 
        border: 0;
        &:focus {
          outline: none;
        }
      }
    }
    & .header__items span{
      padding: 0 0 0 20px;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      &:hover{
        color: #000000;
      }
      &.navHighlighted {
        color: #000000; 
      }
    }
  }

  & > div > ul {display: flex; padding: 20px 0px; margin: 0; width: 100%}
  & ul {list-style: none}
// course type column
  & > div > ul > li {
    padding: 0 10px;
    margin: 0; 
    & .columnTitle{
      margin-bottom: 8px;
      padding-left: 5px;
      font-weight: 700;
      font-size: 14px;
      color: rgba(0,0,0,0.8);
      width: 180px;
    }
    & > div:nth-child(2) > ul {
      padding: 0 0 0 5px;
      margin: 0; 
      display: row;
      grid-auto-flow: column;
      grid-template-rows: repeat(7, auto);
      //grid-template-rows: repeat(5, auto);
      & > li {
        width: auto;
        min-width: 270px;
        height: 30px;
        // min-width: 250px;
        // height: 44px;
        display: flex;
        align-items: flex-start;
        padding: 18px 10px 15px 0;
        &:first-child{
          padding-top: 10px;
        }
        & > span:first-child{
          padding: 0;
        }
        & > span:nth-child(2) {
          padding-top: 2px;
          line-height: 1.8em;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-content: center;
          font-size: 14px;
          font-weight: 400;
          & span {padding: 0; line-height: 1.2em; max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: noWrap;
          &.userCount {
            color: #7c7c7c; 
            font-size: 12px; 
            font-weight: 400;
            line-height: 1.55em;
            padding-left: 5px;
          }
          }
        }
      }
    }
  }
  & li{
    & svg {
      color: #E2E2E2;
    }
  }

  // & li[datacat]:hover {
  //   background-color: #e6f7ff;
  //   cursor: pointer;
  // }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    // & svg {
    //   color: #E2E2E2;
    // }
  }
  .coursesViewButton {
    min-height: unset;
    min-width: unset;
    width: 100px;
    height: 30px;
    margin: 10px;
    padding: 10px;
    //border-radius: 15px;
    border-radius: 5px;
    //color: #5BC2A8;
    background-color: #14967B;
    color: white;
    border: none;
  }
  .scroll {
    //width: 870px; 
    width: "auto"; //for bigger box
    display: flex; 
    overflow-x: auto;
    overflow-y: hidden; 
    flex: 1;
    margin: 0 15px;
    & .internalBox {
      width: 971; 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      flex: 1
    }
  }
  .sleekScroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    
  }
  .sleekScroll::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
    &:hover{
      background: #5BC2A8;
      cursor: pointer;
    }
  }
  
  .sleekScroll::-webkit-scrollbar-track {
    background: #fafafa;
  }

  p {
    margin: 0;
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 13px;
    line-height: 1.3em;
}

`;

const icon = <CheckBoxOutlineBlankIcon style={{ color: "#E2E2E2" }} fontSize="small" />;
const checkedIcon = <CheckBoxIcon style={{ color: "#5BC2A8" }} fontSize="small" />;

export default function QualificationsList2(props) {
  const [courses, setCourses] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tags_values, setTagsValues] = useState([]);
  const [pholder, setPholder] = useState('Search/choose educational qualifications');
  const [tab, setTab] = useState('');
  const [highlight, setlimelight] = useState('black');
  const scrollRef = useRef(null);
  const scrollRef_certificate = useRef(null);
  const scrollRef_ugdiploma = useRef(null);
  const scrollRef_pgdiploma = useRef(null);
  const scrollRef_ugdegree = useRef(null);
  const scrollRef_pgdegree = useRef(null);
  const scrollRef_iti = useRef(null);
  const executeScroll = () => { if (scrollRef.current) { scrollRef.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_certificate = () => { if (scrollRef_certificate.current) { scrollRef_certificate.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_ugdiploma = () => { if (scrollRef_ugdiploma.current) { scrollRef_ugdiploma.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_pgdiploma = () => { if (scrollRef_pgdiploma.current) { scrollRef_pgdiploma.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_ugdegree = () => { if (scrollRef_ugdegree.current) { scrollRef_ugdegree.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_pgdegree = () => { if (scrollRef_pgdegree.current) { scrollRef_pgdegree.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const executeScroll_iti = () => { if (scrollRef_iti.current) { scrollRef_iti.current.scrollIntoView({ behavior: "auto", block: "end", inline: "start" }) } };
  const courseListRef = useRef(null);

  const [search, setSearch] = React.useState('');
  const [noSearchResults, setNoSearchResults] = React.useState(false);
  const [sliceCourses, setSliceCourses] = React.useState(5);
  const [isViewAll, setIsViewAll] = React.useState(false);

  const [isFirstRender, setIsFirstRender] = React.useState(true);

  //if user select qualifications on landing page then init the tags on the page 2
  const setInitTags = () => {
    if (props.quals && isFirstRender) {
      setTagsValues(props.quals);
      //props.setQualifications(props.quals); 
      setIsFirstRender(false);
    }
  }
  setInitTags()

  // remove duplicates
  let newArray = courses.filter((item, i, array) =>
    array.findIndex(t => t.qualification_id == item.qualification_id) == i
  )
  //alert("newarray")

  const groupByCat = (qualifications = []) => {
    let result = [];
    result = qualifications.reduce((r, a) => {
      if (a.course_type !== 'Ph.D' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
        r[a.course_type] = r[a.course_type] || [];
        r[a.course_type].push(a);
      }
      return r;
    }, Object.create(null));
    return result;
  };

  let newHashMap = groupByCat(newArray);

  const groupByCourse = (qualifications = []) => {
    let result = [];
    result = qualifications.reduce((r, a) => {
      if (a.course_type !== 'Ph.D' && a.course_type !== undefined && a.course_type !== 'NA' && a.course_name !== undefined && a.course_name !== 'NA' && a.specialization_name !== 'NA') {
        r[a.course_name] = r[a.course_name] || [];
        r[a.course_name].push(a);
      }
      return r;
    }, Object.create(null));
    return result;
  };

  let courseHashMap = groupByCourse(newArray);

  let idealOrder = ['ITI', 'UG Diploma', 'UG Degree', 'PG Degree', 'PG Diploma', 'School', 'Certification']
  const sorter = (a, b) => {
    return idealOrder.indexOf(a) - idealOrder.indexOf(b);
  };

  let hashMap1 = Object.keys(newHashMap);

  hashMap1.sort(sorter);

  let courseList = hashMap1.map((key_category, index) => (
    <li key={index} data-cat-col={key_category}
    // style={{display: isCatDisplayed[index] ? "list-item": "none"}}
    >
      <div className="columnTitle" ref={key_category === "School" ? scrollRef : (key_category === 'Certification' ? scrollRef_certificate : (key_category === 'UG Diploma' ? scrollRef_ugdiploma : (key_category === 'PG Diploma' ? scrollRef_pgdiploma : (key_category === 'UG Degree' ? scrollRef_ugdegree : (key_category === 'PG Degree' ? scrollRef_pgdegree : scrollRef_iti)))))} style={{ color: tab === key_category ? highlight : (tab !== key_category && tab.length > 0 ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)') }}>{key_category}</div>
      <div>
        <ul ref={courseListRef} className="sleekScroll">
          {newHashMap[key_category].sort((a, b) => {
            if (a.QID_count && b.QID_count) {
              return a.QID_count < b.QID_count ? 1 : -1
            }
            // }).slice(0, sliceCourses).filter(item =>
          }).filter(item =>
            item['course_name'].toLowerCase().includes(search.toLowerCase()) || (item['specialization_name'] && item['specialization_name'].toLowerCase().includes(search.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].toLowerCase().includes(search.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].replace(/\W|_/g, '').toLowerCase().includes(search.toLowerCase()))
          ).map((key_course, index) => {
            return (
              <React.Fragment key={key_course['qualification_id']}>
                {index === 0 &&
                  <li datacat={key_category} key={key_category + index} title={"Any"} style={{ color: tab === key_category ? highlight : (tab !== key_category && tab.length > 0 ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)') }}
                  >
                    <Checkbox
                      style={{ marginRight: 8 }}
                      icon={icon}
                      checkedIcon={checkedIcon}
                      name={key_category}
                      onChange={(e) => {
                        let objKey = key_category.replace(" ", "");
                        if (e.target.checked == true) {
                          if (isRecheck === false) {
                            if (tags_values.length === 0 && props.qualifications && props.qualifications.length !== 0) { //at the time of clicking the checkbox for first time if tags are not there even if qualifications exist then make qualifications empty and set auto quals flag to false
                              if (props.setCurrentQualifications) { props.setCurrentQualifications([]); }
                              if (props.setAutoQualsFlag2) props.setAutoQualsFlag2(false);
                            }

                            setIsAnyChecked(prevState => ({ ...prevState, [objKey]: true }))
                            checkAllCourses(e);

                          }
                        } else if (e.target.checked == false) {
                          setIsAnyChecked(prevState => ({ ...prevState, [objKey]: false }))
                          if (isUncheckAllEnabled) {
                            uncheckAllCourses(e);
                          }

                        }
                      }}
                    />
                    <span >
                      <span className="coursename">
                        {"Any"}
                      </span>
                    </span>
                  </li>
                }

                <li datacat={key_category} key={key_course['qualification_id']} title={key_course['specialization_name'] ? key_course['course_name'] + ' — ' + key_course['specialization_name'] : key_course['course_name']} style={{ color: tab === key_category ? highlight : (tab !== key_category && tab.length > 0 ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,1)') }}
                >
                  <Checkbox
                    style={{ marginRight: 8 }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    name={key_course['course_name'] + key_course['qualification_id']}
                    onChange={(e) => {
                      if (e.target.checked == true) {
                        if (isRecheck === false) {
                          if (tags_values.length === 0 && props.qualifications && props.qualifications.length !== 0) { //at the time of clicking the checkbox for first time if tags are not there even if qualifications exist then make qualifications empty and set auto quals flag to false
                            if (props.setCurrentQualifications) { props.setCurrentQualifications([]); }
                            if (props.setAutoQualsFlag2) props.setAutoQualsFlag2(false);
                          }

                          setTagsValues(prevState => [...prevState, { tag_name: key_course['course_name'], tag_spl: key_course['specialization_name'], tag_id: key_course['qualification_id'], tag_abb: key_course['course_abbreviation'], tag_courseType: key_category }]);

                          if (props.setLoginQualifications) {
                            props.setLoginQualifications(prevState => (
                              [...prevState, { tag_name: key_course['course_name'], tag_spl: key_course['specialization_name'], tag_id: key_course['qualification_id'], tag_abb: key_course['course_abbreviation'], tag_courseType: key_category }]
                            ));
                          } else if (props.setCurrentQualifications) {
                            props.setCurrentQualifications(prevState => (
                              [...prevState, { tag_name: key_course['course_name'], tag_spl: key_course['specialization_name'], tag_id: key_course['qualification_id'], tag_abb: key_course['course_abbreviation'], tag_courseType: key_category }]
                            ));
                          }
                        }
                        //debugger;
                      } else if (e.target.checked == false) {

                        setTagsValues(tags_values.filter(item => item['tag_id'] != key_course['qualification_id']))
                        if (props.setLoginQualifications) {
                          props.setLoginQualifications(prevState => (
                            prevState.filter(item => item['tag_id'] != key_course['qualification_id'])
                          ));
                        } else if (props.setCurrentQualifications) {
                          props.setCurrentQualifications(prevState => (
                            prevState.filter(item => item['tag_id'] != key_course['qualification_id'])
                          ));
                        }
                      }
                    }}
                  />
                  <span >
                    <span className="coursename">
                      {key_course['course_abbreviation'] ? key_course['course_abbreviation'] : key_course['course_name']} <span style={{ fontSize: 13 }}> {key_course['specialization_name'] && ('— ' + key_course['specialization_name'])}
                      </span>
                    </span>
                    {/* <span className="userCount">{'(' + key_course['QID_count'] + ')'}</span> */}
                  </span>
                </li>

              </React.Fragment>
            )
          }
          )}
        </ul>
      </div>
    </li>
  ))

  const handleClose = () => {
    if (document.querySelector('.value-tags')) {
      setPholder('');
    } else if (!document.querySelector('.value-tags')) {
      setPholder('Search/choose educational qualifications');
    }
    //clear quals box search input on close
    document.querySelector('.searchInput').value = '';
    setSearch('');
    setNoSearchResults(false);
    // reset the display of all the course category columns
    let courseCols = document.querySelectorAll('li[data-cat-col]');
    for (let i = 0; i < courseCols.length; i++) {
      courseCols[i].style.display = "list-item"
    }

    document.querySelector(".box_label").classList.remove('focused')
    document.querySelector(".listbox").style.display = "none";
  };

  const handleSearchFilter = (e) => {
    setSearch(e.target.value);
    let search = e.target.value;

    let courseListResult = hashMap1.map((key_category, index) => {
      let arr = newHashMap[key_category].filter(item =>
        item['course_name'].toLowerCase().includes(search.toLowerCase()) || (item['specialization_name'] && item['specialization_name'].toLowerCase().includes(search.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].toLowerCase().includes(search.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].replace(/\W|_/g, '').toLowerCase().includes(search.toLowerCase()))
      )
      //do not display the columns for which no result
      let catCol = document.querySelector('li[data-cat-col="' + key_category);
      if (catCol && arr.length === 0) {
        document.querySelector('li[data-cat-col="' + key_category).style.display = "none"
      } else if (catCol && arr.length > 0) {
        document.querySelector('li[data-cat-col="' + key_category).style.display = "list-item"
      }

      return arr.length //result for each category
    });

    if (courseListResult.every((elem) => elem === 0)) { //if no result at all then show no results
      setNoSearchResults(true);
    } else {
      setNoSearchResults(false);
    }

  }

  let isUncheckAllEnabled = true;

  const handleClearAll = () => {
    document.querySelector('.searchInput').value = '';
    setSearch('');
    // setTimeout(() => {
    tags_values.map((option, index) => {
      document.querySelector('input[name="' + option['tag_name'] + option['tag_id'] + '"]').click();
    });
    setTagsValues([]);

    // to uncheck the first option of any under every cat if it is checked
    hashMap1.map((category, index) => {
      let input_checkbox = document.querySelector('input[name="' + category + '"]');
      if (input_checkbox && input_checkbox.checked == true) {
        isUncheckAllEnabled = false; //so that other checkboxes under the cat do not get triggered after the any checkbox is clicked
        input_checkbox.click();
      }
      isUncheckAllEnabled = true;
    });



    if (props.setLoginQualifications)
      props.setLoginQualifications([])
    else
      if (props.setCurrentQualifications)
        props.setCurrentQualifications([]);
    // }, 0.5)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          handleClose();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    //alert("before setcourses")
    const fetchData = async () => {
      setIsLoading(true);

      const result = await axios(BASE_URL + '/users/get_aggregated_qualification_mapping/');
      setCourses(result.data);
      if (props.setTopCourses) {
        props.setTopCourses(result.data);
      }
      //alert("setcourses")
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    //console.log(tags_values.length)
    if (tags_values.length > 0) {
      setPholder('');
    } else if (tags_values.length <= 0) {
      setPholder('Search/choose educational qualifications');
    }
  }, [tags_values]);

  let isRecheck = false;
  const [isOpenClicked, setIsOpenClicked] = React.useState(false);

  const [isAnyChecked, setIsAnyChecked] = React.useState(props.isAnyQualCatChecked ? props.isAnyQualCatChecked : { ITI: false, UGDiploma: false, UGDegree: false, PGDegree: false, PGDiploma: false, School: false, Certification: false })

  useEffect(() => {
    tags_values.map((option, index) => {
      setIsOpenClicked(false)
      let input_checkbox = document.querySelector('input[name="' + option['tag_name'] + option['tag_id'] + '"]');
      if (input_checkbox && input_checkbox.checked == false) {
        isRecheck = true;
        input_checkbox.click();
      }
      isRecheck = false;
    });

    //to check the first option of any under every cat if it is checked
    hashMap1.map((category, index) => {
      let anyCheckbox = document.querySelector('input[name="' + category + '"]');
      let isAny = isAnyChecked[category.replace(" ", "")];
      if (isAny && anyCheckbox && anyCheckbox.checked == false) {
        isRecheck = true;
        anyCheckbox.click();
      }
      isRecheck = false;
    });

  }, [search, isOpenClicked])

  React.useEffect(() => {
    if (props.setIsAnyQualCatChecked) {
      props.setIsAnyQualCatChecked(isAnyChecked);
    }
  }, [isAnyChecked])

  const checkAllCourses = (e) => {// loop thru all cat specific courses and check them
    let cat = e.target.name;
    newHashMap[cat].map((option, index) => {
      let input_checkbox = document.querySelector('input[name="' + option['course_name'] + option['qualification_id'] + '"]');
      if (input_checkbox && input_checkbox.checked == false) {
        input_checkbox.click();
      }
    })
  }

  const uncheckAllCourses = (e) => {
    let cat = e.target.name;
    newHashMap[cat].map((option, index) => { // loop thru all cat specific courses and uncheck them
      let input_checkbox = document.querySelector('input[name="' + option['course_name'] + option['qualification_id'] + '"]');
      if (input_checkbox && input_checkbox.checked == true) {
        input_checkbox.click();
      }
    })
    // to clear the corresponding tags from the outer box 
    let tagsVar = tags_values.filter(item => item['tag_courseType'] !== cat)
    setTagsValues(tagsVar)
  }

  const trackInitialRenderRef = React.useRef(true);
  // do a custom role string search through the quals array whenever search button is clicked and a new role is set 
  //console.log(newArray)
  React.useEffect(() => {
    //alert("auto quals useeffect")
    if (trackInitialRenderRef.current) {
      trackInitialRenderRef.current = false;
      return;
    }
    // if searched role is there and no tags are set then it indicates that user has not selected the qualifcations. Run this function in this case of autoselection. 
    if (props.searchedRole && tags_values.length === 0 && courses.length !== 0) {
      //console.log(props.searchedRole);
      //console.log(newArray)
      // do a custom role string search through the quals array
      const matchedRolesArray = newArray.filter(item =>
        item['course_name'] && item['course_name'].toLowerCase().includes(props.searchedRole.toLowerCase()) || (item['specialization_name'] && item['specialization_name'].toLowerCase().includes(props.searchedRole.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].toLowerCase().includes(props.searchedRole.toLowerCase())) || (item['course_abbreviation'] && item['course_abbreviation'].replace(/\W|_/g, '').toLowerCase().includes(props.searchedRole.toLowerCase()))
      );

      // add tag_id key to the array items
      let matchedRolesArrayWithTagID = matchedRolesArray.map((item, index) => (
        { ...item, tag_id: item['qualification_id'] }))

      // set auto qualifications to be sent through location object from landing page to page 2
      if (props.setAutoQualifications) {
        //console.log("I am setting auto qualifications")
        if (RolesNotToBePassedToAlgolia.includes(props.searchedRole.toLowerCase().trim())) {
          props.setAutoQualifications([]); //let autoquals be empty if role is equal to all or fresher
        } else {
          props.setAutoQualifications(matchedRolesArrayWithTagID);
        }


        if (props.setRefreshAutoQualifications) props.setRefreshAutoQualifications(prev => !prev);
        // if (props.setAutoQualsFlag) props.setAutoQualsFlag(true); 
        if (props.setAutoQualsFlag2) props.setAutoQualsFlag2(true); // for loader and divider messages
      }
      //console.log(matchedRolesArrayWithTagID);
    }
  }, [props.triggerClick, courses]) // courses added in dependency for the cases of pg1 to pg2 when courses are still being loaded asynchronously so that newArray is not empty

  return (
    <div style={{ flex: 1, padding: '16px 0 15px 0' }} ref={wrapperRef}>
      <div className={props.classes ? props.classes.mobileQualboxContainer : ''} >
        <Label className={`${props.classes ? props.classes.qualLabel : ''} box_label`}>Preferred Qualification(s)</Label>
        <InputWrapper className={props.classes ? props.classes.qualInputWrapper : ''} onClick={() => { document.querySelector(".listbox").style.display = "flex"; document.querySelector(".searchInput").focus(); document.querySelector(".box_label").classList.add('focused'); setIsOpenClicked(true) }}>
          {tags_values.map((option, index) => {
            return index === tags_values.length - 1 ?
              <Tag key={index} data-tag-name={option['tag_name']} label={option['tag_abb'] && option['tag_spl'] ? option['tag_abb'] + ' — ' + option['tag_spl'] : (option['tag_abb'] && !option['tag_spl'] ? option['tag_abb'] : option['tag_name'])} />
              : null
          }
          )}
          {tags_values.length > 1 ? <span style={{ padding: '7px 2px' }}>+{tags_values.length - 1} More</span> : null}
          {tags_values.length > 0 ? <Button className='clearButton' size="small" variant="outlined" onClick={handleClearAll}> <CloseIcon style={{ fontSize: 14 }}></CloseIcon> </Button> : null}
          <Input className="qualsInput" data-name="course-input" placeholder={pholder}
          // style={{ zIndex: 999, padding: '8px 0 20px' }} 
          />
        </InputWrapper>
      </div>
      <Listbox className="listbox" style={{ display: "none", boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }} ref={trackInitialRenderRef}>
        <div className="header">

          <div className="header__items">
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_iti)}>ITI</span>
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }}
              onMouseLeave={(e) => { setTab('') }} onClick={executeScroll_ugdiploma}>UG Diploma</span>
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_ugdegree)}>UG Degree</span>

            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_pgdegree)}>PG Degree</span>
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }}
              onMouseLeave={(e) => { setTab('') }} onClick={executeScroll_pgdiploma}>PG Diploma</span>
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(e) => { executeScroll() }}>School</span>
            <span onMouseEnter={(e) => { setTab(e.target.innerHTML) }} onMouseLeave={(e) => { setTab('') }} onClick={(executeScroll_certificate)}>Certification</span>
          </div>

          <div>
            <div className="searchbox">
              <div className="searchIcon"><SearchIcon fontSize="small" /></div>
              <Input className="searchInput" type="text" placeholder="Search qualifications" onChange={handleSearchFilter} />
            </div>
          </div>

          <div onClick={handleClose}>
            <p style={{ fontSize: '16px', color: 'grey', cursor: 'pointer' }}>Close </p>
            {/* <CloseIcon style={{ color: 'grey', cursor: 'pointer' }}></CloseIcon> */}
          </div>
        </div>
        <div className="scroll sleekScroll">{isLoading ? <div className="internalBox">
          <CircularProgress style={{ color: '#ccc' }} /></div>
          :
          <ul>
            {/* {courseList} */}
            {noSearchResults ?
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <p style={{ fontSize: '16px', fontWeight: 500, marginBottom: 5 }}>No results found</p>
                <p style={{ fontSize: '14px' }}>Try to search with different letters or clear search.</p>
                <Button
                  className='coursesViewButton'
                  size="small"
                  variant="outlined"
                  style={{ width: 'auto', textTransform: 'unset', marginTop: 20 }}
                  onClick={(e) => {
                    document.querySelector('.searchInput').value = '';
                    setSearch('');
                    setNoSearchResults(false);
                  }}>Clear Search</Button>
              </div>
              : courseList}
          </ul>}
        </div>
        <div style={{ width: '100%', textAlign: 'center', display: noSearchResults ? 'none' : undefined }}>
          {isViewAll ? <Button className='coursesViewButton' size="small" variant="outlined" onClick={(e) => {
            // setSliceCourses(5); 
            setIsViewAll(false); document.querySelector('.listbox').classList.remove('expanded')
          }}>View Top 5</Button>
            :
            <Button className='coursesViewButton' size="small" variant="contained" onClick={(e) => {
              // setSliceCourses(newArray.length); 
              setIsViewAll(true); document.querySelector('.listbox').classList.add('expanded')
            }}> View All</Button>}
        </div>
      </Listbox>
    </div>
  );
}

