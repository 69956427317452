import React from 'react';
import { Typography, Grid, InputBase } from '@material-ui/core';

function JobHighlight(props) {
    const { classes, values, setValues } = props;
    return <Grid item xs={12} className={classes.my4} >
        <Typography gutterBottom variant='h5'>Video Job Description</Typography>
        <InputBase
            classes={{ root: classes.TfRoot }}
            className={classes.my3}
            multiline
            style={{ borderBottom: '1px solid black' }}
            fullWidth
            size='medium'
            required
            placeholder='Paste URL here...'
            onChange={e => setValues({ ...values, video_jd: e.target.value })}
            value={values.video_jd}
        />
        <span className={`${classes.helper_text}`}> Please upload a video as job description on <a href='https://drive.google.com/' rel='noreferrer' className={classes.vidJdLinks} target='_blank' >Google Drive</a> or <a href='https://youtube.com/' rel='noreferrer' className={classes.vidJdLinks} target='_blank' >Youtube</a> and paste the link here. </span>
    </Grid>;
}

export default JobHighlight;
