import React from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const filter = createFilterOptions();
var axios = require('axios');

var BASE_URL = process.env.REACT_APP_API_ROOT;

function Role(props) {
  const { classes, values, setValues, errors, setErrors } = props;

  const [active, setActive] = React.useState(false);
  const changeAlertVisibility = () => {
    setActive(prev => !prev);
  }
  const [Roles, setRoles] = React.useState([]);
  const [inputRoleValue, setInputRoleValue] = React.useState(props.role ? props.role : "");
  const [inputBlur, setInputBlur] = React.useState(true);

  const onRoleInputChangeHandler = (event, newInputValue, reason) => {
    setInputRoleValue(newInputValue);
  }

  React.useEffect(() => {
    axios.get(BASE_URL + '/select/get_jobroles').then((Result) => {
      let rolesArr = Result.data;
      setRoles(rolesArr);
    })
  }, []);

  return <Grid item xs={12} className={classes.my4} >
    <Typography gutterBottom variant='h5' classes={{ root: classes.flexbox }} className={`${classes.my2} ${classes.inputHeading}`} ><span>Choose Role <span style={{color: '#f44336'}}>*</span></span>
      <InfoIcon classes={{ root: classes.icon }} onClick={changeAlertVisibility} />
      <Alert icon={false} severity="success" className={active ? `${classes.show}` : `${classes.hidden}`}>
        Select the closest role from the list to your work title.
      </Alert>
    </Typography>
    <Autocomplete
      name="mapped_role"
      freeSolo
      options={Roles}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.job_role_name
      }
      }
      style={{ flex: 0.75 }}

      value={values.mapped_role}
      selectOnFocus
      clearOnBlur

      onChange={(event, newValue) => {
        event.preventDefault();
        setErrors({});
        if (typeof newValue === 'string') {
          setValues({ ...values, mapped_role: newValue });
          setInputBlur(false);
        } else if (newValue && newValue.inputValue) {
          setValues({ ...values, mapped_role: newValue.inputValue });
          setInputBlur(false);
        } else if (newValue) {
          setValues({ ...values, mapped_role: newValue.job_role_name });
          setInputBlur(false);
        } else {
          setValues({ ...values, mapped_role: "" });
          setInputBlur(false);
        }
      }}

      inputValue={inputRoleValue}

      onInputChange={onRoleInputChangeHandler}

      onBlur={(event) => {
        if (inputBlur && inputRoleValue !== "") {
          setValues({ ...values, mapped_role: inputRoleValue });
        }
      }}

      classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator }}

      renderInput={(params) => <TextField {...params} variant={window.innerWidth <= 600 ? 'outlined' : 'standard'} error={errors.mapped_role ? true : false} helperText={errors.mapped_role ? errors.mapped_role : ''} value="" placeholder="Search or add role e.g Sales Associate" classes={{ root: classes.TfRoot }}
        InputLabelProps={{
          shrink: true,
        }} />
      }

      renderOption={(option) => {
        if (option) {
          return (
            <Grid container alignItems="flex-start" direction="column">
              <Typography variant="body2" style={{ opacity: 1, color: 'black', textTransform: 'capitalize' }}>{option.job_role_name} </Typography>
            </Grid>
          )
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // add new input value at the top of the dropdown list if value is not empty or already exists in list
        if (params.inputValue !== '') {
          filtered.unshift({
            inputValue: params.inputValue,
            job_role_name: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
    />
    {/* <span className={classes.helper_text}>To select press Enter ↲ | To navigate results use ↑ ↓ </span> */}
  </Grid>;
}

export default Role;
