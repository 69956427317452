import error from "./error.png"
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    errorContainer: {
        width: 600,
        height: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        paddingTop: 30,
        '@media (max-width: 600px)': {
            width: 300,
            height: '90vh',
        },
        '& > div': {
            height: 'initial !important'
        }
    }
}))

export default function Error(props) {
    const classes = useStyles()

    const nextPath = (path) => {
        props.history.push(path);
    }

    return (
        <div className={classes.errorContainer}>
            <div style={{ width: '100%', height: '65%', overflow: 'hidden' }}>
                <img style={{ width: '100%', }} src={error} /></div>
            <Typography variant="h1" style={{ marginTop: 10 }}>404</Typography>

            <Typography variant="body2" style={{ marginTop: 0, marginBottom: 40, fontWeight: 200, fontSize: 16, color: 'grey', textTransform: 'uppercase' }}>Page not found</Typography>

            <IconButton aria-label="back home" style={{ background: 'rgb(20, 150, 123)', width: 200, height: 50, borderRadius: 10 }}

                onClick={() => nextPath('/')} >
                <span style={{ color: 'white', fontSize: 16, textDecoration: 'none' }}> Go back to Home Page </span>
            </IconButton>

        </div>
    )
}