import React from 'react'
import { Checkbox, FormControlLabel, Grid, IconButton, InputBase, Switch, Tooltip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

function ScreeningForm(props) {
    const { classes, values, setValues, customQuestions, setCustomQuestions } = props;
    const [count, setCount] = React.useState(0);

    const [questions, setQuestions] = React.useState([
        {
            name: 'location_agree',
            ques: "Are you ready to work at the mentioned location?"
        },
        {
            name: 'salary_agree',
            ques: "Are you ready to work for the mentioned salary?",
        },
        {
            name: 'why_good_fit',
            ques: "Why should we select you for this role? Tell us in detail how your education and/or experience will help you perform the mentioned work.",
        },
        {
            name: 'change_job_reason',
            ques: "Why do you want to change your current job?",
        },
        {
            name: 'salary_expectation',
            ques: "What are your salary expectations for this role?",
        },
        {
            name: 'own_laptop',
            ques: "Do you have a personal laptop/computer?",
        },
        {
            name: 'valid_dl_license',
            ques: "Do you have a valid driving license?",
        },
        {
            name: 'own_vehicle',
            ques: "Do you have a personal vehicle?",
        },
    ]);

    const newItem = {
        name: '',
        question: "",
    };

    let addQues;

    function handleChange(e, val) {
        if (e.target.checked) {
            setValues({ ...values, [val]: true })
        }
        else {
            setValues({ ...values, [val]: false })
        }
    }

    function addCustomQues() {
        setCount(prev => prev + 1)
        addQues = {
            name: count,
            question: <InputBase type='textarea' size='small' fullWidth classes={{ root: classes.TaRootSrcForm }} multiline rows={2} placeholder='Type here' required
                onChange={e => {
                    let ques = e.target.value;
                    let list = [...values.custom_questions];
                    newItem.question = ques;
                    list.push({ question: ques, isChecked: true })
                    // list[count]['question'] = ques;
                    setValues({ ...values, custom_questions: list })
                }}
                onBlur={addToList} />
        }
        setCustomQuestions([...customQuestions, addQues]);
    }

    function addToList() {
        newItem.name = addQues.name;
        setCustomQuestions([...customQuestions, newItem]);
    }

    function handleCustomQuesChange(e, val, key) {
        let list = [...values.custom_questions]
        if (e.target.checked) {
            list[val][key] = true
            setValues({ ...values, custom_questions: list })
        }
        else {
            list[val][key] = false
            setValues({ ...values, custom_questions: list })
        }
    }
    function editDone(index) {
        let customQuestionsArray = [...customQuestions];
        newItem.name = addQues.name;
        customQuestionsArray.splice(index, 1, newItem);
        setCustomQuestions(customQuestionsArray);
    }

    function editCustomQuestion(index) {
        let customQuestionsArray = [...values.custom_questions]
        addQues = {
            name: count,
            question: <InputBase type='textarea' size='small' defaultValue={customQuestions[index].question} fullWidth classes={{ root: classes.TaRootSrcForm }} multiline rows={2} placeholder='Type here' required
                onChange={e => {
                    let ques = e.target.value;
                    let list = [...values.custom_questions];
                    list.splice(index, 1, { question: ques, isChecked: true })
                    newItem.question = ques;
                    // list.push({ question: ques, isChecked: true })
                    // list[count]['question'] = ques;
                    setValues({ ...values, custom_questions: list })
                }}
                onFocus={() => {
                    let ques = values.custom_questions[index].question;
                    let list = [...values.custom_questions];
                    list.splice(index, 1, { question: ques, isChecked: true })
                    newItem.question = ques;
                    // list.push({ question: ques, isChecked: true })
                    // list[count]['question'] = ques;
                    setValues({ ...values, custom_questions: list })
                }}
                onBlur={() => editDone(index)} />
        }
        customQuestionsArray.splice(index, 1, addQues)
        setCustomQuestions(customQuestionsArray);
    }

    return (
        <>
            <Grid direction='column' style={{ marginTop: window.innerWidth <= 600 ? 0 : '-4em', maxWidth: 'fit-content' }} container>
                <Grid className={`${classes.my2} ${classes.sticky_heading} ${classes.inputHeading}`} container item direction='column'>
                    <Typography className={`${classes.inputHeading}`} gutterBottom variant='h5'>Screening Questions</Typography>
                    <span className={classes.helper_text}>Select the questions that apply for this role</span>
                </Grid>
                <Grid className={classes.my2} container item direction='column'>
                    {questions.map((item, index) => {
                        return <Grid key={index} className={`${classes.mb1} ${classes.ScreeningFormContainer}`} wrap='nowrap' justifyContent='space-between' item container>
                            <Grid style={{ width: '100%' }} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values[item.name]}
                                            onChange={(e) => handleChange(e, item.name)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                    label={item.ques}
                                />
                            </Grid>
                        </Grid>
                    })}
                    {customQuestions.length > 0 && customQuestions.map((item, index) => {
                        return <Grid alignItems='center' key={index} style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', padding: '1em 1em 1em 2em', margin: 'auto', width: '99%' }} className={classes.mb1} wrap='nowrap' justifyContent='space-between' item container>
                            <Grid style={{ width: '100%' }} item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={values.custom_questions.length > 0 && values.custom_questions[index] ? values.custom_questions[index].isChecked : false}
                                            onChange={(e) => handleCustomQuesChange(e, index, 'isChecked')}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                    label={item.question}
                                />
                            </Grid>
                            {typeof (item.question) === 'string' && <Grid style={{ cursor: 'pointer' }} item onClick={() => editCustomQuestion(index)}>
                                <EditOutlinedIcon color='primary' fontSize='small' />
                            </Grid>}
                        </Grid>
                    })}
                    {customQuestions.length < 3 && <Grid style={{ width: '100%' }} item>
                        <Tooltip title="You can add upto 3 more questions">
                            <Typography gutterBottom variant='body1' component='p' style={{ display: 'inline-flex' }} classes={{ body1: classes.my2 }} color='primary' onClick={addCustomQues} >
                                <AddIcon color='primary' classes={{ root: classes.icon }} /> <span className={classes.icon}>Add more questions</span>
                            </Typography>
                        </Tooltip>
                        {/* <span className={classes.helper_text}>You can add upto 3 questions</span> */}
                    </Grid>}
                </Grid>
            </Grid>
        </>
    )
}

export default ScreeningForm