import React, { useState, useEffect, useMemo } from "react";

import Button from '@material-ui/core/Button';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";

import { csv } from "d3-fetch";
import { scaleQuantize } from "d3-scale";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageLoader from '../discovery/PageLoader';
import LogoLoader from '../../assets/logo-loader.gif';
import useStyles from './styles'; 

//const geoUrl = "/districtMap.json";
//const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/countries/india/india-districts.json"
const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/india/india-districts.json"

// const colorScale = scaleQuantize()
//   .domain([1, 2000])
//   .range([
//     "#e0f2f1",
//     "#b2dfdb",
//     "#80cbc4",
//     "#4db6ac",
//     "#26a69a",
//     "#009688",
//     "#00897b",
//     "#00796b",
//     "#00695c",
//     "#57b2f9",
//     "#57b2f9",
//     "#57b2f9",
//     "#57b2f9",
//     "#57b2f9",
//     "#57b2f9",
//     "#57b2f9",
//     "#fefefe"
//   ]);

const colorScale = scaleQuantize()
  .domain([1, 2000])
  .range([
    "#57b2f960",
    "#57b2f965",
    "#57b2f970",
    "#57b2f975",
    "#57b2f980",
    "#57b2f985",
    "#57b2f990",
    "#57b2f995",
    "#57b2f999",
    "#57b2f9a0",
    "#57b2f9a5",
    "#57b2f9b0",
    "#57b2f9b5",
    "#57b2f9c0",
    "#57b2f9c5",
    "#57b2f9d0",
    "#fefefe"
  ]);

const MapChartJobs = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]); // list of required districts with their jobs count
  const [position, setPosition] = useState({ coordinates: [20.593684, 78.96288], zoom: 20 });
  const [maxValue, setMaxValue] = useState(0);
  const [markers, setMarkers] = useState([{ markerOffset: 3, name: "Ambala", count: 213, coordinates: [77.096695, 30.278180] },
  { markerOffset: 3, name: "Jind", count: 200, coordinates: [76.616666, 29.616668] },
  { markerOffset: 3, name: "Bhiwani", count: 206, coordinates: [76.033514, 28.899046] },
  // { markerOffset: 3, name: "Gurugram", coordinates: [77.026344, 28.457523] },
  { markerOffset: 3, name: "Gurugram", count: 450, coordinates: [77.226344, 28.2] },
  { markerOffset: 3, name: "Delhi", count: 370, coordinates: [77.1314900, 28.7519500] }]);
  const [origMarkers, setOrigMarkers] = useState([]);

  const [tooltipData, setTooltipData] = useState("");
  const [curDist, setCurDist] = useState("")
  const [totalCompaniesinDist, setTotalCompaniesinDist] = useState(0);
  const [totalSectors, setTotalSectors] = useState(0); // total sectors in the current district

  const [allCompCount, setAllCompCount] = useState(0); // count of all companies
  const [secCompCount, setSecCompCount] = useState({}); // sectorwise count of jobs

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  // vars to store count of jobs in dist groups
  let ambalaCount = 0;
  let jindCount = 0;
  let bhiwaniCount = 0;
  let gurugramCount = 0;
  let delhiCount = 0;

  // vars to store count of sectors in dist groups
  let sectorCount = { ambala: 0, bhiwani: 0, jind: 0, gurugram: 0, delhi: 0 }
  let _data = [];
  // let distCat = "";
  // vars for count of sector-wise jobs in the district groups
  let textilesSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let beautySecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let mechanicalSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let healthcareSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let itSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let secretarialSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }

  // to calculate the bubble size proportional to count of jobs in that dist group
  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0.5, 8]),
    [maxValue]
  );

  useEffect(() => {
    //names of the required districts as spelled/named in our DB
    // Yamuna Nagar   Sonepat   Gautam Buddh Nagar "New Delhi", "South East Delhi", "West Delhi", "East Delhi", "South Delhi", "South West Delhi", "North West Delhi", "Central Delhi", "North Delhi", "North East Delhi"  
    const ambala = ["Ambala", "Yamuna Nagar", "Kurukshetra", "Panchkula"];
    const jind = ["Jind", "Hisar", "Kaithal", "Karnal", "Panipat"];
    const bhiwani = ["Bhiwani", "Mahendragarh", "Rohtak"];
    const gurugram = ["Gurgaon", "Faridabad", "Palwal", "Rewari", "Mewat"];
    const delhi = ["New Delhi", "South East Delhi", "West Delhi", "East Delhi", "South Delhi", "South West Delhi", "North West Delhi", "Central Delhi", "North Delhi", "North East Delhi", "Jhajjar", "Sonepat", "Gautam Buddh Nagar", "Ghaziabad"]

    const requiredDists = ambala.concat(jind, bhiwani, gurugram, delhi); // list of all the required districts

    const _markers = [
      { markerOffset: 3, name: "Ambala", count: 0, coordinates: [77.096695, 30.278180] },
      { markerOffset: 3, name: "Jind", count: 0, coordinates: [76.616666, 29.616668] },
      { markerOffset: 3, name: "Bhiwani", count: 0, coordinates: [76.033514, 28.899046] },
      // { markerOffset: 3, name: "Gurugram", coordinates: [77.026344, 28.457523] },
      { markerOffset: 3, name: "Gurugram", count: 0, coordinates: [77.226344, 28.2] },
      { markerOffset: 3, name: "Delhi", count: 0, coordinates: [77.1314900, 28.7519500] }
    ];

    // map the sectors in our DB
    const textilesSec = ["tailoring", "textile production"];
    const beautySec = ["beautician", "fashion ddesigning", "footwear production", "salon manager"];
    const mechanicalSec = ["automobile production", "automobile service", "boiler operator", "draughtsman (mechanical)", "mechanical engineer", "electrician", "electronics technician", "draughtsman (civil)"];
    const healthcareSec = [];
    const itSec = ["data entry", "digital marketing", "computer teacher", "content writing", "customer support"];
    const secretarialSec = ["stenographer (hindi)", "stenographer (english)", "office assistant", "finance"]

    //get the districts data from our DB
    //fetch("/jobs.json")
    fetch("https://api.meetworks.in/jobposting/map/location")
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        data.map((district, index) => {
          let cur = requiredDists.find((item) => item.toLowerCase() === district.job_location.toLowerCase())
          let c = district.totalCount // count of jobs in this district
          let cs = district.job_roleCount // count of sectors in this district
          let s = district.job_roles; // individual count of sector-wise companies in this district
          let sectorNames = Object.keys(s);
          console.log(sectorNames)
          // if the specific sector category array includes the sector in the companies of a district, then add to the count
          const forLoop = (sector, count) => {
            let i;
            for (i = 0; i < sectorNames.length; i++) {
              if (sector.includes(sectorNames[i])) {
                //console.log(sectorNames[i])
                count += s[sectorNames[i]];
                //console.log(count)
              }
            }
          }

          if (cur) { //if the district is the same as any of the ones in requiredDists during this loop then add to the count and push to the array of _data of required districts
            if (ambala.some((item) => item === cur)) {
              ambalaCount += c; //add to count of jobs in ambala dist group
              // distCat = "ambala"
              sectorCount.ambala += cs; //add to count of jobs having sector mapping in ambala dist group

              let i;
              for (i = 0; i < sectorNames.length; i++) { //add to count of sector-wise jobs in ambala dist group
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.ambala += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.ambala += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.ambala += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.ambala += s[sectorNames[i]];
                }
                if (itSec.includes(sectorNames[i])) {
                  itSecCount.ambala += s[sectorNames[i]];
                }
                if (secretarialSec.includes(sectorNames[i])) {
                  secretarialSecCount.ambala += s[sectorNames[i]];
                }
              }
              // forLoop(beautySec, beautySecCount.ambala);


            } else if (jind.some((item) => item === cur)) {
              jindCount += c;
              // distCat = "jind"
              sectorCount.jind += cs; //add to count of jobs having sector mapping in jind dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.jind += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.jind += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.jind += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.jind += s[sectorNames[i]];
                }
                if (itSec.includes(sectorNames[i])) {
                  itSecCount.jind += s[sectorNames[i]];
                }
                if (secretarialSec.includes(sectorNames[i])) {
                  secretarialSecCount.jind += s[sectorNames[i]];
                }
              }
            } else if (bhiwani.some((item) => item === cur)) {
              bhiwaniCount += c;
              // distCat = "bhiwani"
              sectorCount.bhiwani += cs; //add to count of jobs having sector mapping in bhiwani dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.bhiwani += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.bhiwani += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.bhiwani += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.bhiwani += s[sectorNames[i]];
                }
                if (itSec.includes(sectorNames[i])) {
                  itSecCount.bhiwani += s[sectorNames[i]];
                }
                if (secretarialSec.includes(sectorNames[i])) {
                  secretarialSecCount.bhiwani += s[sectorNames[i]];
                }
              }
            } else if (gurugram.some((item) => item === cur)) {
              gurugramCount += c;
              // distCat = "gurugram"
              sectorCount.gurugram += cs; //add to count of jobs having sector mapping in gurugram dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.gurugram += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.gurugram += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  //console.log(sectorNames[i])
                  mechanicalSecCount.gurugram += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.gurugram += s[sectorNames[i]];
                }
                if (itSec.includes(sectorNames[i])) {
                  itSecCount.gurugram += s[sectorNames[i]];
                }
                if (secretarialSec.includes(sectorNames[i])) {
                  secretarialSecCount.gurugram += s[sectorNames[i]];
                }
              }
            } else if (delhi.some((item) => item === cur)) {
              delhiCount += c;
              // distCat = "delhi"
              sectorCount.delhi += cs; //add to count of jobs having sector mapping in delhi dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.delhi += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.delhi += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  //console.log(sectorNames[i])
                  mechanicalSecCount.delhi += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.delhi += s[sectorNames[i]];
                }
                if (itSec.includes(sectorNames[i])) {
                  itSecCount.delhi += s[sectorNames[i]];
                }
                if (secretarialSec.includes(sectorNames[i])) {
                  secretarialSecCount.delhi += s[sectorNames[i]];
                }
              }
            }

            _data.push({
              ["name"]: cur,
              ["count"]: c,
              // ["sectors"]: {
              //   "textile": textilesSecCount[distCat],
              //   "beauty": beautySecCount[distCat],
              //   "mechanical": mechanicalSecCount[distCat],
              //   "healthcare": healthcareSecCount[distCat]
              // }
            })
          }
        })
        //console.log(textilesSecCount, beautySecCount, mechanicalSecCount, healthcareSecCount)
        //console.log(_data)

        // making the count of jobs in the districts in a group the same as their total so that they can be given a same color in the map
        _data.map(((district, index) => {
          if (ambala.some((item) => item === district.name)) {
            district.count = ambalaCount
          } else if (jind.some((item) => item === district.name)) {
            district.count = jindCount
          } else if (bhiwani.some((item) => item === district.name)) {
            district.count = bhiwaniCount
          } else if (gurugram.some((item) => item === district.name)) {
            district.count = gurugramCount
          } else if (delhi.some((item) => item === district.name)) {
            district.count = delhiCount
          }
        }))
        //console.log(_data)

        //sorting the districts in the _data in descending order of number of jobs
        const sortedDistricts = sortBy(_data, (o) => -o.count);
        setMaxValue(sortedDistricts[0].count);
        setData(sortedDistricts)
        //console.log(sortedDistricts)

        //setting the count of jobs on the markers
        _markers.map((item, index) => {
          if (index === 0) {
            item.count = ambalaCount
            item.allCount = ambalaCount
            item.sectorCount = sectorCount.ambala
            item.sectors = {
              textile: textilesSecCount.ambala,
              beauty: beautySecCount.ambala,
              mechanical: mechanicalSecCount.ambala,
              healthcare: healthcareSecCount.ambala,
              it: itSecCount.ambala,
              secretarial: secretarialSecCount.ambala
            }
          } else if (index === 1) {
            item.count = jindCount;
            item.allCount = jindCount;
            item.sectorCount = sectorCount.jind
            item.sectors = {
              textile: textilesSecCount.jind,
              beauty: beautySecCount.jind,
              mechanical: mechanicalSecCount.jind,
              healthcare: healthcareSecCount.jind,
              it: itSecCount.jind,
              secretarial: secretarialSecCount.jind
            }
          } else if (index === 2) {
            item.count = bhiwaniCount;
            item.allCount = bhiwaniCount;
            item.sectorCount = sectorCount.bhiwani
            item.sectors = {
              textile: textilesSecCount.bhiwani,
              beauty: beautySecCount.bhiwani,
              mechanical: mechanicalSecCount.bhiwani,
              healthcare: healthcareSecCount.bhiwani,
              it: itSecCount.bhiwani,
              secretarial: secretarialSecCount.bhiwani
            }
          } else if (index === 3) {
            item.count = gurugramCount;
            item.allCount = gurugramCount;
            item.sectorCount = sectorCount.gurugram
            item.sectors = {
              textile: textilesSecCount.gurugram,
              beauty: beautySecCount.gurugram,
              mechanical: mechanicalSecCount.gurugram,
              healthcare: healthcareSecCount.gurugram,
              it: itSecCount.gurugram,
              secretarial: secretarialSecCount.gurugram
            }
          } else if (index === 4) {
            item.count = delhiCount;
            item.allCount = delhiCount;
            item.sectorCount = sectorCount.delhi
            item.sectors = {
              textile: textilesSecCount.delhi,
              beauty: beautySecCount.delhi,
              mechanical: mechanicalSecCount.delhi,
              healthcare: healthcareSecCount.delhi,
              it: itSecCount.delhi,
              secretarial: secretarialSecCount.delhi
            }
          }
        })
        setMarkers(_markers);
        setOrigMarkers(_markers); //to save the original markers data
        props.setOrigMarkers(_markers); //to save the original markers data in parent

        // get sectorwise companies count
        let _textilesSecCompCount = 0, _mechanicalSecCompCount = 0, _beautySecCompCount = 0,
          _healthSecCompCount = 0, _itSecCompCount = 0, _secretarialSecCompCount = 0
        for (const key in textilesSecCount) {
          _textilesSecCompCount += textilesSecCount[key]
        }
        for (const key in mechanicalSecCount) {
          _mechanicalSecCompCount += mechanicalSecCount[key]
        }
        for (const key in beautySecCount) {
          _beautySecCompCount += beautySecCount[key]
        }
        for (const key in healthcareSecCount) {
          _healthSecCompCount += healthcareSecCount[key]
        }
        for (const key in itSecCount) {
          _itSecCompCount += itSecCount[key]
        }
        for (const key in secretarialSecCount) {
          _secretarialSecCompCount += secretarialSecCount[key]
        }
        let a = ambalaCount + jindCount + bhiwaniCount + delhiCount + gurugramCount
        let b = {
          all: _textilesSecCompCount + _mechanicalSecCompCount + _beautySecCompCount + _healthSecCompCount + _itSecCompCount + _secretarialSecCompCount,
          textiles: _textilesSecCompCount,
          mechanical: _mechanicalSecCompCount,
          beauty: _beautySecCompCount,
          healthcare: _healthSecCompCount,
          it: _itSecCompCount,
          secretarial: _secretarialSecCompCount
        }
        setAllCompCount(a);
        setSecCompCount(b);

        props.setAllJobsCount(a);
        props.setSecJobsCount(b);
      })
      .catch((error) => { console.log(error) })
  }, [])

  // useEffect(() => {
  //   csv("/sample_jobs.csv").then(districts => {
  //     const sortedDistricts = sortBy(districts, (o) => -o.jobs_count);
  //     setMaxValue(sortedDistricts[0].jobs_count);
  //     setData(sortedDistricts);
  //   });
  // }, []);

  function exportTableToCSV(html, filename) {
    var csv = [
      ["Trade Group", "Total Count", "Tailoring/Textiles", "Beauty", "Mechanical", "Healthcare"],
      ["District Group"]];
    var rows = origMarkers;

    for (var i = 0; i < rows.length; i++) {
      var row = rows[i].name + "+" + "," + rows[i].allCount + "," + rows[i].sectors.textile + "," + rows[i].sectors.beauty + "," + rows[i].sectors.mechanical + "," + rows[i].sectors.healthcare
      csv.push(row);
    }
    let lastRow = [["Total", allCompCount, secCompCount.textiles, secCompCount.beauty, secCompCount.mechanical, secCompCount.healthcare]];
    csv.push(lastRow);

    // download csv file
    downloadCSV(csv.join("\n"), filename);
  }

  function downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], { type: "text/csv" });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  // Trade buttons click interaction starts
  const [activeTradeBtn, setActiveTradeBtn] = useState({ all: true, textile: false, beauty: false, mechanical: false, healthcare: false })

  const handleTradeGroupData = (trade) => {
    let _markers = origMarkers;
    if (!["all"].includes(trade)) {//add count on buttons except the all
      _markers.map((item, index) => {
        item.count = item.sectors[trade];
      })
    } else {//add count on the all button
      _markers.map((item, index) => {
        item.count = item.allCount;
      })
    }

    setMarkers(_markers)

    let _activeTradeBtn = activeTradeBtn;

    for (const key in _activeTradeBtn) {
      _activeTradeBtn[key] = false
    }

    // highlight the active btn
    if (trade === "all") {
      _activeTradeBtn.all = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "textile") {
      _activeTradeBtn.textile = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "mechanical") {
      _activeTradeBtn.mechanical = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "beauty") {
      _activeTradeBtn.beauty = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "healthcare") {
      _activeTradeBtn.healthcare = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "it") {
      _activeTradeBtn.it = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "secretarial") {
      _activeTradeBtn.secretarial = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    }
  }
  // Trade buttons click interaction ends

useEffect(()=>{
  if (props.tradeBtn === "") return;

  handleTradeGroupData(props.tradeBtn)
}, [props.tradeBtn])

  return (
    <>
        <div className={classes.mapContainer}>

          <PageLoader loaderMessage={"Generating Map..."} style={{ top: '-40px' }}></PageLoader>
          {/* <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white' }}>
            <img alt="meet animated logo loader" style={{ width: 60, height: 68 }} src={LogoLoader} />
          </div> */}

          {/* Trade btns */}
          <div style={{ position: "absolute", padding: 10, top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            className={`${classes.legend} ${activeTradeBtn.all ? classes.legend_pri : classes.legend_sec}`}
          >
            All <span>{allCompCount}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.beauty ? classes.legend_pri : classes.legend_sec}`}
          >
            Beauty <span>{secCompCount.beauty}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.it ? classes.legend_pri : classes.legend_sec}`}
          >
            Computer/IT <span>{secCompCount.it}</span>
          </div>
          
          <div
            className={`${classes.legend} ${activeTradeBtn.mechanical ? classes.legend_pri : classes.legend_sec}`}
          >
            Mechanical <span>{secCompCount.mechanical}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.secretarial ? classes.legend_pri : classes.legend_sec}`}
          >
            Secretarial <span>{secCompCount.secretarial}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.textile ? classes.legend_pri : classes.legend_sec}`}
          >
            Textiles <span>{secCompCount.textiles}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.healthcare ? classes.legend_pri : classes.legend_sec}`}
          >
            Other <span>{secCompCount.healthcare}</span>
          </div>
        </div>

          {/* Map */}
          <ComposableMap
            projection="geoMercator"
            projectionConfig={{
              scale: 1500,
            }}
          >
            <ZoomableGroup
              center={[76.65, 29.4]}// to change the center of map
              zoom={10}
              minZoom={10}
              onMoveEnd={handleMoveEnd}
            >
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    const cur = data.find(s => s.name === geo.properties.NAME_2);
                    const thisDist = geo.properties.NAME_2;
                    //vars to color the combined districts. the names of districts here match the ones in districtMap.json. e.g. Delhi is West
                    const ambala = ["Ambala", "Panchkula", "Kurukshetra", "Yamuna Nagar"];
                    const jind = ["Jind", "Hisar", "Kaithal", "Karnal", "Panipat"];
                    const bhiwani = ["Bhiwani", "Mahendragarh", "Rohtak"];
                    const gurugram = ["Gurgaon", "Faridabad", "Palwal", "Mewat", "Rewari"];
                    const delhi = ["Delhi", "Jhajjar", "Sonepat", "Ghaziabad", "Gautam Buddha Nagar"]
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        // fill={colorScale(cur ? cur.companies_number : "teal")}
                        fill={ambala.includes(thisDist) ? "#bdbdbd" :
                          jind.includes(thisDist) ? "#e0e0e0" :
                            bhiwani.includes(thisDist) ? "#c3cecd" :
                              gurugram.includes(thisDist) ? "lightgrey" :
                                delhi.includes(thisDist) ? "#b4bdbc" : "#fff"
                        }
                        stroke={cur ? "#f2f2f2" : "#f1f1f1"}
                        strokeWidth={0.1}
                      // onClick={() => { alert(thisDist) }}
                      />
                    );
                  })
                }
              </Geographies>

              {/* company markers */}
              {markers.map(({ name, count, sectors, sectorCount, coordinates, markerOffset }, index) => (
                <Marker key={name} coordinates={coordinates}>
                  <a id={"tooltip-anchor-jobs" + index}
                    onMouseEnter={() => {
                      setTooltipData(sectors);
                      setTotalCompaniesinDist(count);
                      setTotalSectors(sectorCount);
                      setCurDist(name)
                    }}
                    onMouseLeave={() => {
                      setTooltipData("");
                    }}
                  >
                    <circle fill={colorScale(count)} stroke="#FFF" strokeWidth={0.1} r={popScale(count)} />
                    <text
                      textAnchor="middle"
                      y={markerOffset}
                      x="1"
                      style={{ fontFamily: "sans-serif", fill: "#000", fontSize: '0.175rem', fontWeight: 600 }}
                    >
                      {name + "+"}
                    </text>
                    <text
                      textAnchor="middle"
                      y={markerOffset + 3.5}
                      x="1"
                      style={{ fontFamily: "sans-serif", fill: "#1d1d1d", fontSize: '0.2rem', fontWeight: 600 }}
                    >
                      {count}
                    </text>
                  </a>
                </Marker>
              ))}

            </ZoomableGroup>
          </ComposableMap>

          {/* tooltip for companies markers */}
          {markers.map((item, index) => (
            <Tooltip anchorId={"tooltip-anchor-jobs" + index} key={index}>
              <div>

                <h2 style={{ marginBottom: 3, marginTop: 2 }}>{curDist}</h2>

                <h4 style={{ marginBottom: 5, marginTop: 2 }}>Total Openings: <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}>{totalCompaniesinDist}</span></h4>

                <h4 style={{ marginBottom: 5, marginTop: 4 }}>Openings in various sectors</h4>
                <ul style={{ textAlign: 'left', marginTop: 5 }}>
                {/* <li style={{ fontWeight: 700 }}>Total
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem', fontWeight: 700 }}> {totalSectors}</span>
                </li> */}
                <li>Beauty
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.beauty}</span></li>
                <li>IT
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.it}</span></li>
                
                <li>Mechanical
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.mechanical}</span>
                </li>
                <li>Secretarial
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.secretarial}</span></li>
                <li>Tailoring/Textiles
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.textile}</span>
                </li>
                <li>Other
                  <span style={{ color: "#57b2f9", fontSize: '1.1rem' }}> {tooltipData.healthcare}</span></li>
              </ul>
              </div>
            </Tooltip>
          ))
          }
        </div>
      
      {/* download csv btn */}
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button
          variant="contained"
          style={{ width: 220 }}
          className={`${classes.headerButton} ${classes.headerButton_pri}`}
          onClick={() => { exportTableToCSV(null, 'companies.csv'); }}>
          Download CSV
        </Button>
      </div> */}

    </>
  );
};

export default MapChartJobs;
