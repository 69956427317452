import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkIcon from '@material-ui/icons/Work';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import AddLocationRoundedIcon from '@material-ui/icons/AddLocationRounded';
import MotorcycleRoundedIcon from '@material-ui/icons/MotorcycleRounded';
import LaptopChromebookRoundedIcon from '@material-ui/icons/LaptopChromebookRounded';
import SignalWifi4BarRoundedIcon from '@material-ui/icons/SignalWifi4BarRounded';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import Chip from '@material-ui/core/Chip';
import profile from '../../assets/welder.jpg';
import { Block, SignalCellularNull, SignalCellularNullSharp } from '@material-ui/icons';
import myInitObject from './location'
//import profile_image from './profile_image.png'
import count_list from './count_list'
import selected_qualifications from './quals';
import { green } from '@material-ui/core/colors';
import SnackBarCustom from './SnackBarCustom';
import SignInUpDrawer from '../../components/SignInUpDrawer'
//import { useSessionStorage } from '../../components/SessionStorageForInvite';
import ApiHandler from '../../ApiHandler';
import swal from 'sweetalert';
import FeedbackAlert from '../../components/FeedbackAlert';
//import qs from 'qs';
import { useHistory } from "react-router-dom";
import AnalyticsHandler from '../../AnalyticsHandler';
import { RolesNotToBePassedToAlgolia } from '../../Globals';
import { checkDateFormat } from '../../Globals';

var axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;
var Get_invite_status_URL = "/jobposting/verify_invite_jobseeker";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#555',
    color: '#fff',
    //boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 'unset',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: 300,
    marginBottom: '-18px',
  },
  paper: {
    boxSizing: 'border-box',
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 830,
    maxHeight: 300,
    width: 830,
    height: 300,
    overflowX: 'hidden',
    // overflowY: 'scroll',
    overflow: 'auto', // changed for firefox as the wide native scrollbar on the card there was causing the layout to overflow
    position: 'absolute',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& .imgPholder': {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  leftSection: {
    height: '100%',
    padding: 0,
    position: 'relative',
    background: '#D5F5ED',
    overflow: 'hidden',
    maxWidth: '23%',
    flexBasis: '23%',
  },
  namePlate: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 16,
    marginRight: '7px!important',
  },
  RightSection: {
    //height: '100%',
    position: 'relative',
    maxHeight: '100%',
    paddingTop: 10,
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  image: {
    width: '100%',
    height: 450,
    overflow: 'hidden'
  },
  img: {
    margin: '0 auto',
    display: 'block',
    width: 'auto',
    height: '100%',
    maxWidth: 'unset',
    //position: 'absolute',
  },
  imgPholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarWrapper: {
    width: '100%',
    height: 300,
    borderRadius: 0,
    //paddingTop: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
    margin: 0,
    '&>img': {
      transition: 'opacity 0.25s ease-in-out',
      pointerEvents: "none",
    },
  },
  verified: {
    color: '#289e80',
    marginTop: '-20px',
  },
  lastActive: {
    color: '#999',
    fontSize: 12,
  },
  inlineSubtext: {
    color: '#232323',
    fontSize: '13px',
    fontWeight: 300,
  },
  chipsWrapper: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  buttonSec: {
    flex: 1,
    marginRight: theme.spacing(1),
    textTransform: 'none',
    '& > *': {
      color: '#999'
    },
  },
  button: {
    flex: 1,
    textTransform: 'none',
  },
  expTimelineWrapper: {
    width: 600,
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  eduTimelineWrapper: {
    width: 600,
    position: 'relative',
    //marginLeft: '-26px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  timelineArrow: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    background: '#f6fffde3',
    color: '#555',
    position: 'absolute',
    top: 101,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    fontSize: 24,
    '&:hover': {
      background: 'white',
      color: '#5bc2a8',
      boxShadow: '0 0 8px rgba(0,0,0,0.15)',
    },
  },
  timelineArrow_L: {
    left: 10,
  },
  timelineArrow_R: {
    right: 10,
  },
  timelineArrow_Edu_L: {
    left: 0,
    top: 'unset',
    bottom: 29.5,
  },
  timelineArrow_Edu_R: {
    right: 0,
    top: 'unset',
    bottom: 29.5,
  },
  timelineList: {
    display: 'flex',
    marginTop: 10,
    marginLeft: '-12px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      fontSize: 12,
      position: 'relative',
      //width: 400,
      width: 300,
      '& .breakChar': {
        display: 'none',
      },
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 150,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        //display: 'none',
      },
      '&:last-child > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '72.5px',
          height: '0.5px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        transform: 'translateX(50%)',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 10%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
    '& > div:nth-child(1)': {
      '&.withBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.noBreak': {
        width: 300
      }
    },
    '& > div:last-child': {
      '&.withBreakSameMon': {
        '& > div:nth-child(1) > div:nth-child(2) > span::after': {
          display: 'none',
        },
      }
    },
    '& > div.withBreak': {
      width: '300px!important',
      '& > div:nth-child(2) > div:nth-child(2) > span::after': {
        display: 'block',
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
      '& .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreak + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'red',
        backgroundColor: '#e0e0e0',
      },
    },
    '& > div.withBreakSameMon': {
      width: '150px!important',
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
      '& > div:nth-child(1) > div:nth-child(2) > span::after': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      '&:not(:last-child) .breakChar': {
        display: 'block',
        color: '#b0b0b0',
      },
    },
    '& > div.withBreakSameMon + div': {
      '& > div:nth-child(1) > div:nth-child(2) > span::before': {
        //backgroundColor: 'purple',
        backgroundColor: '#e0e0e0',
      },
      // '& > div:nth-child(2) > div:nth-child(2) > span::after': {
      //   backgroundColor: 'purple',
      // },
    },
    '& > div.noBreakSameMon': {
      width: 150,
    },
    '& > div.noBreak': {
      width: 150,
    },
  },

  timelineListEdu: {
    display: 'flex',
    listStyle: 'none',
    marginTop: 10,
    marginLeft: '-37.3px',
    marginBottom: 8,
    fontSize: 12,
    fontWeight: 400,
    color: '#797979',
    '& > div': {
      // display: 'flex',
      // flexDirection: 'row',
      // alignItems: 'flex-start',
      // fontSize: 12,
      width: 200,
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
        textTransform: 'capitalize',
        width: 200,
      },
      '&:first-child > div:nth-child(1) > div:nth-child(2) > span::before': {
        display: 'none',
      },
      '&:last-child > div:nth-child(1) > div:nth-child(2) > span::after': {
        display: 'none',
      },
      '& > div > div:nth-child(2) > span': {
        position: 'relative',
        display: 'flex',
        width: 5,
        height: 5,
        marginBottom: 5,
        borderRadius: 5,
        backgroundColor: '#5bc2a8',
        '&:before, &:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          zIndex: '-1',
          top: '3px',
          transform: 'translateY(-50%)',
          MozTransform: 'translateY(0)',
          backgroundColor: '#999',
          width: '98px',
          height: '1px',
          maxWidth: '98px',
        },
        '&:before': {
          right: '5px',
        },
        '&:after': {
          left: '5px',
        },
      },
      '& > div > div:nth-child(3)': {
        width: '100%',
        textAlign: 'center',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        padding: '0 19%',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        height: 28,
        overflow: 'hidden',
      },
    },
  },

  timelineDuration: {
    fontSize: 10,
    color: '#797979',
    fontSize: 12,
    fontWeight: 300,
  },
  duration: {
    marginTop: 5,
    fontWeight: 400,
  },
  chiproot: {
    borderRadius: 4,
    height: 24,
  },
  chiplabel: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  bigIconRect: {
    position: 'relative',
    // marginLeft: '23%',
    margin: '0 20px 0 0',
    zIndex: 2,
    height: 35,
    width: 140,
    padding: 10,
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    border: '1px solid #14967B',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#b9f0e1',
      //color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  tglBtnLabel: {
    textTransform: 'capitalize'
  },
  tglBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
  },
  saveIcon: {
    height: 30,
    width: 30,
  },
  saveIconRect: {
    height: 24,
    width: 24,
    marginRight: 5,
  },
  infoIcon: {
    height: 20,
    width: 20,
    color: '#555',
  },
  bigIcon: {
    height: 46,
    width: 46,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#fab116',
    border: '1px solid #fab116',
    '&:hover': {
      backgroundColor: '#fab116',
      color: '#ffffff',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    }
  },
  popover: {
    pointerEvents: 'none',
  },
  paperPopover: {
    padding: theme.spacing(1),
    width: 200,
  },
  dialogBackdropRoot: {
    backgroundColor: "transparent"
  }
}));

var rad = function (x) {
  return x * Math.PI / 180;
};

var getDistance = function (p1, employer_lat_lng) {
  var R = 6378137; // Earth’s mean radius in meter
  var dLat = rad(employer_lat_lng.lat - p1.lat);
  var dLong = rad(employer_lat_lng.lng - p1.lng);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) * Math.cos(rad(employer_lat_lng.lat)) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in meter
};

export default function ComplexGrid(props) {
  //   if(!props.hit.user_experiences[0].job_role_display){
  //console.log(props.hit.user_qualifications)
  //   }

  const classes = useStyles();

  const history = useHistory();

  var years_to_display = (props.hit.total_months_exp / 12).toFixed(0) == 1 ? " year " : " years ";
  var months_to_display = props.hit.total_months_exp % 12 == 1 ? " month " : " months  ";

  var last_active = moment(new Date()).diff(moment(new Date(props.hit.last_seen_at * 1000)), 'days');

  var employer_lat_lng = {
    lat: myInitObject.lat,
    lng: myInitObject.long
  }

  var geoType = props.geographical_type;
  //console.log(geoType)

  var tagLabelDist = employer_lat_lng.lat && employer_lat_lng.lng && geoType ?
    (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1) == 0.0 ? "0" :
      (getDistance(props.hit._geoloc, employer_lat_lng) / 1000).toFixed(1)
    : null;

  var tagLabel = tagLabelDist ?
    geoType === "locality" && tagLabelDist < 60 ? tagLabelDist + " km from city center" :
      geoType !== "locality" && tagLabelDist < 60 ? tagLabelDist + " km from area center" :
        "> 60 km"
    : null;

  var qualifications_sorted = props.hit.user_qualifications ? props.hit.user_qualifications.sort((a, b) => new Date(a.user_passing_year) - new Date(b.user_passing_year)) : null;

  var quals_raw_data = []

  //  quals_raw_data = qualifications_sorted.filter((item, i, array) =>
  //     array.findIndex(t => t.course_name && item.course_name && t.course_name == item.course_name) == i
  //   );

  quals_raw_data = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.course_name && t.user_passing_year && item.course_name && item.user_passing_year && t.course_name == item.course_name && t.user_passing_year == item.user_passing_year) == i
  );

  var experiences_sorted = props.hit.user_experiences && props.hit.user_experiences.length != 0 ? props.hit.user_experiences.sort((a, b) => {

    if (a == {} || b == {})
      return 0;
    if (a.company_ending_date === b.company_ending_date)
      return 0;
    else
      if (a.company_ending_date === 'Present') {
        return (new Date() - new Date(b.company_ending_date))
      }
      else
        if (b.company_ending_date === 'Present') {
          return (new Date(a.company_ending_date) - new Date())
        }
        else {
          return (new Date(a.company_ending_date) - new Date(b.company_ending_date))
        }
  }).reverse() : null;

  //console.log("role in list::::::" + props.role);
  //var qualifications = selected_qualifications.quals;
  var qualifications = props.qualifications;
  //var role = selected_qualifications.role;
  var role = props.role;
  var secondaryRoles = props.secondaryRoles;
  var jobseekerEnteredRoles = props.jobseekerEnteredRoles;
  // console.log(jobseekerEnteredRoles)
  // var job_role_id = selected_qualifications.job_role_id;
  //var job_role_id  = props.job_role_id;
  //var preferredQuals = selected_qualifications.preferredQualsArray;
  var qualifications_to_search = props.hit.user_qualifications;
  var experiences_to_search = props.hit.user_experiences;
  var relevant_quals = false, relevant_to_show_role = false, relevant = false, relevant_role = false, relevant_to_show_quals = false, relevant_all = false;
  var k = 0;

  for (; k < qualifications.length; k++) {
    var item = qualifications[k].tag_id;
    var t = 0;
    for (; t < qualifications_to_search.length; t++) {
      if (item == qualifications_to_search[t].qualification_id) {
        relevant_quals = true;
        break;
      }
    }
  }
  var k = 0;

  // if (job_role_id == 3) {
  //   for (; k < experiences_to_search.length; k++) {
  //     if (experiences_to_search[k].job_role_id && (job_role_id == experiences_to_search[k].job_role_id)) {
  //       relevant_role = true;
  //       break;
  //     }
  //   }
  // }
  // else {

  for (; k < experiences_to_search.length; k++) {

    if (RolesNotToBePassedToAlgolia.includes(role.toLowerCase().trim())) break; // if role is all or fresher then relevant_role will always be false

    if (experiences_to_search[k].user_post && (role.toUpperCase().trim() == experiences_to_search[k].user_post.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }
    //check if any of the secondary roles exists in the user_post
    if (experiences_to_search[k].user_post && secondaryRoles && secondaryRoles.length !== 0 && secondaryRoles.some(item => item.title.toUpperCase().trim() === experiences_to_search[k].user_post.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }

    //check if any of the secondary roles exists in the user_post
    if (experiences_to_search[k].job_role_display && jobseekerEnteredRoles && jobseekerEnteredRoles.some(item => item.toUpperCase().trim() === experiences_to_search[k].job_role_display.toUpperCase().trim())) {
      relevant_role = true;
      break;
    }
  }
  // }


  relevant = relevant_quals && relevant_role;

  relevant_to_show_quals = (!relevant_role) && (relevant_quals);

  //relevant_to_show_role = (relevant_role) && (!relevant_quals)

  relevant_to_show_role = (relevant_role); // so that roles irrespective of quals set the flag to true

  relevant_all = (!relevant_role) && (!relevant_quals)

  // debugger;

  //var months_array = [], i = 0, item;
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // for (item in experiences_sorted)
  // {

  //   if (experiences_sorted[item] && experiences_sorted[item] != {}) {
  //     if (experiences_sorted[item].company_ending_date && experiences_sorted[item].company_starting_date) {

  //       var startDate = experiences_sorted[item].company_starting_date === 'Present' ? new Date() : new Date(experiences_sorted[item].company_starting_date);


  //       var endDate = experiences_sorted[item].company_ending_date === 'Present' ? new Date() : new Date(experiences_sorted[item].company_ending_date)

  //       var a = moment(endDate);
  //       var b = moment(startDate);

  //       var x = a.diff(b, 'months')

  //       months_array[i++] = {
  //         months: x,
  //         user_post: experiences_sorted[item].user_post
  //       }
  //     }

  //     else {
  //       months_array[i++] = {
  //         months: null,
  //         user_post: experiences_sorted[item].user_post
  //       };
  //     }
  //   }

  //   else {

  //     months_array[i++] = {
  //       months: null,
  //       user_post: null
  //     }

  //   }
  // }

  var total_years = Math.trunc(props.hit.total_months_exp / 12);
  var total_months = props.hit.total_months_exp % 12;
  var fresher = false;

  if ((total_years == 0 && total_months == 0) || props.hit.user_experiences == [] || props.hit.user_experiences == [{}] || props.hit.total_months_exp == null || props.hit.total_months_exp == 0)
    fresher = true;

  //debugger;

  var full_name = props.hit.jobseeker_name ? props.hit.jobseeker_name : "";
  var full_name_array = full_name.split(" ");
  var k = 0;
  var initials = "";

  for (; k < (full_name_array.length > 2 ? 2 : full_name_array.length); k++) {
    initials = initials + full_name_array[k].charAt(0).toUpperCase();
  }

  var bg_colors = ["#B9E4D9", "#FDEBB4", "#FFCDD3", "#E4BDDB"]
  var text_colors = ["#5BC2A8", "#FCD450", "#EF9A9B", "#C361B1"]

  var array_name = props.hit.jobseeker_name ? props.hit.jobseeker_name.split(" ") : "";
  var k = 0, name_sentence_case = "";
  for (; k < array_name.length; k++) {
    if (array_name[k])
      name_sentence_case += array_name[k].charAt(0).toUpperCase() + array_name[k].substr(1).toLowerCase() + " ";
  }

  const handleProfileImage = (event) => {
    let imageWidth = event.target.width;
    let imageHeight = event.target.height;

    if (imageWidth / imageHeight <= 190.9 / 300) {
      //event.target.style.height = (300 * (190.9 / imageWidth)) + "px"
      event.target.style.height = 'auto';
      event.target.style.width = '100%';
    }
    event.target.style.opacity = 1
    event.target.closest("#leftSectionId").style.backgroundColor = "rgba(0,0,0,0)";
  }

  let scrollableWidth = 0;
  const scrollTimeline = (e) => {
    let timeline = e.target.nextSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth += 400;
    if (scrollableWidth <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' });
      expArrowRRef.current.style.display = "flex";
      if (scrollableWidth + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }
  const scrollTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidth -= 400;

    if (scrollableWidth >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidth + 'px)' }, { duration: 500, fill: 'forwards' })
      expArrowLRef.current.style.display = "flex";
      if (scrollableWidth - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  let scrollableWidthEdu = 0;
  const scrollEduTimelineL = (e) => {
    let timeline = e.target.nextSibling;
    scrollableWidthEdu += 400;
    if (scrollableWidthEdu <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' });
      eduArrowRRef.current.style.display = "flex";
      if (scrollableWidthEdu + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }

  const scrollEduTimelineL_2 = (e) => {
    let timeline = e.target.nextSibling;
    scrollableWidthEdu += 400;
    if (scrollableWidthEdu <= 0) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' });
      eduArrowRRef_2.current.style.display = "flex";
      if (scrollableWidthEdu + 400 > 0) {
        e.target.style.display = "none"
      }
    }
  }

  const scrollEduTimelineR = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidthEdu -= 400;
    //console.log("TimelineWidth="+TimelineWidth)
    //console.log("scrollableWidthEdu="+scrollableWidthEdu)
    if (scrollableWidthEdu >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' })
      eduArrowLRef.current.style.display = "flex";
      if (scrollableWidthEdu - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }

  const scrollEduTimelineR_2 = (e) => {
    let timeline = e.target.previousSibling;
    let TimelineWidth = timeline.scrollWidth;
    scrollableWidthEdu -= 400;
    //console.log("TimelineWidth="+TimelineWidth)
    //console.log("scrollableWidthEdu="+scrollableWidthEdu)
    if (scrollableWidthEdu >= -TimelineWidth) {
      timeline && timeline.animate({ transform: 'translateX(' + scrollableWidthEdu + 'px)' }, { duration: 500, fill: 'forwards' })
      eduArrowLRef_2.current.style.display = "flex";
      if (scrollableWidthEdu - 405 <= -TimelineWidth) {
        e.target.style.display = "none"
      }
    }
  }
  const userEdu = qualifications_sorted.filter((item, i, array) =>
    array.findIndex(t => t.qualification_id && item.qualification_id && t.qualification_id == item.qualification_id) == i
  );

  const userExpFiltered =
    // (
    props.hit.user_experiences
  // && props.hit.user_experiences.length != 0 && props.hit.user_experiences.length > 1) ? props.hit.user_experiences.filter((item, i) => item.job_role_id === null || item.job_role_id !== 0) : props.hit.user_experiences;

  const userExpSorted = userExpFiltered && userExpFiltered.length != 0 ? userExpFiltered.sort((a, b) => {
    if (a == {} || b == {})
      return 0;
    if (a.company_starting_date === b.company_starting_date)
      return 0;
    else {
      return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
    }
  }) : null;

  let userExp = userExpSorted
    && userExpSorted.filter((item, i, array) =>
      array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
    )

  // const userExpSorted = props.hit.user_experiences && props.hit.user_experiences.length != 0 ? props.hit.user_experiences.sort((a, b) => {
  //   if (a == {} || b == {})
  //     return 0;
  //   if (a.company_starting_date === b.company_starting_date)
  //     return 0;
  //   else {
  //     return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
  //   }
  // }) : null;

  // let userExp = userExpSorted && userExpSorted.filter((item, i, array) =>
  //   array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
  // )

  // let userExpFiltered = props.hit.user_experiences && props.hit.user_experiences.length != 0 ? props.hit.user_experiences.filter((item, i, array) =>
  //     array.findIndex(t => t.company_name && t.user_post && item.company_name && item.user_post && t.company_name == item.company_name && t.user_post == item.user_post) == i
  //   ) : null
  //   const userExp = userExpFiltered && userExpFiltered.sort((a, b) => {
  //     if (a == {} || b == {})
  //       return 0;
  //     if (a.company_starting_date === b.company_starting_date)
  //       return 0;
  //     else {
  //       return (new Date(a.company_starting_date) - new Date(b.company_starting_date))
  //     }
  //   }) ;

  // userExp = userExp && userExp.filter(value => Object.keys(value).length !== 0)
  // userExp = userExp && userExp.filter(item => {return item.user_post})
  // userExp = userExp && userExp.filter(value => Object.keys(value) !== 0 && Object.keys(value) !== null && Object.keys(value) !== undefined)

  const expMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const expMonthsAndExp = userExp && userExp.map((item, i) => {
    if (item != {}) {
      if (item.company_ending_date && item.company_starting_date) {
        var startDate = checkDateFormat(item.company_starting_date);
        var endDate = checkDateFormat(item.company_ending_date);
        // var startDate = item.company_starting_date === 'Present' ? new Date() : new Date(item.company_starting_date);
        // var endDate = item.company_ending_date === 'Present' ? new Date() : new Date(item.company_ending_date);
        var startDateNext = userExp[i + 1] ? new Date(userExp[i + 1].company_starting_date) : null;
        var a = moment(endDate);
        var b = moment(startDate);
        var c = moment(startDateNext);
        var duration = a.diff(b, 'months');
        var dd = c.diff(a, 'days');
        var sameDaysDiff = a.diff(b, 'days')
        return {
          months: duration,
          sameDaysDiff: sameDaysDiff,
          daysDiff: dd,
          expStartMon: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(0, 3) : startDate.getMonth(),
          expEndMon: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(0, 3) : endDate.getMonth(),
          expStartYear: typeof (startDate) === 'string' && startDate.includes(' ') ? startDate.slice(4, 8) : startDate.getFullYear(),
          expEndYear: typeof (endDate) === 'string' && endDate.includes(' ') ? endDate.slice(4, 8) : endDate.getFullYear(),
          job_role_display: item.job_role_display,
        }
      }
      else {
        return {
          months: null,
          sameDaysDiff: null,
          daysDiff: null,
          expEndMon: null,
          expStartMon: null,
          expStartYear: null,
          expEndYear: null,
          job_role_display: item.job_role_display,
        };
      }
    }
    else {
      return {
        months: null,
        sameDaysDiff: null,
        daysDiff: null,
        expEndMon: null,
        expStartMon: null,
        expStartYear: null,
        expEndYear: null,
        job_role_display: null
      }
    }
  })

  const hasOverlapExps = userExp && userExp.length > 0 && userExp.map((item, i) => {
    if (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0) {
      return { overlap: 'true' }
    } else {
      return { overlap: 'false' }
    }
  })

  //check the invitedList for the presence of this jobseeker's id
  const invitedList = props.inviteList;
  const isInvited = invitedList.some(item => item.jobseeker_id === props.hit.objectID);
  const [selected, setSelected] = React.useState(false);
  const [gotResponse, setGotResponse] = React.useState(true);
  const [gotUninviteResponse, setGotUninviteResponse] = React.useState(true);

  useEffect(() => {
    //alert("invitedList= " + invitedList)
    if (props.signInStatus) {
      //alert("isInvited= " + isInvited)
      setSelected(isInvited);
    }
  }, []);

  // Invite button functionality starts
  let cid = localStorage.getItem('company_id');
  const [company_id, setCompanyId] = useState(cid);
  const [jobseeker_id, setJobseekerId] = useState(props.hit.objectID);
  const [job_role, setJobRole] = useState(props.role);
  const [location, setLocation] = useState(props.sendLocationForInvite);
  const [invitedRoles, setInvitedRoles] = React.useState([]);

  const [open, setOpen] = React.useState(false);

  const handleUninviteClickOpen = () => {
    setOpen(true);
  };

  const handleUninviteCancelClose = () => {
    setOpen(false);
  };

  const handleInviteClick = () => {
    //AnalyticsHandler.track("discoveryResults_list_InviteUninvite_btnClick");
    if (props.signInStatus) {
      if (selected === true) { //open confirmation dialog if uninvite is clicked
        handleUninviteClickOpen();
        return
      }

      let payload = {
        company_id,
        jobseeker_id,
        job_role,
        location,
        invite_status: !selected,
      }
      setGotResponse(false);
      //alert(props.signInStatus)
      ApiHandler.sendInvite(payload, response => {
        if (response.status == 200) {

          AnalyticsHandler.track("discoveryResults_list_Invite_success");
          if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
            setSelected(true);
            handleSnackBar();
            sessionStorage.setItem('isSnackbarDisplayed', true)
          }
          // else if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
          //   setSelected(false);
          // } 
          else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === false) {
            setSelected(true);
          }
          // else if (sessionStorage.getItem('isSnackbarDisplayed')) {
          //   setSelected(prev => !prev); alert("selected= " + selected)
          // }
          setGotResponse(true);
          updateInvitedJobRoles();
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              if (response.response.data.message === "Session Expire") {
                props.removeLocalStorageOnSessionExpiry();
              }
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
        }
      })
    } else if (props.signInStatus === false) {
      props.handleDrawer();
    }
  }

  const handleUninviteYesClose = () => {
    AnalyticsHandler.track("discoveryResults_list_Uninvite_btnClick");
    if (props.signInStatus) {
      let payload = {
        company_id,
        jobseeker_id,
        job_role,
        location,
        invite_status: !selected,
      }
      setGotUninviteResponse(false);
      //alert(props.signInStatus)
      ApiHandler.sendInvite(payload, response => {
        if (response.status == 200) {
          setOpen(false);
          if (!sessionStorage.getItem('isSnackbarDisplayed') && selected === true) {
            setSelected(false);
          } else if (sessionStorage.getItem('isSnackbarDisplayed') && selected === true) { //uninvite
            setSelected(false);
          }
          setGotUninviteResponse(true);
          updateInvitedJobRoles();
        }
        else {
          var ErrorMessage = 'Oops! Something went wrong. Please try again.';
          if (response.response) {
            let statusCode = response.response.status;
            if (statusCode === 500) {
              ErrorMessage = 'Oops! Something went wrong at our end. Please refresh the page and try again.';
            } else if (statusCode === 404) {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            } else if (statusCode === 400) {
              if (response.response.data.message === "Session Expire") {
                props.removeLocalStorageOnSessionExpiry();
              }
            } else {
              ErrorMessage = 'Oops! Something went wrong. Please try again.';
            }
          } else if (response.message === 'Network Error') {
            ErrorMessage = 'Looks like your internet is down. Try a different network or wait till your connection is restored.';
          } else {
            ErrorMessage = 'Oops! Something went wrong. Please try again.';
          }
          handleAlert(ErrorMessage, 'error');
        }
      })
    } else if (props.signInStatus === false) {
      props.handleDrawer();
    }
  }
  // Invite button functionality ends

  const timelineRef = React.useRef(null);
  const expArrowLRef = React.useRef(null);
  const expArrowRRef = React.useRef(null);

  const eduTimelineRef = React.useRef(null);
  const eduArrowLRef = React.useRef(null);
  const eduArrowRRef = React.useRef(null);

  const eduTimelineRef_2 = React.useRef(null);
  const eduArrowLRef_2 = React.useRef(null);
  const eduArrowRRef_2 = React.useRef(null);

  const updateInvitedJobRoles = () => {
    ApiHandler.getInvitedJobRoles(company_id, jobseeker_id, response => {
      if (response.status === 200) {
        let jobRoles = response.data && response.data[0] ? response.data[0].job_roles : [];
        setInvitedRoles(jobRoles);
      } else {
        console.log("Oops! Something went wrong. Please try again.")
      }
    })
  }

  React.useEffect(() => {
    updateInvitedJobRoles();
  }, [])

  React.useEffect(() => {
    if (timelineRef.current && expArrowRRef.current && timelineRef.current.scrollWidth > 612) {
      expArrowRRef.current.style.display = "flex";
    }

    if (eduTimelineRef.current && eduArrowRRef.current && eduTimelineRef.current.scrollWidth > 638) {
      //console.log(eduTimelineRef.current.scrollWidth)
      eduArrowRRef.current.style.display = "flex";
    }

    if (eduTimelineRef_2.current && eduArrowRRef_2.current && eduTimelineRef_2.current.scrollWidth > 638) {
      //console.log(eduTimelineRef.current.scrollWidth)
      eduArrowRRef_2.current.style.display = "flex";
    }

  }, []);

  // React.useEffect(() => {
  //   const getData = async () => {
  //     let inviteData = {
  //       company_id,
  //       jobseeker_id,
  //       job_role
  //     }
  //    // alert(inviteData.jobseeker_id + inviteData.job_role + inviteData.company_id)
  //     try {
  //       const options = {
  //         method: 'post',
  //         //headers: { 'content-type': 'application/x-www-form-urlencoded' },
  //         //url: 'https://01265c8ecf57.ngrok.io/jobposting/verify_invite_jobseeker',
  //         //data : qs.stringify(inviteData),
  //         //url: 'https://meet2.meetworks.in/jobposting/verify_invite_jobseeker',
  //         url: BASE_URL + Get_invite_status_URL,
  //         data: inviteData,
  //       }
  //       //console.log(options)
  //       var result = await axios(options);
  //       console.log(result);
  //       setInviteStatus(result.data.invite_status);
  //       setSelected(result.data.invite_status);

  //       //setInviteData(result.data)
  //     } catch (error) {
  //       console.warn(error);
  //     }
  //   }
  //   getData();
  // }, []);

  // snackbar callback starts

  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  //const [snackBarDisplayed, setSnackBarDisplayed] = useSessionStorage('terms', false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = () => {
    setCanSnackBarOpen(true)
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  // FeedbackAlert callback starts
  const [alertMessage, setAlertMessage] = React.useState({ message: 'Alert', type: 'success' })
  const [refreshAlert, doRefreshAlert] = React.useState(false);
  const [canAlertOpen, setCanAlertOpen] = React.useState(false);
  const handleAlert = (message, type) => {
    setCanAlertOpen(true);
    setAlertMessage({ message: message, type: type });
    doRefreshAlert(prev => !prev);
  }
  // FeedbackAlert callback ends

  // var hashMapQuals = [], i=0, k=0;

  // for(; props.hit.user_qualifications.length; i++ )

  //   {
  //       if(props.hit.user_qualifications[i])
  //   {
  //     if   (!hashMapQuals.includes({course_name: props.hit.user_qualifications[i].course_name,
  //                                   user_college: props.hit.user_qualifications[i].user_college,
  //                                   user_passing_year: props.hit.user_qualifications[i].user_passing_year}))
  //     {
  //         hashMapQuals[k++]  = {course_name: props.hit.user_qualifications[i].course_name,
  //                                user_college: props.hit.user_qualifications[i].user_college,
  //                                user_passing_year: props.hit.user_qualifications[i].user_passing_year}
  //     }
  //   }

  //   }

  const length = invitedRoles.length;
  var invitedRolesList = [];
  if (invitedRoles.length > 2) {
    invitedRolesList.push(invitedRoles[0]);
    invitedRolesList.push(invitedRoles[1]);
  } else {
    invitedRoles.forEach(item => {
      invitedRolesList.push(item);
    })
  }

  return (
    <div
      // data-is-relevant={relevant} // to disable role+qual divider
      data-is-relevant-to-show-quals={relevant_to_show_quals}
      data-is-relevant-to-show-role={relevant_to_show_role}
      data-is-relevant-all={relevant_all}
      style={{ marginBottom: 10 }}
    >
      <div className={classes.root} style={{ height: invitedRoles.length !== 0 ? 332 : 300 }}>
        <Paper className={classes.paper} elevation={3} style={{ height: invitedRoles.length !== 0 ? 332 : 300, maxHeight: invitedRoles.length !== 0 ? 332 : 300 }}>
          {invitedRoles.length !== 0 ?
            <Grid item container
              justify="center"
              alignItems="center"
              style={{ height: 30, background: '#ececec' }}
            > <Typography variant="body2" style={{ fontWeight: 500, fontSize: 13 }}>
                Invited for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>
                  {invitedRolesList.map((item, i) => {
                    if (i < invitedRolesList.length - 1) {
                      return item + ", "
                    } else {
                      return item
                    }
                  })}
                  {length > 2 ? " " + "+" + (length - 2) + " " + (length === 3 ? "other" : "others") : ""}
                </span>
              </Typography></Grid> : null
          }

          <Grid container direction="row"
            justify="center"
            alignItems="flex-start" style={{ height: invitedRoles.length !== 0 ? 302 : '100%', overflow: 'hidden' }}>
            <Grid item className={classes.leftSection} id="leftSectionId">
              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container justify="center"
                  alignItems="center">
                  <Grid item xs container direction="column" justify="center"
                    alignItems="center">

                    <div className={classes.avatarWrapper} style={{ height: invitedRoles.length !== 0 ? 302 : 300 }}>
                      {props.hit.user_image_url ?
                        <img className={classes.img} alt="candidates avatar image" loading='lazy' width={225} height={300} src={props.hit.user_image_url} onLoad={handleProfileImage} style={{ opacity: 0 }} />
                        :
                        <div className="imgPholder"
                        // style={{ backgroundColor: `${bg_colors[count_list.counter]}` }}
                        >
                          <p style={{
                            fontSize: 50, fontWeight: 400, backgroundColor: 'rgba(255,255,255,0.3)', width: 90, height: 90, lineHeight: '90px', textAlign: 'center', borderRadius: '50%',
                            //  color: `${text_colors[count_list.counter]}` 
                          }}> {initials} </p>
                        </div>}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={9} sm container className={classes.RightSection}>
              <Grid item xs={12} container direction="row" justify="space-between"
                alignItems="flex-start">
                <Grid item xs container direction="row" justify="flex-start" alignItems="baseline" style={{ marginBottom: 10 }}>
                  <Typography variant="h6" component='h2' className={classes.namePlate} >
                    {name_sentence_case}
                  </Typography>

                  <span style={{ fontWeight: 400, fontSize: 14, marginRight: 10 }}>

                    {((props.hit.area || props.hit.city || props.hit.distric) || (employer_lat_lng.lat != null && employer_lat_lng.lng != null)) ?
                      <>
                        <span style={{ fontSize: 18, marginRight: 4, color: '#ccc', fontWeight: 100 }}>|</span>
                        <span style={{ position: 'relative', marginRight: 8 }}>
                          <LocationOnRoundedIcon style={{ marginTop: 0, position: 'absolute', top: 1, color: '#5bc2a8', fontSize: 14 }} />
                        </span>
                      </> : null}
                    {/* Show area, city / city, district / area, district / city / district / area */}
                    <span style={{ fontWeight: 400, fontSize: 14, marginLeft: 8 }}>
                      {props.hit.area && props.hit.city ? props.hit.area + ', ' + props.hit.city : (props.hit.city && props.hit.distric ? props.hit.city + ', ' + props.hit.distric : (props.hit.area && props.hit.distric ? props.hit.area + ', ' + props.hit.distric : (props.hit.city ? props.hit.city : (props.hit.distric ? props.hit.distric : (props.hit.area ? props.hit.area : "")))))}
                    </span>
                    {/* show distance tag if lat long present & geotype is not state or district */}
                    {employer_lat_lng.lat != null && employer_lat_lng.lng != null && geoType && geoType !== "administrative_area_level_1" && geoType && geoType !== "administrative_area_level_2" && geoType !== "colloquial_area" ? <Chip size="small" label={tagLabel} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null}
                    {/* 
                    {distanceOfJobseekerFromEmployer && distanceOfJobseekerFromEmployer < 1.2 ? <Chip size="small" 
                    label={"< 1.2 km"} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} /> : null} */}

                  </span>
                </Grid>
                {/* <Grid item>
                  <Typography variant="body2" className={classes.lastActive}>
                    Last active:  {last_active <= 30 ? (last_active == 0 ? "Today" : (last_active == 1 ? last_active + " day" : last_active + " days")) : " > 1 month "}
                  </Typography>
                </Grid> */}
              </Grid>
              <Grid item xs container direction="column"
                justify="center"
                alignItems="center">
                <Grid item xs container direction="column" justify="center"
                  alignItems="flex-start" >
                  <Grid item xs container justify="flex-start" alignItems="flex-start" style={{ flexWrap: 'nowrap', marginTop: 10, marginBottom: 10 }} >
                    <WorkIcon fontSize="small" className="primaryText" />
                    <Typography variant="subtitle1" component='h2' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10 }}>Experience</Typography>
                    {fresher ?
                      <Chip size="small" label={fresher ? 'Fresher' : ((total_years == 0 || total_months == 0) ? ((total_years != 0) ? (total_years + years_to_display) : (total_months + months_to_display)) : (total_years + years_to_display + total_months + months_to_display))} className="primary" classes={{ root: classes.chiproot, label: classes.chiplabel }} style={{ marginLeft: 10 }} />
                      : null}
                  </Grid>

                  <div className={classes.expTimelineWrapper}>
                    <div ref={expArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_L}`} onClick={scrollTimeline} style={{ display: 'none' }}>&#8249;</div>
                    {userExp && userExp.length === 1 && expMonthsAndExp[0].sameDaysDiff <= 31 && expMonthsAndExp[0].expStartMon === expMonthsAndExp[0].expEndMon ?
                      <div style={{ marginLeft: 30, marginBottom: 10 }}>
                        <Typography variant="subtitle1" component='h3' style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                          {userExp[0].job_role_display} | <span style={{ fontWeight: 100, fontSize: 12 }}>{expMonths[expMonthsAndExp[0].expStartMon]} {expMonthsAndExp[0].expStartYear}</span>
                        </Typography>
                      </div>
                      :
                      <div style={{ position: 'relative' }}>
                        {userExp && userExp.length > 0 && hasOverlapExps.some(item => item.overlap === 'true') ?
                          <LightTooltip title="This user has some experiences whose start date is before the end date of their previous experience. For such experiences, the timeline indicates only the starting month." placement="top-start" arrow>
                            <IconButton aria-label="save" size="small" style={{ position: "absolute", top: '-4px', left: '-2px', zIndex: '9', cursor: 'default' }}>
                              <InfoOutlinedIcon className={classes.infoIcon} />
                            </IconButton>
                          </LightTooltip>
                          : null}
                        <div id="timelineListId" className={classes.timelineList} ref={timelineRef}>
                          {userExp && userExp.length > 0 && userExp.map((item, i) => (
                            <div key={i}
                              // style={{ width: userExp.length === 2 && i === 0 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30 ? 150 : (userExp.length === 1 && 300) }}
                              style={{
                                width:
                                  (userExp.length === 1)
                                    || (userExp.length > 1 && i === userExp.length - 1
                                      // && (expMonthsAndExp[i].sameDaysDiff > 31 || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon)
                                    )
                                    || (userExp.length > 1 && i === 0 && expMonthsAndExp[i].daysDiff >= 30)
                                    ? 300
                                    : 150
                              }}
                              className={expMonthsAndExp[i]
                                &&
                                // (expMonthsAndExp[i].daysDiff >= 30
                                //   && expMonthsAndExp[i].sameDaysDiff <= 31
                                //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                // )
                                // || (
                                //   expMonthsAndExp[i].sameDaysDiff <= 31 &&
                                //    i === userExp.length - 1
                                //   && expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon
                                // ) ? "withBreakSameMon"
                                // : 
                                (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30
                                  ? "withBreak"
                                  : (
                                    // (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff <= 31)
                                    //   ||
                                    (userExp.length === 2 && expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff < 0
                                    ) ? "noBreakSameMon"
                                      : "noBreak"))}
                            >

                              <div>
                                <div className={classes.timelineDuration}>
                                  {/* {expMonths[expMonthsAndExp[i].expStartMon]} {expMonthsAndExp[i].expStartYear} */}
                                  {typeof (expMonthsAndExp[i].expStartMon) === 'string' ? expMonthsAndExp[i].expStartMon : expMonths[expMonthsAndExp[i].expStartMon]} {expMonthsAndExp[i].expStartYear}
                                </div>
                                <div>
                                  <span></span>
                                </div>
                                <div
                                  style={{
                                    transform: expMonthsAndExp[i] && (expMonthsAndExp[i].daysDiff < 0
                                      // ||
                                      // (
                                      //   expMonthsAndExp[i].expStartMon === expMonthsAndExp[i].expEndMon &&
                                      //   expMonthsAndExp[i].sameDaysDiff <= 31)
                                    )
                                      && 'translate(0)'
                                  }}
                                >
                                  <LightTooltip title={item.job_role_display ? (item.company_name ? item.job_role_display + " at " + item.company_name : null) : null} placement="bottom">
                                    <div>{item.job_role_display ? item.job_role_display : null}</div>
                                  </LightTooltip>
                                </div>
                              </div>

                              {userExp && userExp.length && i !== userExp.length - 1
                                && (expMonthsAndExp[i] && expMonthsAndExp[i].daysDiff >= 30)
                                // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                // )
                                &&
                                <div>
                                  <div className={classes.timelineDuration}>
                                    {/* {expMonths[expMonthsAndExp[i].expEndMon]} {expMonthsAndExp[i].expEndYear} */}
                                    {typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear}
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                </div>
                              }
                              {userExp && userExp.length &&
                                (i === userExp.length - 1
                                  // && (expMonthsAndExp[i] && expMonthsAndExp[i].sameDaysDiff > 31
                                  //   || expMonthsAndExp[i].expStartMon !== expMonthsAndExp[i].expEndMon
                                  // )
                                )
                                &&
                                <div>
                                  <div className={classes.timelineDuration}>
                                    {/* {userExp[i].company_ending_date === "Present" ? "Present" : (expMonths[expMonthsAndExp[i].expEndMon] ? expMonths[expMonthsAndExp[i].expEndMon] + " " + expMonthsAndExp[i].expEndYear : "")} */}
                                    {userExp[i].company_ending_date === "Present" ? "Present" : typeof (expMonthsAndExp[i].expEndMon) === 'string' ? expMonthsAndExp[i].expEndMon + ' ' + expMonthsAndExp[i].expEndYear : expMonths[expMonthsAndExp[i].expEndMon] + ' ' + expMonthsAndExp[i].expEndYear} 
                                  </div>
                                  <div>
                                    <span></span>
                                  </div>
                                </div>
                              }

                              <span className="breakChar" style={{ position: 'absolute', top: '19%', right: '-22px', background: 'white' }}>b r e a k</span>
                            </div>
                          ))}

                        </div>
                      </div>
                    }
                    <div ref={expArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_R}`} onClick={scrollTimelineR} style={{ display: 'none' }}>&#8250;</div>
                  </div>
                </Grid>

                <Grid item xs container direction="column" justify="flex-start" alignItems="flex-start" style={{ marginTop: 12, marginBottom: 12 }}>
                  <Grid item xs container justify="flex-start" alignItems="center" style={{ marginBottom: 10 }}>
                    <SchoolRoundedIcon fontSize="small" className="primaryText" />
                    <Typography variant="subtitle1" component='h3' style={{ fontWeight: 400, fontSize: 14, color: '#5bc2a8', textTransform: 'uppercase', letterSpacing: '0.1em', marginLeft: 10, }}>Education</Typography>
                  </Grid>

                  {quals_raw_data.length === 1 ?
                    <div style={{ marginLeft: 30, marginBottom: 10 }}>
                      <Typography variant="subtitle1" component='h4' style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                        {
                          (quals_raw_data[0].course_type && (quals_raw_data[0].course_type === "UG Diploma" || quals_raw_data[0].course_type === "PG Diploma" || quals_raw_data[0].course_type === "ITI" || quals_raw_data[0].course_type === "Certification"))
                            ? quals_raw_data[0].course_type : (quals_raw_data[0].course_abbreviation ? quals_raw_data[0].course_abbreviation : (quals_raw_data[0].course_name ? quals_raw_data[0].course_name : null))}{(quals_raw_data[0].course_type && (quals_raw_data[0].course_type === "UG Diploma"
                              || quals_raw_data[0].course_type === "PG Diploma" || quals_raw_data[0].course_type === "ITI" || quals_raw_data[0].course_type === "Certification")) ? (quals_raw_data[0].course_name ? "—" + quals_raw_data[0].course_name : null) :
                              (quals_raw_data[0].specialization_name ? "—" + quals_raw_data[0].specialization_name : null)
                        }
                        {' | '}<span style={{ fontWeight: 100, fontSize: 12 }}>{quals_raw_data[0].user_passing_year}</span>
                      </Typography>
                    </div>
                    : (quals_raw_data.length !== 1 ?
                      <div className={classes.eduTimelineWrapper}>
                        <div ref={eduArrowLRef_2} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_L}`} onClick={scrollEduTimelineL_2} style={{ display: 'none' }}>&#8249;</div>
                        <div ref={eduTimelineRef_2} className={classes.timelineListEdu}>
                          {quals_raw_data.map((item, i) =>
                          (<div key={i}>
                            <div>
                              <div className={classes.timelineDuration}>
                                {item.user_passing_year}
                              </div>
                              <div>
                                <span></span>
                              </div>
                              <div>
                                <LightTooltip title={((item.course_type &&
                                  (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification"))
                                  ? (item.course_type + (item.course_name ? "—" + item.course_name : ""))
                                  : (item.course_abbreviation ?
                                    (item.course_abbreviation + (item.specialization_name ? "—" + item.specialization_name : ""))
                                    : ""))
                                  + (item.user_college ? (" from " + item.user_college) : null)}


                                  placement="bottom">
                                  <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', height: '100%' }}>{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : item.course_name)}{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}</div>
                                </LightTooltip>
                              </div>
                            </div>
                          </div>)
                          )}
                        </div>

                        <div ref={eduArrowRRef_2} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_R}`} onClick={scrollEduTimelineR_2} style={{ display: 'none' }}>&#8250;</div>

                      </div>

                      : null)

                  }

                  {/* {userEdu.length === 1 ?
                    <div style={{ marginLeft: 30, marginBottom: 10 }}>
                      <Typography variant="subtitle1" style={{ fontSize: 12, fontWeight: 500, color: '#797979' }}>
                        {userEdu[0].course_type && (userEdu[0].course_type === "UG Diploma" || userEdu[0].course_type === "PG Diploma" || userEdu[0].course_type === "ITI" || userEdu[0].course_type === "Certification") ? userEdu[0].course_type : (userEdu[0].course_abbreviation ? userEdu[0].course_abbreviation : (userEdu[0].course_name ? userEdu[0].course_name : null))}{userEdu[0].course_type && (userEdu[0].course_type === "UG Diploma" || userEdu[0].course_type === "PG Diploma" || userEdu[0].course_type === "ITI" || userEdu[0].course_type === "Certification") ? (userEdu[0].course_name ? "—" + userEdu[0].course_name : null) : (userEdu[0].specialization_name ? "—" + userEdu[0].specialization_name : null)}{' | '}<span style={{ fontWeight: 100, fontSize: 12 }}>{userEdu[0].user_passing_year}</span>
                      </Typography>
                    </div>
                    :
                    <div className={classes.eduTimelineWrapper}>
                      <div ref={eduArrowLRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_L}`} onClick={scrollEduTimelineL} style={{ display: 'none' }}>&#8249;</div>
                      <div ref={eduTimelineRef} className={classes.timelineListEdu}>
                        {userEdu.map((item, i) =>
                        (<div key={i}>
                          <div>
                            <div className={classes.timelineDuration}>
                              {item.user_passing_year}
                            </div>
                            <div>
                              <span></span>
                            </div>
                            <div>
                              <LightTooltip title={item.course_type &&
                                ((item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification")
                                  ? item.course_type + (item.course_name ? "—" + item.course_name : "")
                                  : (item.course_abbreviation ?
                                    (item.course_abbreviation + (item.specialization_name ? "—" + item.specialization_name : ""))
                                    : "")) + " from " + (item.user_college)

                              }
                                placement="bottom">
                                <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', height: '100%' }}>{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? item.course_type : (item.course_abbreviation ? item.course_abbreviation : item.course_name)}{item.course_type && (item.course_type === "UG Diploma" || item.course_type === "PG Diploma" || item.course_type === "ITI" || item.course_type === "Certification") ? (item.course_name ? "—" + item.course_name : null) : (item.specialization_name ? "—" + item.specialization_name : null)}</div>
                              </LightTooltip>
                            </div>
                          </div>
                        </div>)
                        )}
                      </div>

                      <div ref={eduArrowRRef} className={`${classes.timelineArrow} ${classes.timelineArrow_Edu_R}`} onClick={scrollEduTimelineR} style={{ display: 'none' }}>&#8250;</div>

                    </div>
                  } */}

                </Grid>
              </Grid>
              {/* <Grid item container justify="flex-end"
                alignItems="flex-end" style={{ position: 'absolute', bottom: 10, right: 10 }}>
                <IconButton aria-label="save" className={classes.bigIcon}>
                  <BookmarkRoundedIcon className={classes.saveIcon} />
                </IconButton>
              </Grid> */}
            </Grid>

          </Grid>
        </Paper>
      </div>
      <Grid item container
        justify="center"
        alignItems="flex-end"
        style={{ paddingLeft: '23%' }}
      >
        {/* <IconButton aria-label="save" className={classes.bigIconRect}>
                  <EmailOutlinedIcon className={classes.saveIconRect} />
                  Invite
                </IconButton> */}
        <ToggleButton
          id="invite_uninvite_btn_id"
          value="check"
          className={classes.bigIconRect}
          classes={{ label: classes.tglBtnLabel, selected: classes.tglBtnSelected, }}
          selected={selected}
          onChange={gotResponse ? handleInviteClick : null}
        >
          {selected ?
            <CheckIcon className={classes.saveIconRect} />
            : <EmailOutlinedIcon className={classes.saveIconRect} />
          }
          {selected ? "Invited" : "Email Invite"}
        </ToggleButton>

        <Button
          className={classes.bigIconRect}
          onClick={() => {
            AnalyticsHandler.track("fullProfile_listView_btnClick");
            history.push({ pathname: `/profile/${(array_name[0] ? array_name[0] : "") + (array_name[1] ? "-" : "") + (array_name[1] ? array_name[1] : "") + '-' + props.hit.objectID}` });
          }}
        ><OpenInNewOutlinedIcon className={classes.saveIconRect} /> Full Profile</Button>
      </Grid>
      <SnackBarCustom canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} />
      <FeedbackAlert canAlertOpen={canAlertOpen} refresh={refreshAlert} alertMessage={alertMessage.message} alertType={alertMessage.type}></FeedbackAlert>

      <div>
        <Dialog
          open={open}
          onClose={handleUninviteCancelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            classes: {
              root: classes.dialogBackdropRoot
            }
          }}
          transitionDuration={150}
        >
          <DialogTitle id="alert-dialog-title">{"Confirmation to cancel invite"}</DialogTitle>
          <DialogContent style={{ height: 100 }}>
            <DialogContentText id="alert-dialog-description" style={{ color: '#555', fontSize: 16, fontWeight: 'bold' }}>
              Are you sure you want to cancel your invite to <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{props.hit.jobseeker_name ? props.hit.jobseeker_name.toLowerCase() : ""}</span> for <span style={{ color: '#14967B', textTransform: 'capitalize' }}>{role}</span>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button id="cancel_hire_btn_id" onClick={handleUninviteCancelClose}>
              Cancel
            </Button>
            <Button id="hire_btn_id" onClick={gotUninviteResponse ? handleUninviteYesClose : null} autoFocus style={{ color: '#14967B', fontWeight: 'bold' }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}