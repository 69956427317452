import React, { useState, useEffect, useMemo } from "react";

import Button from '@material-ui/core/Button';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";

import { csv } from "d3-fetch";
import { scaleQuantize } from "d3-scale";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageLoader from '../discovery/PageLoader';
import LogoLoader from '../../assets/logo-loader.gif';
import useStyles from './styles';

//const geoUrl = "/districtMap.json";
//const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/countries/india/india-districts.json"
const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/india/india-districts.json"

// const colorScale = scaleQuantize()
//   .domain([1, 2000])
//   .range([
//     "#e0f2f1",
//     "#b2dfdb",
//     "#80cbc4",
//     "#4db6ac",
//     "#26a69a",
//     "#009688",
//     "#00897b",
//     "#00796b",
//     "#00695c",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#5f5bc2",
//     "#fefefe"
//   ]);

const colorScale = scaleQuantize()
  .domain([1, 2000])
  .range([
    "#5f5bc260",
    "#5f5bc265",
    "#5f5bc270",
    "#5f5bc275",
    "#5f5bc280",
    "#5f5bc285",
    "#5f5bc290",
    "#5f5bc295",
    "#5f5bc299",
    "#5f5bc2a0",
    "#5f5bc2a5",
    "#5f5bc2b0",
    "#5f5bc2b5",
    "#5f5bc2c0",
    "#5f5bc2c5",
    "#5f5bc2d0",
    "#fefefe"
  ]);

const MapChartStudents = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]); // list of required districts with their jobs count
  const [position, setPosition] = useState({ coordinates: [20.593684, 78.96288], zoom: 20 });
  const [maxValue, setMaxValue] = useState(0);
  const [markers, setMarkers] = useState([{ markerOffset: 0, name: "Ambala", count: 781, coordinates: [77.096695, 30.278180] },
  { markerOffset: 0, name: "Jind", count: 872, coordinates: [76.616666, 29.616668] },
  { markerOffset: 0, name: "Bhiwani", count: 820, coordinates: [76.033514, 28.899046] },
  // { markerOffset: 0, name: "Gurugram", coordinates: [77.026344, 28.457523] },
  { markerOffset: 0, name: "Gurugram", count: 788, coordinates: [77.226344, 28.2] },
  { markerOffset: 0, name: "Delhi", count: 1150, coordinates: [77.1314900, 28.7519500] }]);
  const [origMarkers, setOrigMarkers] = useState([]);

  const [tooltipData, setTooltipData] = useState("");
  const [curDist, setCurDist] = useState("")
  const [totalCompaniesinDist, setTotalCompaniesinDist] = useState(0);
  const [totalSectors, setTotalSectors] = useState(0); // total sectors in the current district

  const [allCompCount, setAllCompCount] = useState(0); // count of all companies
  const [secCompCount, setSecCompCount] = useState({}); // sectorwise count of jobs

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  // vars to store count of all students in dist groups
  let ambalaCount = 781;
  let jindCount = 872;
  let bhiwaniCount = 820;
  let gurugramCount = 788;
  let delhiCount = 1150;

  // vars to store count of treatment group students in dist groups
  let ambalaCountT = 308;
  let jindCountT = 435;
  let bhiwaniCountT = 350;
  let gurugramCountT = 383;
  let delhiCountT = 593;

  // vars to store count of sectors in dist groups
  let sectorCount = { ambala: 0, bhiwani: 0, jind: 0, gurugram: 0, delhi: 0 }
  let _data = [];
 
// vars for count of sector-wise all students in the district groups
let textilesSecCount = { ambala: 192, jind: 290, bhiwani: 254, gurugram: 206, delhi: 331 }
let beautySecCount = { ambala: 81, jind: 106, bhiwani: 178, gurugram: 119, delhi: 113 }
let mechanicalSecCount = { ambala: 42, jind: 73, bhiwani: 57, gurugram: 92, delhi: 147 }
let healthcareSecCount = { ambala: 0, jind: 14, bhiwani: 2, gurugram: 7, delhi: 24 }
let itSecCount = { ambala: 304, jind: 284, bhiwani: 273, gurugram: 315, delhi: 352 }
let secretarialSecCount = { ambala: 162, jind: 105, bhiwani: 56, gurugram: 49, delhi: 183 }

  // vars for count of sector-wise treatment group students in the district groups
  let textilesSecCountT = { ambala: 81, jind: 171, bhiwani: 99, gurugram: 93, delhi: 187 }
  let beautySecCountT = { ambala: 30, jind: 55, bhiwani: 94, gurugram: 70, delhi: 33 }
  let mechanicalSecCountT = { ambala: 13, jind: 45, bhiwani: 32, gurugram: 38, delhi: 91 }
  let healthcareSecCountT = { ambala: 0, jind: 6, bhiwani: 2, gurugram: 6, delhi: 13 }
  let itSecCountT = { ambala: 135, jind: 104, bhiwani: 105, gurugram: 156, delhi: 181 }
  let secretarialSecCountT = { ambala: 49, jind: 54, bhiwani: 18, gurugram: 20, delhi: 88 }

  // to calculate the bubble size proportional to count of jobs in that dist group
  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0.5, 8]),
    [maxValue]
  );

  useEffect(() => {
    
    const _markers = [{ markerOffset: 0, name: "Ambala", count: ambalaCount, allCount: ambalaCount, countT: ambalaCountT, allCountT: ambalaCountT, coordinates: [77.096695, 30.278180], 
    sectors: {
      textile: textilesSecCount.ambala,
      beauty: beautySecCount.ambala,
      mechanical: mechanicalSecCount.ambala,
      healthcare: healthcareSecCount.ambala,
      it: itSecCount.ambala,
      secretarial: secretarialSecCount.ambala
    }, sectorsT: {
      textile: textilesSecCountT.ambala,
      beauty: beautySecCountT.ambala,
      mechanical: mechanicalSecCountT.ambala,
      healthcare: healthcareSecCountT.ambala,
      it: itSecCountT.ambala,
      secretarial: secretarialSecCountT.ambala
    } },
    { markerOffset: 0, name: "Jind", count: jindCount, allCount: jindCount, countT: jindCountT,allCountT: jindCountT, coordinates: [76.616666, 29.616668], 
    sectors: {
      textile: textilesSecCount.jind,
      beauty: beautySecCount.jind,
      mechanical: mechanicalSecCount.jind,
      healthcare: healthcareSecCount.jind,
      it: itSecCount.jind,
      secretarial: secretarialSecCount.jind
    }, sectorsT: {
      textile: textilesSecCountT.jind,
      beauty: beautySecCountT.jind,
      mechanical: mechanicalSecCountT.jind,
      healthcare: healthcareSecCountT.jind,
      it: itSecCountT.jind,
      secretarial: secretarialSecCountT.jind
    }},
    { markerOffset: 0, name: "Bhiwani", count: bhiwaniCount, allCount: bhiwaniCount, countT: bhiwaniCountT, allCountT: bhiwaniCountT, coordinates: [76.033514, 28.899046], 
    sectors: {
      textile: textilesSecCount.bhiwani,
      beauty: beautySecCount.bhiwani,
      mechanical: mechanicalSecCount.bhiwani,
      healthcare: healthcareSecCount.bhiwani,
      it: itSecCount.bhiwani,
      secretarial: secretarialSecCount.bhiwani
    }, sectorsT: {
      textile: textilesSecCountT.bhiwani,
      beauty: beautySecCountT.bhiwani,
      mechanical: mechanicalSecCountT.bhiwani,
      healthcare: healthcareSecCountT.bhiwani,
      it: itSecCountT.bhiwani,
      secretarial: secretarialSecCountT.bhiwani
    } },
    // { markerOffset: 0, name: "Gurugram", coordinates: [77.026344, 28.457523] },
    { markerOffset: 0, name: "Gurugram", count: gurugramCount, allCount: gurugramCount, countT: gurugramCountT, allCountT: gurugramCountT, coordinates: [77.226344, 28.2], 
    sectors: {
      textile: textilesSecCount.gurugram,
      beauty: beautySecCount.gurugram,
      mechanical: mechanicalSecCount.gurugram,
      healthcare: healthcareSecCount.gurugram,
      it: itSecCount.gurugram,
      secretarial: secretarialSecCount.gurugram
    },
    sectorsT: {
      textile: textilesSecCountT.gurugram,
      beauty: beautySecCountT.gurugram,
      mechanical: mechanicalSecCountT.gurugram,
      healthcare: healthcareSecCountT.gurugram,
      it: itSecCountT.gurugram,
      secretarial: secretarialSecCountT.gurugram
    } },
    { markerOffset: 0, name: "Delhi", count: delhiCount, allCount: delhiCount, countT: delhiCountT, allCountT: delhiCountT, coordinates: [77.1314900, 28.7519500], 
    sectors: {
      textile: textilesSecCount.delhi,
      beauty: beautySecCount.delhi,
      mechanical: mechanicalSecCount.delhi,
      healthcare: healthcareSecCount.delhi,
      it: itSecCount.delhi,
      secretarial: secretarialSecCount.delhi
    },
    sectorsT: {
      textile: textilesSecCountT.delhi,
      beauty: beautySecCountT.delhi,
      mechanical: mechanicalSecCountT.delhi,
      healthcare: healthcareSecCountT.delhi,
      it: itSecCountT.delhi,
      secretarial: secretarialSecCountT.delhi
    } }];

        _data = _markers
        //sorting the districts in the _data in descending order of number of jobs
        const sortedDistricts = sortBy(_data, (o) => -o.count);
        setMaxValue(sortedDistricts[0].count);
        setData(sortedDistricts)
        //console.log(sortedDistricts)

        setMarkers(_markers);
        setOrigMarkers(_markers); //to save the original markers data
        props.setOrigMarkers(_markers); //to save the original markers data in parent

        // get sectorwise students count
        let _textilesSecCompCount = 0, _mechanicalSecCompCount = 0, _beautySecCompCount = 0,
          _healthSecCompCount = 0, _itSecCompCount = 0, _secretarialSecCompCount = 0,
          _textilesSecCompCountT = 0, _mechanicalSecCompCountT = 0, _beautySecCompCountT = 0,
          _healthSecCompCountT = 0, _itSecCompCountT = 0, _secretarialSecCompCountT = 0
        for (const key in textilesSecCount) {
          _textilesSecCompCount += textilesSecCount[key];
          _textilesSecCompCountT += textilesSecCountT[key];
        }
        for (const key in mechanicalSecCount) {
          _mechanicalSecCompCount += mechanicalSecCount[key];
          _mechanicalSecCompCountT += mechanicalSecCountT[key]
        }
        for (const key in beautySecCount) {
          _beautySecCompCount += beautySecCount[key];
          _beautySecCompCountT += beautySecCountT[key]
        }
        for (const key in healthcareSecCount) {
          _healthSecCompCount += healthcareSecCount[key];
          _healthSecCompCountT += healthcareSecCountT[key];
        }
        for (const key in itSecCount) {
          _itSecCompCount += itSecCount[key];
          _itSecCompCountT += itSecCountT[key]
        }
        for (const key in secretarialSecCount) {
          _secretarialSecCompCount += secretarialSecCount[key];
          _secretarialSecCompCountT += secretarialSecCountT[key]
        }

        let a = ambalaCount + jindCount + bhiwaniCount + delhiCount + gurugramCount
        let b = {
          all: _textilesSecCompCount + _mechanicalSecCompCount + _beautySecCompCount + _healthSecCompCount + _itSecCompCount + _secretarialSecCompCount,
          textiles: _textilesSecCompCount,
          mechanical: _mechanicalSecCompCount,
          beauty: _beautySecCompCount,
          healthcare: _healthSecCompCount,
          it: _itSecCompCount,
          secretarial: _secretarialSecCompCount
        }
        setAllCompCount(a);
        setSecCompCount(b);

        // set the count for treatment group students
        let aT = ambalaCountT + jindCountT + bhiwaniCountT + delhiCountT + gurugramCountT
        let bT = {
          all: _textilesSecCompCountT + _mechanicalSecCompCountT + _beautySecCompCountT + _healthSecCompCountT + _itSecCompCountT + _secretarialSecCompCountT,
          textiles: _textilesSecCompCountT,
          mechanical: _mechanicalSecCompCountT,
          beauty: _beautySecCompCountT,
          healthcare: _healthSecCompCountT,
          it: _itSecCompCountT,
          secretarial: _secretarialSecCompCountT
        }
        props.setAllStudentsCount(aT);
        props.setSecStudentsCount(bT);
      
  }, [])

  // Trade buttons click interaction starts
  const [activeTradeBtn, setActiveTradeBtn] = useState({ all: true, textile: false, beauty: false, mechanical: false, healthcare: false })

  const handleTradeGroupData = (trade) => {
    let _markers = [...origMarkers];
    if (!["all"].includes(trade)) {//add count on buttons except the all
      _markers.map((item, index) => {
        item.count = item.sectors[trade];
        item.countT = item.sectorsT[trade];
      })
    } else {//add count on the all button
      _markers.map((item, index) => {
        item.count = item.allCount;
        item.countT = item.allCountT;
      })
    }

    setMarkers(_markers)

    let _activeTradeBtn = activeTradeBtn;

    for (const key in _activeTradeBtn) {
      _activeTradeBtn[key] = false
    }

    // highlight the active btn
    if (trade === "all") {
      _activeTradeBtn.all = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "textile") {
      _activeTradeBtn.textile = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "mechanical") {
      _activeTradeBtn.mechanical = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "beauty") {
      _activeTradeBtn.beauty = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "healthcare") {
      _activeTradeBtn.healthcare = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "it") {
      _activeTradeBtn.it = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "secretarial") {
      _activeTradeBtn.secretarial = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    }
  }
  // Trade buttons click interaction ends

  useEffect(() => {
    if (props.tradeBtn === "") return;

    handleTradeGroupData(props.tradeBtn)
  }, [props.tradeBtn])

  return (
    <>
      <div className={classes.mapContainer}>

        <PageLoader loaderMessage={"Generating Map..."} style={{ top: '-40px' }}></PageLoader>
        
        {/* Trade btns */}
        <div style={{ position: "absolute", padding: 10, top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            className={`${classes.legend} ${activeTradeBtn.all ? classes.legend_pri : classes.legend_sec}`}
          >
            All <span>{allCompCount}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.beauty ? classes.legend_pri : classes.legend_sec}`}
          >
            Beauty <span>{secCompCount.beauty}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.it ? classes.legend_pri : classes.legend_sec}`}
          >
            Computer/IT <span>{secCompCount.it}</span>
          </div>
          
          <div
            className={`${classes.legend} ${activeTradeBtn.mechanical ? classes.legend_pri : classes.legend_sec}`}
          >
            Mechanical <span>{secCompCount.mechanical}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.secretarial ? classes.legend_pri : classes.legend_sec}`}
          >
            Secretarial <span>{secCompCount.secretarial}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.textile ? classes.legend_pri : classes.legend_sec}`}
          >
            Textiles <span>{secCompCount.textiles}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.healthcare ? classes.legend_pri : classes.legend_sec}`}
          >
            Other <span>{secCompCount.healthcare}</span>
          </div>
        </div>

        {/* Map */}
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 1500,
          }}
        >
          <ZoomableGroup
            center={[76.65, 29.4]}// to change the center of map
            zoom={10}
            minZoom={10}
            onMoveEnd={handleMoveEnd}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const cur = data.find(s => s.name === geo.properties.NAME_2);
                  const thisDist = geo.properties.NAME_2;
                  //vars to color the combined districts. the names of districts here match the ones in districtMap.json. e.g. Delhi is West
                  const ambala = ["Ambala", "Panchkula", "Kurukshetra", "Yamuna Nagar"];
                  const jind = ["Jind", "Hisar", "Kaithal", "Karnal", "Panipat"];
                  const bhiwani = ["Bhiwani", "Mahendragarh", "Rohtak"];
                  const gurugram = ["Gurgaon", "Faridabad", "Palwal", "Mewat", "Rewari"];
                  const delhi = ["Delhi", "Jhajjar", "Sonepat", "Ghaziabad", "Gautam Buddha Nagar"]
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      // fill={colorScale(cur ? cur.companies_number : "teal")}
                      fill={ambala.includes(thisDist) ? "#bdbdbd" :
                        jind.includes(thisDist) ? "#e0e0e0" :
                          bhiwani.includes(thisDist) ? "#c3cecd" :
                            gurugram.includes(thisDist) ? "lightgrey" :
                              delhi.includes(thisDist) ? "#b4bdbc" : "#fff"
                      }
                      stroke={cur ? "#f2f2f2" : "#f1f1f1"}
                      strokeWidth={0.1}
                    // onClick={() => { alert(thisDist) }}
                    />
                  );
                })
              }
            </Geographies>

            {/* company markers */}
            {markers.map(({ name, count, countT, sectors, sectorCount, coordinates, markerOffset }, index) => (
              <Marker key={name} coordinates={coordinates}>
                {/* <a id={"tooltip-anchor-students" + index}
                  onMouseEnter={() => {
                    setTooltipData(sectors);
                    setTotalCompaniesinDist(count);
                    setTotalSectors(sectorCount);
                    setCurDist(name)
                  }}
                  onMouseLeave={() => {
                    setTooltipData("");
                  }}
                > */}
                  <circle fill={colorScale(count)} stroke="#FFF" strokeWidth={0.1} r={popScale(count)} />
                  <text
                    textAnchor="middle"
                    y={markerOffset}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#000", fontSize: '0.175rem', fontWeight: 600 }}
                  >
                    {name + "+"}
                  </text>
                  {/* <text
                    textAnchor="middle"
                    y={markerOffset + 3.5}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#4e5656", fontSize: '0.2rem', fontWeight: 600 }}
                  >
                    {count}
                  </text> */}
                  <text
                    textAnchor="middle"
                    y={markerOffset + 3.5}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#1d1d1d", fontSize: '0.165rem', fontWeight: 600 }}
                  >
                    T {countT}
                  </text>
                  <text
                    textAnchor="middle"
                    y={markerOffset + 6.5}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#1d1d1d", fontSize: '0.165rem', fontWeight: 600 }}
                  >
                    C {count - countT}
                  </text>
                {/* </a> */}
              </Marker>
            ))}

          </ZoomableGroup>
        </ComposableMap>

        {/* tooltip for companies markers */}
        {/* {markers.map((item, index) => (
          <Tooltip anchorId={"tooltip-anchor-students" + index} key={index}>
            <div>

              <h2 style={{ marginBottom: 3, marginTop: 2 }}>{curDist}</h2>

              <h4 style={{ marginBottom: 5, marginTop: 2 }}>Total Students: <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}>{totalCompaniesinDist}</span></h4>

              <h4 style={{ marginBottom: 5, marginTop: 4 }}>Students in various sectors</h4>
              <ul style={{ textAlign: 'left', marginTop: 5 }}>
                <li style={{ fontWeight: 700 }}>Total
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem', fontWeight: 700 }}> {totalSectors}</span>
                </li>
                <li>Beauty
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.beauty}</span></li>
                <li>IT
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.it}</span></li>
                <li>Healthcare
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.healthcare}</span></li>
                <li>Mechanical
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.mechanical}</span>
                </li>
                <li>Secretarial
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.secretarial}</span></li>
                <li>Tailoring/Textiles
                  <span style={{ color: "#5f5bc2", fontSize: '1.1rem' }}> {tooltipData.textile}</span>
                </li>
              </ul>
            </div>
          </Tooltip>
        ))
        } */}
      </div>

    </>
  );
};

export default MapChartStudents;
