import React, {useRef} from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropLeftIcon from '@material-ui/icons/ArrowLeft';
import { connectPagination } from 'react-instantsearch-dom';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#55c6a9',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    position: 'relative',
    overflow: 'visible',
    alignItems: 'center',
    justifyContent: 'center',
    width: 850,
    height: 0,
    margin: 'auto',
    marginTop: '-1px',
    padding: 0,
    zIndex: '2',
  },

  controls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    position: 'absolute',
    top: '-25px',
  },
  saveIcon: {
    height: 27,
    width: 27,
  },
  bigIcon: {
    height: 54,
    width: 54,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#fab116',
    border: '2px solid #fab116',
    marginLeft: 30,
    marginRight: 30,
    '&:hover': {
      backgroundColor: '#fab116',
      color: '#ffffff',
      boxShadow: '0px 0px 7px rgba(221, 150, 0, 0.66)',

    }
  },
  smallIcon: {
    height: 46,
    width: 46,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    border: '2px solid #14967B',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    color: '#14967B',
    '&:hover': {
      backgroundColor: '#b9f0e1',
    }
  },
  kbdIcon: {
    height: 20,
    width: 20,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: '#dddddd',
    border: '1px solid #ccc',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    color: 'rgba(0, 0, 0, 0.3);',
  },
  secondaryControls: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'relative',

  },
}));

 var page = 1;

 function ProfileCardNavbar_2({ currentRefinement, nbPages, refine, createURL,hasMore, refineNext }) {
  const classes = useStyles();
  const theme = useTheme();

//const [sentinel, setSentinel] = React.useState(null);
const sentinelRef = useRef(null);



React.useEffect(() => {

 const observer = new IntersectionObserver((entries) => {

 entries.forEach(entry => {

      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });

});

 observer.observe(sentinelRef.current);

})


  return (
    <Card className={classes.root} elevation={4}>
      <div className={classes.controls}>
        <IconButton aria-label="previous" className={classes.smallIcon} onClick={event => {
              event.preventDefault();
              console.log("prev....."+page)
              refine(page);
              if(page > 1)
              {
                  page = page-1;
              }
            }}>
          {theme.direction === 'rtl' ? <ArrowForwardIosRoundedIcon /> : <ArrowBackIosRoundedIcon />}
        </IconButton>
        <LightTooltip title="Save Profile" placement="top-start">
          <IconButton aria-label="save" className={classes.bigIcon}>
            <BookmarkRoundedIcon className={classes.saveIcon} />
          </IconButton>
        </LightTooltip>
        <IconButton aria-label="next" className={classes.smallIcon} onClick={event => {
              event.preventDefault();
              console.log("next......"+ page);
              refine(page);
              page = page + 1;
            }}>
          {theme.direction === 'rtl' ? <ArrowBackIosRoundedIcon /> : <ArrowForwardIosRoundedIcon />}
        </IconButton>
            <li
            className="ais-InfiniteHits-sentinel"
            ref= {sentinelRef

                }
          />
      </div>
      {/* <div className={classes.secondaryControls}>
        <div style={{flex: 1}}>
          <ArrowDropUpIcon className={classes.kbdIcon} />
          <ArrowDropDownIcon className={classes.kbdIcon} />
          <ArrowDropLeftIcon className={classes.kbdIcon}/>
          <ArrowDropRightIcon className={classes.kbdIcon}/>
        </div>
        <Button variant="contained" color="primary" size="small" style={{flex: 0.5, boxShadow: 'none', textTransform: 'capitalize', backgroundColor: '#2b9f81'}}>
          View Saved Profiles (10)
        </Button>
        <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
        <IconButton aria-label="send whatsapp" disabled className={classes.smallIcon}>
          <WhatsAppIcon />
        </IconButton>
        <IconButton aria-label="send sms" disabled className={classes.smallIcon}>
          <SmsIcon />
        </IconButton>
        </div>
      </div> */}
    </Card>
  );
}

export default ProfileCardNavbar_2;