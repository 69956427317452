import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Switch, Checkbox, TextField, Chip, Drawer } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LocationOnOutlined, PeopleAltOutlined } from '@material-ui/icons';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ListIcon from '@material-ui/icons/List';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InvitedListItem from '../views/openings/ListView';
import MobileInvitedListItem from '../views/openings/MobileApplicantsList';
import ApplicantsCard from '../views/openings/ProfileCardView';
// import DiscoveryList_2 from '../views/discovery/DiscoveryList_2'
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import LogoLoader from '../assets/logo-loader.gif';
import InfiniteScroll from 'react-infinite-scroll-component';
import JobDetail from './JobDetailModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import JobForm from './JobForm';
import MobileJobForm from './MobileJobForm';
import AnalyticsHandler from '../AnalyticsHandler';
import SnackBarGeneric from '../views/talentpool/SnackBarGeneric';
import Loader from '../views/company/Loader';
import JobUpdate from './JobUpdate';
import EventIcon from '@material-ui/icons/Event';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import bgIcon from '../assets/Iconwallpaper2.png';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.3em',
      backgroundColor: '#D5F5ED'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "23px",
    },
    '.MuiTabs-indicator': {
      height: '0px',
    },
    '.Mui-selected': {
      // backgroundColor: '#AFAFAF',
    },
    '.MuiTypography-h4 ': {
      fontSize: '1rem !important'
    },
    '.MuiAccordion-root': {
      boxShadow: 'none !important'
    }
  },
  dialogPaper: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important'
  },
  root: {
    flexGrow: 1,
  },
  pageInfoText: {
    marginBottom: 20,
    color: "#555",
    lineHeight: '2em'
  },
  tab_paper: {
    position: 'fixed',
    width: '100%',
    zIndex: '9',
    borderTop: '1px solid #AFAFAF',
    marginTop: '2px',
    display: 'none !important',
    '@media (max-width: 600px)': {
      display: 'block !important',
      position: 'sticky',
      borderTop: 'none',
      marginTop: 0,
      padding: 0,
      top: '7em',
      boxShadow: 'rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;'
    }
  },
  tabsDiv: {
    width: '99.5vw',
    marginTop: '-1em'
  },
  modal_div: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: '40px',
    zIndex: '1001',
    '@media (max-width: 600px)': {
      padding: 0,
      background: `url(${bgIcon})`,
      backgroundColor: '#fff'
    }
  },
  // job_modal: {
  //   marginTop: '3em',
  //   width: '70%',
  //   margin: 'auto',
  //   minHeight: '80vh',
  //   borderRadius: '6px'
  // },
  custom_tab: {
  },
  searchinput: {
    backgroundColor: '#fff',
    borderRadius: '23px',
    '@media (max-width: 600px)': {
      borderRadius: 0
    },
  },
  opportunities_section: {
    width: '88%',
    margin: 'auto',
    '@media (max-width: 1220px)': {
      width: '95%',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: 0,
    },
  },
  opportunities: {
    marginTop: '-1em',
  },
  opportunities_card: {
    border: '1px solid #14967B',
    borderRadius: '6px',
    backgroundColor: '#fff',
    width: '49%',
    margin: '1em 0',
    minHeight: '32vh',
    '@media (max-width: 600px)': {
      width: '100%',
    }
  },
  verif_status: {
    borderRadius: '6px 6px 0px 0px',
    padding: '0.5rem 1rem',
  },
  salary_detail: {
    padding: '0.2rem 0.8rem',
    borderRadius: '4px',
    display: 'inline-block',
    minHeight: '20px',
    margin: '0.3em 1em',
    backgroundColor: '#5BC2A8',
    '@media (max-width: 600px)': {
      color: '#333 !important',
      backgroundColor: 'initial',
      margin: '0 0.4em'

    }
  },
  job_status: {
    marginTop: '0.3em',
    padding: '0.5rem 1rem',
    '@media (max-width: 600px)': {
      flexDirection: 'column !important',
      alignItems: 'flex-start !important',
      padding: '0 1rem 0.5rem 1rem'
    }
  },
  job_details: {
    minHeight: '4.5vh',
    maxHeight: '4.5vh',
  },
  invite_button: {
    borderColor: theme.palette.primary.main,
    padding: '0px 10px !important',
    minHeight: '2.2rem',
  },
  role_name: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '30px',
    border: '1px solid #AFAFAF',
    padding: '0.7rem 1rem',
  },
  sortByCheckboxRoot: {
    marginRight: 0,
    '& span:nth-child(2)': {
      fontSize: 14
    }
  },
  application_card: {
    borderRadius: '6px',
    boxShadow: '2px 4px 7px 0px #0000001A',
    backgroundColor: '#fff',
    width: '60%',
    margin: 'auto',
    alignItems: 'flex-start !important',
    maxHeight: '40vh',
    overflow: 'visible',
  },
  connector: {
    backgroundColor: '#AFAFAF',
    height: '1px',
    width: '58%',
    margin: '4em auto',
  },
  role_select_box: {
    width: '80%',
    margin: '1em 0',
    justifyContent: 'center',
    '& .MuiInputBase-root': {
      width: 'auto',
      '@media (max-width: 600px)': {
        width: '50%'
      },
      '& .MuiSelect-select': {
        '&:focus': {
          backgroundColor: '#fff',
        }
      },
      '& .MuiOutlinedInput-input': {
        padding: '0.4em 2em 0.4em 0.4em',
        backgroundColor: '#fff',
        fontSize: '0.9rem'
      }
    },
    '@media (max-width: 600px)': {
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  applicantsTabBtn: {
    padding: '0 5px',
    minWidth: '10%',
    minHeight: '1.75em',
    maxHeight: '1.75em',
    backgroundColor: '#fff'
  },
  tabBtnWrapper: {
    flexDirection: 'row !important',
    textTransform: 'capitalize',
    fontWeight: 400
  },
  togBtnSizeSmall: {
    padding: '1px 12px',
    backgroundColor: '#FFFFFF',
    marginLeft: '0!important',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    }
  },
  togBtnLabel: {
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 400,
    color: '#555',
  },
  togBtnSelected: {
    backgroundColor: '#14967B!important',
    color: '#ffffff!important',
    '& > span': {
      color: '#ffffff!important',
    }
  },
  applicant_profile: {
    '& > div': {
      margin: '-5px auto 0 auto !important',
      '@media (max-width: 600px)': {
        margin: '0 auto 4em auto !important',
      },
    }
  },
  pageBar: {
    width: '885px',
    padding: '1em',
    flexBasis: 65,
    backgroundColor: "transparent",
    margin: '0 auto 0 5px',
    zIndex: 99,
    transition: '0.2s',
    position: 'sticky',
    top: 59,
    alignItems: 'center',
    '@media (max-width: 1127px)': {
      width: '866px',
    },
    '@media (max-width: 600px)': {
      width: '100%',
      margin: 0,
      alignItems: 'flex-start'
    },
  },
  backToPostedJobsIcon: {
    position: 'fixed',
    top: '10%',
    left: '2%',
    color: '#00000091',
    zIndex: 999,
    backgroundColor: '#f8f8f8',
    '@media (max-width: 600px)': {
      top: 0,
      left: 0,
      display: 'none'
    },
  },
  scrFormLabel: {
    fontSize: '0.95em'
  },
  exp_yrs: {
    position: 'absolute',
    top: '-2em',
    left: '-1em'
  },
  btns_container: {
    position: 'absolute',
    bottom: '-1em',
    left: '35%',
    zIndex: '100',
  },
  btn_grp_btns: {
    borderColor: theme.palette.primary.main,
    padding: '4px 15px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(252 252 252)',
    }
  },
  filterSidebar: {
    width: '18vw',
    position: 'fixed',
    left: '2%',
    height: '78vh',
    backgroundColor: '#fff',
    top: '18%',
    padding: '2em 1em',
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 99,
    '@media (max-width: 1197px)': {
      left: '2%',
    },
    '@media (max-width: 600px)': {
      display: 'none'
    },
  },
  actionBtns: {
    '@media (max-width: 1197px)': {
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  applicantSectionContainer: {
    '@media (min-width: 600px)': {
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '20vw'
    }
  },
  opportunity_cards: {
    '@media (max-width: 600px)': {
      padding: '0 10px',
      marginBottom: '5em'
    }
  },
  rolesHeading: {
    display: 'block',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  applicantsViewChangeIcon: {
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  slideBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #14967B',
    cursor: 'pointer',
    zIndex: 9
  },
  next: {
    right: '1%',
  },
  prev: {
    left: '1%',
  },
  backBtn: {
    position: 'absolute',
    top: '10%',
    left: '2%',
    color: '#fff'
  },
  disabledBtn: {
    opacity: '0.5',
    pointerEvents: 'none',
  },
  flexbox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  flexboxCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  inline_flex: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  justify_flexEnd: {
    justifyContent: 'flex-end'
  },
  justify_flexStart: {
    justifyContent: 'flex-start'
  },
  justify_center: {
    justifyContent: 'center'
  },
  justify_space_bw: {
    justifyContent: 'space-between'
  },
  justify_space_around: {
    justifyContent: 'space-around'
  },
  flex_flow_wrap: {
    flexWrap: 'wrap'
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  position_relative: {
    position: 'relative'
  },
  mr1: {
    marginRight: '0.5rem !important'
  },
  mr2: {
    marginRight: '1rem !important'
  },
  mr3: {
    marginRight: '0.3rem !important'
  },
  mb1: {
    marginBottom: '0.5rem !important'
  },
  my1: {
    margin: '0.5rem 0 !important'
  },
  my2: {
    margin: '1rem 0 !important'
  },
  my3: {
    margin: '1.5rem 0 !important'
  },
  my4: {
    margin: '2rem 0 !important'
  },
  mx1: {
    margin: '0 0.5rem !important'
  },
  mx2: {
    margin: '0 1rem !important'
  },
  margin_1: {
    margin: '1em !important'
  },
  margin_2: {
    margin: '2em !important'
  },
  py1: {
    padding: '0.5rem 0 !important'
  },
  py2: {
    padding: '1rem 0 !important'
  },
  px1: {
    padding: '0 0.5rem !important'
  },
  px2: {
    padding: '0 1rem !important'
  },
  padding_1: {
    padding: '0.5rem !important'
  },
  padding_2: {
    padding: '1rem !important'
  },
  width_100: {
    width: '100%'
  },
  width_max_content: {
    width: 'max-content'
  },
  fontSize_small: {
    fontSize: '0.8rem'
  },
  fontSize_medium: {
    fontSize: '0.9rem'
  },
  fontSize_xl: {
    fontSize: '2rem'
  },
  bold: {
    fontWeight: 'bold'
  },
  semi_bold: {
    fontWeight: '500'
  },
  color_primary: {
    color: theme.palette.primary.main
  },
  color_secondary: {
    color: theme.palette.secondary.main
  },
  color_white: {
    color: '#FFF',
  },
  color_black: {
    color: '#333',
  },
  cursor_pointer: {
    cursor: 'pointer'
  },
  ttc: {
    textTransform: 'capitalize',
  },
  d_inline_block: {
    display: 'inline-block'
  },
  width_50: {
    width: '50%'
  },
  color_light_grey: {
    color: '#797979'
  },
  color_orange: {
    color: '#F27726'
  },
  bg_primary: {
    backgroundColor: '#5BC2A8',
  },
  bg_secondary: {
    backgroundColor: '#D5F5ED',
  },
  bg_white: {
    backgroundColor: '#fff',
  },
  searchBarJobs: {
    // width: '30%',
    marginTop: '2em',
    '@media (max-width: 600px)': {
      marginTop: 0,
      position: 'sticky',
      top: '3em',
      background: '#fff',
      padding: '1em 0px',
      zIndex: 9
    },
    '& > div': {
      width: '30%',
      '@media (max-width: 600px)': {
        width: '95%'
      },
    }
  },
  sortingChip: {
    minWidth: 45,
    height: 26,
    marginRight: 5,
    color: '#afafaf',
    border: '1px solid #afafaf'
  },
  sortingChipActive: {
    minWidth: 45,
    height: 26,
    marginRight: 5,
    color: '#55c6a9',
    border: '1px solid #55c6a9'
  },
  sortingChipClicked: {
    backgroundColor: '#fff'
  },
  filterDrawerPaper: {
    height: '80vh',
    borderRadius: '22px 22px 0 0'
  },
  mobileSortFilterBtn: {
    '& span': {
      '& span': {
        marginRight: 2
      }
    },
    '@media (min-width: 600px)': {
      display: 'none'
    },
  },
  sliderContainer: {
    position: 'relative',
    display: 'flex',
    overflowX: 'scroll',
    marginBottom: 15,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '@media (min-width: 600px)': {
      display: 'none'
    },
  },
  slider: {
    display: 'flex'
  }
}));

// loader
export function Spinner(props) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px', minHeight: props.minHeight ? props.minHeight : '' }}>
      <img src={LogoLoader} alt="loading" style={{ maxWidth: '50px' }} />
    </div>
  )
}

const isMobile = window.innerWidth <= 600 ? true : false;

// job cards component
export function JobCard(props) {
  const { classes, job, index, jobDetailsRef, createDate, setJobIndex, setJDLoader, setOpenJobModal, searchApplicants, getJobApplicants, setOpenConfirmDialog, setCurrentJobId, setRoleType, handleSnackBar } = props;
  return <>
    <div className={`${classes.verif_status} ${classes.flexbox} ${classes.justify_space_bw}`} style={{ backgroundColor: job.isVerified ? '#D5F5ED' : '#E0E0E0' }}>
      <Typography variant='body1' component='span' className={`${classes.flexbox} ${classes.fontSize_medium}`}
        style={{ color: job.isVerified ? '#14967B' : '#797979' }}
      >
        <VerifiedUserIcon fontSize='small' className={classes.mr3} />
        {job.isVerified ? 'Verified' : 'Pending Verification'}
      </Typography>
      <Typography variant='body1' component='span' className={`${classes.flexbox} ${classes.fontSize_medium}`} >
        Updated: {createDate(job.job_updated_at)}
      </Typography>
    </div>
    <div className={classes.card_body}>
      <div className={`${classes.flexbox} ${classes.justify_space_bw} ${classes.px2} ${classes.my1}`}>
        <Typography component='span' className={classes.bold}>
          {job.job_title}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <EditOutlinedIcon color='primary' className={`${classes.mr1} ${classes.cursor_pointer}`}
            onClick={() => {
              AnalyticsHandler.track("JobOpenings_editJobBtn")
              props.setJobUpdateLoader(true);
              props.setShowJobUpdatePage(true);
              // props.setJobFormModalLoader(true);
              props.getJobDataToUpdateJob(job);
            }}
          />
          <CopyToClipboard text={`${process.env.REACT_APP_NODE_ENV === 'development' ? 'https://jsdev.meetworks.in' : 'https://findwork.meetworks.in'}/jobDetail/${job.job_title}-${localStorage.getItem('company_name')}-${job.job_location && typeof job.job_location === 'string' ? job.job_location + '-' + job._id : job._id}`} >
            <ShareOutlinedIcon color='primary' className={`${classes.cursor_pointer}`}
              onClick={() => { AnalyticsHandler.track('JobOpenings_shareJob_btn'); handleSnackBar(`Link copied. Share now!`) }}
            />
          </CopyToClipboard>
        </div>
      </div>
      <Typography component='span' className={`${classes.semi_bold} ${classes.salary_detail} ${classes.color_white}`}>
        {job.job_salary_amount && (job.job_salary_amount !== '' || job.job_salary_amount > 0) ? (job.job_salary_amount.includes('₹') ? job.job_salary_amount : `₹ ${job.job_salary_amount}`) : 'No fixed salary'}
      </Typography>
      <div className={`${classes.job_details} ${classes.flexbox}  ${classes.px2}`}>
        {job.opportunity_type &&
          <Typography component='span'
            className={`${classes.flexbox} ${classes.mr1} ${classes.semi_bold} ${classes.fontSize_medium}`}
            style={{
              color: job.opportunity_type.includes('ob') ? 'rgb(249, 150, 45)' : job.opportunity_type.includes('nternship') ? 'rgb(20, 150, 123)' : job.opportunity_type.includes('renticeship') ? 'rgb(114, 86, 179)' : job.opportunity_type.includes('reelanc') ? 'rgb(94, 150, 221)' : '#333'
            }}
          >
            <WorkOutlineIcon fontSize='small' className={`${classes.mr3}`} />
            {job.opportunity_type}
            {/* {job.opportunity_type.includes('reelanc') ? 'Part-time' : job.opportunity_type.includes('ob') ? 'Full-time' : job.opportunity_type.includes('nternship') ? 'Internship' : job.opportunity_type.includes('renticeship') ? 'Apprenticeship' : ''} */}
          </Typography>}
        {job.opportunity_type && job.no_of_vacancies && <Typography component='h2' className={classes.fontSize_xl} > &middot; </Typography>}
        <Typography component='span' className={`${classes.flexbox} ${classes.semi_bold} ${classes.fontSize_medium} ${classes.my1}`}>
          {job.no_of_vacancies > 0 ? <PermIdentityIcon fontSize='small' className={classes.mr3} /> : ''}
          {job.no_of_vacancies ? job.no_of_vacancies + ' ' + (job.no_of_vacancies > 1 ? 'openings' : 'opening') : ''}
          {/* {job.no_of_vacancies ? job.no_of_vacancies : ''} */}
        </Typography>
      </div>
      <div className={`${classes.flexbox} ${classes.px2}`} style={{ justifyContent: job.job_location ? 'space-between' : 'flex-end', borderBottom: '1px solid #AFAFAF' }}>
        {job.job_location && <Typography component='span' className={`${classes.flexbox} ${classes.mb1} ${classes.fontSize_medium}`}>
          <LocationOnOutlined fontSize='small' className={classes.mr3} />
          {typeof job.job_location === 'string' ?
            job.job_location :
            job.job_location.length > 0 ? (job.job_location.length === 1 ? (job.job_location[0].city || job.job_location[0].address) + (job.isWorkFromHome ? ' + 1 other' : '') : `${job.job_location[0].city} + ${job.isWorkFromHome ? (job.job_location.length - 1) + 1 : job.job_location.length - 1} ${job.job_location.length === 2 ? 'other' : 'others'}`) : (job.isWorkFromHome ? 'Work From Home' : '')
          }
        </Typography>}
        <Typography component='span' color='primary' className={`${classes.flexbox} ${classes.cursor_pointer}`}
          onClick={(e) => {
            AnalyticsHandler.track("JobOpenings_viewJobDetailsBtn")
            setJobIndex(index)
            //newly added
            setCurrentJobId(job._id);
            setJDLoader(true);
            setOpenJobModal(true)
          }
          } >
          Details
          <ExpandMoreIcon color='primary' />
        </Typography>
      </div>
      <div className={`${classes.job_status} ${classes.flexbox} ${classes.justify_space_bw}`}>
        <div className={classes.inline_flex}>
          <Typography component='span' className={`${classes.color_light_grey} ${classes.mr3}`}>Status: </Typography>
          <FormControlLabel
            control={
              <Switch
                // id={job._id}
                onChange={() => {
                  AnalyticsHandler.track("JobOpenings_jobStatusBtn");
                  setOpenConfirmDialog(true)
                  // props.setCurrentJob(job)
                  props.getJobDataToUpdateJob(job)
                }}
                color='primary'
                name={`status${index}`}
                checked={job.enable_status ? true : false}
              />}
            label={job.enable_status ? "Open" : "Closed"}
            labelPlacement='end'
            className={classes.settings_label}

          />
        </div>
        <div className={`${classes.inline_flex} ${classes.actionBtns}`}>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.mr1} ${classes.ttc} ${classes.invite_button}`}
            startIcon={<GroupAddIcon />}
            onClick={() => {
              AnalyticsHandler.track("JobOpenings_inviteJobseekersBtn");
              let val = jobDetailsRef.current[index].getAttribute('id');
              if (val === 'true') {
                searchApplicants(index, props.openRoles, job.job_location, job);
              } else {
                searchApplicants(index, props.closedRoles, job.job_location, job);
              }
            }}
          >
            Invite jobseekers
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.ttc} ${classes.invite_button}`}
            startIcon={<GroupAddIcon />}
            onClick={() => {
              AnalyticsHandler.track("JobOpenings_viewApplicantsBtn");
              // props.getJobDataToUpdateJob(job);
              props.setProfileView('list')
              props.setSingleHitOffset(0);
              props.setListView(true);
              props.setCardView(false);
              setJobIndex(index)
              let val = jobDetailsRef.current[index].getAttribute('id');
              props.getJobDataToUpdateJob(job)
              if (val === 'true') {
                props.jobCardsRef.current.style.display = 'none'
                props.jobsTypeTabRef.current.style.display = 'none'
                setRoleType(props.openRoles);
                props.setSortApplicantsVal(0);
                props.setSortApplicants('all');
                getJobApplicants(index, props.openRoles)
              } else {
                props.jobCardsRef.current.style.display = 'none'
                props.jobsTypeTabRef.current.style.display = 'none'
                setRoleType(props.closedRoles);
                getJobApplicants(index, props.closedRoles)
              }
              // to invite candidates if no applicants found
              props.setCurrentJobLocation(job.job_location);
              props.setCurrentJobStatus(val);
              props.setCurrentJob(job);
            }}
          >
            Applicants
          </Button>
        </div>
      </div>
    </div>
  </>
}

// secondary tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

// propTypes for secondary tab
TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function filterProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function JobOpenings() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [mainTabValue, setMainTabValue] = React.useState(0);
  const [openJobModal, setOpenJobModal] = React.useState(false);
  const [postedJobs, setPostedJobs] = React.useState([]);
  const [resultsOffset, setResultsOffset] = React.useState(100);
  const [applicantResultsOffset, setApplicantResultsOffset] = React.useState(10);
  const [profileView, setProfileView] = React.useState('list');
  const [totalResults, setTotalResults] = React.useState(0);
  const [totalApplicantsResults, setTotalApplicantsResults] = React.useState(0);
  const [jobId, setJobId] = React.useState('');
  const [jobIndex, setJobIndex] = React.useState(0);
  const [profileIndex, setProfileIndex] = React.useState(0);
  const [showAllApplications, setShowAllApplications] = React.useState(true);
  const [showRoleApplications, setShowRoleApplications] = React.useState(false);
  const [applicantRole, setApplicantRole] = React.useState('');
  const [loader, setLoader] = React.useState(true);
  const [JDLoader, setJDLoader] = React.useState(false);
  const [listView, setListView] = React.useState(true);
  const [cardView, setCardView] = React.useState(false);
  const [hits, setHits] = React.useState([]);
  const [allHits, setAllHits] = React.useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [sortApplicants, setSortApplicants] = React.useState('all');
  const [sortApplicantsVal, setSortApplicantsVal] = React.useState(0);
  // const [allApplicants, setAllApplicants] = React.useState([]);
  const [currentJob, setCurrentJob] = React.useState(null);
  const [openJobRoles, setOpenJobRoles] = React.useState([]);
  const [closedJobRoles, setClosedJobRoles] = React.useState([]);
  const [allJobsByCompany, setAllJobsByCompany] = React.useState([]);
  const [currentJobId, setCurrentJobId] = React.useState('');
  const [refresh, doRefresh] = React.useState(false);
  const [jobData, setJobData] = React.useState({});
  const [currentJobLocation, setCurrentJobLocation] = React.useState('');
  const [currentJobStatus, setCurrentJobStatus] = React.useState('');
  const [openJobPostModal, setOpenJobPostModal] = React.useState(false);
  const [roleType, setRoleType] = React.useState(null);
  const [jobFormModalLoader, setJobFormModalLoader] = React.useState(true);
  const [showJobUpdatePage, setShowJobUpdatePage] = React.useState(false);
  const [jobUpdateLoader, setJobUpdateLoader] = React.useState(false);
  const [sortWith, setSortWith] = React.useState('date');
  const [location, setLocation] = React.useState([]);
  const openRolesRef = React.useRef([]);
  const closedRolesRef = React.useRef([]);
  const [jobFormVisibility, setJobFormVisibility] = React.useState('');
  const [openMobileSortList, setOpenMobileSortList] = React.useState(false)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false)
  const history = useHistory();
  //states to apply filter in applicants section

  let initialRender = React.useRef(true);

  const jobCardsRef = React.useRef(null);
  const jobsTypeTabRef = React.useRef(null);

  //tab vals for responsive filter drawer 
  const [mobileFilterTabVal, setMobileFilterTabVal] = React.useState(0);

  const handleMobileFilterTabChange = (event, newValue) => {
    setMobileFilterTabVal(newValue);
  };

  const [filters, setFilters] = React.useState({
    location_agree: false,
    salary_agree: false,
    own_laptop: false,
    own_vehicle: false,
    valid_dl_license: false,
    salary_expectation: false,
    salary_expectation: false,
    why_good_fit: false,
    change_job_reason: false,
    location: ''
  });

  const [query, setQuery] = React.useState([]);
  const [queryParams, setQueryParams] = React.useState([]);

  React.useEffect(() => {
    axios.get(BASE_URL + '/select/get_all_locations').then((Result) => {
      let locArr = Result.data;
      let filtered = locArr.filter(item => item.city !== 'Work From Home')
      setLocation(filtered);
    })
  }, []);

  // change tab value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // scroll functionality for applicants tab
  const pageBarRef = React.useRef(null);
  let lastScrollY = 0;
  let ticking = false;

  const handleScrollChange = () => {
    lastScrollY = window.scrollY;
    if (lastScrollY > 20) {
      ticking = true
    } else {
      ticking = false
    }
    if (pageBarRef.current !== null) {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          pageBarRef.current.style.background = 'transparent';
          pageBarRef.current.style.boxShadow = "none";
          pageBarRef.current.style.flexBasis = "65px";
        });
      } else if (ticking) {
        window.requestAnimationFrame(() => {
          pageBarRef.current.style.background = '#f9f9f9';
          pageBarRef.current.style.boxShadow = "0px 5px 5px rgba(0,0,0,0.05)";
          pageBarRef.current.style.flexBasis = "50px";
        });
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScrollChange);
    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, []);

  // scroll functionality ends

  // create job updating date in a particular format
  const createDate = (date) => {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let responseDate = date ? new Date(date) : null;
    let jobUpdatedDate = date ? responseDate.getDate() + " " + months[responseDate.getMonth()] + ", " + responseDate.getFullYear() : null
    return jobUpdatedDate;
  }

  // snackbar callback starts
  const [snackBarMessage, setSnackBarMessage] = React.useState('')
  const [refreshSnackBar, doRefreshSnackBar] = React.useState(false);
  const [canSnackBarOpen, setCanSnackBarOpen] = React.useState(false);
  const handleSnackBar = (message) => {
    setCanSnackBarOpen(true);
    setSnackBarMessage(message);
    doRefreshSnackBar(prev => !prev)
  }
  // snackbar callback ends

  // handle job details modal
  const handleBack = () => {
    setOpenJobModal(false);
  }
  // get all jobs posted by an employer
  React.useEffect(() => {
    setLoader(true);
    setSearchQuery('')
    axios.get(`${BASE_URL}/companies/getalljobpostingbycompanyid/${localStorage.getItem('company_id')}/0/100`)
      .then(res => {
        if (res.data[0].results.length > 0) {
          setPostedJobs(res.data[0].results);
          setOpenJobRoles(res.data[0].results.filter(job => job.enable_status === true));
          setClosedJobRoles(res.data[0].results.filter(job => job.enable_status !== true));
          setTotalResults(res.data[0].totalCount[0].count);
        } else {
          setPostedJobs([]);
          setOpenJobRoles([]);
          setClosedJobRoles([]);
          setTotalResults(0);
        }
        setLoader(false);
      })
      .catch(err => {
        // console.log(err);
      })
  }, [refresh]);

  // fetch more data for posted job when user scroll at the bottom
  const fetchMoreData = async () => {
    setResultsOffset(prev => prev + 100);
    axios.get(`${BASE_URL}/companies/getalljobpostingbycompanyid/${localStorage.getItem('company_id')}/${resultsOffset}/100`)
      .then(res => {
        setPostedJobs(postedJobs.concat(res.data[0].results));
        setOpenJobRoles(openJobRoles.concat(res.data[0].results.filter(job => job.enable_status === true)));
        setClosedJobRoles(closedJobRoles.concat(res.data[0].results.filter(job => job.enable_status !== true)));
        // setTotalResults(res.data[0].totalCount[0].count)
      })
  };

  // get data for application landing page
  // const getAllApplications = () => {
  //   // add job ids array
  //   const jobIds = [];
  //   for (let i = 0; i < postedJobs.length; i++) {
  //     jobIds.push(postedJobs[i]._id);
  //   }

  //   if (jobIds.length > 0) {
  //     jobIds.forEach(id => {
  //       axios.get(`${BASE_URL}/jobposting/appliedApplication/0/20/${id}`)
  //         // axios.get(`${BASE_URL}/jobposting/appliedApplication/0/20/60c9c38394c75e778ffe5446`)
  //         .then(res => {
  //           setAllApplicants(allApplicants.concat(res.data[0].User_Data));
  //         })
  //         .catch(err => {
  //           // console.log(err);
  //         })
  //     })
  //   }
  // }
  // get applicants for a particular job
  const getJobApplicants = (index, roles, queryParams, isFiltersApplied) => {
    window.scrollTo(0, 0);
    setLoader(true);
    setProfileIndex(0);
    setShowRoleApplications(true);
    setShowAllApplications(false);
    let id = ''
    if (!isFiltersApplied) {
      id = roles[index]._id;
      setJobId(id);
      setApplicantRole(`${roles[index].job_title}${id}`);
    }
    setMainTabValue(1);
    // if (profileView === 'list') {
    if (sortApplicantsVal === 0) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/0/10/${!isFiltersApplied ? id : jobId}${queryParams ? `?${queryParams}` : ''}`)
          .then(res => {
            setHits([...res.data[0].User_Data]);
            setAllHits([...res.data[0].User_Data]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setLoader(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/0/10/${!isFiltersApplied ? id : jobId}?sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...res.data[0].User_Data]);
            setAllHits([...res.data[0].User_Data]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setLoader(false);
          })
      }
    } else if (sortApplicantsVal === 1 || sortApplicantsVal === 2 || sortApplicantsVal === 3 || sortApplicantsVal === 4) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/0/10/${!isFiltersApplied ? id : jobId}?status=${sortApplicants}${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...res.data[0].User_Data]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setLoader(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/0/10/${!isFiltersApplied ? id : jobId}?status=${sortApplicants}&sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...res.data[0].User_Data]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setLoader(false);
          })
      }
    }
    // } else {
    //   getOneByOneApplicants();
    // }
  }


  const fetchMoreApplicants = async () => {
    setApplicantResultsOffset(prev => prev + 10);
    if (sortApplicantsVal === 0) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${applicantResultsOffset}/10/${jobId}${queryParams ? `?${queryParams}` : ''}`)
          .then(res => {
            setHits([...hits, ...res.data[0].User_Data]);
            setTotalApplicantsResults(res.data[0].totalCount[0].count);
            setShowRoleApplications(true);
            setShowAllApplications(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${applicantResultsOffset}/10/${jobId}?sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...hits, ...res.data[0].User_Data]);
            setTotalApplicantsResults(res.data[0].totalCount[0].count);
            setShowRoleApplications(true);
            setShowAllApplications(false);
          })
      }
    } else if (sortApplicantsVal === 1 || sortApplicantsVal === 2 || sortApplicantsVal === 3 || sortApplicantsVal === 4) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${applicantResultsOffset}/10/${jobId}?status=${sortApplicants}${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...hits, ...res.data[0].User_Data]);
            setTotalApplicantsResults(res.data[0].totalCount[0].count);
            setShowRoleApplications(true);
            setShowAllApplications(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${applicantResultsOffset}/10/${jobId}?status=${sortApplicants}&sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            setHits([...hits, ...res.data[0].User_Data]);
            setTotalApplicantsResults(res.data[0].totalCount[0].count);
            setShowRoleApplications(true);
            setShowAllApplications(false);
          })
      }
    }
  }

  React.useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (postedJobs.length > 0) {
        if (roleType !== null) {
          if (profileView === 'list') {
            getJobApplicants(jobIndex, roleType, queryParams, queryParams.length > 0 ? true : false);
          } else {
            getOneByOneApplicants(0, queryParams);
          }
        }
      }
    }
  }, [sortApplicants, sortWith]);

  // close confirmation dilog when user changes the job status
  const closeDialog = () => {
    setOpenConfirmDialog(false);
  }

  //search applicants when clicked on invite jobseekers button
  const searchApplicants = (index, roles, location, job) => {
    let mapped_role = roles[index].job_title;
    mapped_role = mapped_role.includes('/') ? mapped_role.replaceAll('/', '-') : mapped_role;
    var newPath;
    if (location && location.length > 0) {
      newPath = `/discover/${mapped_role}/${location[0].city}`;
    } else if (job.isWorkFromHome) {
      newPath = `/discover/${mapped_role}/anywhere`;
    } else {
      newPath = `/discover/${mapped_role}/anywhere`;
    }
    history.push({
      pathname: newPath,
    })

  }
  // update the job
  const getJobDataToUpdateJob = (job) => {
    // setJobFormModalLoader(true);
    axios.post(`${BASE_URL}/companies/getJobDetails`, { _id: job._id })
      .then(res => {
        let company_id = localStorage.getItem('company_id').toString()
        let jobpost_id = job._id;
        let latLngArr = [];
        let currentJobData = { ...res.data, jobpost_id, company_id };
        let data;
        if (currentJobData.geo_json) {
          currentJobData.geo_json.coordinates.forEach(element => {
            if (typeof element === 'number' && (element !== 0.1276 && element !== 51.5072)) {
              latLngArr.push(element);
              data = { ...currentJobData, geo_json: { type: 'MultiPoint', coordinates: [latLngArr] } }
            } else if (element === 0.1276 || element === 51.5072) {
              data = { ...currentJobData, geo_json: null }
            } else {
              data = { ...currentJobData }
            }
          })
        } else {
          data = { ...currentJobData }
        }
        delete data._id
        delete data.companies
        setJobData(data);
        setJobUpdateLoader(false);
      })
  }
  //update the job status
  const changeJobStatus = (job) => {
    AnalyticsHandler.track("JobOpenings_confirmBtn_ChangeJobStaus")
    setOpenConfirmDialog(false);
    setJDLoader(true);
    // let currentJob = job;
    axios.post(`${BASE_URL}/jobposting/v2/jobpost/`, { ...job, enable_status: !job.enable_status })
      .then(res => {
        // console.log(res);
        doRefresh(!refresh);

      }).catch(err => {
        // console.log(err)
      })
  }

  // get all jobs with job title and job id
  React.useEffect(() => {
    axios.get(`${BASE_URL}/jobposting/jobpost/0/100/${localStorage.getItem('company_id')}`)
      .then(res => {
        setAllJobsByCompany(res.data[0].results);
        window.scrollTo(0, 0);
      })
      .catch(err => {
        // console.log(err);
      })
  }, [refresh])

  const backToOpportunities = () => {
    setFilters({
      location_agree: false,
      salary_agree: false,
      own_laptop: false,
      own_vehicle: false,
      valid_dl_license: false,
      salary_expectation: false,
      salary_expectation: false,
      why_good_fit: false,
      change_job_reason: false,
      location: ''
    })
    jobCardsRef.current.style.display = 'initial';
    jobsTypeTabRef.current.style.display = 'block'
    setApplicantResultsOffset(10)
    setMainTabValue(0);
    setShowRoleApplications(false)
    // setSortApplicants('all');
  }

  const [searchQuery, setSearchQuery] = React.useState('');
  const [singleHit, setSingleHit] = React.useState({})
  const [singleHitOffset, setSingleHitOffset] = React.useState(0);
  const [profileViewLoader, setProfileViewLoader] = React.useState(false);

  //get profiles for card view in applicant section while clicking next & back button
  const getOneByOneApplicants = (offset, queryParams) => {
    setProfileViewLoader(true);
    if (sortApplicantsVal === 0) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${offset !== undefined ? offset : singleHitOffset}/10/${jobId}${queryParams ? `?${queryParams}` : ''}`)
          .then(res => {
            // setHits([...res.data[0].User_Data]);
            setSingleHit(res.data[0].User_Data[0]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setProfileViewLoader(false);
            setLoader(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${offset !== undefined ? offset : singleHitOffset}/10/${jobId}?sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            // setHits([...res.data[0].User_Data]);
            setSingleHit(res.data[0].User_Data[0]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setProfileViewLoader(false);
            setLoader(false);
          })
      }
    } else if (sortApplicantsVal === 1 || sortApplicantsVal === 2 || sortApplicantsVal === 3 || sortApplicantsVal === 4) {
      if (sortWith === 'lastActive') {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${offset !== undefined ? offset : singleHitOffset}/10/${jobId}?status=${sortApplicants}${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            // setHits([...res.data[0].User_Data]);
            setSingleHit(res.data[0].User_Data[0]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setProfileViewLoader(false);
            setLoader(false);
          })
      } else {
        axios.get(`${BASE_URL}/jobposting/appliedApplication/${offset !== undefined ? offset : singleHitOffset}/10/${jobId}?status=${sortApplicants}&sortBy=date${queryParams ? `&${queryParams}` : ''}`)
          .then(res => {
            // setHits([...res.data[0].User_Data]);
            setSingleHit(res.data[0].User_Data[0]);
            if (res.data[0].User_Data.length > 0) {
              setTotalApplicantsResults(res.data[0].totalCount[0].count);
            } else {
              setTotalApplicantsResults(0);
            }
            setProfileViewLoader(false);
            setLoader(false);
          })
      }
    }
  }

  let initialRender2 = React.useRef(true);

  React.useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      getOneByOneApplicants(singleHitOffset, queryParams);
    }
  }, [singleHitOffset])


  function removeA(arr) {
    var name, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
      name = a[--L];
      while ((ax = arr.indexOf(name)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  const setFilterValues = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.checked });
    if (e.target.checked) {
      setQuery([...query, e.target.value])
    } else {
      setQuery(removeA(query, e.target.value))
    }
  }

  const applyFilters = () => {
    let filteredResults = [...query]
    if (filters.location) {
      filteredResults.push(`location=${filters.location}`)
    }
    let filteredQuery = filteredResults.join('&')
    setQueryParams(filteredQuery);

    if (profileView === 'list') {
      getJobApplicants(jobIndex, postedJobs, filteredQuery, true)
    } else {
      setSingleHitOffset(0);
      getOneByOneApplicants(0, filteredQuery)
    }
    if (isFilterDrawerOpen) setIsFilterDrawerOpen(false)
  }

  const handleMobileSortWith = (e, index) => {
    let val = e.target.innerText
    if (val === 'All') {
      setSortApplicants('all');
      setSortApplicantsVal(0);
    } else if (val === 'Shortlisted') {
      setSortApplicants(val)
      setSortApplicantsVal(1);
    } else if (val === 'Hired') {
      setSortApplicants(val)
      setSortApplicantsVal(2);
    } else if (val === 'Rejected') {
      setSortApplicants(val)
      setSortApplicantsVal(3);
    } else if (val === 'Pending') {
      setSortApplicants('Applied')
      setSortApplicantsVal(4);
    }
    setApplicantResultsOffset(10)
    setSingleHitOffset(0)
  }

  return (
    <>
      {!showJobUpdatePage && <div className={classes.openings_card}>
        <div className={classes.tabsDiv}>
          {/* <Paper className={classes.tab_paper}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="secondary"
            centered
          >
            <Tab label="Opportunities" className={`${classes.ttc} ${classes.custom_tab}`} {...a11yProps(0)} />
            <Tab label="Applications" className={`${classes.ttc} ${classes.custom_tab}`} {...a11yProps(1)}
              onClick={() => {
                setShowAllApplications(true);
                setShowRoleApplications(false);
                // getAllApplications();
              }} />
          </Tabs>
        </Paper> */}
          {/* opportunities section */}
          {/* <TabPanel value={value} index={0}> */}
          <div ref={jobCardsRef} className={classes.opportunities}>
            <div className={`${classes.searchBarJobs} ${classes.flexbox} ${classes.justify_center}`}>

              {/* searchbar to search jobs by job_title */}
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={searchQuery}
                  onChange={e => {
                    e.preventDefault()
                    const val = e.target.value;
                    setSearchQuery(val)
                    if (val !== '') {
                      setOpenJobRoles(postedJobs.filter(item => item.enable_status && item.job_title !== null && item.job_title.toLowerCase().includes(val.toLowerCase()) ? item : null))
                      setClosedJobRoles(postedJobs.filter(item => !item.enable_status && item.job_title !== null && item.job_title.toLowerCase().includes(val.toLowerCase()) ? item : null))
                    } else {
                      setOpenJobRoles(postedJobs.filter(item => item.enable_status === true))
                      setClosedJobRoles(postedJobs.filter(item => item.enable_status === false))
                    }
                  }}
                  placeholder='Search by job title'
                  className={classes.searchinput}
                  endAdornment={searchQuery.length > 0 ? <InputAdornment position="end">
                    <ClearIcon style={{ cursor: 'pointer', color: '#797979' }}
                      onClick={() => {
                        setSearchQuery('');
                        setOpenJobRoles(postedJobs.filter(item => item.enable_status === true))
                        setClosedJobRoles(postedJobs.filter(item => item.enable_status === false))
                      }}
                    />
                  </InputAdornment> : null}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  labelWidth={0}
                />
              </FormControl>
            </div>
            <div className={`${classes.opportunities_section} ${classes.flexboxCol} ${classes.justify_space_bw}`}>
              <Paper ref={jobsTypeTabRef} className={classes.tab_paper}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="secondary"
                  centered
                >
                  <Tab label="Open Jobs" style={{ borderBottom: value === 0 ? '2px solid' : '' }} className={`${classes.ttc} ${classes.custom_tab}`} {...a11yProps(0)} />
                  <Tab label="Closed Jobs" style={{ borderBottom: value === 1 ? '2px solid' : '' }} className={`${classes.ttc} ${classes.custom_tab}`} {...a11yProps(1)}
                    onClick={() => {
                      setShowAllApplications(true);
                      setShowRoleApplications(false);
                      // getAllApplications();
                    }} />
                </Tabs>
              </Paper>
              {loader &&
                <div className={`${classes.flexbox} ${classes.justify_center}`} style={{ minHeight: '80vh' }}>
                  <Spinner />
                </div>
              }
              {!loader && <InfiniteScroll
                dataLength={postedJobs.length}
                next={fetchMoreData}
                hasMore={postedJobs.length !== totalResults}
                loader={<Spinner />}
                scrollThreshold={0.9}
              >
                <Typography variant='h6' component='h1' className={`${classes.my2} ${classes.rolesHeading}`} style={{ borderBottom: '1px solid #AFAFAF' }}>Open Jobs</Typography>
                {value === 0 && <div className={`${classes.opportunity_cards} ${classes.flexbox} ${classes.flex_flow_wrap}`} style={{ justifyContent: openJobRoles.length > 0 ? 'space-between' : 'center' }}>
                  {/* {postedJobs.filter(job => job.enable_status === true).map((job, index) => { */}
                  {openJobRoles.length > 0 ? openJobRoles.map((job, index) => {
                    return <div key={index} id={`${job.enable_status}`} ref={el => openRolesRef.current[index] = el} className={`${classes.opportunities_card}`}>
                      <JobCard classes={classes} job={job} openRoles={openJobRoles} index={index} jobDetailsRef={openRolesRef} createDate={createDate} setJobIndex={setJobIndex} setJDLoader={setJDLoader} setOpenJobModal={setOpenJobModal} searchApplicants={searchApplicants} getJobApplicants={getJobApplicants} setOpenConfirmDialog={setOpenConfirmDialog} setCurrentJobId={setCurrentJobId} setJobData={setJobData} getJobDataToUpdateJob={getJobDataToUpdateJob} setCurrentJobLocation={setCurrentJobLocation} setCurrentJobStatus={setCurrentJobStatus} setRoleType={setRoleType} handleSnackBar={handleSnackBar} setCurrentJob={setCurrentJob} setShowJobUpdatePage={setShowJobUpdatePage} setJobFormModalLoader={setJobFormModalLoader} setJobUpdateLoader={setJobUpdateLoader} setProfileView={setProfileView} setSingleHitOffset={setSingleHitOffset} setListView={setListView} setCardView={setCardView} setSortApplicantsVal={setSortApplicantsVal} setSortApplicants={setSortApplicants} jobCardsRef={jobCardsRef} jobsTypeTabRef={jobsTypeTabRef} />
                    </div>
                  }) :
                    <div className={`${classes.justify_center}`} style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <Typography variant="h6" className={classes.pageInfoText} style={{ width: '60%', textAlign: 'center' }}>
                        No open roles available. However you can create a new role by clicking on the button below.
                      </Typography>
                      <Button id="postjob_btn_id" variant="contained" color="primary" className={`${classes.headerButton} ${classes.ttc} ${classes.headerButton_pri}`} style={{ marginRight: 15 }} onClick={() => {
                        setOpenJobPostModal(true);
                      }}>
                        Post Job
                      </Button>
                    </div>}
                </div>}
                {closedJobRoles.length > 0 && <Typography variant='h6' component='h3' className={`${classes.my2} ${classes.rolesHeading}`} style={{ borderBottom: '1px solid #AFAFAF' }}>Closed Jobs</Typography>}
                {closedJobRoles.length > 0 && isMobile && value === 1 ? <div className={`${classes.opportunity_cards} ${classes.flexbox} ${classes.justify_space_bw} ${classes.flex_flow_wrap}`}>
                  {closedJobRoles.map((job, index) => {
                    return <div key={index} id={`${job.enable_status}`} ref={el => closedRolesRef.current[index] = el} className={`${classes.opportunities_card}`}>
                      <JobCard classes={classes} job={job} closedRoles={closedJobRoles} index={index} jobDetailsRef={closedRolesRef} createDate={createDate} setJobIndex={setJobIndex} setJDLoader={setJDLoader} setOpenJobModal={setOpenJobModal} searchApplicants={searchApplicants} getJobApplicants={getJobApplicants} setOpenConfirmDialog={setOpenConfirmDialog} setCurrentJobId={setCurrentJobId} setJobData={setJobData} getJobDataToUpdateJob={getJobDataToUpdateJob} setCurrentJobLocation={setCurrentJobLocation} setCurrentJobStatus={setCurrentJobStatus} setRoleType={setRoleType} handleSnackBar={handleSnackBar} setCurrentJob={setCurrentJob} setShowJobUpdatePage={setShowJobUpdatePage} setJobFormModalLoader={setJobFormModalLoader} setJobUpdateLoader={setJobUpdateLoader} setProfileView={setProfileView} setSingleHitOffset={setSingleHitOffset} setListView={setListView} setCardView={setCardView} setSortApplicantsVal={setSortApplicantsVal} setSortApplicants={setSortApplicants} jobsTypeTabRef={jobsTypeTabRef} jobCardsRef={jobCardsRef} />
                    </div>
                  })}

                </div> : (closedJobRoles.length > 0 && !isMobile) ? <div className={`${classes.opportunity_cards} ${classes.flexbox} ${classes.justify_space_bw} ${classes.flex_flow_wrap}`}>
                  {closedJobRoles.map((job, index) => {
                    return <div key={index} id={`${job.enable_status}`} ref={el => closedRolesRef.current[index] = el} className={`${classes.opportunities_card}`}>
                      <JobCard classes={classes} job={job} closedRoles={closedJobRoles} index={index} jobDetailsRef={closedRolesRef} createDate={createDate} setJobIndex={setJobIndex} setJDLoader={setJDLoader} setOpenJobModal={setOpenJobModal} searchApplicants={searchApplicants} getJobApplicants={getJobApplicants} setOpenConfirmDialog={setOpenConfirmDialog} setCurrentJobId={setCurrentJobId} setJobData={setJobData} getJobDataToUpdateJob={getJobDataToUpdateJob} setCurrentJobLocation={setCurrentJobLocation} setCurrentJobStatus={setCurrentJobStatus} setRoleType={setRoleType} handleSnackBar={handleSnackBar} setCurrentJob={setCurrentJob} setShowJobUpdatePage={setShowJobUpdatePage} setJobFormModalLoader={setJobFormModalLoader} setJobUpdateLoader={setJobUpdateLoader} setProfileView={setProfileView} setSingleHitOffset={setSingleHitOffset} setListView={setListView} setCardView={setCardView} setSortApplicantsVal={setSortApplicantsVal} setSortApplicants={setSortApplicants} jobsTypeTabRef={jobsTypeTabRef} jobCardsRef={jobCardsRef} />
                    </div>
                  })}

                </div> : ''}
                {/* confirmation dialog to change job status */}
                <Dialog
                  open={openConfirmDialog}
                  onClose={closeDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to change the job status?"}
                  </DialogTitle>

                  <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={() => changeJobStatus(jobData)} color="primary" autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </InfiniteScroll>}

              {/* dialog to post a new job  */}
              <Dialog fullWidth={isMobile ? true : false} fullScreen={isMobile ? true : false} classes={{ paperScrollPaper: classes.dialogPaper }} maxWidth='xl' aria-labelledby="form-dialog-title"
                open={openJobPostModal}
                onClose={(event, reason) => {
                  if (reason === 'backdropClick') {
                    return;
                  }
                }}
              >
                <DialogContent style={{ backgroundColor: isMobile ? '#fff' : 'inherit' }}>
                  {isMobile ? <MobileJobForm /> : <JobForm />}
                </DialogContent>
                <ArrowBackIcon fontSize='large' style={{ position: 'absolute', top: '7%', left: '2%', color: !isMobile ? '#fff' : '#333', cursor: 'pointer', zIndex: '9999' }} onClick={() => setOpenJobPostModal(false)} />
              </Dialog>

              {/* job details modal box */}
              {openJobModal && <div className={`${classes.modal_div}`}>
                <JobDetail classes={classes} openRoles={openJobRoles} closedRoles={closedJobRoles} job={postedJobs} jobIndex={jobIndex} setJobIndex={setJobIndex} createDate={createDate} handleBack={handleBack} setOpenJobModal={setOpenJobModal} JDLoader={JDLoader} setJDLoader={setJDLoader} getJobApplicants={getJobApplicants} searchApplicants={searchApplicants} currentJobId={currentJobId} setCurrentJobLocation={setCurrentJobLocation} setCurrentJobStatus={setCurrentJobStatus} setOpenConfirmDialog={setOpenConfirmDialog} setCurrentJobId={setCurrentJobId} handleSnackBar={handleSnackBar} refresh={refresh} getJobDataToUpdateJob={getJobDataToUpdateJob} setCurrentJob={setCurrentJob} setJobUpdateLoader={setJobUpdateLoader} setShowJobUpdatePage={setShowJobUpdatePage} setJobData={setJobData} jobCardsRef={jobCardsRef} jobsTypeTabRef={jobsTypeTabRef} />
              </div>}

              {/* </div> */}
            </div>
          </div>
          {/* </TabPanel> */}

          {/* applicants section */}
          <TabPanel value={mainTabValue} index={1}>

            <div className={classes.applications}>
              {/* applications landing page */}
              {/* {showAllApplications && allApplicants.map((hit, index) => (
              <li key={index} className="ais-InfiniteHits-item" style={{ width: '100%', listStyleType: 'none' }}>
                <div className={`${classes.position_relative} ${classes.connector}`}>
                  <Typography component='span' color='primary' className={`${classes.role_name} ${classes.fontSize_medium}`}>New applications for {hit.job_role}</Typography>
                </div>
                <InvitedListItem
                  hit={hit}
                  showRejectBtn={showRejectBtn}
                  showShortlistBtn={showShortlistBtn}
                  showHireBtn={showHireBtn}
                />
                <div className={`${classes.flexbox} ${classes.justify_flexEnd}`} style={{ width: '58%', margin: '2em auto' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={`${classes.mr1} ${classes.ttc}`}
                    onClick={() => {
                      setShowAllApplications(false);
                      setShowRoleApplications(true);
                    }}
                  >
                    View All Applicants
                  </Button>
                </div>
              </li>
            ))} */}

              {/* all applications */}
              {showRoleApplications &&
                <div>
                  {!isMobile && <Typography component='span' className={`${classes.flexbox} ${classes.px2} ${classes.my1} ${classes.semi_bold} ${classes.backToPostedJobsIcon}`}>
                    <ArrowBackIcon className={`${classes.cursor_pointer} ${classes.mr1}`} onClick={backToOpportunities} />
                    All Posted Jobs
                  </Typography>}
                  <div className={classes.filterSidebar}>
                    <Typography style={{ color: '#00000091', fontWeight: 500, textAlign: 'center', borderBottom: '1px solid #00000016', width: '100%', display: 'block', paddingBottom: '1em' }} component='span' className={`${classes.mr3}`}>All filters</Typography>
                    {(jobData.location_agree || jobData.salary_agree || jobData.own_laptop || jobData.own_vehicle || jobData.salary_expectation || jobData.why_good_fit || jobData.change_job_reason || jobData.valid_dl_license) && <div style={{ marginTop: '1.5em', borderBottom: '1px solid #00000016', paddingBottom: '1em' }}>
                      <Typography style={{ color: '#00000091', fontWeight: 500, display: 'block', marginBottom: '0.5em' }} component='span' className={`${classes.mr3}`}>Screening Questions</Typography>
                      {jobData.location_agree && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.location_agree}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="location_agree"
                            color="primary"
                            value='location_agree=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Location agree'
                      />}
                      {jobData.salary_agree && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.salary_agree}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="salary_agree"
                            color="primary"
                            value='salary_agree=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Salary agree'
                      />}
                      {jobData.own_vehicle && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.own_vehicle}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="own_vehicle"
                            color="primary"
                            value='own_vehicle=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Own Vehicle'
                      />}
                      {jobData.own_laptop && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.own_laptop}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="own_laptop"
                            color="primary"
                            value='own_laptop=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Own Laptop'
                      />}
                      {jobData.valid_dl_license && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.valid_dl_license}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="valid_dl_license"
                            color="primary"
                            value='valid_dl_license=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Valid Driving License'
                      />}
                      {jobData.salary_expectation && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.salary_expectation}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="salary_expectation"
                            color="primary"
                            value='salary_expectation=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Salary Expectations'
                      />}
                      {jobData.why_good_fit && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.why_good_fit}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="why_good_fit"
                            color="primary"
                            value='why_good_fit=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Why good fit'
                      />}
                      {jobData.change_job_reason && <FormControlLabel
                        style={{ color: '#00000091' }}
                        control={
                          <Checkbox
                            checked={filters.change_job_reason}
                            onChange={(e) => setFilterValues(e)}
                            size='small'
                            name="change_job_reason"
                            color="primary"
                            value='change_job_reason=1'
                          />
                        }
                        classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                        label='Job change reason'
                      />}
                    </div>}
                    <div style={{ marginTop: '1.5em' }}>
                      <Typography style={{ color: '#00000091', fontWeight: 500, marginBottom: '0.5em' }} component='p' className={`${classes.mr3}`} >Location</Typography>
                      <Autocomplete
                        //   classes={{ root: classes.locationAutoComplete }}
                        // multiple
                        name="job_location"
                        // ref={locRef}
                        value={filters.location || null}
                        options={location}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          return option.city
                        }
                        }

                        onChange={(e, newValue) => {
                          e.preventDefault();
                          if (newValue) {
                            setFilters({ ...filters, location: newValue.city });
                          } else {
                            setFilters({ ...filters, location: '' });
                          }
                        }}

                        getOptionSelected={(option, value) => option.city === value}

                        renderOption={(option) => {
                          if (option.city !== 'Work From Home') {
                            return option.city
                          }
                        }}

                        filterSelectedOptions

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder={'Search location'}
                            classes={{ root: classes.TfRoot }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: '50%', margin: '1em auto' }}>
                      <Button variant="contained"
                        style={{ padding: '4px', textTransform: 'capitalize' }}
                        color='primary'
                        onClick={applyFilters}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  <div
                    className={classes.applicantSectionContainer}
                  >
                    <div style={{ marginBottom: 70 }}>
                      {<Grid container
                        direction='column' className={classes.pageBar} id="pageBar" ref={pageBarRef} >
                        <div className={classes.flexbox}>
                          {isMobile && <ArrowBackIcon style={{ alignSelf: 'flex-start' }} className={`${classes.cursor_pointer} ${classes.mr2}`} onClick={backToOpportunities} />}
                          <Typography style={{ color: '#00000091', fontWeight: 500 }} component='span' className={`${classes.mr3}`}>All applicants for</Typography>
                        </div>
                        <div className={`${classes.role_select_box} ${classes.flexbox}`}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant='outlined'
                            value={applicantRole}
                            // value='jobTitle'
                            onChange={e => {
                              setFilters({
                                location_agree: false,
                                salary_agree: false,
                                own_laptop: false,
                                own_vehicle: false,
                                valid_dl_license: false,
                                salary_expectation: false,
                                salary_expectation: false,
                                why_good_fit: false,
                                change_job_reason: false,
                                location: ''
                              });
                              setQuery([]);
                              setQueryParams([]);
                              setRoleType(postedJobs);
                              setProfileView('list');
                              setSingleHitOffset(0)
                              setListView(true);
                              setCardView(false);
                            }}
                          >
                            {allJobsByCompany.map((job, index) => (
                              <MenuItem key={index} value={`${job.job_title + job._id}`} id={index} name={job._id}
                                onClick={(e) => {
                                  getJobDataToUpdateJob(postedJobs[e.target.id])
                                  setApplicantRole(e.target.getAttribute('data-value'));
                                  setJobIndex(e.target.id);
                                  getJobApplicants(e.target.id, postedJobs);
                                }}
                              >
                                {job.job_title}
                                <span style={{ display: 'none' }}>{job._id}</span>
                              </MenuItem>
                            ))}
                          </Select>
                          <div className={classes.flexbox}>
                            <Button variant='contained' className={`${classes.ttc} ${classes.mobileSortFilterBtn} ${classes.mr1}`} style={{ color: '#fff', padding: '2px 10px' }} startIcon={<FilterListIcon />} color='secondary' onClick={() => setIsFilterDrawerOpen(true)}>Filter</Button>
                            <div className={classes.position_relative}>
                              <Button variant='contained' className={`${classes.ttc} ${classes.mobileSortFilterBtn}`} style={{ color: '#fff', padding: '2px 10px' }} startIcon={<ImportExportIcon />} color='secondary' onClick={() => setOpenMobileSortList(p => !p)}>Sort</Button>
                              <div style={{ position: ' absolute', right: 0, top: 35, zIndex: 99, width: '35vw', padding: '5px 10px', backgroundColor: '#fff', boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', display: openMobileSortList ? 'block' : 'none' }}>
                                <FormControlLabel
                                  classes={{ root: classes.sortByCheckboxRoot }}
                                  control={
                                    <Checkbox
                                      value={sortWith}
                                      icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                                      checkedIcon={<CheckBoxIcon fontSize="medium" />}
                                      checked={sortWith === 'lastActive'}
                                      onChange={() => {
                                        AnalyticsHandler.track("JobOpenings_Applicants_sortByBtn");
                                        setApplicantResultsOffset(10)
                                        setSortWith('lastActive')
                                      }}
                                      name="Last Active"
                                      color="primary"
                                      classes={{ root: classes.checkboxElement }}
                                    />
                                  }
                                  label="Last Active"
                                />
                                <FormControlLabel
                                  classes={{ root: classes.sortByCheckboxRoot }}
                                  control={
                                    <Checkbox
                                      value={sortWith}
                                      icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                                      checkedIcon={<CheckBoxIcon fontSize="medium" />}
                                      checked={sortWith === 'date'}
                                      onChange={() => {
                                        AnalyticsHandler.track("JobOpenings_Applicants_sortByBtn");
                                        setApplicantResultsOffset(10)
                                        setSortWith('date')
                                      }}
                                      name="Date"
                                      color="primary"
                                      classes={{ root: classes.checkboxElement }}
                                    />
                                  }
                                  label="Date"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        <Grid container className={classes.sliderContainer}>
                          <div className={classes.slider}>
                            <Chip
                              label="All"
                              onClick={(e) => handleMobileSortWith(e)}
                              variant="outlined"
                              clickable
                              color='secondary'
                              classes={{ root: sortApplicants === 'all' ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                            />
                            <Chip
                              label="Shortlisted"
                              onClick={(e) => handleMobileSortWith(e)}
                              variant="outlined"
                              clickable
                              color='secondary'
                              classes={{ root: sortApplicants === 'Shortlisted' ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                            />
                            <Chip
                              label="Hired"
                              onClick={(e) => handleMobileSortWith(e)}
                              variant="outlined"
                              clickable
                              color='secondary'
                              classes={{ root: sortApplicants === 'Hired' ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                            />
                            <Chip
                              label="Rejected"
                              onClick={(e) => handleMobileSortWith(e)}
                              variant="outlined"
                              clickable
                              color='secondary'
                              classes={{ root: sortApplicants === 'Rejected' ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                            />
                            <Chip
                              label="Pending"
                              onClick={(e) => handleMobileSortWith(e)}
                              variant="outlined"
                              clickable
                              color='secondary'
                              classes={{ root: sortApplicants === 'Applied' ? classes.sortingChipActive : classes.sortingChip, clickable: classes.sortingChipClicked }}
                            />
                          </div>
                        </Grid>
                        {allHits.length > 0 && <div style={{ display: window.innerWidth <= 600 ? 'none' : 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                          <Grid item container
                            justifyContent="flex-start"
                            alignItems="center">
                            <Tabs
                              value={sortApplicantsVal}
                              onChange={(e, newValue) => {
                                AnalyticsHandler.track('JobOpenings_Applicants_filterByBtn')
                                if (profileView === 'cards') {
                                  setProfileViewLoader(true)
                                }
                                let val = newValue;
                                if (val === 0) {
                                  setSortApplicants('all')
                                } else if (val === 1) {
                                  setSortApplicants('Shortlisted')
                                } else if (val === 2) {
                                  setSortApplicants('Hired')
                                } else if (val === 3) {
                                  setSortApplicants('Rejected')
                                } else if (val === 4) {
                                  setSortApplicants('Applied')
                                }
                                setSortApplicantsVal(newValue);
                                setApplicantResultsOffset(10)
                                setSingleHitOffset(0)
                              }}
                              style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px', minHeight: 'inherit', borderRadius: 3 }}>
                              <Tab
                                {...a12yProps(0)}
                                id="view_card_btn_id"
                                label="all"
                                classes={{ root: classes.applicantsTabBtn, selected: classes.togBtnSelected, wrapper: classes.tabBtnWrapper }}
                                icon={<PeopleAltOutlined fontSize="small" style={{ fontSize: 16, margin: '0 5px 0 0' }} />}
                              >
                                All
                              </Tab>
                              <Tab {...a12yProps(1)}
                                id="view_list_btn_id"
                                label="Shortlisted"
                                classes={{ root: classes.applicantsTabBtn, selected: classes.togBtnSelected, wrapper: classes.tabBtnWrapper }}
                                icon={<StarBorderIcon fontSize="small" style={{ fontSize: 16, margin: '0 5px 0 0' }} />}
                              >
                                Shortlisted
                              </Tab>
                              <Tab {...a12yProps(2)}
                                id="view_list_btn_id"
                                label="Hired"
                                classes={{ root: classes.applicantsTabBtn, selected: classes.togBtnSelected, wrapper: classes.tabBtnWrapper }}
                                icon={<CheckCircleOutlineIcon fontSize="small" style={{ fontSize: 16, margin: '0 5px 0 0' }} />}
                              >
                                Hired
                              </Tab>
                              <Tab {...a12yProps(3)}
                                id="view_list_btn_id"
                                label="Rejected"
                                classes={{ root: classes.applicantsTabBtn, selected: classes.togBtnSelected, wrapper: classes.tabBtnWrapper }}
                                icon={<CancelOutlinedIcon fontSize="small" style={{ fontSize: 16, margin: '0 5px 0 0' }} />}
                              >
                                Rejected
                              </Tab>
                              <Tab {...a12yProps(4)}
                                id="view_list_btn_id"
                                label="Pending"
                                classes={{ root: classes.applicantsTabBtn, selected: classes.togBtnSelected, wrapper: classes.tabBtnWrapper }}
                                icon={<HourglassEmptyIcon fontSize="small" style={{ fontSize: 16, margin: '0 5px 0 0' }} />}
                              >
                                Pending
                              </Tab>
                            </Tabs>
                          </Grid>
                          <Grid item container
                            justifyContent="flex-end"
                            alignItems="center"
                            // ref={scrollRefPagination}
                            className={classes.applicantsViewChangeIcon}
                          >
                            <Typography variant="body2" style={{ color: '#00000091', fontWeight: 500 }} className={classes.mr3}>
                              View:
                            </Typography>
                            <ToggleButtonGroup size="small"
                              value={profileView}
                              exclusive
                              onChange={() => {
                                AnalyticsHandler.track("JobOpenings_Applicants_viewChangeBtn");
                                setApplicantResultsOffset(10)
                                if (profileView === 'cards') {
                                  setProfileIndex(0);
                                  setProfileView('list')
                                  setListView(true)
                                  setCardView(false)
                                  getJobApplicants(jobIndex, roleType, queryParams)
                                } else if (profileView === 'list') {
                                  setProfileViewLoader(true);
                                  getOneByOneApplicants(0, queryParams)
                                  // setSingleHitOffset(p => p + 1);
                                  setProfileView('cards')
                                  setListView(false)
                                  setCardView(true)
                                }
                              }}
                              style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px' }}>
                              <ToggleButton style={{ padding: '0 5px' }} id="view_card_btn_id" value="cards" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                                <RecentActorsIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> 1 by 1
                              </ToggleButton>
                              <ToggleButton style={{ padding: '0 5px' }} id="view_list_btn_id" value="list" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                                <ListIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> All Results
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>

                          <Grid item container
                            justifyContent="flex-end"
                            alignItems="center"
                          // style={{ marginTop: '0.5em' }}
                          >
                            <Typography variant="body2" style={{ color: '#00000091', fontWeight: 500 }} className={classes.mr3}>
                              Sort by:
                            </Typography>
                            <ToggleButtonGroup size="small"
                              value={sortWith}
                              exclusive
                              onChange={() => {
                                AnalyticsHandler.track("JobOpenings_Applicants_sortByBtn");
                                setApplicantResultsOffset(10)
                                if (sortWith === 'date') {
                                  // setProfileIndex(0);
                                  setSortWith('lastActive')
                                } else if (sortWith === 'lastActive') {
                                  setSortWith('date')
                                }
                              }}
                              style={{ boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 5px' }}>
                              <ToggleButton style={{ padding: '0 5px' }} id="view_card_btn_id" value="date" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                                <EventIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> Date
                              </ToggleButton>
                              <ToggleButton style={{ padding: '0 5px' }} id="view_list_btn_id" value="lastActive" classes={{ sizeSmall: classes.togBtnSizeSmall, label: classes.togBtnLabel, selected: classes.togBtnSelected }}>
                                <VisibilityIcon fontSize="small" style={{ fontSize: 16, marginRight: 5 }} /> Last Active
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                          <Grid item style={{ marginTop: '0.5em', color: 'rgb(121,121,121)', width: '50%' }}>
                            {!loader && <Typography component='h1' variant='subtitle1' >Total {totalApplicantsResults} applicants</Typography>}
                          </Grid>

                        </div>}
                      </Grid>}

                      {/* drawer to apply filters from mobile starts */}
                      <Drawer classes={{ paper: classes.filterDrawerPaper }} anchor='bottom' open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)} >
                        <Typography style={{ padding: '1em' }} variant='h6'>Filter</Typography>
                        <Grid container>
                          <Grid xs={4} container item style={{ height: '60vh', backgroundColor: '#e1e1e1', paddingTop: '1em' }} >
                            <Tabs
                              indicatorColor='white'
                              orientation="vertical"
                              value={mobileFilterTabVal}
                              onChange={handleMobileFilterTabChange}
                              aria-label="Vertical tabs example"
                              className={classes.tabs}
                            >
                              <Tab style={{ padding: 5 }} className={classes.ttc} label="Screening Questions" {...filterProps(0)} />
                              <Tab style={{ padding: 5, borderTop: '1px solid #afafaf', margin: 10 }} className={classes.ttc} label="Location" {...filterProps(1)} />
                            </Tabs>
                          </Grid>
                          <Grid xs={8} item style={{ padding: '1em' }}>
                            <TabPanel value={mobileFilterTabVal} index={0}>
                              {jobData.location_agree && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.location_agree}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="location_agree"
                                    color="primary"
                                    value='location_agree=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Location agree'
                              />}
                              {jobData.salary_agree && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.salary_agree}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="salary_agree"
                                    color="primary"
                                    value='salary_agree=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Salary agree'
                              />}
                              {jobData.own_vehicle && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.own_vehicle}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="own_vehicle"
                                    color="primary"
                                    value='own_vehicle=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Own Vehicle'
                              />}
                              {jobData.own_laptop && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.own_laptop}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="own_laptop"
                                    color="primary"
                                    value='own_laptop=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Own Laptop'
                              />}
                              {jobData.valid_dl_license && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.valid_dl_license}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="valid_dl_license"
                                    color="primary"
                                    value='valid_dl_license=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Valid Driving License'
                              />}
                              {jobData.salary_expectation && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.salary_expectation}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="salary_expectation"
                                    color="primary"
                                    value='salary_expectation=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Salary Expectations'
                              />}
                              {jobData.why_good_fit && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.why_good_fit}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="why_good_fit"
                                    color="primary"
                                    value='why_good_fit=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Why good fit'
                              />}
                              {jobData.change_job_reason && <FormControlLabel
                                style={{ color: '#00000091' }}
                                control={
                                  <Checkbox
                                    checked={filters.change_job_reason}
                                    onChange={(e) => setFilterValues(e)}
                                    size='small'
                                    name="change_job_reason"
                                    color="primary"
                                    value='change_job_reason=1'
                                  />
                                }
                                classes={{ label: classes.scrFormLabel, root: classes.srcFormLabelRoot }}
                                label='Job change reason'
                              />}
                            </TabPanel>
                            <TabPanel value={mobileFilterTabVal} index={1}>
                              <Autocomplete
                                //   classes={{ root: classes.locationAutoComplete }}
                                // multiple
                                name="job_location"
                                // ref={locRef}
                                value={filters.location || null}
                                options={location}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  return option.city
                                }
                                }

                                onChange={(e, newValue) => {
                                  e.preventDefault();
                                  if (newValue) {
                                    setFilters({ ...filters, location: newValue.city });
                                  } else {
                                    setFilters({ ...filters, location: '' });
                                  }
                                }}

                                getOptionSelected={(option, value) => option.city === value}

                                renderOption={(option) => {
                                  if (option.city !== 'Work From Home') {
                                    return option.city
                                  }
                                }}

                                filterSelectedOptions

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={'Search location'}
                                    classes={{ root: classes.TfRoot }}
                                  />
                                )}
                              />
                            </TabPanel>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent='flex-end' >
                          <Button variant='outlined' color='secondary' onClick={() => setIsFilterDrawerOpen(false)} className={`${classes.ttc} ${classes.mr2}`}>Close</Button>
                          <Button variant='contained' style={{ color: '#fff', backgroundColor: '#55c6a9' }} onClick={applyFilters} className={`${classes.ttc} ${classes.mr2}`}>Apply</Button>
                        </Grid>
                      </Drawer>
                      {/* drawer to apply filters from mobile ends */}

                      {!loader && listView &&
                        (hits.length > 0 ?
                          <InfiniteScroll
                            dataLength={hits.length}
                            next={fetchMoreApplicants}
                            hasMore={hits.length !== totalApplicantsResults}
                            loader={<Spinner />}
                            scrollThreshold={0.9}
                            style={{ overflow: 'hidden' }}
                          >
                            {hits.filter((user, index, array) => index === array.findIndex(obj => obj.objectID === user.objectID)).map((hit, index) => (
                              <li key={index} className={classes.applicant_profile} style={{ width: '100%', listStyleType: 'none' }}>
                                {/* {hits.length > 0 ? */}
                                {window.innerWidth <= 600 ?
                                  <MobileInvitedListItem
                                    hit={hit}
                                    createDate={createDate}
                                    jobData={jobData}
                                    jobPostId={jobId}
                                    handleSnackBar={handleSnackBar}
                                  />
                                  :
                                  <InvitedListItem
                                    hit={hit}
                                    createDate={createDate}
                                    jobData={jobData}
                                    jobPostId={jobId}
                                    handleSnackBar={handleSnackBar}
                                  />}
                              </li>
                            ))}
                          </InfiniteScroll>
                          : queryParams.length > 0 ? (<div className={`${classes.justify_center}`} style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="h6" className={classes.pageInfoText} style={{ width: '50%', textAlign: 'center' }}>
                              {sortApplicants === 'all' ? 'No applicant matches the filter of your preference. Please change/remove the filter to view the list of applicants.' : 'No applicant found'}
                            </Typography>
                          </div>) :
                            (<div className={`${classes.justify_center}`} style={{ minHeight: '50vh', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                              <Typography variant="h6" className={classes.pageInfoText} style={{ width: isMobile ? '80%' : '50%', textAlign: 'center' }}>
                                {sortApplicants === 'all' ? 'Currently no one has applied to this job. However you can still invite the jobseekers for the current job.' : 'No applicant found'}
                              </Typography>
                              {sortApplicants === 'all' && <Button
                                variant="contained"
                                color="primary"
                                className={`${classes.ttc}`}
                                style={{ padding: '8px 15px' }}
                                startIcon={<GroupAddIcon />}
                                onClick={() => {
                                  // let valId = Number(val.slice(1, val.length));
                                  AnalyticsHandler.track("JobOpenings_inviteJobseekersBtn")
                                  let mapped_role = currentJob.job_title;
                                  mapped_role = mapped_role.includes('/') ? mapped_role.replaceAll('/', '-') : mapped_role;
                                  let location = currentJob.job_location;
                                  var newPath;
                                  if (location && location.length > 0) {
                                    newPath = `/discover/${mapped_role}/${location[0].city}`;
                                  } else if (currentJob.isWorkFromHome) {
                                    newPath = `/discover/${mapped_role}/anywhere`;
                                  } else {
                                    newPath = `/discover/${mapped_role}/anywhere`;
                                  }
                                  history.push({
                                    pathname: newPath,
                                  })
                                }}
                              >
                                Invite jobseekers
                              </Button>}
                            </div>)
                        )
                      }
                      {!loader && cardView &&
                        (singleHit ?
                          <li className={`${classes.applicant_profile} ${classes.position_relative}`} style={{ width: '100%', listStyleType: 'none' }}>
                            <div style={{ width: '60vw', position: 'relative' }} className={`${classes.flexbox} ${classes.justify_center}`}>
                              {profileViewLoader && <Spinner minHeight='60vh' />}
                              {!profileViewLoader && <ApplicantsCard
                                // hit={hits.filter((user, index, array) => index === array.findIndex(obj => obj.objectID === user.objectID))[profileIndex]}
                                hit={singleHit}
                                jobData={jobData}
                                jobPostId={jobId}
                                handleSnackBar={handleSnackBar}
                                createDate={createDate}
                              />}
                              <NavigateBeforeIcon fontSize='large' className={singleHitOffset === 0 ? `${classes.slideBtn} ${classes.prev} ${classes.disabledBtn}` : `${classes.slideBtn} ${classes.prev}`}
                                onClick={() => {
                                  if (singleHitOffset > 0) {
                                    // setProfileIndex(prev => prev - 1)
                                    setSingleHitOffset(p => p - 1);
                                    setProfileViewLoader(true);
                                  } else {
                                    return
                                  }
                                }}
                              />
                              <NavigateNextIcon fontSize='large' className={totalApplicantsResults - 1 === singleHitOffset ? `${classes.slideBtn} ${classes.next} ${classes.disabledBtn}` : `${classes.slideBtn} ${classes.next}`}
                                onClick={() => {
                                  if (singleHitOffset < totalApplicantsResults) {
                                    // setProfileIndex(prev => prev + 1)
                                    setSingleHitOffset(p => p + 1);
                                    setProfileViewLoader(true);
                                  } else {
                                    return
                                  }
                                }}
                              />
                            </div>
                          </li> : <div className={`${classes.flexbox} ${classes.justify_center}`} style={{ minHeight: '50vh' }}>No applicant found</div>)}
                    </div>
                  </div>
                  {loader &&
                    <div className={`${classes.flexbox} ${classes.justify_center}`} style={{ minHeight: window.innerWidth <= 600 ? '50vh' : '80vh', marginLeft: window.innerWidth <= 600 ? 0 : '16rem' }}>
                      <Spinner />
                    </div>
                  }
                </div>
              }
            </div>
          </TabPanel>
        </div>
        <SnackBarGeneric canSnackBarOpen={canSnackBarOpen} refresh={refreshSnackBar} message={snackBarMessage} />
      </div>}
      {showJobUpdatePage &&
        <div>
          {jobUpdateLoader && <Loader containerWidth='60vw' maxWidth='40px' minHeight='75vh' />}
          {!jobUpdateLoader && <div className={classes.modal_div}><JobUpdate values={jobData} setShowJobUpdatePage={setShowJobUpdatePage} setJobUpdateLoader={setJobUpdateLoader} doRefresh={doRefresh} setJobFormVisibility={setJobFormVisibility} /></div>}
          {/* <ArrowBackIcon fontSize={window.innerWidth <= 600 ? 'medium' : 'large'} style={{ position: 'fixed', left: '2%', top: '10%', cursor: 'pointer' }}
            onClick={() => {
              doRefresh(p => !p)
              setShowJobUpdatePage(false);
            }}
          /> */}
        </div>}
    </>
  )
}

export default JobOpenings