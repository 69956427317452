import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import ProfileCards_2 from './ProfileCards_2'

class InfiniteHits_Card extends Component {

  render() {
    const { hits } = this.props;
    //console.log("role......." + this.props.role);

    return (
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list">
          {hits.map(hit => (
            <li key={hit.objectID}
              className="ais-InfiniteHits-item"
              style={{ width: '100%' }}>
              <ProfileCards_2
                hit={hit}
                role={this.props.role}
                qualifications={this.props.qualifications}
                job_role_id={this.props.job_role_id} />
            </li>
          ))}


        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits_Card);
