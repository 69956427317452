import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WorkTitle from '../views/jobs/WorkTitle';
import { Checkbox, FormControlLabel, Grid, StepConnector, TextField, withStyles } from '@material-ui/core';
import WorkType from '../views/jobs/WorkType';
import Role from '../views/jobs/Role';
import Schedule from '../views/jobs/Schedule';
import Description from '../views/jobs/Description';
import Duration from '../views/jobs/Duration';
import Vacancies from '../views/jobs/Vacancies';
import { Autocomplete } from '@material-ui/lab';
import Salary from '../views/jobs/Salary';
import Perks from '../views/jobs/Perks';
import Qualifications from '../views/jobs/Qualifications';
import Skills from '../views/jobs/Skills';
import OtherRequirements from '../views/jobs/OtherRequirements';
import Gender from '../views/jobs/Gender';
import ScreeningForm from '../views/jobs/ScreeningForm';
import {
    InstantSearch,
    Configure,
    Pagination,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import ListLoader from '../views/discovery/ListLoader';
import Hits from '../views/jobs/Hits';
import No_Results from '../views/discovery/NoResults';
import { connectStateResults } from 'react-instantsearch-dom';
import { Component, useRef } from 'react';
import selected_qualifications from '../views/discovery/quals';
import { RolesNotToBePassedToAlgolia } from '../Globals';
import JobPreview from './JobPreview';
import global_isSignedIn from './../views/discovery/global_isSignedIn';
import ApiHandler from '../ApiHandler';
import '../views/jobs/Hits.css'
import JobUpdate from './JobUpdate';
import Loader from '../views/company/Loader';
import AnalyticsHandler from '../AnalyticsHandler';
import { ArrowBack } from '@material-ui/icons';

class DefaultScrollTo extends Component {
    componentDidUpdate(prevProps) {
        const { value, hasNotChanged } = this.props;

        if (value !== prevProps.value && hasNotChanged) {
            this.el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    }
}


const axios = require('axios');
var BASE_URL = process.env.REACT_APP_API_ROOT;
const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.3em',
            backgroundColor: '#D5F5ED'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#55c6a9',
            borderRadius: "23px",
        },
        '.MuiAutocomplete-tag': {
            backgroundColor: '#fff !important',
            border: '1px solid #55c6a9 !important'
        },
        '.MuiChip-deleteIcon': {
            color: '#55c6a9 !important',
        },
        '.Mui-checked': {
            color: '#14967B !important'
        },
        '.MuiStepIcon-active': {
            color: '#55c6a9 !important'
        },
        '.MuiStepIcon-completed': {
            color: '#55c6a9 !important'
        }
    },
    // root: {
    //   width: '100vw',
    // },
    previewButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#fff',
        borderRadius: '6px',
        color: '#14967b',
        '&:hover': {
            backgroundColor: '#fff',
        },
        textTransform: 'capitalize'
    },
    job_preview: {
        position: 'fixed',
        width: '100%',
        top: 70,
        left: 0,
        height: '95vh',
        zIndex: 1700
    },
    nextButton: {
        marginRight: theme.spacing(1),
        backgroundColor: '#14967B',
        borderRadius: '6px',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#14967B',
        },
        textTransform: 'capitalize'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
    },
    customStep: {
        // position: 'relative',
        // minHeight: '4em',
        // display: 'flex',
        // alignItems: 'center',
        padding: 0,
        maxWidth: 'initial',
        marginRight: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        '& > span': {
            // '@media (max-width: 600px)': {
            //     flexDirection: 'column'
            // },
        },
    },
    ScreeningFormContainer: {
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        padding: '1em 1em 1em 2em',
        margin: 'auto',
        width: '99%',
        '@media (max-width: 600px)': {
            padding: '0.5em',
            width: '98%',
        },
    },
    scrFormLabel: {
        marginLeft: '2em',
        width: '100%',
        '@media (max-width: 600px)': {
            marginLeft: '0 !important',
            fontSize: '12px'
        },
    },
    srcFormLabelRoot: {
        width: '100%',
    },
    customStepper: {
        padding: '3em 0 1em 0',
        position: 'sticky',
        top: 0,
        zIndex: 9,
        marginTop: -20
    },
    labelContainer: {
        backgroundColor: 'transparent !important',
    },
    form_card: {
        backgroundColor: 'white',
        // boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        // minHeight: '75vh',
        // maxHeight: '75vh',
        overflow: 'auto',
        // padding: '100px 80px 80px 80px',
        display: 'flex',
        flexDirection: 'column',
        // margin: '16px auto',
        // maxWidth: '75vw',
        borderRadius: '4px',
        // '@media (max-height: 620px)': {
        //     minHeight: '70vh',
        //     maxHeight: '70vh',
        // },
    },
    formWidth: {
        width: '100%',
    },
    TfRoot: {
        margin: '-10px 0',
        '@media (max-width: 600px)': {
            margin: '0 10px 0 0',
            // width: '60% !important'
        },
        '& div': {
            padding: '0',
            '@media (max-width: 600px)': {
                padding: '0 5px !important',
                margin: '0',
                height: '35px'
            },
        },
        '& input': {
            // height: 48,
            '&::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            },
            '&::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            }
        },
        '& fieldset': {
            border: '1px solid #808080',
        },
    },
    TfLocationRoot: {
        margin: '-10px 0',
        '@media (max-width: 600px)': {
            margin: '0 10px 0 0',
            // width: '60% !important'
        },
        '& > div': {
            padding: '0',
            '@media (max-width: 600px)': {
                padding: '0 5px !important',
                margin: '0',
                minHeight: '35px'
            },
        },
        '& input': {
            // height: 48,
            '&::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            },
            '&::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0
            }
        },
        '& fieldset': {
            border: '1px solid #808080',
        },
    },
    popperLocation: {
        marginTop: 16,
        "& .MuiAutocomplete-listbox": {
            paddingBottom: 0,
            '&::after': {
                content: '""',
                position: 'absolute',
                right: 8,
                bottom: 8,
                width: 144,
                height: 18,
                //   backgroundImage: `url(${poweredByGoogle})`,
                backgroundRepeat: 'no-repeat',
                // zIndex: 99999

            }
        },
    },
    comboBoxRoot: {
        // marginTop: 22,
        border: '0!important',
        "& .MuiFormLabel-root": {
            color: "black",
            fontWeight: "700",
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: "#5BC2A8",
            fontWeight: "700",
        },
        // "&:after": {
        //     position: 'absolute',
        //     content: '""',
        //     width: 1,
        //     height: 34,
        //     backgroundColor: '#E0E0E0',
        // }
    },

    popupIndicator: {
        display: 'none'
    },

    input: {
        height: 30,
        fontSize: '12px',
        // maxWidth: '91%',
        "&::-webkit-input-placeholder": {
            color: 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
        },

    },

    inputFocusedDark: {
        "& ::-webkit-input-placeholder": {
            color: 'red',
        },
    },
    listbox: {
    },
    paper: {
        borderRadius: 10,
        "& .MuiAutocomplete-option[aria-disabled='true']": { // add this to make the disabled instruction text of role box clear
            opacity: 1
        }
    },
    paperLocation: {
        borderRadius: 10,
        paddingBottom: 30
    },
    TaRoot: {
        margin: '0 3px',
        padding: 0,
        '& textarea': {
            backgroundColor: '#F5F5F5',
            borderRadius: '1px',
            color: '#797979',
            fontSize: '0.85em',
            textAlign: 'center',
            '&:focus': {
                border: '1px solid #5BC2A8'
            }

        }
    },
    TaRootSrcForm: {
        margin: '0 3px',
        padding: 0,
        '& textarea': {
            backgroundColor: '#F5F5F5',
            borderRadius: '1px',
            color: '#797979',
            fontSize: '1em',
            textAlign: 'center',
            paddingTop: '1em',
            '&:focus': {
                border: '1px solid #5BC2A8'
            }

        }
    },
    IbRoot: {
        borderBottom: '2px solid #232323',
        width: '30%',
        '& input': {
            height: 38,
            padding: '0 8px',

        }
    },
    skillsInput: {
        // borderBottom: '2px solid #232323',
        // width: '26%',
        '& label': {
            color: '#232323',
        },
        '& input': {
            padding: '0 5px',
            height: 30,
            fontSize: '0.8em'
        },
        '@media (max-width: 1240px)': {
            width: '75vw'
        },
        '@media (max-width: 600px)': {
            // padding: '9px 9px 9px 15px'
        },
    },
    skillsInputExp: {
        // borderBottom: '2px solid #232323',
        // width: '26%',
        '& label': {
            color: '#232323',
        },
        '& input': {
            padding: '0 5px',
            height: 30,
            fontSize: '0.8em'
        },
        '@media (max-width: 1240px)': {
            width: '75vw'
        },
        '@media (max-width: 600px)': {
            '& > div': {
                padding: '9px 9px 9px 10px'
            }
        },
    },
    skillsDiv: {
        width: '100%',
        justifyContent: 'center',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },
    hideOnMobile: {
        display: 'none',
    },
    nextBackBtns: {
        // position: 'sticky',
        // bottom: 0,
        // background: '#fff',
        padding: '1em 0 3em 0',
        justifyContent: 'space-between'
    },
    days: {
        width: '20px',
        height: '20px',
        border: '1px solid #5BC2A8',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#797979',
        borderRadius: '2px',
        marginRight: '5px',
        boxShadow: '0px 2px 4px rgba(20, 150, 123, 0.1)',
        '& label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.72em',
            wordBreak: 'break-word',
            width: 'inherit',
            height: 'inherit',
            borderRadius: '3px',
            margin: '-1px 0 -1px -1px',
            '&:hover': {
                cursor: 'pointer'
            },
        },
        '& input[type="checkbox"]': {
            display: 'none',
            '&:checked + label': {
                color: '#fff',
                backgroundColor: '#5BC2A8',
            }
        },
    },
    icon_btn: {
        border: '1px solid',
        borderRadius: '50%',
        color: '#55c6a9',
        padding: '6px',
        marginRight: theme.spacing(1),
    },
    icon_btn_group: {
        position: 'absolute',
        top: '5%',
        left: '4%',
        zIndex: '10',
        '& button': {
            position: 'sticky',
            top: '0',
        },
        '@media (max-width: 1180px)': {
            left: '11% !important'
        },
        '@media (max-width: 1240px)': {
            left: '8%'
        },
    },
    form_status: {
        position: 'absolute',
        top: '5%',
        right: '4%',
        color: '#AFAFAF',
        fontWeight: 300,
        zIndex: '100',
        '@media (max-width: 1180px)': {
            right: '11% !important'
        },
        '@media (max-width: 1240px)': {
            right: '8%'
        },
    },
    helper_text: {
        color: "#AFAFAF",
        fontSize: '0.9rem',
        fontWeight: 300,
        display: 'block',
    },
    icon: {
        cursor: 'pointer',
        color: '#55c6a9',
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center'
    },
    flexboxCol: {
        display: 'flex',
        flexDirection: 'column',
    },
    show: {
        display: 'flex',
        backgroundColor: '#55c6a93d',
        color: '#14967B',
        padding: '0 8px',
        position: 'relative',
        borderRadius: '3px',
        top: '11px',
        left: '5px',

        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: 0,
            height: 0,
            top: '2px',
            left: '-7px',
            borderRight: '7px solid #55c6a93d',
            borderBottom: '6px solid transparent',
            borderTop: '7px solid transparent'
        }
    },
    hidden: {
        display: 'none'
    },
    workTypeFormControl: {
        alignItems: 'flex-start',
        // maxWidth: '90%',
    },
    workTypeOptions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '-0.5em',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& label': {
            height: '30px',
            '& span:nth-child(2)': {
                fontSize: 14,
            }
        },
    },
    radioColor: {
        color: '#55c6a9',
        '& span': {
            '& div': {
                '& svg': {
                    fontSize: '0.8em'
                }
            }
        }
    },
    disableDiv: {
        pointerEvents: 'none',
        opacity: '0.45'
    },
    formControl: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    formControlSalary: {
        flexDirection: 'row',
        alignItems: 'center',
        '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
    },

    timePicker: {
        width: '30%',
        marginTop: 6,
        '& > div': {
            fontSize: '14px',
            '& > div': {
                marginLeft: -10,
                '& button': {
                    padding: 0,
                    '& > span': {
                        '& > svg': {
                            fontSize: '0.7em'
                        }
                    }
                }
            }
        }
    },
    timeFormControl: {
        flexDirection: 'row !important',
    },
    locationAutoComplete: {
        // '& > div': {
        //   '& > div': {
        //     paddingRight: '10px !important',
        //   }
        // }
        width: '725px',
        [theme.breakpoints.up('xl')]: {
            width: '825px',
        }
    },
    benefitsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto auto',
        gridGap: '1em',
        margin: '1.2em 0.1em',
        maxWidth: '85%',
        '@media (max-width: 600px)': {
            gridTemplateColumns: 'auto auto auto',
        },
    },
    benefitsItem: {
        width: '100px',
        height: '74px',
        border: '1px solid #5BC2A8',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#797979',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(20, 150, 123, 0.1)',
        '@media (max-width: 600px)': {
            width: '79px',
            height: '75px'
        },
        '&:hover': {
            cursor: 'pointer'
        },
        '& label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.72em',
            wordBreak: 'break-word',
            width: 'inherit',
            height: 'inherit',
            borderRadius: '3px',
            margin: '-1px 0 -1px -1px',
        },
        '& input[type="checkbox"][id^="checkbox"]': {
            display: 'none',
            '&:checked + label': {
                color: '#fff',
                backgroundColor: '#5BC2A8',
                cursor: 'pointer',
                '& img': {
                    color: '#fff',
                    filter: 'brightness(5)'
                }

            }
        },
    },
    allQualCheckbox: {
        marginBottom: '0.5em',
        '& span:nth-child(1)': {
            padding: '0 !important',
            margin: '0 0.2em !important',
        }
    },
    labelSpan: {
        textAlign: 'center',
        width: '85%'
    },
    sticky_heading: {
        position: 'sticky',
        top: '-100px',
        zIndex: '10',
        padding: '0.8em 0',
        backgroundColor: '#fff',
        marginTop: '-2em',
        '@media (max-width: 600px)': {
            position: 'initial',
            top: 'initial',
            zIndex: '8',
            padding: '0',
            backgroundColor: 'transparent',
            marginTop: '0',
        },
    },
    position_relative: {
        position: 'relative'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    mx1: {
        margin: '0 0.5em !important'
    },
    mx2: {
        margin: '0 1em !important',
        '@media (max-width: 600px)': {
            margin: '0 !important'
        },
    },
    mx3: {
        margin: '0 1.5em !important'
    },
    mx4: {
        margin: '0 2.5em !important'
    },
    my1: {
        margin: '0.5em 0 !important'
    },
    my2: {
        margin: '1em 0 !important'
    },
    my3: {
        margin: '1.5em 0 !important'
    },
    my4: {
        margin: '2.5em 0 !important',
        '@media (max-width: 600px)': {
            margin: '0 !important'
        },
    },
    mr1: {
        marginRight: '1em !important'
    },
    mr2: {
        marginRight: '2em !important'
    },
    mr3: {
        marginRight: '3em !important'
    },
    ml1: {
        marginLeft: '1em !important'
    },
    ml2: {
        marginLeft: '2em !important'
    },
    mb1: {
        marginBottom: '1em !important'
    },
    inputHeading: {
        '@media (max-width: 600px)': {
            fontSize: '1em',
            fontWeight: 500
        },
    },
    stepLabel: {
        fontSize: '10px',
        width: '60px',
        wordWrap: 'break-word'
    },
    vidJdLinks: {
        fontWeight: 400,
        textDecoration: 'none',
        color: '#55c6a9',
    },
    completedStep: {
        color: 'blue !important'
    },

    inputRootAc: {
        padding: '0 9px!important',
        background: theme.palette.common.greyLight,
    },
    locationIcon: {
        //color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
        color: '#999',
        fontSize: 16,
        marginTop: 5,
    },
    gpsIcon: {
        paddingRight: 16,
        //color: '#5BC2A8',
        color: '#999',
        fontSize: 16,
        marginTop: 4,
    },
    popperAC: {
        zIndex: 99999
    },
}));

function getSteps() {
    return ['Job Role & Type', 'Location & Vacancies', 'Salary & Benefits', 'Requirements', 'Screening Questions'];
}

const searchClient = algoliasearch('L42QPPK6EJ', '9f31e7c74c2420ac1e2fd4863a69463a');


export default function MobileJobForm(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [formStatus, setFormStatus] = React.useState(0);
    const [showRoles, setShowRoles] = React.useState(false);
    const [showDuration, setShowDuration] = React.useState(false);
    const [showUnpaidOption, setShowUnpaidOption] = React.useState(false);
    const [benefits, setBenefits] = React.useState([]);
    const [errors, setErrors] = React.useState({});
    const [formSubmitSuccess, setFormSubmitSuccess] = React.useState(false);
    const [formSubmitError, setFormSubmitError] = React.useState(false);
    const [formSubmitUpdateSuccess, setFormSubmitUpdateSuccess] = React.useState(false);
    const [formSubmitUpdateError, setFormSubmitUpdateError] = React.useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = React.useState('');
    const [disableSubmitBtn, setDisableSubmitBtn] = React.useState(false);
    const [customQuestions, setCustomQuestions] = React.useState([]);
    const [multipleLocationVal, setMultipleLocationVal] = React.useState([]);
    const [jobPreviewModal, setJobPreviewModal] = React.useState(false);
    const [postedJobId, setPostedJobId] = React.useState(null);
    const [jobPreviewLoader, setJobPreviewLoader] = React.useState(false);
    const [showJobUpdatePage, setShowJobUpdatePage] = React.useState(false);
    const [jobUpdateLoader, setJobUpdateLoader] = React.useState(false);
    const [postedJobData, setPostedJobData] = React.useState(false);
    const [refresh, doRefresh] = React.useState(false);
    const [location, setLocation] = React.useState([]);
    const [jobType, setJobType] = React.useState([]);
    const [isUnpaid, setIsUnpaid] = React.useState(false);

    const [values, setValues] = React.useState({
        company_id: parseInt(localStorage.getItem('company_id')),
        title: "",
        job_description: "",
        mapped_role: "",
        opportunity_type: "",
        internship_duration: "",
        enable_status: true,
        isVerified: false,
        isFlexibleDays: false,
        isFlexibleTime: false,
        isWorkFromHome: false,
        job_location: [],
        no_of_vacancies: '',
        job_salary_amount: '',
        variable_salary_amount: '',
        variable_salary_unit: '',
        work_benefits: [],
        skills_required: [],
        qualification_req: [],
        candidate_requirement: "",
        video_jd: "",
        gender_pref: [],
        gender_pref_reason: "",
        working_days: [],
        min_salary: '',
        max_salary: '',
        working_time: null,
        salary_agree: false,
        salary_expectation: false,
        location_agree: false,
        valid_dl_license: false,
        own_laptop: false,
        own_vehicle: false,
        why_good_fit: false,
        change_job_reason: false,
        custom_questions: [],
        geo_json: {
            type: "MultiPoint",
            coordinates: []
        },

    });

    const [isListLoaderOn, setIsListLoaderOn] = React.useState(false);
    const paginationRef = React.useRef(null)
    const [hitsPerPage, setHitsPerPage] = React.useState(12);
    const [profileView, setProfileView] = React.useState('list');
    const [listLoaderRef, setListLoaderRef] = React.useState(null);
    const [flag_no_results, setFlagNoResults] = React.useState(false);
    const [sortSelected, setSortSelected] = React.useState(null);
    const [searchRef, setSearchRef] = React.useState(null);
    const [geographical_type, setGeographicalType] = React.useState(location && location.state && location.state.geographical_type ? location.state.geographical_type : null);
    const listViewWrapperRef = useRef(null);
    const [nb_hits, setNbHits] = React.useState(null);
    var lat_lng = {
        lat: location && location.state && location.state.lat ? location.state.lat : null,
        lng: location && location.state && location.state.long ? location.state.long : null,
    }
    const [lat, setLatitude] = React.useState(lat_lng.lat);
    const [long, setLongitude] = React.useState(lat_lng.lng);

    const [jobseekerEnteredRoles, setJobseekerEnteredRoles] = React.useState(null);
    const [secondaryRoles, setSecondaryRoles] = React.useState(location && location.state && location.state.secondaryRoles ? location.state.secondaryRoles : []);
    const [qualifications, setQualifications] = React.useState(location && location.state && location.state.qualifications ? location.state.qualifications : []);
    let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
    const [isSignedIn, setIsSignedIn] = React.useState((localStorage.getItem("is_logged_in")) || false);
    global_isSignedIn.isSignedIn = (localStorage.getItem("is_logged_in")) || false;

    const [location_invite, setLocationInvite] = React.useState(values.job_location.length !== 0 ? values.job_location[0].city.split("-").join(" ").replaceAll("_", "/") : '');
    const sendLocationForInvite = () => location_invite !== "" && location_invite !== "anywhere" ? location_invite : "Anywhere/ Work-from-Home";

    // const [isSignedIn, setIsSignedIn] = React.useState(true);
    // global_isSignedIn.isSignedIn = true;

    const checkSignedInStatus = () => {
        return isSignedIn
    }

    var i, qualifications_id = [];

    if (qualifications)
        var first_qualification = qualifications[0];
    else
        var first_qualification = "";

    if (qualifications) {
        for (i = 0; i < qualifications.length; i++) {
            // qualifications_id[i] = `user_qualifications.qualification_id: ${qualifications[i].tag_id}<score=${qualifications.length - i}>`
            qualifications_id[i] = `user_qualifications.qualification_id: ${qualifications[i].tag_id}<score=${1}>`
        }

    }

    var j, secondary_roles = [];
    if (secondaryRoles) {
        for (j = 0; j < secondaryRoles.length; j++) {
            // secondary_roles[j] = `user_experiences.user_post: ${secondaryRoles[j].title} <score=${qualifications.length + 1}>`
            secondary_roles[j] = `user_experiences.user_post: ${secondaryRoles[j].title} <score=${2}>`
        }
    }
    selected_qualifications.secondaryRoles = secondaryRoles;

    var k, jobseeker_entered_roles = [];

    if (jobseekerEnteredRoles) {
        for (k = 0; k < jobseekerEnteredRoles.length; k++) {
            jobseeker_entered_roles[k] = `user_experiences.job_role_display: ${jobseekerEnteredRoles[k]} <score=${2}>`
        }
    }
    const [filters, setFilters] = React.useState({
        freshers: false,
        female: false
    })

    //function to handle next/submit button
    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            //   AnalyticsHandler.track('JobPostingForm_submitBtn')
            setDisableSubmitBtn(true);
            delete values.min_salary;
            delete values.max_salary;
            axios.post(BASE_URL + '/jobposting/createjob', values, { withCredentials: true })
                .then((response) => {
                    //   AnalyticsHandler.track('JobPostingForm_jobCreate_Successful');
                    setFormSubmitSuccess(true);
                    setFormSubmitError(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setPostedJobId(response.data.data._id);
                })
                .catch((error) => {
                    //   AnalyticsHandler.track('JobPostingForm_createNewJob_Error');
                    console.log(error)
                    // if (error.response.status === 400) {
                    //     if (error.response.data.message === 'Session Expire') {
                    //         setSubmitErrorMessage('Please check the details again and retry submitting the form');
                    //     } else {
                    //         setSubmitErrorMessage(error.response.data.message);
                    //     }
                    // }
                    setFormSubmitSuccess(false);
                    setFormSubmitError(true);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                })
        } else {
            //   AnalyticsHandler.track('JobPostingForm_nextBtn');
            if (activeStep === 0) {
                if (!values.title) {
                    setErrors({ ...errors, title: 'Job title is required*' });
                }
                else if (!values.mapped_role) {
                    setErrors({ ...errors, mapped_role: 'Job role is required*' });
                }
                else if (!values.opportunity_type) {
                    setErrors({ ...errors, opportunity_type: 'Job type is required*' });
                }
                else if (values.job_description.trim() === '') {
                    setErrors({ ...errors, job_description: 'Job description is required*' });
                }
                else {
                    setErrors({});
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setFormStatus(prev => prev + 14);
                    //   addAttribute();
                }
            }
            else if (activeStep === 1) {
                if (values.job_location.length === 0) {
                    if (!values.isWorkFromHome) {
                        setErrors({
                            ...errors,
                            job_location: 'Job location is required*',
                        })
                    } else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                        setFormStatus(prev => prev + 7);
                        // addAttribute();
                    }
                }
                else if (!values.no_of_vacancies || isNaN(values.no_of_vacancies) || values.no_of_vacancies < 1) {
                    setErrors({ ...errors, no_of_vacancies: 'Please enter a valid number*' });
                }
                else {
                    setErrors({});
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setFormStatus(prev => prev + 14);
                    //   addAttribute();
                }
            }
            else if (activeStep === 2) {
                if ((values.min_salary === '' || values.min_salary < 1) && !isUnpaid) {
                    setErrors({
                        ...errors,
                        min_salary: 'Minimum salary amount is required*',
                    })
                } else if ((values.max_salary === '' || values.max_salary < 1) && !isUnpaid) {
                    setErrors({
                        ...errors,
                        max_salary: 'Maximum salary amount is required*',
                    })
                }
                else {
                    setValues({ ...values, job_salary_amount: values.min_salary ? values.min_salary.concat('-' + values.max_salary) : '0' })
                    setErrors({});
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setFormStatus(prev => prev + 14);
                    //   addAttribute();
                }
            }
            else if (activeStep === 3) {
                if (values.qualification_req.length < 1 || values.qualification_req.some(qual => qual.course_type === null && (values.qualification_req.length > 0 && values.qualification_req[0].course_type !== 'any'))) {
                    setErrors({
                        ...errors,
                        qualification_req: 'Course type is required*',
                    })
                } else {
                    setErrors({});
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setFormStatus(prev => prev + 14);
                    //   addAttribute();
                }

            }
        }
    };
    const addAttribute = () => {
        let currentStep = stepRef.current[activeStep].children[1].children[0];
        if (currentStep.getAttribute('class').includes('MuiStepLabel-active')) {
            currentStep.setAttribute('name', 'completedStep');
        }
    }
    //handle back button
    const handleBack = () => {
        if (activeStep === 2 && showRoles === false) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
            setFormStatus(prev => prev - 14);
        }
        else if (activeStep === 4 && showDuration === false) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
            setFormStatus(prev => prev - 14);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setFormStatus(prev => prev - 7);
        }
    };

    const steps = getSteps();

    const goToDiscoveryTab = () => {
        if (window.location.pathname === '/') {
            window.location.reload();
        } else {
            history.push({
                pathname: '/'
            });
        }

    };

    const handleReset = () => {
        setActiveStep(0);
    };

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_experience_type').then((res) => {
            setJobType(res.data);
        })
    }, []);

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_benefits').then((Result) => {
            let perksArr = Result.data;
            setBenefits(perksArr);
        })
    }, []);

    const [locationValue, setLocationValue] = React.useState([]);
    const [isWfhChecked, setIsWfhChecked] = React.useState(false);
    const locRef = React.useRef();

    React.useEffect(() => {
        axios.get(BASE_URL + '/select/get_all_locations').then((Result) => {
            let locArr = Result.data;
            let filtered = locArr.filter(item => item.city !== 'Work From Home')
            setLocation(filtered);
        })
    }, []);

    React.useEffect(() => {
        if (Array.isArray(values.job_location) && values.job_location.length > 0) {
            let locVal = [];
            for (let i = 0; i < values.job_location.length; i++) {
                const element = values.job_location[i];
                locVal.push({ city: element });
            }
            setLocationValue(locVal);
        } else if (typeof values.job_location === 'string') {
            setLocationValue([{ city: values.job_location }]);
        }
    }, []);

    // invite list api functionality starts for recommended profiles
    const [inviteList, setInviteList] = React.useState([]);

    React.useEffect(() => {
        const handleInviteList = () => {
            let payload = {
                company_id: localStorage.getItem('company_id'),
                job_role: values.mapped_role,
            }
            //alert(payload.job_role)
            ApiHandler.getInviteList(payload, response => {
                if (response.status == 200) {
                    setInviteList(response.data);
                } else if (response.response.data.message === 'Session Expire') {
                    console.log("session expired while getting invite list");
                } else {
                    //console.log("error in getting a successful response for invited jobseeker profiles")
                }
            })
        }

        if (isSignedIn) {
            //alert(isSignedIn)
            handleInviteList();
            //console.log('invite list useeffect called')
        }
    }, [formSubmitSuccess])

    const Results = connectStateResults(
        ({ searchState, searching, searchResults, children }) => {

            if (!searchResults) {
                setProfileView('no_results');
                setLocationValue("Anywhere/ Work-from-Home");
                //setFlagLocationText(true);
            }

            // no results found for the search parameters entered
            if (searchResults && searchResults.nbHits == 0) {

                setProfileView('no_results');
                setLocationValue("Anywhere/ Work-from-Home");
                switchtoRecentFromNearest(); // after location is changed to Anywhere, change sort order to recent from nearest
                // setFlagLocationText(true);
                setFlagNoResults(true);
            }

            if (searchResults && (searchResults.nbHits > 0)) {
                if (locationValue == "Anywhere/ Work-from-Home") {
                    setProfileView('cards');
                    setLocationValue("");
                    setFlagNoResults(false);
                }
            }
            if (searchResults && searchResults.nbHits) {
                setNbHits(searchResults.nbHits);

            }
            return searchResults && searchResults.nbHits !== 0
                ? (children) // if results are not 0 then show the results
                : (searching ?
                    // <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> 
                    <div></div> // else if searching for results then show empty div
                    //else show no results page
                    : (<div>
                        <No_Results
                            searchRef={searchRef}
                        />
                    </div>)
                )
        }
    );
    const switchtoRecentFromNearest = () => {
        if (sortSelected === "user_experience_and_qualification") {
            setSortSelected("last_active_standard")
        }
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return <>
                    <WorkTitle classes={classes} errors={errors} setErrors={setErrors} values={values} setValues={setValues} setShowRoles={setShowRoles} />
                    {showRoles && <Role classes={classes} values={values} setValues={setValues} errors={errors} setErrors={setErrors} />}
                    <WorkType classes={classes} errors={errors} setErrors={setErrors} values={values} setValues={setValues} setShowDuration={setShowDuration} setShowUnpaidOption={setShowUnpaidOption} jobType={jobType} />
                    {showDuration && <Duration classes={classes} values={values} setValues={setValues} />}
                    <Schedule classes={classes} values={values} setValues={setValues} isMobile={true} />
                    <Description classes={classes} errors={errors} setErrors={setErrors} values={values} setValues={setValues} minRows={3} />
                </>
            case 1:
                return <>
                    <Grid item xs={12} className={classes.my4} >
                        <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Location(s)<span style={{ color: '#f44336' }}>*</span></Typography>
                        <FormControlLabel
                            control={<Checkbox checked={isWfhChecked}
                                onChange={(e) => {
                                    setIsWfhChecked(prev => !prev);
                                    if (e.target.checked) {
                                        setValues({ ...values, isWorkFromHome: true });
                                    } else {
                                        setValues({ ...values, isWorkFromHome: false });
                                    }
                                }}
                            />}
                            label='Work From Home'
                            className={classes.allQualCheckbox}
                        />
                        <Autocomplete
                            //   classes={{ root: classes.locationAutoComplete }}
                            multiple
                            name="job_location"
                            ref={locRef}
                            options={location}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                return option.city
                            }
                            }

                            renderOption={(option) => {
                                if (option.city !== 'Work From Home') {
                                    return option.city
                                }
                            }}

                            filterSelectedOptions
                            getOptionSelected={(option, value) => option.city === value.city}
                            value={locationValue || []}
                            // defaultValue={values.job_location}
                            onChange={(event, newValue) => {
                                event.preventDefault();
                                setErrors({});
                                if (newValue) { // when value is selected from dropdown
                                    let locVal = [];
                                    // let isWorkFromHome = false;
                                    let latLng = [];
                                    newValue.map((item, index) => {
                                        // if (item.city === 'Work From Home') {
                                        //   isWorkFromHome = true;
                                        // } else {
                                        locVal.push({ city: item.city, address: '', area: '' })
                                        latLng.push([item.longitude, item.latitude])
                                        // }
                                    });
                                    // setValues({ ...values, job_location: locVal, isWorkFromHome, geo_json: latLng.length > 0 ? { type: 'MultiPoint', coordinates: latLng } : null });
                                    setValues({ ...values, job_location: locVal, geo_json: latLng.length > 0 ? { type: 'MultiPoint', coordinates: latLng } : null });
                                    setLocationValue(newValue);
                                    // locVal.length === 1 ? setValues({ ...values, job_location: locVal.toString() }) : setValues({ ...values, job_location: locVal });
                                }
                            }}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={window.innerWidth <= 600 ? 'outlined' : 'standard'}
                                    error={errors.job_location ? true : false}
                                    helperText={errors.job_location ? errors.job_location : ''}
                                    placeholder={values.job_location && values.job_location.length > 0 ? '' : 'Select work from home or search location'}
                                    classes={{ root: classes.TfLocationRoot }}
                                />
                            )}
                        />
                    </Grid>
                    <Vacancies classes={classes} errors={errors} setErrors={setErrors} values={values} setValues={setValues} />
                </>
            case 2:
                return <>
                    <Salary classes={classes} errors={errors} setErrors={setErrors} values={values} setValues={setValues} showUnpaidOption={showUnpaidOption} setIsUnpaid={setIsUnpaid} isMobile={true} />
                    <Perks classes={classes} values={values} setValues={setValues} benefits={benefits} setBenefits={setBenefits} />

                </>
            case 3:
                return <>
                    <Qualifications classes={classes} values={values} setValues={setValues} errors={errors} setErrors={setErrors} isMobile={true} />
                    <Skills classes={classes} values={values} setValues={setValues} isMobile={true} />
                    <Gender classes={classes} values={values} setValues={setValues} />
                    <OtherRequirements classes={classes} values={values} setValues={setValues} />
                </>
            case 4:
                return <>
                    <ScreeningForm classes={classes} values={values} setValues={setValues} customQuestions={customQuestions} setCustomQuestions={setCustomQuestions} />
                </>
            default:
                return 'Unknown stepIndex';
        }
    }
    const Connector = withStyles({
        alternativeLabel: {
            top: '12px',
            left: '-20px',
            right: '52px',
            position: 'absolute'
        },
        // active: {
        //     '& $line': {
        //         backgroundImage:
        //             'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        //     },
        // },
        // completed: {
        //     '& $line': {
        //         backgroundImage:
        //             'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        //     },
        // },
        line: {
            height: 3,
            border: 0,
            backgroundColor: '#eaeaf0',
            borderRadius: 1,
        },
    })(StepConnector);

    const [jobFormVisibility, setJobFormVisibility] = React.useState('visible');

    // create job updating date in a particular format
    const createDate = (date) => {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let responseDate = date ? new Date(date) : null;
        let jobUpdatedDate = date ? responseDate.getDate() + " " + months[responseDate.getMonth()] + ", " + responseDate.getFullYear() : null
        return jobUpdatedDate;
    }

    const getBackToJobForm = () => {
        setJobFormVisibility('visible');
        setJobPreviewModal(false);
    }

    // update the job
    const getJobDataToUpdateJob = (job) => {
        // AnalyticsHandler.track("JobOpenings_editJobBtn")
        // setJobFormModalLoader(true);
        axios.post(`${BASE_URL}/companies/getJobDetails`, { _id: job._id })
            .then(res => {
                let company_id = localStorage.getItem('company_id').toString()
                let jobpost_id = job._id;
                let latLngArr = [];
                let currentJobData = { ...res.data, jobpost_id, company_id };
                let data;
                if (currentJobData.geo_json) {
                    currentJobData.geo_json.coordinates.forEach(element => {
                        if (typeof element === 'number' && (element !== 0.1276 && element !== 51.5072)) {
                            latLngArr.push(element);
                            data = { ...currentJobData, geo_json: { type: 'MultiPoint', coordinates: [latLngArr] } }
                        } else if (element === 0.1276 || element === 51.5072) {
                            data = { ...currentJobData, geo_json: null }
                        } else {
                            data = { ...currentJobData }
                        }
                    })
                } else {
                    data = { ...currentJobData }
                }
                delete data._id
                delete data.companies
                setPostedJobData(data);
                setJobUpdateLoader(false);
            })
    }

    return (
        <div style={{ margin: '5em 1em' }} className={classes.root}>
            {/* <div style={{boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)'}}>
                <p>Post Jobs</p>
            </div> */}
            {!jobPreviewModal && !showJobUpdatePage && <div style={{ display: 'flex', position: 'fixed', top: 0, left: 0, color: '#333', cursor: 'pointer', zIndex: '1500', backgroundColor: '#fff', width: '100%', height: 60, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.17)', alignItems: 'center', justifyContent: 'flex-start' }}>
                <ArrowBack fontSize='small' style={{ color: '#333', cursor: 'pointer', zIndex: '1500', padding: 5 }} onClick={() => props.setOpenJobPostingForm(false)} /> Post Jobs
            </div>}
            <Stepper style={{ visibility: jobFormVisibility, backgroundColor: 'transparent' }} activeStep={activeStep} connector={<Connector />} alternativeLabel className={classes.customStepper}>
                {steps.map((label) => (
                    <Step key={label} className={classes.customStep}>
                        <StepLabel classes={{ active: classes.stepLabelActive, completed: classes.stepLabelCompleted, label: classes.stepLabel, iconContainer: classes.iconContainer, labelContainer: classes.labelContainer }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div style={{ visibility: jobFormVisibility, border: '1px solid #55c6a9', borderRadius: '5px', padding: '0 1em', backgroundColor: '#fff', zIndex: 10, position: 'relative' }}>
                {activeStep === steps.length ? (
                    <div className={classes.form_card}>
                        {formSubmitSuccess && <div className={`${classes.instructions} ${classes.flexboxCol}`}>
                            <Typography variant='h5' component='h5' className={classes.my1} style={{ color: '#55c6a9', fontSize: '1.25rem' }}>Form completed successfully!</Typography>
                            <Typography variant='body1' component='p' style={{ fontSize: '14px', marginTop: '1em', textAlign: 'center' }}>Please give our team 24-48 hours to review the job post.</Typography>
                            <Typography variant='body1' component='p' style={{ fontSize: '14px', textAlign: 'center' }}>You will be notified once the job is live on our app.</Typography>
                        </div>}
                        {formSubmitError && <div className={`${classes.instructions} ${classes.flexboxCol}`}>
                            <Typography variant='h5' component='h5' className={classes.my1} style={{ color: '#55c6a9', fontSize: '1.25rem' }}>Some error occured!</Typography>
                            <Typography variant='body1' component='p' style={{ fontSize: '1.2rem', marginTop: '1em' }}>{submitErrorMessage}</Typography>
                        </div>}
                        {formSubmitUpdateSuccess && <div className={`${classes.instructions} ${classes.flexboxCol}`}>
                            <Typography variant='h5' component='h5' className={classes.my1} style={{ color: '#55c6a9' }}>Job updated successfully!</Typography>
                            {/* <Typography variant='body1' component='p' style={{ fontSize: '1.2rem', marginTop: '1em' }}>Please fill the details again and retry submitting the form.</Typography> */}
                        </div>}
                        {formSubmitUpdateError && <div className={`${classes.instructions} ${classes.flexboxCol}`}>
                            <Typography variant='h5' component='h5' className={classes.my1} style={{ color: '#55c6a9' }}>Some error occured!</Typography>
                            <Typography variant='body1' component='p' style={{ fontSize: '1.2rem', marginTop: '1em' }}>Please fill the details again and retry updating the job.</Typography>
                        </div>}
                        <div className={classes.flexbox} style={{ margin: '2em auto' }}>
                            {formSubmitSuccess && <Button
                                className={classes.previewButton}
                                variant='contained'
                                onClick={() => {
                                    AnalyticsHandler.track('JobPostingForm_previewBtn')
                                    setJobPreviewLoader(true);
                                    setJobPreviewModal(true);
                                    setJobFormVisibility('hidden');
                                }}

                            >
                                Preview Job
                            </Button>}
                            <Button className={classes.nextButton}
                                onClick={goToDiscoveryTab}

                            >
                                {'Go To Homepage'}
                            </Button>
                        </div>
                        {formSubmitSuccess && <div>
                            <Typography style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 500 }} variant='body1' component='h2'>Here are some candidates you can invite for this role: </Typography>
                            <div id='algolia_results' style={{ maxHeight: '70vh', minHeight: '70vh', overflowY: 'auto', overflowX: 'hidden', padding: window.innerWidth <= 600 ? 0 : '2em 1em 0 0', marginTop: '1em', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
                                <InstantSearch indexName="last_active_standard" searchClient={searchClient}>
                                    <Configure
                                        page={1}
                                        hitsPerPage={12}
                                        filters={(values.mapped_role.toLowerCase().trim() === "fresher" || filters.freshers ? `total_months_exp<6` : `total_months_exp=0 OR total_months_exp>0`)}
                                        aroundLatLng={values.geo_json.coordinates.length > 0 ? `${values.geo_json.coordinates[0][1]}, ${values.geo_json.coordinates[0][0]}` : `0,0`}
                                        optionalFilters={[[...qualifications_id, `user_experiences.user_post: ${RolesNotToBePassedToAlgolia.includes(values.mapped_role.toLowerCase().trim()) ? '' : values.mapped_role} <score=${2}>`, ...secondary_roles, ...jobseeker_entered_roles]]}
                                    />
                                    <div className="flex-container-pagination flex-container-pagination-list">
                                        <div data-classname="pagination"
                                            style={{ width: window.innerWidth <= 600 ? '100%' : '90%' }} //this is imp to init the infinite scroll
                                            ref={paginationRef}  // to click the next btn after invite all
                                        >
                                            {/* list view */}

                                            <ListLoader
                                                setListLoaderRef={setListLoaderRef}
                                                isListLoaderOn={isListLoaderOn}
                                            />
                                            <Results>
                                                <Hits
                                                    minHitsPerPage={12}
                                                    location={values.job_location}
                                                    role={values.mapped_role}
                                                    secondaryRoles={secondaryRoles}
                                                    qualifications={qualifications}
                                                    searchRef={searchRef}
                                                    signInStatus={checkSignedInStatus}
                                                    sendLocationForInvite={sendLocationForInvite}
                                                    inviteList={inviteList}
                                                    geographical_type={geographical_type}
                                                    paginationRef={paginationRef}
                                                    classes={classes}
                                                />
                                                <Pagination />
                                            </Results>
                                        </div>
                                    </div>
                                </InstantSearch>
                            </div>
                        </div>}
                    </div>
                ) : (
                    <div>
                        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                        <div className={`${classes.flexbox} ${classes.nextBackBtns}`}>
                            <Button disabled={activeStep === 0} style={{ width: '45%' }} variant="contained" color="primary" onClick={() => setActiveStep(p => p - 1)}>
                                Back
                            </Button>
                            <Button disabled={disableSubmitBtn} style={{ width: '45%' }} variant="contained" color="primary" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {jobPreviewModal && <div className={`${classes.job_preview}`}>
                <JobPreview classes={classes} preview={true} createDate={createDate} handleBack={getBackToJobForm} jobPreviewLoader={jobPreviewLoader} setJobPreviewLoader={setJobPreviewLoader} currentJobId={postedJobId} getJobDataToUpdateJob={getJobDataToUpdateJob} setShowJobUpdatePage={setShowJobUpdatePage} setJobUpdateLoader={setJobUpdateLoader} setJobPreviewModal={setJobPreviewModal} jobFormDialogRef={props.jobFormDialogRef} isMobile={true} />
            </div>}
            {showJobUpdatePage &&
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh' }}>
                    {jobUpdateLoader && <Loader containerWidth='96%' maxWidth='40px' minHeight='75vh' />}
                    {!jobUpdateLoader && <JobUpdate height='86vh' setJobFormVisibility={setJobFormVisibility} values={postedJobData} setShowJobUpdatePage={setShowJobUpdatePage} setJobUpdateLoader={setJobUpdateLoader} doRefresh={doRefresh} />}
                    {<div style={{ display: 'flex', position: 'fixed', top: 0, left: 0, color: '#333', cursor: 'pointer', zIndex: '1500', backgroundColor: '#fff', width: '100%', height: 65, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.17)', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <ArrowBack fontSize='small' style={{ color: '#333', cursor: 'pointer', zIndex: '1500', padding: 5 }} onClick={() => {
                            doRefresh(p => !p)
                            setShowJobUpdatePage(false);
                            // setJobPreviewDisplay('block');
                            setJobPreviewModal(true);
                        }} /> Back to job preview
                    </div>}
                    {/* <ArrowBack fontSize='large' style={{ position: 'fixed', left: '2%', top: '10%', cursor: 'pointer', color: '#fff' }}
                        onClick={() => {
                            doRefresh(p => !p)
                            setShowJobUpdatePage(false);
                            // setJobPreviewDisplay('block');
                            setJobPreviewModal(true);
                        }}
                    /> */}
                </div>}
        </div>
    );
}