import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Paper, TextField, Button, Typography } from '@material-ui/core';
import '../../assets/css/meetApp.css';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { ThemeProvider } from '@material-ui/styles';
import MyTheme from '../../style/JobAppTheme';
import { StylesProvider } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../Login/Login_2.css';
import ApiHandler from '../../ApiHandler';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  bgOverlay: {
    background: 'rgba(0,0,0,0.15)',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  pageTitle: {
    color: "white",
    paddingTop: "135px",
    paddingBottom: "50px",
    fontSize: "36px",
    fontWeight: 'bolder',
    textShadow: "2px 2px 5px black"
  },
  searchBarWrapper: {
    display: "flex",
    flexDirection: "row",
    background: "white",
    // width: 900,
    width: 400,
    height: 40,
    // paddingLeft: 15,
    paddingLeft: 10,
    borderRadius: 35,
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    "&.shrink": {
      height: 40,
    },
    '@media (max-width: 600px)': {
      width: '90vw'
  },
  },
  searchBarIconWrapper: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    height: '100%',
    width: 40,
    minWidth: 40,
    textTransform: "none",
    color: '#14967B',
    fontSize: 16,
  },
  icon: {
    //color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    color: '#999',
    fontSize: 16,
    marginTop: 5,
  },
  comboBoxRoot: {
    marginTop: 0,
    border: '0!important',
    "& .MuiFormLabel-root": {
      color: "black",
      fontWeight: "700",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#5BC2A8",
      fontWeight: "700",
    },
  },
  inputRoot: {
    padding: '0 10px 0 0!important',
    borderColor: 'transparent!important',
    fontSize: 14,
    //   "& .MuiIconButton-label": { 
    //     display: "flex",  
    // },
    "& *, &:hover > *": {
      borderColor: 'transparent!important',
    },
  },

  popupIndicator: {
    display: 'none'
  },

  input: {
    height: 40,
    maxWidth: '98%',
    "&::-webkit-input-placeholder": {
      color: 'rgba(0, 0, 0, 0.6)',
      opacity: 1,
    },
  },
  popper: {
    marginTop: 16,
  },
  listbox: {
  },
  paper: {
    borderRadius: 10,
  },
  option: {
    textTransform: 'capitalize',
  }
}));

export default function SearchBar(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [invitedList, setInvitedList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    const handleInvitedList = () => {
      let payload = {
        company_id: localStorage.getItem('company_id'),
      }
      ApiHandler.getInvitedPageList(payload, response => {
        if (response.status == 200) {
          //console.log(response.data);
          let data = response.data;
          data.sort((a, b) => {
            let nameA = a.jobseeker_name;
            let nameB = b.jobseeker_name;
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }        
            return 0;
          }
          )
          setInvitedList(data);
        } else if (response.response && response.response.data.message === 'Session Expire') {
          props.removeLocalStorageOnSessionExpiry();
        } else {
          console.log("error in getting a successful response for invited jobseeker profiles")
        }
        setIsLoading(false);
      })
    }
    // if (isSignedIn) {
    //alert(isSignedIn)
    handleInvitedList();
    // }
  }, [])

  return (
    <div className="searchBarOuterWrapper">
      {/* <StylesProvider injectFirst> */}
      <div className={`${classes.searchBarWrapper} ${classes.shrink}`} style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)' }}>
        <div className={classes.searchBarIconWrapper}>
          <SearchIcon style={{ fontSize: 25 }} />
        </div>

        <Autocomplete
          id="jobseekersBox"
          options={invitedList}
          getOptionLabel={(option) => option.jobseeker_name ? option.jobseeker_name.toLowerCase(): ""}
          onChange={(event, newvalue) => {
            if (newvalue) {
              //alert("onchange triggered and newvalue entered")
              console.log(newvalue._id)
              props.setJobseekerIDSearched(newvalue._id);
            } else {
              //alert("not new search value")
              props.setJobseekerIDSearched(null)
            }
          }}
          loading={isLoading}
          style={{ flex: 1 }}
          classes={{ root: classes.comboBoxRoot, inputRoot: classes.inputRoot, input: classes.input, popper: classes.popper, listbox: classes.listbox, paper: classes.paper, popupIndicator: classes.popupIndicator, option: classes.option }}
          renderInput={(params) => <TextField {...params} value="" variant="outlined" placeholder="Search by name and select"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress style={{ color: '#ccc' }} size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
          }
        />
      </div>
      {/* </StylesProvider> */}
    </div>
  );
}

