import React from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';

function Vacancies(props) {
  const { classes, values, setValues, errors, setErrors } = props;
  return <Grid item xs={12} className={classes.my3} >
    <Typography gutterBottom variant='h5' className={`${classes.my2} ${classes.inputHeading}`}>Vacancies<span style={{color: '#f44336'}}>*</span></Typography>
    {/* {showError && <Alert icon={false} severity="error">
      Please enter a valid number.
    </Alert>} */}
    <TextField classes={{ root: classes.TfRoot }} variant={window.innerWidth <= 600 ? 'outlined' : 'standard'} value={values.no_of_vacancies !== null ? values.no_of_vacancies : ""} type='number' fullWidth size='medium' required placeholder='Add number of openings e.g. 2'
      onChange={e => {
        // if (e.target.value < 1 || isNaN(e.target.value)) {
        //   setShowError(true);
        // } else {
        //   console.log(e.target.value);
        //   setShowError(false);
        // }
        setErrors({});
        setValues({ ...values, no_of_vacancies: e.target.value });
      }}
      error={errors.no_of_vacancies ? true : false}
      helperText={errors.no_of_vacancies ? errors.no_of_vacancies : ''}
    />
    {/* {errors.no_of_vacancies && <Alert icon={<CancelIcon />} severity="error">{errors.no_of_vacancies}</Alert>} */}
    {/* <span className={classes.helper_text}> Press any key between 1 to 5 to select. </span> */}
  </Grid>;
}

export default Vacancies;
