import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PrimaryAppBar from '../../components/JobAppBar';
import { StylesProvider } from "@material-ui/core/styles";
import '../../style/App.css';
import { useHistory } from "react-router-dom";
import global_isSignedIn from '../discovery/global_isSignedIn';
import SignInUpDrawer from '../../components/SignInUpDrawer';
import ApiHandler from '../../ApiHandler';
import JobsForm from '../../components/JobForm'
import { Dialog, DialogContent } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiDialog-paper': {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
      position: 'static !important',
    },
    '.MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
    '.MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
    },
  },
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // paddingTop: 84,
    paddingTop: '4rem',
    backgroundAttachment: 'fixed',
  },
  pageTitle: {
    color: '#5BC2A8',
    textAlign: 'center',
    marginBottom: '0.2em',
    fontWeight: 500,
  },
  pageBar: {
    //width: 860,
    width: 772,
    flexBasis: 65,
    //marginTop: 35,
    marginTop: 0,
    zIndex: 8,
  },
  pageInfo: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '3em',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80vh',
    maxWidth: '80%'
  },
  pageInfoText: {
    marginBottom: 20,
    color: "#555",
    lineHeight: '2em',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      maxWidth: 'initial'
    },
  },
  relevanceMsgBar: {
    width: 860,
    //flexBasis: 65,
    //marginTop: 25,
    zIndex: 8,
    padding: '0',
    marginLeft: 7,
    marginTop: 10,
    background: 'linear-gradient(89deg, rgb(255 255 255 / 0%) 0%, rgb(230 230 230 / 80%) 50%, rgba(255,255,255,0.1) 100%)',
  },
  subtitle: {
    lineHeight: '2em',
    color: '#797979',
    marginRight: 10,
    fontSize: 14,
    fontWeight: 500,
  },
  listViewWrapper: {
    opacity: 1,
    '&.animate': {
      opacity: 0,
      transition: 'opacity 0.25s ease-in-out',
    }
  },
  headerButton: {
    textTransform: "none",
    width: 100,
    height: 35,
  },
  headerButton_pri: {
    backgroundColor: "#14967B",
    color: "white",
    borderRadius: 5,
    '&:hover': {
      backgroundColor: "#14967B",
    },
  },
  headerButton_sec: {
    backgroundColor: "transparent",
    color: "#14967B",
    //border: "1px solid #14967B",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
}));

export const DrawerContext = React.createContext({});

export default function Jobs(props) {
  const classes = useStyles();
  const [navValue, setNavValue] = React.useState(false);

  let sessionCookie = process.env.REACT_APP_NODE_ENV === 'development' ? true : document.cookie.indexOf('connect.sid') === -1 ? null : true;
  const [isSignedIn, setIsSignedIn] = React.useState((sessionCookie && localStorage.getItem("is_logged_in")) || false);
  global_isSignedIn.isSignedIn = (sessionCookie && localStorage.getItem("is_logged_in")) || false;

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const checkSignedInStatus = () => {
    return isSignedIn
  }
  const handleSignedInStatus = (v) => {
    setIsSignedIn(v);
  }

  document.body.classList.remove("login");
  // document.body.classList.add('wallpaper--icon');

  const jobsSection = (
    <div className={classes.pageInfo}>
      {/* <Typography className={classes.pageInfoText} variant="h6">Jobs Page Coming Soon...</Typography> */}
      {/* <Dialog open aria-labelledby="form-dialog-title">
        <DialogContent>
          <ArrowBackIcon fontSize='large' onClick={handleClose} style={{ position: 'absolute', top: '10%', left: '2%', color: '#fff', cursor: 'pointer' }} />
        </DialogContent>
      </Dialog> */}
      <JobsForm />
    </div>

  )

  React.useEffect(() => {
    if (global_isSignedIn.isSignedIn === false) {
      setIsSignedIn(false);
    } else if (global_isSignedIn.isSignedIn === true) {
      setIsSignedIn(true);
    }
  }, [global_isSignedIn.isSignedIn])

  // drawer callback starts
  const [refresh, doRefresh] = React.useState(false);
  const [forRefreshOnly, setForRefreshOnly] = React.useState(false);
  const [canDrawerOpen, setCanDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setCanDrawerOpen(true)
    doRefresh(prev => !prev)
  }
  // drawer callback ends

  // drawer context for card view starts
  const setCommand = (command) => {
    setState({ ...state, command: command })
  }

  const initCardDrawerState = {
    command: "",
    setCommand: setCommand
  }

  const [state, setState] = React.useState(initCardDrawerState)

  React.useEffect(() => {
    if (state.command !== "") {
      handleDrawer();
    }
  }, [state])
  // drawer context for card view ends

  return (
    <>
      {/* <StylesProvider injectFirst> */}
      <div className={classes.root} style={{ backgroundColor: isSignedIn ? '' : '#fff' }}>
        <Helmet>
          <title>Post Job</title>
          <meta name='description' content='Post a new job vacancy.' />
        </Helmet>
        {/* <PageLoader loaderMessage={loaderMessageP1ToP2()}></PageLoader> */}
        {/* <PrimaryAppBar
          isSignedIn={isSignedIn}
          setIsSignedIn={handleSignedInStatus}
          handleDrawer={handleDrawer}
          navValue={navValue}
          setNavValue={setNavValue}
        >
        </PrimaryAppBar> */}
        {isSignedIn
          ? jobsSection
          : <div className={classes.pageInfo}>
            <Typography className={classes.pageInfoText} variant="h6">Sign in to post new jobs and process applications for your posted jobs.</Typography>
            <Button variant="contained" className={`${classes.headerButton} ${classes.headerButton_pri}`} onClick={handleDrawer}>
              Sign In
            </Button>
          </div>
        }
        {/* {jobsSection} */}
      </div>
      <SignInUpDrawer
        canDrawerOpen={canDrawerOpen}
        refresh={refresh}
        isSignedIn={isSignedIn}
        setIsSignedIn={handleSignedInStatus}
        setForRefreshOnly={setForRefreshOnly}
        setCanDrawerOpen={setCanDrawerOpen}
        doRefresh={doRefresh}
      />
      {/* </StylesProvider> */}
    </>
  )
}