import React, { useState, useEffect, useMemo, useRef } from "react";

import Button from '@material-ui/core/Button';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";

//import { csv } from "d3-fetch";
import { scaleQuantize } from "d3-scale";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageLoader from '../discovery/PageLoader';
import LogoLoader from '../../assets/logo-loader.gif';
import useStyles from './styles'; 

//const geoUrl = "/districtMap.json";
//const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/countries/india/india-districts.json"
const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/countries/india/india-districts.json"

// const colorScale = scaleQuantize()
//   .domain([1, 2000])
//   .range([
//     "#e0f2f1",
//     "#b2dfdb",
//     "#80cbc4",
//     "#4db6ac",
//     "#26a69a",
//     "#009688",
//     "#00897b",
//     "#00796b",
//     "#00695c",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#5BC2A8",
//     "#fefefe"
//   ]);

const colorScale = scaleQuantize()
  .domain([1, 2000])
  .range([
    "#5BC2A860",
    "#5BC2A865",
    "#5BC2A870",
    "#5BC2A875",
    "#5BC2A880",
    "#5BC2A885",
    "#5BC2A890",
    "#5BC2A895",
    "#5BC2A899",
    "#5BC2A8a0",
    "#5BC2A8a5",
    "#5BC2A8b0",
    "#5BC2A8b5",
    "#5BC2A8c0",
    "#5BC2A8c5",
    "#5BC2A8d0",
    "#fefefe"
  ]);

const MapChart = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]); // list of required districts with their companies count
  const [position, setPosition] = useState({ coordinates: [20.593684, 78.96288], zoom: 20 });
  const [maxValue, setMaxValue] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [origMarkers, setOrigMarkers] = useState([]);
  const [distLabelMarkers, setDistLabelMarkers] = useState([
    { markerOffset: 0, name: "Ambala", coordinates: [76.776695, 30.378180] },
    { markerOffset: 0, name: "Panchkula", coordinates: [76.854172, 30.695202] },
    { markerOffset: 0, name: "Kurukshetra", coordinates: [76.878281, 29.969513] },
    { markerOffset: 0, name: "Yamunanagar", coordinates: [77.2673901, 30.1290485] },
    { markerOffset: 0, name: "Jind", coordinates: [76.316666, 29.316668] },
    { markerOffset: 0, name: "Kaithal", coordinates: [76.398454, 29.785673] },
    { markerOffset: 0, name: "Karnal", coordinates: [76.990547, 29.685629] },
    { markerOffset: 0, name: "Panipat", coordinates: [76.977081, 29.398928] },
    { markerOffset: 7, name: "Bhiwani", coordinates: [76.133514, 28.799046] },
    { markerOffset: 0, name: "Mahendragarh", coordinates: [76.150932, 28.268347] },
    { markerOffset: 0, name: "Rohtak", coordinates: [76.606613, 28.895515] },
    { markerOffset: 0, name: "Hisar", coordinates: [75.721123, 29.151861] },
    { markerOffset: 2, name: "Gurugram", coordinates: [77.026344, 28.457523] },
    { markerOffset: 0, name: "Faridabad", coordinates: [77.31778940, 28.4089123] },
    { markerOffset: 0, name: "Palwal", coordinates: [77.3320262, 28.1487362] },
    { markerOffset: 3, name: "Mewat", coordinates: [77.056442, 28.010693] },
    { markerOffset: 0, name: "Rewari", coordinates: [76.616669, 28.183332] },
    { markerOffset: 0, name: "Delhi", coordinates: [77.2314900, 28.6519500] },
    { markerOffset: 0, name: "Jhajjar", coordinates: [76.656914, 28.607111] },
    { markerOffset: -3, name: "Sonipat", coordinates: [77.091492, 28.928574] },
    { markerOffset: 0, name: "Ghaziabad", coordinates: [77.449791, 28.667856] },
    { markerOffset: 0, name: "Noida", coordinates: [77.391029, 28.535517] }
  ]);

  const [tooltipData, setTooltipData] = useState("");
  const [curDist, setCurDist] = useState("")
  const [totalCompaniesinDist, setTotalCompaniesinDist] = useState(0);
  const [totalSectors, setTotalSectors] = useState(0); // total sectors in the current district

  const [allCompCount, setAllCompCount] = useState(0); // count of all companies
  const [secCompCount, setSecCompCount] = useState({}); // sectorwise count of companies

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  // vars to store count of companies in dist groups
  let ambalaCount = 0;
  let jindCount = 0;
  let bhiwaniCount = 0;
  let gurugramCount = 0;
  let delhiCount = 0;

  // vars to store count of sectors in dist groups
  let sectorCount = { ambala: 0, bhiwani: 0, jind: 0, gurugram: 0, delhi: 0 }
  let _data = [];
  // let distCat = "";
  // vars for count of sector-wise companies in the district groups
  let textilesSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let beautySecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let mechanicalSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }
  let healthcareSecCount = { ambala: 0, jind: 0, bhiwani: 0, gurugram: 0, delhi: 0 }

  // to calculate the bubble size proportional to count of companies in that dist group
  const popScale = useMemo(
    () => scaleLinear().domain([0, maxValue]).range([0.5, 8]),
    [maxValue]
  );

  useEffect(() => {
    //names of the required districts as spelled/named in our DB
    // Yamuna Nagar   Sonepat   Gautam Buddh Nagar "New Delhi", "South East Delhi", "West Delhi", "East Delhi", "South Delhi", "South West Delhi", "North West Delhi", "Central Delhi", "North Delhi", "North East Delhi"  
    const ambala = ["Ambala", "Yamuna Nagar", "Kurukshetra", "Panchkula"];
    const jind = ["Jind", "Hisar", "Kaithal", "Karnal", "Panipat"];
    const bhiwani = ["Bhiwani", "Mahendragarh", "Rohtak", "Charkhi Dadri"];
    const gurugram = ["Gurgaon", "Faridabad", "Palwal", "Rewari", "Mewat"];
    const delhi = ["New Delhi", "South East Delhi", "West Delhi", "East Delhi", "South Delhi", "South West Delhi", "North West Delhi", "Central Delhi", "North Delhi", "North East Delhi", "Jhajjar", "Sonepat", "Gautam Buddh Nagar", "Ghaziabad"]

    const requiredDists = ambala.concat(jind, bhiwani, gurugram, delhi); // list of all the required districts

    const _markers = [
      { markerOffset: 3, name: "Ambala", count: 0, coordinates: [77.096695, 30.278180] },
      { markerOffset: 3, name: "Jind", count: 0, coordinates: [76.616666, 29.616668] },
      { markerOffset: 3, name: "Bhiwani", count: 0, coordinates: [76.033514, 28.899046] },
      // { markerOffset: 3, name: "Gurugram", coordinates: [77.026344, 28.457523] },
      { markerOffset: 3, name: "Gurugram", count: 0, coordinates: [77.226344, 28.2] },
      { markerOffset: 3, name: "Delhi", count: 0, coordinates: [77.1314900, 28.7519500] }
    ];

    const _studentMarkers = [
      { markerOffset: 3, name: "Ambala", count: 1000, sectors: { textile: 0, beauty: 0, mechanical: 0, healthcare: 0 }, sectorCount: 0, coordinates: [76.776695, 30.378180] },
      { markerOffset: 3, name: "Jind", count: 750, sectors: { textile: 0, beauty: 0, mechanical: 0, healthcare: 0 }, sectorCount: 0, coordinates: [76.316666, 29.316668] },
      { markerOffset: 3, name: "Bhiwani", count: 1000, sectors: { textile: 0, beauty: 0, mechanical: 0, healthcare: 0 }, sectorCount: 0, coordinates: [76.133514, 28.799046] },
      // { markerOffset: 5, name: "Gurugram", coordinates: [77.026344, 28.457523] },
      { markerOffset: 3, name: "Gurugram", count: 760, sectors: { textile: 0, beauty: 0, mechanical: 0, healthcare: 0 }, sectorCount: 0, coordinates: [77.026344, 28.3] },
      { markerOffset: 3, name: "Delhi", count: 900, sectors: { textile: 0, beauty: 0, mechanical: 0, healthcare: 0 }, sectorCount: 0, coordinates: [77.2314900, 28.6519500] }
    ];

    // map the sectors in our DB
    const textilesSec = ["Textile and Apparel", "Fashion", "Footwear Manufacturing", "Handicrafts", "Jewellery"];
    const beautySec = ["Beauty and Cosmetology"];
    const mechanicalSec = ["Automobile"];
    const healthcareSec = ["Healthcare", "Wellness and Nutrition"];

    //get the districts data from our DB
    // fetch("/sectors.json")
    fetch("https://api.meetworks.in/companies/district/v2")
      .then((res) => res.json())
      .then((data) => {
        //console.log(data)
        data.map((district, index) => {
          let cur = requiredDists.find((item) => item === district.district)
          let c = district.total_count // count of companies in this district
          let cs = district.sector_count // count of companies in this district
          let s = district.sectors; // individual count of sector-wise companies in this district
          let sectorNames = Object.keys(s);
          //console.log(s)
          // if the specific sector category array includes the sector in the companies of a district, then add to the count
          const forLoop = (sector, count) => {
            let i;
            for (i = 0; i < sectorNames.length; i++) {
              if (sector.includes(sectorNames[i])) {
                //console.log(sectorNames[i])
                count += s[sectorNames[i]];
                //console.log(count)
              }
            }
          }

          if (cur) { //if the district is the same as any of the ones in requiredDists during this loop then add to the count and push to the array of _data of required districts
            if (ambala.some((item) => item === cur)) {
              ambalaCount += c; //add to count of companies in ambala dist group
              // distCat = "ambala"
              sectorCount.ambala += cs; //add to count of companies having sector mapping in ambala dist group

              let i;
              for (i = 0; i < sectorNames.length; i++) { //add to count of sector-wise companies in ambala dist group
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.ambala += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.ambala += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.ambala += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.ambala += s[sectorNames[i]];
                }
              }
              // forLoop(beautySec, beautySecCount.ambala);


            } else if (jind.some((item) => item === cur)) {
              jindCount += c;
              // distCat = "jind"
              sectorCount.jind += cs; //add to count of companies having sector mapping in jind dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.jind += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.jind += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.jind += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.jind += s[sectorNames[i]];
                }
              }
            } else if (bhiwani.some((item) => item === cur)) {
              bhiwaniCount += c;
              // distCat = "bhiwani"
              sectorCount.bhiwani += cs; //add to count of companies having sector mapping in bhiwani dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.bhiwani += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.bhiwani += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  mechanicalSecCount.bhiwani += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.bhiwani += s[sectorNames[i]];
                }
              }
            } else if (gurugram.some((item) => item === cur)) {
              gurugramCount += c;
              // distCat = "gurugram"
              sectorCount.gurugram += cs; //add to count of companies having sector mapping in gurugram dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.gurugram += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.gurugram += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  //console.log(sectorNames[i])
                  mechanicalSecCount.gurugram += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.gurugram += s[sectorNames[i]];
                }
              }
            } else if (delhi.some((item) => item === cur)) {
              delhiCount += c;
              // distCat = "delhi"
              sectorCount.delhi += cs; //add to count of companies having sector mapping in delhi dist group
              let i;
              for (i = 0; i < sectorNames.length; i++) {
                if (textilesSec.includes(sectorNames[i])) {
                  textilesSecCount.delhi += s[sectorNames[i]];
                }
                if (beautySec.includes(sectorNames[i])) {
                  beautySecCount.delhi += s[sectorNames[i]];
                }
                if (mechanicalSec.includes(sectorNames[i]) || sectorNames[i].toLowerCase().includes("manufacturing")) {
                  //console.log(sectorNames[i])
                  mechanicalSecCount.delhi += s[sectorNames[i]];
                }
                if (healthcareSec.includes(sectorNames[i])) {
                  healthcareSecCount.delhi += s[sectorNames[i]];
                }
              }
            }

            _data.push({
              ["name"]: cur,
              ["count"]: c,
              // ["sectors"]: {
              //   "textile": textilesSecCount[distCat],
              //   "beauty": beautySecCount[distCat],
              //   "mechanical": mechanicalSecCount[distCat],
              //   "healthcare": healthcareSecCount[distCat]
              // }
            })
          }
        })
        //console.log(textilesSecCount, beautySecCount, mechanicalSecCount, healthcareSecCount)
        //console.log(_data)

        // making the count of companies in the districts in a group the same as their total so that they can be given a same color in the map
        _data.map(((district, index) => {
          if (ambala.some((item) => item === district.name)) {
            district.count = ambalaCount
          } else if (jind.some((item) => item === district.name)) {
            district.count = jindCount
          } else if (bhiwani.some((item) => item === district.name)) {
            district.count = bhiwaniCount
          } else if (gurugram.some((item) => item === district.name)) {
            district.count = gurugramCount
          } else if (delhi.some((item) => item === district.name)) {
            district.count = delhiCount
          }
        }))
        //console.log(_data)

        //sorting the districts in the _data in descending order of number of companies
        const sortedDistricts = sortBy(_data, (o) => -o.count);
        setMaxValue(sortedDistricts[0].count);
        setData(sortedDistricts)
        //console.log(sortedDistricts)

        //setting the count of companies on the markers
        _markers.map((item, index) => {
          if (index === 0) {
            item.count = ambalaCount
            item.allCount = ambalaCount
            item.sectorCount = sectorCount.ambala
            item.sectors = {
              textile: textilesSecCount.ambala,
              beauty: beautySecCount.ambala,
              mechanical: mechanicalSecCount.ambala,
              healthcare: healthcareSecCount.ambala
            }
          } else if (index === 1) {
            item.count = jindCount;
            item.allCount = jindCount;
            item.sectorCount = sectorCount.jind
            item.sectors = {
              textile: textilesSecCount.jind,
              beauty: beautySecCount.jind,
              mechanical: mechanicalSecCount.jind,
              healthcare: healthcareSecCount.jind
            }
          } else if (index === 2) {
            item.count = bhiwaniCount;
            item.allCount = bhiwaniCount;
            item.sectorCount = sectorCount.bhiwani
            item.sectors = {
              textile: textilesSecCount.bhiwani,
              beauty: beautySecCount.bhiwani,
              mechanical: mechanicalSecCount.bhiwani,
              healthcare: healthcareSecCount.bhiwani
            }
          } else if (index === 3) {
            item.count = gurugramCount;
            item.allCount = gurugramCount;
            item.sectorCount = sectorCount.gurugram
            item.sectors = {
              textile: textilesSecCount.gurugram,
              beauty: beautySecCount.gurugram,
              mechanical: mechanicalSecCount.gurugram,
              healthcare: healthcareSecCount.gurugram,
            }
          } else if (index === 4) {
            item.count = delhiCount;
            item.allCount = delhiCount;
            item.sectorCount = sectorCount.delhi
            item.sectors = {
              textile: textilesSecCount.delhi,
              beauty: beautySecCount.delhi,
              mechanical: mechanicalSecCount.delhi,
              healthcare: healthcareSecCount.delhi
            }
          }
        })
        setMarkers(_markers);
        setOrigMarkers(_markers); //to save the original markers data
        props.setOrigMarkers(_markers); //to save the original markers data in parent

        // get sectorwise companies count
        let _textilesSecCompCount = 0, _mechanicalSecCompCount = 0, _beautySecCompCount = 0,
          _healthSecCompCount = 0
        for (const key in textilesSecCount) {
          _textilesSecCompCount += textilesSecCount[key]
        }
        for (const key in mechanicalSecCount) {
          _mechanicalSecCompCount += mechanicalSecCount[key]
        }
        for (const key in beautySecCount) {
          _beautySecCompCount += beautySecCount[key]
        }
        for (const key in healthcareSecCount) {
          _healthSecCompCount += healthcareSecCount[key]
        }
        let a = ambalaCount + jindCount + bhiwaniCount + delhiCount + gurugramCount
        let b = {
          all: _textilesSecCompCount + _mechanicalSecCompCount + _beautySecCompCount + _healthSecCompCount,
          textiles: _textilesSecCompCount,
          mechanical: _mechanicalSecCompCount,
          beauty: _beautySecCompCount,
          healthcare: _healthSecCompCount
        }
        setAllCompCount(a);
        setSecCompCount(b);

        props.setAllCompCount(a);
        props.setSecCompCount(b);
      })
      .catch((error) => { console.log(error) })
  }, [])

  // useEffect(() => {
  //   csv("/sample.csv").then(districts => {
  //     const sortedDistricts = sortBy(districts, (o) => -o.companies_number);
  //     setMaxValue(sortedDistricts[0].companies_number);
  //     setData(sortedDistricts);
  //   });
  // }, []);


  // function exportTableToCSV(html, filename) {
  //   var csv = [];
  //   var rows = document.querySelectorAll("table tr");

  //   for (var i = 0; i < rows.length; i++) {
  //     var row = [], cols = rows[i].querySelectorAll("td, th");
  //     for (var j = 0; j < cols.length; j++) {
  //       row.push(cols[j].innerText);
  //     }
  //     csv.push(row.join(","));
  //   }

  //   // download csv file
  //   downloadCSV(csv.join("\n"), filename);
  // }

  // function exportTableToCSV(html, filename) {
  //   var csv = [
  //     ["Trade Group", "Total Count", "Tailoring/Textiles", "Beauty", "Mechanical", "Healthcare"],
  //     ["District Group"]];
  //   var rows = origMarkers;

  //   for (var i = 0; i < rows.length; i++) {
  //     var row = rows[i].name + "+" + "," + rows[i].allCount + "," + rows[i].sectors.textile + "," + rows[i].sectors.beauty + "," + rows[i].sectors.mechanical + "," + rows[i].sectors.healthcare
  //     csv.push(row);
  //   }
  //   let lastRow = [["Total", allCompCount, secCompCount.textiles, secCompCount.beauty, secCompCount.mechanical, secCompCount.healthcare]];
  //   csv.push(lastRow);

  //   // download csv file
  //   downloadCSV(csv.join("\n"), filename);
  // }

  // function downloadCSV(csv, filename) {
  //   var csvFile;
  //   var downloadLink;

  //   csvFile = new Blob([csv], { type: "text/csv" });
  //   downloadLink = document.createElement("a");
  //   downloadLink.download = filename;
  //   downloadLink.href = window.URL.createObjectURL(csvFile);
  //   downloadLink.style.display = "none";
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  // }

  // useEffect(()=>{
  //   exportTableToCSV(null, 'strivedata.csv')
  // }, [props.downloadData])

  // Trade buttons click interaction starts
  const [activeTradeBtn, setActiveTradeBtn] = useState({ all: true, textile: false, beauty: false, mechanical: false, healthcare: false })

  const handleTradeGroupData = (trade) => {
    let _markers = origMarkers;
    if (!["all", "secretarial", "it"].includes(trade)) {//add count on buttons except the 3
      _markers.map((item, index) => {
        item.count = item.sectors[trade];
      })
    } else {//add count on the 3 buttons
      _markers.map((item, index) => {
        item.count = item.allCount;
      })
    }

    setMarkers(_markers)

    let _activeTradeBtn = activeTradeBtn;
    for (const key in _activeTradeBtn) {
      _activeTradeBtn[key] = false
    }
    // highlight the active btn
    if (trade === "all") {
      _activeTradeBtn.all = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "textile") {
      _activeTradeBtn.textile = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "mechanical") {
      _activeTradeBtn.mechanical = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "beauty") {
      _activeTradeBtn.beauty = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "healthcare") {
      _activeTradeBtn.healthcare = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "it") {
      _activeTradeBtn.it = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    } else if (trade === "secretarial") {
      _activeTradeBtn.secretarial = true;
      setActiveTradeBtn({ ..._activeTradeBtn })
    }

  }
  // Trade buttons click interaction ends

  useEffect(() => {
    if (props.tradeBtn === "") return;

    handleTradeGroupData(props.tradeBtn)
  }, [props.tradeBtn]) // highlight active trade buttons on the map

  return (
    <>
      <div className={classes.mapContainer}>

        <PageLoader loaderMessage={"Generating Map..."} style={{ top: '-40px' }}></PageLoader>
        {/* <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white' }}>
            <img alt="meet animated logo loader" style={{ width: 60, height: 68 }} src={LogoLoader} />
          </div> */}

        {/* Trade btns */}
        <div style={{ position: "absolute", padding: 10, top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div
            className={`${classes.legend} ${activeTradeBtn.all ? classes.legend_pri : classes.legend_sec}`}
          >
            All <span>{allCompCount}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.beauty ? classes.legend_pri : classes.legend_sec}`}
          >
            Beauty <span>{secCompCount.beauty}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.it ? classes.legend_pri : classes.legend_sec}`}
          >
            Computer/IT <span>{allCompCount}</span>
          </div>
          
          <div
            className={`${classes.legend} ${activeTradeBtn.mechanical ? classes.legend_pri : classes.legend_sec}`}
          >
            Mechanical <span>{secCompCount.mechanical}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.secretarial ? classes.legend_pri : classes.legend_sec}`}
          >
            Secretarial <span>{allCompCount}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.textile ? classes.legend_pri : classes.legend_sec}`}
          >
            Textiles <span>{secCompCount.textiles}</span>
          </div>
          <div
            className={`${classes.legend} ${activeTradeBtn.healthcare ? classes.legend_pri : classes.legend_sec}`}
          >
            Other <span>{secCompCount.healthcare}</span>
          </div>
          {/* <Button
            variant="contained"
            id="all-btn"
            className={`${classes.headerButton} ${activeTradeBtn.all ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => {
              handleTradeGroupData("all")
            }}>
            All {allCompCount}
          </Button>
          <Button
            variant="contained"
            id="beauty-btn"
            className={`${classes.headerButton} ${activeTradeBtn.beauty ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => { handleTradeGroupData("beauty") }}>
            Beauty {secCompCount.beauty}
          </Button>
          <Button
            variant="contained"
            id="it-btn"
            className={`${classes.headerButton} ${activeTradeBtn.it ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => {
              handleTradeGroupData("it")
            }}>
            Computer/IT {allCompCount}
          </Button>
          <Button
            variant="contained"
            id="healthcare-btn"
            className={`${classes.headerButton} ${activeTradeBtn.healthcare ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => { handleTradeGroupData("healthcare") }}>
            Healthcare {secCompCount.healthcare}
          </Button>
          <Button
            variant="contained"
            id="mechanical-btn"
            className={`${classes.headerButton} ${activeTradeBtn.mechanical ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => { handleTradeGroupData("mechanical") }}>
            Mechanical {secCompCount.mechanical}
          </Button>
          <Button
            variant="contained"
            id="secretarial-btn"
            className={`${classes.headerButton} ${activeTradeBtn.secretarial ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => {
              handleTradeGroupData("secretarial")
            }}>
            Secretarial {allCompCount}
          </Button>
          <Button
            variant="contained"
            id="textile-btn"
            className={`${classes.headerButton} ${activeTradeBtn.textile ? classes.headerButton_pri : classes.headerButton_sec}`}
            onClick={() => { handleTradeGroupData("textile") }}>
            Textiles {secCompCount.textiles}
          </Button> */}
        </div>

        {/* Map */}
        <ComposableMap
          projection="geoMercator"
          projectionConfig={{
            scale: 1500,
          }}
        >
          <ZoomableGroup
            center={[76.65, 29.4]}// to change the center of map
            zoom={10}
            minZoom={10}
            onMoveEnd={handleMoveEnd}
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const cur = data.find(s => s.name === geo.properties.NAME_2);
                  const thisDist = geo.properties.NAME_2;
                  //vars to color the combined districts. the names of districts here match the ones in districtMap.json. e.g. Delhi dists are only Delhi
                  const ambala = ["Ambala", "Panchkula", "Kurukshetra", "Yamuna Nagar"];
                  const jind = ["Jind", "Hisar", "Kaithal", "Karnal", "Panipat"];
                  const bhiwani = ["Bhiwani", "Mahendragarh", "Rohtak",];
                  const gurugram = ["Gurgaon", "Faridabad", "Palwal", "Mewat", "Rewari"];
                  const delhi = ["Delhi", "Jhajjar", "Sonepat", "Ghaziabad", "Gautam Buddha Nagar"]
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      // fill={colorScale(cur ? cur.companies_number : "teal")}
                      fill={ambala.includes(thisDist) ? "#bdbdbd" :
                        jind.includes(thisDist) ? "#e0e0e0" :
                          bhiwani.includes(thisDist) ? "#c3cecd" :
                            gurugram.includes(thisDist) ? "lightgrey" :
                              delhi.includes(thisDist) ? "#b4bdbc" : "#fff"
                      }
                      stroke={cur ? "#f2f2f2" : "#f1f1f1"}
                      strokeWidth={0.1}
                    // onClick={() => { alert(thisDist) }}
                    />
                  );
                })
              }
            </Geographies>

            {/* individual dist name markers */}
            {/* {distLabelMarkers.map(({ name, count, sectors, sectorCount, coordinates, markerOffset }, index) => (
                <Marker key={name} coordinates={coordinates}>
                  <text
                    textAnchor="middle"
                    y={markerOffset}
                    x="0"
                    style={{ fontFamily: "sans-serif", fill: "grey", fontSize: '0.135rem', fontWeight: 500 }}
                  >
                    {name}
                  </text>

                </Marker>
              ))} */}

            {/* company markers */}
            {markers.map(({ name, count, sectors, sectorCount, coordinates, markerOffset }, index) => (
              <Marker key={name} coordinates={coordinates}>
                <a id={"tooltip-anchor-companies" + index}
                  onMouseEnter={() => {
                    setTooltipData(sectors);
                    setTotalCompaniesinDist(count);
                    setTotalSectors(sectorCount);
                    setCurDist(name)
                  }}
                  onMouseLeave={() => {
                    setTooltipData("");
                  }}
                >
                  <circle fill={colorScale(count)} stroke="#FFF" strokeWidth={0.1} r={popScale(count)} />
                  <text
                    textAnchor="middle"
                    y={markerOffset}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#000", fontSize: '0.175rem', fontWeight: 600 }}
                  >
                    {name + "+"}
                  </text>
                  <text
                    textAnchor="middle"
                    y={markerOffset + 3.5}
                    x="1"
                    style={{ fontFamily: "sans-serif", fill: "#1d1d1d", fontSize: '0.2rem', fontWeight: 600 }}
                  >
                    {count}
                  </text>
                </a>
              </Marker>
            ))}

          </ZoomableGroup>
        </ComposableMap>

        {/* tooltip for companies markers */}
        {markers.map((item, index) => (
          <Tooltip anchorId={"tooltip-anchor-companies" + index} key={index}>
            <div>

              <h2 style={{ marginBottom: 3, marginTop: 2 }}>{curDist}</h2>

              <h4 style={{ marginBottom: 5, marginTop: 2 }}>Total Companies: <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}>{totalCompaniesinDist}</span></h4>

              <h4 style={{ marginBottom: 5, marginTop: 4 }}>Companies in various sectors</h4>
              <ul style={{ textAlign: 'left', marginTop: 5 }}>
                {/* <li style={{ fontWeight: 700 }}>Total
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem', fontWeight: 700 }}> {totalSectors}</span>
                </li> */}
                <li>Beauty
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {tooltipData.beauty}</span></li>
                <li>IT
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {item.allCount}</span></li>
                
                <li>Mechanical
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {tooltipData.mechanical}</span>
                </li>
                <li>Secretarial
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {item.allCount}</span></li>
                <li>Tailoring/Textiles
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {tooltipData.textile}</span>
                </li>
                <li>Other
                  <span style={{ color: "#5BC2A8", fontSize: '1.1rem' }}> {tooltipData.healthcare}</span></li>
              </ul>
            </div>
          </Tooltip>
        ))
        }
      </div>

      {/* download csv btn */}
      {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <Button
          variant="contained"
          style={{ width: 220 }}
          className={`${classes.headerButton} ${classes.headerButton_pri}`}
          onClick={() => { exportTableToCSV(null, 'companies.csv'); }}>
          Download CSV
        </Button>
      </div> */}

    </>
  );
};

export default MapChart;
